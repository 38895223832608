import { useMutation } from "@tanstack/react-query";
import api from "../../../api";
import { fromJson } from "../../../domain-adapters/Lecture";
import { Lecture } from "../../../domains/Lecture";
import { LectureQuestionnaire } from "../../../domains/LectureQuestionnaire";
import { HTTPErrors, createError } from "../../../errors";
import { CopyableFormMode } from "../../../helpers/viewMode";
import SectionInterface from "../../../interfaces/SectionInterface";

type UseMutationWithLectureProps = {
  section: SectionInterface;
  onError: (errors: HTTPErrors) => void;
  onSuccess: () => void;
};

type QuestionnaireParam = Omit<LectureQuestionnaire, "id"> & {
  id?: LectureQuestionnaire["id"];
};
export type MutateLectureParamData = Omit<
  Lecture,
  "updatedAt" | "questionnaires" | "status" | "id"
> & { id?: string; questionnaires?: QuestionnaireParam[] };

type MutateLectureParam = {
  data: MutateLectureParamData;
  mode: CopyableFormMode;
};

export const useMutationWithLecture = ({
  section,
  onError,
  onSuccess,
}: UseMutationWithLectureProps) => {
  return useMutation<Lecture, HTTPErrors, MutateLectureParam>({
    mutationFn: async (param) => {
      const result =
        param.mode === "edit" && param.data.id
          ? await api.patch(patchUrlOf(section, param.data.id), toRecord(param))
          : await api.post(postUrlOf(section), toRecord(param));

      if (result.ok) {
        const json = await result.json();
        return fromJson(json.lecture);
      }
      throw await createError(result);
    },
    onSuccess,
    onError,
  });
};

const postUrlOf = (section: SectionInterface) =>
  `/api/v1/sections/${section.id}/lectures`;

const patchUrlOf = (section: SectionInterface, lectureId: string) =>
  `/api/v1/sections/${section.id}/lectures/${lectureId}`;

const toRecord = ({ data, mode }: MutateLectureParam) => {
  return {
    transcribe_questionnaire_answer: false,
    register_study_record: false,
    name: data.name,
    attendance_confirm: data.attendanceConfirm,
    attendance_limit_days: data.attendanceLimitDays,
    attendance_location_collect: data.attendanceLocationCollect,
    ...(data.questionnaires
      ? {
          questionnaires: data.questionnaires.map((qs) => ({
            ...(qs.id && mode === "edit" ? { id: qs.id } : {}), // 複製の時はquestionnaireIdを引き継がない
            ...(qs.format !== "free"
              ? { choices: JSON.stringify(qs.choices) }
              : {}),
            question: qs.question,
            format: qs.format,
            disabled: qs.disabled,
            deleted: qs.deleted,
          })),
        }
      : {}),
  };
};
