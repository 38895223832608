import * as React from "react";

import classNames from "classnames";
import { RegexpHelper } from "../../../helpers/RegexpHelper";
import TimeHelper from "../../../helpers/TimeHelper";
import GuardianMessageInterface from "../../../interfaces/GuardianMessageInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import CircleIcon from "../../atoms/CircleIcon";
import styles from "./GuardianMessageListItem.scss";
const nl2br = require("react-nl2br");
const reactStringReplace = require("react-string-replace");

interface Props {
  message: GuardianMessageInterface;
  student: StudentInterface;
}

const GuardianMessageListItem: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.root} role="listitem">
      {props.message.operator
        ? renderOperatorGuardianMessage(props)
        : renderStudentGuardianMessage(props)}
    </div>
  );
};

const renderStudentGuardianMessage = (props: Props) => {
  const { sentAt } = props.message;
  const { fullName } = props.student;

  return (
    <div className={styles.studentGuardianMessage}>
      <div className={styles.studentGuardianMessage__header}>
        <CircleIcon
          iconProps={{
            name: "icon-guardian-message",
            iconClassName: styles.studentIcon,
          }}
          className={styles.studentIconWrapper}
        />
        <span className={styles.name}>{fullName}（保護者）</span>
        <time className={styles.datetime}>{TimeHelper.fullFormat(sentAt)}</time>
      </div>
      {renderStudentGuardianMessageBody(props.message)}
    </div>
  );
};

const renderOperatorGuardianMessage = (props: Props) => {
  const { sentAt } = props.message;
  const { fullName } = props.student.section;
  const namePrefix = props.message.announceMessage ? "【一斉送信】" : "";

  return (
    <div className={styles.operatorGuardianMessage}>
      <div className={styles.operatorGuardianMessage__header}>
        <time className={styles.datetime}>{TimeHelper.fullFormat(sentAt)}</time>
        <span className={styles.name}>{namePrefix + fullName}</span>
        <CircleIcon
          iconProps={{
            name: "icon-account",
            iconClassName: styles.operatorIcon,
          }}
          className={styles.operatorIconWrapper}
        />
      </div>
      {renderOperatorGuardianMessageBody(props.message)}
    </div>
  );
};

const renderStudentGuardianMessageBody = (
  message: GuardianMessageInterface,
) => {
  if (message.imageUrl) {
    return (
      <a href={message.imageUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={message.imageUrl}
          className={styles.studentGuardianMessage__image}
        />
      </a>
    );
  } else {
    return (
      <p className={styles.studentGuardianMessage__content}>
        {reactStringReplace(
          nl2br(message.content),
          RegexpHelper.URL,
          (match: string): React.ReactNode => (
            <a href={match} target="_blank" rel="noopener noreferrer">
              {match}
            </a>
          ),
        )}
      </p>
    );
  }
};

const renderOperatorGuardianMessageBody = (
  message: GuardianMessageInterface,
) => {
  if (message.imageUrl) {
    return (
      <a href={message.imageUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={message.imageUrl}
          className={styles.operatorGuardianMessage__image}
        />
      </a>
    );
  } else {
    return (
      <p
        className={classNames(styles.operatorGuardianMessage__content, {
          [styles.operatorGuardianMessage__contentAnnounce]:
            message.announceMessage,
        })}
      >
        {reactStringReplace(
          nl2br(message.content),
          RegexpHelper.URL,
          (match: string): React.ReactNode => (
            <a href={match} target="_blank" rel="noopener noreferrer">
              {match}
            </a>
          ),
        )}
      </p>
    );
  }
};

export default GuardianMessageListItem;
