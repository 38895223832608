import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BackLink from "../../../../components/atoms/BackLink";
import Icon from "../../../../components/atoms/Icon";
import Loader from "../../../../components/atoms/Loader";
import { useFetchProfile } from "../../../../hooks/http/useFetchProfile";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import AppStateInterface from "../../../../interfaces/AppStateInterface";
import OperatorInterface from "../../../../interfaces/OperatorInterface";
import SectionInterface from "../../../../interfaces/SectionInterface";
import GoogleClassroomCourseLinkModal from "../GoogleClassroomCourseLinkModal";
import LinkedGoogleClassroomCourseTable from "../LinkedGoogleClassroomCourseTable";
import { useLinkCourses } from "../useLinkCourses";
import { useRelinkCourse } from "../useRelinkCourse";
import { useUnlinkCourse } from "../useUnlinkCourse";
import styles from "./styles.scss";

interface OwnProps {
  section: SectionInterface;
  operator: OperatorInterface;
}
interface Props {
  section: SectionInterface;
  operator: OperatorInterface;
}

const HELP_URL = "https://fs-help.studyplus.co.jp/ja/articles/4625523";

const SectionsSettingsGoogleClassroom: React.FC<Props> = (props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { data, isLoading, error } = useFetchProfile();
  useQueryError(error);

  const { requestLinkCourses, isPending: isLinking } = useLinkCourses({
    sectionId: props.section.id,
  });

  const { requestUnlinkCourse, isPending: isUnlinking } = useUnlinkCourse({
    sectionId: props.section.id,
  });

  const { requestRelinkCourse, isPending: isRelinking } = useRelinkCourse({
    sectionId: props.section.id,
  });

  const isSubmitting = isLinking || isUnlinking || isRelinking;

  if (isLoading || !data) return <Loader loading />;

  return (
    <>
      <div className={styles.container}>
        <BackLink>戻る</BackLink>
        <div className={styles.root}>
          <div className={styles.header}>
            <div className={styles.logo}>
              <img src="/images/google_classroom_square_icon.png" />
            </div>
            <div className={styles.title}>Google Classroom連携</div>
            <a
              href={HELP_URL}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="icon-help" />
              連携方法を見る
            </a>
          </div>
          <div className={styles.summary}>
            <span className={styles.summary__description}>
              Google
              Classroomは、教師による課題の管理をサポートするシンプルで使いやすいツールです。Classroom
              を利用すると、教師はクラスの作成、課題の出題と採点、フィードバックの提供などを
              1 か所で行えます。
            </span>
            <div className={styles.summary__features}>
              <div className={styles.summary__features__heading}>
                主な機能：
              </div>
              <div className={styles.summary__features__body}>
                ・生徒からの課題提出がStudyplus・Studyplus for
                Schoolのタイムラインに投稿されます
              </div>
            </div>
          </div>
          <div className={styles.action}>
            <div className={styles.action__box}>
              <div>
                1. GoogleアカウントとStudyplus for Schoolアカウントを連携する
              </div>
              <Link
                to="/settings/profile"
                className={`buiButtonBase buiButtonVariantOutline buiButtonSizeSm buiButtonRound`}
              >
                アカウント設定
              </Link>
            </div>
            <div className={styles.action__box}>
              <div>2. Google Classroomで作成したクラスを同期する</div>
              <Button
                variant="outline"
                size="sm"
                isRound={true}
                disabled={!data.googleClassroomConnected}
                onClick={() => setIsModalOpen(true)}
              >
                クラスを追加
              </Button>
            </div>
          </div>
          <div className={styles.courses}>
            <div className={styles.courses__heading}>
              Google Classroom：同期中クラス
            </div>
            <LinkedGoogleClassroomCourseTable
              sectionId={props.section.id}
              submitting={isSubmitting}
              unlinkCourse={(course) => {
                requestUnlinkCourse({ course });
              }}
              relinkCourse={(courseId) => {
                requestRelinkCourse({ courseId });
              }}
            />
          </div>
        </div>
      </div>
      <GoogleClassroomCourseLinkModal
        submitting={isSubmitting}
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        linkCourses={(courseIds: string[]) => {
          requestLinkCourses({
            selectedCourseIds: courseIds,
            onSuccess: () => setIsModalOpen(false),
          });
        }}
      />
    </>
  );
};

const mapStateToProps = (
  _: AppStateInterface,
  ownProps: OwnProps,
): OwnProps => ({
  section: ownProps.section,
  operator: ownProps.operator,
});

export default connect(mapStateToProps)(SectionsSettingsGoogleClassroom);
