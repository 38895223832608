import { useQuery } from "@tanstack/react-query";
import { boronClient } from "../../api";
import { type HTTPErrors, createError } from "../../errors";
import type { paths } from "../../lib/api/v1";

const path = "/api/v1/students/{student_id}/koekake_assists";

type ResponseBody =
  paths[typeof path]["get"]["responses"]["200"]["content"]["application/json"];

type Props = {
  studentId: string;
  datetime: string;
};

export const useFetchKoekakeAssists = ({ studentId, datetime }: Props) => {
  const { data, isLoading, error } = useQuery<ResponseBody, HTTPErrors>({
    queryKey: ["koekakeAssists", studentId, datetime],
    queryFn: async () => {
      const { response } = await boronClient.GET(path, {
        params: {
          path: { student_id: studentId },
          query: { datetime },
        },
      });

      if (response.ok) return await response.json();

      throw await createError(response);
    },
  });

  return {
    messages: data?.messages,
    isLoading,
    error,
  };
};
