import * as React from "react";
import StudentTag from "../../../domains/StudentTag";
import DeprecatedTagButton from "../../features/DeprecatedTagButton/index";
import {
  TagFilterDropdown,
  useTagFilterDropdown,
} from "../../features/TagFilter/TagFilterDropDown/index";
import { TagFilterOpenButton } from "../../features/TagFilter/TagFilterOpenButton/index";
import { TagSelector } from "../../features/TagSelector/index";

type Props = {
  tags: StudentTag[];
  selectedTags: StudentTag[];
  onSelect: (tag: StudentTag) => () => void;
  onDeselect: (tag: StudentTag) => () => void;
  label?: string;
};

export const StudentTagFilter = ({
  tags,
  selectedTags,
  onSelect,
  onDeselect,
  label,
}: Props) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const { isOpened, close, open } = useTagFilterDropdown({
    buttonRef,
    dropdownRef,
    containerRef,
  });

  const isSelectedTag = (tag: StudentTag) =>
    selectedTags.some((selectedTag) => selectedTag.id === tag.id);

  return (
    <>
      {selectedTags.map((tag: StudentTag) => (
        <SelectedStudentTagButton
          tag={tag}
          onClick={isSelectedTag(tag) ? onDeselect(tag) : onSelect(tag)}
          key={`selected-tag-${tag.id}`}
        />
      ))}
      <div ref={containerRef}>
        <TagFilterOpenButton
          label={label}
          onClick={isOpened ? close : open}
          buttonRef={buttonRef}
        />
      </div>
      <TagFilterDropdown
        opened={isOpened}
        dropdownRef={dropdownRef}
        onClose={close}
      >
        <TagSelector
          tags={tags}
          selectedTags={selectedTags}
          renderTag={(tag) => (
            <SelectableStudentTagButton
              tag={tag}
              isSelected={isSelectedTag(tag)}
              onClick={isSelectedTag(tag) ? onDeselect(tag) : onSelect(tag)}
            />
          )}
        />
      </TagFilterDropdown>
    </>
  );
};

const SelectableStudentTagButton = ({
  tag,
  isSelected,
  onClick,
}: {
  tag: StudentTag;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const theme =
    tag.type === "smart_tag"
      ? isSelected
        ? "smarttag-active"
        : "smarttag"
      : isSelected
        ? "main"
        : "gray";

  return (
    <DeprecatedTagButton label={tag.name} onClick={onClick} theme={theme} />
  );
};

const SelectedStudentTagButton = ({
  tag,
  onClick,
}: {
  tag: StudentTag;
  onClick: () => void;
}) => {
  const theme = tag.type === "smart_tag" ? "smarttag-white" : "white";

  return (
    <DeprecatedTagButton
      label={tag.name}
      onClick={onClick}
      theme={theme}
      iconName="icon-close-x"
    />
  );
};
