import * as React from "react";
import { connect } from "react-redux";
import { initializeFiltersByQuery } from "../actions/common/filters";
import { clearStudent } from "../actions/common/student";
import SectionsTemplate from "../components/templates/SectionsTemplate/index";
import { SectionProvider } from "../contexts/section";
import { WithRouterProps } from "../helpers/RouterHelper";
import FiltersInterface from "../interfaces/FiltersInterface";
import { OperatorPrivilege } from "../interfaces/OperatorInterface";
import {
  AuthenticateRouterProps,
  AuthenticatedPageCallbacks,
  AuthenticatedPageProps,
} from "./enhanceAuthenticatedPage";
import enhanceAuthenticatedPage from "./enhanceAuthenticatedPage";
import withFiltersInitialization from "./withFiltersInitialization";

export interface SectionsPageProps
  extends WithRouterProps<AuthenticateRouterProps>,
    AuthenticatedPageProps,
    AuthenticatedPageCallbacks {
  initializeFiltersByQuery: typeof initializeFiltersByQuery;
  clearStudent: typeof clearStudent;
}

interface PageInfoInterface {
  title: string;
}

const enhanceSectionsPage = (
  WrappedComponent: React.ComponentClass<any, any> | React.FC<any>,
  pageInfo: PageInfoInterface,
  requiredPrivileges?: OperatorPrivilege[],
) => {
  class SectionsPage extends React.Component<SectionsPageProps, unknown> {
    get studentFilter() {
      return this.props.studentFilterContext.findTagFilter({
        sectionId: this.props.currentSection?.data?.id || "",
      });
    }

    get filter() {
      return this.props.filters.find((filter: FiltersInterface) => {
        return (
          !!this.props.currentSection.data &&
          filter.sectionId === this.props.currentSection.data.id
        );
      });
    }

    constructor(props: SectionsPageProps) {
      super(props);
    }

    componentDidMount() {
      /**
       * 生徒情報をパージ。校舎メニュー/生徒メニューの切り替えのタイミングで入退室ステータスを確実に取り直したいため。
       * (校舎メニュー生徒メニューを切り替えても、同じ生徒への遷移だと取り直ししていなかった)
       * セクションメニュー内を回遊するたびに実行されてしまうが、毎回リクエストが走るわけではないので許容できると判断
       */
      this.props.clearStudent();
    }

    render() {
      if (
        !this.props.currentSection.data ||
        !this.props.currentOperator ||
        !this.studentFilter ||
        !this.filter ||
        this.props.params.sectionId !== this.props.currentSection.data.id
      ) {
        return null;
      }

      return (
        <React.Fragment>
          <SectionsTemplate pageInfo={{ title: pageInfo.title }}>
            <SectionProvider value={this.props.currentSection.data}>
              <WrappedComponent
                {...this.props}
                filter={this.filter}
                section={this.props.currentSection.data}
              />
            </SectionProvider>
          </SectionsTemplate>
        </React.Fragment>
      );
    }
  }
  return enhanceAuthenticatedPage(
    withFiltersInitialization(connect(null, mapDispatchToProps)(SectionsPage)),
    requiredPrivileges,
  );
};
const mapDispatchToProps = {
  clearStudent,
};

export default enhanceSectionsPage;
