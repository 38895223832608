import * as React from "react";
import BackLink from "../../../../components/atoms/BackLink";
import SectionInterface from "../../../../interfaces/SectionInterface";
import StudentNewForm from "../StudentNewForm";
import styles from "./styles.scss";

type Props = {
  section: SectionInterface;
};

const SectionsSettingsStudentsNew = (props: Props): React.ReactElement => {
  return (
    <div className={styles.root}>
      <BackLink>戻る</BackLink>
      <StudentNewForm section={props.section} />
    </div>
  );
};

export default SectionsSettingsStudentsNew;
