import OperatorInterface from "../../../interfaces/OperatorInterface";
import Icon from "../../atoms/Icon";
import styles from "./styles.scss";

interface Props {
  operator: OperatorInterface;
}

const ForbiddenError = (props: Props) => {
  const { operator } = props;

  return (
    <div className={styles.root}>
      <p>
        アクセスができるアカウントに切り替えてください。
        <a
          href="https://fs-help.studyplus.co.jp/articles/3079903"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          詳細はこちら
        </a>
        <br />
        現在、以下のアカウントでログイン中です。
      </p>
      <p className={styles.operator}>
        <Icon className={styles.icon} name="icon-account" />
        {operator.lastName} {operator.firstName} : {operator.email}
        でログイン中です。
      </p>
    </div>
  );
};

export default ForbiddenError;
