import { useEffect } from "react";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper.tsx";
import { AuthenticatedPageProps } from "../../hocs/enhanceAuthenticatedPage.tsx";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage.tsx";
import { useQueryError } from "../../hooks/http/useQueryError.ts";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll.ts";
import SectionInterface from "../../interfaces/SectionInterface.ts";
import SectionStaffBoardPosts from "./SectionStaffBoardPosts";
import { useFetchStaffBoardPosts } from "./useFetchStaffBoardPosts.ts";

interface Props
  extends OutletContextProps,
    Pick<AuthenticatedPageProps, "currentOperator"> {
  section: SectionInterface;
}

const SectionsStaffBoardPostsPage: React.FC<Props> = (props) => {
  const { section, currentOperator, setActiveMenu } = props;

  useEffect(() => {
    setActiveMenu("dashboard");
  }, []);

  const { staffBoardPosts, isLoading, error, fetchNextStaffBoardPosts } =
    useFetchStaffBoardPosts({
      sectionId: section.id,
    });
  useQueryError(error);

  useOnMainScrollAreaScroll(
    () => fetchNextStaffBoardPosts(),
    [fetchNextStaffBoardPosts],
  );

  return (
    <SectionStaffBoardPosts
      section={section}
      currentOperator={currentOperator}
      staffBoardPosts={staffBoardPosts}
      isLoading={isLoading}
    />
  );
};

const pageInfo = {
  title: "スタッフボード",
};

const EnhancedPage = enhanceSectionsPage(SectionsStaffBoardPostsPage, pageInfo);

export default withRouter(EnhancedPage);
