import { Dispatch } from "redux";
import { createAction } from "redux-actions";

import AnalyticsApi from "../api-clients/AnalyticsApi";
import StudentTag from "../domains/StudentTag";
import FiltersHelper from "../helpers/FiltersHelper";
import AppStateInterface from "../interfaces/AppStateInterface";
import { buildMainResourceApiErrorAction } from "./common/errors";
import { initializeFiltersByQuery } from "./common/filters";
import { showErrorFlashMessage, showSuccessFlashMessage } from "./flashMessage";

export const getStudentAnalyticsTableTimeRequestSending = createAction(
  "GET_STUDENT_ANALYTICS_TABLE_TIME_REQUEST_SENDING",
);
export const getInitialStudentAnalyticsTableTimeRequestSending = createAction(
  "GET_INITIAL_STUDENT_ANALYTICS_TABLE_TIME_REQUEST_SENDING",
);
export const getStudentAnalyticsTableTimeResponseReceived = createAction(
  "GET_STUDENT_ANALYTICS_TABLE_TIME_RESPONSE_RECEIVED",
);
export const getStudentAnalyticsTableTimeResponseError = createAction(
  "GET_STUDENT_ANALYTICS_TABLE_TIME_RESPONSE_ERROR",
);

// 初期ロード
export const loadInitialStudentAnalyticsTableTime =
  (studentId: string) =>
  (dispatch: Dispatch, getState: () => AppStateInterface) => {
    dispatch(getInitialStudentAnalyticsTableTimeRequestSending());
    const query = FiltersHelper.stateToFiltersQueryString(getState());

    AnalyticsApi.interruptGetStudentTime(studentId, { query })
      .then((res: any) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(getStudentAnalyticsTableTimeResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStudentAnalyticsTableTimeResponseError());
        }
      })
      .catch((err: any) => {
        dispatch(getStudentAnalyticsTableTimeResponseError(err));
      });
  };

// 追加読み込み
export const loadStudentAnalyticsTableTime =
  (studentId: string, page?: number) => (dispatch: any, getState: any) => {
    const query = FiltersHelper.stateToFiltersQueryString(getState(), { page });

    dispatch(getStudentAnalyticsTableTimeRequestSending());

    AnalyticsApi.getStudentTime(studentId, { query })
      .then((res: any) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(getStudentAnalyticsTableTimeResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStudentAnalyticsTableTimeResponseError());
        }
      })
      .catch((err: any) => {
        dispatch(getStudentAnalyticsTableTimeResponseError(err));
      });
  };

// 学習量
export const getStudentAnalyticsTableAmountRequestSending = createAction(
  "GET_STUDENT_ANALYTICS_TABLE_AMOUNT_REQUEST_SENDING",
);
export const getInitialStudentAnalyticsTableAmountRequestSending = createAction(
  "GET_INITIAL_STUDENT_ANALYTICS_TABLE_AMOUNT_REQUEST_SENDING",
);
export const getStudentAnalyticsTableAmountResponseReceived = createAction(
  "GET_STUDENT_ANALYTICS_TABLE_AMOUNT_RESPONSE_RECEIVED",
);
export const getStudentAnalyticsTableAmountResponseError = createAction(
  "GET_STUDENT_ANALYTICS_TABLE_AMOUNT_RESPONSE_ERROR",
);

// 初期ロード
export const loadInitialStudentAnalyticsTableAmount =
  (studentId: string) =>
  (dispatch: Dispatch, getState: () => AppStateInterface) => {
    dispatch(getInitialStudentAnalyticsTableAmountRequestSending());
    const query = FiltersHelper.stateToFiltersQueryString(getState());

    AnalyticsApi.interruptGetStudentAmount(studentId, { query })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getStudentAnalyticsTableAmountResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStudentAnalyticsTableAmountResponseError());
        }
      })
      .catch((err: any) => {
        dispatch(getStudentAnalyticsTableAmountResponseError(err));
      });
  };

// 追加読み込み
export const loadStudentAnalyticsTableAmount =
  (studentId: string, page?: number) => (dispatch: any, getState: any) => {
    const query = FiltersHelper.stateToFiltersQueryString(getState(), { page });

    dispatch(getStudentAnalyticsTableAmountRequestSending());

    AnalyticsApi.getStudentAmount(studentId, { query })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getStudentAnalyticsTableAmountResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStudentAnalyticsTableAmountResponseError());
        }
      })
      .catch(() => {
        dispatch(getStudentAnalyticsTableAmountResponseError());
      });
  };

// クエリストリングから状態を作って、それからアナリティクスデータを読み込む
export const changeStudentAnalyticsTimeFilter =
  (
    sectionId: string,
    studentId: string,
    query: string,
    allTags: StudentTag[],
  ) =>
  (dispatch: any) => {
    dispatch(getInitialStudentAnalyticsTableTimeRequestSending());
    dispatch(
      initializeFiltersByQuery({ query, allTags, sectionId, force: true }),
    );
    // NOTE: 絞り込みの条件変更で、連続で実行されることを考慮して割り込みfetchにしてあります
    AnalyticsApi.interruptGetStudentTime(studentId, { query })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getStudentAnalyticsTableTimeResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStudentAnalyticsTableTimeResponseError());
        }
      })
      .catch((err: any) => {
        dispatch(getStudentAnalyticsTableTimeResponseError(err));
      });
  };

export const changeStudentAnalyticsAmountFilter =
  (
    sectionId: string,
    studentId: string,
    query: string,
    allTags: StudentTag[],
  ) =>
  (dispatch: any) => {
    dispatch(getInitialStudentAnalyticsTableAmountRequestSending());
    dispatch(
      initializeFiltersByQuery({ query, allTags, sectionId, force: true }),
    );
    // NOTE: 絞り込みの条件変更で、連続で実行されることを考慮して割り込みfetchにしてあります
    AnalyticsApi.interruptGetStudentAmount(studentId, { query })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getStudentAnalyticsTableAmountResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStudentAnalyticsTableAmountResponseError());
        }
      })
      .catch((err: any) => {
        dispatch(getStudentAnalyticsTableAmountResponseError(err));
      });
  };

const openAnalyticsShareModal = createAction("OPEN_ANALYTICS_SHARE_MODAL");
const closeAnalyticsShareModal = createAction("CLOSE_ANALYTICS_SHARE_MODAL");
export const toggleModal = (isOpen: boolean) => (dispatch: Dispatch) => {
  if (isOpen) {
    dispatch(openAnalyticsShareModal());
  } else {
    dispatch(closeAnalyticsShareModal());
  }
};

const postAnalyticsShareSending = createAction("POST_ANALYTICS_SHARE_SENDING");
const postAnalyticsShareSuccess = createAction("POST_ANALYTICS_SHARE_SUCCESS");
const postAnalyticsShareError = createAction("POST_ANALYTICS_SHARE_ERROR");

export const postAnalyticsShare =
  (studentId: string, message: string, image: Blob) => (dispatch: Dispatch) => {
    dispatch(postAnalyticsShareSending(studentId));
    AnalyticsApi.postStudentShare(studentId, message, image)
      .then((res) => {
        if (res.ok) {
          dispatch(showSuccessFlashMessage("アナリティクスを送信しました"));
          dispatch(postAnalyticsShareSuccess(studentId));
        } else {
          dispatch(showErrorFlashMessage("アナリティクスの送信に失敗しました"));
          dispatch(postAnalyticsShareError(studentId));
        }
      })
      .catch(() => {
        dispatch(showErrorFlashMessage("アナリティクスの送信に失敗しました"));
        dispatch(postAnalyticsShareError(studentId));
      });
  };
