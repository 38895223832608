import * as React from "react";
import Loader from "../../components/atoms/Loader";
import StudentFilter from "../../components/features/NewStudentFilter";
import { SectionTimelineTab } from "../../components/features/SectionTimelineTab";
import TimelineItem from "../../components/features/Timeline/TimelineItem";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../helpers/RouterHelper";
import {
  AuthenticateRouterProps,
  AuthenticatedPageProps,
} from "../../hocs/enhanceAuthenticatedPage";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import { useQueryString } from "../../hooks/useQueryString";
import SectionInterface from "../../interfaces/SectionInterface";
import StudyRecordInterface from "../../interfaces/StudyRecordInterface";
import { TimelineType } from "../../interfaces/TimelineInterface";
import styles from "./index.scss";
import { useFetchSectionsTimeline } from "./useFetchSectionsTimeline";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    Pick<AuthenticatedPageProps, "studentFilterContext">,
    OutletContextProps {
  section: SectionInterface;
}

const SectionsTimelinePage = (props: Props) => {
  React.useLayoutEffect(() => {
    props.setActiveMenu("timeline");
  }, []);

  const { data, isLoading, isFetchingNextPage } =
    useSectionsTimelinePage(props);

  if (!props.section) return null;

  return (
    <>
      <SectionTimelineTab
        sectionId={props.section.id}
        timelineType={TimelineType.TIMELINE_REALTIME}
        search={props.location.search}
      />
      <StudentFilter sectionId={props.section.id} />
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <TimelineRealtimeItems
          data={data as StudyRecordInterface[]}
          isFetchingNextPage={isFetchingNextPage}
        />
      )}
    </>
  );
};

type TimelineRealtimeItemsProps = {
  data: StudyRecordInterface[];
  isFetchingNextPage: boolean;
};
const TimelineRealtimeItems = ({
  data,
  isFetchingNextPage,
}: TimelineRealtimeItemsProps) => {
  return (
    <div className={styles.root}>
      <div>
        {data && data.length === 0 ? (
          <p className="mt-4 text-center">勉強記録がまだありません</p>
        ) : (
          data?.map((studyRecord) => {
            return (
              <div
                className={styles.timelineItem}
                key={`TimelineRealtimeItem-${studyRecord.id}`}
              >
                <TimelineItem
                  studyRecord={studyRecord as StudyRecordInterface}
                  student={studyRecord.student}
                  canSendComment={studyRecord.student.canSendComment}
                  showHeader={true}
                  sectionId={studyRecord.student.section.id}
                  billingPlan={studyRecord.student.billingPlan}
                />
              </div>
            );
          })
        )}
      </div>
      <Loader loading={isFetchingNextPage} />
    </div>
  );
};

const useSectionsTimelinePage = (props: Props) => {
  const queryParams = useQueryString();
  const { data, isLoading, isFetchingNextPage, fetchNextPage, error } =
    useFetchSectionsTimeline({
      sectionId: props.params.sectionId,
      queryParams: queryParams,
      enabled: !!props.section,
    });
  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (isLoading || isFetchingNextPage) return;

    fetchNextPage();
  }, [isLoading, isFetchingNextPage, fetchNextPage]);

  return {
    data,
    isLoading,
    isFetchingNextPage,
  };
};

const pageInfo = {
  title: "タイムライン",
};

export default enhanceSectionsPage(SectionsTimelinePage, pageInfo);
