import { Link } from "react-router-dom";
import StudentFilter from "../../components/features/NewStudentFilter";
import TabsNavi from "../../components/molecules/TabsNavi";
import { OrderDirFilterType } from "../../interfaces/FiltersInterface";
import { OperatorRole } from "../../interfaces/OperatorInterface";
import SectionInterface from "../../interfaces/SectionInterface";
import StudentInterface from "../../interfaces/StudentInterface";
import styles from "./SectionsSettingsStudents.scss";
import SectionSettingsStudentsIndex from "./SectionsSettingsStudentsIndex";

interface Props {
  section: SectionInterface;
  operatorRole: OperatorRole;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  students: readonly StudentInterface[];
  updateStudent: (student: StudentInterface) => void;
  removeStudent: (studentId: string) => void;
  orderDir: OrderDirFilterType;
  onOrderChange: (orderDir: OrderDirFilterType) => void;
}

// 校舎独自教材一覧ページ
const SectionsSettingsStudents = (props: Props) => {
  return (
    <div className={styles.root}>
      <TabsNavi
        tabs={[
          {
            label: "生徒一覧",
            link: `/sections/${props.section.id}/settings/students`,
            active: true,
          },
        ]}
      />
      <div className={styles.navigation}>
        <div className={styles.filter}>
          <StudentFilter sectionId={props.section.id} />
        </div>
        <Link
          to={`/sections/${props.section.id}/students/new`}
          className={`buiButtonBase buiButtonVariantFilled ${styles.button}`}
        >
          生徒の登録
        </Link>
      </div>
      <SectionSettingsStudentsIndex
        guardianLineConnectionUsable={
          props.section.guardianLineConnectionUsable
        }
        isLoading={props.isLoading}
        isFetchingNextPage={props.isFetchingNextPage}
        students={props.students}
        updateStudent={props.updateStudent}
        removeStudent={props.removeStudent}
        operatorRole={props.operatorRole}
        onOrderChange={props.onOrderChange}
        orderDir={props.orderDir}
      />
    </div>
  );
};

export default SectionsSettingsStudents;
