import { Button, IconPlusTag } from "@studyplus/boron-ui";
import * as React from "react";
import { FileTypes } from "../../../domains/ExaminationResult";
import FileHelper from "../../../helpers/FileHelper";
import ErrorText from "../../atoms/ErrorText";
import { AttachmentViewer } from "../AttachmentViewer";
import { useAttachmentViewer } from "../AttachmentViewer/useAttachmentViewer";
import TagButton from "../TagButton";

export const MAX_NUM_OF_IMAGES = 5;
interface Props {
  disabledFileAttachmentButton?: boolean;
  onChange: (values: { files?: File[]; numberOfImages?: number }) => void;
  files: File[] | null;
  hasError: boolean;
  errorTexts: string[];
}

export const ExaminationResultFileAttachmentItem = (props: Props) => {
  const attachmentViewerProps = useAttachmentViewer({
    files: props.files,
    maxPageNo: MAX_NUM_OF_IMAGES,
  });

  React.useEffect(() => {
    props.onChange({ numberOfImages: attachmentViewerProps.numOfImages });
  }, [attachmentViewerProps.numOfImages]);

  const attachmentItemRef = React.useRef<HTMLInputElement>(null);

  const onClickFileChange = () => {
    if (attachmentItemRef.current) {
      attachmentItemRef.current.click();
    }
  };

  const handleChangeAttachments = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const files: File[] = [];
    files.push(...(props.files || []));
    files.push(...Array.from(e.target.files));
    props.onChange({
      files,
      numberOfImages: attachmentViewerProps.parsedFiles.length,
    });

    // NOTE: 次に同じファイルを選択した時にonChangeが発火しないため、valueをクリアする
    e.target.value = "";
  };

  const handleRemoveAttachment = (targetFile: File) => {
    const changedFiles =
      props.files?.filter((file) => file !== targetFile) || props.files;
    props.onChange({
      files: changedFiles || [],
      numberOfImages: attachmentViewerProps.parsedFiles.length,
    });
  };

  const handlePageChange = (targetFile: File) => {
    const firstImageIndexOfTargetFile =
      attachmentViewerProps.parsedFiles.findIndex(
        (file) => file.file === targetFile,
      );
    attachmentViewerProps.setPageNo(firstImageIndexOfTargetFile);
  };

  const acceptTypes = FileTypes.join(", ");

  return (
    <div className="rounded-sm bg-gray-100 p-4 pb-6">
      <div className="flex items-center justify-start">
        <Button
          variant="outline"
          disabled={
            props.disabledFileAttachmentButton ||
            props.hasError ||
            attachmentViewerProps.parsedFiles.length >= MAX_NUM_OF_IMAGES ||
            attachmentViewerProps.isParsing
          }
          onClick={onClickFileChange}
          size="sm"
          withLeftIconLayoutSize="md"
          className="pl-4 pr-8"
          type="button"
        >
          <IconPlusTag />
          <span>画像の登録</span>
        </Button>
        <input
          ref={attachmentItemRef}
          id="examination-result-file-attachment-item__input"
          type="file"
          onChange={handleChangeAttachments}
          multiple
          accept={acceptTypes}
          hidden
        />
      </div>
      {props.errorTexts.map((errorText, index) => (
        <ErrorText key={index}>{errorText}</ErrorText>
      ))}
      {props.files && (
        <>
          <div className="mt-4 flex flex-wrap gap-4">
            {Array.from(props.files).map((file, index) => (
              <TagButton
                key={`attachment-tag-button_${file.name}_${index}`}
                label={FileHelper.truncateFilename(file.name, 16)}
                variant="outline"
                hoverVariant="solid"
                colorType="primary"
                iconName="icon-close-x"
                onClick={() => handlePageChange(file)}
                onClickIcon={() => handleRemoveAttachment(file)}
                iconType="circle"
                highlight={attachmentViewerProps.isCurrentFile(file)}
                className="max-w-md"
              />
            ))}
          </div>
          <div className="mt-9">
            <AttachmentViewer
              {...attachmentViewerProps}
              maxPageNo={MAX_NUM_OF_IMAGES}
            />
          </div>
        </>
      )}
    </div>
  );
};
