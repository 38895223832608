import classNames from "classnames";
import NaviMenuToggleContainer from "../../../containers/NaviMenuToggleContainer";
import Icon from "../../atoms/Icon";
import styles from "./styles.scss";

interface Props {
  opened: boolean;
  onToggle: (e: any) => void;
}

const PresentationalComponent = (props: Props) => {
  return (
    <button
      className={classNames(styles.root, { [styles.opened]: props.opened })}
      onClick={props.onToggle}
    >
      <div className={styles.iconWrapper}>
        <Icon name="icon-arrow-open" className={styles.iconOpen} />
        <Icon name="icon-arrow-close" className={styles.iconClose} />
      </div>
      <div className={styles.label}>閉じる</div>
    </button>
  );
};

const NaviMenuToggleButton = () => (
  <NaviMenuToggleContainer presentationalComponent={PresentationalComponent} />
);

export default NaviMenuToggleButton;
