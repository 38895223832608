import * as React from "react";
import { BlockRow } from "../../../components/atoms/BlockRow";
import Loader from "../../../components/atoms/Loader";
import TabsNavi from "../../../components/molecules/TabsNavi";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import enhanceSectionsSettingsPage, {
  SectionsSettingsPageProps,
} from "../../../hocs/enhanceSectionsSettingsPage";
import { useFetchContentCourseResults } from "../../../hooks/http/useFetchContentCourseResults";
import { useQueryError } from "../../../hooks/http/useQueryError";
import SectionInterface from "../../../interfaces/SectionInterface";
import { CourseResultTable } from "./CourseResultTable";

type Props = SectionsSettingsPageProps &
  OutletContextProps & {
    section: SectionInterface;
  };

const CourseResults = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("courses");
  }, []);
  const { data, isLoading, error } = useFetchContentCourseResults({
    sectionId: props.section.id,
  });
  useQueryError(error);

  return (
    <>
      <TabsNavi
        tabs={[
          {
            label: "コンテンツ",
            active: false,
            link: `/sections/${props.section.id}/settings/contents`,
          },
          {
            label: "コース",
            active: false,
            link: `/sections/${props.section.id}/settings/content_courses`,
          },
          {
            label: "学習結果",
            active: true,
            link: `/sections/${props.section.id}/settings/content_courses/results`,
          },
        ]}
      />
      <BlockRow marginTop="2.4rem">
        {data ? (
          <CourseResultTable section={props.section} courseResults={data} />
        ) : null}
      </BlockRow>
      <Loader loading={isLoading} />
    </>
  );
};

const pageInfo = {
  title: "学習結果一覧",
};

export default enhanceSectionsSettingsPage(CourseResults, pageInfo);
