import { useState } from "react";
import * as React from "react";

import SplitLayout, { Column } from "../../components/atoms/SplitLayout";
import GuardianMessage from "../../components/features/GuardianMessage";
import StudentFilter from "../../components/features/NewStudentFilter";
import TabsNavi from "../../components/molecules/TabsNavi";
import { useFetchStudentGuardianMessages } from "../../hooks/http/useFetchStudentGuardianMessages";
import { useQueryError } from "../../hooks/http/useQueryError";
import ApiResponse from "../../interfaces/ApiResponse";
import GuardianMessageInterface from "../../interfaces/GuardianMessageInterface";
import GuardianMessageThreadInterface from "../../interfaces/GuardianMessageThreadInterface";
import { OperatorPrivilegeInfoInterface } from "../../interfaces/OperatorInterface";
import SectionInterface from "../../interfaces/SectionInterface";
import SectionGuardianMessageThreadList from "./SectionGuardianMessageThreadList";
import styles from "./SectionGuardianMessages.scss";

interface Props {
  section: SectionInterface;
  operatorPrivilege: OperatorPrivilegeInfoInterface | null;
  guardianMessageThreadsProps: GuardianMessageThreadsProps;
  onLoadMoreThreads: () => void;
  openGuardianMessageThread: (studentId: string) => void;
}

export type GuardianMessageThreadsProps = ApiResponse<
  GuardianMessageThreadInterface[] | null
> & {
  loading: boolean;
  hasMore: boolean;
  openedStudentId: string | null;
  updateCurrentThreadLatestMessage: (
    guardianMessage: GuardianMessageInterface,
  ) => void;
};

const SectionGuardianMessages: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const [focusedColumn, setFocus] = useState(Column.Left);
  const {
    currentGuardianMessageThread,
    guardianMessageProps,
    loadMoreMessages,
  } = useSectionGuardianMessages(props);

  return (
    <React.Fragment>
      <TabsNavi tabs={displayAnnounceMessagesTab(props)} />
      <StudentFilter sectionId={props.section.id} />
      <div className={styles.message}>
        <SplitLayout focusedColumn={focusedColumn}>
          <SplitLayout.Navigation focusedColumn={focusedColumn}>
            <SectionGuardianMessageThreadList
              guardianMessageThreadsProps={props.guardianMessageThreadsProps}
              onLoadMore={props.onLoadMoreThreads}
              onClickThread={(destinationId: string) => () => {
                setFocus(Column.Right);
                props.openGuardianMessageThread(destinationId);
              }}
            />
          </SplitLayout.Navigation>
          {currentGuardianMessageThread && guardianMessageProps.data && (
            <GuardianMessage
              student={currentGuardianMessageThread.student}
              guardianMessagesProps={guardianMessageProps}
              onCloseRightColumn={() => setFocus(Column.Left)}
              loadMoreMessages={loadMoreMessages}
              updateCurrentThreadLatestMessage={
                props.guardianMessageThreadsProps
                  .updateCurrentThreadLatestMessage
              }
            />
          )}
        </SplitLayout>
      </div>
    </React.Fragment>
  );
};

const useSectionGuardianMessages = (props: Props) => {
  const [currentGuardianMessageThread, setCurrentGuardianMessageThread] =
    useState<GuardianMessageThreadInterface | null>(
      getCurrentGuardianMessageThread(props.guardianMessageThreadsProps),
    );
  React.useEffect(() => {
    setCurrentGuardianMessageThread(
      getCurrentGuardianMessageThread(props.guardianMessageThreadsProps),
    );
  }, [props.guardianMessageThreadsProps.openedStudentId]);

  const {
    data,
    meta,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
  } = useFetchStudentGuardianMessages({
    studentId: props.guardianMessageThreadsProps.openedStudentId || "",
  });
  useQueryError(error);

  const guardianMessageProps = {
    data: data || [],
    meta: meta || { till: undefined },
    loading: isLoading || isFetchingNextPage,
    hasMore: hasNextPage || false,
  };

  return {
    currentGuardianMessageThread,
    guardianMessageProps,
    loadMoreMessages: fetchNextPage,
  };
};

const getCurrentGuardianMessageThread = (
  guardianMessageThreadsProps: Props["guardianMessageThreadsProps"],
) => {
  if (guardianMessageThreadsProps.data) {
    return (
      guardianMessageThreadsProps.data.find(
        (thread: GuardianMessageThreadInterface) =>
          thread.student.id === guardianMessageThreadsProps.openedStudentId,
      ) || null
    );
  } else {
    return null;
  }
};

const displayAnnounceMessagesTab = (props: Props) => {
  const { operatorPrivilege, section } = props;
  const tabs = [
    {
      label: "保護者",
      link: {
        pathname: `/sections/${section.id}/guardian_message_threads`,
        search: location.search,
      },
      active: true,
    },
  ];

  if (
    operatorPrivilege?.role === "admin" ||
    operatorPrivilege?.role === "unlimited_member"
  ) {
    tabs.push({
      label: "一斉送信",
      link: {
        pathname: `/sections/${section.id}/tags/guardian_messages`,
        search: location.search,
      },
      active: false,
    });
  }

  return tabs;
};

export default SectionGuardianMessages;
