import { useInfiniteQuery } from "@tanstack/react-query";
import ApiClient from "../../api";
import { SectionExaminationDetail } from "../../domains/SectionExamination";
import { HTTPErrors, createError } from "../../errors";
import { StudentFilterParams } from "../../helpers/FiltersHelper";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { AnalyticsFilter } from "../../hooks/filters/useAnalyticsFilter";
import ApiResponse from "../../interfaces/ApiResponse";

export type displayItemType = "point" | "deviation";

type Props = {
  sectionId: string;
  examinationId: string | null;
  displayItem: displayItemType;
  studentFilterParams: StudentFilterParams;
  analyticsFilter: AnalyticsFilter;
};
type ResponseData = ApiResponse<SectionExaminationDetail>;

export const useFetchSectionExaminationDetail = ({
  sectionId,
  examinationId,
  displayItem,
  studentFilterParams,
  analyticsFilter,
}: Props) => {
  const result = useInfiniteQuery<ResponseData, HTTPErrors>({
    queryKey: cacheKeyOf(
      sectionId,
      examinationId,
      displayItem,
      studentFilterParams,
      analyticsFilter,
    ),
    queryFn: async ({ pageParam = 1 }) => {
      if (!examinationId) {
        throw new Error(
          "examinationId is required to fetch examination result",
        );
      }

      const response = await ApiClient.get(
        `${baseURLOf(sectionId, examinationId)}`,
        {
          query: {
            page: pageParam,
            display_item: displayItem,
            ...studentFilterParams,
            order: analyticsFilter.order,
            order_dir: analyticsFilter.orderDir,
          },
          skipNullQuery: true,
        },
      );
      if (response.ok) {
        const json = (await response.json()) as {
          examinationResultsAnalyticsTable: ResponseData;
        };
        return json.examinationResultsAnalyticsTable;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });
  const data = result.data?.pages.flatMap((page) => page.data);
  return { ...result, data };
};

export const cacheKeyOf = (
  sectionId: string,
  examinationId: string | null,
  displayItem: displayItemType,
  studentFilterParams: StudentFilterParams,
  analyticsFilter: AnalyticsFilter,
) =>
  [
    sectionId,
    "analytics",
    "examinations",
    examinationId,
    displayItem,
    studentFilterParams,
    analyticsFilter,
  ].filter((k) => k !== null);

export const baseURLOf = (sectionId: string, examinationId: string) =>
  `/api/v1/sections/${sectionId}/analytics/examinations/${examinationId}`;
