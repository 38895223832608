import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
} from "react-beautiful-dnd";
import { BlockRow } from "../../../../components/atoms/BlockRow/index";
import ErrorText from "../../../../components/atoms/ErrorText";
import { Flex } from "../../../../components/atoms/Flex/index";
import Icon from "../../../../components/atoms/Icon";
import Input from "../../../../components/atoms/Input";
import Label from "../../../../components/atoms/Label";
import Radio from "../../../../components/atoms/Radio";
import { SingleDatePickerField } from "../../../../components/atoms/SingleDatePickerField";
import Textarea from "../../../../components/atoms/Textarea/index";
import { TimeInput } from "../../../../components/atoms/TimeInput";
import { usePrompt } from "../../../../hooks/usePrompt";
import LtiDeploymentInterface from "../../../../interfaces/LtiDeploymentInterface";
import { ContentUnitForm } from "./ContentUnitForm";
import { ContentsSelectModal } from "./ContentsSelectModal";
import styles from "./styles.scss";
import {
  ContentCourseFormProps,
  useContentCourseForm,
} from "./useContentCourseForm";
import { THUMBNAIL_FILETYPES, UnitValue } from "./useContentCourseFormState";

type Props = ContentCourseFormProps & {
  ltiDeployments: LtiDeploymentInterface[];
};

export const ContentCourseForm = (props: Props) => {
  const form = useContentCourseForm(props);
  usePrompt(
    "登録している内容が削除されますが、このページから移動してもよろしいですか？",
    form.dirty && !form.isSubmitting,
  );

  return (
    <>
      <BlockRow marginTop="0.2rem">
        <form onSubmit={form.handleSubmit}>
          <Flex justifyContent="flex-start" marginTop="0">
            <BlockRow marginTop="2.4rem">
              <ThumbnailField
                form={form}
                registeredImageUrl={props.course?.thumbnailUrl}
              />
            </BlockRow>
            <CourseInfoFields form={form} />
          </Flex>

          <h3 className={styles.unitHeader}>ユニット</h3>
          <DragDropContext onDragEnd={form.onDragEnd}>
            <Droppable droppableId="units" type="unit">
              {(provided) => (
                <div ref={provided.innerRef}>
                  {Object.values(form.values.units).map(
                    (unit: UnitValue, index: number) => (
                      <Draggable
                        key={`UnitForm-${unit.id}`}
                        draggableId={unit.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <BlockRow marginTop="1.6rem">
                              <ContentUnitForm
                                unit={unit}
                                onChange={form.handleChange}
                                onDelete={form.removeUnit}
                                onBlur={form.handleBlur}
                                onRemoveContent={form.removeContentFromUnit}
                                errors={form.errors.units?.[unit.id] || {}}
                                touched={form.touched.units?.[unit.id] || {}}
                                onOpenContentsSelectModal={() => {
                                  form.openContentsSelectModal(unit.id);
                                }}
                                dragHandleProps={
                                  provided.dragHandleProps as
                                    | DraggableProvidedDragHandleProps
                                    | undefined
                                }
                                contentDraggableIdFor={
                                  form.contentDraggableIdFor
                                }
                              />
                            </BlockRow>
                          </div>
                        )}
                      </Draggable>
                    ),
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <BlockRow marginTop="1.6rem">
            <button
              className={styles.unitBtn}
              onClick={form.addUnit}
              type="button"
            >
              <Flex alignItems={"center"} justifyContent="center" marginTop="0">
                <Icon name="icon-plus-tag" />
                新しいユニットを追加
              </Flex>
            </button>
          </BlockRow>

          <Flex justifyContent="flex-end" marginTop="2.4rem">
            <Button
              type="submit"
              className={styles.submitButton}
              disabled={!form.isValid}
              isLoading={form.isSubmitting}
            >
              {props.course ? "更新" : "登録"}
            </Button>
          </Flex>
        </form>
      </BlockRow>
      <ContentsSelectModal
        isOpen={form.contentsSelectModalState.isOpened}
        onAddContents={form.addContentsToUnit}
        unitId={form.contentsSelectModalState.unitId}
        sectionId={props.sectionId}
        ltiDeployments={props.ltiDeployments}
        onClose={form.closeContentsSelectModal}
        initialCheckedContents={
          form.contentsSelectModalState.unitId
            ? Object.values(
                form.values.units[form.contentsSelectModalState.unitId]
                  .contents,
              )
            : []
        }
      />
    </>
  );
};

const ThumbnailField = ({
  form,
  registeredImageUrl,
}: {
  form: ReturnType<typeof useContentCourseForm>;
  registeredImageUrl?: string | null;
}) => {
  const [previewImageUrl, setPreviewImageUrl] = React.useState<string | null>(
    null,
  );
  const handleChangeThumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (THUMBNAIL_FILETYPES.includes(file.type)) {
        const fileUrl = URL.createObjectURL(file);
        setPreviewImageUrl(fileUrl);
      }
      form.setFieldValue("thumbnail", file);
    }
  };

  return (
    <label className={styles.thumbnail} htmlFor="courseThumbnail">
      <input
        id="courseThumbnail"
        type="file"
        name="thumbnail"
        accept={THUMBNAIL_FILETYPES.join(",")}
        className={styles.thumbnail__input}
        onChange={handleChangeThumbnail}
      />
      <ThumbnailImage
        url={previewImageUrl ?? registeredImageUrl}
        name={form.values.thumbnail?.name}
      />
      <BlockRow marginTop="1.6rem">
        <div className={styles.thumbnail__label}>サムネイルを変更</div>
      </BlockRow>
      <ErrorText>{form.errors.thumbnail}</ErrorText>
    </label>
  );
};
const ThumbnailImage = ({
  url,
  name,
}: {
  url?: string | null;
  name?: string;
}) =>
  url ? (
    <ThumbnailImagePreview url={url} name={name || url} />
  ) : (
    <ThumbnailImagePlaceholder />
  );
const ThumbnailImagePreview = (props: { url: string; name: string }) => (
  <img src={props.url} className={styles.thumbnail__image} alt={props.name} />
);
const ThumbnailImagePlaceholder = () => (
  <div className={styles.thumbnail__empty}>
    <Icon name="icon-picture-img" />
  </div>
);

const CourseInfoFields = ({
  form,
}: {
  form: ReturnType<typeof useContentCourseForm>;
}) => {
  return (
    <div className={styles.courseInfo}>
      <BlockRow marginTop="0">
        <Label htmlFor="courseName" isMute>
          コース名
        </Label>
        <Input
          id="courseName"
          name="name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          hasError={Boolean(form.errors["name"]) && form.touched["name"]}
          placeholder="名称を入力"
          value={form.values.name}
        />
        {Boolean(form.errors["name"]) && form.touched["name"] ? (
          <ErrorText>{form.errors["name"]}</ErrorText>
        ) : null}
      </BlockRow>
      <BlockRow marginTop="1rem">
        <Label htmlFor="courseDescription" isMute>
          コース説明文
        </Label>
        <Textarea
          id="courseDescription"
          name="description"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          hasError={
            Boolean(form.errors["description"]) && form.touched["description"]
          }
          placeholder="説明を入力"
          value={form.values.description}
        />
        {Boolean(form.errors["description"]) && form.touched["description"] ? (
          <ErrorText>{form.errors["description"]}</ErrorText>
        ) : null}
      </BlockRow>
      <BlockRow marginTop="1rem">
        <Label htmlFor="courseDuration" isMute>
          配信期間
        </Label>
      </BlockRow>
      <BlockRow marginTop="1rem">
        <Radio
          id="courseIsSetDuration_false"
          name="isSetDuration"
          checked={!form.values.isSetDuration}
          label="期間指定なし"
          weight="normal"
          onChange={() => form.setFieldValue("isSetDuration", false)}
          className={styles.duration__radio}
        />
        <Radio
          id="courseIsSetDuration_true"
          name="isSetDuration"
          checked={form.values.isSetDuration}
          label=""
          onChange={() => form.setFieldValue("isSetDuration", true)}
          className={styles.duration__radio}
        >
          <Flex marginTop="0" alignItems="center">
            <SingleDatePickerField
              value={form.values.startDate}
              onDateChange={(m) => form.setFieldValue("startDate", m)}
              className={styles.duration__date}
              disabled={!form.values.isSetDuration}
            />
            <div className={styles.durationPicker}>
              <TimeInput
                name="startTime"
                value={form.values.startTime}
                onChange={form.handleChange}
                aria-label="開始日時"
                disabled={!form.values.isSetDuration}
              />
            </div>
            <span className={styles.duration__tilde}>〜</span>
            <SingleDatePickerField
              value={form.values.endDate}
              onDateChange={(m) => form.setFieldValue("endDate", m)}
              className={styles.duration__date}
              disabled={!form.values.isSetDuration}
            />
            <div className={styles.durationPicker}>
              <TimeInput
                name="endTime"
                value={form.values.endTime}
                onChange={form.handleChange}
                aria-label="終了日時"
                disabled={!form.values.isSetDuration}
              />
            </div>
          </Flex>
        </Radio>
        {form.dirty && form.errors.startTime ? (
          <ErrorText>{form.errors.startTime}</ErrorText>
        ) : null}
      </BlockRow>
    </div>
  );
};
