import classNames from "classnames";
import { Link } from "react-router-dom";
import {
  confirmedAll,
  translateGuardianEmailStatuses,
} from "../../domains/GuardianEmail";
import { OperatorRole } from "../../interfaces/OperatorInterface";
import StudentInterface from "../../interfaces/StudentInterface";
import styles from "./SectionsSettingsStudentsIndex.scss";
import { EntryQR } from "./StudentItem/EntryQR";
import { GuardianEmailFormModal } from "./StudentItem/GuardianEmailFormModal/GuardianEmailFormModal";
import { LineCode } from "./StudentItem/LineCode";
import { LineCodeStatus } from "./StudentItem/LineCodeStatus";
import { Passcode } from "./StudentItem/Passcode";
import { StudentBillingPlan } from "./StudentItem/StudentBillingPlan/StudentBillingPlan";
import { StudentItemDropdown } from "./StudentItem/StudentItemDropdown";

interface Props {
  student: StudentInterface;
  updateStudent: (student: StudentInterface) => void;
  removeStudent: (studentId: string) => void;
  operatorRole: OperatorRole;
  guardianLineConnectionUsable: boolean;
  isGuardianEmailVisible: boolean;
}

export const StudentItem = (props: Props) => {
  const { student, updateStudent, removeStudent } = props;

  return (
    <tr>
      <td>{student.code}</td>
      <td className={styles.name}>
        <Link to={`/students/${student.id}`}>{student.fullName}</Link>
      </td>
      <td className={styles.jobCell}>{student.jobHuman}</td>
      <td>
        <StudentBillingPlan student={student} updateStudent={updateStudent} />
      </td>
      <td className={styles.passcodeCell}>
        <Passcode student={student} updateStudent={updateStudent} />
      </td>
      <td className={styles.passcodeStatusCell}>
        <span
          className={classNames(styles.passcodeStatus, {
            [styles.active]: student.status === "active",
          })}
        >
          {student.statusHuman}
        </span>
      </td>
      <td className={styles.center}>
        <EntryQR student={student} />
      </td>
      {props.isGuardianEmailVisible && (
        <>
          <td className={styles.guardianEmailCell}>
            <GuardianEmailFormModal
              student={student}
              updateStudent={updateStudent}
            />
          </td>
          <td className={styles.guardianEmailStatusCell}>
            <span
              className={classNames(styles.guardianStatus, {
                [styles.active]: confirmedAll(student.guardianEmails),
              })}
            >
              {translateGuardianEmailStatuses(student.guardianEmails)}
            </span>
          </td>
        </>
      )}
      {props.guardianLineConnectionUsable && (
        <>
          <td className={styles.lineCodeCell}>
            <div className={styles.lineCode}>
              <LineCode student={student} updateStudent={updateStudent} />
            </div>
          </td>
          <td className={styles.lineCodeStatusCell}>
            <LineCodeStatus student={student} />
          </td>
        </>
      )}
      <td className={styles.dropdownCell}>
        <StudentItemDropdown
          student={student}
          operatorRole={props.operatorRole}
          guardianLineConnectionUsable={props.guardianLineConnectionUsable}
          updateStudent={updateStudent}
          removeStudent={removeStudent}
        />
      </td>
    </tr>
  );
};
