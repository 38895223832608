import { useFetchStudentMessages } from "../../../hooks/http/useFetchStudentMessages.ts";
import { useQueryError } from "../../../hooks/http/useQueryError.ts";
import StudentInterface from "../../../interfaces/StudentInterface.ts";
import MessageList from "../MessageList";
import { useChangeBotMessageStatus } from "./useChangeBotMessageStatus.tsx";
import { useDeleteMessage } from "./useDeleteMessage.tsx";

export const StudentMessageList = ({
  sectionId,
  student,
}: {
  sectionId: string;
  student: Omit<StudentInterface, "billingPlan">;
}) => {
  const { isLoading, error, isFetchingNextPage, fetchNextPage, messages } =
    useFetchStudentMessages({
      studentId: student.id,
    });
  useQueryError(error);

  const deleteMessage = useDeleteMessage({
    sectionId: sectionId,
  });

  const { changeBotMessageStatusDone, changeBotMessageStatusIgnored } =
    useChangeBotMessageStatus();

  const handleLoadMore = () => {
    fetchNextPage();
  };

  return (
    <MessageList
      student={student}
      messages={messages}
      isLoading={isLoading || isFetchingNextPage}
      loadMore={handleLoadMore}
      changeBotMessageStatusDone={changeBotMessageStatusDone}
      changeBotMessageStatusIgnored={changeBotMessageStatusIgnored}
      deleteMessage={deleteMessage}
    />
  );
};
