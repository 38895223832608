import addDays from "date-fns/addDays";
import setDay from "date-fns/setDay";
import startOfISOWeek from "date-fns/startOfISOWeek";
import startOfWeek from "date-fns/startOfWeek";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { toQueryString } from "../../../helpers/TimeHelper";
import { CalendarType } from "../../atoms/Calendar";

export type UseStudentCalendarHeaderProps = {
  startDate?: Date;
  onClickAddEvent?: () => void;
  calendarType: CalendarType;
};

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

// eslint-disable-next-line
export const useStudentCalendarHeader = (
  props: UseStudentCalendarHeaderProps,
) => {
  const navigate = useNavigate();
  const prevCalendarType = React.useRef(props.calendarType);

  const range = React.useMemo(
    () => calcDateRange(props.startDate),
    [props.startDate],
  );

  const onChangeDateRange = React.useCallback(
    (dates: { startDate?: Date; endDate?: Date }) => {
      if (dates.startDate) {
        const date = toQueryString(dates.startDate);
        const type = toStringFromCalendarType(props.calendarType);
        navigate(`.?type=${type}&date=${date}`);
      }
    },
    [props.calendarType],
  );

  const onClickToday = React.useCallback((): void => {
    const startDate =
      props.calendarType === "Daily" ? new Date() : startOfISOWeek(new Date());
    const date = toQueryString(startDate);
    navigate(
      `.?type=${toStringFromCalendarType(props.calendarType)}&date=${date}`,
    );
  }, [props.calendarType, range]);

  const onChangeCalendarType = React.useCallback(
    (newType: CalendarType) => {
      if (newType === prevCalendarType.current) {
        return;
      }
      const startDate =
        newType === "Daily"
          ? determineStartDateSwitchDaily(range.startDate)
          : startOfISOWeek(range.startDate);
      const date = toQueryString(startDate);
      const type = toStringFromCalendarType(newType);
      prevCalendarType.current = newType;
      navigate(`.?type=${type}&date=${date}`);
    },
    [props.calendarType, range],
  );

  const onChangeSingleDate = React.useCallback(
    (date?: Date) => {
      if (date) {
        navigate(`.?type=${props.calendarType}&date=${toQueryString(date)}`);
      }
    },
    [props.calendarType],
  );

  return {
    startDate: range.startDate,
    endDate: range.endDate,
    onChangeDateRange,
    onChangeSingleDate,
    onClickToday,
    onChangeCalendarType,
  };
};

const calcDateRange = (startDate?: Date): DateRange => {
  const date = startDate ?? new Date();
  return { startDate: date, endDate: addDays(date, 6) };
};

const toStringFromCalendarType = (type: CalendarType) =>
  type === "Daily" ? "daily" : "weekly";

const determineStartDateSwitchDaily = (date: Date) => {
  const day = new Date().getDay();
  return setDay(startOfWeek(date), day);
};
