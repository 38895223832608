import { ErrorMessage, Field, Formik, FormikProps } from "formik";
import * as yup from "yup";

import { Button } from "@studyplus/boron-ui";
import Input from "../../../../components/atoms/Input";
import Label from "../../../../components/atoms/Label";
import { ImportHistoryInterface } from "../../../../domains/ImportHistory";
import { WithRouterProps, withRouter } from "../../../../helpers/RouterHelper";
import SectionInterface from "../../../../interfaces/SectionInterface";
import styles from "./styles.scss";
import { useUploadForm } from "./useUploadForm";

interface OwnProps extends WithRouterProps {
  section: SectionInterface;
  operation: ImportHistoryInterface["operation"];
}

type Props = OwnProps;

export type Values = {
  csv: File | undefined;
  zip: File | undefined;
};

const validationSchema = (requireZip: boolean) => {
  return yup.object().shape({
    csv: yup.mixed().required("CSVファイルは必須です"),
    zip: requireZip
      ? yup.mixed().required("ZIP画像ファイルは必須です")
      : yup.mixed(),
  });
};

const UploadForm = (props: Props): JSX.Element => {
  const { section, operation } = props;

  const { requireZip, handleSubmit } = useUploadForm({
    sectionId: section.id,
    operation,
  });

  const title = requireZip
    ? "情報を保存したCSVファイルと画像のZIPファイルをアップロード"
    : "情報を保存したCSVファイルをアップロード";

  return (
    <div className={styles.box}>
      <div className={styles.boxHeading}>
        <span className={styles.boxHeadingTitle}>{`2. ${title}`}</span>
        <span className={styles.notice}>
          ※エクセル形式ではインポートできませんので、必ずCSV形式で保存してください。
        </span>
      </div>
      <div className={styles.buttons}>
        <Formik
          initialValues={{ csv: undefined, zip: undefined } as Values}
          validationSchema={validationSchema(requireZip)}
          onSubmit={handleSubmit}
        >
          {(formikProps) => <Form requireZip={requireZip} {...formikProps} />}
        </Formik>
      </div>
    </div>
  );
};

const Form = ({
  requireZip,
  ...props
}: {
  requireZip: boolean;
} & FormikProps<Values>): JSX.Element => {
  return (
    <form className={styles.uploadForm} onSubmit={props.handleSubmit}>
      <div className={styles.uploadFormFields}>
        <Label className={styles.uploadFormLabel} htmlFor="csv">
          CSVファイル
        </Label>
        <Field
          id="csv"
          component={Input}
          name="csv"
          type="file"
          accept=".csv,text/csv"
          onChange={handleChangeFile(props, "csv")}
        />
        <ErrorMessage
          name="csv"
          component="div"
          className={styles.errorMessage}
        />
        {requireZip ? (
          <>
            <Label className={styles.uploadFormLabel} htmlFor="zip">
              画像ZIPファイル
            </Label>
            <Field
              id="zip"
              component={Input}
              name="zip"
              type="file"
              accept=".zip,application/zip"
              onChange={handleChangeFile(props, "zip")}
            />
            <ErrorMessage
              name="zip"
              component="div"
              className={styles.errorMessage}
            />
          </>
        ) : null}
      </div>
      <div className={styles.uploadFormButton}>
        <Button
          disabled={!props.isValid || props.isSubmitting}
          isBlock={true}
          type="submit"
        >
          インポート
        </Button>
      </div>
    </form>
  );
};

const handleChangeFile =
  (props: FormikProps<Values>, fieldName: string) =>
  (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { setFieldTouched, setFieldValue } = props;
    if (e.currentTarget && e.currentTarget.files) {
      setFieldValue(fieldName, e.currentTarget.files[0]);
      setFieldTouched(fieldName, true, false);
    } else {
      setFieldValue(fieldName, undefined);
      setFieldTouched(fieldName, true, false);
    }
  };

export default withRouter<Props>(UploadForm);
