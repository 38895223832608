import classNames from "classnames";
import { differenceInDays } from "date-fns";
import { ErrorMessage, useField } from "formik";
import { DateRangePickerField } from "../../atoms/DateRangePickerField";
import { useMultiSectionBatchesDateRangePicker } from "./useMultiSectionBatchesDateRangePicker";

export const MultiSectionBatchesDownloadDateRangePicker = () => {
  const [field, meta, helpers] = useField("dateRange");
  const { value: dateRange } = field;
  const { touched, error } = meta;
  const { setValue } = helpers;
  const { disabled } = useMultiSectionBatchesDateRangePicker(dateRange);

  return (
    <div
      className={classNames(
        "flex flex-col gap-8 rounded-lg bg-[#fafafa] px-10 py-[22px]",
        {
          "border border-solid border-red-400": touched && error,
        },
      )}
    >
      <div>
        <p>2. 出力したい期間を選択してください</p>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-8">
          <span
            className="w-[30rem]"
            onClick={() => {
              if (dateRange.startDate && dateRange.endDate) {
                setValue({});
              }
            }}
          >
            <DateRangePickerField
              dateRange={dateRange}
              onChangeDateRange={(dates) => {
                setValue(dates);
              }}
              placeholder="出力期間を選択"
              disabled={disabled}
              className="bg-white"
              fromDate={new Date(2024, 3, 1)} // NOTE: 2024/4/1 以降の日付を選択可能にする
            />
          </span>
          {dateRange.startDate !== undefined &&
            dateRange.endDate !== undefined && (
              <p className="font-bold">
                の{differenceInDays(dateRange.endDate, dateRange.startDate) + 1}
                日間
              </p>
            )}
        </div>
      </div>
      <ErrorMessage
        name="dateRange"
        component="div"
        className="m-4 text-red-400"
      />
    </div>
  );
};
