import { Button } from "@studyplus/boron-ui";
import classNames from "classnames";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import DeviceHelper from "../../../helpers/DeviceHelper";
import FileHelper from "../../../helpers/FileHelper";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import { MessageFormStateInterface } from "../../../interfaces/MessageInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface";
import { PostZoomMeetingMessageParams } from "../../../interfaces/PostZoomMeetingMessageParams";
import InputIcon from "../../atoms/InputIcon";
import ResumableTextarea from "../../atoms/ResumableTextarea";
import { useResumableTextarea } from "../../atoms/ResumableTextarea/useResumableTextarea";
import Textarea from "../../atoms/Textarea/index";
import DeprecatedTagButton from "../../features/DeprecatedTagButton/index";
import TooltipIconButton from "../TooltipIcon/index";
import ZoomModal from "./ZoomModal/index";
import styles from "./styles.scss";

const mimeTypes = {
  jpg: "image/jpeg",
  gif: "image/gif",
  png: "image/png",
  pdf: "application/pdf",
};

type FileType = keyof typeof mimeTypes;

export interface MessageDestinationInterface {
  path: string;
  name: string;
}

interface Props extends WithRouterProps {
  formState?: MessageFormStateInterface;
  canSendMessage: boolean;
  allowedFileTypes: FileType[];
  disabledPlaceholder: string;
  postMessage: (content: string) => void;
  postMessageFile: (file: File) => void;
  changeFormContent: (content: string) => void;
  changeFormFile: (file: File | null) => void;
  rows?: number;
  storageKey: string;

  // Zoom関係のprops
  // FIXME: Zoom関係の責務は別コンポーネントに切り出したい
  destination?: MessageDestinationInterface | null;
  operator?: OperatorInterface;
  operatorProfile?: OperatorProfileInterface | null;
  postZoomMeetingMessage?: (
    params: PostZoomMeetingMessageParams,
    setSubmitting: (submitting: boolean) => void,
    setErrors: (errors: Record<string, unknown>) => void,
    onSuccessCallback: () => void,
  ) => void;
  defaultZoomMeetingContent?: string;
}

const resumeMessage =
  "編集途中のメッセージを復元しますか？\n（「キャンセル」を選択すると復元内容が破棄されます。）";

const renderApiErrors = (apiErrors: ApiErrorInterface[]) => {
  if (apiErrors.length > 0) {
    return (
      <div className={styles.errors}>
        {apiErrors.map((error: ApiErrorInterface, i: number) => {
          return (
            <p key={`MessageForm-apiErrors-${i}`} className={styles.error}>
              {error.message}
            </p>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

const ContentField: React.FC<Props> = (props) => {
  const { formState, storageKey, changeFormContent, canSendMessage } = props;

  const { clearValue: clearContentStorage, ...resumableTextareaProps } =
    useResumableTextarea({
      value: formState?.values.content || "",
      key: storageKey,
      confirmMessage: resumeMessage,
      onResume: changeFormContent,
    });

  const prevSubmitting = useRef(formState?.submitting);

  useEffect(() => {
    if (formState) {
      if (
        prevSubmitting.current &&
        !formState.submitting &&
        formState.apiErrors.length === 0
      ) {
        clearContentStorage();
      }
      prevSubmitting.current = formState.submitting;
    }
  }, [formState?.submitting]);

  if (!formState) {
    return (
      <Textarea
        name="content"
        disabled={true}
        placeholder={props.disabledPlaceholder}
        value={""}
        rows={props.rows || 1}
        className={styles.content__inputDisabled}
      />
    );
  }

  const { file } = formState.values;

  if (!canSendMessage) {
    return (
      <Textarea
        name="content"
        disabled={true}
        placeholder={props.disabledPlaceholder}
        value={""}
        rows={props.rows || 1}
        className={styles.content__inputDisabled}
      />
    );
  }

  if (file) {
    return renderFileName(props);
  } else {
    return (
      <ResumableTextarea
        name="content"
        hasError={formState.apiErrors.length > 0}
        placeholder="メッセージを入力"
        value={formState.values.content}
        onChange={handleChangeInput(props)}
        rows={props.rows || 1}
        className={styles.content__input}
        {...resumableTextareaProps}
      />
    );
  }
};

const handleChangeInput = (props: Props) => (e: React.ChangeEvent<any>) => {
  props.changeFormContent(e.target.value);
};

const isValid = (formState: MessageFormStateInterface): boolean => {
  const { content, file } = formState.values;

  return !!content || !!file;
};

const handleFileDelete = (props: Props) => () => {
  props.changeFormFile(null);
};

const renderFileName = (props: Props) => {
  if (!props.formState) {
    return null;
  }

  const { file } = props.formState.values;

  if (file) {
    return (
      <div
        className={classNames(styles.content__file, {
          [styles.fieldError]: props.formState.apiErrors.length > 0,
        })}
      >
        <DeprecatedTagButton
          label={FileHelper.truncateFilename(file.name, 30)}
          iconName="icon-close-x"
          theme="lightgray"
          onClick={handleFileDelete(props)}
          dataTestId={"common-message-form-file-delete-button"}
        />
      </div>
    );
  } else {
    return null;
  }
};

const AttachmentIcon = ({
  allowedFileTypes,
  formState,
  changeFormFile,
}: Props) => {
  const handleChangeFileAttachment = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!e.currentTarget.files) return;

    const file = e.currentTarget.files[0];
    changeFormFile(file);
  };

  return (
    <InputIcon
      iconName="icon-picture-img"
      inputAttributes={{
        accept: allowedFileTypes.map((t) => mimeTypes[t]).join(","),
        id: "message_image",
        type: "file",
      }}
      className={styles.imageIcon}
      tooltipLabel={`ファイルの添付( ${allowedFileTypes
        .map((t) => t)
        .join(",")} )`}
      onChange={handleChangeFileAttachment}
      disabled={!!formState?.values.content || formState?.submitting}
    />
  );
};

const CommonMessageForm: React.FC<Props> = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClickIcon = () => {
    if (props.operatorProfile && props.operatorProfile.zoomConnected) {
      setIsModalOpen(true);
    } else {
      const zoomConnectSettingUrl = `/settings/profile`;
      props.navigate(zoomConnectSettingUrl);
    }
  };

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      const { formState } = props;

      if (!formState) {
        return;
      }

      if (formState.values.file) {
        props.postMessageFile(formState.values.file);
      } else {
        props.postMessage(formState.values.content);
      }
    },
    [props.formState, props.postMessage, props.postMessageFile],
  );

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handlePostZoomMeetingMessage = (
    params: PostZoomMeetingMessageParams,
    setSubmitting: (submitting: boolean) => void,
    setErrors: (errors: Record<string, unknown>) => void,
    onSuccessCallback: () => void,
  ) => {
    if (props.postZoomMeetingMessage) {
      props.postZoomMeetingMessage(
        params,
        setSubmitting,
        setErrors,
        onSuccessCallback,
      );
    }
  };

  if (!props.formState) {
    return (
      <form className={styles.root} onSubmit={handleSubmit}>
        <div className={styles.content}>
          <ContentField {...props} />
        </div>
        <div className={styles.button}>
          <Button type="submit" disabled={true} isBlock={true}>
            送信
          </Button>
        </div>
      </form>
    );
  }

  return (
    <React.Fragment>
      <form className={styles.root} onSubmit={handleSubmit}>
        {DeviceHelper.isPC()
          ? null
          : renderApiErrors(props.formState.apiErrors)}
        <div className={styles.content}>
          <ContentField {...props} />
          {props.canSendMessage && (
            <>
              {props.operatorProfile && props.operator && props.destination && (
                <TooltipIconButton
                  id={"common-message-form-zoom-icon"}
                  iconName="icon-zoom"
                  className={styles.zoomIcon}
                  tooltipLabel={"ZoomのミーティングURLを自動発行"}
                  onClick={handleClickIcon}
                  disabled={
                    !!props.formState?.values.content ||
                    props.formState.submitting
                  }
                />
              )}
              <AttachmentIcon {...props} />
            </>
          )}
        </div>
        <div className={styles.button}>
          <Button
            type="submit"
            disabled={props.formState.submitting || !isValid(props.formState)}
            isBlock={true}
          >
            送信
          </Button>
        </div>
      </form>
      {props.operatorProfile &&
      props.operator &&
      props.destination &&
      props.defaultZoomMeetingContent ? (
        <ZoomModal
          onClose={handleModalClose}
          onSubmit={handlePostZoomMeetingMessage}
          isOpened={isModalOpen}
          operator={props.operator}
          operatorProfile={props.operatorProfile}
          destination={props.destination}
          defaultContent={props.defaultZoomMeetingContent}
        />
      ) : null}
      {DeviceHelper.isPC() ? renderApiErrors(props.formState.apiErrors) : null}
    </React.Fragment>
  );
};

export default withRouter<Props>(CommonMessageForm);
