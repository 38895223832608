import * as React from "react";
import TimeHelper from "../../../../helpers/TimeHelper";
import { DirectMessage } from "../../../../interfaces/MessageInterface";
import StudentInterface from "../../../../interfaces/StudentInterface";
import CircleIcon from "../../../atoms/CircleIcon";
import styles from "./styles.scss";
const nl2br = require("react-nl2br");
const reactStringReplace = require("react-string-replace");
import { RegexpHelper } from "../../../../helpers/RegexpHelper.ts";
import Icon from "../../../atoms/Icon";
import LinkButton from "../../../atoms/LinkButton";

interface Props {
  message: DirectMessage;
  student: Omit<StudentInterface, "billingPlan">;
  deleteMessage: (studentId: string, messageId: string) => void;
}

const MessageItem = (props: Props) => {
  return (
    <div
      className={styles.root}
      role="listitem"
      data-testid="direct_message-listitem"
    >
      {props.message.fromStudent
        ? renderStudentMessage(props)
        : renderOperatorMessage(props)}
    </div>
  );
};

const renderStudentMessage = (props: Props) => {
  const { sentAt } = props.message;
  const { fullName } = props.student;

  return (
    <div className={styles.studentMessage}>
      <div className={styles.studentMessage__header}>
        <CircleIcon
          iconProps={{
            name: "icon-notice-user",
            iconClassName: styles.studentIcon,
          }}
          className={styles.studentIconWrapper}
        />
        <span className={styles.name}>{fullName}</span>
        <time className={styles.datetime}>{TimeHelper.fullFormat(sentAt)}</time>
      </div>
      {renderStudentMessageBody(props.message)}
    </div>
  );
};

const onClickDelete = (
  studentId: string,
  messageId: string,
  deleteMessage: (studentId: string, messageId: string) => void,
) => {
  if (
    window.confirm(
      `送信したメッセージを削除しますか？\nメッセージの削除後は復元できません。受信者側のメッセージも削除されます。`,
    )
  ) {
    deleteMessage(studentId, messageId);
  }
};

const renderDeleteIcon = (
  studentId: string,
  message: DirectMessage,
  deleteMessage: (studentId: string, messageId: string) => void,
) => {
  if (!message.deletable) {
    return null;
  }

  return (
    <LinkButton
      onClick={() => onClickDelete(studentId, message.id, deleteMessage)}
      aria-label={`${TimeHelper.fullFormat(
        message.sentAt,
      )}のメッセージを削除する`}
    >
      <span data-testid={`delete-message-${message.id}`}>
        <Icon name="icon-trash" />
      </span>
    </LinkButton>
  );
};

const renderOperatorMessage = (props: Props) => {
  const { sentAt } = props.message;
  const { fullName } = props.student.section;

  return (
    <div className={styles.operatorMessage}>
      <div className={styles.operatorMessage__header}>
        {renderDeleteIcon(props.student.id, props.message, props.deleteMessage)}
        <time className={styles.datetime}>{TimeHelper.fullFormat(sentAt)}</time>
        <span className={styles.name}>{fullName}</span>
        <CircleIcon
          iconProps={{
            name: "icon-account",
            iconClassName: styles.operatorIcon,
          }}
          className={styles.operatorIconWrapper}
        />
      </div>
      {renderOperatorMessageBody(props.message)}
    </div>
  );
};

const renderDeletedMessage = (message: DirectMessage) => {
  return (
    <p
      className={styles.message__deletedContent}
      data-testid={`message-${message.id}`}
    >
      {message.deleterKind === "section"
        ? "このメッセージは削除されました"
        : "このメッセージはスタディプラス運営事務局により削除されました"}
    </p>
  );
};

const renderStudentMessageBody = (message: DirectMessage) => {
  if (message.deletedAt) {
    return renderDeletedMessage(message);
  } else if (message.presignedUrl) {
    return (
      <a href={message.presignedUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={message.presignedUrl}
          className={styles.studentMessage__image}
        />
      </a>
    );
  } else {
    return (
      <p className={styles.studentMessage__content}>
        {reactStringReplace(
          nl2br(message.content),
          RegexpHelper.URL,
          (match: string): React.ReactNode => (
            <a href={match} target="_blank" rel="noopener noreferrer">
              {match}
            </a>
          ),
        )}
      </p>
    );
  }
};

const renderOperatorMessageBody = (message: DirectMessage) => {
  if (message.deletedAt) {
    return renderDeletedMessage(message);
  } else if (message.presignedUrl) {
    return (
      <div className="flex gap-1">
        {message.receivedAt && (
          <div className="flex items-end">
            <p className="w-[26px] text-md text-black">既読</p>
          </div>
        )}
        <a
          href={message.presignedUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={message.presignedUrl}
            className={styles.operatorMessage__image}
          />
        </a>
      </div>
    );
  } else {
    return (
      <div className="flex gap-1">
        {message.receivedAt && (
          <div className="flex flex-col justify-end">
            <p className="w-[26px] text-md text-black">既読</p>
          </div>
        )}
        <p
          className={styles.operatorMessage__content}
          data-testid={`message-${message.id}`}
        >
          {reactStringReplace(
            nl2br(message.content),
            RegexpHelper.URL,
            (match: string, i: number): React.ReactNode => (
              <a
                href={match}
                target="_blank"
                rel="noopener noreferrer"
                key={`string-replace-${i}`}
              >
                {match}
              </a>
            ),
          )}
        </p>
      </div>
    );
  }
};

export default MessageItem;
