import classnames from "classnames";
import * as React from "react";
import { ColorProps, resolveColorStyles } from "../../../helpers/styles/Colors";
import styles from "./styles.scss";

type Props = React.PropsWithChildren<React.HTMLProps<HTMLSpanElement>> &
  Pick<ColorProps, "backgroundColor">; // TODO: "backgroundColor" わかりづらいので Button側にcolorType実装したらこっちも"colorType"にしたい

export const ColorDot = ({
  className,
  backgroundColor,
  ...restProps
}: Props) => {
  return (
    <span
      className={classnames(
        styles.root,
        resolveColorStyles({ backgroundColor }),
        className,
      )}
      aria-hidden="true"
      {...restProps}
    />
  );
};
