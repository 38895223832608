import { Button } from "@studyplus/boron-ui";
import classNames from "classnames";
import * as React from "react";
import Icon from "../../../../components/atoms/Icon";
import Loader from "../../../../components/atoms/Loader";
import { Modal } from "../../../../components/atoms/Modal";
import DeprecatedTagButton from "../../../../components/features/DeprecatedTagButton";
import { StudentTagType } from "../../../../domains/StudentTag";
import { WithRouterProps, withRouter } from "../../../../helpers/RouterHelper";
import { GuardianAnnounceMessageTagInterface } from "../../../../interfaces/GuardianAnnounceMessageTagInterface";
import SectionInterface from "../../../../interfaces/SectionInterface";
import { RecipientStudents } from "./RecipientStudents";
import styles from "./styles.scss";

interface Props extends WithRouterProps {
  section: SectionInterface;
  guardianAnnounceMessageTags: GuardianAnnounceMessageTagInterface[];
  isModalOpen: boolean;
  onDecideTag: (tag: GuardianAnnounceMessageTagInterface) => void;
  onDeselectTag: () => void;
  setIsModalOpen: (modalState: boolean) => void;
  selectedDestinationId: string | null;
}

const GuardianAnnounceMessageTagSelector: React.FC<Props> = (props: Props) => {
  const [selectedTag, setSelectedTag] =
    React.useState<GuardianAnnounceMessageTagInterface | null>(null);
  const [openedTag, setOpenedTag] =
    React.useState<GuardianAnnounceMessageTagInterface | null>(null);

  // methods
  const sortAndFilterByName = (
    tags: GuardianAnnounceMessageTagInterface[],
    type: StudentTagType,
  ): GuardianAnnounceMessageTagInterface[] => {
    return tags
      .filter((tag) => tag.type === type)
      .sort(
        (
          a: GuardianAnnounceMessageTagInterface,
          b: GuardianAnnounceMessageTagInterface,
        ) => {
          return a.name > b.name ? 1 : -1;
        },
      );
  };

  const tags =
    props.guardianAnnounceMessageTags.length === 0
      ? null
      : [
          ...sortAndFilterByName(props.guardianAnnounceMessageTags, "status"),
          ...sortAndFilterByName(props.guardianAnnounceMessageTags, "job"),
          ...sortAndFilterByName(props.guardianAnnounceMessageTags, "tag_ids"),
          ...sortAndFilterByName(
            props.guardianAnnounceMessageTags,
            "smart_tag",
          ),
        ];

  const handleDeselectDestinationTag = () => {
    setSelectedTag(null);
    props.onDeselectTag();
  };

  const handleDecideTag = () => {
    if (!selectedTag) {
      return;
    }

    props.onDecideTag(selectedTag);
    props.setIsModalOpen(false);
    props.navigate(
      `/sections/${props.section.id}/tags/${selectedTag.id}/guardian_messages`,
    );
  };

  const isDisabledTag = (tag: GuardianAnnounceMessageTagInterface): boolean => {
    return !tag.studentCount;
  };

  const handleSelectDestinationTag =
    (tag: GuardianAnnounceMessageTagInterface) => () => {
      setSelectedTag(tag);
    };

  const handleCloseTag = () => {
    setOpenedTag(null);
  };

  const handleOpenTag = (tag: GuardianAnnounceMessageTagInterface) => () => {
    if (isDisabledTag(tag)) {
      return;
    }

    setOpenedTag(tag);
  };

  const renderDestinationOption = (
    destinationTag: GuardianAnnounceMessageTagInterface,
  ) => {
    if (destinationTag.type === "status" && destinationTag.id !== "active") {
      return null;
    }

    const isOpened = !!openedTag && openedTag.id === destinationTag.id;
    const isSelected = !!selectedTag && selectedTag.id === destinationTag.id;

    return (
      <React.Fragment
        key={`AnnounceMessageForm-DestinationOption-${destinationTag.id}`}
      >
        <label
          className={classNames(styles.label, {
            [styles.active]: isSelected,
            [styles.labelDisabled]: isDisabledTag(destinationTag),
          })}
        >
          <div
            className={classNames(styles.row, {
              [styles.disabledRow]: isDisabledTag(destinationTag),
            })}
          >
            <input
              type="radio"
              name={destinationTag.id}
              checked={isSelected}
              onChange={handleSelectDestinationTag(destinationTag)}
              disabled={isDisabledTag(destinationTag)}
            />
            <div className={styles.tagName}>
              {destinationTag.name} ({destinationTag.studentCount}
              名)
            </div>
          </div>
          {isOpened ? (
            <Icon name="icon-arrow-up" onClick={handleCloseTag} />
          ) : (
            <Icon
              name="icon-arrow-down"
              className={styles.icon}
              onClick={handleOpenTag(destinationTag)}
            />
          )}
        </label>
        {isOpened && (
          <RecipientStudents
            sectionId={props.section.id}
            tag={destinationTag}
          />
        )}
      </React.Fragment>
    );
  };

  const renderDestinationModal = (
    tags: GuardianAnnounceMessageTagInterface[],
  ) => {
    const studentCount = selectedTag ? selectedTag.studentCount : 0;

    return (
      <Modal
        isOpen={props.isModalOpen}
        onRequestClose={() => props.setIsModalOpen(false)}
      >
        <Modal.Header onClose={() => props.setIsModalOpen(false)}>
          <div className={styles.header}>
            <h2 className={styles.headerTitle}>宛先選択</h2>
            <p className={styles.headerComment}>
              ※LINE連携済またはメール登録済の保護者のみ送信可能です
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <h3 className={styles.heading}>保護者送信先（{studentCount}名）：</h3>
          <div className={styles.row}>
            <div className={styles.selectedTag}>
              {selectedTag ? (
                <DeprecatedTagButton
                  label={selectedTag.name}
                  iconName="icon-close-x"
                  theme={
                    selectedTag.type === "smart_tag"
                      ? "smarttag-white"
                      : "white"
                  }
                  onClick={handleDeselectDestinationTag}
                />
              ) : null}
            </div>
            <Button
              type="button"
              disabled={!selectedTag}
              isBlock={true}
              onClick={handleDecideTag}
              className={styles.button}
            >
              決定
            </Button>
          </div>
          <div>
            {tags.map((guardianAnnounceMessageTag) =>
              renderDestinationOption(guardianAnnounceMessageTag),
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  if (!tags) {
    return <Loader loading={true} />;
  }

  const selectedDestinationTag = tags.find(
    (tag) => tag.id === props.selectedDestinationId,
  );

  return (
    <React.Fragment>
      {selectedDestinationTag ? (
        <DeprecatedTagButton
          label={selectedDestinationTag.name}
          theme={
            selectedDestinationTag.type === "smart_tag"
              ? "smarttag-white"
              : "white"
          }
          iconName={"icon-close-x"}
          onClick={handleDeselectDestinationTag}
        />
      ) : (
        <DeprecatedTagButton
          label="選択"
          iconName="icon-plus-tag"
          theme="green"
          onClick={() => props.setIsModalOpen(true)}
        />
      )}
      {renderDestinationModal(tags)}
    </React.Fragment>
  );
};

export default withRouter<Props>(GuardianAnnounceMessageTagSelector);
