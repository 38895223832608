import { useCallback, useState } from "react";

type UseBooleanProps = {
  initialValue?: boolean;
};

export const useBoolean = (props?: UseBooleanProps) => {
  const initialValue = props?.initialValue ?? false;
  const [value, setValue] = useState(initialValue);

  const on = useCallback(() => {
    setValue(true);
  }, []);

  const off = useCallback(() => {
    setValue(false);
  }, []);

  const toggle = useCallback(() => {
    setValue(!value);
  }, [value]);

  return { value, on, off, toggle };
};
