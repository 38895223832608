import { AnyAction, combineReducers } from "redux";
import { ActionTypes as AuthorizationActionTypes } from "../actions/common/session/types";
import DeviceHelper from "../helpers/DeviceHelper";
import AppStateInterface from "../interfaces/AppStateInterface";
import { currentSection } from "./common/currentSection";
import errors from "./common/errors";
import { filters } from "./common/filters";
import { operatorPrivileges } from "./common/operatorPrivileges";
import session from "./common/session";
import flashMessage from "./flashMessage";
import invitation from "./invitation";
import karteTemplates from "./karteTemplates";
import sectionAnnounceMessages from "./pages/SectionsAnnounceMessagesPage/index";
import sectionsSettingsSkillsEdit from "./pages/SectionsSettingsSkillsEditPage/index";
import sectionsSettingsSkillsNew from "./pages/SectionsSettingsSkillsNewPage/index";
import studentLearningMaterialsPreRegistrations from "./pages/StudentsLearningMaterialsPreRegistrationsPage";
import resetPassword from "./pages/resetPassword/index";
import studentsLearningMaterials from "./pages/studentsLearningMaterials";
import studentsLearningMaterialsNew from "./pages/studentsLearningMaterialsNew";
import sectionKarteTemplateEditState from "./sectionKarteTemplateEditState";
import sectionKarteTemplateNewState from "./sectionKarteTemplateNewState";
import sectionKarteTemplatesState from "./sectionKarteTemplatesState";
import sectionKartes from "./sectionKartes";
import sectionLearningMaterialEditState from "./sectionLearningMaterialEditState";
import sectionLearningMaterialNewState from "./sectionLearningMaterialNewState";
import sectionOperatorInvitations from "./sectionOperatorInvitations";
import sectionsSettingsOperatorsState from "./sectionSettingsOperatorsState";
import sectionStaysState from "./sectionStaysState";
import { sectionStudyRecordCommentsState } from "./sectionStudyRecordCommentsState";
import student from "./student";
import studentAnalyticsTable from "./studentAnalyticsTable";
import studentKarte from "./studentKarte";
import studentKarteEdit from "./studentKarteEdit";
import studentKartes from "./studentKartes";

// FIXME: エラーメッセージは要相談
export const HTTP_ERROR_MESSAGE =
  "通信エラーです。しばらくしてから再読込してください。";

const shouldReload = (state = false, action: ReduxActions.Action<any>) => {
  switch (action.type) {
    case AuthorizationActionTypes.SIGN_OUT:
      return true;
    default:
      return state;
  }
};

const navigationOpened = (
  state: boolean = DeviceHelper.isPC(),
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "TOGGLE_NAVIGATION":
      return !state;
    default:
      return state;
  }
};

// FIXME: ReduxActions.Action<any> で統一したい
const combinedReducer = combineReducers<AppStateInterface, any>({
  session,
  currentSection,
  student,
  flashMessage,
  shouldReload,
  navigationOpened,
  studentAnalyticsTable,
  filters,
  sectionOperatorInvitations,
  sectionKartes,
  studentKartes,
  karteTemplates,
  studentKarte,
  sectionStudyRecordCommentsState,
  sectionsSettingsOperatorsState,
  sectionLearningMaterialNewState,
  sectionLearningMaterialEditState,
  studentKarteEdit,
  sectionsSettingsKarteTemplatesState: sectionKarteTemplatesState,
  sectionsSettingsKarteTemplatesNewState: sectionKarteTemplateNewState,
  sectionsSettingsKarteTemplatesEditState: sectionKarteTemplateEditState,
  studentsLearningMaterials,
  studentsLearningMaterialsNew,
  resetPassword,
  sectionStaysState,
  errors,
  sectionAnnounceMessages,
  operatorPrivileges,
  studentLearningMaterialsPreRegistrations,
  invitation,
  sectionsSettingsSkillsNew,
  sectionsSettingsSkillsEdit,
});

export default (
  state: AppStateInterface | undefined,
  action: AnyAction,
): AppStateInterface => {
  if (action.type === "redux/RESET_STATE") {
    if (state && state.session) {
      return {
        ...combinedReducer(undefined, action),
        session: state.session,
        flashMessage: state.flashMessage,
        filters: state.filters,
      };
    }
  }

  if (action.type === "redux/CLEAR_STATE") {
    state = undefined;
  }

  return combinedReducer(state, action);
};
