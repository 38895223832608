import { boronClient } from "../../../api.ts";
import { FSHTTPError } from "../../../errors.ts";
import { useBoronMutation } from "../../../hooks/http/useBoronMutation.ts";
import { useUpdateBotMessageCache } from "../../../hooks/http/useFetchStudentMessages.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";

export const useChangeBotMessageStatus = () => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { updateBotMessageCache } = useUpdateBotMessageCache();

  const { mutate } = useBoronMutation(
    async ({
      studentId,
      messageId,
      status,
    }: {
      studentId: string;
      messageId: string;
      status: "done" | "ignored";
    }) =>
      boronClient.PUT(
        `/api/v1/students/{student_id}/bot_messages/{bot_message_hashid}/${status}`,
        {
          params: {
            path: { student_id: studentId, bot_message_hashid: messageId },
          },
        },
      ),
    {
      onSuccess: () => {
        showSuccessMessage("対応状況を変更しました");
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage("対応状況を変更できませんでした");
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    },
  );

  const changeBotMessageStatusDone = (studentId: string, messageId: string) => {
    mutate(
      { status: "done", studentId, messageId },
      {
        onSuccess: () =>
          updateBotMessageCache(studentId, { id: messageId, status: "done" }),
      },
    );
  };

  const changeBotMessageStatusIgnored = (
    studentId: string,
    messageId: string,
  ) => {
    mutate(
      { status: "ignored", studentId, messageId },
      {
        onSuccess: () =>
          updateBotMessageCache(studentId, {
            id: messageId,
            status: "ignored",
          }),
      },
    );
  };

  return { changeBotMessageStatusDone, changeBotMessageStatusIgnored };
};
