import { initializeFiltersByQuery } from "../actions/common/filters";
import { clearStudent } from "../actions/common/student";
import StudentNavi from "../components/organisms/StudentNavi";
import { withRouter } from "../helpers/RouterHelper";
import {
  AuthenticatedPageCallbacks,
  AuthenticatedPageProps,
} from "../hocs/enhanceAuthenticatedPage";
import withFiltersInitialization from "../hocs/withFiltersInitialization";

export interface SidebarProps
  extends AuthenticatedPageProps,
    AuthenticatedPageCallbacks {
  initializeFilterByQuery: typeof initializeFiltersByQuery;
  clearStudent: typeof clearStudent;
  activeMenu: string;
}

const StudentSidebar = (props: SidebarProps) => {
  const tagFilter = studentTagFilter(props);
  if (
    !props.currentSection.data ||
    !props.currentOperator ||
    !props.student.data ||
    !props.operatorPrivileges.data ||
    !tagFilter
  ) {
    return null;
  }

  return (
    <StudentNavi
      {...props}
      operatorPrivileges={props.operatorPrivileges.data}
      student={props.student.data}
      section={props.currentSection.data}
      opened={props.navigationOpened}
      tagFilter={tagFilter}
    />
  );
};

const studentTagFilter = (props: SidebarProps) => {
  return props.studentFilterContext.findTagFilter({
    sectionId: props.currentSection?.data?.id || "",
  });
};

export default withRouter<SidebarProps>(
  withFiltersInitialization(StudentSidebar),
);
