import * as queryString from "query-string";
import ApiClient from "../api";
import { SectionLearningMaterialFormInterface } from "../interfaces/SectionLearningMaterialInterface";

export type LearningMaterialSearchType =
  | "section_original"
  | "drill"
  | "general_books"
  | "itunes_app";

export interface SearchLearningMaterialParams {
  keyword: string;
  type: LearningMaterialSearchType;
  page?: number;
  student_id?: string;
}

const SectionLearningMaterialApi = {
  search: (
    sectionId: string,
    params: SearchLearningMaterialParams,
  ): Promise<Response> => {
    const query = queryString.stringify(params);
    return ApiClient.get(
      `/api/v1/sections/${sectionId}/learning_materials/search`,
      { query: `?${query}` },
    );
  },

  getSectionLearningMaterial: (
    sectionId: string,
    learningMaterialId: string,
  ): Promise<Response> => {
    return ApiClient.get(
      `/api/v1/sections/${sectionId}/learning_materials/${learningMaterialId}`,
    );
  },

  updateSectionLearningMaterial: (
    sectionId: string,
    learningMaterialId: string,
    params: SectionLearningMaterialFormInterface,
  ): Promise<Response> => {
    const target_path = `/api/v1/sections/${sectionId}/learning_materials/${learningMaterialId}`;
    return ApiClient.sendFormData(target_path, "PATCH", params);
  },

  createSectionLearningMaterial: (
    sectionId: string,
    params: SectionLearningMaterialFormInterface,
  ): Promise<Response> => {
    const target_path = `/api/v1/sections/${sectionId}/learning_materials`;
    return ApiClient.sendFormData(target_path, "POST", params);
  },
};

export default SectionLearningMaterialApi;
