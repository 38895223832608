import { KarteAttachment } from "../../../interfaces/KarteInterface";
import DeleteIcon from "../../atoms/DeleteIcon/index";
import Icon from "../../atoms/Icon/index";
import styles from "./styles.scss";

interface Props {
  fileAttachments: KarteAttachment[];
  keys: string[];
  deletable?: boolean;
  onDelete?: (index: number) => (e: any) => void;
}

const KarteFileAttachmentList = (props: Props) => {
  const { fileAttachments } = props;

  if (fileAttachments.length > 0) {
    return (
      <ul className={styles.root}>
        {fileAttachments.map((attachment: KarteAttachment, i: number) => {
          return (
            <li className={styles.attachment} key={props.keys[i]}>
              {renderDeleteIcon(props, i)}
              <a
                href={attachment.presignedUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.attachment__link}
              >
                <Icon name="icon-file" />
                <span className={styles.attachment__filename}>
                  {attachment.filename}
                </span>
              </a>
            </li>
          );
        })}
      </ul>
    );
  } else {
    return null;
  }
};

const renderDeleteIcon = (props: Props, i: number) => {
  if (props.deletable && props.onDelete) {
    return <DeleteIcon onDelete={props.onDelete(i)} />;
  } else {
    return null;
  }
};

export default KarteFileAttachmentList;
