import { boronClient } from "../../../api.ts";
import { useBoronMutation } from "../../../hooks/http/useBoronMutation.ts";
import { useUpdateFollowStatusCache } from "../../../hooks/http/useFetchFollowStatus.tsx";
import { useUpdateMessageThreadLatestMessage } from "../../../hooks/http/useFetchMessageThreads.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";

export const useFollowUnfollowThread = ({
  sectionId,
  studentId,
}: {
  sectionId: string;
  studentId: string;
}) => {
  const { showErrorMessage } = useFlashMessage();

  const { updateFollowStatusCache } = useUpdateFollowStatusCache({ studentId });
  const { updateMessageThreadLatestMessageByStudentId } =
    useUpdateMessageThreadLatestMessage({
      sectionId,
    });

  const { mutate: mutateFollow, isPending: isPendingFollow } =
    useFollowThread();

  const { mutate: mutateUnfollow, isPending: isPendingUnfollow } =
    useUnfollowThread();

  const follow = () => {
    if (window.confirm("通知をオンにしますか？")) {
      if (isPendingFollow || isPendingUnfollow) {
        return;
      }

      mutateFollow(
        { studentId },
        {
          onSuccess: () => {
            updateFollowStatusCache(true);
            updateMessageThreadLatestMessageByStudentId(studentId, {
              isFollowing: true,
            });
          },
          onError: () => showErrorMessage(HTTP_ERROR_MESSAGE),
        },
      );
    }
  };

  const unfollow = () => {
    if (window.confirm("通知を解除しますか？")) {
      if (isPendingFollow || isPendingUnfollow) {
        return;
      }

      mutateUnfollow(
        { studentId },
        {
          onSuccess: () => {
            updateFollowStatusCache(false);
            updateMessageThreadLatestMessageByStudentId(studentId, {
              isFollowing: false,
            });
          },
          onError: () => showErrorMessage(HTTP_ERROR_MESSAGE),
        },
      );
    }
  };

  return {
    follow,
    unfollow,
  };
};

const useFollowThread = () => {
  return useBoronMutation(
    async ({ studentId }: { studentId: string }) =>
      await boronClient.POST(
        "/api/v1/students/{student_id}/direct_messages/follow",
        {
          params: {
            path: { student_id: studentId },
          },
        },
      ),
  );
};

const useUnfollowThread = () => {
  return useBoronMutation(
    async ({ studentId }: { studentId: string }) =>
      await boronClient.DELETE(
        "/api/v1/students/{student_id}/direct_messages/follow",
        {
          params: {
            path: { student_id: studentId },
          },
        },
      ),
  );
};
