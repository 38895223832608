import { Button } from "@studyplus/boron-ui";
import classNames from "classnames";
import isAfter from "date-fns/isAfter";
import subMonths from "date-fns/subMonths";
import * as React from "react";
import { Link } from "react-router-dom";
import SectionInterface from "../../../interfaces/SectionInterface";
import SkillMasterInterface from "../../../interfaces/SkillMasterInterface";
import BackLink from "../../atoms/BackLink";
import styles from "./styles.scss";

interface Props {
  section: SectionInterface;
  skillMasters: SkillMasterInterface[];
}

const renderSkillIcon = (skillMaster: SkillMasterInterface) => {
  switch (skillMaster.type) {
    case "study_record_skill_master":
    default:
      return <img src={`/images/icon-skill-study_time.png`} />;
  }
};

const renderSkillMaster = (props: Props, skillMaster: SkillMasterInterface) => {
  const baseDate = subMonths(new Date(), 3);
  const isNew = isAfter(new Date(skillMaster.createdAt), baseDate);

  return (
    <Link
      to={`/sections/${props.section.id}/settings/skill_masters/${skillMaster.id}/skills/new`}
      key={`SkillMasters-${skillMaster.id}`}
    >
      <div
        className={classNames(styles.skillMaster, {
          [styles.skillMasterNew]: isNew,
        })}
      >
        <h3 className={styles.skillMaster__heading}>{skillMaster.name}</h3>
        <div className={styles.skillMaster__image}>
          {renderSkillIcon(skillMaster)}
        </div>
        <Button
          className={styles.skillMaster__button}
          disabled={false}
          size="sm"
          isRound={true}
          variant="outline"
        >
          登録
        </Button>
      </div>
    </Link>
  );
};

const SkillMasters = (props: Props): React.ReactElement<Props> => {
  return (
    <div className={styles.root}>
      <BackLink path={`/sections/${props.section.id}/settings/skills`}>
        戻る
      </BackLink>
      <div className={styles.container}>
        <h2 className={styles.heading}>登録したいアシスタントスキルを選ぶ</h2>
        <p className={styles.description}>
          条件に一致する生徒を発見し、お知らせする設定ができます。
          <a
            rel="noreferrer noopener"
            className={styles.link}
            target="_blank"
            href="https://fs-help.studyplus.co.jp/ja/articles/3589514-%E3%82%A2%E3%82%B7%E3%82%B9%E3%82%BF%E3%83%B3%E3%83%88%E6%A9%9F%E8%83%BD%E3%81%A8%E3%81%AF"
          >
            使い方はこちら
          </a>
        </p>
        <div className={styles.skillMasters}>
          {props.skillMasters.map((skillMaster) =>
            renderSkillMaster(props, skillMaster),
          )}
        </div>
      </div>
    </div>
  );
};

export default SkillMasters;
