import * as React from "react";
import { useParams } from "react-router";
import {
  ContentResultTable,
  ContentResultTableRow,
} from "../../../components/features/ContentResultTable";
import { ContentResultTemplate } from "../../../components/features/ContentResultTemplate";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { useFetchContentCourseContentResults } from "../../../hooks/http/useFetchContentCourseContentResults";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useOnScrollContentBottom } from "../../../hooks/useOnScrollContentBottom";
import SectionInterface from "../../../interfaces/SectionInterface";

const useContentCourseContentResults = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  const { contentCourseId: courseId = "", unitId = "" } = useParams<{
    contentCourseId: string;
    unitId: string;
  }>();
  const fetchResult = useFetchContentCourseContentResults({
    sectionId,
    courseId,
    unitId,
  });
  useQueryError(fetchResult.error);
  const breadcrumbsProps = fetchResult.data && {
    items: [
      {
        label: "学習結果",
        url: `/sections/${sectionId}/settings/content_courses/results`,
      },
      {
        label: fetchResult.data.course.name,
        url: `/sections/${sectionId}/settings/content_courses/${fetchResult.data.course.id}/results`,
      },
      {
        label: fetchResult.data.unit.name,
      },
    ],
  };

  return {
    breadcrumbsProps,
    ...fetchResult,
  };
};

type Props = OutletContextProps & {
  section: SectionInterface;
};
const ContentCourseContentResultsPage = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("courses");
  }, []);
  const {
    data,
    breadcrumbsProps,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useContentCourseContentResults({
    sectionId: props.section.id,
  });
  const rootRef = React.useRef(null);
  const { refLastElement } = useOnScrollContentBottom({
    onScrollContentBottom() {
      !isLoading && hasNextPage && fetchNextPage();
    },
    rootContentWithScroll: rootRef.current,
  });

  if (!data || !breadcrumbsProps) return null;

  return (
    <ContentResultTemplate
      sectionId={props.section.id}
      courseId={data.course.id}
      title={data.unit.name}
      subText={`コンテンツ数：${data.contents.length}`}
      breadcrumbsProps={breadcrumbsProps}
      isLoading={isLoading || isFetchingNextPage}
    >
      <ContentResultTable columns={data.contents} ref={rootRef}>
        {data.data.map((e, i) => (
          <ContentResultTableRow
            key={`ContentResultTableRow-${e.student.id}`}
            student={e.student}
            results={e.unitResult}
            scores={e.unitResult.contentResults}
            rank={i + 1}
            totalCount={data.meta.totalCount}
            courseId={data.course.id}
            columns={data.contents}
            ref={refLastElement}
          />
        ))}
      </ContentResultTable>
    </ContentResultTemplate>
  );
};

const pageInfo = {
  title: "学習結果一覧",
};
export default enhanceSectionsSettingsPage(
  ContentCourseContentResultsPage,
  pageInfo,
);
