import classNames from "classnames";
import * as React from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import { dispatchSignOut } from "../../../actions/common/session";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { BoronNavigator } from "../../../navigators/BoronNavigator";
import CircleIcon from "../../atoms/CircleIcon/index";
import HeaderMenuFooter from "../../atoms/HeaderMenuFooter/index";
import Icon from "../../atoms/Icon/index";
import HeaderAccountAddition from "../../molecules/HeaderAccountAddition/index";
import HeaderAccountUsername from "../../molecules/HeaderAccountUsername/index";
import HeaderPopup from "../../molecules/HeaderPopup/index";
import styles from "./styles.scss";

export enum HeaderAccountTheme {
  Main,
  White,
}
interface Props {
  currentOperator: OperatorInterface;
  operators: OperatorInterface[];
  className?: string;
  dispatchSignOut: HandleThunkActionCreator<typeof dispatchSignOut>;
  theme?: HeaderAccountTheme;
}

interface State {
  listVisible: boolean;
}

class HeaderAccount extends React.Component<Props, State> {
  buttonRef: React.RefObject<any>;
  listRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.state = { listVisible: false };
    this.buttonRef = React.createRef();
    this.listRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.closeList);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.closeList);
  }

  render() {
    return (
      <div className={this.attachParentClassName(this.props)}>
        <button
          onClick={this.toggleListVisibility}
          className={styles.button}
          ref={this.buttonRef}
        >
          <CircleIcon
            className={styles.iconAccountWrapper}
            iconProps={{
              name: "icon-account",
              iconClassName: classNames(styles.iconAccount, {
                [styles.white]: this.props.theme === HeaderAccountTheme.White,
              }),
            }}
          />
          <div className={this.nameStyleClass()}>
            {this.props.currentOperator.fullName}
          </div>
          <div className={styles.arrow}>
            <Icon name="icon-arrow-drop-down" className={styles.iconArrow} />
          </div>
        </button>
        {this.renderPopup()}
      </div>
    );
  }

  private nameStyleClass(): string {
    switch (this.props.theme) {
      case HeaderAccountTheme.White:
        return `${styles.name} ${styles.white}`;
      case HeaderAccountTheme.Main:
        return `${styles.name} ${styles.main}`;
      default:
        return `${styles.name} ${styles.main}`;
    }
  }

  private isCurrentOperator(operator: OperatorInterface): boolean {
    return this.props.currentOperator.id === operator.id;
  }

  private renderAccountUsername(operator: OperatorInterface) {
    return (
      <HeaderAccountUsername
        key={`header-account-current-username-${operator.id}`}
        operator={operator}
        isCurrentOperator={this.isCurrentOperator(operator)}
      />
    );
  }

  private renderCurrentAccountUsername() {
    const currentOperator = this.props.operators.find(
      (operator: OperatorInterface) => {
        return this.isCurrentOperator(operator);
      },
    );

    if (!currentOperator) {
      return null;
    }

    return this.renderAccountUsername(currentOperator);
  }

  private renderOtherAccountUsernames() {
    if (this.props.operators.length > 0) {
      return this.props.operators
        .filter((operator) => !this.isCurrentOperator(operator))
        .map((others) => {
          return this.renderAccountUsername(others);
        });
    } else {
      return null;
    }
  }

  private renderPopup() {
    if (this.state.listVisible) {
      return (
        <HeaderPopup widthType="narrow">
          {this.renderCurrentAccountUsername()}
          {this.renderOtherAccountUsernames()}
          <HeaderAccountAddition onAdd={this.handleAddAccount} />
          <HeaderMenuFooter
            label="ログアウト"
            onClick={this.props.dispatchSignOut}
          />
        </HeaderPopup>
      );
    } else {
      return null;
    }
  }

  // FIXME: 以下は3つのコンポーネントで重複しているので、どこか適切な場所にまとめる (HOC?)
  private toggleListVisibility = () => {
    if (this.state.listVisible) {
      this.setState({ listVisible: false }, () => {
        document.removeEventListener("click", this.closeList);
      });
    } else {
      this.openList();
    }
  };

  private closeList = (e: any) => {
    if (this.buttonRef.current && this.buttonRef.current.contains(e.target)) {
      return;
    }

    if (this.listRef.current && this.listRef.current.contains(e.target)) {
      return;
    }

    this.setState({ listVisible: false }, () => {
      document.removeEventListener("click", this.closeList);
    });
  };

  private openList = () => {
    this.setState({ listVisible: true }, () => {
      document.addEventListener("click", this.closeList);
    });
  };
  private attachParentClassName = (props: Props) => {
    if (props.className) {
      return `${styles.root} ${props.className}`;
    } else {
      return styles.root;
    }
  };

  private handleAddAccount = (): void => {
    BoronNavigator.signInWithBackTo(location.pathname);
  };
}

const mapDispatchToProps = {
  dispatchSignOut,
};

export default connect(null, mapDispatchToProps)(HeaderAccount);
