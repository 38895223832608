import { SectionDetailInterface } from "../../../interfaces/SectionInterface";
import Icon from "../../atoms/Icon";
import styles from "./styles.scss";

interface Props {
  section: SectionDetailInterface;
}

const SettingNaviSectionProfile = (props: Props) => {
  return (
    <div className={styles.root}>
      <Icon name="icon-navi-school" className={styles.iconNaviSchool} />
      <div className={styles.name}>
        <div className={styles.schoolName}>{props.section.schoolName}</div>
        <div className={styles.sectionName}>{props.section.sectionName}</div>
      </div>
      <div className={styles.studentsCount}>
        ( 連携中：{props.section.activeStudentCount}名 )
      </div>
    </div>
  );
};

export default SettingNaviSectionProfile;
