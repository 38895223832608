import addDays from "date-fns/addDays";
import startOfISOWeek from "date-fns/startOfISOWeek";
import * as React from "react";
import { DateRange, TermType } from "../../../domains/QueryDateRange";

export type UseStudentStudyScheduleHeaderProps = {
  type: TermType;
  startDate: Date;
  endDate: Date;
  onChangeDateParams: (startDate: Date, type: TermType) => void;
};

export type UseStudentStudyScheduleHeaderReturn = ReturnType<
  typeof useStudentStudyScheduleHeader
>;

// eslint-disable-next-line
export const useStudentStudyScheduleHeader = (
  props: UseStudentStudyScheduleHeaderProps,
) => {
  const prevTermType = React.useRef(props.type);

  const range = React.useMemo(
    () => calcDateRange(props.startDate),
    [props.startDate],
  );

  const onChangeDateRange = React.useCallback(
    (dates: { startDate?: Date; endDate?: Date }) => {
      if (dates.startDate) {
        props.onChangeDateParams(dates.startDate, props.type);
      }
    },
    [props.type, props.onChangeDateParams],
  );

  const onChangeTermType = React.useCallback(
    (newType: TermType) => {
      if (newType === prevTermType.current) {
        return;
      }
      const startDate =
        newType === "daily" ? new Date() : startOfISOWeek(range.startDate);
      const type = newType;
      prevTermType.current = newType;
      props.onChangeDateParams(startDate, type);
    },
    [props.type, range, props.onChangeDateParams],
  );

  const onChangeSingleDate = React.useCallback(
    (date?: Date) => {
      if (date) {
        props.onChangeDateParams(date, props.type);
      }
    },
    [props.type, props.onChangeDateParams],
  );

  return {
    startDate: range.startDate,
    endDate: range.endDate,
    onChangeDateRange,
    onChangeSingleDate,
    onChangeTermType,
  };
};

const calcDateRange = (startDate?: Date): DateRange => {
  const sd = startDate ?? new Date();
  return { startDate: sd, endDate: addDays(sd, 6) };
};
