import * as React from "react";
import Loader from "../../../components/atoms/Loader";
import StaffBoardPostForm from "../../../components/features/StaffBoardPostForm";
import TabsNavi from "../../../components/molecules/TabsNavi";
import { FSHTTPError } from "../../../errors.ts";
import { useDeleteStaffBoardPost } from "../../../hooks/http/useDeleteStaffBoardPost.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import OperatorInterface from "../../../interfaces/OperatorInterface.ts";
import SectionInterface from "../../../interfaces/SectionInterface.ts";
import StaffBoardPostInterface, {
  StaffBoardPostFormInterface,
} from "../../../interfaces/StaffBoardPostInterface.ts";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";
import { useRemoveStaffBoardPostFromListCache } from "../useFetchStaffBoardPosts.ts";
import StaffBoardPostList from "./StaffBoardPostList";
import { useCreateStaffBoardPost } from "./useCreateStaffBoardPost.tsx";

interface Props {
  section: SectionInterface;
  currentOperator: OperatorInterface | null;
  staffBoardPosts: StaffBoardPostInterface[];
  isLoading: boolean;
}

const SectionStaffBoardPosts = (props: Props) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { mutate: createPost, isPending: isCreatingPost } =
    useCreateStaffBoardPost({
      sectionId: props.section.id,
    });

  const { mutate: deletePostMutation } = useDeleteStaffBoardPost({
    sectionId: props.section.id,
  });

  const { removeStaffBoardPostFromListCache } =
    useRemoveStaffBoardPostFromListCache({
      sectionId: props.section.id,
    });

  const handleSubmit = (
    values: StaffBoardPostFormInterface,
    resetForm: () => void,
  ) => {
    createPost(values, {
      onSuccess: () => {
        resetForm();
      },
    });
  };

  const deletePost = (staffBoardPostId: string) => {
    deletePostMutation(
      {
        staffBoardPostId,
      },
      {
        onSuccess: () => {
          removeStaffBoardPostFromListCache(staffBoardPostId);
          showSuccessMessage("投稿を削除しました");
        },
        onError: (error) => {
          if (error instanceof FSHTTPError) {
            showErrorMessage("投稿を削除できませんでした");
          } else {
            showErrorMessage(HTTP_ERROR_MESSAGE);
          }
        },
      },
    );
  };

  if (!props.currentOperator) return null;

  return (
    <React.Fragment>
      <TabsNavi
        tabs={[
          {
            label: "スタッフボード",
            link: `#`,
            active: true,
          },
        ]}
      />
      <StaffBoardPostForm
        submitting={isCreatingPost}
        operatorName={props.currentOperator.fullName}
        onSubmit={handleSubmit}
      />
      <StaffBoardPostList
        section={props.section}
        currentOperator={props.currentOperator}
        staffBoardPosts={props.staffBoardPosts}
        deletePost={deletePost}
      />
      <Loader loading={props.isLoading} />
    </React.Fragment>
  );
};

export default SectionStaffBoardPosts;
