import { IconLock } from "@studyplus/boron-ui";
import * as React from "react";
import { MainSection } from "../../../components/atoms/MainSection";
import { StudyScheduleForm } from "../../../components/molecules/StudyScheduleForm";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import enhanceStudentsPage from "../../../hocs/enhanceStudentsPage";
import { useFetchBookshelfEntries } from "../../../hooks/http/useBookshelfEntries";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import StudentInterface from "../../../interfaces/StudentInterface";
import StudentStudyScheduleIndex from "./StudentStudyScheduleIndex/index";
import { useStudentStudySchedules } from "./useStudentStudySchedules";
import { useFetchStudySchedules } from "./useStudyScheduleApi";

type Props = OutletContextProps & {
  student: StudentInterface;
};

const Component = (props: Props): React.ReactElement => {
  React.useEffect(() => {
    props.setActiveMenu("study_schedules");
  }, []);
  const bookshelfEntriesState = useFetchBookshelfEntries(props.student.id, {
    type: "all",
  });

  useQueryError(bookshelfEntriesState.error);

  const { startDate, endDate } = useStudentStudySchedules();
  const studySchedulesState = useFetchStudySchedules({
    from: startDate,
    to: endDate,
    studentId: props.student.id,
  });

  useQueryError(studySchedulesState.error);

  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const handleStudyScheduleMutateSuccess = React.useCallback(
    (message: string) => {
      showSuccessMessage(message);
      studySchedulesState.refetch();
    },
    [studySchedulesState],
  );

  if (props.student.billingPlan === "free") {
    return (
      <div className="mt-10 whitespace-nowrap break-keep text-center align-middle text-gray-800">
        <IconLock className="fill-gray-800 align-bottom" />
        <a
          href={"https://fs-help.studyplus.co.jp/ja/articles/9153866"}
          className="ml-2 underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          プロプラン
        </a>
        にすると全ての学習計画の閲覧ができます
      </div>
    );
  }

  return (
    <React.Fragment>
      <MainSection>
        <StudyScheduleForm
          student={props.student}
          loadingBookshelfEntries={bookshelfEntriesState.isLoading}
          bookshelfEntries={bookshelfEntriesState.data || []}
          onMutateSuccess={handleStudyScheduleMutateSuccess}
          onMutateError={showErrorMessage}
        />
      </MainSection>
      <StudentStudyScheduleIndex
        student={props.student}
        data={studySchedulesState.data}
        isLoading={studySchedulesState.isLoading}
      />
    </React.Fragment>
  );
};

const pageInfo = {
  title: "学習計画",
};

const StudentsStudySchedulesPage = enhanceStudentsPage(Component, pageInfo);

export default StudentsStudySchedulesPage;
