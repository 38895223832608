import { useNavigate } from "react-router";
import { Flex } from "../../../components/atoms/Flex/index";
import Icon from "../../../components/atoms/Icon/index";
import LinkButton from "../../../components/atoms/LinkButton";
import {
  HorizontalScrollTable,
  TableColumn,
} from "../../../components/atoms/Table/index";
import {
  CustomLearningMaterialTag,
  DefaultLearningMaterialTag,
} from "../../../components/features/LearningMaterialTagLabel/LearningMaterialTagLabel";
import SectionInterface from "../../../interfaces/SectionInterface";
import SectionLearningMaterialInterface from "../../../interfaces/SectionLearningMaterialInterface";
import styles from "./styles.scss";

type TableProps = {
  section: SectionInterface;
  sectionLearningMaterials?: SectionLearningMaterialInterface[];
};
export const LearningMaterialTable = (props: TableProps) => {
  const { section, sectionLearningMaterials } = props;
  const navigate = useNavigate();

  const navigateToEditPage = (
    learningMaterial: SectionLearningMaterialInterface,
  ) => {
    navigate(
      `/sections/${section.id}/settings/section_learning_materials/${learningMaterial.id}/edit`,
    );
  };

  if (!sectionLearningMaterials) {
    return null;
  }

  if (sectionLearningMaterials.length === 0) {
    return (
      <Flex justifyContent="center">
        独自教材がありません。右上の「独自教材を登録」から独自教材を登録できます。
      </Flex>
    );
  }

  return (
    <HorizontalScrollTable
      headers={[
        { text: "教材名", centering: true, key: "name" },
        { text: "教材タグ", centering: true, key: "tags" },
        { text: "", centering: true, key: "edit" },
      ]}
      rows={sectionLearningMaterials?.map((item) => (
        <tr key={`section-learning-material-${item.id}`}>
          <TableColumn className={styles.nameCell}>
            <Flex alignItems="center">
              <img
                src={item.imageUrl}
                alt={item.name}
                className={styles.learningMaterialImage}
              />
              {item.name}
            </Flex>
          </TableColumn>
          <TableColumn>
            <ul className={styles.tags}>
              {item.tags.map((lmTag) =>
                lmTag.tagType === "default" ? (
                  <DefaultLearningMaterialTag
                    key={`learning-material-tag-label-${lmTag.id}`}
                  >
                    {lmTag.name}
                  </DefaultLearningMaterialTag>
                ) : (
                  <CustomLearningMaterialTag
                    key={`learning-material-tag-label-${lmTag.id}`}
                  >
                    {lmTag.name}
                  </CustomLearningMaterialTag>
                ),
              )}
            </ul>
          </TableColumn>
          <TableColumn align="right" className={styles.editCell}>
            <LinkButton
              onClick={() => {
                navigateToEditPage(item);
              }}
            >
              <Flex marginTop="0rem" alignItems="center">
                <Icon name="icon-edit" />
                <span>編集</span>
              </Flex>
            </LinkButton>
          </TableColumn>
        </tr>
      ))}
    />
  );
};
