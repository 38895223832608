import * as React from "react";

import isAfter from "date-fns/isAfter";
import { useCallback } from "react";
import { AnalyticsTableData } from "../../../containers/pages/SectionsAnalyticsPage";
import { isPushableCalendarNext } from "../../../helpers/AnalyticsHelper";
import {
  AnalyticsTermType,
  AnalyticsType,
} from "../../../interfaces/AnalyticsTableInterface";
import { OrderDirFilterType } from "../../../interfaces/FiltersInterface";
import FiltersInterface from "../../../interfaces/FiltersInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import PrintButton from "../../atoms/PrintButton";
import StudentFilter from "../../features/NewStudentFilter";
import { SectionAnalyticsTypeTab } from "../../features/SectionAnalyticsTypeTab";
import { SectionStudyRecordAnalyticsSwitcher } from "../../features/SectionStudyRecordAnalyticsSwitcher";
import { DateRangeNavigation } from "../../general/DateRangeNavigation";
import { FieldVisibilityToggle } from "../../general/FieldVisibilityToggle";
import { AnalyticsTable } from "../../molecules/AnalyticsTable";
import SwitchTabs from "../../molecules/SwitchTabs";
import styles from "./styles.scss";

interface Props {
  section: SectionInterface;
  filter: FiltersInterface;
  analyticsTable: AnalyticsTableData;
  analyticsType: AnalyticsType;
  term?: AnalyticsTermType;
  order?: string | null;
  orderDir?: OrderDirFilterType | null;
  startDate: Date;
  endDate: Date;
  onDateRangeFilterChange: (dateRange: {
    startDate: Date | null;
    endDate: Date | null;
  }) => void;
  onTermChange: (term: AnalyticsTermType) => void;
  onOrderChange: (order: string | null) => void;
}

const isDayBlocked = (day: Date) => {
  return isAfter(day, new Date());
};

const SectionsAnalytics = (props: Props): JSX.Element => {
  const onDatesChange = useCallback(
    (dateRange: { startDate?: Date; endDate?: Date }) => {
      props.onDateRangeFilterChange({
        startDate: dateRange?.startDate ?? new Date(),
        endDate: dateRange?.endDate ?? new Date(),
      });
    },
    [props.onDateRangeFilterChange],
  );

  return (
    <React.Fragment>
      <SectionAnalyticsTypeTab
        sectionId={props.section.id}
        analyticsType={props.analyticsType}
      />
      <StudentFilter sectionId={props.section.id} />
      <div className={styles.tableControl}>
        <div className="flex items-center">
          {(props.analyticsType === "time" ||
            props.analyticsType === "amount") && (
            <>
              <SectionStudyRecordAnalyticsSwitcher
                sectionId={props.section.id}
                currentAnalyticsType={props.analyticsType}
              />
              <div className="ml-[1rem]">
                <FieldVisibilityToggle
                  id="sectionAnalyticsStudyRecord"
                  fieldNames={["プラン", "生徒ID", "学年（職業）"]}
                />
              </div>
            </>
          )}
        </div>
        <DateRangeNavigation
          startDate={props.filter.dateRangeFilter.startDate}
          endDate={props.filter.dateRangeFilter.endDate}
          term={props.filter.term}
          onDatesChange={onDatesChange}
          calendarDisabled={props.term === "monthly" || props.term === "weekly"}
          isDayBlocked={isDayBlocked}
          canPushRightButton={isPushableCalendarNext(
            props.filter.dateRangeFilter.endDate,
            props.term ?? "daily",
          )}
        />
        <div className="flex items-center justify-end gap-x-7">
          <SwitchTabs
            tabs={[
              {
                id: "daily",
                label: "日",
                active: !props.term || props.term === "daily",
              },
              {
                id: "weekly",
                label: "週",
                active: props.term === "weekly",
              },
              {
                id: "monthly",
                label: "月",
                active: props.term === "monthly",
              },
            ]}
            onClickTab={(tab: any) => () => {
              props.onTermChange(tab.id);
            }}
          />
          <PrintButton />
        </div>
      </div>
      <AnalyticsTable
        section={props.section}
        analyticsTable={props.analyticsTable}
        analyticsType={props.analyticsType}
        orderDir={props.orderDir}
        order={props.order}
        onOrderChange={props.onOrderChange}
      />
    </React.Fragment>
  );
};

export default SectionsAnalytics;
