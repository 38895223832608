import { useQuery } from "@tanstack/react-query";
import {
  ExaminationResult,
  ExaminationResultAttachmentFile,
} from "../../domains/ExaminationResult";

export const useFetchExaminationResultAttachments = (
  examinationResult?: ExaminationResult,
) => {
  return useQuery({
    queryKey: cacheKeys(examinationResult),
    queryFn: async () => {
      if (examinationResult === undefined) {
        throw new Error(
          "examinationResult is required to fetch examinationResultAttachments",
        );
      }

      const promises = examinationResult.resultAttachments.map((attachment) => {
        return new Promise<ExaminationResultAttachmentFile>((resolve) => {
          fetch(attachment.url)
            .then((response) => response.blob())
            .then((blobFile) =>
              resolve({
                id: attachment.id,
                file: new File([blobFile], attachment.filename, {
                  type: attachment.contentType,
                }),
              }),
            );
        });
      });

      const attachments = await Promise.all(promises);
      return attachments;
    },
    enabled: examinationResult !== undefined,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

const cacheKeys = (examinationResult: ExaminationResult | undefined) => {
  if (examinationResult === undefined) {
    return ["examinationResultAttachments"];
  }
  return [
    "examinationResultAttachments",
    examinationResult.id,
    ...examinationResult.resultAttachments.map((attachment) => attachment.id),
  ];
};
