import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  LearningMaterialTag,
  LearningMaterialTagList,
  LearningMaterialTagType,
  LearningMaterialTagWithDeletable,
} from "../../../domains/LearningMaterialTag";
import { LearningMaterialTagButton } from "../LearningMaterialTagButton";
import { TagSelector } from "../TagSelector";
import { TagSelectorNewButton } from "../TagSelector/TagSelectorNewButton";

const tagTypeOrder: readonly LearningMaterialTagType[] = [
  "default",
  "have_no_tag",
  "custom",
] as const;

type Props = {
  sectionId: string;
  tags: LearningMaterialTagList;
  selectedTags: LearningMaterialTagList;
  onSelect: (tag: LearningMaterialTag) => void;
  onDeselect: (tag: LearningMaterialTag) => void;
  isSelectable?: (tag: LearningMaterialTag) => boolean;
};
export const LearningMaterialTagSelector = ({
  sectionId,
  tags,
  selectedTags,
  onSelect,
  onDeselect,
  isSelectable,
}: Props) => {
  const navigate = useNavigate();

  const isSelectedTag = (tag: LearningMaterialTag) =>
    selectedTags.some((t) => t.id === tag.id);

  const navigateToTagPage = () => {
    navigate(`/sections/${sectionId}/settings/learning_material_tags`);
  };

  const sortedTags = React.useMemo(
    () =>
      [...tags].sort((tag1, tag2) => {
        const tag1Index = tagTypeOrder.indexOf(tag1.tagType);
        const tag2Index = tagTypeOrder.indexOf(tag2.tagType);
        return tag1Index === tag2Index
          ? tag1.name > tag2.name // tagTypeが同じ場合は名前で並べる
            ? 1
            : -1
          : tag1Index - tag2Index;
      }),
    [tags],
  );

  return (
    <TagSelector
      tags={sortedTags}
      selectedTags={selectedTags as LearningMaterialTagWithDeletable[]}
      renderTag={(tag) => {
        const isSelected = isSelectedTag(tag);
        return (
          <LearningMaterialTagButton
            tag={tag}
            isSelected={isSelected}
            isClickable={isSelectable ? isSelectable(tag) : true}
            onClick={() => (isSelected ? onDeselect(tag) : onSelect(tag))}
          />
        );
      }}
    >
      <TagSelectorNewButton
        label="教材タグを新規作成"
        onClick={navigateToTagPage}
      />
    </TagSelector>
  );
};
