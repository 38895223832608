import classNames from "classnames";
import { FieldProps } from "formik";
import * as React from "react";
import { useCallback } from "react";
import Icon from "../Icon";
import styles from "./styles.scss";

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement>,
    React.ClassAttributes<HTMLDivElement> {
  field?: FieldProps["field"];
  className?: string;
  isBlock?: boolean;
  labelMargin?: "narrow" | "wide"; // default "wide"
  strokeColorLevel?: "lighten1" | "normal" | "darken";
  indeterminate?: boolean; // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate Reactはこのアトリビュート対応してないっぽい
}

const Checkbox: React.FC<Props> = (props: Props) => {
  const {
    isBlock,
    field,
    children,
    key,
    ref,
    className,
    checked,
    onChange,
    labelMargin,
    strokeColorLevel = "lighten1",
    ...attributes
  } = props;

  // field(formik)のpropsが優先される
  const isChecked = field ? field.value : !!checked;
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (field?.onChange) {
        field.onChange(e);
      } else if (onChange) {
        onChange(e);
      }
    },
    [field?.onChange, onChange],
  );
  const iconName = attributes.indeterminate
    ? "icon-checkbox-hyphen"
    : attributes.disabled
      ? "icon-checkbox-disabled"
      : isChecked
        ? "icon-checkbox-checked"
        : "icon-checkbox-none";

  return (
    <div
      className={classNames(className, styles.root, {
        [styles.block]: isBlock,
        [styles.disabled]: attributes.disabled,
      })}
      key={key}
      ref={ref}
    >
      <input
        {...attributes}
        {...field}
        type="checkbox"
        className={styles.input}
        checked={isChecked}
        onChange={handleChange}
      />
      <label
        htmlFor={attributes.id}
        className={classNames(styles.label, {
          [styles.block__label]: isBlock,
        })}
      >
        <Icon
          name={iconName}
          className={classNames(styles.icon, {
            [styles.iconWithLabel]: !!children,
            [styles.narrowMargin]: labelMargin === "narrow",
            [styles.iconChecked]: isChecked || attributes.indeterminate,
            [styles.iconDisabled]: attributes.disabled,
            [styles.strokeColorLighten1]: strokeColorLevel === "lighten1",
            [styles.strokeColorNormal]: strokeColorLevel === "normal",
            [styles.strokeColorDarken]: strokeColorLevel === "darken",
          })}
        />
        <span className={styles.labelText}>{children}</span>
      </label>
    </div>
  );
};

export default Checkbox;
