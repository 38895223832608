import classNames from "classnames";
import * as React from "react";
import Icon from "../../../components/atoms/Icon";
import LinkButton from "../../../components/atoms/LinkButton";
import SortLabel, {
  OrderDirectionType,
} from "../../../components/molecules/SortLabel";
import { useRefreshAnalyticsApi } from "../../../containers/pages/StudentsLearningMaterialTagAnalyticsTimePage/useLearningMaterialTagAnalyticsApi";
import { LearningMaterialTagWithDeletable } from "../../../domains/LearningMaterialTag";
import { calculateDeviation } from "../../../helpers/AnalyticsHelper";
import {
  determineAnalyticsTypeColumnSortOrder,
  determineDateColumnSortOrder,
  toAnalyticsScoreTypeName,
  toHeaderDate,
} from "../../../helpers/AnalyticsHelper";
import TimeHelper from "../../../helpers/TimeHelper";
import { useLearningMaterialTagsCache } from "../../../hooks/http/useLearningMaterialTagApi";
import { useAssociateLearningMaterialTag } from "../../../hooks/http/useLearningMaterialTagApi";
import { AnalyticsType } from "../../../interfaces/AnalyticsTableInterface";
import FiltersInterface, {
  OrderDirFilterType,
} from "../../../interfaces/FiltersInterface";
import {
  LearningMaterialScore,
  LearningMaterialTagAnalyticsHeatMap,
} from "../../../interfaces/StudentAnalyticsTableInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import {
  LearningMaterialTagAssociation,
  LearningMaterialTagModal,
  useOpenLearningMaterialTagModal,
} from "../../features/LearningMaterialTagModal";
import {
  AnalyticsColorDot,
  ColorDotTd,
  HeatMapTd,
  MixedCheckValue,
  MixedCheckbox,
  NoTextTd,
  Table,
  Td,
  Th,
  TitleTd,
  Tr,
} from "../AnalyticsTable";
import styles from "./styles.scss";
import {
  useActiveHeatMapRowMapDispatchers,
  useActiveHeatMapRowMapValues,
  useUpdateActiveHeatMapRowMapWithDeps,
} from "./useActiveHeatMapRowMap";

type UseLearningMaterialTagAnalyticsHeatMapTableProps = {
  student: StudentInterface;
  learningMaterialTagAnalyticsHeatMap?: LearningMaterialTagAnalyticsHeatMap | null;
};
type DeviationMap = { [id: string]: ReadonlyArray<number> };

// Main hooks

export const useLearningMaterialTagAnalyticsHeatMapTable = ({
  student,
  learningMaterialTagAnalyticsHeatMap,
}: UseLearningMaterialTagAnalyticsHeatMapTableProps) => {
  const openedRowMapState = useOpenedRowMap();

  const deviationMap = useDeviationMap({ learningMaterialTagAnalyticsHeatMap });

  useUpdateActiveHeatMapRowMapWithDeps(
    learningMaterialTagAnalyticsHeatMap?.learningMaterialTags ?? null,
  );

  const { modalProps, openModal } = useEditLearningMaterialTagModal(student);

  const activeHeatMapRowMapDispatchers = useActiveHeatMapRowMapDispatchers();
  const activeHeatMapRowMapValues = useActiveHeatMapRowMapValues();
  return {
    deviationMap,
    activeHeatMapRowMapDispatchers,
    activeHeatMapRowMapValues,
    openedRowMapState,
    modalProps,
    openModal,
  };
};

// Main component

/**
 * 教材タグヒートマップ表示用テーブル
 * 既存のアナリティクスを見る限り、学習時間/学習量 でデータのレイアウトは同じはずなので、共通化できるよう切り出しておく
 * (現時点では教材タグのアナリティクスは学習時間のみの実装)
 */
type Props = UseLearningMaterialTagAnalyticsHeatMapTableProps & {
  filter: FiltersInterface;
  analyticsType: AnalyticsType;
  onOrderChange: (
    order?: string | null,
    orderDir?: OrderDirFilterType | null,
  ) => void;
};
export const LearningMaterialTagAnalyticsHeatMapTable = ({
  onOrderChange,
  filter,
  analyticsType,
  learningMaterialTagAnalyticsHeatMap,
  student,
}: Props) => {
  const {
    deviationMap,
    activeHeatMapRowMapDispatchers,
    activeHeatMapRowMapValues,
    openedRowMapState,
    modalProps,
    openModal,
  } = useLearningMaterialTagAnalyticsHeatMapTable({
    learningMaterialTagAnalyticsHeatMap,
    student,
  });

  return (
    <>
      {learningMaterialTagAnalyticsHeatMap && deviationMap && (
        <>
          <Table>
            <thead>
              <Tr>
                <Th>
                  <MixedCheckbox
                    onChanged={(checked) =>
                      !checked
                        ? activeHeatMapRowMapDispatchers.inactivateAll()
                        : activeHeatMapRowMapDispatchers.activateAll()
                    }
                    id="learning-material-tag-analyticsheader"
                    value={activeHeatMapRowMapValues.headerCheckboxChecked}
                  />
                </Th>
                <Th colSpan={3}>教材名</Th>
                <Th>
                  <SortLabel
                    label={toAnalyticsScoreTypeName(analyticsType)}
                    active={!filter.order}
                    orderDirection={
                      (filter.orderDir as OrderDirectionType) ?? "desc"
                    }
                    onClick={() =>
                      onOrderChange(
                        null,
                        determineAnalyticsTypeColumnSortOrder(
                          filter.orderDir ?? "",
                        ),
                      )
                    }
                  />
                </Th>
                {learningMaterialTagAnalyticsHeatMap.columns.map(
                  (queryDate, i) => (
                    <Th key={`lmtag-analytics-header-${i}`}>
                      <SortLabel
                        label={toHeaderDate(
                          queryDate,
                          learningMaterialTagAnalyticsHeatMap.term,
                        )}
                        active={filter.order === queryDate}
                        orderDirection={
                          (filter.orderDir as OrderDirectionType) ?? "desc"
                        }
                        onClick={() =>
                          onOrderChange(
                            queryDate,
                            determineDateColumnSortOrder({
                              currentSortKey: filter.order ?? "",
                              sortKey: queryDate,
                              currentOrder: filter.orderDir ?? "",
                            }),
                          )
                        }
                      />
                    </Th>
                  ),
                )}
              </Tr>
            </thead>
            <tbody>
              {learningMaterialTagAnalyticsHeatMap.learningMaterialTags.map(
                (lmtag, learningMaterialTagIndex) => {
                  const checkboxValue = determineCheckboxValue(
                    activeHeatMapRowMapValues.activeHeatMapRowRecord[lmtag.id],
                    activeHeatMapRowMapValues.noScoreInSpecificTermRecord[
                      lmtag.id
                    ],
                  );
                  const disabled = checkboxValue !== true;
                  // その期間に勉強記録のスコアがないのでチェックボックスもdisableにする
                  const inactiveRow =
                    activeHeatMapRowMapValues.noScoreInSpecificTermRecord[
                      lmtag.id
                    ];

                  return (
                    <React.Fragment key={`lmtag-analytics-row-${lmtag.id}`}>
                      <Tr>
                        <NoTextTd disabled={disabled}>
                          <MixedCheckbox
                            onChanged={(checked) =>
                              checked
                                ? activeHeatMapRowMapDispatchers.activate(
                                    lmtag.id,
                                  )
                                : activeHeatMapRowMapDispatchers.inactivate(
                                    lmtag.id,
                                  )
                            }
                            disabled={inactiveRow}
                            id={lmtag.id}
                            value={checkboxValue}
                          />
                        </NoTextTd>
                        <ColorDotTd disabled={disabled}>
                          <AnalyticsColorDot
                            rank={learningMaterialTagIndex + 1}
                          />
                        </ColorDotTd>
                        <TitleTd disabled={disabled}>
                          <div className={styles.LearningMaterialTagName}>
                            <div>{lmtag.name}</div>
                            <div
                              className={
                                styles.LearningMaterialTagName__Warning
                              }
                            >
                              {lmtag.id === "duplicated" && (
                                <Icon name="icon-warning" />
                              )}
                            </div>
                          </div>
                        </TitleTd>
                        <NoTextTd>
                          <div className={styles["Icon--FillGray"]}>
                            <LinkButton
                              aria-label="この教材タグに設定されている教材一覧のアナリティクスを見る"
                              onClick={() => openedRowMapState.toggle(lmtag.id)}
                            >
                              {openedRowMapState.openedRowMap[lmtag.id] ? (
                                <Icon name="icon-arrow-up" />
                              ) : (
                                <Icon name="icon-arrow-down" />
                              )}
                            </LinkButton>
                          </div>
                        </NoTextTd>
                        <Td disabled={disabled}>
                          {TimeHelper.secondsToDisplayTime(lmtag.totalScore)}
                        </Td>
                        {lmtag.scores.map((score, i) => (
                          <HeatMapTd
                            disabled={disabled}
                            key={`lmtag-analytics-heatmap-${lmtag.id}-${i}`}
                            deviation={deviationMap[lmtag.id][i]}
                            score={score}
                          >
                            {TimeHelper.secondsToDisplayTime(score)}
                          </HeatMapTd>
                        ))}
                      </Tr>
                      {openedRowMapState.openedRowMap[lmtag.id] &&
                        lmtag.learningMaterials.map(
                          (learningMaterialScore, i) => {
                            const isNotFirstRow = i !== 0;
                            const lastRowClass =
                              i === lmtag.learningMaterials.length - 1
                                ? styles[
                                    "LearningMaterialConnector--HalfHeight"
                                  ]
                                : null;
                            return (
                              <Tr
                                key={`child-learning-material-${learningMaterialScore.learningMaterialCode}`}
                              >
                                <NoTextTd
                                  disabled={disabled}
                                  noBorderTop={isNotFirstRow}
                                />
                                <ColorDotTd
                                  disabled={disabled}
                                  noBorderTop={isNotFirstRow}
                                  positionRelative
                                >
                                  <span
                                    className={classNames(
                                      styles.LearningMaterialConnector,
                                      lastRowClass,
                                    )}
                                  />
                                  <AnalyticsColorDot
                                    rank={learningMaterialTagIndex + 1}
                                  />
                                </ColorDotTd>
                                <TitleTd
                                  disabled={disabled}
                                  noBorderTop={isNotFirstRow}
                                >
                                  <div
                                    className={styles.LearningMaterialTagName}
                                  >
                                    <div>{learningMaterialScore.name}</div>
                                  </div>
                                </TitleTd>
                                <NoTextTd noBorderTop={isNotFirstRow}>
                                  <div className={styles["Icon--FillGray"]}>
                                    <LinkButton
                                      aria-label="この教材の教材タグを編集するモーダルを開く"
                                      onClick={() =>
                                        openModal(learningMaterialScore)
                                      }
                                    >
                                      <Icon
                                        name="icon-import-tag"
                                        className={styles.TagIcon}
                                      />
                                    </LinkButton>
                                  </div>
                                </NoTextTd>
                                <Td
                                  disabled={disabled}
                                  noBorderTop={isNotFirstRow}
                                >
                                  {TimeHelper.secondsToDisplayTime(
                                    learningMaterialScore.totalScore,
                                  )}
                                </Td>
                                {learningMaterialScore.scores.map(
                                  (score, i) => (
                                    <HeatMapTd
                                      disabled={disabled}
                                      key={`lmtag-analytics-heatmap-${lmtag.id}-${i}`}
                                      deviation={
                                        deviationMap[
                                          learningMaterialScore
                                            .learningMaterialCode
                                        ][i]
                                      }
                                      score={score}
                                    >
                                      {TimeHelper.secondsToDisplayTime(score)}
                                    </HeatMapTd>
                                  ),
                                )}
                              </Tr>
                            );
                          },
                        )}
                    </React.Fragment>
                  );
                },
              )}
            </tbody>
          </Table>
          <LearningMaterialTagModal {...modalProps} />
        </>
      )}
    </>
  );
};

// Helpers

type UseDeviationMapProps = {
  learningMaterialTagAnalyticsHeatMap?: LearningMaterialTagAnalyticsHeatMap | null;
};
/**
 * calculateDeviation して得た値をメモ化するhooks
 */
const useDeviationMap = ({
  learningMaterialTagAnalyticsHeatMap,
}: UseDeviationMapProps) =>
  React.useMemo<DeviationMap | null>(() => {
    if (!learningMaterialTagAnalyticsHeatMap) {
      return null;
    }
    const flattedScores =
      learningMaterialTagAnalyticsHeatMap.learningMaterialTags.flatMap(
        (item) => {
          return [
            ...item.scores,
            ...item.learningMaterials.flatMap((material) => material.scores),
          ];
        },
      );

    const deviationMap: Record<string, number[]> = {};
    for (const tagScore of learningMaterialTagAnalyticsHeatMap.learningMaterialTags) {
      deviationMap[tagScore.id] = tagScore.scores.map((score) =>
        calculateDeviation(flattedScores, score),
      );
      // 教材タグ別のものとネストしてる教材別のものを並列にテーブルに表示するので、ネストした教材別のも計算してmapに保存する
      for (const materialScore of tagScore.learningMaterials) {
        deviationMap[materialScore.learningMaterialCode] =
          materialScore.scores.map((score) =>
            calculateDeviation(flattedScores, score),
          );
      }
    }
    return deviationMap;
  }, [learningMaterialTagAnalyticsHeatMap]);

/**
 * 開いている教材タグの行を保持するState
 */
const useOpenedRowMap = () => {
  const [openedRowMap, setOpenedRowMap] = React.useState<
    Record<string, boolean>
  >({});

  const toggle = React.useCallback((id: string) => {
    setOpenedRowMap((previous) => {
      const newValue = !previous[id];
      return { ...previous, [id]: newValue };
    });
  }, []);

  return { openedRowMap, toggle };
};

const determineCheckboxValue = (
  checked: boolean,
  noRecord: boolean,
): MixedCheckValue => {
  if (noRecord) {
    return "indeterminate";
  }
  return checked;
};

const useEditLearningMaterialTagModal = (student: StudentInterface) => {
  const modalState = useOpenLearningMaterialTagModal();
  const learningMaterialTags = useLearningMaterialTagsCache(student.section.id);
  const openModal = React.useCallback(
    (learningMaterialScore: LearningMaterialScore) => {
      modalState.openModal({
        learningMaterialName: learningMaterialScore.name,
        learningMaterialCode: learningMaterialScore.learningMaterialCode,
        learningMaterialTags:
          learningMaterialScore.attachedLearningMaterialTags ?? [],
      });
    },
    [learningMaterialTags],
  );

  const apiDataRefresher = useRefreshAnalyticsApi(student);

  const { mutate, isLoading } = useAssociateLearningMaterialTag({
    sectionId: student.section.id,
    onSuccess: () => {
      modalState.closeModal();
      apiDataRefresher.execute();
    },
  });

  const onSubmit = (
    learningMaterial: LearningMaterialTagAssociation,
    tags: ReadonlyArray<LearningMaterialTagWithDeletable>,
  ) => {
    mutate({
      learningMaterialCode: learningMaterial.learningMaterialCode,
      learningMaterialTags: tags.filter((tag) => tag.isDeletable),
    });
  };

  const modalProps = {
    sectionId: student.section.id,
    selectableTags: learningMaterialTags ?? [],
    currentLearningMaterial: modalState.currentLearningMaterial,
    isOpenModal: Boolean(modalState.currentLearningMaterial),
    onSubmit,
    isSubmitting: isLoading,
    closeModal: modalState.closeModal,
  };

  return {
    modalProps,
    openModal,
    isSubmitting: isLoading,
  };
};
