import classNames from "classnames";
import { Field, Form, FormikProps, withFormik } from "formik";
import flatMap from "lodash-es/flatMap";
import * as React from "react";
import { useState } from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import * as yup from "yup";

import { Button } from "@studyplus/boron-ui";
import { Chart } from "chart.js";
import format from "date-fns/format";
import {
  postAnalyticsShare,
  toggleModal,
} from "../../../actions/studentAnalyticsTable";
import { AnalyticsTermType } from "../../../interfaces/AnalyticsTableInterface";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { StudentAnalyticsRowInterface } from "../../../interfaces/StudentAnalyticsTableInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import Icon from "../../atoms/Icon";
import Label from "../../atoms/Label";
import { Modal } from "../../atoms/Modal";
import Textarea from "../../atoms/Textarea";
import StudentAnalyticsGraph, {
  Props as GraphProps,
} from "../../molecules/StudentAnalyticsGraph";
import SharingAnalyticsGraph from "./SharingAnalyticsGraph";
import styles from "./styles.scss";

const MAX_MESSAGE_LENGTH = 2000;

type GraphComponentProps = {
  [key in keyof GraphProps]?: GraphProps[key];
};

interface DispatchProps {
  postAnalyticsShare: HandleThunkActionCreator<typeof postAnalyticsShare>;
  toggleModal: HandleThunkActionCreator<typeof toggleModal>;
}

interface StateProps {
  submitting: boolean;
  isOpen: boolean;
}

interface OwnProps extends GraphComponentProps {
  startDate: Date | null;
  endDate: Date | null;
  term: AnalyticsTermType;
  student: StudentInterface;
  totalScore: number;
}

type Props = DispatchProps & StateProps & OwnProps;

interface Values {
  message: string | null;
  image: Blob;
}

const canvasToBlob = (canvas: HTMLCanvasElement): Blob => {
  const base64 = canvas.toDataURL("image/png");
  // Base64からバイナリへ変換
  const bin = atob(base64.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  const blob = new Blob([buffer.buffer], {
    type: "image/png",
  });
  return blob;
};

const formatDateRange = (
  startDate: Date | null,
  endDate: Date | null,
  term: AnalyticsTermType,
): string => {
  return `${formatDate(startDate, term)}〜${formatDate(endDate, term)}`;
};

const formatDate = (
  date: Date | null,
  term: AnalyticsTermType | undefined,
): string => {
  if (!date) {
    return "";
  }
  switch (term) {
    case "weekly":
      return format(date, "yyyy年MM月dd日週");
    case "monthly":
      return format(date, "yyyy年MM月");
    case "daily":
    default:
      return format(date, "yyyy年MM月dd日（E）");
  }
};

const PureAnalyticsShareButton: React.FC<Props & FormikProps<Values>> = (
  props,
) => {
  const [chart, setChart] = useState<Chart | null>(null);

  if (props.student.lineConnectionStatus !== "connected") {
    return null;
  }

  const openModal = () => {
    props.toggleModal(true);
  };
  const closeModal = () => {
    props.toggleModal(false);
  };
  const messageLength = Array.from(props.values.message || "").length;

  if (!props.learningMaterials || !props.analyticsType || !props.columns) {
    return (
      <button className={classNames([styles.button, styles.disabled])}>
        <Icon name="icon-line" className={styles.lineIcon} />
      </button>
    );
  }

  const sumScore = flatMap(
    props.learningMaterials,
    (learningMateral: StudentAnalyticsRowInterface) => learningMateral.scores,
  ).reduce((prev: number, current = 0) => prev + current, 0);

  const setSendImage = (canvas: HTMLCanvasElement) => {
    const image = canvasToBlob(canvas);
    props.setFieldValue("image", image);
  };

  const rangeString = formatDateRange(
    props.startDate,
    props.endDate,
    props.term,
  );
  const analyticsType =
    props.analyticsType === "amount" ? "学習量" : "学習時間";
  const placeholder = `${props.student.fullName}さんの${rangeString}の${analyticsType}をお送りします。`;

  return (
    <>
      <button className={styles.button} onClick={openModal}>
        <Icon name="icon-line" className={styles.lineIcon} />
      </button>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={closeModal}
        className={styles.modal}
      >
        <Modal.Header onClose={closeModal}>
          <h2>{props.student.fullName}のアナリティクスを送信</h2>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className={styles.messageLabelLine}>
              <Label className={styles.messageLabel}>メッセージ</Label>
              <div className={styles.messageLength}>
                {messageLength}/{MAX_MESSAGE_LENGTH}字
              </div>
            </div>
            <Field
              name="message"
              component={Textarea}
              placeholder={placeholder}
              className={styles.message}
            />
            <SharingAnalyticsGraph
              startDate={props.startDate}
              endDate={props.endDate}
              term={props.term}
              rangeString={rangeString}
              chart={chart}
              sumScore={sumScore}
              totalScore={props.totalScore}
              analyticsType={props.analyticsType}
              setSendImage={setSendImage}
            />
            <div className={styles.submitButton}>
              <Button
                disabled={props.submitting || !props.isValid}
                type="submit"
              >
                送信
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <div className={styles.render}>
        <StudentAnalyticsGraph
          learningMaterials={props.learningMaterials}
          term={props.term}
          analyticsType={props.analyticsType}
          columns={props.columns}
          setChart={setChart}
        />
      </div>
    </>
  );
};

const AnalyticsShareButton = withFormik<Props, Values>({
  handleSubmit: (values, formikBag) => {
    const { image } = values;

    let message = values.message || "";
    if (Array.from(message).length === 0) {
      const { startDate, endDate, term, analyticsType, student } =
        formikBag.props;
      const rangeString = formatDateRange(startDate, endDate, term);
      const analyticsTypeDisplay =
        analyticsType === "amount" ? "学習量" : "学習時間";
      message = `${student.fullName}さんの${rangeString}の${analyticsTypeDisplay}をお送りします。`;
    }
    formikBag.props.postAnalyticsShare(
      formikBag.props.student.id,
      message,
      image,
    );
  },
  validationSchema: yup.object().shape({
    message: yup.string().trim().min(0).max(MAX_MESSAGE_LENGTH),
  }),
  isInitialValid: true,
})(PureAnalyticsShareButton);

const mapStateToProps = (state: AppStateInterface) => ({
  submitting: state.studentAnalyticsTable.submittingShare,
  isOpen: state.studentAnalyticsTable.isOpenShareModal,
});

const mapDispatchToProps = {
  postAnalyticsShare,
  toggleModal,
};

export default connect<StateProps, DispatchProps, OwnProps, AppStateInterface>(
  mapStateToProps,
  mapDispatchToProps,
)(AnalyticsShareButton);
