import { useContentCourseDetailQuery } from "../../../hooks/http/useContentCoursesApi";
import { useQueryError } from "../../../hooks/http/useQueryError";
import SectionInterface from "../../../interfaces/SectionInterface";

type UseSectionsSettingsContentCourseDetail = {
  section: SectionInterface;
  courseId: string | null;
};

export const useExistingCourse = (
  props: UseSectionsSettingsContentCourseDetail,
) => {
  const { course, isLoading, error } = useContentCourseDetailQuery({
    sectionId: props.section.id,
    courseId: props.courseId,
    disableRefetchOnWindowFocus: true,
  });
  useQueryError(error);

  return {
    course,
    isLoading,
  };
};
