import { useEffect } from "react";
import SectionsSettingsStudents from "../../features/SectionsSettingsStudentsPage/SectionsSettingsStudents";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../hocs/enhanceAuthenticatedPage";
import enhanceSectionsSettingsPage from "../../hocs/enhanceSectionsSettingsPage";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import OperatorPrivilegesStateInterface from "../../interfaces/OperatorPrivilegesStateInterface";
import SectionInterface from "../../interfaces/SectionInterface";
import { useFetchStudents } from "./useFetchStudents";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    OutletContextProps {
  section: SectionInterface;
  operatorPrivileges: OperatorPrivilegesStateInterface;
}

const SectionsSettingsStudentsPage = (props: Props) => {
  const { section } = props;

  useEffect(() => {
    props.setActiveMenu("students");
  }, []);

  const {
    data,
    isLoading,
    isFetchingNextPage,
    loadMore,
    updateStudent,
    removeStudent,
    changeOrder,
    orderDir,
  } = useFetchStudents({
    sectionId: section.id,
  });

  useOnMainScrollAreaScroll(loadMore, [loadMore]);

  if (!props.operatorPrivileges.data) {
    return null;
  }

  const students = data?.pages.flatMap((page) => page.data) || [];

  return (
    <SectionsSettingsStudents
      section={props.section}
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      students={students}
      updateStudent={updateStudent}
      removeStudent={removeStudent}
      operatorRole={props.operatorPrivileges.data.role}
      onOrderChange={changeOrder}
      orderDir={orderDir}
    />
  );
};

const pageInfo = {
  title: "生徒",
};

export default enhanceSectionsSettingsPage(
  SectionsSettingsStudentsPage,
  pageInfo,
);
