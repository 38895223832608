import * as React from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import {
  changeSectionKartesFilter,
  loadInitialSectionKartes,
  loadSectionKartes,
} from "../../../actions/sectionKartes";
import SectionsKartes from "../../../components/organisms/SectionsKartes/index";
import {
  OutletContextProps,
  WithRouterProps,
  useOnLocationChange,
  withRouter,
} from "../../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../../hocs/enhanceAuthenticatedPage";
import enhanceSectionsPage from "../../../hocs/enhanceSectionsPage";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { KartesStateInterface } from "../../../interfaces/KarteInterface";
import SectionInterface from "../../../interfaces/SectionInterface";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    OutletContextProps {
  section: SectionInterface | null;
  kartes: KartesStateInterface;
  loadSectionKartes: (sectionId: string, page: number) => void;
  loadInitialSectionKartes: HandleThunkActionCreator<
    typeof loadInitialSectionKartes
  >;
  changeSectionKartesFilter: (sectionId: string, query: string) => void;
}

const SectionsKartesPage = ({
  params,
  section,
  kartes,
  loadInitialSectionKartes,
  loadSectionKartes,
  changeSectionKartesFilter,
  setActiveMenu,
}: Props) => {
  const { sectionId } = params;

  React.useEffect(() => {
    loadInitialSectionKartes(sectionId);
    setActiveMenu("karte");
  }, []);

  useOnLocationChange(() => {
    if (location.search) {
      const query = location.search.toString();
      changeSectionKartesFilter(sectionId, query);
    }
  });

  useOnMainScrollAreaScroll(() => {
    if (kartes.loading || !kartes.hasMore) {
      return;
    }

    loadSectionKartes(sectionId, kartes.meta.currentPage + 1);
  }, [kartes.loading, kartes.hasMore, sectionId]);

  if (!section) {
    return null;
  }

  return <SectionsKartes section={section} kartes={kartes} />;
};

const mapStateToProps = (state: AppStateInterface) => ({
  kartes: state.sectionKartes,
});

const actions = {
  loadInitialSectionKartes,
  loadSectionKartes,
  changeSectionKartesFilter,
};

const ConnectedSectionsKartesPage = connect(
  mapStateToProps,
  actions,
)(SectionsKartesPage);

export default withRouter<Props>(
  enhanceSectionsPage(ConnectedSectionsKartesPage, {
    title: "カルテ",
  }),
);
