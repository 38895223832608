import * as React from "react";
import { useNavigate } from "react-router";
import { FileTypes } from "../../../domains/Content";
import { DispatchProps } from "../../../hocs/enhanceAuthenticatedPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import { useFilesState } from "./useFilesState";
import { useMutateFile } from "./useMutateFile";

export type UseSectionSettingsNewContentFilesProps = Pick<
  DispatchProps,
  "showErrorFlashMessage" | "showSuccessFlashMessage"
> & {
  section: SectionInterface;
};

export const useSectionSettingsNewContentFiles = ({
  section,
  showErrorFlashMessage,
  showSuccessFlashMessage,
}: UseSectionSettingsNewContentFilesProps) => {
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const { state, dispatch } = useFilesState();
  const { mutate } = useMutateFile({
    section,
    files: state.context.files,
    onSuccessUploadSingleFile: (id: string) =>
      dispatch({ type: "OnSuccessUploadFile", id }),
    onDoneUploadAllFiles: () => dispatch({ type: "onCompleteAllUpload" }),
  });

  const onChangeFiles = () => {
    if (!inputFileRef.current || inputFileRef.current.files === null) {
      return;
    }
    dispatch({
      type: "OnChooseFile",
      files: Array.from(inputFileRef.current.files),
    });
    inputFileRef.current.value = "";
  };

  const onClickFile = () => {
    inputFileRef.current?.click();
  };

  const onClickDelete = (id: string) => {
    dispatch({ type: "OnDelete", id });
  };

  const onSubmit = () => {
    dispatch({ type: "OnUpload" });
    mutate();
  };

  React.useEffect(() => {
    if (state.status === "Complete") {
      showSuccessFlashMessage("ファイルの登録を完了しました");
      navigate(`/sections/${section.id}/settings/contents`);
    }
    if (state.status === "UploadError") {
      showErrorFlashMessage(
        "ファイルの登録に失敗しました。しばらく間を開けてから再度お試しください。",
      );
    }
  }, [state.status]);

  return {
    filesState: state,
    inputFileRef,
    onClickFile,
    onChangeFiles,
    onClickDelete,
    onSubmit,
    acceptTypes: FileTypes.join(", "),
  };
};
