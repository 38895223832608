import { Flex } from "../../../components/atoms/Flex";
import {
  FixedTable,
  TableWrapper,
  Td,
  Th,
} from "../../../components/features/AnalyticsTable";
import { NameLink } from "../../../components/features/AnalyticsTable/NameLink";
import { StudentNameColumn } from "../../../components/features/AnalyticsTable/StudentNameColumn";
import { AttendanceStatus } from "../../../components/features/AttendanceStatus";
import { JobHumanLabel } from "../../../components/features/JobHumanLabel";
import { LectureTypeLabel } from "../../../components/features/Schedule/LectureTypeLabel";
import { Text } from "../../../components/general/Text";
import SortLabel, {
  OrderDirectionType,
} from "../../../components/molecules/SortLabel";
import { LectureStudentAnalytics } from "../../../domains/LectureStudentAnalytics";
import { OrderType } from "../../../domains/LectureStudentAnalytics";
import { StartEndTitleHeader } from "../StartEndTitleHeader";
import type { OnClickSortHandlerType } from "../types";
import styles from "./AnalyticsLectureStudentTable.scss";

export type Props = {
  sectionId: string;
  lectureStudentAnalytics: LectureStudentAnalytics;
  onClickSort: OnClickSortHandlerType;
  sortedColumn?: OrderType;
  sortOrderDirectionType?: OrderDirectionType;
};
export const AnalyticsLectureStudentTable = ({
  sectionId,
  sortedColumn,
  sortOrderDirectionType,
  onClickSort,
  lectureStudentAnalytics,
}: Props) => {
  const { columns, students } = lectureStudentAnalytics;
  return (
    <TableWrapper>
      <FixedTable className={styles.table}>
        <thead>
          <tr>
            <Th className={styles.nameHeader}>氏名</Th>
            <Th className={styles.scoreHeader}>
              <SortLabel
                label="出席率"
                onClick={() => {
                  onClickSort(
                    "totalScore",
                    determineSortOrder({
                      currentOrderType: sortedColumn,
                      newOrderType: "totalScore",
                      currentSortDirection: sortOrderDirectionType,
                    }),
                  );
                }}
                active={sortedColumn === "totalScore"}
                orderDirection={sortOrderDirectionType}
              />
            </Th>
            <Th className={styles.scoreHeader}>
              <SortLabel
                label={
                  <Text
                    as="span"
                    color={
                      sortedColumn === "withTransferredTotalScore"
                        ? "primary"
                        : "gray-darken-2"
                    }
                    align="center"
                    size="xs"
                  >
                    振替先含む
                    <br />
                    出席率
                  </Text>
                }
                onClick={() => {
                  onClickSort(
                    "withTransferredTotalScore",
                    determineSortOrder({
                      currentOrderType: sortedColumn,
                      newOrderType: "withTransferredTotalScore",
                      currentSortDirection: sortOrderDirectionType,
                    }),
                  );
                }}
                active={sortedColumn === "withTransferredTotalScore"}
                orderDirection={sortOrderDirectionType}
              />
            </Th>
            {Array.from(new Array(7)).map((_, i) => {
              return (
                <Th key={`start-end-header-${i}`}>
                  {columns[i] ? (
                    <StartEndTitleHeader
                      allday={columns[i].allday}
                      startAt={columns[i].startAt}
                      endAt={columns[i].endAt}
                    />
                  ) : null}
                </Th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {students.map((student) => {
            return (
              <tr key={`row-${student.id}`}>
                <Td wordWrap>
                  <StudentNameColumn>
                    <Text size="xs" color="black-lighten-2">
                      {student.rank}
                    </Text>
                    <Flex marginTop="0" alignItems="center" gap="1.6">
                      {student.section.id !== sectionId && (
                        <LectureTypeLabel lectureType="fromOtherSection" />
                      )}
                      <NameLink
                        to={{
                          pathname: `/students/${student.id}/analytics/lecture_attendance`, // 仮
                          search: window.location.search,
                        }}
                      >
                        {student.fullName}
                        {student.section.id !== sectionId &&
                          `（${student.section.sectionName}）`}
                      </NameLink>
                    </Flex>
                    <div>
                      <JobHumanLabel>{student.jobHuman}</JobHumanLabel>
                    </div>
                  </StudentNameColumn>
                </Td>
                <Td>{Math.round(student.totalScore * 100)}%</Td>
                <Td>
                  <Text as="span" color="gray-darken-2" align="center">
                    {Math.round(student.withTransferredTotalScore * 100)}%
                  </Text>
                </Td>
                {Array.from(new Array(7)).map((_, i) => {
                  const attendanceStatus = student.attendanceStatuses[i];
                  const transferredAttendanceStatus =
                    student.transferredAttendanceStatuses[i];
                  return (
                    <Td key={`cell-${student.id}-${i}`}>
                      {/* 出席ステータスの配列が 7未満のケースがあが、その場合でもセルは7個埋めておきたいため */}
                      {attendanceStatus ? (
                        <AttendanceStatus
                          attendanceStatus={attendanceStatus}
                          transferredAttendanceStatus={
                            transferredAttendanceStatus
                          }
                        />
                      ) : (
                        <>ー</>
                      )}
                    </Td>
                  );
                })}
              </tr>
            );
          })}
          {students.length === 0 && columns.length > 0 && (
            <tr>
              <Td colSpan={10}>対象生徒が設定されていません。</Td>
            </tr>
          )}
          {students.length === 0 && columns.length === 0 && (
            <tr>
              <Td colSpan={10}>講義が登録されていません。</Td>
            </tr>
          )}
        </tbody>
      </FixedTable>
    </TableWrapper>
  );
};

type DetermineSortOrderParams = {
  currentOrderType?: OrderType;
  newOrderType: OrderType;
  currentSortDirection?: OrderDirectionType;
};
const determineSortOrder = (
  params: DetermineSortOrderParams,
): OrderDirectionType => {
  if (params.currentOrderType !== params.newOrderType) {
    return "asc";
  }
  return params.currentSortDirection === "asc" ? "desc" : "asc";
};
