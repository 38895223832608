import * as React from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import { acceptInvitation, denyInvitation } from "../../../actions/invitation";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { SectionOperatorInvitationStateInterface } from "../../../interfaces/SectionOperatorsInterface";
import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader/index";
import styles from "./styles.scss";

interface Props extends WithRouterProps {
  invitationState: SectionOperatorInvitationStateInterface;
  acceptInvite: HandleThunkActionCreator<typeof acceptInvitation>;
  denyInvite: HandleThunkActionCreator<typeof denyInvitation>;
}

interface State {
  submitting: boolean;
}

class InvitationAccept extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { submitting: false };
  }

  render() {
    if (this.props.invitationState.loading) {
      return <Loader loading={this.props.invitationState.loading} />;
    }

    if (!this.props.invitationState.data) {
      return null;
    }

    return (
      <div className={styles.root}>
        <div className={styles.heading}>
          <p>{this.props.invitationState.data.fullName}から</p>
          <p>招待されています</p>
        </div>
        <div className={styles.body}>
          <p>{this.props.invitationState.data.fullName}に所属する場合は、</p>
          <p>招待の承認をするとスタッフメンバーに登録されます。</p>
        </div>
        <div className={styles.accept}>
          <Button
            disabled={
              this.props.invitationState.loading || this.state.submitting
            }
            onClick={this.acceptInvite}
          >
            {" "}
            招待を承認{" "}
          </Button>
        </div>
        <div>
          {this.props.invitationState.data.role === "admin" ? null : (
            <button
              disabled={
                this.props.invitationState.loading || this.state.submitting
              }
              className={styles.deny}
              onClick={this.denyInvite}
            >
              {" "}
              承認しない
            </button>
          )}
        </div>
      </div>
    );
  }

  private acceptInvite = () => {
    const { data } = this.props.invitationState;
    if (!data) return;

    this.props.acceptInvite(data, this.props.navigate, this.afterRequest);
  };

  private denyInvite = () => {
    const { data } = this.props.invitationState;
    if (!data) return;

    this.props.denyInvite(data, this.props.navigate, this.afterRequest);
  };

  private afterRequest = () => {
    this.setState({ submitting: false });
  };
}

const mapStateToProps = (state: AppStateInterface) => ({
  invitationState: state.invitation,
});

const actions = {
  acceptInvite: acceptInvitation,
  denyInvite: denyInvitation,
};

const ConnectedComponent = connect(mapStateToProps, actions)(InvitationAccept);

export default withRouter<Props>(ConnectedComponent);
