import { useMutation } from "@tanstack/react-query";
import { boronClient } from "../../../../../../../api";
import { HTTPErrors, createError } from "../../../../../../../errors";
import { StudyRecordCommentInterface } from "../../../../../../../interfaces/StudyRecordInterface";
import { paths } from "../../../../../../../lib/api/v1";

type TimelineCommentRequestBody =
  paths["/api/v1/students/{student_id}/study_records/{study_record_hashid}/comments"]["post"]["requestBody"]["content"]["application/json"]["body"];

type Props = {
  studentId: string;
  studyRecordHashid: string;
  onSuccess: (comment: StudyRecordCommentInterface) => void;
  onError: () => void;
};

export const usePostTimelineComment = ({
  studentId,
  studyRecordHashid,
  onSuccess,
  onError,
}: Props) => {
  return useMutation<
    StudyRecordCommentInterface,
    HTTPErrors,
    TimelineCommentRequestBody
  >({
    mutationFn: (recordComment) =>
      postStudyRecordComment({ recordComment, studentId, studyRecordHashid }),
    onSuccess,
    onError,
  });
};

const postStudyRecordComment = async ({
  studentId,
  studyRecordHashid,
  recordComment,
}: Omit<Props, "onSuccess" | "onError"> & {
  recordComment: TimelineCommentRequestBody;
}) => {
  const URL =
    "/api/v1/students/{student_id}/study_records/{study_record_hashid}/comments";

  const { response, data } = await boronClient.POST(URL, {
    body: {
      body: recordComment,
    },
    params: {
      path: {
        student_id: studentId,
        study_record_hashid: studyRecordHashid,
      },
    },
  });

  if (response.ok && data) return data.studyRecordComment;

  throw await createError(response);
};
