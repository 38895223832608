import * as React from "react";
import { Schedule } from "../../../../domains/Schedule";
import { MutateScheduleProps, useDeleteSchedule } from "../useScheduleApi";

export type UseDeleteWithRecurrencesProps = {
  onClose: () => void;
  schedule: Schedule;
  onMutateSuccess: (message: string) => void;
  onMutateError: (message: string) => void;
} & MutateScheduleProps;

export const useDeleteWithRecurrences = (
  props: UseDeleteWithRecurrencesProps,
) => {
  const { studentId, from, to, schedule } = props;

  const mutateState = useDeleteSchedule({
    studentId,
    from,
    to,
  });

  const onSubmit = React.useCallback(
    (updateType: any) => {
      mutateState.mutate({
        schedule: {
          ...schedule,
          recurrence: updateType === "this" ? null : schedule.recurrence,
        },
        updateType,
      });
    },
    [schedule, mutateState],
  );

  React.useEffect(() => {
    if (!mutateState.isLoading && mutateState.data) {
      props.onClose();
      props.onMutateSuccess("削除を完了しました");
    }
  }, [mutateState.data, mutateState.isLoading]);

  React.useEffect(() => {
    if (mutateState.isError) {
      props.onMutateError("削除に失敗しました");
    }
  }, [mutateState.isError, mutateState.error]);

  return {
    schedule,
    isUpdating: mutateState.isLoading,
    onSubmit,
  };
};
