import { fromJson as fromJsonSchedule } from "../domain-adapters/Schedule";
import {
  LectureSessionDetail,
  LectureSessionWithSchedule,
} from "../domains/LectureSession";
import { fromJson as fromJsonLecture } from "./Lecture";

export const fromJson = (json: unknown): LectureSessionDetail => {
  const record = json as Record<string, any>;

  return {
    canceled: record.id,
    totalCount: record.totalCount,
    attendanceCount: record.attendanceCount,
    absenceCount: record.absenceCount,
    fromOtherSectionCount: record.fromOtherSectionCount,
    transferCount: record.transferCount,
    transferAttendanceCount: record.transferAttendanceCount,
    transferAbsenceCount: record.transferAbsenceCount,
    lecture: fromJsonLecture(record.lecture),
    schedule: fromJsonSchedule(record.schedule),
    classroom: record.classroom,
    teacher: record.teacher,
  } as LectureSessionDetail;
};

export const toLectureSessionWithSchedule = (
  json: Record<string, any>,
): LectureSessionWithSchedule => {
  return {
    id: json.id,
    canceled: json.canceled,
    totalCount: json.totalCount,
    attendanceCount: json.attendanceCount,
    absenceCount: json.absenceCount,
    lecture: json.lecture,
    schedule: fromJsonSchedule(json.schedule),
  };
};
