import Loader from "../../../components/atoms/Loader/index";
import SubLayout from "../../../components/atoms/SubLayout";
import ActivationForm from "../../../components/organisms/ActivationForm";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import withErrorPages from "../../../hocs/withErrorPages";
import { useQueryError } from "../../../hooks/http/useQueryError";
import styles from "./styles.scss";
import { useFetchCheckToken } from "./useFetchCheckToken";

const ActivationPage = (
  props: WithRouterProps<{ emailActivationToken: string }>,
) => {
  const { isLoading, error } = useFetchCheckToken({
    token: props.params.emailActivationToken,
  });
  useQueryError(error);

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  return (
    <SubLayout>
      <SubLayout.Header />
      <SubLayout.Main>
        <div className={styles.root}>
          <SubLayout.Heading>新規登録</SubLayout.Heading>
          <ActivationForm token={props.params.emailActivationToken} />
        </div>
      </SubLayout.Main>
      <SubLayout.Footer />
    </SubLayout>
  );
};

export default withRouter(withErrorPages(ActivationPage));
