import * as React from "react";
import ContentBox from "../../components/atoms/ContentBox";
import Loader from "../../components/atoms/Loader";
import { DrillProgressLegend } from "../../components/features/DrillProgressLegend";
import { StudentAnalyticsTypeTab } from "../../components/features/StudentAnalyticsTypeTab";
import { OutletContextProps } from "../../helpers/RouterHelper";
import enhanceStudentsPage from "../../hocs/enhanceStudentsPage";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import StudentInterface from "../../interfaces/StudentInterface";
import { RetentionLevelTable } from "./RetentionLevelTable";
import { useFetchStudentDrillRetentionLevel } from "./useFetchStudentDrillRetentionLevel";

type Props = OutletContextProps & {
  student: StudentInterface;
};

const HELP_URL = "https://fs-help.studyplus.co.jp/ja/articles/8329266";

const StudentsAnalyticsRetentionLevelPage_ = ({
  student,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("analytics");
  }, []);

  const studentId = student.id;
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useFetchStudentDrillRetentionLevel({
      studentId,
    });

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  return (
    <>
      <div className="mb-4">
        <StudentAnalyticsTypeTab
          student={student}
          activeAnalyticsType="retentionLevel"
        />
      </div>

      <div className="py-6">
        <DrillProgressLegend helpUrl={HELP_URL} />
      </div>

      {isLoading || data == null ? (
        <Loader loading />
      ) : (
        <ContentBox className="overflow-hidden p-0">
          {data && <RetentionLevelTable data={data} student={student} />}
        </ContentBox>
      )}
      {isFetchingNextPage && <Loader loading />}
    </>
  );
};

const pageInfo = {
  title: "アナリティクス",
};

export const StudentsAnalyticsRetentionLevelPage = enhanceStudentsPage(
  StudentsAnalyticsRetentionLevelPage_,
  pageInfo,
);
