import classNames from "classnames";
import * as React from "react";
import { Column } from "..";
import styles from "./styles.scss";

export interface NavigationProps extends React.PropsWithChildren<unknown> {
  focusedColumn: Column;
}

const Navigation = (props: NavigationProps) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.focused]: props.focusedColumn === Column.Left,
      })}
    >
      {props.children}
    </div>
  );
};

export default Navigation;
