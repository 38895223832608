import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import { fromJson } from "../../domain-adapters/Lecture";
import { Lecture } from "../../domains/Lecture";
import { HTTPErrors, createError } from "../../errors";
import SectionInterface from "../../interfaces/SectionInterface";

type UseFetchLectureDetailProps = {
  section: SectionInterface;
  lectureId: string;
  skip: boolean;
};

export const useFetchLectureDetail = ({
  section,
  lectureId,
  skip,
}: UseFetchLectureDetailProps) => {
  return useQuery<Lecture, HTTPErrors>({
    queryKey: cacheKeyOf(section, lectureId),
    queryFn: async () => {
      const result = await api.interruptGet(urlOf(section, lectureId));
      if (result.ok) {
        const json = await result.json();
        return fromJson(json.lecture);
      }
      throw await createError(result);
    },
    gcTime: 0,
    enabled: !skip,
    retry: 0, //404の場合にリトライさせたくないため
  });
};

const urlOf = (section: SectionInterface, lectureId: string) =>
  `/api/v1/sections/${section.id}/lectures/${lectureId}`;

const cacheKeyOf = (section: SectionInterface, lectureId: string) => [
  "lecture",
  section.id,
  lectureId,
];
