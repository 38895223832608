import addDays from "date-fns/addDays";
import startOfISOWeek from "date-fns/startOfISOWeek";
import * as React from "react";
import { determineCalendarType } from "../../../components/atoms/Calendar";
import { ScheduleForStudent } from "../../../domains/Schedule";
import { useQueryString } from "../../../hooks/useQueryString";
import StudentInterface from "../../../interfaces/StudentInterface";
import { useDeleteSchedule } from "./useScheduleApi";
import { useModalModeState } from "./useScheduleModal";

export type UseStudentCalendarProps = {
  showErrorFlashMessage: (message: string) => void;
  showSuccessFlashMessage: (message: string) => void;
  student: StudentInterface;
};
// eslint-disable-next-line
export const useStudentCalendar = ({
  showSuccessFlashMessage: reduxShowSuccessFlashMessage,
  showErrorFlashMessage: reduxShowErrorFlashMessage,
  student,
}: UseStudentCalendarProps) => {
  // ex.) http://URL/students/[student-id]/calendar?type=weekly&date=2020-12-25
  const queryParams = useQueryString();
  const modalState = useModalModeState();

  const calendarStartDate = React.useMemo(() => {
    if (queryParams.date) {
      return determineGivenDate(queryParams.date);
    }
    return startOfISOWeek(new Date());
  }, [queryParams.date]);

  const calendarType = React.useMemo(() => {
    return determineCalendarType(queryParams.type);
  }, [queryParams.type]);

  const calendarEndDate = React.useMemo(() => {
    switch (calendarType) {
      case "Daily":
        return calendarStartDate;
      case "Weekly":
      default:
        return addDays(calendarStartDate, 6);
    }
  }, [calendarType, calendarStartDate]);

  const [events] = React.useState([]);

  const onMutateSuccess = React.useCallback((message: string) => {
    reduxShowSuccessFlashMessage(message);
    modalState.closeModal();
  }, []);

  const onMutateError = React.useCallback((message: string) => {
    reduxShowErrorFlashMessage(message);
  }, []);

  // 予定の削除
  const { mutate, isSuccess, data, isError, error } = useDeleteSchedule({
    studentId: student.id,
    from: calendarStartDate,
    to: calendarEndDate,
  });

  const onDelete = React.useCallback((schedule: ScheduleForStudent) => {
    if (schedule.recurrence) {
      modalState.openUpdateConfirmToDelete(schedule);
    } else {
      if (window.confirm("この予定を本当に削除しますか？")) {
        mutate({ schedule, updateType: "this" });
      }
    }
  }, []);

  React.useEffect(() => {
    if (isSuccess && data) {
      onMutateSuccess("削除を完了しました");
    }
  }, [data]);

  React.useEffect(() => {
    if (isError && error) {
      onMutateError(error);
    }
  }, [isError, error]);

  return {
    calendarStartDate,
    calendarEndDate,
    calendarType,
    events,
    onMutateSuccess,
    onMutateError,
    onDelete,
    ...modalState,
  };
};

const determineGivenDate = (dateStr: string) => {
  const result = new Date(dateStr);
  return result.toString() === "Invalid Date" ? new Date() : result;
};
