import { IconEllipsis, IconLineStop, IconSync } from "@studyplus/boron-ui";
import { useInfiniteQuery } from "@tanstack/react-query";
import * as React from "react";
import api from "../../../../api";
import Icon from "../../../../components/atoms/Icon";
import Loader from "../../../../components/atoms/Loader";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "../../../../components/general/DropdownMenu/DropdownMenu";
import { HTTPErrors, createError } from "../../../../errors";
import { getNextPageParam } from "../../../../helpers/ReactQueryHelper";
import { useOnMainScrollAreaScroll } from "../../../../hooks/useMainScrollAreaScroll";
import ApiResponse from "../../../../interfaces/ApiResponse";
import { LinkedGoogleClassroomCourseInterface } from "../../../../interfaces/SectionsSettingsGoogleClassroomInterface";
import styles from "./styles.scss";

interface Props {
  sectionId: string;
  unlinkCourse: (course: LinkedGoogleClassroomCourseInterface) => void;
  relinkCourse: (courseId: string) => void;
  submitting: boolean;
}

type ResponseData = ApiResponse<LinkedGoogleClassroomCourseInterface[]>;
const useFetchLinkedGoogleClassroomCourse = (props: Props) => {
  const url = `/api/v1/sections/${props.sectionId}/google_classroom/courses`;
  const result = useInfiniteQuery<ResponseData, HTTPErrors>({
    queryKey: [url],
    queryFn: async ({ pageParam }) => {
      const response = await api.get(url, { query: { page: pageParam } });
      if (response.ok) {
        const json = await response.json();
        return json.courses;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  return {
    ...result,
    data: result.data?.pages.flatMap((page) => page.data),
  };
};

const LinkedGoogleClassroomCourseTable: React.FC<Props> = (props) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useFetchLinkedGoogleClassroomCourse(props);
  useOnMainScrollAreaScroll(() => {
    !isLoading && !isFetchingNextPage && hasNextPage && fetchNextPage();
  }, [hasNextPage, isLoading, isFetchingNextPage, fetchNextPage]);

  return (
    <div className={styles.table__wrapper}>
      <table className={styles.table__body}>
        <thead>
          <tr className={styles.table__header}>
            <th className={styles.header__course}>クラス名</th>
            <th className={styles.header__operator}>連携スタッフ</th>
            <th className={styles.header__status}>ステータス</th>
            <th className={styles.header__menu}></th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((course) => {
              const key = `linked_google_course_${course.courseId}`;
              return course.alternateLink && course.operator ? (
                <CourseWithAlternateLink {...props} course={course} key={key} />
              ) : (
                <CourseWithoutAlternateLink
                  {...props}
                  course={course}
                  key={key}
                />
              );
            })}
        </tbody>
      </table>
      <Loader loading={isLoading || isFetchingNextPage} />
      {data && data.length === 0 && (
        <div className={styles.table__notfound}>
          <p>表示できるクラスが見つかりません</p>
        </div>
      )}
    </div>
  );
};

interface CourseProps {
  course: LinkedGoogleClassroomCourseInterface;
}
const CourseWithAlternateLink: React.FC<Props & CourseProps> = (props) => {
  const { course } = props;
  const handleClick = () => {
    if (!props.submitting) {
      props.unlinkCourse(course);
    }
  };

  return (
    <tr
      key={`google_classroom_course ${course.courseId}`}
      className={styles.table__row}
    >
      <td className={styles.course}>
        <a
          className={styles.course__success}
          href={course.alternateLink ? course.alternateLink : "#"}
          target="_blank"
          rel="noreferrer noopener"
        >
          {course.name}
        </a>
      </td>
      <td>
        <div className={styles.operator}>
          {course.operator ? (
            <>
              <Icon name="icon-account" className={styles.operator__icon} />
              <span className={styles.operator__name}>
                {course.operator.fullName}
              </span>
            </>
          ) : null}
        </div>
      </td>
      <td>
        <span className={styles.status_success}>同期中</span>
      </td>
      <td>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <span
              className="cursor-pointer px-2 text-gray-600 hover:text-blue-400"
              aria-label={`${course.name}のメニューを開く`}
              role="button"
            >
              <IconEllipsis />
            </span>
          </DropdownMenuTrigger>
          <DropdownMenuPortal>
            <DropdownMenuContent side="left" align="end">
              <DropdownMenuItem onSelect={handleClick} asChild>
                <span className="group flex items-center px-8 py-4 text-black hover:text-blue-400">
                  <IconLineStop className="mr-3 text-gray-600 group-hover:text-blue-400" />
                  クラス同期の停止
                </span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenuPortal>
        </DropdownMenu>
      </td>
    </tr>
  );
};

const CourseWithoutAlternateLink: React.FC<Props & CourseProps> = (props) => {
  const { course } = props;
  const handleRelink = () => {
    if (!props.submitting) {
      props.relinkCourse(course.id);
    }
  };
  const handleUnlink = () => {
    if (!props.submitting) {
      props.unlinkCourse(course);
    }
  };
  return (
    <tr
      key={`google_classroom_course ${course.courseId}`}
      className={styles.table__row}
    >
      <td className={styles.course}>
        <span className={styles.course__failed}>{course.name}</span>
      </td>
      <td>
        {course.operator ? (
          <div className={styles.operator}>
            <Icon name="icon-account" className={styles.operator__icon} />
            <span className={styles.operator__name}>
              {course.operator.fullName}
            </span>
          </div>
        ) : null}
      </td>
      <td>
        <span className={styles.status_failed}>同期失敗</span>
      </td>
      <td>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <span
              className="cursor-pointer px-2 text-gray-600 hover:text-blue-400"
              aria-label={`${course.name}のメニューを開く`}
              role="button"
            >
              <IconEllipsis />
            </span>
          </DropdownMenuTrigger>
          <DropdownMenuPortal>
            <DropdownMenuContent side="left" align="end">
              <DropdownMenuItem onSelect={handleRelink} asChild>
                <span className="group flex items-center px-8 py-4 text-black hover:text-blue-400">
                  <IconSync className="mr-3 text-gray-600 group-hover:text-blue-400" />
                  クラス同期の再開
                </span>
              </DropdownMenuItem>
              <DropdownMenuItem onSelect={handleUnlink} asChild>
                <span className="group flex items-center px-8 py-4 text-black hover:text-blue-400">
                  <IconLineStop className="mr-3 text-gray-600 group-hover:text-blue-400" />
                  クラス同期の停止
                </span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenuPortal>
        </DropdownMenu>
      </td>
    </tr>
  );
};

export default LinkedGoogleClassroomCourseTable;
