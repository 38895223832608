import { useQuery } from "@tanstack/react-query";
import { boronClient } from "../../api";
import { HTTPErrors, createError } from "../../errors";
import { paths } from "../../lib/api/v1";

type Props = {
  studentId: string;
  studyRecordId: string;
};

export type StudentStudyRecordResponse =
  paths[Url]["get"]["responses"]["200"]["content"]["application/json"]["studyRecord"];

const URL = "/api/v1/students/{student_id}/study_records/{study_record_hashid}";
type Url = typeof URL;
export const useFetchStudentStudyRecord = ({
  studentId,
  studyRecordId,
}: Props) => {
  return useQuery<StudentStudyRecordResponse, HTTPErrors>({
    queryKey: [URL, studentId, studyRecordId],
    queryFn: async () => {
      const { data, response } = await boronClient.GET(URL, {
        params: {
          path: { student_id: studentId, study_record_hashid: studyRecordId },
        },
      });
      if (response.ok && data) {
        return data.studyRecord;
      }
      throw await createError(response);
    },
  });
};
