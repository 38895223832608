import * as React from "react";
import SectionsSettingsThirdPartyApplications from "../../../components/organisms/SectionsSettingsThirdPartyApplications";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";

interface Props extends OutletContextProps {
  section: SectionInterface;
}

const SectionsSettingsThirdPartyApplicationsPage: React.FC<Props> = (props) => {
  React.useEffect(() => {
    props.setActiveMenu("applications");
  }, []);
  return <SectionsSettingsThirdPartyApplications section={props.section} />;
};

const pageInfo = {
  title: "アプリ連携",
};

export default enhanceSectionsSettingsPage(
  SectionsSettingsThirdPartyApplicationsPage,
  pageInfo,
  ["third_party_application:settings"],
);
