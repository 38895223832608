import * as React from "react";
import { useState } from "react";
import { Location } from "react-router";

import SplitLayout, { Column } from "../../../components/atoms/SplitLayout";
import StudentFilter from "../../../components/features/NewStudentFilter";
import TabsNavi from "../../../components/molecules/TabsNavi";
import { useFetchMessageThreads } from "../../../hooks/http/useFetchMessageThreads.ts";
import MessageThreadInterface from "../../../interfaces/MessageThreadInterface.ts";
import OperatorInterface, {
  OperatorPrivilegeInfoInterface,
} from "../../../interfaces/OperatorInterface.ts";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface.ts";
import SectionInterface from "../../../interfaces/SectionInterface.ts";
import SectionMessageThreadList from "../SectionMessageThreadList";
import SectionStudentMessage from "../SectionStudentMessage";
import { useOpenThread } from "../useOpenThread";
import styles from "./styles.scss";

interface Props {
  section: SectionInterface;
  operatorPrivilege: OperatorPrivilegeInfoInterface | null;
  operatorProfile: OperatorProfileInterface | null;
  operator: OperatorInterface;
  location: Location;
}

const SectionMessages: React.FC<Props> = (props) => {
  const { section, location, operatorPrivilege } = props;

  const [focusedColumn, setFocusedColumn] = useState(Column.Left);

  const { messageThreads, isLoading, isFetchingNextPage, fetchNextPage } =
    useFetchMessageThreads({ sectionId: section.id });

  const { openedThread, openThread } = useOpenThread({
    sectionId: section.id,
    messageThreads: messageThreads,
  });

  const handleClickThread = (thread: MessageThreadInterface) => {
    setFocusedColumn(Column.Right);
    openThread(thread);
  };

  const handleCloseRightColumn = () => {
    setFocusedColumn(Column.Left);
  };

  const tabs = [
    {
      label: "個別送信",
      link: {
        pathname: `/sections/${props.section.id}/message_threads`,
        search: location.search,
      },
      active: true,
    },
  ];

  if (
    operatorPrivilege &&
    (operatorPrivilege.role === "admin" ||
      operatorPrivilege.role === "unlimited_member")
  ) {
    tabs.push({
      label: "一斉送信",
      link: {
        pathname: `/sections/${section.id}/tags/messages`,
        search: location.search,
      },
      active: false,
    });
  }

  return (
    <React.Fragment>
      <TabsNavi tabs={tabs} />
      <StudentFilter sectionId={section.id} />
      <div className={styles.message}>
        <SplitLayout focusedColumn={focusedColumn}>
          <SplitLayout.Navigation focusedColumn={focusedColumn}>
            <SectionMessageThreadList
              messageThreads={messageThreads}
              openedThread={openedThread}
              isLoading={isLoading}
              isFetchingNextPage={isFetchingNextPage}
              onLoadMore={fetchNextPage}
              onClickThread={handleClickThread}
            />
          </SplitLayout.Navigation>
          <SectionStudentMessage
            sectionId={section.id}
            messageThread={openedThread}
            onCloseRightColumn={handleCloseRightColumn}
            operator={props.operator}
            operatorProfile={props.operatorProfile}
          />
        </SplitLayout>
      </div>
    </React.Fragment>
  );
};

export default SectionMessages;
