import { Button } from "@studyplus/boron-ui";
import { ConfirmUpdateTypeModal } from ".";
import { useDeleteWithRecurrences } from "./useDeleteWithReccurences";
import { UseUpdateWithRecurrencesProps } from "./useUpdateWithReccurences";

export type Props = UseUpdateWithRecurrencesProps;

export const ConfirmToDelete = (props: Props) => {
  const state = useDeleteWithRecurrences(props);

  return (
    <ConfirmUpdateTypeModal
      headerLabel="繰り返し予定の削除"
      onClose={props.onClose}
      buttonsArea={(chosen) => (
        <div>
          <Button
            disabled={state.isUpdating}
            isLoading={state.isUpdating}
            onClick={() => state.onSubmit(chosen)}
          >
            削除
          </Button>
        </div>
      )}
    />
  );
};
