import classNames from "classnames";
import {
  ErrorMessage,
  Field,
  FormikBag,
  FormikErrors,
  FormikProps,
  withFormik,
} from "formik";
import snakeCase from "lodash-es/snakeCase";
import * as React from "react";
import * as yup from "yup";
import StudentTag from "../../../domains/StudentTag";
import FormikHelper from "../../../helpers/FormikHelper";
import StatusTagHelper from "../../../helpers/StatusTagHelper";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import SkillInterface from "../../../interfaces/SkillInterface";
import StudyRecordSkillInterface from "../../../interfaces/StudyRecordSkillInterface";
import {
  StudyRecordSkillArgumentsParams,
  StudyRecordSkillOperator,
  StudyRecordSkillParamsInterface,
} from "../../../interfaces/StudyRecordSkillInterface";
import BackLink from "../../atoms/BackLink/index";
import SelectField from "../../atoms/DeprecatedSelectField/index";
import ErrorText from "../../atoms/ErrorText/index";
import Input from "../../atoms/Input";
import Radio from "../../atoms/Radio/index";
import SkillForm from "../../molecules/SkillForm";
import {
  SkillFormArguments,
  SkillFormArgumentsRow,
  SkillFormArgumentsSelect,
  SkillFormArgumentsText,
  SkillFormOptions,
} from "../../molecules/SkillForm/index";
import styles from "./styles.scss";

interface ArgumentsValues {
  tagId?: string;
  period?: number;
  value?: number;
  operator?: StudyRecordSkillOperator;
  message?: "enabled" | "disabled";
  messageIgnoranceDays?: number;
  smartTag?: "enabled" | "disabled";
}

interface Values {
  name: string;
  description: string;
  arguments: ArgumentsValues;
}

type FormAction = "new" | "edit";

interface ComponentProps {
  action: FormAction;
  skill?: SkillInterface;
  skillMasterId: string;
  submitting: boolean;
  onSubmit: (
    params: StudyRecordSkillParamsInterface,
    setSubmitting: (submitState: boolean) => void,
    setErrors: (errors: FormikErrors<Values>) => void,
  ) => void;
  apiErrors: ApiErrorInterface[];
  heading: string;
  helpUrl: string;
}

// APIへのリクエストの形にする
const valuesToParams = (
  skillMasterId: string,
  values: Values,
): StudyRecordSkillParamsInterface => {
  // values.argumentsのキー名をすべてスネークケースにする
  const params: any = {};
  (Object.keys(values.arguments) as (keyof ArgumentsValues)[]).forEach(
    (key: keyof ArgumentsValues) => {
      params[snakeCase(key)] = values.arguments[key];
    },
  );

  return {
    skill_master_id: skillMasterId,
    name: values.name,
    description: values.description,
    arguments: {
      ...(params as StudyRecordSkillArgumentsParams),
      message: params.message === "enabled",
      message_ignorance_days:
        params.message_ignorance_days !== undefined
          ? params.message_ignorance_days
          : null,
      smart_tag: params.smart_tag === "enabled",
    },
  };
};

const mapPropsToValues = (props: ComponentProps): Values => {
  const skill = props.skill as StudyRecordSkillInterface;
  const { action } = props;

  if (action === "edit" && skill) {
    return {
      name: skill.name,
      description: skill.description ?? "",
      arguments: {
        ...skill.arguments,
        message: skill.arguments.message ? "enabled" : "disabled",
        messageIgnoranceDays: skill.arguments.messageIgnoranceDays || 0,
        smartTag: skill.arguments.smartTag ? "enabled" : "disabled",
      },
    };
  } else {
    return {
      name: "",
      description: "",
      arguments: {
        message: "enabled",
        messageIgnoranceDays: 0,
        smartTag: "enabled",
        tagId: "active",
        period: 3,
        value: 0,
        operator: "eq",
      },
    };
  }
};

const setErrors =
  (formikBag: FormikBag<ComponentProps, Values>) =>
  (errors: FormikErrors<Values>): void => {
    formikBag.setErrors(errors);
  };

const setSubmitting =
  (formikBag: FormikBag<ComponentProps, Values>) =>
  (submitState: boolean): void => {
    formikBag.setSubmitting(submitState);
  };

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
) => {
  formikBag.props.onSubmit(
    valuesToParams(formikBag.props.skillMasterId, values),
    setSubmitting(formikBag),
    setErrors(formikBag),
  );
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("スキル名は必須です")
    .max(32, "スキル名は32文字以下でご入力ください"),
  description: yup
    .string()
    .nullable()
    .max(200, "説明は200文字以下でご入力ください"),
  arguments: yup
    .object()
    .required()
    .shape({
      tagId: yup.string().required("タグの選択は必須です"),
      period: yup.string().required("期間は必須です"),
      value: yup.string().required("値は必須です"),
      operator: yup.string().required("条件は必須です"),
      message: yup.string().required(),
      messageIgnoranceDays: yup.string().when("message", {
        is: (value: string) => value === "true",
        then: yup.string().required("繰り返し期間は必須です"),
        otherwise: yup.string(),
      }),
      smartTag: yup.string().required(),
    }),
});

const operatorOptions = [
  {
    label: "より大きい",
    value: "gt",
  },
  {
    label: "より小さい",
    value: "lt",
  },
  {
    label: "以上",
    value: "gteq",
  },
  {
    label: "以下",
    value: "lteq",
  },
  {
    label: "と一致する",
    value: "eq",
  },
];

interface Props {
  action: FormAction;
  skill?: SkillInterface;
  skillMasterId: string;
  tags: StudentTag[];
  sectionId: string;
  submitting: boolean;
  onSubmit: (
    params: StudyRecordSkillParamsInterface,
    setSubmitting: (submitState: boolean) => void,
    setErrors: (errors: FormikErrors<Values>) => void,
  ) => void;
  apiErrors: ApiErrorInterface[];
  heading: string;
  helpUrl: string;
}

const InnerForm = (props: Props & FormikProps<Values>) => {
  // 選択可能な属性タグは「連携中」のみ
  const selectableStatusTag = StatusTagHelper.getById("active");
  const selectableAllTags = props.tags.filter(
    (tag) => tag.type !== "smart_tag",
  );
  const selectableTags = selectableStatusTag
    ? [selectableStatusTag, ...selectableAllTags]
    : selectableAllTags;
  const tagIdOptions = selectableTags.map((tag: StudentTag) => ({
    label: tag.name,
    value: tag.id,
  }));

  return (
    <SkillForm {...props}>
      <SkillFormArguments>
        <SkillFormArgumentsRow>
          <SkillFormArgumentsSelect width="30rem">
            <Field
              name="arguments.tagId"
              options={tagIdOptions}
              isSearchable={true}
              component={SelectField}
              placeholder={"対象生徒タグを選択"}
            />
          </SkillFormArgumentsSelect>
          <SkillFormArgumentsText>が直近</SkillFormArgumentsText>
          <SkillFormArgumentsSelect width="30rem">
            <Field
              name="arguments.period"
              component={Input}
              type="number"
              min={1}
              placeholder="値"
              hasError={FormikHelper.shouldRenderError(
                props,
                "arguments.period",
              )}
              required={true}
            />
            <ErrorMessage
              name="arguments.period"
              component={ErrorText}
              className={styles.error}
            />
          </SkillFormArgumentsSelect>
          <SkillFormArgumentsText>日間の</SkillFormArgumentsText>
        </SkillFormArgumentsRow>
        <SkillFormArgumentsRow>
          <SkillFormArgumentsText>学習時間が</SkillFormArgumentsText>
          <SkillFormArgumentsSelect width="30rem">
            <Field
              name="arguments.value"
              component={Input}
              type="number"
              min={0}
              placeholder="値"
              hasError={FormikHelper.shouldRenderError(
                props,
                "arguments.value",
              )}
              required={true}
              step={0.5}
            />
            <ErrorMessage
              name="arguments.value"
              component={ErrorText}
              className={styles.error}
            />
          </SkillFormArgumentsSelect>
          <SkillFormArgumentsText>時間</SkillFormArgumentsText>
          <SkillFormArgumentsSelect width="30rem">
            <Field
              name="arguments.operator"
              component={SelectField}
              type="number"
              options={operatorOptions}
              placeholder="条件を選択"
              hasError={FormikHelper.shouldRenderError(
                props,
                "arguments.operator",
              )}
              isSearchable={false}
              hideIndicator={true}
            />
            <ErrorMessage
              name="arguments.operator"
              component={ErrorText}
              className={styles.error}
            />
          </SkillFormArgumentsSelect>
        </SkillFormArgumentsRow>
      </SkillFormArguments>
      <SkillFormOptions
        label="メッセージ通知"
        description="条件に一致した場合、AM6:00に生徒個別メッセージでお知らせします。（※生徒には見えません）"
      >
        <div className={styles.block}>
          <Field
            id="arguments.message_on"
            name="arguments.message"
            value="enabled"
            type="radio"
            component={Radio}
            label="通知する"
            isBlock={false}
            checked={props.values.arguments.message === "enabled"}
          />
          <SkillFormArgumentsText isInline={true}>
            ：ただし最終通知から
          </SkillFormArgumentsText>
          <Field
            name="arguments.messageIgnoranceDays"
            component="input"
            type="number"
            min={0}
            className={classNames(styles.messageFieldset__ignorance__field, {
              [styles.messageFieldset__ignorance__fieldError]:
                FormikHelper.shouldRenderError(
                  props,
                  "arguments.messageIgnoranceDays",
                ),
            })}
          />
          <SkillFormArgumentsText isInline={true}>
            日間は繰り返し通知をしない
          </SkillFormArgumentsText>
        </div>
        <Field
          id="arguments.message_off"
          name="arguments.message"
          value="disabled"
          type="radio"
          component={Radio}
          label="通知しない"
          isBlock={true}
          checked={props.values.arguments.message === "disabled"}
        />
      </SkillFormOptions>
      <SkillFormOptions
        label="スマートタグ"
        description="条件に一致した生徒にタグを付けることで絞り込み検索ができるようになります。"
      >
        <Field
          id="arguments.smartTag_on"
          name="arguments.smartTag"
          value="enabled"
          type="radio"
          component={Radio}
          label="タグ付けをする"
          checked={props.values.arguments.smartTag === "enabled"}
          isBlock={true}
        />
        <Field
          id="arguments.smartTag_off"
          name="arguments.smartTag"
          value="disabled"
          component={Radio}
          type="radio"
          label="タグ付けをしない"
          checked={props.values.arguments.smartTag === "disabled"}
          isBlock={true}
        />
      </SkillFormOptions>
    </SkillForm>
  );
};

const ConnectedForm = withFormik<Props, Values>({
  mapPropsToValues,
  validationSchema,
  handleSubmit,
  isInitialValid: (props: ComponentProps) => !!props.skill,
})(InnerForm);

const StudyRecordSkillForm = (props: Props): React.ReactElement<Props> => {
  const backLinkPath =
    props.action === "new"
      ? `/sections/${props.sectionId}/settings/skill_masters`
      : `/sections/${props.sectionId}/settings/skills`;

  return (
    <div className={styles.root}>
      <BackLink path={backLinkPath}>戻る</BackLink>
      <ConnectedForm {...props} />
    </div>
  );
};

export default StudyRecordSkillForm;
