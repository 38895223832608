import * as React from "react";
import { Content } from "../../../../../domains/Content";
import { useQueryError } from "../../../../../hooks/http/useQueryError";
import { useFetchSectionContents } from "../../../SectionsSettingsContentsPage/useContentsApi";
import {
  SearchContentTypes,
  initialContentTypes,
} from "./useContentsSearchForm";

type Props = {
  isOpen: boolean;
  initialCheckedContents: Content[];
  sectionId: string;
  unitId: string | null;
  onAddContents: (unitId: string, contents: Content[]) => void;
};
export const useContentsSelectModal = ({
  initialCheckedContents,
  isOpen,
  onAddContents,
  sectionId,
  unitId,
}: Props) => {
  const [checkedContents, setCheckedContents] = React.useState<Content[]>([]);

  // 開いたときに選択状態に反映
  React.useEffect(() => {
    if (isOpen) {
      setCheckedContents(initialCheckedContents);
    }
  }, [isOpen]);

  // 閉じたときにリセット
  React.useEffect(() => {
    if (!isOpen) {
      // 選択状態をリセット
      setCheckedContents([]);
      // 検索条件をリセット(モーダルを再表示した時に検索条件はリセットされるが、検索結果は直前の結果が残ってしまい条件と結果に一貫性がないため対処)
      setSearchTextParam("");
      setSearchContentTypesParam(initialContentTypes);
    }
  }, [isOpen]);

  // 検索
  const [searchTextParam, setSearchTextParam] = React.useState<string>("");
  const [searchContentTypesParam, setSearchContentTypesParam] =
    React.useState<SearchContentTypes>(initialContentTypes);
  const handleSearch = (
    searchText: string,
    searchContentTypes: SearchContentTypes,
  ) => {
    setSearchTextParam(searchText);
    setSearchContentTypesParam(searchContentTypes);
  };
  const query = {
    name: searchTextParam,
    content_types: searchContentTypesParam,
  };
  const searchResult = useFetchSectionContents({ sectionId, query });
  useQueryError(searchResult.error);

  const handleLoadMore = () => {
    if (
      searchResult.hasNextPage &&
      !searchResult.isLoading &&
      !searchResult.isFetchingNextPage
    ) {
      searchResult.fetchNextPage();
    }
  };

  const contents = React.useMemo(
    () =>
      searchResult.data
        ? searchResult.data.pages.reduce<Content[]>(
            (previousValue, currentValue) => {
              return [...previousValue, ...currentValue.data];
            },
            [],
          )
        : [],
    [searchResult.data],
  );

  const handleAdd = () => {
    if (!unitId) {
      return;
    }
    onAddContents(unitId, checkedContents);
  };

  const handleCheck = React.useCallback((content: Content) => {
    setCheckedContents((prevState) => [...prevState, content]);
  }, []);

  const handleUnCheck = React.useCallback((content: Content) => {
    setCheckedContents((prevState) =>
      prevState.filter((checkedContent) => checkedContent.id !== content.id),
    );
  }, []);

  return {
    handleAdd,
    handleCheck,
    handleUnCheck,
    handleLoadMore,
    handleSearch,
    checkedContents,
    contents,
    searchResult,
  };
};
