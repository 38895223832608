import { Button } from "@studyplus/boron-ui";
import { useFormik } from "formik";
import * as React from "react";
import { BlockRow } from "../../../../components/atoms/BlockRow/index";
import ErrorText from "../../../../components/atoms/ErrorText";
import { Flex } from "../../../../components/atoms/Flex/index";
import { InlineBlock } from "../../../../components/atoms/InlineBlock";
import Input from "../../../../components/atoms/Input";
import Label from "../../../../components/atoms/Label/index";
import { MainSection } from "../../../../components/atoms/MainSection";
import {
  LearningMaterialTagModal,
  useOpenLearningMaterialTagModal,
} from "../../../../components/features/LearningMaterialTagModal/index";
import TagButton from "../../../../components/features/TagButton/index";
import { Text } from "../../../../components/general/Text";
import {
  LearningMaterialTagList,
  LearningMaterialTagWithDeletable,
} from "../../../../domains/LearningMaterialTag";
import { useFetchLearningMaterialTags } from "../../../../hooks/http/useLearningMaterialTagApi";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import SectionInterface from "../../../../interfaces/SectionInterface";
import SectionLearningMaterialInterface from "../../../../interfaces/SectionLearningMaterialInterface";
import styles from "./styles.scss";
import validationSchema from "./validationSchema";
import { VALID_FILE_TYPES } from "./validationSchema";

interface Props {
  section: SectionInterface;
  sectionLearningMaterial?: SectionLearningMaterialInterface;
  submitting: boolean;
  onSubmit: (values: Values) => void;
  apiError?: string;
}

export const SectionLearningMaterialForm = (props: Props) => {
  const form = useForm(props);
  const tagModal = useOpenLearningMaterialTagModal();
  const tags = useFetchLearningMaterialTags({
    section: props.section,
    narrowingCondition: "all",
    shouldGetAllRecords: true,
  });
  useQueryError(tags.error);

  return (
    <MainSection>
      <form
        className={styles.form}
        onSubmit={form.handleSubmit}
        aria-label="校舎独自教材フォーム"
      >
        <BlockRow marginTop="0">
          <InlineBlock marginLeft="0">
            <Label
              htmlFor={"SectionLearningMaterialForm-Name-input"}
              className={styles.label}
              noMargin
              isMute
            >
              教材名
            </Label>
          </InlineBlock>
          <InlineBlock marginLeft="2.4rem">
            <Text color="notification" size="xs">
              {form.touched.name && form.errors.name}
            </Text>
          </InlineBlock>
        </BlockRow>
        <BlockRow marginTop="0.8rem">
          <Input
            id="SectionLearningMaterialForm-Name-input"
            name="name"
            placeholder="教材名を入力"
            hasError={Boolean(form.touched.name && form.errors.name)}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.name}
          />
        </BlockRow>
        <BlockRow marginTop="2.4rem">
          <BlockRow marginTop="0">
            <InlineBlock marginLeft="0">
              <Label
                htmlFor={"SectionLearningMaterialForm-File-input"}
                className={styles.label}
                noMargin
                isMute
              >
                教材画像
              </Label>
            </InlineBlock>
            <InlineBlock marginLeft="2.4rem">
              <Text color="gray-darken-2" size="xs">
                ※1MB以下のJPEG、GIF、PNG画像がアップロードできます
              </Text>
            </InlineBlock>
          </BlockRow>
          <BlockRow marginTop="0.8rem">
            <Input
              id="SectionLearningMaterialForm-File-input"
              type="file"
              name="file"
              onChange={form.handleChangeFile}
              hasError={Boolean(form.touched.file && form.errors.file)}
              accept={VALID_FILE_TYPES.join(",")}
            />
            <Text color="notification">
              {form.touched.file && form.errors.file}
            </Text>
          </BlockRow>
        </BlockRow>
        {props.apiError && (
          <ErrorText key={`sectionLearningMaterialError`}>
            {props.apiError}
          </ErrorText>
        )}
        <BlockRow marginTop="2rem">
          <Flex gap="1" wrap>
            <TagButton
              colorType="primary"
              variant="solid"
              label="教材タグ設定"
              iconName="icon-arrow-right"
              onClick={() => {
                tagModal.openModal({
                  learningMaterialName: form.values.name || "",
                  learningMaterialCode: "",
                  learningMaterialTags: form.values.tags,
                });
              }}
            />
            <SelectedTags tags={form.values.tags} />
          </Flex>
          <LearningMaterialTagModal
            sectionId={props.section.id}
            selectableTags={tags.data || []}
            onSubmit={(_, selectedTags) => {
              form.handleChangeTags(selectedTags);
              tagModal.closeModal();
            }}
            isSubmitting={false}
            currentLearningMaterial={tagModal.currentLearningMaterial}
            closeModal={tagModal.closeModal}
            isOpenModal={tagModal.isOpenModal}
          />
        </BlockRow>
        <Flex justifyContent="flex-end">
          <Button
            disabled={!form.isValid || props.submitting}
            isLoading={props.submitting}
            type="submit"
            className={styles.submitButton}
          >
            登録
          </Button>
        </Flex>
      </form>
    </MainSection>
  );
};

const SelectedTags = ({ tags }: { tags: LearningMaterialTagList }) => (
  <>
    {tags.map((tag) => (
      <TagButton
        label={tag.name}
        colorType={tag.tagType === "default" ? "light-blue" : "light-green"}
        variant="solid"
        disabled
        key={`SelectedTag-${tag.id}`}
      />
    ))}
  </>
);

export type Values = {
  name: string;
  file?: File;
  tags: ReadonlyArray<LearningMaterialTagWithDeletable>;
};

// hooks
const useForm = (props: Props) => {
  const initialValues = {
    name: props.sectionLearningMaterial?.name || "",
    tags: props.sectionLearningMaterial?.tags || [],
  };
  const isInitialValid = props.sectionLearningMaterial !== undefined;
  const handleSubmit = (values: Values) => {
    props.onSubmit(values);
  };

  const form = useFormik<Values>({
    initialValues,
    isInitialValid,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget?.files?.[0];
    form.setFieldValue("file", file);
    form.setFieldTouched("file");
  };

  const handleChangeTags = (tags: LearningMaterialTagList) => {
    form.setFieldValue("tags", tags);
  };

  React.useEffect(() => form.resetForm, []);

  return {
    ...form,
    handleChangeFile,
    handleChangeTags,
  };
};
