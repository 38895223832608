import { useEffect } from "react";
import { ResolveThunks, connect } from "react-redux";
import { resetPassword } from "../../actions/pages/resetPassword";
import { initializeResetPasswordPage } from "../../actions/pages/resetPassword/index";
import Loader from "../../components/atoms/Loader/index";
import SubLayout from "../../components/atoms/SubLayout";
import PasswordResetForm from "../../components/molecules/PasswordResetForm";
import { WithRouterProps, withRouter } from "../../helpers/RouterHelper";
import AppStateInterface from "../../interfaces/AppStateInterface";
import { ResetPasswordState } from "../../interfaces/ResetPasswordState";
import NotFoundErrorPage from "./NotFoundErrorPage";

interface StateProps {
  resetPassword: ResetPasswordState;
}

interface DispatchProps {
  requestResetPassword: typeof resetPassword;
  initializeResetPasswordPage: typeof initializeResetPasswordPage;
}

type Props = StateProps &
  WithRouterProps<{ password_reset_token: string }> &
  ResolveThunks<DispatchProps>;

const PasswordResetPage = (props: Props) => {
  useEffect(() => {
    props.initializeResetPasswordPage(props.params.password_reset_token);
  }, []);

  const { submitting, isValidToken, initialized } = props.resetPassword;

  if (initialized && !isValidToken) return <NotFoundErrorPage />;

  const resetPasswordAction = (
    password: string,
    passwordConfirmation: string,
  ) => {
    if (submitting || !isValidToken) return;

    props.requestResetPassword(
      props.params.password_reset_token,
      password,
      passwordConfirmation,
      props.navigate,
    );
  };

  return (
    <SubLayout>
      <SubLayout.Header />
      <SubLayout.Main>
        {initialized ? (
          <div>
            <SubLayout.Heading>パスワード再設定</SubLayout.Heading>
            <PasswordResetForm
              resetPassword={resetPasswordAction}
              apiErrors={props.resetPassword.apiErrors}
              submitting={submitting}
            />
          </div>
        ) : (
          <Loader loading={submitting} />
        )}
      </SubLayout.Main>
      <SubLayout.Footer />
    </SubLayout>
  );
};

const actions = {
  requestResetPassword: resetPassword,
  initializeResetPasswordPage,
};

const mapStateToProps = (state: AppStateInterface) => ({
  resetPassword: state.resetPassword,
});

export default withRouter<Props>(
  connect(mapStateToProps, actions)(PasswordResetPage),
);
