import classNames from "classnames";
import CircleIcon from "../../atoms/CircleIcon";
import Icon from "../../atoms/Icon";
import styles from "./styles.scss";

export type ColorType =
  | "primary"
  | "primary-lighten-1"
  | "gray-lighten-2"
  | "light-blue"
  | "light-blue-lighten-1"
  | "light-green"
  | "light-green-lighten-1";

type Variants = "solid" | "outline";

type iconType = "default" | "circle";

export interface Props {
  label: string;
  iconName?: string;
  onClick?: (e: any) => void;
  buttonRef?: any;
  className?: string;
  disabled?: boolean;
  variant: Variants;
  colorType: ColorType;
  hoverColorType?: ColorType;
  hoverVariant?: Variants;
  iconType?: iconType;
  highlight?: boolean;
  onClickIcon?: (e: any) => void;
  bold?: boolean;
}

const renderIcon = (props: Props) => {
  if (!props.iconName) return null;

  const { iconType = "default" } = props;
  return iconType === "default" ? (
    <Icon
      name={props.iconName}
      className={styles.icon}
      onClick={props.onClickIcon}
    />
  ) : (
    <CircleIcon
      iconProps={{
        name: props.iconName,
      }}
      className={styles.circleIcon}
      onClick={props.onClickIcon}
    />
  );
};

const classMap = (props: Props) => {
  return {
    // variants
    [styles.solid]: props.variant === "solid",
    [styles.outline]: props.variant === "outline",
    [styles.hoverSolid]: props.hoverVariant === "solid",
    [styles.hoverOutline]: props.hoverVariant === "outline",

    // primary
    [styles.primary]: props.colorType === "primary",
    [styles.hoverPrimary]: props.hoverColorType === "primary",
    [styles.primaryLighten1]: props.colorType === "primary-lighten-1",

    // gray
    [styles.grayLighten2]: props.colorType === "gray-lighten-2",

    // light-blue
    [styles.lightBlue]: props.colorType === "light-blue",
    [styles.hoverLightBlue]: props.hoverColorType === "light-blue",
    [styles.hoverLightBlueLighten1]:
      props.hoverColorType === "light-blue-lighten-1",

    // light-green
    [styles.lightGreen]: props.colorType === "light-green",
    [styles.hoverLightGreen]: props.hoverColorType === "light-green",
    [styles.hoverLightGreenLighten1]:
      props.hoverColorType === "light-green-lighten-1",

    // アイコン
    [styles.withIcon]: !!props.iconName,
    [styles.circleIcon]: props.iconType === "circle",

    // ハイライト
    [styles.highlight]: !!props.highlight,

    // font-weight
    [styles.bold]: !!props.bold,
  };
};

const TagButton = (props: Props) => (
  <button
    className={classNames(styles.root, classMap(props), props.className)}
    onClick={props.onClick}
    ref={props.buttonRef}
    type="button"
    disabled={!!props.disabled}
  >
    <span className={styles.label}>{props.label}</span>
    {renderIcon(props)}
  </button>
);

export default TagButton;
