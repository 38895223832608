import { FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import {
  ImportHistoryInterface,
  requireZipOperation,
} from "../../../../domains/ImportHistory";
import {
  UnprocessableEntityError,
  UnprocessableEntityErrorItem,
} from "../../../../errors";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { Values } from "./UploadForm";
import { usePostImportOperation } from "./usePostImportOperation";

export const useUploadForm = ({
  sectionId,
  operation,
}: {
  sectionId: string;
  operation: ImportHistoryInterface["operation"];
}) => {
  const navigate = useNavigate();
  const { showErrorMessage } = useFlashMessage();

  const { mutate } = usePostImportOperation({
    sectionId: sectionId,
    operation,
  });

  const handleSubmit = (
    { csv, zip }: Values,
    formikAction: FormikHelpers<Values>,
  ) => {
    if (!csv) return;

    mutate(
      { csv, zip },
      {
        onSuccess: (data) => {
          navigate(
            `/sections/${sectionId}/settings/import_histories/${data.importHistory.id}`,
          );
        },
        onError: (error) => {
          showErrorMessage("一括操作が実行できませんでした");
          if (error instanceof UnprocessableEntityError) {
            setErrors(formikAction.setFieldError, error.originalErrors);
          }
        },
        onSettled: () => {
          formikAction.setSubmitting(false);
        },
      },
    );
  };

  const requireZip = requireZipOperation(operation);

  return {
    requireZip,
    handleSubmit,
  };
};

const setErrors = (
  setFieldError: FormikHelpers<Values>["setFieldError"],
  errors: UnprocessableEntityErrorItem[],
) => {
  errors.forEach((error) => {
    if (
      error.resource === "ImportHistoryAttachments[0]" &&
      error.field === "file"
    ) {
      setFieldError("csv", `CSVファイル${error.message}`);
    } else if (
      error.resource === "ImportHistoryAttachments[1]" &&
      error.field === "file"
    ) {
      setFieldError("zip", `画像ZIPファイル${error.message}`);
    }
  });
};
