import * as React from "react";
import { connect } from "react-redux";
import {
  changeOperatorRole,
  deleteInvite,
  deleteOperator,
  getOperators,
  getOperatorsInitial,
  inviteOperator,
  updateOperatorSectionTags,
} from "../../actions/operator";
import SectionsSettingsOperators from "../../components/organisms/SectionsSettingsOperators";
import { StudentTagFilterInterface } from "../../domains/StudentTag";
import { OutletContextProps } from "../../helpers/RouterHelper";
import enhanceSectionsSettingsPage from "../../hocs/enhanceSectionsSettingsPage";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import AppStateInterface from "../../interfaces/AppStateInterface";
import OperatorInterface, {
  OperatorRoleTypes,
} from "../../interfaces/OperatorInterface";
import SectionInterface from "../../interfaces/SectionInterface";
import { SectionOperatorsStateInterface } from "../../interfaces/SectionOperatorsInterface";

interface Props extends OutletContextProps {
  section: SectionInterface;
  tagFilter: StudentTagFilterInterface;
  currentOperator: OperatorInterface;
  sectionOperatorsState: SectionOperatorsStateInterface;
  getOperatorsInitial: (sectionId: string) => void;
  getOperators: (sectionId: string, page: number) => void;
  inviteOperator: (
    sectionId: string,
    email: string,
    resetFrom: () => void,
  ) => void;
  deleteInvite: (sectionId: string, inviteId: string) => void;
  deleteOperator: (sectionId: string, operatorId: string) => void;
  changeOperatorRole: (
    sectionId: string,
    operatorId: string,
    role: OperatorRoleTypes,
  ) => void;
  updateOperatorSectionTags: (
    sectionId: string,
    operatorId: string,
    tagIds: string[],
  ) => void;
}

const mapStateToProps = (state: AppStateInterface) => ({
  sectionOperatorsState: state.sectionsSettingsOperatorsState,
});

const actions = {
  getOperators,
  getOperatorsInitial,
  inviteOperator,
  deleteInvite,
  deleteOperator,
  changeOperatorRole,
  updateOperatorSectionTags,
};

const SectionsSettingsOperatorsPage = (props: Props) => {
  React.useEffect(() => {
    props.getOperatorsInitial(props.section.id);
    props.setActiveMenu("staff");
  }, []);

  useOnMainScrollAreaScroll(() => {
    const { sectionOperatorsState } = props;
    if (sectionOperatorsState.hasMore && !sectionOperatorsState.loading) {
      props.getOperators(
        props.section.id,
        props.sectionOperatorsState.activeOperators.meta.currentPage + 1,
      );
    } else {
      return;
    }
  }, [
    props.sectionOperatorsState.hasMore,
    props.sectionOperatorsState.loading,
  ]);

  if (!props.section) {
    return null;
  }

  return (
    <SectionsSettingsOperators
      section={props.section}
      currentOperator={props.currentOperator}
      sectionTags={props.tagFilter.tags}
      sectionOperatorsState={props.sectionOperatorsState}
      inviteOperator={props.inviteOperator}
      deleteInvite={props.deleteInvite}
      deleteOperator={props.deleteOperator}
      changeOperatorRole={props.changeOperatorRole}
      updateOperatorSectionTags={props.updateOperatorSectionTags}
    />
  );
};

const pageInfo = {
  title: "スタッフ一覧",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsOperatorsPage,
  pageInfo,
  ["current_section_operator:index"],
);

export default connect(mapStateToProps, actions)(EnhancedPage as any);
