import * as React from "react";
import { Link } from "react-router-dom";
import { BlockRow } from "../../atoms/BlockRow";
import { Flex } from "../../atoms/Flex";
import Icon from "../../atoms/Icon";
import Loader from "../../atoms/Loader";
import { BreadcrumbsView } from "../../general/BreadcrumbsView";
import styles from "./styles.scss";

type Props = {
  sectionId: string;
  courseId: string;
  title: string;
  subText: string;
  breadcrumbsProps: React.ComponentProps<typeof BreadcrumbsView>;
  isLoading: boolean;
};
export const ContentResultTemplate = ({
  sectionId,
  courseId,
  title,
  subText,
  breadcrumbsProps,
  isLoading,
  children,
}: React.PropsWithChildren<Props>) => (
  <>
    <BreadcrumbsView {...breadcrumbsProps} />
    <BlockRow marginTop="0.8rem"></BlockRow>
    <div className={styles.ContentResultTemplate}>
      <Flex justifyContent="space-between" marginTop="0" wrap>
        <div className={styles.ContentResultTemplate__head}>
          <Icon
            name="icon-folder-level-1"
            color="gray-darken-1"
            className={styles.ContentResultTemplate__icon}
          />
          <BlockRow marginTop="0">
            <p className={styles.ContentResultTemplate__title}>{title}</p>
            <p className={styles.ContentResultTemplate__text}>{subText}</p>
          </BlockRow>
        </div>
        <Flex marginTop="0">
          <LinkButton
            to={`/sections/${sectionId}/settings/content_courses/${courseId}`}
            icon="icon-content-list"
          >
            詳細を見る
          </LinkButton>
        </Flex>
      </Flex>
      <BlockRow marginTop="2rem">{children}</BlockRow>
    </div>
    <Loader loading={isLoading} />
  </>
);

type LinkButtonProps = React.PropsWithChildren<{
  to: string;
  icon?: string;
}>;
const LinkButton = (props: LinkButtonProps) => (
  <Link to={props.to} className={styles.LinkButton}>
    {props.icon ? <Icon name={props.icon} /> : null}
    {props.children}
  </Link>
);
