import * as React from "react";
import { HandleThunkActionCreator } from "react-redux";
import { Location } from "react-router";
import { dispatchSwitchOperator } from "../../../actions/common/session/index";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import LinkList from "../../atoms/LinkList";

interface DispatchProps {
  onSwitchOperator: HandleThunkActionCreator<typeof dispatchSwitchOperator>;
}
interface StateProps {
  currentOperator: OperatorInterface;
  operators: OperatorInterface[];
}

interface Props extends DispatchProps, StateProps {
  location: Location;
}

const SessionsEdit = (props: Props): React.ReactElement => {
  return (
    <LinkList>
      {props.operators.map((operator) => renderOperator(props, operator))}
    </LinkList>
  );
};

const renderOperator = (props: Props, operator: OperatorInterface) => {
  if (!props.currentOperator) {
    return null;
  }

  return (
    <LinkList.Item
      key={`SessionsEdit-${operator.id}`}
      isActive={props.currentOperator.id === operator.id}
      isDisabled={props.currentOperator.id === operator.id}
      iconName="icon-account"
      theme="gray"
      onClick={handleClickOperator(props, operator)}
      to={props.location}
    >
      {operator.fullName}
    </LinkList.Item>
  );
};

const handleClickOperator =
  (props: Props, operator: OperatorInterface) => () => {
    props.onSwitchOperator(operator, { withRedirect: true });
  };

export default SessionsEdit;
