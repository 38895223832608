import * as React from "react";
import Loader from "../../components/atoms/Loader";
import { SectionAnalyticsTypeTab } from "../../components/features/SectionAnalyticsTypeTab";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import enhanceSectionPage from "../../hocs/enhanceSectionsPage";
import { AnalyticsLectureStudent } from "./AnalyticsLectureStudent";
import { BreadCrumbs } from "./BreadCrumbs";
import styles from "./SectionsAnalyticsLectureStudentPage.scss";
import {
  HooksProps,
  useSectionAnalyticsLectureStudentPage,
} from "./useSectionsAnalyticsLectureStudentPage";

type Props = OutletContextProps & HooksProps;

const SectionsAnalyticsLectureStudentPage_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("analytics");
  }, []);
  const section = props.section;
  const { lecture } = useSectionAnalyticsLectureStudentPage({ section });

  return (
    <div className={styles.analyticsLectureStudent}>
      <SectionAnalyticsTypeTab
        sectionId={section.id}
        analyticsType="lectureAttendance"
      />
      {lecture ? (
        <>
          <BreadCrumbs sectionId={section.id} lectureName={lecture.name} />
          <AnalyticsLectureStudent lecture={lecture} />
        </>
      ) : (
        <Loader loading />
      )}
    </div>
  );
};

const pageInfo = {
  title: "アナリティクス",
};
export const SectionsAnalyticsLectureStudentPage = withRouter(
  enhanceSectionPage(SectionsAnalyticsLectureStudentPage_, pageInfo),
);
