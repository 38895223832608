import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router";
import { dispatchGetUserInfo } from "../../../actions/authorization/userInfo";
import Loader from "../../../components/atoms/Loader/index";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { ConsentAgreement } from "../../ConsentAgreement";
import styles from "./styles.scss";

export const LOGIN_PATH = "/login";

const RequireLogin = () => {
  const dispatch = useDispatch();
  const session = useSelector((state: AppStateInterface) => state.session);
  const location = useLocation();

  React.useEffect(() => {
    if (session.isLoggedIn) return;
    dispatch(dispatchGetUserInfo());
  }, []);

  if (session.shouldRedirect && session.backTo === LOGIN_PATH) {
    return (
      <Navigate
        to={LOGIN_PATH}
        state={{ search: `?url=${location.pathname}` }}
        replace
      />
    );
  }

  if (location.pathname === LOGIN_PATH) {
    return (
      <>
        <ConsentAgreement />
        <Outlet />
      </>
    );
  }

  // NOTE:
  // /api/v1/meからcurrentUserを取得してログインの有無を確認するまでLoaderを表示する
  if (!session.isLoggedIn) {
    return (
      <div className={styles.loading}>
        <Loader loading={true} />
      </div>
    );
  }

  if (session.currentOperator !== null) {
    return (
      <>
        <ConsentAgreement />
        <Outlet />
      </>
    );
  } else {
    return (
      <Navigate
        to={LOGIN_PATH}
        state={{ search: `?url=${location.pathname}` }}
        replace
      />
    );
  }
};

export default RequireLogin;
