import * as React from "react";
import { boronClient } from "../../../api";
import { useBeforeUnload } from "../../../hooks/useBeforeUnload";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { usePrompt } from "../../../hooks/usePrompt";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";
import { DrillLearningMaterialValue } from "../useStudyTaskForm";
import { Props } from "./DrillLearningMaterialSelectionForm";
import { DrillSearchResponseItem } from "./DrillLearningMaterialSelector/useFetchDrillLearningMaterials";

export const useDrillLearningMaterialSelectionForm = ({
  defaultDrillLearningMaterial,
  openMainFormHandler,
  selectDrillLearningMaterialHandler,
  section,
}: Props) => {
  const [selectedDrill, setSelectedDrill] =
    React.useState<DrillLearningMaterialValue | null>(
      defaultDrillLearningMaterial || null,
    );
  const [temporaryDrill, setTemporaryDrill] =
    React.useState<DrillLearningMaterialValue | null>(
      defaultDrillLearningMaterial || null,
    );
  const { setIsDirty, openMainForm } =
    useNavigationBlocker(openMainFormHandler);
  const { fetchDrillWithContents, isLoading: isLoadingSelectedDrill } =
    useDrillWithContentsFetcher();

  const abortControllerRef = React.useRef(new AbortController());

  const setSelectedDrillWithDirty = (
    drill: DrillLearningMaterialValue | null,
  ) => {
    abortControllerRef.current.abort();
    setSelectedDrill(drill);
    setIsDirty(true);
  };

  const confirmDrillLearningMaterial = () => {
    if (isLoadingSelectedDrill) {
      alert("教材の読み込み中です。しばらくお待ちください。");
      return;
    }
    if (selectedDrill == null || selectedDrill.sections.length === 0) {
      alert(
        "教材の学習範囲が選択されていません。学習範囲としてセクションまたはコンテンツを選択してください。",
      );
      return;
    }
    selectDrillLearningMaterialHandler(selectedDrill);
  };

  const selectDrillAndAllContents = async (drill: DrillSearchResponseItem) => {
    if (selectedDrill) {
      alert(
        "すでに選択している教材があるため、「選択中の教材」を削除してから追加したい教材を追加してください。",
      );
      return;
    }

    setSelectedDrillWithDirty({
      ...drill,
      sections: [],
    });
    setTemporaryDrill({
      ...drill,
      sections: [],
    });
    abortControllerRef.current = new AbortController();
    const drillWithContents = await fetchDrillWithContents({
      sectionId: section.id,
      drillMaterialCode: drill.learningMaterialCode || "",
      signal: abortControllerRef.current.signal,
      onError: clearDrillAndContents,
    });

    if (drillWithContents) {
      setSelectedDrillWithDirty({
        ...drill,
        sections: drillWithContents.sections,
      });
      setTemporaryDrill({
        ...drill,
        sections: drillWithContents.sections,
      });
    }
  };

  const clearDrillAndContents = () => {
    setSelectedDrillWithDirty(null);
    setTemporaryDrill(null);
  };

  const openContentSelector = (drill: DrillSearchResponseItem) => {
    if (selectedDrill?.publicId === drill.publicId) {
      setTemporaryDrill(selectedDrill);
    } else {
      setTemporaryDrill({
        ...drill,
        sections: [],
      });
    }
  };

  const closeContentSelector = () => {
    setTemporaryDrill(null);
  };
  return {
    selectedDrill,
    temporaryDrill,
    setTemporaryDrill,
    openMainForm,
    isLoadingSelectedDrill,
    setSelectedDrillWithDirty,
    confirmDrillLearningMaterial,
    clearDrillAndContents,
    selectDrillAndAllContents,
    openContentSelector,
    closeContentSelector,
  };
};

const useNavigationBlocker = (openMainFormHandler: () => void) => {
  const [isDirty, setIsDirty] = React.useState(false);

  useBeforeUnload(
    React.useCallback(
      (e) => {
        if (isDirty) {
          e.preventDefault();
          e.returnValue = "";
        }
      },
      [isDirty],
    ),
  );
  usePrompt(
    "選択中の内容が削除されますが、このページから移動してもよろしいですか？",
    isDirty,
  );
  const openMainForm = () => {
    if (
      !isDirty ||
      confirm(
        "選択中の内容が削除されますが、このページから移動してもよろしいですか？",
      )
    ) {
      openMainFormHandler();
    }
  };
  return { setIsDirty, openMainForm };
};

const useDrillWithContentsFetcher = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { showErrorMessage } = useFlashMessage();

  const fetchDrillWithContents = async ({
    sectionId,
    drillMaterialCode,
    signal,
    onError,
  }: {
    sectionId: string;
    drillMaterialCode?: string;
    signal: AbortSignal;
    onError: () => void;
  }) => {
    if (!drillMaterialCode) return;
    setIsLoading(true);
    const { data, response } = await boronClient.GET(
      "/api/v1/sections/{section_id}/drill_learning_materials/{steak_material_code}",
      {
        params: {
          path: {
            section_id: sectionId,
            steak_material_code: drillMaterialCode,
          },
          query: {
            include: ["contents"],
          },
        },
        signal: signal,
      },
    );
    setIsLoading(false);
    if (response.ok && data) {
      return data.drill;
    } else {
      showErrorMessage(HTTP_ERROR_MESSAGE);
      onError();
    }
  };
  return { fetchDrillWithContents, isLoading };
};
