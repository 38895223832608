import { IconTrash } from "@studyplus/boron-ui";
import classNames from "classnames";
import parseISO from "date-fns/parseISO";
import { Link } from "react-router-dom";
import Icon from "../../components/atoms/Icon";
import {
  HorizontalScrollTable,
  TableColumn,
} from "../../components/atoms/Table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "../../components/general/DropdownMenu/DropdownMenu";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../components/general/Popover/Popover";
import { formatDate } from "../../helpers/TimeHelper";
import { paths } from "../../lib/api/v1";
import { Period } from "./index";
import { useDeleteStudyTask } from "./useDeleteStudyTask";

type Props = {
  sectionId: string;
  period: Period;
  studyTasks: StudyTask[];
};
type StudyTask =
  paths["/api/v1/sections/{section_id}/analytics/study_tasks"]["get"]["responses"]["200"]["content"]["application/json"]["studyTasks"]["data"][0];
export const StudyTaskTable = ({ sectionId, period, studyTasks }: Props) => {
  const noRowsText =
    period === "during"
      ? "期間中の課題はありません"
      : period === "before"
        ? "期間前の課題はありません"
        : "期間終了の課題はありません";

  return (
    <HorizontalScrollTable
      noRowsText={noRowsText}
      headers={[
        {
          text: "タイトル / 対象教材",
          centering: true,
          key: "title",
          className: "min-w-[30rem] w-[40%]",
        },
        { text: "配信期間", centering: true, key: "period" },
        { text: "終了期間", centering: true, key: "end" },
        { text: "配信人数", centering: true, key: "students" },
        { text: "課題進捗", centering: true, key: "progress" },
        { text: "", centering: false, key: "blank", className: "min-w-9" },
      ]}
      rows={studyTasks.map((studyTask: StudyTask) => (
        <Row
          key={studyTask.id}
          sectionId={sectionId}
          studyTask={studyTask}
          period={period}
        />
      ))}
    />
  );
};

const Row = ({
  sectionId,
  studyTask,
  period,
}: {
  sectionId: string;
  studyTask: StudyTask;
  period: Period;
}) => {
  const allStudentCount =
    studyTask.studiedStudents.length +
    studyTask.studyingStudents.length +
    studyTask.notStudiedStudents.length;
  const studiedPercentage =
    (studyTask.studiedStudents.length / allStudentCount) * 100;
  const studyingPercentage =
    (studyTask.studyingStudents.length / allStudentCount) * 100;
  // 割り切れないケースがあるので、未学習の割合は残り全てとする
  const notStudiedPercentage = 100 - studiedPercentage - studyingPercentage;

  const startAt = parseISO(studyTask.startAt);
  const endAt = parseISO(studyTask.endAt);

  const { onDelete } = useDeleteStudyTask({
    sectionId,
    studyTaskId: studyTask.id,
  });

  return (
    <tr key={studyTask.id}>
      <TableColumn className="min-w-[30rem]">
        <div className="flex flex-nowrap items-start gap-4">
          <div className="h-[4.5rem] w-[4.5rem] flex-shrink-0 flex-grow-0">
            <img
              src={studyTask.learningMaterial.imageUrl}
              alt={studyTask.learningMaterial.name}
              className="h-full w-full object-contain"
            />
          </div>
          <div className="flex-shrink flex-grow">
            <Link
              className="leading-8 text-blue-400 underline"
              to={`/sections/${sectionId}/analytics/study_task/${studyTask.id}`}
            >
              {studyTask.title}
            </Link>
            <div className="pt-3 text-md text-black">
              {studyTask.learningMaterial.name}
            </div>
          </div>
        </div>
      </TableColumn>
      <TableColumn centering>
        <span className="whitespace-nowrap">{formatDate(startAt)}</span>
        <span> ~ </span>
        <span className="whitespace-nowrap">{formatDate(endAt)}</span>
      </TableColumn>
      <TableColumn centering className="whitespace-nowrap text-md">
        <RestDays
          period={period}
          numberOfDaysToEnd={studyTask.numberOfDaysToEnd}
        />
      </TableColumn>
      <TableColumn centering>{allStudentCount}人</TableColumn>
      <TableColumn>
        <div className="flex text-sm">
          <div className="whitespace-nowrap">
            完了：{studyTask.studiedStudents.length}人
            <span className="mx-5">|</span>
          </div>
          <div className="whitespace-nowrap">
            学習中：
            {studyTask.studyingStudents.length}人<span className="mx-5">|</span>
          </div>
          <div className="whitespace-nowrap">
            未学習：
            {studyTask.notStudiedStudents.length}人
          </div>
        </div>
        <div className="mt-2 flex h-[1rem] w-full flex-nowrap items-stretch justify-start overflow-hidden rounded-full">
          <Bar
            label="完了"
            className="bg-blue-300"
            percentage={studiedPercentage}
            students={studyTask.studiedStudents}
          />
          <Bar
            label="学習中"
            className="bg-green-300"
            percentage={studyingPercentage}
            students={studyTask.studyingStudents}
          />
          <Bar
            label="未学習"
            className="bg-gray-400"
            percentage={notStudiedPercentage}
            students={studyTask.notStudiedStudents}
          />
        </div>
      </TableColumn>
      <TableColumn className="w-9 pl-0">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Icon name="icon-ellipsis" />
          </DropdownMenuTrigger>
          <DropdownMenuPortal>
            <DropdownMenuContent
              side="left"
              align="start"
              alignOffset={14}
              sideOffset={-4}
            >
              <DropdownMenuItem onSelect={onDelete} asChild>
                <span className="flex items-center py-3 pr-10">
                  <IconTrash className="mr-3 text-gray-800" />
                  削除
                </span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenuPortal>
        </DropdownMenu>
      </TableColumn>
    </tr>
  );
};

const RestDays = ({
  period,
  numberOfDaysToEnd,
}: {
  period: Period;
  numberOfDaysToEnd: number;
}) => {
  if (period === "before") {
    return <div>配信前</div>;
  }

  if (numberOfDaysToEnd <= 0) {
    return <div>配信終了</div>;
  }

  return (
    <div>
      残り
      <span className="mx-1 text-xl font-bold">{numberOfDaysToEnd}</span>日
    </div>
  );
};

const Bar = ({
  label,
  className,
  percentage,
  students,
}: {
  label: string;
  className: string;
  percentage: number;
  students: StudyTask["studiedStudents"];
}) => {
  // 全体の生徒数が0の場合にNaNになる
  if (isNaN(percentage) || percentage === 0) {
    return null;
  }

  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger asChild>
          <div
            className={`h-full cursor-pointer ${className}`}
            style={{ width: `${percentage}%` }}
          />
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent side="top">
            <div className="line-clamp-[10] max-w-[31rem] text-lg leading-[1.6rem]">
              <p className="text-center">{label}：</p>
              <p className="text-left">
                {students.map((student, i) => (
                  <span key={student.publicId}>
                    <span
                      className={classNames({
                        "whitespace-nowrap": student.name.length < 20, // 20文字以上は一行に入りきらないので改行する
                      })}
                    >
                      {student.name}
                      {i !== students.length - 1 && ", "}
                    </span>
                    {i !== students.length - 1 && <wbr />}
                  </span>
                ))}
              </p>
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};
