import {
  Button,
  IconArrowClose,
  IconCloseX,
  IconHelpSupport,
  IconPlus,
} from "@studyplus/boron-ui";
import { SectionAnalyticsTypeTab } from "../../../components/features/SectionAnalyticsTypeTab";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbItemLink,
} from "../../../components/general/Breadcrumb";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../../components/general/Popover/Popover";
import SectionInterface from "../../../interfaces/SectionInterface";
import { DrillLearningMaterialValue } from "../useStudyTaskForm";
import { ContentSelector } from "./ContentSelector/ContentSelector";
import { DrillShowResponseItem } from "./ContentSelector/useFetchDrillWithContents";
import { DrillLearningMaterialSelector } from "./DrillLearningMaterialSelector/DrillLearningMaterialSelector";
import { SelectedDrillLearningMaterial } from "./SelectedDrillLearningMaterial";
import { useDrillLearningMaterialSelectionForm } from "./useDrillLearningMaterialSelectionForm";

export type DrillSection = DrillShowResponseItem["sections"][0];
export type Props = {
  section: SectionInterface;
  openMainFormHandler: () => void;
  selectDrillLearningMaterialHandler: (
    drillLearningMaterial: DrillLearningMaterialValue,
  ) => void;
  defaultDrillLearningMaterial?: DrillLearningMaterialValue;
};
export const DrillLearningMaterialSelectionForm = ({
  section,
  openMainFormHandler,
  selectDrillLearningMaterialHandler,
  defaultDrillLearningMaterial,
}: Props) => {
  const {
    selectedDrill,
    temporaryDrill,
    setTemporaryDrill,
    openMainForm,
    isLoadingSelectedDrill,
    setSelectedDrillWithDirty,
    confirmDrillLearningMaterial,
    clearDrillAndContents,
    selectDrillAndAllContents,
    openContentSelector,
    closeContentSelector,
  } = useDrillLearningMaterialSelectionForm({
    defaultDrillLearningMaterial,
    openMainFormHandler,
    selectDrillLearningMaterialHandler,
    section,
  });

  return (
    <>
      <SectionAnalyticsTypeTab
        sectionId={section.id}
        analyticsType="studyTask"
      />

      <div className="my-5">
        <DrillSelectionBreadcrumb
          sectionId={section.id}
          openMainForm={openMainForm}
        />
      </div>

      <div className="flex h-[calc(100vh-22rem)] min-h-5 w-full min-w-fit flex-nowrap items-stretch justify-start rounded border border-solid border-gray-400 bg-white">
        <div className="box-border flex w-[35rem] flex-shrink-0 flex-grow-0 flex-col flex-nowrap items-stretch justify-between border-0 border-r border-solid border-gray-400 p-7">
          <div>
            <div className="flex flex-nowrap items-center gap-1 text-2xl font-bold leading-[1.4rem] text-black">
              <BackButton onClick={openMainForm} />
              <span>デジタル教材と学習範囲の選択</span>
            </div>
            <div className="mt-7 box-border w-full rounded border border-solid border-gray-400 px-4 py-5">
              <div className="flex flex-nowrap items-center gap-1">
                <span>選択中の教材</span>
                <HelpIcon />
              </div>
              {selectedDrill ? (
                <div className="relative mt-3">
                  <button
                    className="absolute right-3 top-3 p-0 text-gray-800 hover:text-blue-400"
                    onClick={clearDrillAndContents}
                  >
                    <IconCloseX />
                  </button>

                  <SelectedDrillLearningMaterial
                    sectionId={section.id}
                    drill={selectedDrill}
                    isLoading={isLoadingSelectedDrill}
                    onClick={() => setTemporaryDrill(selectedDrill)}
                  />
                </div>
              ) : (
                <div className="mt-4 box-border w-full rounded border border-dashed border-gray-400 py-5">
                  <div className="flex flex-nowrap items-center justify-center">
                    <IconPlus />
                    <span>教材を追加してください</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-7">
            <Button onClick={confirmDrillLearningMaterial} isBlock>
              決定
            </Button>
          </div>
        </div>
        <div className="flex-shrink flex-grow p-7">
          {temporaryDrill ? (
            <ContentSelector
              sectionId={section.id}
              temporaryDrill={temporaryDrill}
              setTemporaryDrill={setTemporaryDrill}
              selectedDrill={selectedDrill}
              setSelectedDrill={setSelectedDrillWithDirty}
              closeContentSelector={closeContentSelector}
              isLoadingSelectedDrill={isLoadingSelectedDrill}
            />
          ) : (
            <DrillLearningMaterialSelector
              sectionId={section.id}
              selectedDrill={selectedDrill}
              selectDrillAndAllContents={selectDrillAndAllContents}
              openContentSelector={openContentSelector}
            />
          )}
        </div>
      </div>
    </>
  );
};

const DrillSelectionBreadcrumb = (props: {
  sectionId: string;
  openMainForm: () => void;
}) => (
  <Breadcrumb>
    <BreadcrumbItem>
      <BreadcrumbItemLink
        href={`/sections/${props.sectionId}/analytics/study_task`}
      >
        <div className="mx-4 text-lg">課題進捗</div>
      </BreadcrumbItemLink>
    </BreadcrumbItem>
    <BreadcrumbItem>
      <div
        className="mx-4 cursor-pointer text-lg text-blue-400"
        onClick={props.openMainForm}
      >
        課題の登録
      </div>
    </BreadcrumbItem>
    <BreadcrumbItem className="max-w-[21rem]" isCurrent>
      <div className="mx-4 text-lg">デジタル教材と学習範囲の選択</div>
    </BreadcrumbItem>
  </Breadcrumb>
);

const BackButton = (props: { onClick: () => void }) => (
  <button className="p-0 leading-[0] text-black" onClick={props.onClick}>
    <IconArrowClose />
  </button>
);

const HelpIcon = () => (
  <PopoverProvider>
    <Popover>
      <PopoverTrigger className="p-0 leading-[0]">
        <IconHelpSupport className="h-[1.4rem] w-[1.4rem] text-gray-800" />
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent side="top">
          <div className="text-center">
            右側のデジタル教材リストから
            <br />
            教材を「追加」をして対象範囲を
            <br />
            選ぶことができます
          </div>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  </PopoverProvider>
);
