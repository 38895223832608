import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import * as React from "react";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import Button from "../../atoms/Button/index";
import ErrorText from "../../atoms/ErrorText/index";
import Input from "../../atoms/Input";
import Label from "../../atoms/Label";
import Loader from "../../atoms/Loader/index";
import ApiClient from "./../../../api";
import styles from "./styles.scss";
import validationSchema from "./validationSchema";

interface Values {
  email: string;
}
type ComponentProps = WithRouterProps;

type Props = FormikProps<Values> & ComponentProps;

class PasswordResetRequestForm extends React.Component<Props, unknown> {
  render() {
    return (
      <Form>
        <Label htmlFor="password-reset_email">メールアドレス</Label>
        {this.renderError()}
        <div className={styles.input}>
          <Field
            id="password-reset_email"
            name="email"
            placeholder="sample-mail@studyplus.jp"
            component={Input}
          />
        </div>
        <div className={styles.button}>
          <Button type="submit" disabled={!this.isSubmittable()}>
            再設定メールを送信
          </Button>
        </div>
        <Loader loading={this.props.isSubmitting} />
      </Form>
    );
  }

  private renderError() {
    if (!this.shouldRenderError()) {
      return null;
    }
    return <ErrorText>{this.props.errors.email}</ErrorText>;
  }

  private isSubmittable() {
    return this.props.isValid && !this.props.isSubmitting;
  }

  private shouldRenderError() {
    return this.props.errors.email && this.props.touched.email;
  }
}

const mapPropsToValues = (): Values => ({
  email: "",
});

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
) => {
  formikBag.setSubmitting(true);
  ApiClient.post(`/api/v1/password_reset`, { operator: values }).then((res) => {
    if (res.ok) {
      formikBag.props.navigate(`/password_reset/sent`);
    }
    formikBag.setSubmitting(false);
  });
};
const FormedComponent = withFormik({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
})(PasswordResetRequestForm);

export default withRouter<Props>(FormedComponent);
