import classNames from "classnames";
import * as React from "react";
import {
  GroupHoverProps,
  resolveGroupHoverStyle,
} from "../../../helpers/styles/Colors";
import styles from "./styles.scss";

type Props = {
  children: React.ReactNode;
  marginTop?: string;
  justifyContent?:
    | "start"
    | "end"
    | "center"
    | "left"
    | "right"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch"
    | "baseline"
    | "first baseline"
    | "last baseline"
    | "safe center"
    | "unsafe center"
    | "flex-end"
    | "flex-start";
  alignItems?:
    | "start"
    | "end"
    | "center"
    | "stretch"
    | "self-start"
    | "self-end"
    | "flex-end"
    | "flex-start"
    | "baseline"
    | "first baseline"
    | "last baseline"
    | "safe center"
    | "unsafe center";
  wrap?: boolean;
  gap?: "0.4" | "0.8" | "1" | "1.6" | "2";
} & GroupHoverProps;

export const Flex = (props: Props) => {
  return (
    <div
      className={classNames(
        styles.root,
        gapStyle(props.gap),
        {
          [styles.wrap]: props.wrap,
        },
        resolveGroupHoverStyle(props),
      )}
      style={{
        marginTop: props.marginTop || "1rem",
        justifyContent: props.justifyContent,
        alignItems: props.alignItems,
      }}
    >
      {props.children}
    </div>
  );
};

const gapStyles = {
  "0.4": styles.gap0_4,
  "0.8": styles.gap0_8,
  "1": styles.gap1,
  "1.6": styles.gap1_6,
  "2": styles.gap2,
};
const gapStyle = (gap?: Props["gap"]) => (gap ? gapStyles[gap] : undefined);
