import * as React from "react";
import { GroupBase, OptionsOrGroups, Props } from "react-select";
import { LoadOptions } from "react-select-async-paginate";
import api from "../../api";
import {
  Additional,
  LoadableSelect,
} from "../../components/general/SelectWrapper";
import { ManagementLearningMaterial } from "../../domains/ManagementLearningMaterial";
import { createError } from "../../errors";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import SectionInterface from "../../interfaces/SectionInterface";

export type OptionType = {
  label: string;
  value: string;
  unit: string;
};

type SectionProps = {
  section: SectionInterface;
};
type ReactSelectProps = Pick<Props, "placeholder">;

type OwnProps = {
  onChange: (option: OptionType | null | undefined) => void;
  defaultValue: OptionType | null;
};

type FetchResponse = {
  options: OptionType[];
  hasMore: boolean;
} | void;
const useLoadOptions = ({ section }: SectionProps) => {
  const fetcher = async (
    inputWords: string,
    page: number,
  ): Promise<FetchResponse> => {
    const res = await api.get(
      `/api/v1/sections/${section.id}/management_learning_materials`,
      {
        query: {
          name: inputWords,
          page: page,
        },
      },
    );

    if (res.ok) {
      const json = await res.json();
      const learningMaterials = json.sectionManagementLearningMaterials
        .data as ReadonlyArray<ManagementLearningMaterial>;
      const options = learningMaterials.map<OptionType>((material) => ({
        label: material.learningMaterial.name,
        value: material.learningMaterial.publicId,
        unit: material.learningMaterial.unit,
      }));
      const meta = json.sectionManagementLearningMaterials.meta;
      return {
        options,
        hasMore: meta.currentPage < meta.totalPages,
      };
    }
    throw await createError(res);
  };

  const { showErrorMessage } = useFlashMessage();

  return async (
    inputWord: string,
    _loadedOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>,
    { page }: Additional,
  ) => {
    const res = await fetcher(inputWord, page).catch(() => {
      showErrorMessage("教材の検索に失敗しました");
    });

    return {
      options: res ? res.options : [],
      hasMore: res ? res.hasMore : false,
      additional: {
        page: page + 1,
      },
    };
  };
};

const LoadableLearningMaterialSelect_ = ({
  section,
  placeholder,
  onChange,
  defaultValue,
}: SectionProps & ReactSelectProps & OwnProps) => {
  const loadOptions = useLoadOptions({ section });
  return (
    <LoadableSelect
      onChange={(p?: OptionType | null) => {
        onChange(p);
      }}
      placeholder={placeholder}
      noOptionsMessage={() => "教材名を入力することで候補を検索できます"}
      loadingMessage={() => "検索中..."}
      loadOptions={
        loadOptions as LoadOptions<
          OptionType | undefined,
          GroupBase<OptionType | undefined>,
          Additional
        >
      }
      defaultValue={defaultValue}
      size="md"
      isClearable={true}
    />
  );
};

export const LoadableLearningMaterialSelect = React.memo(
  LoadableLearningMaterialSelect_,
);
