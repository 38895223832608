import classNames from "classnames";
import * as React from "react";
import Icon from "../../atoms/Icon/index";
import styles from "./styles.scss";

export type OrderDirectionType = "desc" | "asc";

interface Props {
  label: React.ReactNode;
  orderDirection?: OrderDirectionType;
  active: boolean;
  onClick?: (e: any) => void;
}

const renderIcon = (props: Props) => {
  if (!props.active || !props.orderDirection) {
    return <Icon name="icon-table-sort" />;
  }

  switch (props.orderDirection) {
    case "asc":
      return <Icon name="icon-arrow-drop-up" />;
    case "desc":
    default:
      return <Icon name="icon-arrow-drop-down" />;
  }
};

const SortLabel = (props: Props) => {
  // トグルボタンとしての役割の説明になるので表示とは反対の説明になる
  const label =
    !props.active || props.orderDirection === "asc"
      ? "降順にソートする"
      : "昇順にソートする";
  return (
    <div
      role="button"
      aria-label={`${props.label} ${label}`}
      className={classNames(styles.root, {
        [styles.highlighted]: props.active,
      })}
      onClick={props.onClick}
    >
      {props.label}
      {renderIcon(props)}
    </div>
  );
};

export default SortLabel;
