import classnames from "classnames";
import * as React from "react";
import styles from "./RadioButtonGroup.scss";

/* 一旦スタイリングのバリエーションは考えない */
type RadioButtonProps = React.PropsWithChildren<
  React.HTMLProps<HTMLInputElement>
>;
export const RadioButton = ({
  id,
  children,
  className,
  checked,
  disabled,
  ...restProps
}: RadioButtonProps) => {
  return (
    <label
      htmlFor={id}
      className={classnames(
        styles.Label,
        styles.DefaultColorTypeLabel,
        {
          [styles.DefaultColorTypeLabel__checked]: checked,
          [styles.DefaultColorTypeLabel__disabled]: disabled,
        },
        className,
      )}
    >
      <input
        id={id}
        type="radio"
        className={styles.InputRadio}
        checked={checked}
        disabled={disabled}
        {...restProps}
      />
      {children}
    </label>
  );
};

export const DefaultColorTypeRadioButton = ({
  children,
  ...restProps
}: RadioButtonProps) => {
  return (
    <RadioButton className={styles.DefaultColorTypeLabel} {...restProps}>
      {children}
    </RadioButton>
  );
};

export const RadioButtonGroup = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <div className={styles.FieldSet}>{children}</div>;
};
