import LinkText from "../LinkText";
import styles from "./DescriptionText.scss";
type Props = {
  children: string | null;
};
export const DescriptionText = ({ children }: Props) => {
  if (!children) {
    return null;
  }

  return (
    <div className={styles.DescriptionText}>
      <LinkText>{children}</LinkText>
    </div>
  );
};
