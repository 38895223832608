import classNames from "classnames";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import isBefore from "date-fns/isBefore";
import isToday from "date-fns/isToday";
import subDays from "date-fns/subDays";
import { useCallback, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import styleVars from "../../../styles/variables.scss";
import Icon from "../../atoms/Icon/index";
import { DatePicker } from "../../general/DatePicker";
import styles from "./styles.scss";

type BlockType = "AfterToday" | "Nothing";

interface Props {
  date?: Date;
  onDateChange: (date?: Date) => void;
  blockType?: BlockType;
}

export const SingleDatePickerWrapper = (props: Props) => {
  const [calendarVisible, setCalendarVisible] = useState(false);

  const date = props.date ?? new Date();

  const numberOfMonths =
    window.innerWidth > parseInt(styleVars.breakpointMobile) ? 2 : 1;
  const disabled =
    props.blockType === "Nothing" ? undefined : { after: new Date() };

  const handleOnDateChange = (date: Date) => {
    setCalendarVisible(false);
    props.onDateChange(date);
  };

  const handleClickOutside = useCallback(() => {
    setCalendarVisible(false);
  }, []);

  const handleButtonClick = () => {
    setCalendarVisible(!calendarVisible);
  };

  const handleClickLeft = () => {
    props.onDateChange(subDays(date, 1));
  };

  const handleClickRight = () => {
    if (!canPushRightButton()) {
      return;
    }

    props.onDateChange(addDays(date, 1));
  };

  const canPushRightButton = () => {
    if (props.blockType === "Nothing") {
      return true;
    }
    return isBefore(addDays(date, 1), new Date());
  };

  const { insideRef } = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  return (
    <div className={styles.root}>
      <div className={styles.calendarControl}>
        <Icon
          name="icon-arrow-left"
          className={styles.icon}
          onClick={handleClickLeft}
        />
        <button className={styles.dateRange} onClick={handleButtonClick}>
          {formatDate(date)}
          <Icon
            name="icon-calendar"
            className={`${styles.icon} ${styles.calendarIcon}`}
          />
        </button>
        <Icon
          name="icon-arrow-right"
          className={classNames(styles.icon, {
            [styles.disabledIcon]: !canPushRightButton(),
          })}
          onClick={handleClickRight}
        />
        {calendarVisible && (
          <div ref={insideRef} className={styles.calendar}>
            <DatePicker
              date={date}
              disabled={disabled}
              onDateChange={handleOnDateChange}
              numberOfMonths={numberOfMonths}
              defaultMonth={date}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const formatDate = (date: Date): string => {
  if (isToday(date)) {
    return "今日";
  } else {
    return format(date, "yyyy/M/d");
  }
};
