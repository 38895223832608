import { IconLock } from "@studyplus/boron-ui";
import { useNavigate } from "react-router";
import StudentInterface from "../../interfaces/StudentInterface";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../general/Popover/Popover";
import SwitchTabs from "../molecules/SwitchTabs";
import { useStudentAnalyticsSearchQuery } from "./StudentAnalyticsTypeTab";

export type StudentAnalyticsPerspectiveTypes =
  | "material"
  | "learning_material_tag";

type AnalyticsType = "amount" | "time";

type Props = {
  value: StudentAnalyticsPerspectiveTypes;
  currentAnalyticsType: AnalyticsType;
  student: StudentInterface;
};

export const LearningMaterialTagAnalyticsSwitcher = ({
  value,
  currentAnalyticsType,
  student,
}: Props) => {
  const navigate = useNavigate();
  const { searchQueryWithLearningMaterialFilter } =
    useStudentAnalyticsSearchQuery();

  return (
    <>
      <SwitchTabs
        tabs={[
          {
            id: "time",
            label: "学習時間",
            active: value === "material" && currentAnalyticsType === "time",
          },
          {
            id: "amount",
            label: "学習量",
            active: value === "material" && currentAnalyticsType === "amount",
          },
          {
            id: "learning_material_tag",
            label: <TagTimeLabel student={student} />,
            disabled: student.billingPlan === "free",
            active: value === "learning_material_tag",
          },
        ]}
        onClickTab={(tab) => () => {
          let baseUrl = "";
          if (tab.id === "time") {
            baseUrl = `/students/${student.id}/analytics/time`;
          } else if (tab.id === "amount") {
            baseUrl = `/students/${student.id}/analytics/amount`;
          } else {
            baseUrl = `/students/${student.id}/learning_material_tag_analytics/time`;
          }
          navigate(`${baseUrl}?${searchQueryWithLearningMaterialFilter}`);
        }}
      />
    </>
  );
};

const TagTimeLabel = ({ student }: { student: StudentInterface }) => {
  if (student.billingPlan === "free") {
    return <FreePlanTagTimeLabel />;
  } else {
    return <>学習バランス</>;
  }
};

const FreePlanTagTimeLabel = () => {
  return (
    <div className="-ml-2">
      <PopoverProvider>
        <Popover>
          <PopoverTrigger asChild>
            <div className="gap flex items-center gap-1 align-middle">
              <IconLock className="h-3 w-3" />
              <p className="m-auto">学習バランス</p>{" "}
            </div>
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent side="top">
              <p className="text-center">
                プロプランにすると教材カテゴリごと
                <br />
                の学習割合を閲覧できます
              </p>
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </PopoverProvider>
    </div>
  );
};
