import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../../../../components/atoms/Icon";
import SplitLayout, { Column } from "../../../../components/atoms/SplitLayout";
import TabsNavi, {
  TabsNaviItemProps,
} from "../../../../components/molecules/TabsNavi";
import SectionInterface from "../../../../interfaces/SectionInterface";
import GuardianAnnounceMessageForm from "../GuardianAnnounceMessageForm";
import GuardianAnnounceMessageList from "../GuardianAnnounceMessageList";
import SectionGuardianAnnounceMessageThreadList from "../GuardianAnnounceMessageThreadList";
import styles from "./styles.scss";
import { useDestinationTag } from "./useDestinationTag";

interface Props {
  params: { action: string; tagId: string };
  section: SectionInterface;
  tabs: TabsNaviItemProps[];
}

const SectionGuardianAnnounceMessages: React.FC<Props> = (props: Props) => {
  const defaultFocusdColumn =
    props.params.action == "new" ? Column.Right : Column.Left;
  const [focusedColumn, setFocus] = useState(defaultFocusdColumn);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { section } = props;

  const {
    destinationTags,
    openedThread,
    setOpenedThreadId,
    resetOpenedThreadId,
  } = useDestinationTag({
    sectionId: section.id,
    initialOpenedTagId: props.params.tagId,
  });
  const handleCloseRightColumn = () => {
    setFocus(Column.Left);
  };

  const handleClickThread = (threadId: string) => {
    setFocus(Column.Right);
    setOpenedThreadId(threadId);
  };

  const handleClickNewMessage = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (props.params.action === "new") {
      resetOpenedThreadId();
      return;
    }
    if (!props.params.tagId) {
      return;
    }
    setOpenedThreadId(props.params.tagId);
  }, [props.params.tagId, props.params.action]);

  return (
    <>
      <TabsNavi tabs={props.tabs} />
      <div className={styles.message}>
        <SplitLayout focusedColumn={focusedColumn}>
          <SplitLayout.Navigation focusedColumn={focusedColumn}>
            <div className={styles.newMessage}>
              <Link
                to={`/sections/${section.id}/tags/guardian_messages/new`}
                className={styles.newMessage__button}
                onClick={handleClickNewMessage}
              >
                <Icon name="icon-edit" />
                新しいメッセージを作成
              </Link>
            </div>
            <SectionGuardianAnnounceMessageThreadList
              section={section}
              openedThread={openedThread}
              onClickThread={handleClickThread}
            />
          </SplitLayout.Navigation>
          <>
            <SplitLayout.Header>
              <Icon
                name="icon-arrow-close"
                className={styles.close}
                onClick={handleCloseRightColumn}
              />
              <span className={styles.header}>
                {openedThread ? (
                  <>
                    <span className={styles.header__link}>
                      {openedThread.name}（保護者）
                    </span>
                    へメッセージ
                  </>
                ) : (
                  "保護者への新しいメッセージを作成"
                )}
              </span>
            </SplitLayout.Header>
            <SplitLayout.Body>
              {openedThread ? (
                <GuardianAnnounceMessageList
                  tagId={openedThread.id}
                  section={section}
                />
              ) : (
                <div className={styles.listContainer}></div>
              )}
              <GuardianAnnounceMessageForm
                destinationTag={openedThread || undefined}
                section={props.section}
                setOpenedThreadId={setOpenedThreadId}
                resetOpenedThreadId={resetOpenedThreadId}
                destinationTags={destinationTags || []}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </SplitLayout.Body>
          </>
        </SplitLayout>
      </div>
    </>
  );
};

export default SectionGuardianAnnounceMessages;
