import {
  IconImportBookshelf,
  IconImportMaterial,
  IconImportSeiseki,
  IconImportStudent,
  IconImportStudenttag,
  IconImportTag,
  IconSetupDocument,
} from "@studyplus/boron-ui";
import * as React from "react";
import { RouterProps } from "react-router";
import { Link } from "react-router-dom";
import TabsNavi from "../../../components/molecules/TabsNavi";
import { ImportHistoryInterface } from "../../../domains/ImportHistory";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import styles from "./styles.scss";

interface OwnProps {
  section: SectionInterface;
}

type Props = OwnProps & RouterProps & OutletContextProps;

interface TileProps {
  section: SectionInterface;
  title: string;
  iconComponent: React.FC<{ className: string }>;
  actions: Array<{
    operation: ImportHistoryInterface["operation"];
    name: string;
  }>;
  iconClassName?: string;
}

const Tile = (props: TileProps): JSX.Element => {
  const IconComponent = props.iconComponent;
  return (
    <div className={styles.tile}>
      <div className={styles.tileTitle}>{props.title}</div>
      <div className={styles.tileIconContainer}>
        <IconComponent
          className={
            props.iconClassName
              ? props.iconClassName
              : "h-11 w-11 text-gray-600"
          }
        />
      </div>
      <div className={styles.tileButtons}>
        {props.actions.map((action) => {
          return (
            <Link
              className={`${styles.tileButtonLink} buiButtonBase buiButtonVariantOutline buiButtonSizeSm buiButtonBlock buiButtonRound`}
              to={`/sections/${props.section.id}/settings/${action.operation}`}
              key={`import-actions-${action.name}`}
            >
              {action.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const SectionsSettingsImportHistoriesOperationsPage = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("import_histories");
  }, []);
  if (!props.section) {
    return null;
  }

  return (
    <div>
      <TabsNavi
        tabs={[
          {
            label: "一括操作",
            link: `/sections/${props.section.id}/settings/import_histories/operations`,
            active: true,
          },
          {
            label: "操作履歴",
            link: `/sections/${props.section.id}/settings/import_histories`,
            active: false,
          },
        ]}
      />
      <div className={styles.actions}>
        <Tile
          section={props.section}
          title="生徒の登録・更新"
          iconComponent={IconImportStudent}
          actions={[
            { name: "登録", operation: "student_insert" },
            { name: "更新", operation: "student_update" },
          ]}
        />
        <Tile
          section={props.section}
          title="独自教材の登録・更新"
          iconComponent={IconImportMaterial}
          actions={[
            { name: "登録", operation: "learning_material_insert" },
            { name: "更新", operation: "learning_material_update" },
          ]}
        />
        <Tile
          section={props.section}
          title="教材の本棚登録"
          iconComponent={IconImportBookshelf}
          actions={[{ name: "登録", operation: "bookshelf_insert" }]}
        />
        <Tile
          section={props.section}
          title="タグ作成・更新"
          iconComponent={IconImportTag}
          actions={[
            { name: "登録", operation: "tag_insert" },
            { name: "更新", operation: "tag_update" },
          ]}
        />
        <Tile
          section={props.section}
          title="生徒へのタグ付け・削除"
          iconComponent={IconImportStudenttag}
          actions={[
            { name: "登録", operation: "student_tag_insert" },
            { name: "削除", operation: "student_tag_delete" },
          ]}
        />
        <Tile
          section={props.section}
          title="成績記録の登録"
          iconComponent={IconImportSeiseki}
          iconClassName="h-13 w-13 text-gray-600"
          actions={[{ name: "登録", operation: "examination_result_insert" }]}
        />
        <Tile
          section={props.section}
          title="初回配布資料の作成"
          iconComponent={IconSetupDocument}
          actions={[
            { name: "生徒用", operation: "entry_guides_for_students" },
            ...(props.section.guardianLineConnectionUsable
              ? ([
                  { name: "保護者用", operation: "entry_guides_for_guardians" },
                ] as TileProps["actions"])
              : []),
          ]}
        />
        <Tile
          section={props.section}
          title="生徒利用プランの更新"
          iconComponent={IconImportStudent}
          actions={[{ name: "更新", operation: "student_billing_plan_update" }]}
        />
      </div>
    </div>
  );
};

const pageInfo = {
  title: "一括操作",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsImportHistoriesOperationsPage,
  pageInfo,
  ["import_history:index"],
);

export default EnhancedPage;
