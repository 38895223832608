import * as React from "react";
import { KartesStateInterface } from "../../../interfaces/KarteInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import Loader from "../../atoms/Loader/index";
import StudentFilter from "../../features/NewStudentFilter";
import KarteItem from "../../molecules/KarteItem";
import TabsNavi from "../../molecules/TabsNavi";
import styles from "./styles.scss";

interface Props {
  section: SectionInterface;
  kartes: KartesStateInterface;
}

const renderKartes = (props: Props) => {
  const { section, kartes } = props;

  if (!kartes.data) {
    return null;
  }

  if (kartes.data.length === 0) {
    return <p className={styles.notfound}>カルテがありません</p>;
  }

  return kartes.data.map((karte) => {
    return (
      <KarteItem
        section={section}
        karte={karte}
        key={`SectionKartes-KarteItem-${karte.id}`}
      />
    );
  });
};

const SectionsKartes = (props: Props) => {
  return (
    <React.Fragment>
      <TabsNavi
        tabs={[
          {
            label: "カルテ",
            link: `/sections/${props.section.id}/kartes`,
            active: true,
          },
        ]}
      />
      <StudentFilter sectionId={props.section.id} />
      {renderKartes(props)}
      <Loader loading={props.kartes.loading} />
    </React.Fragment>
  );
};

export default SectionsKartes;
