import { usePushHistory } from "../../hooks/usePushHistory";
import { AnalyticsType } from "../../interfaces/AnalyticsTableInterface";
import TabsNavi, { TabsNaviItemProps } from "../molecules/TabsNavi";
import { useLearningMaterialFilterWithLocalStorageContext } from "./LearningMaterialFilter/useLearningMaterialFilterWithLocalStorageContext";
import { useStudentFilterContext } from "./NewStudentFilter/useStudentFilterContext";

type Props = {
  sectionId: string;
  analyticsType: AnalyticsType;
};
export const SectionAnalyticsTypeTab = ({
  sectionId,
  analyticsType,
}: Props) => {
  const { searchQueryWithAllFilters, searchQueryWithOnlyStudentFilter } =
    useSearchQuery(sectionId);

  return (
    <TabsNavi
      tabs={
        [
          {
            label: "学習記録",
            link: {
              pathname: `/sections/${sectionId}/analytics/time`,
              search: searchQueryWithOnlyStudentFilter,
            },
            active: analyticsType === "time" || analyticsType === "amount",
          },
          {
            label: "学習定着",
            link: {
              pathname: `/sections/${sectionId}/analytics/retention_level`,
              search: searchQueryWithAllFilters,
            },
            active: analyticsType === "retentionLevel",
          },
          {
            label: "課題進捗",
            link: {
              pathname: `/sections/${sectionId}/analytics/study_task`,
              search: "?period=during",
            },
            active: analyticsType === "studyTask",
          },
          {
            label: "成績記録",
            link: {
              pathname: `/sections/${sectionId}/analytics/examinations`,
            },
            active: analyticsType === "examination",
          },
          {
            label: "入退室記録",
            link: {
              pathname: `/sections/${sectionId}/analytics/stay`,
              search: searchQueryWithOnlyStudentFilter,
            },
            active: analyticsType === "stay",
          },
          {
            label: "出席記録",
            link: {
              pathname: `/sections/${sectionId}/analytics/lecture_attendance/student`,
              search: searchQueryWithOnlyStudentFilter,
            },
            active: analyticsType === "lectureAttendance",
          },
          {
            label: "個別メッセージ",
            link: {
              pathname: `/sections/${sectionId}/analytics/direct_messages`,
              search: searchQueryWithOnlyStudentFilter,
            },
            active: analyticsType === "directMessage",
          },
          {
            label: "一斉メッセージ",
            link: {
              pathname: `/sections/${sectionId}/analytics/announce_messages`,
              search: searchQueryWithOnlyStudentFilter,
            },
            active: analyticsType === "announceMessage",
          },
        ].filter((tab) => tab !== null) as TabsNaviItemProps[]
      }
      scrollable={true}
    />
  );
};

const useSearchQuery = (sectionId: string) => {
  const studentFilterContext = useStudentFilterContext();
  const learningMaterialFilterContext =
    useLearningMaterialFilterWithLocalStorageContext();
  learningMaterialFilterContext.setFilterKey(sectionId);
  const tagIds = learningMaterialFilterContext.items
    ?.filter((tag) => tag.id !== "have_no_tag")
    .map((tag) => tag.id);
  const haveNoTag = learningMaterialFilterContext.items?.some(
    (tag) => tag.id === "have_no_tag",
  );

  const { buildQuery } = usePushHistory();
  const searchQueryWithAllFilters = buildQuery(
    {
      learning_material_tag_ids: tagIds,
      have_no_tag: haveNoTag ? haveNoTag.toString() : undefined,
      ...studentFilterContext.getStudentFilterParams,
    },
    ["period"],
  ); // 課題進捗でのみ使うperiodを除外
  const searchQueryWithOnlyStudentFilter = buildQuery(
    studentFilterContext.getStudentFilterParams,
    ["period"],
  ); // 課題進捗でのみ使うperiodを除外

  return {
    searchQueryWithAllFilters,
    searchQueryWithOnlyStudentFilter,
  };
};
