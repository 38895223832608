import * as React from "react";
import SelectField from "../../../../components/atoms/DeprecatedSelectField";
import {
  Recurrence,
  RecurringOptions,
  createDaily,
  createIndexedWeekDay,
  createMonthly,
  createWeekDays,
  createWeekly,
  formatRecurrence,
  isChangeableToEmptyRecurrence,
} from "../../../../domains/Recurrence";

type Props = {
  value: RecurringOptions | null;
  date: Date;
  onChange: (option: any) => void;
  disabled: boolean;
  currentRecurrence: Recurrence | null;
};
export const RecurrenceSelect = (props: Props): React.ReactElement => {
  const optionsTable = React.useMemo(() => {
    return createRecurrencesTable(
      props.date,
      props.currentRecurrence ? props.currentRecurrence.until : null,
    );
  }, [props.date.toUTCString()]);

  const options = React.useMemo(
    () => createOptions(optionsTable, props.currentRecurrence),
    [optionsTable],
  );

  return (
    <SelectField
      value={props.value}
      options={options}
      onChange={props.onChange}
      defaultValue={options[0]}
      isSearchable={false}
      placeholder=""
      disabled={props.disabled}
      hideIndicator
    />
  );
};

const createOptions = (
  recurrencesTable: Record<RecurringOptions, Recurrence>,
  currentRecurrence: Props["currentRecurrence"],
) => {
  const allowIncludeEmptyOption =
    isChangeableToEmptyRecurrence(currentRecurrence);
  const recurrences = [
    ...(allowIncludeEmptyOption ? [null] : []),
    ...RecurringOptions,
  ];

  return recurrences.map((r) => {
    return {
      label: formatRecurrence(r ? recurrencesTable[r] : null, true),
      value: r,
    };
  });
};

const createRecurrencesTable = (
  date: Date,
  until: Date | null,
): Record<RecurringOptions, Recurrence> => {
  return {
    daily: createDaily(until),
    weekly: createWeekly(date, until),
    weekdays: createWeekDays(until),
    indexedWeekDay: createIndexedWeekDay(date, until),
    monthly: createMonthly(date, until),
  };
};
