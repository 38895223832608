import { ActionTypes, Actions } from "../../../actions/common/skills/types";
import SectionsSettingsSkillsNewStateInterface from "../../../interfaces/SectionsSettingsSkillsNewStateInterface";

const defaultState: SectionsSettingsSkillsNewStateInterface = {
  submitting: false,
  showModal: false,
  apiErrors: [],
  loading: false,
};

const sectionsSettingsSkillsNew = (
  state: SectionsSettingsSkillsNewStateInterface = defaultState,
  action: Actions,
): SectionsSettingsSkillsNewStateInterface => {
  switch (action.type) {
    case ActionTypes.POST_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case ActionTypes.POST_SUCCESS:
      return {
        ...state,
        submitting: false,
        showModal: true,
      };
    case ActionTypes.POST_FAILURE:
      return {
        ...state,
        submitting: false,
        apiErrors: action.payload ? action.payload.errors : [],
      };
    case ActionTypes.CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
      };

    case ActionTypes.GET_SKILL_MASTER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_SKILL_MASTER_SUCCESS:
      return {
        ...state,
        skillMaster: action.payload.skillMaster,
        loading: false,
      };

    case ActionTypes.GET_SKILL_MASTER_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default sectionsSettingsSkillsNew;
