import styles from "./BreadcrumbsView.styles.scss";

import { Link } from "react-router-dom";
import {
  BreadcrumbItem,
  Breadcrumbs,
  Props as BreadcrumbsProps,
} from "../Breadcrumbs";

type Props = Pick<BreadcrumbsProps, "items">;

export const BreadcrumbsView = (props: Props) => {
  return (
    <div className={styles.BreadcrumbsView}>
      <Breadcrumbs {...props}>{renderBreadcrumbItem}</Breadcrumbs>
    </div>
  );
};
const renderBreadcrumbItem = (item: BreadcrumbItem, isLastItem: boolean) =>
  isLastItem || !item.url ? (
    item.label
  ) : (
    <Link to={item.url}>{item.label}</Link>
  );
