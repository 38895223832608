import { Link } from "react-router-dom";
import AlignMiddle from "../../../../components/atoms/AlignMiddle";
import Icon from "../../../../components/atoms/Icon";
import SwitchButton from "../../../../components/atoms/SwitchButton";
import { TableColumn } from "../../../../components/atoms/Table";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import SkillInterface from "../../../../interfaces/SkillInterface";
import styles from "./styles.scss";
import { useDeleteSkill } from "./useDeleteSkill";
import { useUpdateSkillStatus } from "./useUpdateSkillStatus";

const SkillIcon = ({ skill }: { skill: SkillInterface }) => {
  switch (skill.skillMaster.type) {
    case "study_record_skill_master":
    default:
      return <img src={`/images/icon-skill-study_time.png`} />;
  }
};
export const SkillRow = ({
  sectionId,
  skill,
}: {
  sectionId: string;
  skill: SkillInterface;
}) => {
  const { enableSkill, disableSkill } = useUpdateSkillStatus({ sectionId });
  const { deleteSkill, isDeletingSomeSkill } = useDeleteSkill({ sectionId });
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const enable = async () => {
    await enableSkill(skill.id, {
      onSuccess: () => {
        showSuccessMessage("スキルを有効にしました");
      },
      onError: () => {
        showErrorMessage("スキルを有効にできませんでした");
      },
    });
  };

  const disable = async () => {
    await disableSkill(skill.id, {
      onSuccess: () => {
        showSuccessMessage("スキルを無効にしました");
      },
      onError: () => {
        showErrorMessage("スキルを無効にできませんでした");
      },
    });
  };

  const handleChangeEnabled = async (value: boolean): Promise<boolean> => {
    if (value) {
      await enable();
      return true;
    } else {
      let confirmMessage = "アシスタントスキルをオフにしてよろしいですか？";
      if (Object.hasOwnProperty.call(skill.arguments, "smartTag")) {
        confirmMessage +=
          "\nオフにすると、生徒に付いているスマートタグも外れます。";
      }

      if (window.confirm(confirmMessage)) {
        await disable();
        return false;
      }
      return true;
    }
  };

  const handleDeleteSkill = () => {
    if (isDeletingSomeSkill) return;

    if (skill.enabled) {
      window.alert(
        `スキルは「オン」の状態では削除できません。「オフ」にしてから削除してください。`,
      );
      return;
    }

    if (
      window.confirm(
        `スキル「${skill.name}」を削除してもよろしいですか？\n削除すると、すでに送信されているアシスタントメッセージも削除されます。`,
      )
    ) {
      deleteSkill(skill.id);
    }
  };

  return (
    <tr>
      <TableColumn className={styles.nameColumn} centering={false}>
        <div className={styles.skillMaster}>
          <SkillIcon skill={skill} />
          <div className={styles.skillName}>
            <Link
              className={styles.name}
              to={`/sections/${sectionId}/settings/skills/${skill.id}/edit`}
            >
              {skill.name}
            </Link>
          </div>
        </div>
      </TableColumn>
      <TableColumn centering={false} className={styles.descriptionColumn}>
        <div className={styles.description}>{skill.description}</div>
      </TableColumn>
      <TableColumn centering={true} className={styles.enabledColumn}>
        <SwitchButton
          value={skill.enabled}
          onChange={handleChangeEnabled}
          aria-label={`${skill.name}を${skill.enabled ? "無効化" : "有効化"}`}
        />
      </TableColumn>
      <TableColumn centering={true} className={styles.editColumn}>
        <Link
          to={`/sections/${sectionId}/settings/skills/${skill.id}/edit`}
          className={styles.link}
        >
          <AlignMiddle>
            <Icon name="icon-edit" />
            <span className={styles.link__text}>編集</span>
          </AlignMiddle>
        </Link>
      </TableColumn>
      <TableColumn centering={true} className={styles.deleteColumn}>
        <button
          disabled={isDeletingSomeSkill}
          onClick={handleDeleteSkill}
          className={styles.text_button}
        >
          <AlignMiddle>
            <Icon name="icon-trash" />
            <span className={styles.link__text}>削除</span>
          </AlignMiddle>
        </button>
      </TableColumn>
    </tr>
  );
};
