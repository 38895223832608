import { parseISO } from "date-fns";
import { FormikProps } from "formik";
import * as React from "react";
import { Values } from "../../components/features/ExaminationResultForm/useExaminationResultForm";
import { ExaminationResult } from "../../domains/ExaminationResult";
import { useFetchExaminationResultAttachments } from "../../hooks/http/useFetchExaminationResultAttachments";

export const useEditExaminationResultFormEffect = (
  formik: FormikProps<Values>,
  examinationResult?: ExaminationResult,
) => {
  const { data: attachmentFiles } =
    useFetchExaminationResultAttachments(examinationResult);

  React.useEffect(() => {
    if (examinationResult) {
      const nextValues = {
        ...formik.values,
        totalResult: {
          id: "total",
          name: "総合",
          point: examinationResult.point,
          deviation: examinationResult.deviation,
          allocationOfMarks: examinationResult.allocationOfMarks,
        },
        organizerId: examinationResult.examination.organizer.id,
        classificationId: examinationResult.examination.classification.id,
        examination: examinationResult.examination,
        examinedOn: parseISO(examinationResult.examinedOn),
        resultAttachments: attachmentFiles ?? [],
        numberOfImages: 0,
      };

      // 試験の科目をベースに科目成績が存在すれば、成績をフォームの値にセットします
      const subjectResults: { [id: string]: Values["subjectResults"][number] } =
        {};
      examinationResult.examination.subjects.forEach((subject) => {
        const result = examinationResult.subjectResults.find(
          (result) => result.subject.id === subject.id,
        );
        subjectResults[subject.id] = {
          id: subject.id,
          name: subject.name,
          point: result ? result.point : null,
          deviation: result ? result.deviation : null,
          allocationOfMarks: result ? result.allocationOfMarks : null,
        };
      });
      nextValues.subjectResults = subjectResults;

      formik.setValues(nextValues);
    }
  }, [examinationResult?.id, attachmentFiles]);
};
