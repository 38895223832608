import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import { Link } from "react-router-dom";
import { BlockRow } from "../../../../components/atoms/BlockRow";
import { Flex } from "../../../../components/atoms/Flex";
import Icon from "../../../../components/atoms/Icon";
import Loader from "../../../../components/atoms/Loader";
import Table, { TableColumn } from "../../../../components/atoms/Table";
import { CopyToClipboard } from "../../../../components/general/CopyToClipboard";
import { ContentCourseStudent } from "../../../../domains/Student";
import { stoppingDelivery } from "../../../../domains/Student";
import { truncate } from "../../../../helpers/String";
import TimeHelper from "../../../../helpers/TimeHelper";
import { useScrollWatcher } from "../../../../hooks/useScrollWatcher";
import styles from "./styles.scss";

type Props = {
  isLoading: boolean;
  courseStudents: ContentCourseStudent[];
  onFetchNextPage: () => void;
};

export const StudentList: React.FC<Props> = ({
  isLoading,
  courseStudents,
  onFetchNextPage,
}) => {
  const onScroll = useScrollWatcher({ onReachToBottom: onFetchNextPage });
  return (
    <BlockRow marginTop="1.6rem">
      <div className={styles.table} onScroll={onScroll}>
        <Table
          headSticky
          noOutlineBorder
          headers={[
            { key: "name", text: "氏名", centering: true },
            { key: "job", text: "職業（学年）", centering: true },
            { key: "status", text: "ステータス", centering: true },
            { key: "url", text: "配信URL", centering: true },
            { key: "sharedAt", text: "配信日時", centering: true },
          ]}
          rows={
            courseStudents.length > 0
              ? courseStudents.map((courseStudent) => (
                  <TableRow
                    courseStudent={courseStudent}
                    key={`course-students-table-row-${courseStudent.id}`}
                  />
                ))
              : [<EmptyTableRow key={`course-students-table-row-empty`} />]
          }
        />
        <div className={styles.table__loader} hidden={!isLoading}>
          <Loader loading={isLoading} />
        </div>
      </div>
    </BlockRow>
  );
};

type TableRowProps = {
  courseStudent: ContentCourseStudent;
};
const TableRow = ({ courseStudent }: TableRowProps) => {
  return (
    <tr
      className={stoppingDelivery(courseStudent) ? styles["row--inactive"] : ""}
    >
      <TableColumn>
        <Link className={styles.name} to={`/students/${courseStudent.id}`}>
          <Flex alignItems="center" marginTop="0">
            <Icon className={styles.name__icon} name="icon-notice-user" />
            {courseStudent.fullName}
          </Flex>
        </Link>
      </TableColumn>
      <TableColumn centering>{courseStudent.jobHuman}</TableColumn>
      <TableColumn centering>{courseStudent.statusHuman}</TableColumn>
      <TableColumn centering>{renderDeliveryStatus(courseStudent)}</TableColumn>
      <TableColumn centering>
        {courseStudent.sharedAt
          ? TimeHelper.fullFormat(courseStudent.sharedAt)
          : null}
      </TableColumn>
    </tr>
  );
};
const renderDeliveryStatus = (courseStudent: ContentCourseStudent) => {
  if (courseStudent.deliveryStatus === "in_delivery" && courseStudent.url) {
    return (
      <Flex marginTop="0" alignItems="center" justifyContent="center">
        <div className={styles.url}>
          <a
            className={styles.url__link}
            href={courseStudent.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {truncate(courseStudent.url, 50)}
          </a>
        </div>
        <CopyToClipboard text={courseStudent.url}>
          <Button
            isRound
            variant="outline"
            size="xs"
            onClick={() => courseStudent.url && document.execCommand("copy")}
          >
            リンクをコピー
          </Button>
        </CopyToClipboard>
      </Flex>
    );
  } else if (courseStudent.deliveryStatus === "scheduled_delivery") {
    return "AM 7:00に配信が開始されます";
  } else if (courseStudent.deliveryStatus === "stop_delivery") {
    return "AM 7:00に配信が停止されます";
  }
};
const EmptyTableRow = () => {
  return (
    <tr className={styles.row}>
      <TableColumn
        colSpan={5}
        noWrap
        centering
        className={styles["table__row--blank"]}
      >
        右上の「配信タグの登録」から配信したい対象のタグを設定し、コースの配信ができます。
      </TableColumn>
    </tr>
  );
};
