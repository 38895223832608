import MessageThreadInterface from "../../../interfaces/MessageThreadInterface.ts";
import OperatorInterface from "../../../interfaces/OperatorInterface.ts";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface.ts";
import { generateStorageKey } from "../../atoms/ResumableTextarea/useResumableTextarea.ts";
import CommonMessageForm from "../../molecules/CommonMessageForm";
import { useDirectMessageForm } from "./useDirectMessageForm.ts";

export interface Props {
  sectionId: string;
  student: MessageThreadInterface["student"];
  operator: OperatorInterface;
  operatorProfile: OperatorProfileInterface | null;
}

const DirectMessageForm = (props: Props) => {
  const {
    formState,
    handleChangeContent,
    handleChangeFile,
    handlePostMessage,
    handlePostMessageFile,
    handlePostZoomMeetingMessage,
  } = useDirectMessageForm({
    sectionId: props.sectionId,
    studentId: props.student.id,
  });

  return (
    <CommonMessageForm
      formState={formState}
      canSendMessage={props.student.canSendMessage}
      postMessage={handlePostMessage}
      postMessageFile={handlePostMessageFile}
      postZoomMeetingMessage={handlePostZoomMeetingMessage}
      changeFormContent={handleChangeContent}
      changeFormFile={handleChangeFile}
      allowedFileTypes={["jpg", "png", "gif", "pdf"]}
      disabledPlaceholder={
        "連携していない生徒のため、メッセージが送信できません"
      }
      rows={1}
      operator={props.operator}
      operatorProfile={props.operatorProfile}
      destination={{
        path: `/students/${props.student.id}`,
        name: props.student.fullName,
      }}
      defaultZoomMeetingContent={`${props.student.fullName}さんとZoomミーティングを行います。`}
      storageKey={generateStorageKey(["messages", props.student.id, "new"])}
    />
  );
};

export default DirectMessageForm;
