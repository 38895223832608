import * as React from "react";
import Table from "../../../components/atoms/Table/index";
import { TableColumn } from "../../../components/atoms/Table/index";
import {
  Content,
  ContentTypes,
  formatContentType,
  formatFileSize,
} from "../../../domains/Content";
import styles from "./SingleContentFileTable.scss";

type Props = {
  file: File | null;
  content: Content;
};

export const SingleContentFileTable = ({ file, content }: Props) => {
  const rowProps: RowProps = file
    ? {
        name: file.name,
        fileSize: file.size,
        contentType: file.type,
        ltiToolName: null,
      }
    : content;
  return (
    <Table
      headers={[
        { key: "name", text: "コンテンツ名", centering: false },
        { key: "fileSize", text: "サイズ", centering: true },
        { key: "contentType", text: "種類", centering: true },
      ]}
      rows={[<TableRow key="content-file-row" {...rowProps} />]}
      className={styles.table}
    />
  );
};

type RowProps = {
  name: string;
  fileSize: number | null;
  contentType: string | null;
  ltiToolName: string | null;
};
const TableRow: React.FC<RowProps> = ({
  name,
  fileSize,
  contentType,
  ltiToolName,
}) => {
  return (
    <tr>
      <TableColumn className={styles.contentNameColumn}>{name}</TableColumn>
      <TableColumn noWrap centering className={styles.sizeColumn}>
        {fileSize && formatFileSize(fileSize)}
      </TableColumn>
      <TableColumn noWrap centering className={styles.fileTypeColumn}>
        {contentType &&
          formatContentType(contentType as ContentTypes, ltiToolName)}
      </TableColumn>
    </tr>
  );
};
