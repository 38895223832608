import Container from "./Container";
import Item from "./Item";
import Label from "./Label";
import List from "./List";

const Dropdown = {
  Container,
  List,
  Label,
  Item,
};

export default Dropdown;
