import { useInfiniteQuery } from "@tanstack/react-query";
import ApiClient from "../../api";
import { UnitResults } from "../../domains/ContentCourseUnitResult";
import { HTTPErrors, createError } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";

type Props = {
  sectionId: string;
  courseId: string;
};
export const useFetchContentCourseUnitResults = ({
  sectionId,
  courseId,
}: Props) => {
  const { data, isLoading, ...result } = useInfiniteQuery<
    UnitResults,
    HTTPErrors
  >({
    queryKey: [fetchUrl(sectionId, courseId)],
    queryFn: async ({ pageParam }) => {
      const response = await ApiClient.get(fetchUrl(sectionId, courseId), {
        query: {
          page: pageParam,
        },
      });
      if (response.ok) {
        const json = await response.json();
        return json.unitResults as UnitResults;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  const results: UnitResults | undefined = data
    ? {
        ...data.pages.slice(-1)[0],
        data: data.pages.flatMap((page) => page.data),
      }
    : undefined;
  return {
    ...result,
    data: results,
    isLoading,
  };
};

const fetchUrl = (sectionId: string, courseId: string) =>
  `/api/v1/sections/${sectionId}/contents/courses/${courseId}/results`;
