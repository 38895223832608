import { useMemo } from "react";
import { createContext } from "../../helpers/React";
import { usePageInfoFromUrl } from "./usePageInfoFromUrl";

export const usePageInfo = () => {
  const pageInfo = usePageInfoFromUrl();
  return useMemo(
    () => ({
      ...pageInfo,
    }),
    [pageInfo.featureType, pageInfo.lectureSessionId],
  );
};

export const { useContext: usePageInfoContext, Provider: PageInfoProvider } =
  createContext<ReturnType<typeof usePageInfo>>();
