import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import { fromJson } from "../../../domain-adapters/LectureSession";
import { LectureSessionDetail } from "../../../domains/LectureSession";
import { HTTPErrors, createError } from "../../../errors";

type UseLectureSessionDetailProps = {
  sectionId: string;
  lectureSessionId: string;
};
export const useFetchLectureSession = ({
  sectionId,
  lectureSessionId,
}: UseLectureSessionDetailProps) => {
  const apiPath = `/api/v1/sections/${sectionId}/lecture_sessions/${lectureSessionId}`;
  return useQuery<LectureSessionDetail, HTTPErrors>({
    queryKey: cacheKeyOf({ sectionId, lectureSessionId }),
    queryFn: async () => {
      const res = await api.interruptGet(apiPath);
      if (res.ok) {
        const json = await res.json();
        return fromJson(json.lectureSession);
      }
      throw await createError(res);
    },
    retry: false,
  });
};

export const cacheKeyOf = ({
  sectionId,
  lectureSessionId,
}: UseLectureSessionDetailProps) => [
  "lectureSessionDetail",
  sectionId,
  lectureSessionId,
];
