import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../api";
import { fromJson } from "../../domain-adapters/StudentCourseResult";
import { StudentCourseResult } from "../../domains/StudentCourseResult";
import { HTTPErrors, createError } from "../../errors";

export const useFetchStudentCourseResult = (
  studentId: string,
  courseId: string,
) => {
  const result = useQuery<StudentCourseResult, HTTPErrors>({
    queryKey: [fetchUrl(studentId, courseId)],
    queryFn: async () => {
      const response = await ApiClient.get(fetchUrl(studentId, courseId));
      if (response.ok) {
        const json = await response.json();
        return fromJson(json.studentCourseResult);
      }
      throw await createError(response);
    },
  });
  return result;
};

const fetchUrl = (studentId: string, courseId: string): string =>
  `/api/v1/students/${studentId}/contents/courses/${courseId}/results`;
