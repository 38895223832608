import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import { Link } from "react-router-dom";
import TimeHelper from "../../../../helpers/TimeHelper.ts";
import BotMessageInterface from "../../../../interfaces/BotMessageInterface";
import SkillMasterInterface from "../../../../interfaces/SkillMasterInterface";
import StudentInterface from "../../../../interfaces/StudentInterface.ts";
import CircleIcon from "../../../atoms/CircleIcon";
import Icon from "../../../atoms/Icon";
import styles from "./styles.scss";

interface Props {
  message: BotMessageInterface;
  student: Omit<StudentInterface, "billingPlan">;
  changeStatusDone: () => void;
  changeStatusIgnored: () => void;
}

export const BOT_NAME = "アシスタント";

const BotMessageItem = (props: Props): React.ReactElement<Props> => {
  return (
    <div
      className={styles.root}
      role="listitem"
      data-testid="bot_message-listitem"
    >
      {renderBotMessage(props)}
      {renderWarning(props.message.skill.enabled)}
    </div>
  );
};

const renderBotMessage = (props: Props) => {
  const { sentAt } = props.message;

  return (
    <div className={styles.message}>
      <div className={styles.header}>
        <CircleIcon
          iconProps={{
            name: "icon-notice-assistant",
            fileType: "png",
            iconClassName: styles.botIcon,
          }}
          className={styles.botIconWrapper}
        />
        <span className={styles.name}>{BOT_NAME}</span>
        <time className={styles.datetime}>{TimeHelper.fullFormat(sentAt)}</time>
        <span className={styles.caution}>
          <Icon name="icon-eye" className={styles.eyeIcon} />
          このメッセージは生徒には見えていません
        </span>
      </div>
      <div className={styles.body}>
        {renderBody(props.student.section.id, props.message)}
        {renderStatus(props)}
      </div>
    </div>
  );
};

const renderBody = (sectionId: string, message: BotMessageInterface) => {
  const skillPath = `/sections/${sectionId}/settings/skills/${message.skill.id}/edit`;

  return (
    <p className={styles.content}>
      「
      <Link to={skillPath} className={styles.skillName}>
        {message.skill.name}
      </Link>
      」に該当しました。
    </p>
  );
};

const renderSkillLink = (
  skillMaster: SkillMasterInterface,
  studentId: string,
) => {
  let buttonLink;

  switch (skillMaster.type) {
    case "study_record_skill_master":
    default:
      buttonLink = {
        path: `/students/${studentId}/analytics`,
        label: "アナリティクス",
      };
  }

  return (
    <Link to={buttonLink.path}>
      <Button
        disabled={false}
        size="xs"
        variant="outline"
        className={styles.redButton}
      >
        {`${buttonLink.label}を見る`}
      </Button>
    </Link>
  );
};

const renderStatus = (props: Props) => {
  const { message } = props;

  switch (message.status) {
    case "todo":
      return (
        <div className={styles.statusWithReactions}>
          <p>対応状況を選んでください。</p>
          <div className={styles.status__reactions}>
            <Button
              onClick={props.changeStatusDone}
              disabled={false}
              size="xs"
              variant="outline"
            >
              対応した
            </Button>
            <Button
              onClick={props.changeStatusIgnored}
              disabled={false}
              size="xs"
              variant="outline"
              className={styles.grayButton}
            >
              対応しない
            </Button>
            {renderSkillLink(props.message.skill.skillMaster, props.student.id)}
          </div>
        </div>
      );
    case "done":
      return (
        <div className={styles.status}>
          <div className={styles.status__reactions}>
            <p className={styles.status__done}>「対応した」を選択しました。</p>
          </div>
        </div>
      );
    case "ignored":
      return (
        <div className={styles.status}>
          <div className={styles.status__reactions}>
            <p className={styles.status__ignored}>
              「対応しない」を選択しました。
            </p>
          </div>
        </div>
      );
  }
};

const renderWarning = (enabled: boolean) =>
  enabled ? null : (
    <p className={styles.warning}>
      <span className={styles.emoji}>⚠</span>
      このアシスタントスキルは現在停止中です。
    </p>
  );

export default BotMessageItem;
