import * as React from "react";
import { useEffect } from "react";
import Loader from "../../../components/atoms/Loader/index";
import TabsNavi from "../../../components/molecules/TabsNavi";
import {
  OutletContextProps,
  WithRouterProps,
  withRouter,
} from "../../../helpers/RouterHelper";
import { AuthenticatedPageCallbacks } from "../../../hocs/enhanceAuthenticatedPage";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import SectionInterface from "../../../interfaces/SectionInterface";
import SectionSkills from "./SectionSkills";
import { useFetchSkills } from "./useFetchSkills";

interface OwnProps {
  section: SectionInterface;
}

interface Props
  extends WithRouterProps,
    AuthenticatedPageCallbacks,
    OwnProps,
    OutletContextProps {}

const SectionsSettingsSkillsPage: React.FC<Props> = (props) => {
  useEffect(() => {
    props.setActiveMenu("skills");
  }, []);

  const { skills, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useFetchSkills({
      sectionId: props.section.id,
    });

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage]);

  return (
    <>
      <TabsNavi
        tabs={[
          {
            label: "アシスタント",
            active: true,
            link: "#",
          },
        ]}
      />
      {!isLoading && (
        <SectionSkills
          section={props.section}
          skills={skills}
          loading={isLoading}
        />
      )}
      <Loader loading={isFetchingNextPage || isLoading} />
    </>
  );
};

const pageInfo = {
  title: "アシスタント",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsSkillsPage,
  pageInfo,
  ["assistant:index"],
);

export default withRouter<Props>(EnhancedPage);
