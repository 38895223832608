import { useParams } from "react-router-dom";
import { useContentCourseDetailQuery } from "../../../hooks/http/useContentCoursesApi";
import { useQueryError } from "../../../hooks/http/useQueryError";
import SectionInterface from "../../../interfaces/SectionInterface";

type UseSectionsSettingsContentCourseDetail = {
  section: SectionInterface;
};

export const useSectionsSettingsContentCourseDetail = (
  props: UseSectionsSettingsContentCourseDetail,
) => {
  const { courseId = "" } = useParams<{ courseId: string }>();
  const { course, isLoading, error } = useContentCourseDetailQuery({
    sectionId: props.section.id,
    courseId,
  });
  useQueryError(error);

  return {
    course,
    isLoading,
  };
};
