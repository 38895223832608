import * as React from "react";
import { NavigateFunction } from "react-router";

import isAfter from "date-fns/isAfter";
import { isPushableCalendarNext } from "../../../helpers/AnalyticsHelper";
import {
  AnalyticsTermType,
  StudentAnalyticsType,
} from "../../../interfaces/AnalyticsTableInterface";
import { OrderDirFilterType } from "../../../interfaces/FiltersInterface";
import FiltersInterface from "../../../interfaces/FiltersInterface";
import {
  StudentAnalyticsRowInterface,
  StudentAnalyticsTableStateInterface,
} from "../../../interfaces/StudentAnalyticsTableInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import PrintButton from "../../atoms/PrintButton";
import { LearningMaterialTagAnalyticsSwitcher } from "../../features/LearningMaterialTagAnalyticsSwitcher";
import { StudentAnalyticsTypeTab } from "../../features/StudentAnalyticsTypeTab";
import { DateRangeNavigation } from "../../general/DateRangeNavigation";
import StudentAnalyticsGraph from "../../molecules/StudentAnalyticsGraph";
import StudentAnalyticsTable from "../../molecules/StudentAnalyticsTable";
import SwitchTabs from "../../molecules/SwitchTabs";
import AnalyticsShareButton from "../AnalyticsShareButton";
import { TotalTime } from "./TotalTime";
import styles from "./styles.scss";

interface Props {
  student: StudentInterface;
  analyticsTable: StudentAnalyticsTableStateInterface;
  analyticsType: Extract<StudentAnalyticsType, "time" | "amount">;
  navigate: NavigateFunction;
  filter: FiltersInterface;
  onDateFilterChange: (dateRange: {
    startDate: Date | null;
    endDate: Date | null;
  }) => void;
  onTermChange: (term: AnalyticsTermType) => void;
  onOrderChange: (
    order?: string | null,
    orderDir?: OrderDirFilterType | null,
  ) => void;
  hasMoreData: boolean;
}

interface State {
  uncheckedRows: StudentAnalyticsRowInterface[];
}

export default class StudentAnalytics extends React.Component<Props, State> {
  get checkedRows(): StudentAnalyticsRowInterface[] {
    const { data } = this.props.analyticsTable;
    const { uncheckedRows } = this.state;

    if (uncheckedRows && data) {
      return data.learningMaterials.filter((learningMaterial) => {
        return !uncheckedRows.some((row) => row.id === learningMaterial.id);
      });
    } else {
      return [];
    }
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      uncheckedRows: [],
    };
  }

  render(): JSX.Element {
    return (
      <React.Fragment>
        <div className={styles.tab}>
          <StudentAnalyticsTypeTab
            activeAnalyticsType={this.props.analyticsType}
            student={this.props.student}
          />
        </div>
        <div className={styles.tableControl}>
          <div className={styles.MaterialSwitchWrapper}>
            <LearningMaterialTagAnalyticsSwitcher
              student={this.props.student}
              currentAnalyticsType={this.props.analyticsType}
              value="material"
            />
          </div>
          <DateRangeNavigation
            startDate={this.props.filter.dateRangeFilter.startDate}
            endDate={this.props.filter.dateRangeFilter.endDate}
            term={this.props.filter.term}
            onDatesChange={this.handleDatesChange}
            calendarDisabled={!this.isDaily()}
            canPushRightButton={isPushableCalendarNext(
              this.props.filter.dateRangeFilter.endDate,
              this.props.filter.term,
            )}
            isDayBlocked={(day: Date) => isAfter(day, new Date())}
          />
          <div className={styles.tableControlRight}>
            <AnalyticsShareButton
              student={this.props.student}
              learningMaterials={this.checkedRows}
              startDate={this.props.filter.dateRangeFilter.startDate}
              endDate={this.props.filter.dateRangeFilter.endDate}
              term={this.props.filter.term}
              analyticsType={this.props.analyticsType}
              columns={
                this.props.analyticsTable.data
                  ? this.props.analyticsTable.data.columns
                  : []
              }
              totalScore={
                this.props.analyticsTable.data
                  ? this.props.analyticsTable.data.totalScore
                  : 0
              }
            />
            <SwitchTabs
              tabs={[
                {
                  id: "daily",
                  label: "日",
                  active: this.isDaily(),
                },
                {
                  id: "weekly",
                  label: "週",
                  active: this.props.filter.term === "weekly",
                },
                {
                  id: "monthly",
                  label: "月",
                  active: this.props.filter.term === "monthly",
                },
              ]}
              onClickTab={(tab: any) => () => {
                this.props.onTermChange(tab.id);
              }}
            />
            <PrintButton />
          </div>
        </div>
        <div className={styles.tableWrapper}>
          <div className="px-8 pb-8 pt-7">
            {this.props.hasMoreData && (
              <div className="float-left text-sm text-gray-900">
                ※全件表示されていません。画面をスクロールして全ての学習記録を表示できます
              </div>
            )}
            {this.props.analyticsTable.data &&
              this.props.analyticsType === "time" && (
                <TotalTime
                  learningMaterials={this.checkedRows}
                  withFreePlanTooltip={
                    this.props.student.billingPlan === "free"
                  }
                />
              )}
            {this.props.analyticsTable.data ? (
              <StudentAnalyticsGraph
                learningMaterials={this.checkedRows}
                term={this.props.filter.term}
                analyticsType={this.props.analyticsType}
                columns={this.props.analyticsTable.data.columns}
              />
            ) : null}
          </div>
          <StudentAnalyticsTable
            student={this.props.student}
            analyticsTable={this.props.analyticsTable}
            analyticsType={this.props.analyticsType}
            orderDir={this.props.filter.orderDir}
            order={this.props.filter.order}
            onOrderChange={this.props.onOrderChange}
            onHeadCheckboxChange={this.handleHeadCheckboxChange}
            onRowCheckboxChange={this.handleRowCheckboxChange}
            uncheckedRows={this.state.uncheckedRows}
          />
        </div>
      </React.Fragment>
    );
  }

  private isUncheckedRow(row: StudentAnalyticsRowInterface): boolean {
    return this.state.uncheckedRows.some(
      (uncheckedRow) => uncheckedRow.id === row.id,
    );
  }

  private handleHeadCheckboxChange = () => {
    if (!this.props.analyticsTable.data || !this.state.uncheckedRows) {
      return;
    }
    if (
      this.state.uncheckedRows.length <
      this.props.analyticsTable.data.learningMaterials.length
    ) {
      this.setState({
        uncheckedRows: this.props.analyticsTable.data.learningMaterials,
      });
    } else {
      this.setState({
        uncheckedRows: [],
      });
    }
  };

  private handleRowCheckboxChange =
    (row: StudentAnalyticsRowInterface) => () => {
      if (this.isUncheckedRow(row)) {
        this.setState({
          uncheckedRows: this.state.uncheckedRows.filter(
            (uncheckedRow) => uncheckedRow.id !== row.id,
          ),
        });
      } else {
        this.setState({
          uncheckedRows: this.state.uncheckedRows.concat(row),
        });
      }
    };

  private isDaily(): boolean {
    return !this.props.filter.term || this.props.filter.term === "daily";
  }

  private handleDatesChange = (dates: { startDate?: Date; endDate?: Date }) => {
    if (dates.startDate && dates.endDate) {
      this.props.onDateFilterChange({
        startDate: dates.startDate,
        endDate: dates.endDate,
      });
    }
  };
}
