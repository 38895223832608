import classNames from "classnames";
import format from "date-fns/format";
import { FormikProps } from "formik";
import { useCallback, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { DatePicker } from "../../general/DatePicker";
import Icon from "../Icon";
import styles from "./styles.scss";

type Props = {
  // ひとまずformikからのrender-props経由じゃなくても動くように。
  // ゆくゆくは別コンポーネントに切り分けてformikの依存がなくても動くようにする。
  value?: Date;
  onDateChange?: (date: Date) => void;
  noIcon?: boolean;
  field?: any;
  form?: FormikProps<any>;
  className?: string;
  isOutsideRange?: (day: Date) => boolean;
  hasError?: boolean;
  disabled?: boolean;
  dateFormat?: string;
  calendarPosition?: {
    left: string;
  };
  iconRight?: boolean;
  iconClassName?: string;
  calendarFromDate?: Date;
  calendarToDate?: Date;
};

export const SingleDatePickerField = (props: Props) => {
  const [isCalendarVisible, setCalendarVisible] = useState(false);

  const date: Date = props.field?.value ?? props.value;

  const handleClickController = () => {
    !props.disabled && setCalendarVisible(!isCalendarVisible);
  };
  const handleClickOutside = () => {
    setCalendarVisible(false);
  };
  const handleDateChange = useCallback(
    (date: Date) => {
      const { form, field } = props;
      if (form && field) {
        form.setFieldValue(field.name, date);
      } else {
        props.onDateChange?.(date);
      }
      setCalendarVisible(false);
    },
    [props.onDateChange, props.form, props.field],
  );
  const disabledDates = useCallback(
    (date: Date) => {
      return props.isOutsideRange?.(date) ?? false;
    },
    [props.isOutsideRange],
  );

  const { insideRef } = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  const calendarPosition = {
    left: props.calendarPosition?.left ?? "left-0",
  };
  return (
    <div className={classNames(styles.root, props.className)}>
      <div
        data-testid="single-date-picker-field"
        className={classNames(styles.field, {
          [styles.error]: props.hasError,
          [styles.disabled]: props.disabled,
          "border-blue-400": isCalendarVisible,
        })}
        onClick={handleClickController}
      >
        {!props.noIcon ? (
          <Icon
            className={classNames({
              "text-gray-600": !isCalendarVisible,
              "text-blue-400": isCalendarVisible,
            })}
            name="icon-calendar"
            color={props.disabled ? "gray-darken-1" : "primary"}
          />
        ) : (
          // このコンポーネントの高さが指定されておらず、各箇所でsvgの高さから帳尻が合わせられているので、スペーサーをかます
          <div className={styles.noIconSpacer} />
        )}
        <p className="ml-4">
          {date && format(date, props.dateFormat || "yyyy/M/d")}
        </p>
        {props.iconRight ? (
          <div className="flex grow justify-end">
            <Icon
              name="icon-calendar"
              color={props.disabled ? "gray-darken-1" : "primary"}
              className={classNames(props.iconClassName)}
            />
          </div>
        ) : (
          // このコンポーネントの高さが指定されておらず、各箇所でsvgの高さから帳尻が合わせられているので、スペーサーをかます
          <div className={styles.noIconSpacer} />
        )}
      </div>
      {isCalendarVisible && (
        <div
          ref={insideRef}
          className={`absolute top-[5.4rem] z-[2] ${calendarPosition.left}`}
        >
          <DatePicker
            date={date}
            onDateChange={handleDateChange}
            numberOfMonths={1}
            defaultMonth={date}
            disabled={disabledDates}
            calendarFromDate={props.calendarFromDate}
            calendarToDate={props.calendarToDate}
          />
        </div>
      )}
    </div>
  );
};
