import * as React from "react";
import { useRef } from "react";
import {
  Button,
  DateFormatter,
  DayPickerProps,
  DayProps,
  DayPicker as OriginalDayPicker,
  useDayRender,
} from "react-day-picker";

import format from "date-fns/format";
import ja from "date-fns/locale/ja";
import Icon from "../../atoms/Icon";

import baseStyles from "react-day-picker/dist/style.module.css";
import styles from "./StyledDayPicker.scss";

const formatCaption: DateFormatter = (month) => {
  return <>{format(month, "Y年 M月")}</>;
};

// NOTE: Omitするとnarrow typesが壊れてるためすべてのPropsを受けて上書きする
// type PartialDayPickerProps = Omit<
//   DayPickerProps,
//   "locale" | "formatters" | "classNames" | "components" | "modifiersClassNames"
// >;
type Props = DayPickerProps;

// react-day-pickerの見た目を統一するためのラッパーコンポーネント
const _StyledDayPicker = (props: Props) => {
  return (
    <OriginalDayPicker
      {...props}
      locale={ja}
      formatters={{ formatCaption }}
      classNames={{
        root: styles.root,
        multiple_months: styles.multiple_months,
        months: styles.months,
        vhidden: baseStyles.vhidden,
        button: styles.button,
        caption_label: styles.caption_label,
        caption: styles.caption,
        nav: styles.nav,
        caption_start: styles.caption_start,
        caption_end: styles.caption_end,
        table: styles.table,
        row: styles.row,
        head_cell: styles.head_cell,
        cell: styles.cell,
        day: styles.day,
        day_selected: styles.day_selected,
        day_today: styles.day_today,
        day_disabled: styles.day_disabled,
        day_range_middle: styles.range_middle,
        day_range_start: styles.range_start,
        day_range_end: styles.range_end,
      }}
      modifiersClassNames={{
        selectedDateRange: styles.selected_date_range,
        selectedDateRangeStart: styles.selected_date_range_start,
        selectedDateRangeEnd: styles.selected_date_range_end,
        highlighted: styles.highlighted,
        highlightedStart: styles.highlighted_start,
      }}
      components={{
        IconLeft,
        IconRight,
        Day,
      }}
    />
  );
};
export const StyledDayPicker = React.memo(_StyledDayPicker);

// disabledのときbuttonではなくdivにしたいので独自コンポーネントにする
const Day = (props: DayProps): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef);

  if (dayRender.isHidden) {
    return <></>;
  }
  if (
    !dayRender.isButton ||
    Object.keys(dayRender.activeModifiers).includes("disabled")
  ) {
    return <div {...dayRender.divProps} />;
  }
  return <Button ref={buttonRef} {...dayRender.buttonProps} />;
};

const IconLeft = () => (
  <Icon name={"icon-arrow-left"} color="black-lighten-2" />
);
const IconRight = () => (
  <Icon name={"icon-arrow-right"} color="black-lighten-2" />
);
