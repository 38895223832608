import { Link } from "react-router-dom";
import AlignMiddle from "../../../../components/atoms/AlignMiddle/index";
import { BlockRow } from "../../../../components/atoms/BlockRow";
import Icon from "../../../../components/atoms/Icon";
import LinkButton from "../../../../components/atoms/LinkButton/index";
import Table from "../../../../components/atoms/Table/index";
import { TableColumn } from "../../../../components/atoms/Table/index";
import DeprecatedTagButton from "../../../../components/features/DeprecatedTagButton";
import {
  ContentCourse,
  DEFAULT_THUMBNAIL_URL,
} from "../../../../domains/ContentCourse";
import TimeHelper from "../../../../helpers/TimeHelper";
import SectionInterface from "../../../../interfaces/SectionInterface";
import { useDeleteCourse } from "../useDeleteCourse";
import styles from "./styles.scss";

type Props = {
  section: SectionInterface;
  courses: ContentCourse[];
};
export const CourseIndex = (props: Props) => {
  return props.courses.length > 0 ? (
    <div className={styles.root}>
      <CourseTable {...props} />
    </div>
  ) : (
    <p className={styles.noContent}>
      コースの登録がありません。右上の「コースの登録」からコースの登録ができます。
    </p>
  );
};

const CourseTable = ({ courses, section }: Props) => {
  const { onDelete, isDeleting } = useDeleteCourse(section.id);
  const handleDelete = (course: ContentCourse) => () =>
    !isDeleting && onDelete(course);

  return (
    <Table
      headers={[
        { key: "thumbnail", text: "", centering: true },
        { key: "name", text: "コース名", centering: false },
        { key: "code", text: "コースコード", centering: true },
        { key: "updatedAt", text: "更新日", centering: true },
        { key: "tag", text: "配信対象タグ", centering: true },
        { key: "edit", text: "", centering: false },
        { key: "delete", text: "", centering: false },
      ]}
      rows={courses.map((course) => (
        <TableRow
          course={course}
          sectionId={section.id}
          onDelete={handleDelete}
          isDeleting={isDeleting}
          key={`course-table-row-${course.id}`}
        />
      ))}
      headColumnClassName={""}
      className={styles.table}
    />
  );
};

type RowProps = {
  course: ContentCourse;
  sectionId: string;
  onDelete: (course: ContentCourse) => () => void;
  isDeleting: boolean;
};
const TableRow = ({ course, onDelete, isDeleting, sectionId }: RowProps) => {
  return (
    <tr key={`content-course-${course.id}`} className={styles.row}>
      <TableColumn centering={true} className={styles.iconColumn}>
        <img
          src={course.thumbnailUrl || DEFAULT_THUMBNAIL_URL}
          className={styles.thumbnail}
        />
      </TableColumn>
      <TableColumn className={styles.courseName}>
        <BlockRow>
          <Link
            className={styles.courseName__name}
            to={`/sections/${sectionId}/settings/content_courses/${course.id}`}
          >
            {course.name}
          </Link>
        </BlockRow>
        {course.startAt && course.endAt ? (
          <BlockRow>
            <span className={styles.courseName__durationLabel}>配信期間：</span>
            <span className={styles.courseName__durationValue}>
              {`${TimeHelper.fullFormat(
                course.startAt,
              )} 〜 ${TimeHelper.fullFormat(course.endAt)}`}
            </span>
          </BlockRow>
        ) : null}
        {course.isSharedCourse ? <SharedLabel course={course} /> : null}
      </TableColumn>
      <TableColumn noWrap centering>
        {course.isSharedCourse ? null : course.id}
      </TableColumn>
      <TableColumn noWrap centering>
        {TimeHelper.fullFormat(course.updatedAt)}
      </TableColumn>
      <TableColumn noWrap bold centering className={styles.studentCount}>
        <AlignMiddle>
          <Link
            to={`/sections/${sectionId}/settings/content_courses/${course.id}/students`}
          >
            {course.tag ? (
              <DeprecatedTagButton theme="white" label={course.tag.name} />
            ) : (
              "未設定"
            )}
          </Link>
        </AlignMiddle>
      </TableColumn>
      <TableColumn noWrap bold centering className={styles.actionColumn}>
        {course.isSharedCourse ? null : (
          <Link
            to={`/sections/${sectionId}/settings/content_courses/${course.id}/edit`}
          >
            <AlignMiddle>
              <Icon name="icon-edit" className={styles.icon} />
              <span>編集</span>
            </AlignMiddle>
          </Link>
        )}
      </TableColumn>
      <TableColumn noWrap bold centering className={styles.actionColumn}>
        <LinkButton disabled={isDeleting} onClick={onDelete(course)} bold>
          <AlignMiddle>
            {course.isSharedCourse ? (
              <span>共有解除</span>
            ) : (
              <>
                <Icon name="icon-trash" className={styles.icon} />
                <span>削除</span>
              </>
            )}
          </AlignMiddle>
        </LinkButton>
      </TableColumn>
    </tr>
  );
};

const SharedLabel = ({ course }: { course: ContentCourse }) => (
  <div className={styles.sharedLabel}>
    <span className={styles.sharedLabel__tag}>共有コース</span>
    <span className={styles.sharedLabel__sectionName}>
      {course.ownerSection.fullName}
    </span>
  </div>
);
