import * as React from "react";

import { IconEmail, IconLine } from "@studyplus/boron-ui";
import classNames from "classnames";
import { LineConnectionStatusType } from "../../../interfaces/StudentInterface";
import styles from "./CommunicationStatusLabel.scss";

interface Props {
  sectionId: string;
  lineConnectionStatus: LineConnectionStatusType;
  isGuardianEmailConfirmed: boolean;
  isAllowedToReceiveGuardianMessage: boolean;
}

const CommunicationStatusLabel = (props: Props) => {
  const { isLineConnected, LabelText } = useCommunicationStatusLabel(props);

  if (!LabelText) return null;

  return (
    <div
      aria-label="送受信可否ラベル"
      className={classNames(
        "mb-1 flex items-center rounded-sm leading-loose text-black",
        {
          [styles.receive__enabled]: props.isAllowedToReceiveGuardianMessage,
          [styles.receive__disabled]:
            !props.isAllowedToReceiveGuardianMessage || !isLineConnected,
        },
      )}
    >
      <div className="flex">
        <IconEmail
          className={classNames("my-2 ml-2 mr-1 h-2 w-2 rounded-sm bg-white", {
            [styles.icon__enabled]: props.isGuardianEmailConfirmed,
            [styles.icon__disabled]: !props.isGuardianEmailConfirmed,
          })}
        />
        <IconLine
          className={classNames("my-2 h-1 w-1 rounded-sm bg-white p-[0.2rem]", {
            [styles.icon__enabled]: isLineConnected,
            [styles.icon__disabled]: !isLineConnected,
          })}
        />
      </div>
      {LabelText}
    </div>
  );
};

const useCommunicationStatusLabel = (props: Props) => {
  const isLineConnected = props.lineConnectionStatus === "connected";
  const LabelText = React.useMemo(() => {
    if (props.lineConnectionStatus === "connected") {
      if (props.isAllowedToReceiveGuardianMessage) {
        return <span className="ml-2">LINE連携済のため送受信が可能です</span>;
      } else {
        const linkUrl = `/sections/${props.sectionId}/settings/guardian_message`;
        return (
          <span className="my-1 ml-2 leading-7">
            保護者LINEメッセージ受付設定がオフのため送信専用です{" "}
            <a
              className="text-black underline"
              href={linkUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              設定を変更する
            </a>
          </span>
        );
      }
    } else if (props.isGuardianEmailConfirmed) {
      return <span className="ml-2">メール登録のみのため送信専用です</span>;
    } else {
      // フォーム非活性のためラベル表示なし
      return null;
    }
  }, [
    props.lineConnectionStatus,
    props.isAllowedToReceiveGuardianMessage,
    props.isGuardianEmailConfirmed,
  ]);

  return {
    isLineConnected,
    LabelText,
  };
};

export default CommunicationStatusLabel;
