import { useQuery, useQueryClient } from "@tanstack/react-query";
import { boronClient } from "../../api.ts";
import { createError } from "../../errors.ts";
import { paths } from "../../lib/api/v1";

type Response =
  paths["/api/v1/students/{student_id}/direct_messages/follow"]["get"]["responses"]["200"]["content"]["application/json"];

const buildKey = (studentId: string) => [
  "/api/v1/students/{student_id}/direct_messages/follow",
  studentId,
];

export const useFetchFollowStatus = ({ studentId }: { studentId: string }) => {
  const result = useQuery({
    queryKey: buildKey(studentId),
    queryFn: async () => {
      const { response, data } = await boronClient.GET(
        `/api/v1/students/{student_id}/direct_messages/follow`,
        {
          params: {
            path: {
              student_id: studentId,
            },
          },
        },
      );
      if (response.ok && data) {
        return data;
      }
      throw await createError(response);
    },
  });

  const followStatus = result.data?.followStatus ?? null;

  return {
    ...result,
    followStatus,
  };
};

export const useUpdateFollowStatusCache = ({
  studentId,
}: {
  studentId: string;
}) => {
  const queryClient = useQueryClient();

  const updateFollowStatusCache = (followStatus: boolean) => {
    queryClient.setQueryData<Response>(buildKey(studentId), (data) => {
      if (!data) {
        return data;
      }

      return {
        ...data,
        followStatus,
      };
    });
  };

  return { updateFollowStatusCache };
};
