import classNames from "classnames";
import NaviMenuToggleContainer, {
  PresentationalComponentProps,
} from "../../../containers/NaviMenuToggleContainer";
import Icon from "../../atoms/Icon";
import styles from "./styles.scss";

const PresetnationalComponent = (props: PresentationalComponentProps) => {
  return (
    <button
      className={classNames(styles.root, { [styles.opened]: props.opened })}
      onClick={props.onToggle}
    >
      <div>
        <Icon name="icon-close-x" className={styles.iconClose} />
      </div>
    </button>
  );
};

const NaviMenuSPToggleButton = () => (
  <NaviMenuToggleContainer presentationalComponent={PresetnationalComponent} />
);

export default NaviMenuSPToggleButton;
