import * as React from "react";
import { BlockRow } from "../../../components/atoms/BlockRow";
import { MainSection, PageHeader } from "../../../components/atoms/MainSection";
import { BreadcrumbItem } from "../../../components/general/Breadcrumb";
import { BreadcrumbsView } from "../../../components/general/BreadcrumbsView/BreadcrumbsView";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import enhanceSectionsSettingsPage, {
  SectionsSettingsPageProps,
} from "../../../hocs/enhanceSectionsSettingsPage";
import { createAddURLAction } from "../../../hooks/useDetermineURLAction";
import SectionInterface from "../../../interfaces/SectionInterface";
import { TeacherForm } from "./forms/TeacherForm";
import styles from "./styles.scss";

// Main hooks
type UseSectionSettingsTeacherNewProps = {
  sectionId: string;
};
const useSectionSettingsTeacherNew = ({
  sectionId,
}: UseSectionSettingsTeacherNewProps) => {
  const urlAction = createAddURLAction();

  const teacherFormProps = {
    urlActionType: urlAction.type,
    editingTeacher: undefined,
  };

  const breadcrumbProps = React.useMemo<BreadcrumbItem[]>(
    () => [
      { label: "講師管理", url: `/sections/${sectionId}/settings/teachers` },
      { label: `講師の登録` },
    ],
    [urlAction, sectionId],
  );

  return {
    teacherFormProps,
    breadcrumbProps,
  };
};

// Main Component
type Props = SectionsSettingsPageProps &
  OutletContextProps & {
    section: SectionInterface;
  };
const SectionsSettingsTeacherNewPage_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("teachers");
  }, []);
  const section = props.section;
  const { teacherFormProps, breadcrumbProps } = useSectionSettingsTeacherNew({
    sectionId: section.id,
  });

  return (
    <>
      <BreadcrumbsView items={breadcrumbProps} />
      <BlockRow marginTop="1.6rem">
        <MainSection>
          <div className={styles.mainSectionWrapper}>
            <PageHeader>講師の登録</PageHeader>
            <p className={styles.description}>
              校舎に所属する指導者・講師の登録ができます。
            </p>
            <TeacherForm sectionId={section.id} {...teacherFormProps} />
          </div>
        </MainSection>
      </BlockRow>
    </>
  );
};

const pageInfo = {
  title: "講師登録",
};

export const SectionsSettingsTeacherNewPage = enhanceSectionsSettingsPage(
  SectionsSettingsTeacherNewPage_,
  pageInfo,
  ["teachers"],
);
