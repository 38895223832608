import { HelpLink } from "../../../components/atoms/HelpLink/index";
import { ColorDot } from "../../../components/general/ColorDot";

const HELP_URL = "https://fs-help.studyplus.co.jp/ja/articles/6838646";

type Props = {
  helpUrl?: string;
};
export const DrillProgressLegend = ({ helpUrl = HELP_URL }: Props) => {
  return (
    <div className="flex flex-initial flex-wrap items-center justify-end text-sm">
      <div className="ml-4">
        <ColorDot className="mr-1 bg-blue-400" />S
      </div>
      <div className="ml-4">
        <ColorDot className="mr-1 bg-yellow-400" />A
      </div>
      <div className="ml-4">
        <ColorDot className="mr-1 bg-darkred-300" />B
      </div>
      <div className="ml-4">
        <ColorDot className="mr-1 bg-gray-400" />
        未学習
      </div>
      <div className="ml-4">
        <HelpLink to={helpUrl} className="h-2 w-2" />
      </div>
    </div>
  );
};
