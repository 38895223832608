import classNames from "classnames";
import isBefore from "date-fns/isBefore";
import * as React from "react";
import { Link } from "react-router-dom";
import TimeHelper from "../../../helpers/TimeHelper";
import { components } from "../../../lib/api/v1";
import Icon from "../../atoms/Icon";
import styles from "./styles.scss";

type Props = {
  link?: string;
  threadName: string;
  latestGuardianMessage: components["schemas"]["GuardianMessage"] | null;
  lastReadAt?: string;
  isActive: boolean;
  onClickThread: () => void;
};

const GuardianMessageThreadListItem: React.FC<Props> = (props: Props) => {
  return props.link ? (
    <Link
      to={props.link}
      onClick={handleClick(props)}
      className={classNames(styles.root, {
        [styles.isUnread]: !isRead(props),
        [styles.isActive]: props.isActive,
      })}
    >
      {renderListContent(props)}
    </Link>
  ) : (
    <div
      onClick={handleClick(props)}
      className={classNames(styles.root, {
        [styles.isUnread]: !isRead(props),
        [styles.isActive]: props.isActive,
      })}
    >
      {renderListContent(props)}
    </div>
  );
};

const renderListContent = (props: Props) => {
  const { threadName } = props;

  return (
    <>
      <div className={styles.left}>
        <div className={styles.attention}>
          <span className={styles.attention__dot} />
        </div>
        <Icon name="icon-guardian-message" className={styles.icon} />
      </div>
      <div className={styles.right}>
        <div className={styles.rightHeader}>
          <div className={styles.name}>{threadName}（保護者）</div>
          {renderSentAt(props)}
        </div>
        {renderGuardianMessage(props)}
      </div>
    </>
  );
};

const renderSentAt = (props: Props) => {
  const { latestGuardianMessage } = props;

  if (latestGuardianMessage) {
    return (
      <time className={styles.datetime}>
        {TimeHelper.fullFormat(latestGuardianMessage.sentAt)}
      </time>
    );
  } else {
    return null;
  }
};

const renderGuardianMessage = (props: Props) => {
  const { latestGuardianMessage } = props;

  if (latestGuardianMessage) {
    if (latestGuardianMessage.content) {
      return <p className={styles.content}>{latestGuardianMessage.content}</p>;
    } else if (latestGuardianMessage.imageUrl) {
      return <p className={styles.content}>画像ファイル</p>;
    } else {
      return null;
    }
  } else {
    return <p className={styles.content}>まだメッセージがありません</p>;
  }
};

const handleClick = (props: Props) => () => {
  props.onClickThread();
};

const isRead = (props: Props): boolean => {
  const { latestGuardianMessage, lastReadAt } = props;

  if (!latestGuardianMessage || !lastReadAt) {
    return true;
  }

  return isBefore(new Date(latestGuardianMessage.sentAt), new Date(lastReadAt));
};

export default GuardianMessageThreadListItem;
