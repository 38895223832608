import { StudentStayAnalytics } from "../../domains/StudentAnalytics";
import { toTimeString } from "../../helpers/TimeHelper";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import StudentInterface from "../../interfaces/StudentInterface";
import { useFormikWithStudentStay } from "./useFormikWithStudentStay";
import { useUpdateStudentStay } from "./useStudentStayAnalyticsApi";

export type UseStudentStayFormProps = {
  sectionId: string;
  editingStay: StudentStayAnalytics | null;
  closeModal: () => void;
  stayId: string;
  student: StudentInterface;
};

export const useStudentStayForm = ({
  sectionId,
  editingStay,
  closeModal,
  stayId,
  student,
}: UseStudentStayFormProps) => {
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();

  const onSuccess = () => {
    showSuccessMessage("入退室記録を更新しました");
    closeModal();
  };
  const onError = () => {
    showErrorMessage("入退室記録を更新できませんでした");
  };
  const { mutate, isPending: saving } = useUpdateStudentStay({
    sectionId,
    stayId,
    student,
    onSuccess,
    onError,
  });
  const studentStayFormik = useFormikWithStudentStay({
    initialValues: {
      date: editingStay?.enteredAt ?? new Date(),
      enteredAt: toTimeString(editingStay?.enteredAt ?? new Date()),
      exitedAt: toTimeString(editingStay?.exitedAt ?? new Date()),
    },
    onSubmit(values) {
      mutate({
        entered_at: values.entered_at,
        exited_at: values.exited_at,
      });
    },
  });

  const enteredDateProps = {
    onDateChange: (m: Date) => studentStayFormik.setFieldValue("date", m),
    onBlur: studentStayFormik.handleBlur,
    value: studentStayFormik.values.date,
  };

  const hasErrorWithEnteredAt = Boolean(
    studentStayFormik.touched.enteredAt && studentStayFormik.errors.enteredAt,
  );

  const enteredAtProps = {
    name: "enteredAt",
    onChange: studentStayFormik.handleChange,
    onBlur: studentStayFormik.handleBlur,
    value: studentStayFormik.values.enteredAt,
    hasError: hasErrorWithEnteredAt,
  };
  const enteredAtErrorText = studentStayFormik.errors.enteredAt;

  const enteredAtErrorProps = {
    hidden: !hasErrorWithEnteredAt,
  };

  const hasErrorWithExitedAt = Boolean(
    studentStayFormik.touched.exitedAt && studentStayFormik.errors.exitedAt,
  );

  const exitedAtProps = {
    name: "exitedAt",
    onChange: studentStayFormik.handleChange,
    onBlur: studentStayFormik.handleBlur,
    value: studentStayFormik.values.exitedAt,
    hasError: hasErrorWithExitedAt,
  };
  const exitedAtErrorText = studentStayFormik.errors.exitedAt;

  const exitedAtErrorProps = {
    hidden: !hasErrorWithExitedAt,
  };

  const buttonProps = {
    isLoading: saving,
    disabled: saving || hasErrorWithEnteredAt || hasErrorWithExitedAt,
  };

  const formProps = {
    onSubmit: studentStayFormik.handleSubmit,
  };

  return {
    enteredDateProps,
    enteredAtProps,
    enteredAtErrorProps,
    exitedAtProps,
    exitedAtErrorProps,
    buttonProps,
    formProps,
    exitedAtErrorText,
    enteredAtErrorText,
  };
};
