import * as React from "react";
import { Link } from "react-router-dom";
import Loader from "../../components/atoms/Loader";
import { SectionAnalyticsTypeTab } from "../../components/features/SectionAnalyticsTypeTab";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import enhanceSectionPage from "../../hocs/enhanceSectionsPage";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import SectionInterface from "../../interfaces/SectionInterface";
import { ExaminationFilter } from "./ExaminationFilter";
import { ExaminationTable } from "./ExaminationTable";
import { useFetchSectionExaminations } from "./useFetchSectionExaminations";

type Props = OutletContextProps & { section: SectionInterface };

const SectionsAnalyticsExaminationPage_ = ({
  section,
  setActiveMenu,
}: Props) => {
  const {
    examinations,
    isLoading,
    isFetchingNextPage,
    onChangeFilter,
    filterValues,
  } = useSectionsAnalyticsExaminationPage({ section, setActiveMenu });

  return (
    <>
      <SectionAnalyticsTypeTab
        sectionId={section.id}
        analyticsType="examination"
      />
      <div className="mt-5 flex items-center justify-between">
        <ExaminationFilter
          onChange={onChangeFilter}
          values={filterValues}
          sectionId={section.id}
        />
        <Link
          to={`/sections/${section.id}/settings/examination_result_insert`}
          className="buiButtonBase buiButtonVariantFilled"
        >
          CSVで一括登録
        </Link>
      </div>
      <div className="mt-7">
        {examinations && <ExaminationTable examinations={examinations} />}
      </div>
      <Loader loading={isLoading || isFetchingNextPage} />
    </>
  );
};

type Params = {
  organizerId: string | null;
  classificationId: string | null;
};
const useSectionsAnalyticsExaminationPage = ({
  setActiveMenu,
  section,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("analytics");
  }, []);

  const { filterValues, onChangeFilter } = useExaminationFilter();
  const {
    data: examinations,
    error,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useFetchSectionExaminations({
    sectionId: section.id,
    organizerId: filterValues.organizerId,
    classificationId: filterValues.classificationId,
  });

  useQueryError(error);
  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  return {
    examinations,
    isLoading,
    isFetchingNextPage,
    onChangeFilter,
    filterValues,
  };
};

const useExaminationFilter = () => {
  const [filterValues, setFilterValues] = React.useState<Params>({
    organizerId: null,
    classificationId: null,
  });

  const onChangeFilter = (values: {
    classificationId?: string | null;
    organizerId?: string | null;
  }) => {
    setFilterValues({ ...filterValues, ...values });
  };

  return {
    onChangeFilter,
    filterValues,
  };
};

const pageInfo = {
  title: "アナリティクス",
};
export const SectionsAnalyticsExaminationPage = withRouter(
  enhanceSectionPage(SectionsAnalyticsExaminationPage_, pageInfo),
);
