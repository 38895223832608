import { boronClient } from "../../../api";
import { FSHTTPError } from "../../../errors";
import { useBoronMutation } from "../../../hooks/http/useBoronMutation";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";

export const useRelinkCourse = ({ sectionId }: { sectionId: string }) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { mutate, isPending } = useBoronMutation(
    async ({ courseId }: { courseId: string }) =>
      await boronClient.PATCH(
        "/api/v1/sections/{section_id}/google_classroom/courses/{google_classroom_course_hashid}",
        {
          params: {
            path: {
              section_id: sectionId,
              google_classroom_course_hashid: courseId,
            },
          },
        },
      ),
    {
      onSuccess: () => {
        showSuccessMessage("Google Classroomクラスの連携を再開しました");
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage(
            "該当のGoogle Classroomクラスに所属していないため再開できません",
          );
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    },
  );

  const requestRelinkCourse = ({ courseId }: { courseId: string }) => {
    mutate({ courseId: courseId });
  };

  return { requestRelinkCourse, isPending };
};
