import {
  Button,
  IconArrowLeft,
  IconArrowRight,
  IconExternalLink,
} from "@studyplus/boron-ui";
import DeviceHelper from "../../../helpers/DeviceHelper";
import { ExternalLink } from "../../atoms/ExternalLink";
import Loader from "../../atoms/Loader";
import { ImageMagnifier } from "../../molecules/ImageMagnifier/index";
import { ParsedFilesProps } from "./useAttachmentViewer";

export interface AttachmentViewerProps {
  parsedFiles: ParsedFilesProps[];
  pageNo: number;
  setPageNo: (pageNo: number) => void;
  availablePrev: boolean;
  availableNext: boolean;
  isParsing: boolean;
  maxPageNo: number;
}

export const AttachmentViewer = (props: AttachmentViewerProps) => {
  const { parsedFiles, pageNo, setPageNo, availablePrev, availableNext } =
    props;

  if (!parsedFiles.length || !parsedFiles[pageNo] || props.isParsing) {
    return <Loader loading={props.isParsing} />;
  }

  return (
    <div>
      <div className="flex h-[40rem] items-center justify-center">
        {DeviceHelper.isPC() ? (
          parsedFiles[pageNo].cachedImageUrl ? (
            <ImageMagnifier
              src={parsedFiles[pageNo].cachedImageUrl}
              width="auto"
              height="100%"
            />
          ) : (
            <Loader loading />
          )
        ) : parsedFiles[pageNo].cachedImageUrl ? (
          <img
            src={parsedFiles[pageNo].cachedImageUrl}
            className="h-auto max-h-full w-auto max-w-full"
          />
        ) : (
          <Loader loading />
        )}
      </div>
      <div className="mt-4 flex items-center justify-between bg-white p-4">
        <div className="mr-4">
          <span className="text-gray-darken-1 line-clamp-2 overflow-hidden whitespace-normal text-[13px]">
            {parsedFiles[pageNo].filename}
          </span>
        </div>
        <div className="flex items-center justify-end">
          <ExternalLink to={parsedFiles[pageNo].cachedImageUrl}>
            <Button
              variant="outline"
              className="buiButtonSizeXs border-gray-900 text-[13px] font-medium text-slate-700"
              type="button"
            >
              別タブで開く
              <IconExternalLink />
            </Button>
          </ExternalLink>
        </div>
      </div>
      {parsedFiles.length > 1 && (
        <div className="mt-4 flex items-center justify-center gap-10">
          <Button
            variant="outline"
            size="xs"
            className="border-gray-400 p-0 text-black hover:border-gray-400 hover:bg-blue-100 disabled:border-gray-400 disabled:text-gray-400"
            onClick={() => {
              if (availablePrev) {
                setPageNo(pageNo - 1);
              }
            }}
            type="button"
            disabled={!availablePrev}
          >
            <IconArrowLeft />
          </Button>
          {parsedFiles.map((_, index) => {
            if (index >= props.maxPageNo) return null;
            return (
              <div
                key={`attachment-viewer-image-button_${index}`}
                className={`my-1 cursor-pointer hover:text-blue-400 ${
                  pageNo === index ? "text-blue-400" : "text-black"
                }`}
                onClick={() => setPageNo(index)}
              >
                {index + 1}
              </div>
            );
          })}
          <Button
            variant="outline"
            size="xs"
            className="border-gray-400 p-0 text-black hover:border-gray-400 hover:bg-blue-100 disabled:border-gray-400 disabled:text-gray-400"
            onClick={() => {
              if (availableNext) {
                setPageNo(pageNo + 1);
              }
            }}
            type="button"
            disabled={!availableNext}
          >
            <IconArrowRight />
          </Button>
        </div>
      )}
    </div>
  );
};
