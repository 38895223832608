import classNames from "classnames";
import styles from "./styles.scss";

interface Props {
  value: number;
  variant?: "outline" | "solid";
  className?: string;
}

export const ProgressBar = ({
  value,
  variant = "solid",
  className: parentClassName,
}: Props) => {
  return (
    <div
      className={classNames(styles.track, {
        [styles["track--solid"]]: variant === "solid",
        [styles["track--outline"]]: variant === "outline",
      })}
    >
      <div
        style={{ width: `${value}%` }}
        className={classNames(styles.fillTrack, parentClassName, {})}
      ></div>
    </div>
  );
};
