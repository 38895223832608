import * as React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "../../../components/atoms/Accordion";
import { DescriptionText } from "../../../components/atoms/DescriptionText";
import Icon from "../../../components/atoms/Icon";
import { ContentTypes } from "../../../domains/Content";
import { ContentUnit } from "../../../domains/ContentUnit";
import styles from "./Units.scss";

type UnitsProps = {
  hidden: boolean;
};
export const Units: React.FC<React.PropsWithChildren<UnitsProps>> = ({
  children,
  hidden,
}) => {
  return hidden ? null : <>{children}</>;
};

type Props = {
  units: readonly ContentUnit[]; // このPRではデータ構造はダミーです
};
export const UnitsAccordion = (props: Props) => {
  if (props.units.length === 0) {
    return null;
  }

  return (
    <Accordion>
      {props.units.map((el, i) => (
        <AccordionItem key={`accoroion-item-${i}`}>
          <AccordionButton>
            <div className={styles.button}>
              <div className={styles.button__number}>{i + 1}</div>
              <div className={styles.button__name}>{el.name}</div>
            </div>
          </AccordionButton>
          <AccordionPanel>
            <div className={styles.panel}>
              <div>
                <DescriptionText>{el.description}</DescriptionText>
              </div>
              <div className={styles.panel__contents}>
                {el.contents.map((content, k) => (
                  <div
                    className={styles.contents__element}
                    key={`accordion-content-${i}-${k}`}
                  >
                    <div className={styles.panel__icon}>
                      <ContentIcon contentType={content.contentType} />
                    </div>
                    <div className={styles.panel__name}>
                      {content.url ? (
                        <a
                          className={styles["panel__name--black"]}
                          href={content.url}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {content.name}
                        </a>
                      ) : (
                        content.name
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

type ContentIconProps = {
  contentType: ContentTypes;
};
const ContentIcon = ({ contentType }: ContentIconProps) => (
  <Icon
    name={contentType === "ContentVideo" ? "icon-video" : "icon-textbook"}
  />
);
