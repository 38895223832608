import SplitLayout from "../../../components/atoms/SplitLayout";
import DirectMessageForm from "../../../components/features/DirectMessageForm";
import { StudentMessageHeader } from "../../../components/features/StudentMessageHeader";
import { StudentMessageList } from "../../../components/features/StudentMessageList";
import OperatorInterface from "../../../interfaces/OperatorInterface.ts";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface.ts";
import StudentInterface from "../../../interfaces/StudentInterface.ts";
import styles from "./styles.scss";

interface Props {
  student: StudentInterface;
  isFollowing: boolean;
  operator: OperatorInterface;
  operatorProfile: OperatorProfileInterface | null;
}

const StudentMessage = (props: Props) => {
  return (
    <div className={styles.root}>
      <SplitLayout.Header>
        <StudentMessageHeader
          student={props.student}
          isFollowing={props.isFollowing}
          showCloseIcon={false}
        />
      </SplitLayout.Header>
      <SplitLayout.Body>
        <StudentMessageList
          sectionId={props.student.section.id}
          student={props.student}
        />
        <DirectMessageForm
          operator={props.operator}
          operatorProfile={props.operatorProfile}
          sectionId={props.student.section.id}
          student={props.student}
        />
      </SplitLayout.Body>
    </div>
  );
};

export default StudentMessage;
