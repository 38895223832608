import { useNavigate } from "react-router";
import { Teacher } from "../../../../domains/Teacher";
import { useRefreshActiveTeachers } from "../../../../hooks/http/useFetchTeachers";
import { URLActionTypes } from "../../../../hooks/useDetermineURLAction";
import { determineActionLabel } from "../../../../hooks/useDetermineURLAction";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { useSaveTeacher } from "../useTeachersApi";
import { useFormikWithTeacher } from "./useFormikWithTeacher";

export type UseTeacherFormProps = {
  sectionId: string;
  urlActionType: URLActionTypes;
  editingTeacher: Teacher | undefined;
  closeModal?: () => void;
};

export const useTeacherForm = ({
  sectionId,
  urlActionType,
  editingTeacher,
  closeModal,
}: UseTeacherFormProps) => {
  const navigate = useNavigate();
  const refreshActiveTeachers = useRefreshActiveTeachers({ sectionId });
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();

  const actionLabel = determineActionLabel(urlActionType);

  const { mutate, isPending: saving } = useSaveTeacher({
    sectionId,
    onError() {
      refreshActiveTeachers();
      showErrorMessage(`講師情報の${actionLabel}に失敗しました`);
    },
    onSuccess() {
      showSuccessMessage(`講師情報の${actionLabel}に成功しました`);
      if (closeModal) {
        closeModal();
        refreshActiveTeachers();
      } else {
        navigate(`/sections/${sectionId}/settings/teachers`);
      }
    },
  });

  const teacherFormik = useFormikWithTeacher({
    initialValues: {
      firstName: editingTeacher ? editingTeacher.firstName : "",
      lastName: editingTeacher ? editingTeacher.lastName : "",
    },
    onSubmit(values) {
      mutate({
        id: editingTeacher ? editingTeacher.id : null,
        firstName: values.firstName,
        lastName: values.lastName,
      });
    },
  });

  const hasErrorWithFirstName = Boolean(
    teacherFormik.touched.firstName && teacherFormik.errors.firstName,
  );
  const firstNameProps = {
    name: "firstName",
    onChange: teacherFormik.handleChange,
    onBlur: teacherFormik.handleBlur,
    value: teacherFormik.values.firstName,
    hasError: hasErrorWithFirstName,
    placeholder: "名",
  };
  const firstNameErrorText = teacherFormik.errors.firstName;
  const firstNameErrorProps = {
    hidden: !hasErrorWithFirstName,
  };

  const hasErrorWithLastName = Boolean(
    teacherFormik.touched.lastName && teacherFormik.errors.lastName,
  );
  const lastNameProps = {
    name: "lastName",
    onChange: teacherFormik.handleChange,
    onBlur: teacherFormik.handleBlur,
    value: teacherFormik.values.lastName,
    hasError: hasErrorWithLastName,
    placeholder: "姓",
  };
  const lastNameErrorText = teacherFormik.errors.lastName;
  const lastNameErrorProps = {
    hidden: !hasErrorWithLastName,
  };

  const buttonProps = {
    isLoading: saving,
    disabled: saving || hasErrorWithFirstName || hasErrorWithLastName,
  };

  const formProps = {
    onSubmit: teacherFormik.handleSubmit,
  };

  return {
    firstNameProps,
    firstNameErrorProps,
    lastNameProps,
    lastNameErrorProps,
    buttonProps,
    formProps,
    firstNameErrorText,
    lastNameErrorText,
    actionLabel,
  };
};
