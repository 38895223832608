import * as React from "react";
import { useEffect } from "react";
import Loader from "../../../../components/atoms/Loader";
import GuardianMessageThreadListItem from "../../../../components/molecules/GuardianMessageThreadListItem";
import { GuardianAnnounceMessageTagInterface } from "../../../../interfaces/GuardianAnnounceMessageTagInterface";
import SectionInterface from "../../../../interfaces/SectionInterface";
import { useFetchMessageThreads } from "../useFetchMessageThreads";
import styles from "./styles.scss";

type Props = {
  section: SectionInterface;
  openedThread: GuardianAnnounceMessageTagInterface | null;
  onClickThread: (threadId: string) => void;
};

const SectionGuardianAnnounceMessageThreadList = ({
  section,
  openedThread,
  onClickThread,
}: Props) => {
  const { data, isLoading, isFetchingNextPage, fetchNextPage } =
    useFetchMessageThreads({ sectionId: section.id });

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (isFetchingNextPage) {
      return;
    }

    const scrollBottom =
      e.currentTarget.scrollTop + e.currentTarget.offsetHeight;
    if (scrollBottom + 100 >= e.currentTarget.scrollHeight) {
      fetchNextPage();
    }
  };

  const threads = data?.pages.flatMap((page) => page.data) || [];

  // 初回ロード時に、指定されたスレッド、もしくは最初のスレッドを開く
  useEffect(() => {
    const thread = openedThread ?? threads[0];
    if (!isLoading && thread) {
      onClickThread(thread.id);
    }
  }, [isLoading]);

  if (isLoading) {
    return <Loader loading />;
  }

  return (
    <div className={styles.root} onScroll={handleScroll}>
      {threads.map((messageThread) => (
        <GuardianMessageThreadListItem
          key={messageThread.id}
          link={`/sections/${section.id}/tags/${messageThread.id}/guardian_messages`}
          threadName={messageThread.name}
          latestGuardianMessage={messageThread.latestMessage}
          isActive={!!openedThread && openedThread.id === messageThread.id}
          onClickThread={() => onClickThread(messageThread.id)}
        />
      ))}
      {isFetchingNextPage && <Loader loading />}
    </div>
  );
};

export default SectionGuardianAnnounceMessageThreadList;
