import * as React from "react";
import { Link } from "react-router-dom";

import { truncate } from "../../../helpers/String";
import TimeHelper from "../../../helpers/TimeHelper";
import { MessageNotificationInterface } from "../../../interfaces/NotificationInterface";
import CircleIcon from "../../atoms/CircleIcon";
import HeaderNotificationItemRow from "../../atoms/HeaderNotificationItemRow";
import styles from "./styles.scss";

interface Props {
  message: MessageNotificationInterface;
}

const messagePath = (studentId: string): string => {
  return `/students/${studentId}/messages`;
};

const HeaderDirectMessageNotificationItem = (
  props: Props,
): React.ReactElement => {
  const { message } = props;

  return (
    <HeaderNotificationItemRow highlighted={!message.isRead} key={message.id}>
      <Link to={messagePath(message.studentId)} className={styles.root}>
        <div className={styles.left}>
          <CircleIcon
            iconProps={{ name: "icon-notice-user", iconClassName: styles.icon }}
            className={styles.icon}
          />
        </div>
        <div className={styles.right}>
          <div className={styles.name}>{message.studentFullName}</div>
          <div className={styles.datetime}>
            {TimeHelper.japaneseFormatFullDateTime(message.sentAt)}
          </div>
          <div className={styles.content}>{notice(message)}</div>
        </div>
      </Link>
    </HeaderNotificationItemRow>
  );
};

const notice = (message: MessageNotificationInterface): string => {
  if (message.content) {
    return truncate(message.content, 20);
  } else if (message.deletedAt) {
    return "このメッセージは削除されました";
  } else {
    return "画像ファイル";
  }
};

export default HeaderDirectMessageNotificationItem;
