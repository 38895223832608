import { useFormik } from "formik";
import * as yup from "yup";
import { Teacher, nameLength } from "../../../../domains/Teacher";

type TeacherValue = Omit<Teacher, "id" | "fullName">;

type Props = {
  initialValues: TeacherValue;
  onSubmit: (value: TeacherValue) => void;
};
export const useFormikWithTeacher = ({ initialValues, onSubmit }: Props) => {
  return useFormik<TeacherValue>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit(values) {
      onSubmit(validationSchema.cast(values) as TeacherValue);
    },
  });
};

const validationSchema = yup
  .object<Record<keyof TeacherValue, yup.AnyObjectSchema>>()
  .shape({
    firstName: yup
      .string()
      .trim()
      .required("名を入力してください")
      .max(nameLength, `名は${nameLength}以内で入力してください`),
    lastName: yup
      .string()
      .trim()
      .required("姓を入力してください")
      .max(nameLength, `姓は${nameLength}以内で入力してください`),
  });
