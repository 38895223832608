import SectionInterface from "../interfaces/SectionInterface";
import StudentInterface from "../interfaces/StudentInterface";
import { Lecture } from "./Lecture";
import { LectureAnswer } from "./LectureAnswer";
import { Schedule } from "./Schedule";
import { Student } from "./Student";

export const AttandanceStatus = {
  none: "none",
  presence: "presence",
  absence: "absence",
  cancel: "cancel",
};
export type AttandanceStatus = keyof typeof AttandanceStatus;

export const AbsenceReasonOptions = {
  bad_health: "bad_health",
  personal: "personal",
  family: "family",
  without_notice: "without_notice",
  auto: "auto",
  other: "other",
};
export type AbsenceReasonOptions = keyof typeof AbsenceReasonOptions;

export const AttendanceUpdaterTypes = {
  student: "student",
  operator: "operator",
  auto: "auto",
};
export type AttendanceUpdaterTypes = keyof typeof AttendanceUpdaterTypes;

export const LocationStatusTypes = {
  inside: "inside",
  outside: "outside",
  indeterminable: "indeterminable",
  unable_to_locate: "unable_to_locate",
};
export type LocationStatusTypes = keyof typeof LocationStatusTypes;

export type LectureAttendance = {
  id: string;
  student: Student;
  attendanceStatus: AttandanceStatus;
  absenceReason: AbsenceReasonOptions | null;
  absenceDescription: string | null;
  transferredLectureAttendanceStatus: AttandanceStatus | null;
  hasTransferredLectureAttendance: boolean;
  hasTransferOriginLectureAttendance: boolean;
  fromOtherSection: boolean;
  attendanceStatusUpdatedAt: Date | null;
  staffShareNote: string | null;
  attendanceUpdater: AttendanceUpdaterTypes | null;
  locationStatus: LocationStatusTypes | null;
};

// 校舎を持つ生徒型が必要
export type LectureAttendanceForSection = Omit<LectureAttendance, "student"> & {
  student: StudentInterface;
  lectureSessionId: string;
};

export const isNotAttendant = (attendance: LectureAttendance) =>
  attendance.attendanceStatus === "absence" ||
  attendance.attendanceStatus === "cancel";

export const isCanceledLecture = (attendance: LectureAttendance) =>
  attendance.attendanceStatus === "cancel";

export const reasonOptionLabels: Record<
  keyof typeof AbsenceReasonOptions,
  string
> = {
  bad_health: "体調不良",
  personal: "本人都合",
  family: "家庭都合",
  without_notice: "無断欠席",
  auto: "自動欠席",
  other: "その他",
};

// 出席情報にアンケート回答がぶらさがる型
export type LectureAttendanceWithLectureAnswers = {
  id: LectureAttendance["id"];
  student: Pick<Student, "id" | "fullName" | "jobHuman">;
  lectureAnswers: LectureAnswer[];
};

export type LectureAttendanceForTransfer = Pick<LectureAttendance, "id"> & {
  lectureSessionId: string;
  section: SectionInterface;
  lecture: Lecture;
  schedule: Schedule;
};

export type LectureTransfer = {
  student: StudentInterface;
  fromLectureAttendance: LectureAttendanceForTransfer;
  toLectureAttendance: LectureAttendanceForTransfer;
};

export type LectureAttendanceWithSchedule = LectureAttendanceForSection & {
  schedule: Pick<Schedule, "id" | "summary" | "startAt" | "endAt">;
};
