import isAfter from "date-fns/isAfter";
import * as React from "react";
import { useCallback } from "react";
import Loader from "../../components/atoms/Loader";
import PrintButton from "../../components/atoms/PrintButton";
import { StudentAnalyticsTypeTab } from "../../components/features/StudentAnalyticsTypeTab";
import { DateRangeNavigation } from "../../components/general/DateRangeNavigation";
import SwitchTabs from "../../components/molecules/SwitchTabs";
import { isPushableCalendarNext } from "../../helpers/AnalyticsHelper";
import { OutletContextProps } from "../../helpers/RouterHelper";
import enhanceStudentsPage, {
  StudentsPageProps,
} from "../../hocs/enhanceStudentsPage";
import {
  AnalyticsFilter,
  useAnalyticsFilter,
} from "../../hooks/filters/useAnalyticsFilter";
import { AnalyticsTermType } from "../../interfaces/AnalyticsTableInterface";
import FiltersInterface from "../../interfaces/FiltersInterface";
import StudentInterface from "../../interfaces/StudentInterface";
import { StayAnalyticsChart } from "./StayAnalyticsChart";
import { StayAnalyticsTable } from "./StayAnalyticsTable";
import styles from "./StudentsStayAnalyticsPage.scss";

type UseStudentsStayAnalyticsPageProps = {
  student: StudentInterface;
  analyticsFilter?: AnalyticsFilter;
};

// Main hooks
const useStudentsStayAnalyticsPage = ({
  student,
  analyticsFilter,
}: UseStudentsStayAnalyticsPageProps) => {
  const analyticsProps = React.useMemo(() => {
    if (analyticsFilter == null) return undefined;

    return {
      student,
      term: analyticsFilter.term,
      startDate: analyticsFilter.startDate,
      endDate: analyticsFilter.endDate,
      enabledRequest: analyticsFilter?.enabledRequest,
    };
  }, [
    student,
    analyticsFilter?.term,
    analyticsFilter?.startDate,
    analyticsFilter?.endDate,
  ]);

  const datePickerProps = React.useMemo(() => {
    if (analyticsFilter == null) return undefined;

    return {
      calendarDisabled: analyticsFilter.term !== "daily",
      canPushRightButton: isPushableCalendarNext(
        analyticsFilter.endDate,
        analyticsFilter.term,
      ),
    };
  }, [
    analyticsFilter?.term,
    analyticsFilter?.startDate,
    analyticsFilter?.endDate,
  ]);

  return {
    datePickerProps,
    analyticsProps,
  };
};

type Props = StudentsPageProps &
  OutletContextProps & {
    student: StudentInterface;
    filter: FiltersInterface;
    onTermChange: (term: AnalyticsTermType) => void;
    onDateFilterChange: (dateRange: {
      startDate: Date | null;
      endDate: Date | null;
    }) => void;
  };

// Main component
const StudentsStayAnalyticsPage_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("analytics");
  }, []);
  const student = props.student;
  const { analyticsFilter, changeDates, changeTerm } = useAnalyticsFilter({
    sectionId: student.section.id,
    withStudentFilter: false,
  });

  const { analyticsProps, datePickerProps } = useStudentsStayAnalyticsPage({
    student,
    analyticsFilter,
  });

  const handleDatesChange = useCallback(
    (dates: { startDate?: Date; endDate?: Date }) => {
      if (dates.startDate && dates.endDate) {
        changeDates({
          startDate: dates.startDate,
          endDate: dates.endDate,
        });
      }
    },
    [changeDates],
  );

  if (
    analyticsFilter == null ||
    analyticsProps == null ||
    datePickerProps == null
  ) {
    return <Loader loading />;
  }

  return (
    <>
      <div className={styles.TabWrapper}>
        <StudentAnalyticsTypeTab activeAnalyticsType="stay" student={student} />
      </div>
      <div className={styles.TableControl}>
        <div className={styles.TableControl__LeftArea} />
        <DateRangeNavigation
          startDate={analyticsFilter.startDate}
          endDate={analyticsFilter.endDate}
          term={analyticsFilter.term}
          onDatesChange={handleDatesChange}
          isDayBlocked={(day: Date) => isAfter(day, new Date())}
          {...datePickerProps}
        />
        <div className={styles.TableControl__RightArea}>
          <SwitchTabs
            tabs={[
              {
                id: "daily",
                label: "日",
                active: analyticsFilter.term === "daily",
              },
              {
                id: "weekly",
                label: "週",
                active: analyticsFilter.term === "weekly",
              },
              {
                id: "monthly",
                label: "月",
                active: analyticsFilter.term === "monthly",
              },
            ]}
            onClickTab={(tab: any) => () => {
              changeTerm(tab.id);
            }}
          />
          <PrintButton />
        </div>
      </div>
      <div className={styles.MainContentsWrapper}>
        <StayAnalyticsChart {...analyticsProps} />
        <div className={styles.TableWrapper}>
          <StayAnalyticsTable {...analyticsProps} />
        </div>
      </div>
    </>
  );
};

export const StudentsStayAnalyticsPage = enhanceStudentsPage(
  StudentsStayAnalyticsPage_ as any,
  {
    title: "アナリティクス",
  },
);
