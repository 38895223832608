import { Button } from "@studyplus/boron-ui";
import classNames from "classnames";
import { addDays, isBefore, parseISO } from "date-fns";
import nl2br from "react-nl2br";
import { Link } from "react-router-dom";
import ApiClient from "../../../../api";
import ContentBox from "../../../../components/atoms/ContentBox";
import { ImportHistoryInterface } from "../../../../domains/ImportHistory";
import { IconImportOperation } from "../../../../features/ImportHistory/IconImportOperation";
import TimeHelper from "../../../../helpers/TimeHelper";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import SectionInterface from "../../../../interfaces/SectionInterface";
import styles from "./styles.scss";

interface Props {
  section: SectionInterface;
  importHistory: ImportHistoryInterface;
  reloadImportHistory: () => void;
}

const renderResult = ({
  section,
  importHistory,
  reloadImportHistory,
}: Props): JSX.Element => {
  if (importHistory.status === "success") {
    return (
      <div className={classNames(styles.result, styles.successResult)}>
        <h3 className={styles.resultHeading}>
          {importHistory.operationHuman}に成功しました
          {importHistory.endedAt
            ? `（${TimeHelper.fullFormat(importHistory.endedAt)}）`
            : null}
        </h3>
        <SuccessFeedback section={section} importHistory={importHistory} />
      </div>
    );
  } else if (importHistory.status === "failure") {
    return (
      <div className={classNames(styles.result, styles.failureResult)}>
        <h3 className={styles.resultHeading}>
          {importHistory.operationHuman}に失敗しました
          {importHistory.endedAt
            ? `（${TimeHelper.fullFormat(importHistory.endedAt)}）`
            : null}
        </h3>
        <div>{nl2br(importHistory.feedback)}</div>
      </div>
    );
  } else {
    return (
      <div className={styles.result}>
        <h3 className={styles.resultHeading}>
          {importHistory.operationHuman}を処理中です。
        </h3>
        <div>
          処理が完了後、一括操作完了メールが送信されます。10秒ほどお待ちの上、以下の
          <strong>再読み込みボタン</strong>を押してください。
          <br />
          （件数が多い場合は完了までに2、3分掛かる場合がございます。）
        </div>
        <Button
          className={styles.reloadButton}
          disabled={false}
          variant="outline"
          size="sm"
          isRound={true}
          onClick={reloadImportHistory}
        >
          再読み込み
        </Button>
      </div>
    );
  }
};

const SuccessFeedback: React.FC<Pick<Props, "section" | "importHistory">> = ({
  section,
  importHistory,
}) => {
  switch (importHistory.operation) {
    case "student_insert":
    case "student_update":
    case "student_billing_plan_update":
      return (
        <div className={styles.successFeedback}>
          {importHistory.operation.endsWith("update") ? "更新" : "登録"}
          した生徒は、生徒一覧画面からご確認ください。
          <Link to={`/sections/${section.id}/settings/students`}>
            生徒一覧を見る
          </Link>
        </div>
      );
    case "tag_insert":
    case "tag_update":
      return (
        <div className={styles.successFeedback}>
          {importHistory.operation.endsWith("update") ? "更新" : "登録"}
          したタグは、タグ一覧画面からご確認ください。
          <Link to={`/sections/${section.id}/settings/tags`}>
            タグ一覧を見る
          </Link>
        </div>
      );
    case "learning_material_insert":
    case "learning_material_update":
      return (
        <div className={styles.successFeedback}>
          {importHistory.operation.endsWith("update") ? "更新" : "登録"}
          した校舎独自教材は、校舎独自教材一覧画面からご確認ください。
          <Link
            to={`/sections/${section.id}/settings/section_learning_materials`}
          >
            校舎独自教材一覧を見る
          </Link>
        </div>
      );
    case "drill_learning_material_insert":
      return (
        <div className={styles.successFeedback}>
          登録したデジタル教材は、デジタル教材一覧画面からご確認ください。
          <Link
            to={`/sections/${section.id}/settings/digital_learning_materials`}
          >
            デジタル教材一覧を見る
          </Link>
        </div>
      );
    case "student_tag_insert":
    case "student_tag_delete":
    case "bookshelf_insert":
    case "examination_result_insert":
      return null;
    case "entry_guides_for_students":
    case "entry_guides_for_guardians":
      return (
        <div className={styles.successFeedback}>
          作成された配布資料は、以下からダウンロードしてください。
          <span className="text-gray-700">
            （ダウンロードに数秒かかることがあります）
          </span>
          <div className="mt-8">※資料の保存期間は1週間です。</div>
          <DownloadButtons section={section} importHistory={importHistory} />
        </div>
      );
    default: {
      const unexpectedOperation: string | undefined = importHistory.operation;
      throw new Error(
        `An unexpected operation happened: ${unexpectedOperation}`,
      );
    }
  }
};

const DownloadButtons: React.FC<Pick<Props, "section" | "importHistory">> = ({
  section,
  importHistory,
}) => {
  const { showErrorMessage } = useFlashMessage();

  if (!importHistory.endedAt) throw new Error("データの取得に失敗しました");

  const handleClickDownloadButton =
    (fileName: string, pathWithoutFileName: string) => () => {
      ApiClient.get(pathWithoutFileName + encodeURI(fileName))
        .then((res) => res.blob())
        .then((blob) => {
          const a = document.createElement("a");
          const blobUrl = window.URL.createObjectURL(
            new Blob([blob], {
              type: blob.type,
            }),
          );
          a.style.display = "none";
          a.href = blobUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          console.error(error);
          showErrorMessage("ダウンロードできませんでした");
        });
    };

  if (isBefore(addDays(parseISO(importHistory.endedAt), 7), new Date())) {
    return (
      <div className="mt-8 text-darkred-500">
        保存期間を過ぎたため、資料の配布を停止しました。
      </div>
    );
  }

  if (importHistory.operation === "entry_guides_for_students") {
    return (
      <div className="mt-8">
        <div>
          <Button
            key={`download-button-for-studyplus`}
            className={styles.downloadButton}
            variant="outline"
            size="sm"
            isRound={true}
            onClick={handleClickDownloadButton(
              `Studyplus生徒配布資料（${importHistory.allTagName}）.pdf`,
              `/api/v1/sections/${section.id}/import_histories_for_entry_guides/${importHistory.id}/download/`,
            )}
          >
            {`Studyplus生徒配布資料（${importHistory.allTagName}）`}
          </Button>
        </div>

        <Button
          key={`download-button-for-study-log`}
          className={styles.downloadButton}
          variant="outline"
          size="sm"
          isRound={true}
          onClick={handleClickDownloadButton(
            `Studylog生徒配布資料（${importHistory.allTagName}）.pdf`,
            `/api/v1/sections/${section.id}/import_histories_for_entry_guides/${importHistory.id}/download/`,
          )}
        >
          {`Studylog生徒配布資料（${importHistory.allTagName}）`}
        </Button>
      </div>
    );
  } else {
    return (
      <div className="mt-8">
        <div>
          <Button
            key={`download-button-for-studyplus`}
            className={styles.downloadButton}
            variant="outline"
            size="sm"
            isRound={true}
            onClick={handleClickDownloadButton(
              `保護者配布資料（${importHistory.allTagName}）.pdf`,
              `/api/v1/sections/${section.id}/import_histories_for_entry_guides/${importHistory.id}/download/`,
            )}
          >
            {`保護者配布資料（${importHistory.allTagName}）`}
          </Button>
        </div>
      </div>
    );
  }
};

const ImportHistory = (props: Props): JSX.Element => {
  return (
    <ContentBox className={styles.root}>
      <h2 className={styles.heading}>
        <IconImportOperation
          className="mr-6 h-[3rem] w-[3rem] text-gray-600"
          operation={props.importHistory.operation}
        />
        {props.importHistory.operationHuman}
      </h2>
      {renderResult(props)}
      <div className={styles.buttonContainer}>
        <Link to={`/sections/${props.section.id}/settings/import_histories`}>
          <Button disabled={false}>操作一覧に戻る</Button>
        </Link>
      </div>
    </ContentBox>
  );
};

export default ImportHistory;
