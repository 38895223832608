import classnames from "classnames";
import * as React from "react";
import Checkbox from "../../components/atoms/Checkbox";
import Icon from "../../components/atoms/Icon";
import LinkButton from "../../components/atoms/LinkButton";
import {
  MixedCheckValue,
  MixedCheckbox,
} from "../../components/general/MixedCheckbox";
import { resolveColorStyles } from "../../helpers/styles/Colors";
import { useBoolean } from "../../hooks/useBoolean";
import styles from "./SearchConditionSelector.scss";

type ResourceBase = {
  name: string;
  id: string;
};

type UseArrayProps<T> = {
  initialValues: ReadonlyArray<T>;
};
const useArray = <T,>({ initialValues }: UseArrayProps<T>) => {
  const [values, setValues] = React.useState<ReadonlyArray<T>>(initialValues);

  const add = (items: T | ReadonlyArray<T>) => {
    const newValues = Array.isArray(items) ? items : [items];
    setValues((previous) => [...previous, ...newValues]);
  };
  const reset = () => {
    setValues([]);
  };

  const remove = (item: T) => {
    setValues((previous) =>
      previous.filter((selectedId) => selectedId !== item),
    );
  };
  return {
    values,
    add,
    reset,
    remove,
  };
};

// Main hooks
type UseSearchConditionSelectorProps = {
  initialSelectedIds: ReadonlyArray<string>;
  resourceOptions: ReadonlyArray<ResourceBase>;
  onChange: (selectedIds: ReadonlyArray<string>) => void;
};
export const useSearchConditionSelector = ({
  initialSelectedIds,
  resourceOptions,
  onChange,
}: UseSearchConditionSelectorProps) => {
  const selectedItems = useArray({ initialValues: initialSelectedIds });
  const disclosure = useBoolean({ initialValue: true });

  React.useEffect(() => {
    onChange(selectedItems.values);
  }, [selectedItems.values]);

  const conditionSet = new Set(selectedItems.values);
  const allSelectedCheckboxProps = {
    value: determineMixedCheckValue(conditionSet.size, resourceOptions.length),
    onChanged: (nextValue: boolean) => {
      nextValue
        ? selectedItems.add(resourceOptions.map((item) => item.id))
        : selectedItems.reset();
    },
  };

  const makeOptionsProps = (id: string) => {
    const selected = conditionSet.has(id);
    return {
      selected,
      onChange: (nextSelected: boolean) =>
        nextSelected ? selectedItems.add(id) : selectedItems.remove(id),
    };
  };

  const disclosureButtonProps = {
    onClick: () => disclosure.toggle(),
  };

  return {
    isEmptyOptions: resourceOptions.length === 0,
    allSelectedCheckboxProps,
    makeOptionsProps,
    disclosureButtonProps,
    shouldHideOptions: !disclosure.value,
    toggleIconName: disclosure.value ? "icon-arrow-down" : "icon-arrow-up",
  };
};

const determineMixedCheckValue = (
  numOfSelected: number,
  numOfAllOptions: number,
): MixedCheckValue => {
  if (numOfAllOptions === numOfSelected) {
    return true;
  }
  if (numOfSelected === 0) {
    return false;
  }
  return "indeterminate";
};

// Main Component
type Props = {
  keyId: string;
  titleView: React.ReactElement;
  linkViewToAddRecord: React.ReactElement;
} & UseSearchConditionSelectorProps;

const SearchConditionSelector_ = ({
  titleView,
  linkViewToAddRecord,
  keyId,
  ...props
}: Props) => {
  const {
    makeOptionsProps,
    allSelectedCheckboxProps,
    disclosureButtonProps,
    shouldHideOptions,
    toggleIconName,
    isEmptyOptions,
  } = useSearchConditionSelector(props);
  return (
    <div>
      <div
        className={classnames(
          resolveColorStyles({ color: "primary" }),
          styles.header,
        )}
      >
        {titleView}
        <div
          className={classnames(
            styles.flexSplitter,
            styles.linkButtonWrapper,
            styles.addButton,
          )}
        >
          {linkViewToAddRecord}
        </div>
        <div className={styles.linkButtonWrapper}>
          {!isEmptyOptions && (
            <LinkButton color="gray-darken-1" {...disclosureButtonProps}>
              <Icon name={toggleIconName} />
            </LinkButton>
          )}
        </div>
      </div>
      <ResourceOptionList hidden={shouldHideOptions}>
        {!isEmptyOptions && (
          <li>
            <MixedCheckbox id={`all-${keyId}`} {...allSelectedCheckboxProps}>
              <span className={styles.resourceOptionName}>すべて</span>
            </MixedCheckbox>
          </li>
        )}
        {props.resourceOptions.map((option, i) => {
          const id = `resource-option-${keyId}-${i}`;
          return (
            <ResourceOption
              key={id}
              id={id}
              option={option}
              {...makeOptionsProps(option.id)}
            />
          );
        })}
      </ResourceOptionList>
    </div>
  );
};

export const SearchConditionSelector = React.memo(SearchConditionSelector_);

export const ClassroomTitlteView = () => {
  return (
    <div className={styles.titleViewContainer}>
      <Icon name="icon-door" />
      <div className={styles.title}>教室</div>
    </div>
  );
};

export const TeacherTitleView = () => {
  return (
    <div className={styles.titleViewContainer}>
      <Icon name="icon-school-mini" />
      <div className={styles.title}>講師</div>
    </div>
  );
};

type ResourceOptionListProps = {
  hidden: boolean;
};
const ResourceOptionList = ({
  hidden,
  children,
}: React.PropsWithChildren<ResourceOptionListProps>) => {
  if (hidden) {
    return null;
  }
  return (
    <div className={styles.resourceOptionListContainer}>
      <ul>{children}</ul>
    </div>
  );
};

type ResourceOptionProps = {
  id: string;
  option: ResourceBase;
  selected: boolean;
  onChange: (selected: boolean) => void;
};
const ResourceOption = ({
  option,
  id,
  selected,
  onChange,
}: ResourceOptionProps) => {
  return (
    <li className={styles.resourceOption}>
      <Checkbox
        id={`resource-option-${option.id}-${id}`}
        onChange={() => {
          onChange(!selected);
        }}
        name={`resource-option-${option.id}-${id}`}
        checked={selected}
        isBlock
      >
        <span className={styles.resourceOptionName}>{option.name}</span>
      </Checkbox>
    </li>
  );
};
