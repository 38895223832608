import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import { SectionSetting } from "../../domains/SectionSetting";
import { HTTPErrors, createError } from "../../errors";

type UseFetchClassroomProps = {
  sectionId: string;
};

export const useFetchSectionSettings = ({
  sectionId,
}: UseFetchClassroomProps) => {
  return useQuery<SectionSetting, HTTPErrors>({
    queryKey: cacheKeyOf(sectionId),
    queryFn: async () => {
      const response = await api.get(`/api/v1/sections/${sectionId}/settings`);
      if (response.ok) {
        const json = await response.json();
        return json.sectionSetting as SectionSetting;
      }
      throw await createError(response);
    },
  });
};

export const cacheKeyOf = (sectionId: string) => [sectionId, "settings"];
