import {
  Arrow,
  Content,
  PopoverContentProps,
  PopoverProps,
  PopoverTriggerProps,
  Portal,
  Root,
  Trigger,
} from "@radix-ui/react-popover";
import classNames from "classnames";
import * as React from "react";
import styles from "./Popover.styles.scss";

export const PopoverContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  PopoverContentProps
>(({ children, className, ...props }, ref) => {
  return (
    <Content
      className={classNames(
        styles.PopoverContent,
        className,
        "rounded-sm bg-black px-4 py-3 text-sm text-white",
      )}
      ref={ref}
      {...props}
      onOpenAutoFocus={(e: Event) => {
        // デフォルトの挙動だとContent要素に自動でフォーカスがあたるが、
        // FSだとツールチップ的な使い方をすることが多いためこの挙動を無効化している
        e.preventDefault();
      }}
    >
      {children}
      <Arrow className="fill-black" />
    </Content>
  );
});

// FSの要件に合わせて開閉状態の制御を上書きするため、開閉状態を管理するContextを定義
// 下層コンポーネントからusePopover()というhooksで参照できるようにしている
type PopoverContextValue = { open: boolean; setOpen: (open: boolean) => void };
const PopoverContext = React.createContext<PopoverContextValue | undefined>(
  undefined,
);
export const PopoverProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <PopoverContext.Provider value={{ open, setOpen }}>
      {children}
    </PopoverContext.Provider>
  );
};
export const usePopover = () => {
  const result = React.useContext(PopoverContext);
  if (!result) {
    throw new Error("Wrap your element with <PopoverProvider> to use Popover");
  }
  return result;
};

export const PopoverTrigger = ({ children, ...props }: PopoverTriggerProps) => {
  const { setOpen } = usePopover();

  return (
    // Popoverはそのままだとhover時にコンテンツを表示しないが、
    // FSではツールチップのような使い方も兼ねているため、hover(onMouseEnter)でも発火するようにしている
    <Trigger
      {...props}
      onClick={() => {
        setOpen(true);
      }}
      onMouseEnter={() => {
        setOpen(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}
    >
      {children}
    </Trigger>
  );
};

export const Popover = ({ children, ...props }: PopoverProps) => {
  const { open } = usePopover();

  return (
    <Root {...props} open={open}>
      {children}
    </Root>
  );
};

// NOTE: 以下は現状@radix-uiそのままだが、今後挙動を調整したりスタイルを変えたいことがあるかもしれないので
// general/Popoverのコンポーネントとして公開している
export const PopoverPortal = Portal;
