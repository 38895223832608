import * as React from "react";
import SectionsSettingsKarteTemplatesNew from "../../components/organisms/SectionsSettingsKarteTemplatesNew";
import { OutletContextProps } from "../../helpers/RouterHelper";
import enhanceSectionsSettingsPage from "../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../interfaces/SectionInterface";

interface Props extends OutletContextProps {
  section: SectionInterface;
}

class SectionsSettingsKarteTemplatesNewPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    props.setActiveMenu("template");
  }
  render() {
    return <SectionsSettingsKarteTemplatesNew section={this.props.section} />;
  }
}

const pageInfo = {
  title: "テンプレート",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsKarteTemplatesNewPage,
  pageInfo,
);

export default EnhancedPage;
