import { Button } from "@studyplus/boron-ui";
import TimeHelper from "../../../helpers/TimeHelper";
import { InvitingOperatorInterface } from "../../../interfaces/SectionOperatorsInterface";
import styles from "./styles.scss";

interface Props {
  sectionId: string;
  sectionName: string;
  invitingOperators: InvitingOperatorInterface[];
  deleteInvite: (sectionId: string, inviteId: string) => void;
}

const InvitingOperatorList = (props: Props) => {
  if (!props.invitingOperators || props.invitingOperators.length == 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.label}>招待中スタッフ</div>
      {renderTable(props)}
    </div>
  );
};

const renderTable = (props: Props) => {
  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            <th>送信日時</th>
            <th>メールアドレス</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderList(props)}</tbody>
      </table>
    </div>
  );
};

const renderList = (props: Props) => {
  return props.invitingOperators.map((invite: InvitingOperatorInterface) => {
    return (
      <tr key={`operator-${invite.id}`} className={styles.tableRow}>
        <td className={styles.tableCol}>
          {TimeHelper.fullFormat(invite.sentAt)}
        </td>
        <td className={styles.tableCol}>{invite.email}</td>
        <td className={styles.tableLastCol}>
          <Button
            className={styles.btn}
            onClick={handleClick(props, invite)}
            disabled={invite.submitting}
            variant="outline"
          >
            招待をキャンセル
          </Button>
        </td>
      </tr>
    );
  });
};
const handleClick = (props: Props, invite: InvitingOperatorInterface) => () => {
  if (invite.submitting) return;

  if (
    window.confirm(
      `本当に「${invite.email}」への${props.sectionName}に対する招待をやめてよろしいですか？`,
    )
  ) {
    props.deleteInvite(props.sectionId, invite.id);
  }
};

export default InvitingOperatorList;
