import { IconOptionMenu } from "@studyplus/boron-ui";
import classNames from "classnames";
import styles from "./styles.scss";

interface Props {
  amount: number;
  unit: string;
  startPosition?: number;
  endPosition?: number;
  size?: string;
  isRestrictions?: boolean;
  dataTestId?: string;
}

const rootClassName = (size?: string): string => {
  return classNames(styles.root, {
    [styles.small]: size === "small",
  });
};

const renderRange = (props: Props) => {
  const { startPosition, endPosition, unit } = props;

  if (
    startPosition !== undefined &&
    startPosition !== null &&
    endPosition !== undefined &&
    endPosition !== null
  ) {
    return (
      <span>
        （{`${startPosition}〜${endPosition}`}
        {unit}）
      </span>
    );
  } else {
    return null;
  }
};

const Amount = (props: Props) => {
  const { isRestrictions = false } = props;
  if (isRestrictions) {
    return (
      <div className={rootClassName(props.size)}>
        <IconOptionMenu className="mx-1 align-middle text-gray-800" />
        {props.unit}
      </div>
    );
  }
  return (
    <div className={rootClassName(props.size)} data-testid={props.dataTestId}>
      <span className={styles.number}>{props.amount}</span>
      {props.unit}
      {renderRange(props)}
    </div>
  );
};

export default Amount;
