import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import { Flex } from "../../../../components/atoms/Flex";
import { Modal } from "../../../../components/atoms/Modal";
import TagButton from "../../../../components/features/TagButton";
import { Student } from "../../../../domains/Student";
import StudentTag from "../../../../domains/StudentTag";
import TagEntity from "../../../../entities/TagEntity";
import {
  UseFetchStudentsOptions,
  useFetchSectionStudents,
} from "../../../../hooks/http/useFetchSectionStudents";
import { StudentModalState } from "./StudentModalState";
import styles from "./StudentTagModal.scss";
import { StudentTagSelector } from "./StudentTagSelector";

const useFetchTargetStudents = (
  sectionId: string,
  tag: StudentTag | undefined,
  isEntryGuidesForStudentsOperation: boolean,
): Student[] | undefined => {
  let queryParams: UseFetchStudentsOptions;
  if (tag) {
    const tagEntity = new TagEntity(tag);
    queryParams = tagEntity.toQueryParams() as UseFetchStudentsOptions;
  } else {
    queryParams = {};
  }

  if (isEntryGuidesForStudentsOperation) {
    queryParams.status = ["inviting"];
  }

  const { data, isLoading } = useFetchSectionStudents({
    sectionId,
    params: queryParams,
    shouldGetAllRecords: true,
    enabled: true,
  });

  if (isLoading) return undefined;

  if (isEntryGuidesForStudentsOperation) {
    return data as Student[];
  } else {
    const students = data as Student[];
    return students.filter(
      (student) => student.lineConnectionStatus === "waiting",
    );
  }
};

const studentsCount = (studentModalState: StudentModalState) => {
  if (!studentModalState.selectedTag) return undefined;
  return studentModalState.studentsByTagId[studentModalState.selectedTag.id]
    ?.length;
};

const isSelectableStudentsCount = (count: number | undefined) => {
  if (typeof count === "undefined") return false; // 未選択
  if (count === 0) {
    return false;
  } else if (count >= 100) {
    return false;
  } else {
    return true;
  }
};

const ErrorMessage = ({
  count,
  isForEntryGuidesForStudents,
}: {
  count: number;
  isForEntryGuidesForStudents: boolean;
}) => {
  const targetName = isForEntryGuidesForStudents
    ? "招待中生徒"
    : "LINEが未連携の保護者";
  if (count === 0) {
    return (
      <div className="mt-1 text-sm text-darkred-500">
        {targetName}
        がいないタグは選択できません。
      </div>
    );
  } else if (count >= 100) {
    return (
      <div className="mt-1 text-sm text-darkred-500">
        {targetName}
        が100人以上のタグは選択できません。
      </div>
    );
  } else {
    return <React.Fragment />;
  }
};

type TagModalHandler = {
  studentModalState: StudentModalState;
  openModal: () => void;
  closeModal: () => void;
  addTag: (selectedTag: StudentTag) => void;
  removeTag: () => void;
  openStudentsList: (requestedTagToShowStudents: StudentTag) => void;
  closeStudentsList: () => void;
  addStudents: (tag: StudentTag, students: Student[]) => void;
};

export const useStudentTagModalHandler = (): TagModalHandler => {
  const [studentModalState, setStudentModalState] =
    React.useState<StudentModalState>({
      isOpenModal: false,
      studentsByTagId: {},
    });

  const openModal = () => {
    setStudentModalState((prevState) => ({
      ...prevState,
      isOpenModal: true,
      // タグを選択状態で開くならば、常にstudentListは開く
      isClosedSelectedStudentsList: !prevState.selectedTag ? undefined : false,
    }));
  };
  const closeModal = () => {
    setStudentModalState((prevState) => ({
      ...prevState,
      isOpenModal: false,
    }));
  };
  const addTag = (selectedTag: StudentTag) => {
    setStudentModalState((prevState) => ({
      ...prevState,
      selectedTag,
      isClosedSelectedStudentsList: false,
    }));
  };
  const removeTag = () => {
    setStudentModalState((prevState) => ({
      ...prevState,
      selectedTag: undefined,
      isClosedSelectedStudentsList: undefined,
    }));
  };
  const openStudentsList = (selectedTag: StudentTag) => {
    setStudentModalState((prevState) => ({
      ...prevState,
      selectedTag,
      isClosedSelectedStudentsList: false,
    }));
  };
  const closeStudentsList = () => {
    setStudentModalState((prevState) => ({
      ...prevState,
      isClosedSelectedStudentsList: true,
    }));
  };
  const addStudents = (tag: StudentTag, students: Student[]) => {
    setStudentModalState((prevState) => {
      prevState.studentsByTagId[tag.id] = students;
      return {
        ...prevState,
      };
    });
  };
  return {
    studentModalState,
    openModal,
    closeModal,
    addTag,
    removeTag,
    openStudentsList,
    closeStudentsList,
    addStudents,
  };
};

type Props = {
  sectionId: string;
  tags: StudentTag[];
  onSubmit: (selectedTag: StudentTag | undefined) => void;
  tagModalHandler: TagModalHandler;
  isForEntryGuidesForStudents: boolean;
};
export const StudentTagModal = ({
  sectionId,
  tags,
  onSubmit,
  tagModalHandler,
  isForEntryGuidesForStudents,
}: Props) => {
  const {
    studentModalState,
    closeModal,
    addTag,
    removeTag,
    openStudentsList,
    closeStudentsList,
    addStudents,
  } = tagModalHandler;

  const isOpenModal = tagModalHandler.studentModalState.isOpenModal;

  const students = useFetchTargetStudents(
    sectionId,
    studentModalState.selectedTag,
    isForEntryGuidesForStudents,
  );
  React.useEffect(() => {
    if (studentModalState.selectedTag && students) {
      addStudents(studentModalState.selectedTag, students);
    }
  }, [studentModalState.selectedTag?.id, students?.length]);

  const count = studentsCount(studentModalState);
  return (
    <Modal isOpen={isOpenModal} onRequestClose={closeModal}>
      <Modal.Header onClose={closeModal}>生徒選択</Modal.Header>
      <Modal.Body>
        {isSelectableStudentsCount(count) ? (
          <React.Fragment />
        ) : (
          <ErrorMessage
            count={count as number}
            isForEntryGuidesForStudents={isForEntryGuidesForStudents}
          />
        )}
        <h3 className={styles.heading}>
          作成数
          {count ? `（${count}名）` : ""}:
        </h3>

        <div className={styles.row}>
          <div className={styles.selectedTag}>
            {studentModalState.selectedTag &&
            isSelectableStudentsCount(count) ? (
              <TagButton
                label={studentModalState.selectedTag.name}
                iconName="icon-close-x"
                variant="outline"
                colorType={"primary"}
                onClick={removeTag}
              />
            ) : null}
          </div>
          <Button
            type="button"
            disabled={!isSelectableStudentsCount(count)}
            onClick={() => {
              onSubmit(studentModalState.selectedTag);
            }}
            className={styles.button}
          >
            決定
          </Button>
        </div>
        <Flex justifyContent="left" marginTop="1.2rem">
          <StudentTagSelector
            tags={tags}
            studentModalState={studentModalState}
            addTag={addTag}
            removeTag={removeTag}
            openStudentsList={openStudentsList}
            closeStudentsList={closeStudentsList}
            isForEntryGuidesForStudents={isForEntryGuidesForStudents}
          />
        </Flex>
      </Modal.Body>
    </Modal>
  );
};
