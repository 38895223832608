import { Link } from "react-router-dom";
import { Flex } from "../../components/atoms/Flex";
import Icon from "../../components/atoms/Icon";
import LinkButton from "../../components/atoms/LinkButton";
import {
  HorizontalScrollTable,
  TableColumn,
} from "../../components/atoms/Table";
import {
  CustomLearningMaterialTag,
  DefaultLearningMaterialTag,
} from "../../components/features/LearningMaterialTagLabel/LearningMaterialTagLabel";
import { LearningMaterialTagAssociation } from "../../components/features/LearningMaterialTagModal";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../components/general/Popover/Popover";
import { DigitalLearningMaterialList } from "../../domains/DigitalLearningMaterial";
import styles from "./LearningMaterialTable.scss";

type Props = {
  sectionId: string;
  digitalLearningMaterials: DigitalLearningMaterialList;
  openModal: (learningMaterial: LearningMaterialTagAssociation) => void;
  shouldShowTagUpdateIcon: boolean;
};
export const LearningMaterialTable = ({
  sectionId,
  digitalLearningMaterials,
  openModal,
  shouldShowTagUpdateIcon,
}: Props) => {
  if (digitalLearningMaterials.length === 0) {
    return null;
  }

  return (
    <HorizontalScrollTable
      headers={[
        { text: "教材名", centering: true, key: "learning-material-name" },
        {
          text: "生徒配信枠利用数",
          centering: true,
          key: "learning-material-usage-count",
        },
        { text: "教材タグ", centering: true, key: "learning-material-tags" },
        { text: "", centering: true, key: "config-learning-material-tags" },
      ]}
      rows={digitalLearningMaterials.map((item) => (
        <tr key={`management-learning-material-${item.learningMaterialCode}`}>
          <TableColumn className={styles.learningMaterialCell}>
            <Flex marginTop="0rem" alignItems="center">
              <img className={styles.learningMaterialImg} src={item.imageUrl} />
              <div className="ml-9">
                <span>{item.name}</span>
                <div
                  className="mt-2"
                  aria-label={`${item.name}のドリル教材提供元事業者`}
                >
                  <span className="text-md text-gray-800">
                    {item.creatorName}
                  </span>
                </div>
                <div className="mt-4">
                  <StudyProgressLink
                    sectionId={sectionId}
                    learningMaterialCode={item.learningMaterialCode}
                  />
                </div>
              </div>
            </Flex>
          </TableColumn>
          <TableColumn className={styles.learningMaterialCell}>
            <Flex justifyContent="center">
              {item.drillRegistrationCode ? (
                item.drillRegistrationCode.availableUserMaxNumber ? (
                  <div>
                    {item.drillRegistrationCode.registeredStudentCount}/
                    {item.drillRegistrationCode.availableUserMaxNumber}
                  </div>
                ) : (
                  <div>無制限</div>
                )
              ) : (
                <PopoverProvider>
                  <Popover>
                    <PopoverTrigger>配信不可</PopoverTrigger>
                    <PopoverPortal>
                      <PopoverContent side="top">
                        <div className="text-center">
                          校舎外で使用されている
                          <br />
                          教材のため配信できません
                        </div>
                      </PopoverContent>
                    </PopoverPortal>
                  </Popover>
                </PopoverProvider>
              )}
            </Flex>
          </TableColumn>
          <TableColumn className={styles.learningMaterialTagLabelCell}>
            <ul className={styles.learningMaterialLabelList}>
              {item.tags.map((lmTag) =>
                lmTag.tagType === "default" ? (
                  <DefaultLearningMaterialTag
                    key={`learning-material-tag-label-${lmTag.id}`}
                  >
                    {lmTag.name}
                  </DefaultLearningMaterialTag>
                ) : (
                  <CustomLearningMaterialTag
                    key={`learning-material-tag-label-${lmTag.id}`}
                  >
                    {lmTag.name}
                  </CustomLearningMaterialTag>
                ),
              )}
            </ul>
          </TableColumn>
          <TableColumn className={styles.buttonCell}>
            {shouldShowTagUpdateIcon ? (
              <LinkButton
                onClick={() => {
                  openModal({
                    learningMaterialName: item.name,
                    learningMaterialCode: item.learningMaterialCode,
                    learningMaterialTags: item.tags,
                  });
                }}
              >
                <Flex marginTop="0rem" alignItems="center">
                  <Icon name="icon-import-tag" className={styles.icon} />
                  <span className={styles.linkButtonLabel}>タグ設定</span>
                </Flex>
              </LinkButton>
            ) : null}
          </TableColumn>
        </tr>
      ))}
    />
  );
};

const StudyProgressLink = (props: {
  sectionId: string;
  learningMaterialCode: string;
}) => {
  return (
    <Link
      to={`/sections/${props.sectionId}/analytics/retention_level/${props.learningMaterialCode}`}
      className="buiButtonBase buiButtonVariantOutline buiButtonSizeXs"
    >
      学習進捗を見る
    </Link>
  );
};
