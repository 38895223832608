import { BlockRow } from "../../components/atoms/BlockRow/index";
import Icon from "../../components/atoms/Icon/index";
import { InlineBlock } from "../../components/atoms/InlineBlock/index";
import LinkButton from "../../components/atoms/LinkButton/index";
import { ScreenReaderOnly } from "../../components/general/ScreenReaderOnly/ScreenReaderOnly";
import { Text } from "../../components/general/Text";
import { Lecture } from "../../domains/Lecture";
import { LecturesTable, LecturesTableRow } from "./LecturesTable";

type Props = {
  lectures: Lecture[];
  onActivate: (lecture: Lecture) => void;
};
export const ArchivedLecturesTable = ({ lectures, onActivate }: Props) => {
  if (lectures.length === 0) {
    return (
      <BlockRow marginTop="1.6rem">
        <Text align="center">アーカイブの講座がありません。</Text>
      </BlockRow>
    );
  }

  return (
    <LecturesTable>
      {lectures.map((lecture: Lecture) => (
        <LecturesTableRow lecture={lecture} key={`lecture-row-${lecture.id}`}>
          <Text size="xs" align="right">
            <LinkButton
              onClick={() => {
                onActivate(lecture);
              }}
              bold
            >
              <Icon name="icon-archive" />
              <InlineBlock marginLeft="0.5rem">
                <ScreenReaderOnly>{lecture.name}を</ScreenReaderOnly>
                アクティブに戻す
              </InlineBlock>
            </LinkButton>
          </Text>
        </LecturesTableRow>
      ))}
    </LecturesTable>
  );
};
