import { BlockRow } from "../../components/atoms/BlockRow/index";
import Icon from "../../components/atoms/Icon/index";
import {
  EllipsisDropdown,
  EllipsisDropdownLink,
} from "../../components/general/EllipsisDropdown";
import { ScreenReaderOnly } from "../../components/general/ScreenReaderOnly";
import { Text } from "../../components/general/Text/Text";
import { Lecture } from "../../domains/Lecture";
import { LecturesTable, LecturesTableRow } from "./LecturesTable";

type Props = {
  lectures: Lecture[];
  onArchive: (lecture: Lecture) => void;
};
export const ActiveLecturesTable = ({ lectures, onArchive }: Props) => {
  if (lectures.length === 0) {
    return (
      <BlockRow marginTop="1.6rem">
        <Text align="center">
          講座の登録がありません。右上の「講座の登録」から登録ができます。
        </Text>
      </BlockRow>
    );
  }

  return (
    <LecturesTable>
      {lectures.map((lecture: Lecture) => (
        <LecturesTableRow lecture={lecture} key={`lecture-row-${lecture.id}`}>
          <EllipsisDropdown
            aria-label={`${lecture.name}のドロップダウン`}
            position={{ top: "-6rem", right: "3.6rem" }}
          >
            <EllipsisDropdownLink href={`./${lecture.id}/edit`}>
              <Icon name="icon-edit" />
              講座の編集
            </EllipsisDropdownLink>
            <EllipsisDropdownLink href={`./${lecture.id}/copy`}>
              <Icon name="icon-duplicate" />
              講座の複製
            </EllipsisDropdownLink>
            <EllipsisDropdownLink
              onClick={() => {
                onArchive(lecture);
              }}
            >
              <Icon name="icon-archive" />
              <ScreenReaderOnly>{lecture.name}を</ScreenReaderOnly>
              アーカイブ
            </EllipsisDropdownLink>
          </EllipsisDropdown>
        </LecturesTableRow>
      ))}
    </LecturesTable>
  );
};
