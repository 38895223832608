import { useCallback } from "react";
import { useNavigate } from "react-router";
import { ContentCourse } from "../../../../domains/ContentCourse";
import {
  ContentCourseRequestBody,
  useMutateContentCourse,
} from "../../../../hooks/http/useContentCoursesApi";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";

export type SubmitProps = {
  sectionId: string;
  course?: ContentCourse;
};
export const useSubmit = ({ sectionId, course }: SubmitProps) => {
  const navigate = useNavigate();
  const mutation = useMutateContentCourse({ sectionId, courseId: course?.id });
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const actionLabel = !course ? "登録" : "更新";

  const handleSubmit = useCallback((params: ContentCourseRequestBody) => {
    mutation.mutate(params, {
      onSuccess: () => {
        showSuccessMessage(`コースを${actionLabel}しました`);
        navigate(`/sections/${sectionId}/settings/content_courses`);
      },
      onError: () => {
        showErrorMessage(`コースを${actionLabel}できませんでした`);
      },
    });
  }, []);

  return {
    handleSubmit,
    mutation,
  };
};
