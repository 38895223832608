import * as React from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon/index";
import styles from "./styles.scss";

interface Props {
  path?: string;
  children?: React.ReactNode;
}

const handleClick = (e: any) => {
  e.preventDefault();
  history.back();
};

const renderLink = (props: Props) => {
  const { path } = props;

  if (path) {
    return (
      <Link to={path} className={styles.link}>
        <Icon name="icon-arrow-left" />
        <span className={styles.label}>{props.children}</span>
      </Link>
    );
  } else {
    return (
      <a className={styles.link} onClick={handleClick}>
        <Icon name="icon-arrow-left" />
        <span className={styles.label}>{props.children}</span>
      </a>
    );
  }
};

const BackLink = (props: Props) => {
  return <div className={styles.root}>{renderLink(props)}</div>;
};

export default BackLink;
