import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback } from "react";
import { boronClient } from "../../../api";
import { HTTPErrors, createError } from "../../../errors";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import { paths } from "../../../lib/api/v1";

type GuardianAnnounceMessagesResponse =
  paths["/api/v1/sections/{section_id}/guardian_announce_message_threads/{guardian_announce_message_thread_id}/messages"]["get"]["responses"]["200"]["content"]["application/json"]["messages"];
export type GuardianAnnounceMessage =
  GuardianAnnounceMessagesResponse["data"][0];

export const useFetchGuardianAnnounceMessages = ({
  sectionId,
  tagId,
}: {
  sectionId: string;
  tagId: string;
}) => {
  const queryResult = useInfiniteQuery<
    GuardianAnnounceMessagesResponse,
    HTTPErrors
  >({
    queryKey: buildQueryKey(sectionId, tagId),
    queryFn: async ({ pageParam }) => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/guardian_announce_message_threads/{guardian_announce_message_thread_id}/messages",
        {
          params: {
            path: {
              section_id: sectionId,
              guardian_announce_message_thread_id: tagId,
            },
            query: {
              page: pageParam as number,
            },
          },
        },
      );
      if (response.ok && data) {
        return data.messages;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  const messages = queryResult.data?.pages.flatMap((page) => page.data);
  return {
    ...queryResult,
    messages,
  };
};

export const useInsertGuardianAnnounceMessagesCache = () => {
  const queryClient = useQueryClient();

  return useCallback(
    ({
      sectionId,
      tagId,
      newMessages,
    }: {
      sectionId: string;
      tagId: string;
      newMessages: GuardianAnnounceMessage[];
    }) => {
      queryClient.setQueriesData<
        InfiniteData<GuardianAnnounceMessagesResponse>
      >({ queryKey: buildQueryKey(sectionId, tagId) }, (data) => {
        if (!data) {
          return data;
        }
        return {
          ...data,
          pages: [
            {
              ...data.pages[0],
              data: [...newMessages, ...data.pages[0].data],
            },
            ...data.pages.slice(1),
          ],
        };
      });
    },
    [queryClient],
  );
};

const buildQueryKey = (sectionId: string, tagId: string) => [
  "guardianAnnounceMessages",
  sectionId,
  tagId,
];
