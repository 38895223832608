import * as queryString from "query-string";
import { connect } from "react-redux";
import { showSuccessFlashMessage } from "../actions/flashMessage";
import { WithRouterProps, withRouter } from "../helpers/RouterHelper";

interface DispatchProps {
  showSuccessFlashMessage: typeof showSuccessFlashMessage;
}

type Props = WithRouterProps & DispatchProps;

type Params = { login?: "success" } & Record<string, unknown>;

const LoginCallback = (props: Props) => {
  const params: Params = queryString.parse(props.location.search);

  if (params.login === "success") {
    props.showSuccessFlashMessage("ログインしました");
  }

  return null;
};

const mapDispatchToProps = {
  showSuccessFlashMessage,
};

export default withRouter<Props>(
  connect(null, mapDispatchToProps)(LoginCallback),
);
