import { useLocation } from "react-router-dom";
import {
  BaseURLAction,
  URLType,
  createAddURLAction,
  createEditURLAction,
  useDetermineURLAction,
} from "../../../hooks/useDetermineURLAction";

export const ClassroomURLType = {
  ...URLType,
  copy: "copy",
} as const;

export type ClassroomURLTypes = keyof typeof ClassroomURLType;
type CopyAction<T> = { type: (typeof ClassroomURLType)["copy"] } & T;
type URLActions<T> = BaseURLAction<T> | CopyAction<T>;
type ResourceId = { classroomId: string };

export const useClassroomURLAction = () => {
  const location = useLocation();
  return useDetermineURLAction<ResourceId, URLActions<ResourceId>>(
    (param): URLActions<ResourceId> => {
      if (
        location.pathname.match(/classrooms\/\w+\/copy/) &&
        param.classroomId
      ) {
        return { type: ClassroomURLType.copy, classroomId: param.classroomId };
      }
      return param.classroomId
        ? createEditURLAction({ classroomId: param.classroomId })
        : createAddURLAction();
    },
  );
};

export const determineTitleLabel = (urlTypes: ClassroomURLTypes) => {
  switch (urlTypes) {
    case ClassroomURLType.add:
      return "登録";
    case ClassroomURLType.edit:
      return "編集";
    case ClassroomURLType.copy:
      return "複製";
  }
};
export const determineButtonLabel = (urlTypes: ClassroomURLTypes) =>
  urlTypes === ClassroomURLType.edit ? "更新" : "登録";
