import { useMutation } from "@tanstack/react-query";
import ApiClient from "../../../../api";
import Icon from "../../../../components/atoms/Icon";
import { DropdownMenuItem } from "../../../../components/general/DropdownMenu/DropdownMenu";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import StudentInterface from "../../../../interfaces/StudentInterface";

const useDisableStudent = (
  student: StudentInterface,
  removeStudent: (studentId: string) => void,
) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const mutation = useMutation({
    mutationFn: async () => {
      const response = await ApiClient.delete(`/api/v1/students/${student.id}`);
      if (response.ok) {
        showSuccessMessage("生徒情報を削除しました");
        removeStudent(student.id);
      } else {
        showErrorMessage("生徒情報の削除に失敗しました");
      }
    },
  });
  return {
    disableStudent: () => {
      if (student.status === "inactive") {
        !mutation.isPending &&
          confirm(
            "生徒情報を削除すると、アプリ連携・LINE連携が解除されます。また、すべてのデータが削除され復元することができません。本当に削除しますか？",
          ) &&
          mutation.mutate();
      } else {
        showErrorMessage("生徒アプリ連携の停止を行ってから削除してください");
      }
    },
  };
};

type Props = {
  student: StudentInterface;
  removeStudent: (studentId: string) => void;
};
export const DisableStudentDropdownLink = ({
  student,
  removeStudent,
}: Props) => {
  const { disableStudent } = useDisableStudent(student, removeStudent);

  return (
    <DropdownMenuItem onClick={disableStudent} hoverBgColor="red-lighten-2">
      <Icon className="mr-3 text-red-400" name="icon-trash" />
      <span className="text-red-400">生徒情報の削除</span>
    </DropdownMenuItem>
  );
};
