import { Button, IconTextbook } from "@studyplus/boron-ui";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import ContentBox from "../../components/atoms/ContentBox";
import { MultiSectionBatchesAdminSectionsSelectBox } from "../../components/features/MultiSectionBatches/MultiSectionBatchesAdminSectionsSelectBox";
import { MultiSectionBatchesDescription } from "../../components/features/MultiSectionBatches/MultiSectionBatchesDescription";
import { MultiSectionBatchesDownloadDateRangePicker } from "../../components/features/MultiSectionBatches/MultiSectionBatchesDownloadDateRangePicker";
import {
  adminSectionsSchema,
  useMultiSectionBatchesAdminSectionsSelectBox,
} from "../../components/features/MultiSectionBatches/useMultiSectionBatchesAdminSectionsSelectBox";
import { dateRangeSchema } from "../../components/features/MultiSectionBatches/useMultiSectionBatchesDateRangePicker";
import { DateRange } from "../../components/general/DynamicDateRangePicker";
import enhanceHomePage from "../../hocs/enhanceHomePage";
import { useMultiSectionBatchesDownload } from "../../hooks/http/useMultiSectionBatchesDownload";
import { AdminSection } from "../../interfaces/AdminSection";
import OperatorInterface from "../../interfaces/OperatorInterface";

type Props = {
  currentOperator: OperatorInterface;
};

type Values = {
  dateRange: DateRange;
  adminSections: AdminSection[];
};

const Index = ({ currentOperator }: Props) => {
  const { adminSections, isLoading } =
    useMultiSectionBatchesAdminSectionsSelectBox({
      operatorId: currentOperator.id,
    });

  const { isPending, mutate } = useMultiSectionBatchesDownload({
    multiSectionBatchType: "download_study_records",
  });

  const createDownloadFile = (values: Values) => {
    mutate({
      adminSections: values.adminSections,
      startDate: values.dateRange.startDate,
      endDate: values.dateRange.endDate,
    });
  };

  const canCreate = !isLoading && adminSections.length > 0 && !isPending;

  return (
    <div className="flex w-[90%] flex-col">
      <h1 className="text-bold my-10 text-3xl text-black">校舎横断操作</h1>
      <ContentBox className="w-[80%] p-10">
        <div>
          <div className="flex items-center gap-6">
            <IconTextbook className="h-[3rem] w-[3rem] text-gray-600" />
            <h2 className="text-xl">学習記録のダウンロード</h2>
          </div>
          <MultiSectionBatchesDescription
            helpUrl="https://fs-help.studyplus.co.jp/ja/articles/9738631"
            isOnlyProPlanStudent
          />
          <Formik<Values>
            initialValues={{
              dateRange: { startDate: undefined, endDate: undefined },
              adminSections,
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
              createDownloadFile(values);
            }}
            validationSchema={yup.object().shape({
              dateRange: dateRangeSchema,
              adminSections: adminSectionsSchema,
            })}
          >
            <Form>
              <div className="flex flex-col gap-10">
                <Field
                  name="adminSections"
                  component={MultiSectionBatchesAdminSectionsSelectBox}
                  isLoading={isLoading}
                />
                <Field
                  name="dateRange"
                  component={MultiSectionBatchesDownloadDateRangePicker}
                />
                <div className="flex justify-end">
                  <Button
                    className={classNames({
                      "hover:border-blue-300 hover:bg-blue-300": canCreate,
                    })}
                    disabled={!canCreate}
                    type="submit"
                  >
                    作成
                  </Button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </ContentBox>
    </div>
  );
};

export const MultiSectionBatchesDownloadStudyRecordsPage = enhanceHomePage(
  Index,
  {
    title: "校舎横断操作",
  },
);
