import { boronClient } from "../../../api.ts";
import { FSHTTPError } from "../../../errors.ts";
import { useRemoveStaffBoardPostCommentFromListCache } from "../../../features/SectionsStaffBoardPostsPage/useFetchStaffBoardPosts.ts";
import { useBoronMutation } from "../../../hooks/http/useBoronMutation.ts";
import { useRemoveStaffBoardPostCommentFromSingleCache } from "../../../hooks/http/useFetchStaffBoardPost.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";

export const useDeleteStaffBoardPostComment = ({
  sectionId,
  staffBoardPostId,
}: {
  sectionId: string;
  staffBoardPostId: string;
}) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const { removeStaffBoardPostCommentFromSingleCache } =
    useRemoveStaffBoardPostCommentFromSingleCache({
      sectionId,
      staffBoardPostId,
    });
  const { removeStaffBoardPostCommentFromListCache } =
    useRemoveStaffBoardPostCommentFromListCache({
      sectionId,
      staffBoardPostId,
    });

  return useBoronMutation(
    async ({ commentId }: { commentId: string }) =>
      await boronClient.DELETE(
        "/api/v1/sections/{section_id}/staff_board_posts/{staff_board_post_hashid}/comments/{staff_board_post_comment_hashid}",
        {
          params: {
            path: {
              section_id: sectionId,
              staff_board_post_hashid: staffBoardPostId,
              staff_board_post_comment_hashid: commentId,
            },
          },
        },
      ),
    {
      onSuccess: (_, { commentId }) => {
        removeStaffBoardPostCommentFromListCache(commentId);
        removeStaffBoardPostCommentFromSingleCache(commentId);
        showSuccessMessage("コメントを削除しました");
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage("コメントを削除できませんでした");
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    },
  );
};
