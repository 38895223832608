import { AnyAction } from "redux";
import { POST_ZOOM_MEETING_MESSAGE_SUCCESS } from "../../../../src/actions/pages/sectionAnnounceMessages/types";
import ApiResponse from "../../../interfaces/ApiResponse";
import { AnnounceMessage } from "../../../interfaces/MessageInterface";
import { AnnounceMessagesStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";

export const defaultAnnounceMessagesState: AnnounceMessagesStateInterface = {
  loading: true,
  hasMore: true,
  meta: {
    currentPage: 0,
    totalPages: 0,
  },
  data: [],
};

const appendSuccessResponse = (
  state: AnnounceMessagesStateInterface,
  response: ApiResponse<AnnounceMessage[]>,
) => {
  const { meta, data } = response;

  return {
    ...state,
    hasMore: meta.currentPage < meta.totalPages,
    loading: false,
    data: state.data.concat(data),
    meta,
  };
};

const announceMessages = (
  state: AnnounceMessagesStateInterface = defaultAnnounceMessagesState,
  action: AnyAction,
): AnnounceMessagesStateInterface => {
  const { payload } = action;

  switch (action.type) {
    // Loading
    case "GET_INITIAL_ANNOUNCE_MESSAGES":
      return {
        ...defaultAnnounceMessagesState,
        loading: true,
      };

    case "GET_ANNOUNCE_MESSAGES":
      return {
        ...state,
        loading: true,
      };

    // Success Response
    case "GET_INITIAL_ANNOUNCE_MESSAGES_RESPONSE_RECEIVED": {
      const { meta, data } = payload.messages;

      return {
        ...state,
        meta,
        data,
        hasMore: meta.currentPage < meta.totalPages,
        loading: false,
      };
    }

    case "GET_ANNOUNCE_MESSAGES_RESPONSE_RECEIVED":
      return appendSuccessResponse(state, payload.messages);

    case "POST_ANNOUNCE_MESSAGE_SUCCESS":
    case "POST_ANNOUNCE_MESSAGE_FILE_SUCCESS":
    case POST_ZOOM_MEETING_MESSAGE_SUCCESS:
      return {
        ...state,
        data: [payload.message, ...state.data],
      };

    case "GET_ANNOUNCE_MESSAGES_RESPONSE_ERROR":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default announceMessages;
