import classNames from "classnames";
import * as React from "react";
import { Flex } from "../../../../components/atoms/Flex";
import Icon from "../../../../components/atoms/Icon";
import { ColorDot } from "../../../../components/general/ColorDot";
import { Status, StatusType, detectStatus } from "../../../../domains/LTI";
import { StudentCourseResult } from "../../../../domains/StudentCourseResult";
import styles from "./styles.scss";

type Props = {
  studentCourseResult: StudentCourseResult;
};
export const StudentCourseResultTable = ({ studentCourseResult }: Props) => {
  return (
    <div className={styles.root}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.table__header}>
            <th className={styles["table__header--wide"]}>教科</th>
            <th>提出状況</th>
            <th>完了率</th>
            <th>得点</th>
          </tr>
        </thead>
        <tbody>
          {studentCourseResult.unitResults.map((unitResult) => (
            <UnitResultRow
              key={`UnitResultRow-${unitResult.id}`}
              unitResult={unitResult}
            />
          ))}
          <CourseResultRow studentCourseResult={studentCourseResult} />
        </tbody>
      </table>
    </div>
  );
};

type UnitResultRowProps = {
  unitResult: StudentCourseResult["unitResults"][0];
};
const UnitResultRow = ({ unitResult }: UnitResultRowProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const status = detectStatus(unitResult);
  const { scoreGiven, scoreMaximum, lastSubmittedAt } = unitResult;
  return (
    <>
      <tr className={`${styles.table__row} ${styles.unitRow}`}>
        <td
          onClick={() => setIsOpen(!isOpen)}
          className={styles.unitRow__title}
        >
          <Flex
            marginTop="0"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex marginTop="0" alignItems="center" gap="0.8">
              <Icon name="icon-folder-level-2" />
              <span>{unitResult.name}</span>
            </Flex>
            <Icon
              name={isOpen ? "icon-arrow-up" : "icon-arrow-down"}
              color="primary"
            />
          </Flex>
        </td>
        <td>
          <SubmissionText status={status} lastSubmittedAt={lastSubmittedAt} />
        </td>
        <td>{unitResult.completeRate && unitResult.completeRate * 100}%</td>
        <td>
          <ScoreText
            status={status}
            scoreGiven={scoreGiven}
            scoreMaximum={scoreMaximum}
          />
        </td>
      </tr>
      {isOpen &&
        unitResult.contentResults.map((contentResult, i, arr) => (
          <ContentResultRow
            key={`ContentResultRow=${i}`}
            contentResult={contentResult}
            isLastContent={arr.length === i + 1}
          />
        ))}
    </>
  );
};

type ContentResultRowProps = {
  contentResult: StudentCourseResult["unitResults"][0]["contentResults"][0];
  isLastContent: boolean;
};
const ContentResultRow = ({
  contentResult,
  isLastContent,
}: ContentResultRowProps) => {
  const status = detectStatus(contentResult);
  const { scoreGiven, scoreMaximum, lastSubmittedAt } = contentResult;
  return (
    <tr className={`${styles.table__row} ${styles.contentRow}`}>
      <td className={styles.contentRow__title}>
        <span
          className={classNames(styles.contentRow__verticalline, {
            [styles["contentRow__verticalline--half"]]: isLastContent,
          })}
        />
        <div className={styles.contentRow__name}>
          <ColorDot backgroundColor="gray-darken-1" />
          {contentResult.name}
        </div>
        {contentResult.testReviewUrl && (
          <a
            href={contentResult.testReviewUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.contentRow__link}
          >
            解答をみる
            <Icon name="icon-external-link" />
          </a>
        )}
      </td>
      <td>
        <SubmissionText status={status} lastSubmittedAt={lastSubmittedAt} />
      </td>
      <td>
        {contentResult.completeRate &&
          Math.round(contentResult.completeRate * 100)}
        %
      </td>
      <td>
        <ScoreText
          status={status}
          scoreGiven={scoreGiven}
          scoreMaximum={scoreMaximum}
        />
      </td>
    </tr>
  );
};

type CourseResultRowProps = {
  studentCourseResult: StudentCourseResult;
};
const CourseResultRow = ({ studentCourseResult }: CourseResultRowProps) => {
  const status = detectStatus(studentCourseResult);
  const { scoreGiven, scoreMaximum, completeRate } = studentCourseResult;
  return (
    <tr className={`${styles.table__row} ${styles.courseRow}`}>
      <td>合計</td>
      <td>
        {status == Status.NotSubmitted ? (
          <span
            className={`${styles.courseRow__submission} ${styles["table--red"]}`}
          >
            <Icon name="icon-failed" className={styles.courseRow__icon} />
            未提出あり
          </span>
        ) : (
          <span
            className={`${styles.courseRow__submission} ${styles["table--primary"]}`}
          >
            <Icon name="icon-success" className={styles.courseRow__icon} />
            提出済
          </span>
        )}
      </td>
      <td>
        {completeRate >= 1.0 ? (
          <span
            className={`${styles.courseRow__completeRate} ${styles["table--primary"]}`}
          >
            <Icon name="icon-success" className={styles.courseRow__icon} />
            完了済
          </span>
        ) : (
          <span
            className={`${styles.courseRow__completeRate} ${styles["table--red"]}`}
          >
            <Icon name="icon-failed" className={styles.courseRow__icon} />
            {Math.round(completeRate * 100)}%
          </span>
        )}
      </td>
      <td>{`${scoreGiven ?? 0}/${scoreMaximum}`}</td>
    </tr>
  );
};
type SubmissionTextProps = {
  status: StatusType;
  lastSubmittedAt: Date | null;
};
const SubmissionText = ({ status, lastSubmittedAt }: SubmissionTextProps) => {
  switch (status) {
    case Status.Submitted:
    case Status.Completed: {
      return (
        <span>{lastSubmittedAt && lastSubmittedAt.toLocaleDateString()}</span>
      );
    }
    case Status.NotSubmitted:
    default: {
      return <span className={styles["table--red"]}>未提出</span>;
    }
  }
};
type ScoreTextProps = {
  status: StatusType;
  scoreGiven: number | null;
  scoreMaximum: number;
};
const ScoreText = ({ status, scoreGiven, scoreMaximum }: ScoreTextProps) => {
  switch (status) {
    case Status.Submitted: {
      return (
        <>
          <span className={styles["table--green"]}>採点待ち</span>
          <span> ({`${scoreGiven ?? 0}/${scoreMaximum}`})</span>
        </>
      );
    }
    case Status.Completed:
    case Status.NotSubmitted:
    default: {
      return <span>{`${scoreGiven ?? 0}/${scoreMaximum}`}</span>;
    }
  }
};
