import * as React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackLink from "../../../components/atoms/BackLink";
import { BlockRow } from "../../../components/atoms/BlockRow/index";
import Loader from "../../../components/atoms/Loader";
import { UnprocessableEntityError } from "../../../errors";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import { translateHttpMethod } from "../../../helpers/translateHttpMethod";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import {
  SectionLearningMaterialForm,
  Values as SectionLearningMaterialFormValues,
} from "./SectionLearningMaterialForm";
import { useFetchSectionLearningMaterial } from "./useFetchSectionLearningMaterial";
import {
  SectionLearningMaterialParams,
  useMutateSectionLearningMaterial,
} from "./useMutateSectionLearningMaterial";

interface Props extends OutletContextProps {
  section: SectionInterface;
}

// 校舎独自教材フォームのページ
// URLに:learningMaterialIdがあるときは編集ページ、ないときは新規作成ページになる
export const SectionsSettingsSectionLearningMaterialFormPage = (
  props: Props,
) => {
  React.useEffect(() => {
    props.setActiveMenu("learning_material");
  }, []);
  const {
    handleSubmit,
    sectionLearningMaterialState,
    isSubmitting,
    mutateErrorMessage,
  } = useSectionLearningMaterialFormPage(props.section);

  return sectionLearningMaterialState.isInitialLoading ? (
    <Loader loading={sectionLearningMaterialState?.isInitialLoading} />
  ) : (
    <>
      <BlockRow marginTop="1.2rem">
        <BackLink>戻る</BackLink>
      </BlockRow>
      <BlockRow marginTop="2rem">
        <SectionLearningMaterialForm
          section={props.section}
          submitting={isSubmitting}
          apiError={mutateErrorMessage}
          onSubmit={handleSubmit}
          sectionLearningMaterial={sectionLearningMaterialState.data}
        />
      </BlockRow>
    </>
  );
};
const useSectionLearningMaterialFormPage = (section: SectionInterface) => {
  const { learningMaterialId } = useParams<{
    sectionId: string;
    learningMaterialId: string;
  }>();

  // 編集ページの場合は編集対象の独自教材を取得
  const sectionLearningMaterialState = useFetchSectionLearningMaterial({
    section,
    learningMaterialId,
  });

  useQueryError(sectionLearningMaterialState.error);

  // 更新ロジック
  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const mutation = useMutateSectionLearningMaterial({
    section,
    learningMaterialId,
  });

  const handleSubmit = (values: SectionLearningMaterialFormValues) => {
    const params: SectionLearningMaterialParams = {
      name: values.name,
      tag_ids: values.tags
        .filter((tag) => tag.isDeletable)
        .map((tag) => tag.id),
    };

    if (values.file) {
      params.file = values.file;
    }

    const methodText = translateHttpMethod(mutation.method);
    mutation.mutate(params, {
      onSuccess: () => {
        showSuccessMessage(`校舎独自教材を${methodText}しました`);
        navigate(`/sections/${section.id}/settings/section_learning_materials`);
      },
      onError: () => {
        showErrorMessage(`校舎独自教材を${methodText}できませんでした`);
      },
    });
  };

  // フォームに表示するエラーメッセージ
  const mutateErrorMessage =
    mutation.error instanceof UnprocessableEntityError
      ? mutation.error.getErrorMessages().join("\n")
      : undefined;

  return {
    handleSubmit,
    sectionLearningMaterialState,
    mutateErrorMessage,
    isSubmitting: mutation.isPending,
  };
};

const pageInfo = {
  title: "校舎独自教材の登録",
};

const mapStateToProps = (state: AppStateInterface) => ({
  section: state.currentSection,
  sectionLearningMaterialNewState: state.sectionLearningMaterialNewState,
});

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsSectionLearningMaterialFormPage,
  pageInfo,
  ["management_learning_materials"],
);

export default connect(mapStateToProps)(EnhancedPage as any);
