import * as queryString from "query-string";
import { TimelineType } from "../../../interfaces/TimelineInterface";
import TabsNavi from "../../molecules/TabsNavi";

type Props = {
  sectionId: string;
  timelineType: TimelineType;
  search: string;
};

export const SectionTimelineTab = ({
  sectionId,
  timelineType,
  search,
}: Props) => {
  return (
    <TabsNavi
      tabs={[
        {
          label: "サマリー",
          link: {
            pathname: `/sections/${sectionId}/timeline/daily`,
            search: search,
          },
          active: timelineType === TimelineType.TIMELINE_SUMMARY,
        },
        {
          label: "リアルタイム",
          link: {
            pathname: `/sections/${sectionId}/timeline`,
            search: ignoreDateQuery(search),
          },
          active: timelineType === TimelineType.TIMELINE_REALTIME,
        },
        {
          label: "成績記録",
          link: {
            pathname: `/sections/${sectionId}/timeline/examination`,
            search: ignoreDateQuery(search),
          },
          active: timelineType === TimelineType.TIMELINE_EXAMINATION,
        },
      ]}
    />
  );
};

const ignoreDateQuery = (query: string): string => {
  const queryObject = queryString.parse(query);
  if (queryObject.date) {
    delete queryObject.date;
  }

  return queryString.stringify(queryObject);
};
