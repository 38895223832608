import { IconLock } from "@studyplus/boron-ui";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Icon from "../../../../components/atoms/Icon";
import StudyScheduleProgressBar from "../../../../components/molecules/StudyScheduleProgressBar";
import {
  LearningMaterialProgress,
  toPercent,
} from "../../../../domains/LearningMaterialProgress";
import {
  hasStudyScheduleWithAmount,
  hasStudyScheduleWithTime,
} from "../../../../domains/LearningMaterialProgress";
import { TermType } from "../../../../domains/QueryDateRange";
import { Student } from "../../../../domains/Student";
import TimeHelper, { formatMonthDay } from "../../../../helpers/TimeHelper";
import { toStringFromDate } from "../../../../helpers/TimeHelper";
import styles from "./styles.scss";

interface Props {
  student: Student;
  progresses: readonly LearningMaterialProgress[];
  startDate: Date;
  endDate: Date;
  dateRangeType: TermType;
}

export const StudentStudyScheduleProgressList = (props: Props): JSX.Element => {
  const { student, progresses } = props;

  return (
    <table className={styles.table}>
      <tbody>
        <tr className={styles.tableHeaderRow}>
          <th
            className={classNames(styles.tableHeader, styles.student)}
            rowSpan={progresses.length === 0 ? 2 : progresses.length + 1}
          >
            <Icon name="icon-navi-avatar" className={styles.icon} />
            <Link
              to={`/students/${
                student.id
              }/study_schedules?date=${toStringFromDate(
                props.startDate,
              )}&type=${props.dateRangeType}`}
              className={styles.studentName}
            >
              {student.fullName}
            </Link>
            <div className={styles.job}>（{student.jobHuman}）</div>
          </th>
          <th
            className={classNames(styles.tableHeader, styles.learningMaterial)}
          >
            教材名
          </th>
          <th className={classNames(styles.tableHeader, styles.number)}>
            計画
          </th>
          <th className={classNames(styles.tableHeader, styles.number)}>
            実績
          </th>
          <th className={classNames(styles.tableHeader, styles.percentage)}>
            進捗率
          </th>
          <th className={classNames(styles.graphHeader, styles.tableHeader)}>
            {props.dateRangeType !== "daily" ? (
              <>
                <span>{formatMonthDay(props.startDate)}</span>
                <span>〜</span>
                <span>{formatMonthDay(props.endDate)}</span>
              </>
            ) : (
              <span>{formatMonthDay(props.startDate)}</span>
            )}
          </th>
        </tr>
        {props.student.billingPlan === "free" ? (
          <tr className={styles.tableRow}>
            <th
              className={classNames(styles.tableCell, styles.noData)}
              colSpan={5}
            >
              <IconLock className="fill-gray-800 align-bottom" />
              <a
                href={"https://fs-help.studyplus.co.jp/ja/articles/9153866"}
                className="ml-2 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                プロプラン
              </a>
              にすると全ての学習計画の閲覧ができます
            </th>
          </tr>
        ) : progresses.length > 0 ? (
          progresses.map((progress, i) => {
            const { learningMaterial } = progress;
            const shouldShowStudyScheduleWithTime =
              hasStudyScheduleWithTime(progress);
            const shouldShowStudyScheduleWithAmount =
              hasStudyScheduleWithAmount(progress);
            const percentProgressNumberOfSeconds =
              shouldShowStudyScheduleWithTime
                ? toPercent(progress.numberOfSeconds)
                : 0;
            const percentProgressAmount = shouldShowStudyScheduleWithAmount
              ? toPercent(progress.amount)
              : 0;

            return (
              <tr className={styles.tableRow} key={`SectionStudySchedule-${i}`}>
                <th
                  className={classNames(
                    styles.tableCell,
                    styles.learningMaterial,
                  )}
                >
                  <div className={styles.learningMaterialContainer}>
                    <div
                      className={styles.learningMaterialImage}
                      style={{
                        backgroundImage: `url(${learningMaterial.imageUrl})`,
                      }}
                    />
                    <div className={styles.learningMaterialTitle}>
                      {learningMaterial.name}
                    </div>
                  </div>
                </th>
                <th className={classNames(styles.tableCell, styles.number)}>
                  {shouldShowStudyScheduleWithTime ? (
                    <div className={styles.seconds}>
                      {TimeHelper.secondsToDisplayTime(
                        progress.numberOfSeconds.plan,
                      )}
                    </div>
                  ) : null}
                  {shouldShowStudyScheduleWithAmount ? (
                    <div className={styles.amount}>
                      {progress.amount.plan}
                      {progress.learningMaterial.unit}
                    </div>
                  ) : null}
                </th>
                <th className={classNames(styles.tableCell, styles.number)}>
                  {shouldShowStudyScheduleWithTime ? (
                    <div className={styles.seconds}>
                      {TimeHelper.secondsToDisplayTime(
                        progress.numberOfSeconds.result,
                      )}
                    </div>
                  ) : null}
                  {shouldShowStudyScheduleWithAmount ? (
                    <div className={styles.amount}>
                      {progress.amount.result}
                      {progress.learningMaterial.unit}
                    </div>
                  ) : null}
                </th>
                <th className={classNames(styles.tableCell, styles.percentage)}>
                  {shouldShowStudyScheduleWithTime ? (
                    <div
                      className={classNames(styles.seconds, {
                        [styles.high]: percentProgressNumberOfSeconds >= 100,
                      })}
                    >
                      {percentProgressNumberOfSeconds}%
                    </div>
                  ) : null}
                  {shouldShowStudyScheduleWithAmount ? (
                    <div
                      className={classNames(styles.amount, {
                        [styles.high]: percentProgressAmount >= 100,
                      })}
                    >
                      {percentProgressAmount}%
                    </div>
                  ) : null}
                </th>
                <th className={classNames(styles.tableCell, styles.chartCell)}>
                  {hasStudyScheduleWithTime(progress) && (
                    <div className={classNames(styles.seconds)}>
                      <StudyScheduleProgressBar
                        progress={progress.numberOfSeconds}
                      />
                    </div>
                  )}
                  {hasStudyScheduleWithAmount(progress) && (
                    <div className={classNames(styles.amount)}>
                      <StudyScheduleProgressBar progress={progress.amount} />
                    </div>
                  )}
                </th>
              </tr>
            );
          })
        ) : (
          <tr className={styles.tableRow}>
            <th
              className={classNames(styles.tableCell, styles.noData)}
              colSpan={5}
            >
              期間中の学習計画がありません
            </th>
          </tr>
        )}
      </tbody>
    </table>
  );
};
