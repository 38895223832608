import classNames from "classnames";
import format from "date-fns/format";
import * as React from "react";
import StudyScheduleProgressBar from "../../../../components/molecules/StudyScheduleProgressBar/index";
import { LearningMaterialProgress } from "../../../../domains/LearningMaterialProgress";
import TimeHelper from "../../../../helpers/TimeHelper";
import StudentInterface from "../../../../interfaces/StudentInterface";
import styles from "./styles.scss";

interface Props {
  student: StudentInterface;
  progresses: LearningMaterialProgress[];
  startDate: Date;
  endDate: Date;
}

const StudentStudyScheduleList = (props: Props): React.ReactElement => {
  const { progresses, startDate, endDate } = props;

  if (progresses.length > 0) {
    return (
      <div className={styles.root}>
        {progresses.map((progress, i) =>
          renderProgress(progress, startDate, endDate, i),
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.root}>
        <p className={styles.noData}>学習計画がありません</p>
      </div>
    );
  }
};

const renderProgress = (
  progress: LearningMaterialProgress,
  startDate: Date,
  endDate: Date,
  key: number,
) => {
  const { learningMaterial } = progress;

  return (
    <div className={styles.plan} key={`StudentStudyScheduleList-${key}`}>
      <div className={styles.plan__left}>
        <img src={learningMaterial.imageUrl} className={styles.image} />
      </div>
      <div className={styles.plan__right}>
        <div className={styles.header}>
          <div className={styles.rowBetween}>
            <h3 className={styles.title}>{learningMaterial.name}</h3>
          </div>
        </div>
        {renderTable(progress, startDate, endDate)}
      </div>
    </div>
  );
};

const renderTable = (
  progress: LearningMaterialProgress,
  startDate: Date,
  endDate: Date,
) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.table__headerRow}>
          <th className={styles.table__header}>計画</th>
          <th className={styles.table__header}>実績</th>
          <th className={styles.table__header}>進捗率</th>
          <th className={styles.table__header}>
            <div className={styles.table__header__dates}>
              <span>{format(startDate, "MM/dd")}</span>
              <span>{format(endDate, "MM/dd")}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {renderSeconds(progress)}
        {renderAmount(progress)}
      </tbody>
    </table>
  );
};

const renderSeconds = (progress: LearningMaterialProgress) => {
  const { plan, result, ratio } = progress.numberOfSeconds;
  if (plan > 0) {
    return (
      <tr>
        {/* 計画 */}
        <td
          className={classNames(styles.table__data, styles.table__dataNarrow)}
        >
          {TimeHelper.secondsToDisplayTime(plan)}
        </td>

        {/* 実績 */}
        <td
          className={classNames(styles.table__data, styles.table__dataNarrow)}
        >
          {TimeHelper.secondsToDisplayTime(result)}
        </td>

        {/* 進捗率 */}
        <td
          className={classNames(styles.table__data, styles.table__dataNarrow)}
        >
          <span
            className={classNames({
              [styles.completed]: ratio >= 1,
            })}
          >
            {Math.floor(ratio * 100)}%
          </span>
        </td>
        <td className={styles.table__data}>
          {progress.numberOfSeconds !== undefined ? (
            <StudyScheduleProgressBar progress={progress.numberOfSeconds} />
          ) : null}
        </td>
      </tr>
    );
  }
};

const renderAmount = (progress: LearningMaterialProgress) => {
  const { learningMaterial } = progress;
  const { result, ratio, plan } = progress.amount;

  if (plan > 0 && learningMaterial.unit) {
    return (
      <tr>
        {/* 計画 */}
        <td
          className={classNames(styles.table__data, styles.table__dataNarrow)}
        >
          {plan}
          {learningMaterial.unit}
        </td>
        {/* 実績 */}
        <td
          className={classNames(styles.table__data, styles.table__dataNarrow)}
        >
          {result}
          {learningMaterial.unit}
        </td>
        {/* 進捗率 */}
        <td
          className={classNames(styles.table__data, styles.table__dataNarrow, {
            [styles.completed]: ratio >= 1,
          })}
        >
          {Math.floor(ratio * 100)}%
        </td>
        <td className={styles.table__data}>
          {progress.amount !== undefined ? (
            <StudyScheduleProgressBar progress={progress.amount} />
          ) : null}
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

export default StudentStudyScheduleList;
