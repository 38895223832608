import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import { Modal } from "../../atoms/Modal";
import styles from "./styles.scss";

type FormAction = "new" | "edit";

interface Props {
  showModal: boolean;
  onComplete: () => void;
  action: FormAction;
}

const actionLabel = (action: FormAction) => {
  return action === "new" ? "登録" : "更新";
};

const SkillModal = (props: Props): React.ReactElement<Props> => {
  const { showModal, onComplete, action } = props;

  return (
    <Modal isOpen={showModal} onRequestClose={onComplete}>
      <Modal.Header onClose={onComplete}>
        <h2>{actionLabel(action)}が完了しました</h2>
      </Modal.Header>
      <Modal.Body>
        <p className={styles.message}>
          アシスタントスキルの{actionLabel(action)}が完了しました。
          <br />
          {actionLabel(action)}
          した内容は、明日のAM6:00のお知らせ通知から反映されます。
        </p>
        <div className={styles.buttonContainer}>
          <Button
            disabled={false}
            className={styles.button}
            onClick={props.onComplete}
          >
            完了
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SkillModal;
