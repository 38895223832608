import { useInfiniteQuery } from "@tanstack/react-query";
import api from "../../../api";
import {
  LearningMaterialTagList,
  toTagParams,
} from "../../../domains/LearningMaterialTag";
import { ManagementLearningMaterialList } from "../../../domains/ManagementLearningMaterial";
import { HTTPErrors, createError } from "../../../errors";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import { useQueryError } from "../../../hooks/http/useQueryError";
import ApiResponse from "../../../interfaces/ApiResponse";
import SectionInterface from "../../../interfaces/SectionInterface";

type UseFetchManagementLearningMaterialsProps = {
  section: SectionInterface;
  tags: LearningMaterialTagList;
  name?: string;
  enabled: boolean;
  haveNoTag?: boolean;
};

type Response = ApiResponse<ManagementLearningMaterialList>;

export const useFetchManagementLearningMaterials = (
  props: UseFetchManagementLearningMaterialsProps,
) => {
  const { learning_material_tag_ids, have_no_tag } = toTagParams({
    tags: props.tags,
  });
  const cacheKey = toCacheKeyOf({
    sectionId: props.section.id,
    learning_material_tag_ids,
    name: props.name,
    have_no_tag,
  });
  const baseUrl = `/api/v1/sections/${props.section.id}/management_learning_materials`;
  const result = useInfiniteQuery<Response, HTTPErrors>({
    queryKey: cacheKey,
    queryFn: async ({ pageParam }) => {
      const res = await api.interruptGet(baseUrl, {
        query: {
          page: pageParam,
          name: props.name,
          tag_ids: learning_material_tag_ids,
          have_no_tag,
        },
      });
      if (res.ok) {
        const json = await res.json();
        return json.sectionManagementLearningMaterials;
      }
      throw await createError(res);
    },
    initialPageParam: 1,
    getNextPageParam,
    enabled: props.enabled,
  });

  useQueryError(result.error);

  const data = result.data?.pages.flatMap((page) => page.data);

  return { ...result, data };
};

export type FilterParams = TagParams & {
  name: string;
};
type TagParams = {
  learning_material_tag_ids: string[];
  have_no_tag?: string;
};

export const toCacheKeyOf = ({
  sectionId,
  name,
  learning_material_tag_ids,
  have_no_tag,
}: TagParams & { sectionId: string; name?: string }) => {
  return [
    `/api/v1/sections/${sectionId}/management_learning_materials`,
    name,
    learning_material_tag_ids.join(","),
    have_no_tag,
  ];
};
