import format from "date-fns/format";
import isBefore from "date-fns/isBefore";
import subDays from "date-fns/subDays";
import {
  ErrorMessage,
  Field,
  Form,
  FormikBag,
  FormikProps,
  withFormik,
} from "formik";
import * as React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { parseDateTime } from "../../../../helpers/TimeHelper";
import OperatorInterface from "../../../../interfaces/OperatorInterface";
import OperatorProfileInterface from "../../../../interfaces/OperatorProfileInterface";
import { PostZoomMeetingMessageParams } from "../../../../interfaces/PostZoomMeetingMessageParams";
import Button, { ButtonSize } from "../../../atoms/Button";
import Checkbox from "../../../atoms/Checkbox/index";
import ErrorText from "../../../atoms/ErrorText/index";
import Input from "../../../atoms/Input/index";
import Label from "../../../atoms/Label/index";
import { Modal } from "../../../atoms/Modal/index";
import Radio from "../../../atoms/Radio/index";
import { SingleDatePickerField } from "../../../atoms/SingleDatePickerField";
import Textarea from "../../../atoms/Textarea/index";
import { MessageDestinationInterface } from "../index";
import styles from "./styles.scss";

export interface Values {
  content: string;
  topic: string;
  password?: string;
  startDate: Date;
  startTime: string;
  duration: {
    hours: number;
    minutes: number;
  };
  meetingIdOption: "auto" | "pmi";
  enabledPassword: boolean;
  forcePassword: boolean;
}

interface Props {
  operator: OperatorInterface;
  operatorProfile: OperatorProfileInterface;
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (
    params: PostZoomMeetingMessageParams,
    setSubmitting: (submitting: boolean) => void,
    setErrors: (errors: Record<string, unknown>) => void,
    onSuccessCallback: () => void,
  ) => void;
  destination: MessageDestinationInterface;
  defaultContent: string;
}

const CONTENT_MAX_LENGTH = 2000;
const TOPIC_MAX_LENGTH = 200;

const generatePassword = (): string => {
  return Math.random().toString(36).slice(-10);
};

const InnerForm = (props: Props & FormikProps<Values>): React.ReactElement => {
  // 宛先ごとにフォームの値をリセット
  useEffect(() => {
    props.resetForm();
  }, [props.destination.path]);

  // パスワード必須に
  // チェックを入れたときはパスワードを生成
  // 外したときはパスワードをリセット
  useEffect(() => {
    if (props.values.enabledPassword === true) {
      props.setFieldValue("password", generatePassword());
    } else {
      props.setFieldValue("password", undefined);
    }
  }, [props.values.enabledPassword]);

  return (
    <Form>
      <Modal isOpen={props.isOpened} onRequestClose={props.onClose}>
        <Modal.Header onClose={props.onClose}>
          <span>
            <Link to={props.destination.path} className={styles.name}>
              {props.destination.name}
            </Link>
            へのZoomのURLを自動発行
          </span>
        </Modal.Header>
        <Modal.Body>
          <h3 className={styles.heading}>メッセージ</h3>
          <div className={styles.message}>
            <span className={styles.message__length}>
              {props.values.content.length}/{CONTENT_MAX_LENGTH}字
            </span>
            <Field
              component={Textarea}
              name="content"
              data-testid="zoom_modal-textarea-content"
            />
            <ErrorMessage component={ErrorText} name="content" />
          </div>

          <h3 className={styles.heading}>Zoomスケジュール設定</h3>
          <fieldset>
            <div className={styles.fieldContainer}>
              <Label isMute={true}>タイトル</Label>
              <Field
                component={Input}
                name="topic"
                placeholder={`${props.operator.fullName}のZoomミーティング`}
                className={styles.title}
              />
              <ErrorMessage component={ErrorText} name="topic" />
            </div>

            <div className={styles.fieldContainer}>
              <Label isMute={true}>開催日時</Label>
              <div className={styles.startDate}>
                <Field
                  component={SingleDatePickerField}
                  name="startDate"
                  className={styles.datePicker}
                  isOutsideRange={(date: Date) =>
                    isBefore(date, subDays(new Date(), 1))
                  }
                />
                <Field type="time" name="startTime" className={styles.time} />
              </div>
            </div>

            <div className={styles.fieldContainer}>
              <Label isMute={true}>所要時間</Label>
              <Field
                component={Input}
                className={styles.duration}
                type={"number"}
                name="duration.hours"
                isBlock={false}
                min={0}
                unit="時間"
              />
              <Field
                component={Input}
                className={styles.duration}
                type={"number"}
                name="duration.minutes"
                isBlock={false}
                min={0}
                max={60}
                unit="分"
              />
            </div>

            <div className={styles.fieldContainer}>
              <Label isMute={true}>ミーティングID</Label>
              <Field
                id="common-message-form-meeting-id-option-auto"
                type="radio"
                component={Radio}
                name="meetingIdOption"
                label="自動的に生成"
                value="auto"
                checked={props.values.meetingIdOption === "auto"}
                className={styles.radio}
              />
              <Field
                id="common-message-form-meeting-id-option-pmi"
                type="radio"
                component={Radio}
                name="meetingIdOption"
                label="個人ミーティングID"
                value="pmi"
                checked={props.values.meetingIdOption === "pmi"}
                className={styles.radio}
              />
            </div>

            <div className={styles.fieldContainer}>
              <Label isMute={true}>パスワード</Label>
              <Field
                id="common-message-form-enabled-password"
                component={Checkbox}
                name="enabledPassword"
                disabled={props.values.forcePassword}
              >
                パスワード必須
              </Field>
              {props.values.enabledPassword ? (
                <>
                  <Field
                    name="password"
                    component={Input}
                    isBlock={false}
                    className={styles.password}
                    maxLength={10}
                  />
                  <ErrorMessage component={ErrorText} name="password" />
                </>
              ) : null}
            </div>
          </fieldset>
          <div className={styles.button}>
            <Button
              disabled={!props.isValid || props.isSubmitting}
              size={ButtonSize.Large}
              type="submit"
              onClick={() => {
                props.handleSubmit();
              }}
            >
              ZoomのURLを送信
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Form>
  );
};

const mapPropsToValues = (props: Props): Values => {
  const now = new Date();
  const enabledPassword =
    !!props.operatorProfile.zoomMeetingSetting?.enabledPassword;

  // 0-9 と a-z でつくったランダムな10桁の文字列
  const generatedPassword = generatePassword();

  const defaultValues: Values = {
    content: `${props.defaultContent}`,
    topic: "",
    password: props.operatorProfile.zoomMeetingSetting?.enabledPassword
      ? generatedPassword
      : undefined,
    enabledPassword,
    forcePassword: enabledPassword,
    startDate: now,
    startTime: format(now, "HH:mm"),
    duration: { hours: 1, minutes: 0 },
    meetingIdOption: "auto",
  };

  return defaultValues;
};

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<Props, Values>,
): void => {
  const { props } = formikBag;
  const defaultTopic = `${props.operator.fullName}のZoomミーティング`;
  const startTime = parseDateTime(values.startDate, values.startTime);
  const duration = values.duration.hours * 60 + values.duration.minutes;

  const params = {
    content: values.content,
    topic: values.topic || defaultTopic,
    password: values.password,
    start_time: startTime.toISOString(),
    duration,
    use_pmi: values.meetingIdOption === "pmi",
  };

  const onSuccessCallback = () => {
    props.onClose();
    formikBag.resetForm();
  };

  props.onSubmit(
    params,
    formikBag.setSubmitting,
    formikBag.setErrors,
    onSuccessCallback,
  );
};

type SchemaValues = {
  hours: number;
  minutes: number;
};
const validationSchema = yup.object().shape({
  content: yup
    .string()
    .trim()
    .required("メッセージを入力してください")
    .max(
      CONTENT_MAX_LENGTH,
      `メッセージは${CONTENT_MAX_LENGTH}文字以内にしてください`,
    ),
  topic: yup
    .string()
    .max(
      TOPIC_MAX_LENGTH,
      `タイトルは${TOPIC_MAX_LENGTH}文字以内にしてください`,
    ),
  startTime: yup.string().required("開催日時を入力してください"),
  duration: yup
    .object<Record<keyof SchemaValues, yup.AnyObjectSchema>>()
    .shape({
      hours: yup.number().min(0),
      minutes: yup.number().min(0).max(59),
    }),
  enabledPassword: yup.boolean(),
  forcePassword: yup.boolean(),
  meetingIdOption: yup.string().required(),
});

const validate = (values: Values): Record<string, unknown> => {
  const errors: { password?: string } = {};

  if (values.forcePassword || values.enabledPassword) {
    const { password } = values;

    if (!password) {
      errors.password = "パスワードは必須です";
      return errors;
    }

    const passwordRegex = /^[0-9a-zA-Z@\-_*]+$/;
    if (!passwordRegex.test(password)) {
      errors.password = "パスワードが不正です";
      return errors;
    }
  }

  return errors;
};

const isInitialValid = (): boolean => {
  return true;
};

const formikProps = {
  enableReinitialize: true,
  mapPropsToValues,
  handleSubmit,
  validationSchema,
  isInitialValid,
  validate,
};

const ZoomModal = withFormik<Props, Values>(formikProps)(InnerForm);

export default ZoomModal;
