import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.scss";

type TabLink = {
  pathname: string;
  search?: string;
};

export interface TabsNaviItemProps {
  label: string;
  // NOTE: linkに渡ってくるものはpathnameだけでstringの場合と, location.searchなども含めたobjectの場合がある
  link: TabLink | string;
  active: boolean;
}

interface Props {
  tabs: TabsNaviItemProps[];
  // モバイルのアナリティクスページでタブをスクロールできるようにするためのprops
  scrollable?: boolean;
  border?: "none"; // 暫定
}

const renderLink = (props: TabsNaviItemProps) => {
  if (props.active) {
    return (
      <div
        className={classNames(styles.tabLink, {
          [styles.activeLink]: props.active,
        })}
      >
        {props.label}
      </div>
    );
  } else {
    return (
      <Link
        to={createLinkPath(props.link)}
        className={classNames(styles.tabLink, {
          [styles.activeLink]: props.active,
        })}
      >
        {props.label}
      </Link>
    );
  }
};

const createLinkPath = (link: TabLink | string) => {
  if (typeof link === "string" || !link.search) return link;

  return {
    pathname: link.pathname,
    search: link.search,
  };
};

const TabsNaviItem = (props: TabsNaviItemProps & { scrollable: boolean }) => {
  return (
    <li className={props.scrollable ? styles.scrollableTab : styles.tab}>
      {renderLink(props)}
    </li>
  );
};

export default class TabsNavi extends React.Component<
  Props,
  Record<string, any>
> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <nav
        className={classNames(styles.root, {
          [styles["root--border-none"]]: this.props.border === "none",
        })}
      >
        <ul
          className={
            this.props.scrollable ? styles.scrollableTabs : styles.tabs
          }
        >
          {this.props.tabs.map((tabItem) =>
            this.renderTab(tabItem, !!this.props.scrollable),
          )}
        </ul>
      </nav>
    );
  }

  private renderTab(tabItem: TabsNaviItemProps, scrollable: boolean) {
    let pathname: Location | string = "";

    if (typeof tabItem.link === "object") {
      const link = tabItem.link as Location;

      if (link.pathname) {
        pathname = link.pathname;
      }
    } else {
      pathname = tabItem.link as string;
    }

    return (
      <TabsNaviItem
        {...tabItem}
        scrollable={scrollable}
        key={`tabsNaviItem-${pathname || ""}`}
      />
    );
  }
}
