import { IconCalendar } from "@studyplus/boron-ui";
import classNames from "classnames";
import { DateRange } from "../../general/DynamicDateRangePicker";
import { Dates } from "./Dates";
import styles from "./styles.scss";

type Props = {
  dateRange: DateRange;
  placeholder: string;
  isFocused: boolean;
  onClick: () => void;
  className?: string;
};

// カレンダーの表示/非表示切り替え, 選択中の日付を表示する部分
export const Controller = ({
  dateRange,
  placeholder,
  isFocused,
  onClick,
  className,
}: Props) => {
  const { startDate, endDate } = dateRange;

  return (
    <div
      className={classNames(styles.controller, className, {
        [styles.muted]: !startDate && !endDate,
        "border-blue-400": isFocused,
      })}
      onClick={onClick}
    >
      <IconCalendar
        className={classNames("mr-4 h-2 w-2", {
          "text-gray-600": !isFocused,
          "text-blue-400": isFocused,
        })}
      />
      <Dates dateRange={dateRange} placeholder={placeholder} />
    </div>
  );
};
