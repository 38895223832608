import * as queryString from "query-string";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useLearningMaterialFilterWithLocalStorageContext } from "../../../components/features/LearningMaterialFilter/useLearningMaterialFilterWithLocalStorageContext";
import { notSetTag } from "../../../domains/LearningMaterialTag";
import { useFetchLearningMaterialTags } from "../../../hooks/http/useLearningMaterialTagApi";
import { usePushHistory } from "../../../hooks/usePushHistory";
import SectionInterface from "../../../interfaces/SectionInterface";

export const useLearningMaterialFilter = (section: SectionInterface) => {
  const { tags, isLoading } = useTags(section);
  const {
    searchText,
    searchTextToRequest,
    onChangeSearchText,
    onResetSearchText,
    onSearch,
  } = useSearchText();
  return {
    tags,
    searchText,
    searchTextToRequest,
    onChangeSearchText,
    onResetSearchText,
    onSearch,
    isLoadingTags: isLoading,
  };
};

const useSearchText = () => {
  // searchTextはフォームに表示する値
  // searchTextToRequestはAPIへリクエストする値
  const [searchText, setSearchText] = React.useState<string>("");
  const [searchTextToRequest, setSearchTextToRequest] =
    React.useState<string>("");
  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const onResetSearchText = () => {
    setSearchText("");
  };

  // クエリパラメータの値をフォームの状態に反映
  const { search } = useLocation();
  const name = queryString.parse(search, { arrayFormat: "bracket" }).name as
    | string
    | null;
  React.useEffect(() => {
    setSearchText(name || "");
    setSearchTextToRequest(name || "");
  }, [name]);

  // フォームの状態をクエリパラメータに反映
  const { pushHistory } = usePushHistory();

  // 検索を実行
  const onSearch = () => {
    const nameParam = searchText ? { name: searchText } : { name: undefined };
    pushHistory(nameParam);
  };

  return {
    searchText,
    searchTextToRequest,
    onChangeSearchText,
    onResetSearchText,
    onSearch,
  };
};

const useTags = (section: SectionInterface) => {
  const { setIsTagLoaded } = useLearningMaterialFilterWithLocalStorageContext();

  const { data, isLoading } = useFetchLearningMaterialTags({
    section,
    narrowingCondition: "all",
    shouldGetAllRecords: true,
  });

  React.useEffect(() => {
    if (isLoading) return;

    setIsTagLoaded(true);
  }, [isLoading]);

  const tags = [notSetTag, ...(data || [])];

  return {
    tags: tags,
    isLoading,
  };
};
