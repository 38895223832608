import * as React from "react";
import { useEffect } from "react";

import TabsNavi from "../../components/molecules/TabsNavi";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../helpers/RouterHelper.tsx";
import { AuthenticateRouterProps } from "../../hocs/enhanceAuthenticatedPage.tsx";
import enhanceStudentsPage from "../../hocs/enhanceStudentsPage.tsx";
import { useFetchFollowStatus } from "../../hooks/http/useFetchFollowStatus.tsx";
import { useFetchProfile } from "../../hooks/http/useFetchProfile.ts";
import { useQueryError } from "../../hooks/http/useQueryError.ts";
import OperatorInterface from "../../interfaces/OperatorInterface.ts";
import StudentInterface from "../../interfaces/StudentInterface.ts";
import StudentMessage from "./StudentMessage";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    OutletContextProps {
  student: StudentInterface | null;
  currentOperator: OperatorInterface;
}

const StudentsMessagesPage: React.FC<Props> = (props) => {
  const { studentId } = props.params;
  const { student, location } = props;

  useEffect(() => {
    props.setActiveMenu("message");
  }, []);

  const { followStatus, error } = useFetchFollowStatus({
    studentId,
  });
  useQueryError(error);

  const { data: operatorProfile } = useFetchProfile();

  return (
    <>
      <div style={{ marginTop: "-1.0rem" }}>
        <TabsNavi
          tabs={[
            {
              label: "生徒",
              link: {
                pathname: `/students/${props.params.studentId}/messages`,
                search: location.search,
              },
              active: true,
            },
            {
              label: "保護者",
              link: {
                pathname: `/students/${props.params.studentId}/guardian_messages`,
                search: location.search,
              },
              active: false,
            },
          ]}
        />
      </div>
      {student && followStatus !== null && (
        <StudentMessage
          student={student}
          isFollowing={followStatus}
          operator={props.currentOperator}
          operatorProfile={operatorProfile ?? null}
        />
      )}
    </>
  );
};

const pageInfo = {
  title: "メッセージ",
};

const EnhancedPage = enhanceStudentsPage(StudentsMessagesPage, pageInfo);

export default EnhancedPage;
