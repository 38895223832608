import * as React from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import { initializeSectionTags } from "../../../../actions/common/initializeSectionTags";
import { useStudentFilterContext } from "../../../../components/features/NewStudentFilter/useStudentFilterContext";
import TabsNavi from "../../../../components/molecules/TabsNavi";
import StudentTag, {
  StudentTagFilterInterface,
} from "../../../../domains/StudentTag";
import AppStateInterface from "../../../../interfaces/AppStateInterface";
import SectionInterface from "../../../../interfaces/SectionInterface";
import SectionTagForm from "../SectionTagForm";
import SectionTagList from "../SectionTagList";
import { useIsSubmitting } from "../useIsSubmitting";

interface OuterProps {
  section: SectionInterface;
  tagFilter: StudentTagFilterInterface;
}

interface InnerProps {
  initializeSectionTags: HandleThunkActionCreator<typeof initializeSectionTags>;
}

type Props = InnerProps & OuterProps;

const mapStateToProps = (_: AppStateInterface, ownProps: OuterProps) => ({
  section: ownProps.section,
  tagFilter: ownProps.tagFilter,
});

const actions = {
  initializeSectionTags,
};

const SectionsSettingsTags = (props: Props) => {
  const studentFilterContext = useStudentFilterContext();
  const [tagList, setTagList] = React.useState<StudentTag[]>(
    props.tagFilter.tags.filter((tag) => tag.type === "tag_ids"),
  );
  // 新規作成したタグは先頭に表示するためリストとは別に保持
  const [createdTagList, setCreatedTagList] = React.useState<StudentTag[]>([]);
  // FIXME: tagFilter.tagsの取得をreact-query化したら、react-queryのキャッシュを更新するように変更する
  const onSubmitCallback = (
    type: "create" | "update" | "delete",
    targetTag: StudentTag,
  ) => {
    props.initializeSectionTags(props.section.id, studentFilterContext);

    if (type === "delete") {
      // タグ削除はリストから削除
      const newTagList = tagList.filter((tag) => tag.id !== targetTag.id);
      setTagList(newTagList);
      const newCreatedTagList = createdTagList.filter(
        (tag) => tag.id !== targetTag.id,
      );
      setCreatedTagList(newCreatedTagList);
    } else if (type === "create") {
      // 新規タグは新規タグリストの先頭にする
      setCreatedTagList([targetTag, ...createdTagList]);
    } else if (type === "update") {
      // 更新タグは入れ替え
      const newTagList = tagList.map((tag) =>
        tag.id === targetTag.id ? targetTag : tag,
      );
      setTagList(newTagList);
      const newCreatedTagList = createdTagList.map((tag) =>
        tag.id === targetTag.id ? targetTag : tag,
      );
      setCreatedTagList(newCreatedTagList);
    }
  };

  React.useEffect(() => {
    const ids = tagList.map((tag) => tag.id);
    const newTagList = props.tagFilter.tags.filter(
      (tag) =>
        tag.type === "tag_ids" &&
        // 作成したタグは別リストで管理しているため含めない
        !createdTagList.some((createdTag) => createdTag.id === tag.id),
    );
    // タグ更新時に順番が変わることがあるので、変わらないようにソートする
    const sortedTagList = newTagList.sort((a, b) => {
      return ids.indexOf(a.id) - ids.indexOf(b.id);
    });
    setTagList(sortedTagList);
  }, [props.tagFilter.tags]);

  const isSubmitting = useIsSubmitting({ sectionId: props.section.id });

  return (
    <React.Fragment>
      <TabsNavi
        tabs={[
          {
            label: "タグ",
            link: "#",
            active: true,
          },
        ]}
      />
      <SectionTagForm
        section={props.section}
        submitting={isSubmitting}
        onSubmitCallback={onSubmitCallback}
      />
      <SectionTagList
        section={props.section}
        tags={[...createdTagList, ...tagList]}
        submitting={isSubmitting}
        onSubmitCallback={onSubmitCallback}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps, actions)(SectionsSettingsTags);
