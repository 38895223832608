import { Field, Form, FormikProps, withFormik } from "formik";
import * as React from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import * as yup from "yup";

import { Button } from "@studyplus/boron-ui";
import { postKarteShare, toggleModal } from "../../../actions/karte";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import KarteInterface from "../../../interfaces/KarteInterface";
import Icon from "../../atoms/Icon";
import Label from "../../atoms/Label";
import { Modal } from "../../atoms/Modal";
import Textarea from "../../atoms/Textarea";
import styles from "./styles.scss";

import TimeHelper from "../../../helpers/TimeHelper";

const MAX_MESSAGE_LENGTH = 2000;

interface DispatchProps {
  postKarteShare: HandleThunkActionCreator<typeof postKarteShare>;
  toggleModal: HandleThunkActionCreator<typeof toggleModal>;
}

interface OwnProps {
  karte: KarteInterface;
}

type Props = DispatchProps & OwnProps;

interface Values {
  message: string | null;
}

const PureKarteShareButton: React.FC<Props & FormikProps<Values>> = (props) => {
  if (props.karte.student.lineConnectionStatus !== "connected") {
    return null;
  }

  const openModal = () => {
    props.toggleModal(props.karte.id, true);
  };
  const closeModal = () => {
    props.toggleModal(props.karte.id, false);
  };
  const messageLength = Array.from(props.values.message || "").length;
  const karteCommentLength = Array.from(props.karte.comment || "").length;

  const date = TimeHelper.japaneseFormatFullDate(props.karte.createdAt);
  const placeholder = `${props.karte.student.fullName}さんの${date}のカルテをお送りします。`;

  return (
    <>
      <button className={styles.button} onClick={openModal}>
        <Icon name="icon-line" className={styles.lineIcon} />
      </button>
      <Modal
        isOpen={props.karte.isOpenShareModal}
        onRequestClose={closeModal}
        className={styles.modal}
      >
        <Modal.Header onClose={closeModal}>
          <h2>{props.karte.student.fullName}のカルテを送信</h2>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className={styles.messageLabelLine}>
              <Label className={styles.messageLabel}>メッセージ</Label>
              <div className={styles.messageLength}>
                {messageLength}/{MAX_MESSAGE_LENGTH}字
              </div>
            </div>
            <Field
              name="message"
              component={Textarea}
              placeholder={placeholder}
              className={styles.message}
            />
            <div className={styles.messageLabelLine}>
              <Label className={styles.messageLabel}>カルテ内容</Label>
              <div className={styles.messageLength}>
                {karteCommentLength}/{MAX_MESSAGE_LENGTH}字
              </div>
            </div>
            <Textarea
              className={styles.karteComment}
              placeholder={placeholder}
              value={props.karte.comment}
              readOnly={true}
            />
            <div className={styles.notice}>
              ※内容の変更は、カルテの「編集」から変更できます。
            </div>
            <div className={styles.notice}>
              ※カルテの添付画像・添付ファイルは、送信されません。カルテ内容のテキストのみ送信されます。
            </div>
            <div className={styles.submitButton}>
              <Button
                disabled={props.karte.submittingShare || !props.isValid}
                type="submit"
              >
                送信
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const KarteShareButton = withFormik<Props, Values>({
  handleSubmit: (values, formikBag) => {
    const { karte } = formikBag.props;
    let message = values.message || "";
    if (Array.from(message).length === 0) {
      const date = TimeHelper.japaneseFormatFullDate(karte.createdAt);
      message = `${karte.student.fullName}さんの${date}のカルテをお送りします。`;
    }
    formikBag.props.postKarteShare(karte.student.id, karte.id, message);
  },
  validationSchema: yup.object().shape({
    message: yup.string().trim().min(0).max(MAX_MESSAGE_LENGTH),
  }),
  isInitialValid: true,
})(PureKarteShareButton);

const mapDispatchToProps = {
  postKarteShare,
  toggleModal,
};

export default connect<unknown, DispatchProps, OwnProps, AppStateInterface>(
  null,
  mapDispatchToProps,
)(KarteShareButton);
