import * as React from "react";
import { ContentCourse } from "../../../../domains/ContentCourse";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { HTTP_ERROR_MESSAGE } from "../../../../reducers";
import { useDistributeTagSelectboxOptions } from "./DistributeTagSelectbox";
import { useDistributeTagStudents } from "./DistributeTagStudentsTable";
import { useRegisterContentCourseTag } from "./useRegisterContentCourseTag";

export type UseSelectDistributeTagModalProps = {
  sectionId: string;
  contentCourse: ContentCourse;
  onClose: () => void;
};

export const useSelectDistributeTagModal = ({
  sectionId,
  contentCourse,
  onClose,
}: UseSelectDistributeTagModalProps) => {
  const currentTagId = contentCourse.tag?.id ?? null;
  const [selectedTagId, setSelectedTagId] = React.useState(currentTagId);
  const flashMessage = useFlashMessage();
  // タグ更新hooks
  const { mutate, isPending: submitting } = useRegisterContentCourseTag({
    sectionId,
    contentCourseId: contentCourse.id,
    onError: () => flashMessage.showErrorMessage(HTTP_ERROR_MESSAGE),
    onSuccess: () => {
      onClose();
      flashMessage.showSuccessMessage("更新しました");
    },
  });
  // タグ一覧取得hooks
  const tagOptionsResult = useDistributeTagSelectboxOptions({
    sectionId,
  });
  // タグに紐つく生徒を取得hooks
  const studentsResult = useDistributeTagStudents({
    tagId: selectedTagId,
    sectionId,
  });

  // 初回読み込み時
  // すでに設定済みのタグがあり -> 生徒一覧とタグ一覧を両方終わるまで待つ
  // タグが未設定 -> 生徒一覧のリクエストはしないので、タグ一覧のみ待つ
  const doneInitialLoading = useDoneInitialLoading({
    currentTagId: selectedTagId,
    isTagsLoading: !tagOptionsResult.completeFetchAlltags,
    isStudentsLoading: studentsResult.isLoading,
  });

  //// 各コンポーネントへのprops 準備  ////
  const initialLoaderProps = {
    loading: !doneInitialLoading,
  };

  const studentTableLoaderProps = {
    loading: studentsResult.isLoading,
  };

  const distributeTagSelectorProps = {
    hidden: !doneInitialLoading,
  };

  const tagSelectboxProps = {
    value: selectedTagId,
    onChange: (tagId: string | null) => {
      setSelectedTagId(tagId);
    },
    options: tagOptionsResult.options,
  };

  const tagStudentsTableProps = {
    students: studentsResult.data ?? null,
    hidden: studentsResult.isLoading,
    onFetchNextPage() {
      studentsResult.fetchNextPage();
    },
    isFetching: studentsResult.isFetching,
  };

  const submitButtonProps = {
    onClick: () => {
      mutate({ tag: tagOptionsResult.getTagFromTagId(selectedTagId) ?? null });
    },
    isLoading: submitting,
    disabled: currentTagId === selectedTagId,
  };

  return {
    initialLoaderProps,
    tagSelectboxProps,
    tagStudentsTableProps,
    submitButtonProps,
    distributeTagSelectorProps,
    studentTableLoaderProps,
  };
};

type UseInitialLoadingProps = {
  currentTagId: string | null;
  isTagsLoading: boolean;
  isStudentsLoading: boolean;
};

const useDoneInitialLoading = (props: UseInitialLoadingProps) => {
  const isDoneRef = React.useRef<boolean>(false);
  if (isDoneRef.current) {
    return isDoneRef.current;
  }
  if (props.currentTagId === null && !props.isTagsLoading) {
    isDoneRef.current = true;
  }
  if (props.currentTagId && !props.isStudentsLoading && !props.isTagsLoading) {
    isDoneRef.current = true;
  }
  return isDoneRef.current;
};
