import endOfMonth from "date-fns/endOfMonth";
import startOfDay from "date-fns/startOfDay";
import startOfISOWeek from "date-fns/startOfISOWeek";
import subDays from "date-fns/subDays";
import { InitializeFiltersByQueryPayload } from "../../actions/common/filters";
import FiltersHelper from "../../helpers/FiltersHelper";
import DateRangeFilterInterface from "../../interfaces/DateRangeFilterInterface";
import FiltersInterface, {
  FiltersQueryInterface,
} from "../../interfaces/FiltersInterface";

const defaultDateRangeFilter = (): DateRangeFilterInterface => {
  return {
    startDate: startOfDay(subDays(new Date(), 6)),
    endDate: startOfDay(new Date()),
  };
};

const defaultFilters: FiltersInterface[] = [];
const defaultFilter = (sectionId: string): FiltersInterface => ({
  sectionId,
  dateRangeFilter: defaultDateRangeFilter(),
  term: "daily",
});

export const buildDateRangeFilter = (
  filtersQuery: FiltersQueryInterface,
): DateRangeFilterInterface => {
  let nextDateRangeFilter = defaultDateRangeFilter();
  let endDate = nextDateRangeFilter.endDate;

  // set endDate
  if (filtersQuery.term) {
    endDate =
      filtersQuery.dateRangeFilter && filtersQuery.dateRangeFilter.endDate
        ? filtersQuery.dateRangeFilter.endDate
        : nextDateRangeFilter.endDate;
  }
  if (filtersQuery.term === "weekly") {
    endDate = startOfISOWeek(endDate);
  } else if (filtersQuery.term === "monthly") {
    endDate = endOfMonth(endDate);
  }

  // set startDate
  const startDate = FiltersHelper.calculateStartDate(
    endDate,
    filtersQuery.term,
  );
  nextDateRangeFilter = {
    startDate,
    endDate,
  };

  return nextDateRangeFilter;
};

export const filters = (
  state: FiltersInterface[] = defaultFilters,
  action: ReduxActions.Action<InitializeFiltersByQueryPayload>,
): FiltersInterface[] => {
  switch (action.type) {
    case "INITIALIZE_FILTERS_BY_QUERY":
    case "INITIALIZE_TAG_FILTER":
      if (
        state.some(
          (filter: FiltersInterface) =>
            filter.sectionId === action.payload.sectionId,
        )
      ) {
        return state.map((filter: FiltersInterface) =>
          filterReducer(filter, action),
        );
      } else {
        return [...state, defaultFilter(action.payload.sectionId)];
      }
    case "INITIALIZE_TAG_FILTER_ERROR":
    default: {
      return state;
    }
  }
};

const filterReducer = (
  filter: FiltersInterface,
  action: ReduxActions.Action<InitializeFiltersByQueryPayload>,
): FiltersInterface => {
  // 校舎が異なる場合は処理を行わない
  if (filter.sectionId !== action.payload.sectionId) return filter;

  const filtersQuery = FiltersHelper.queryToFilters(
    action.payload.query,
    action.payload.allTags,
  );
  const nextDateRangeFilter = buildDateRangeFilter(filtersQuery);

  // tagFilterは別reducerでセットするのでここでは捨てる
  const { ...nextFilter } = filtersQuery;

  return {
    ...filter,
    ...nextFilter,
    dateRangeFilter: nextDateRangeFilter,
  };
};
