import * as React from "react";
import {
  MessageFormStateInterface,
  MessageFormValuesInterface,
} from "../../../interfaces/MessageInterface.ts";

const initialValues: MessageFormValuesInterface = { content: "", file: null };
const initialFormState: MessageFormStateInterface = {
  values: initialValues,
  apiErrors: [],
  submitting: false,
};

export const useFormStates = (props: { studentId: string }) => {
  // StudentとMessageThreadは1対1の関係
  // MessageThreadからStudentは辿れるがその逆はできないので、studentIdをキーにする
  const [formStates, setFormStates] = React.useState<{
    [studentId: string]: MessageFormStateInterface;
  }>({});

  const setFormState = (state: Partial<MessageFormStateInterface>) => {
    setFormStates((prevFormStates) => {
      if (!props.studentId) {
        return prevFormStates;
      }

      return {
        ...prevFormStates,
        [props.studentId]: {
          ...initialFormState,
          ...prevFormStates[props.studentId],
          ...state,
        },
      };
    });
  };

  const formState: MessageFormStateInterface | undefined =
    props.studentId in formStates
      ? formStates[props.studentId]
      : initialFormState;

  const setValues = (values: Partial<MessageFormValuesInterface>) =>
    setFormState({
      values: { ...initialValues, ...formState?.values, ...values },
    });

  const handleChangeContent = (content: string) => {
    setValues({ content });
  };

  const handleChangeFile = (file: File | null) => {
    setValues({ file });
  };

  const resetValues = () => setFormState({ values: initialValues });

  return {
    formState,
    setFormState,
    handleChangeFile,
    handleChangeContent,
    resetValues,
  };
};
