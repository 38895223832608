import { useQuery } from "@tanstack/react-query";
import { boronClient } from "../../api";
import { createError } from "../../errors";
import { paths } from "../../lib/api/v1";

const path = "/api/v1/operators/{operator_hashid}/admin_sections";

type ResponseBody =
  paths[typeof path]["get"]["responses"]["200"]["content"]["application/json"];

export const useFetchAdminSections = (operatorId: string) => {
  const { data, isLoading } = useQuery<ResponseBody>({
    queryKey: ["operators", operatorId, "adminSections"],
    queryFn: async () => {
      const { response } = await boronClient.GET(path, {
        params: {
          path: {
            operator_hashid: operatorId,
          },
        },
      });

      if (response.ok) {
        return response.json();
      }

      throw createError(response);
    },
  });

  return {
    data,
    isLoading,
  };
};
