import { HTTPErrors, matchHttpError } from "../../errors";
import { HTTP_ERROR_MESSAGE } from "../../reducers/";
import { useErrorDispatcher } from "../useErrorDispatcher";
import { useFlashMessage } from "../useFlashMessage";

// 参照系のエラーの共通処理
// 参照系のエラー時は独自のエラー処理やメッセージを送る事することが少ないので
export const useQueryError = (error?: HTTPErrors | null) => {
  const {
    sendUnauthorized,
    sendNotFound,
    sendForbidden,
    sendSteakEmergencyError,
    sendMaintenanceError,
  } = useErrorDispatcher();
  const { showErrorMessage } = useFlashMessage();
  if (!error) {
    return;
  }
  matchHttpError(error, {
    caseUnauthorized: sendUnauthorized,
    caseNotFound: sendNotFound,
    caseForbiden: sendForbidden,
    caseSteakEmergencyError: sendSteakEmergencyError,
    caseMaintenanceError: sendMaintenanceError,
    caseOthers: () => showErrorMessage(HTTP_ERROR_MESSAGE),
  });
};
