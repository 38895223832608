import { boronClient } from "../../../api.ts";
import { FSHTTPError } from "../../../errors.ts";
import { useBoronMutation } from "../../../hooks/http/useBoronMutation.ts";
import { useUpdateMessageThreadLatestMessage } from "../../../hooks/http/useFetchMessageThreads.ts";
import { useUpdateDirectMessageCache } from "../../../hooks/http/useFetchStudentMessages.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";

export const useDeleteMessage = ({ sectionId }: { sectionId: string }) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { updateMessageThreadLatestMessageByStudentId } =
    useUpdateMessageThreadLatestMessage({
      sectionId,
    });

  const { updateDirectMessageCache } = useUpdateDirectMessageCache();

  const { mutate } = useBoronMutation(
    async ({
      studentId,
      messageId,
    }: {
      studentId: string;
      messageId: string;
    }) =>
      boronClient.DELETE(
        "/api/v1/students/{student_id}/direct_messages/{message_id}/delete",
        {
          params: {
            path: { student_id: studentId, message_id: messageId },
          },
        },
      ),
    {
      onSuccess: (response, variables) => {
        updateDirectMessageCache(variables.studentId, response.message);
        updateMessageThreadLatestMessageByStudentId(
          variables.studentId,
          (messageThread) =>
            messageThread.latestMessage?.id === response.message.id
              ? { ...messageThread, latestMessage: response.message }
              : messageThread,
        );
        showSuccessMessage("送信したメッセージを削除しました");
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage("削除できませんでした");
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    },
  );

  return (studentId: string, messageId: string) => {
    mutate({ studentId, messageId });
  };
};
