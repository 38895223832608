import * as React from "react";
import { useNavigate } from "react-router-dom";
import BackLink from "../../../components/atoms/BackLink";
import Loader from "../../../components/atoms/Loader";
import StaffBoardPostItem from "../../../components/features/StaffBoardPostItem";
import { FSHTTPError } from "../../../errors.ts";
import { useDeleteStaffBoardPost } from "../../../hooks/http/useDeleteStaffBoardPost.ts";
import { useFetchStaffBoardPost } from "../../../hooks/http/useFetchStaffBoardPost.ts";
import { useQueryError } from "../../../hooks/http/useQueryError.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import OperatorInterface from "../../../interfaces/OperatorInterface.ts";
import SectionInterface from "../../../interfaces/SectionInterface.ts";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";

type Props = {
  section: SectionInterface;
  currentOperator: OperatorInterface;
  staffBoardPostId: string;
};

const SectionStaffBoardPost = (props: Props) => {
  const { section, currentOperator, staffBoardPostId } = props;

  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const navigate = useNavigate();

  const { isPending, data, error } = useFetchStaffBoardPost({
    sectionId: section.id,
    staffBoardPostId,
  });
  useQueryError(error);

  const { mutate: deletePost } = useDeleteStaffBoardPost({
    sectionId: section.id,
  });

  const handleDeletePost = () => {
    deletePost(
      { staffBoardPostId },
      {
        onSuccess: () => {
          showSuccessMessage("投稿を削除しました");
          navigate(`/sections/${section.id}/staff_board_posts`);
        },
        onError: (error) => {
          if (error instanceof FSHTTPError) {
            showErrorMessage("投稿を削除できませんでした");
          } else {
            showErrorMessage(HTTP_ERROR_MESSAGE);
          }
        },
      },
    );
  };

  return (
    <React.Fragment>
      <BackLink>戻る</BackLink>
      {isPending || !data ? (
        <Loader loading />
      ) : (
        <StaffBoardPostItem
          section={section}
          currentOperator={currentOperator}
          staffBoardPost={data}
          deletePost={handleDeletePost}
        />
      )}
    </React.Fragment>
  );
};

export default SectionStaffBoardPost;
