import { Button, IconHelpSupport, IconWarning } from "@studyplus/boron-ui";
import { Link } from "react-router-dom";
import ErrorText from "../../../components/atoms/ErrorText";
import Input from "../../../components/atoms/Input";
import Label from "../../../components/atoms/Label";
import { SingleDatePickerField } from "../../../components/atoms/SingleDatePickerField";
import { ExaminationClassificationSelect } from "../../../components/features/ExaminationClassificationSelect/ExaminationClassificationSelect";
import { ExaminationOrganizerSelect } from "../../../components/features/ExaminationOrganizerSelect/ExaminationOrganizerSelect";
import { ExaminationResultFileAttachmentItem } from "../../../components/features/ExaminationResultFileAttachmentItem";
import { ExaminationSelect } from "../../../components/features/ExaminationSelect/ExaminationSelect";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../../components/general/Popover/Popover";
import { Examination } from "../../../domains/Examination";
import { usePrompt } from "../../../hooks/usePrompt";
import styles from "./ExaminationResultForm.scss";
import {
  ResultValue,
  useExaminationResultForm,
} from "./useExaminationResultForm";

type Props = {
  studentId: string;
  // 新規登録と更新で微妙に挙動を変えるためpropsで受け取っている
  form: ReturnType<typeof useExaminationResultForm>;

  // 得点以降の入力を不可にするかのフラグ
  // 新規登録の際、既存の成績登録があったらできないようにするため
  resultDisabled: boolean;

  // 編集の際は試験の選び直しはできないようにするため
  isExaminationSelectable: boolean;
};

export const ExaminationResultForm = ({
  studentId,
  form,
  resultDisabled,
  isExaminationSelectable,
}: Props) => {
  usePrompt(
    "このページから移動しますか？入力した内容は保存されません。",
    form.dirty && !form.isSubmitting,
  );

  return (
    <form onSubmit={form.handleSubmit} noValidate>
      <p>1. 試験名を選択してください。</p>

      <div className="mt-3 flex gap-4">
        <div className="w-[32.4rem]">
          <Label>主催者</Label>
          <ExaminationOrganizerSelect
            onChange={(value: string | null) => {
              form.setFieldValue("organizerId", value);
            }}
            hasError={
              Boolean(form.errors.organizerId) && form.touched.organizerId
            }
            value={form.values.organizerId ?? undefined}
            isDisabled={!isExaminationSelectable}
          />
          {form.errors.organizerId && form.touched.organizerId && (
            <ErrorText>{form.errors.organizerId}</ErrorText>
          )}
        </div>

        <div className="w-[38.4rem]">
          <Label>試験の種類</Label>
          <ExaminationClassificationSelect
            organizerId={form.values.organizerId}
            onChange={(value: string | null) => {
              form.setFieldValue("classificationId", value);
            }}
            hasError={Boolean(
              form.errors.classificationId && form.touched.classificationId,
            )}
            isDisabled={!isExaminationSelectable}
            value={form.values.classificationId ?? null}
          />
          {form.errors.classificationId && form.touched.classificationId && (
            <ErrorText>{form.errors.classificationId}</ErrorText>
          )}
        </div>
      </div>

      <div className="mb-2 mt-6 flex items-start">
        <Label noMargin>試験名</Label>
        <PopoverProvider>
          <Popover>
            <PopoverTrigger>
              <IconHelpSupport className="h-[1.4rem] w-[1.4rem] text-gray-800" />
            </PopoverTrigger>

            <PopoverPortal>
              <PopoverContent side="top">
                <div className="text-center">
                  「試験名」はリストにあるもののみ
                  <br />
                  登録が可能です
                </div>
              </PopoverContent>
            </PopoverPortal>
          </Popover>
        </PopoverProvider>
      </div>
      <ExaminationSelect
        organizerId={form.values.organizerId}
        classificationId={form.values.classificationId}
        onChange={(value: Examination | undefined) => {
          form.setFieldValue("examination", value ?? null);
        }}
        hasError={Boolean(form.errors.examination && form.touched.examination)}
        isDisabled={!isExaminationSelectable}
        value={form.values.examination?.id ?? ""}
      />
      {form.errors.examination && form.touched.examination && (
        <ErrorText>{form.errors.examination}</ErrorText>
      )}
      {/* 新規登録の場合、試験を選択した時点で既存の成績の有無をチェック。ある場合は編集画面へ誘導する */}
      {resultDisabled && form.values.examination && (
        <p className="mt-7 flex items-center gap-1 rounded-sm bg-gray-100 px-7 py-8">
          <IconWarning className="text-yellow-400" />
          <div>
            選択した成績記録はすでに存在します。
            <Link
              className="text-blue-400 underline"
              to={`/students/${studentId}/analytics/examinations/${form.values.examination.id}/result/edit`}
            >
              登録済みの成績を編集する場合はこちら
            </Link>
          </div>
        </p>
      )}

      {/*  成績入力 */}
      <div className="mt-9">
        2. 科目ごとに成績を入力してください。
        <h3>成績記録入力</h3>
        <p>
          ※画像の登録はJPEG/PNG/PDFのファイル形式で最大5つ、一度にアップロードできる容量は100MBまでです。
        </p>
        <div
          className={`flex flex-wrap gap-4 sm:mt-8 sm:flex-nowrap ${
            // 添付ファイルがある場合はアイテムの高さを揃えない（ビューワー部分の表示を広げない）
            form.values.resultAttachments.length > 0 ? "lg:items-start" : ""
          }`}
        >
          <div className="box-border max-w-full rounded bg-gray-100 p-7 md:w-[40rem] lg:w-[48rem]">
            <div className="mb-2 flex items-center">
              <label>受験日</label>
              <PopoverProvider>
                <Popover>
                  <PopoverTrigger>
                    <IconHelpSupport className="h-[1.4rem] w-[1.4rem] text-gray-800" />
                  </PopoverTrigger>

                  <PopoverPortal>
                    <PopoverContent side="top">
                      <div className="text-center">
                        成績の一覧を並び替える際に
                        <br />
                        参照されます
                      </div>
                    </PopoverContent>
                  </PopoverPortal>
                </Popover>
              </PopoverProvider>
            </div>
            <SingleDatePickerField
              value={!resultDisabled ? form.values.examinedOn : undefined}
              disabled={resultDisabled}
              onDateChange={(date) => {
                form.setFieldValue("examinedOn", date);
              }}
              className={"w-80 bg-white"}
            />
            <div className="mt-5 flex flex-wrap gap-4 text-md">
              <div className="max-w-1/2 w-[6.4rem] text-center sm:w-auto lg:w-80">
                <label>科目名</label>
              </div>
              <div className="flex flex-1 gap-4">
                <label className="w-[6rem] flex-1 text-center">得点</label>
                <label className="w-[6rem] flex-1 text-center">配点</label>
                <label className="w-[6rem] flex-1 text-center">偏差値</label>
              </div>
            </div>

            {/* 総合 */}
            <ResultRow
              form={form}
              result={form.values.totalResult}
              registerable={!resultDisabled}
            />

            {/* 科目別 */}
            {/* 新規登録の場合はExaminationマスタから取得した科目ごとに */}
            {/* 更新の場合はExaminationResultから取得した科目に成績を添えて */}
            {Object.values(form.values.subjectResults).map(
              (result: ResultValue) => (
                <ResultRow
                  form={form}
                  result={result}
                  key={result.id}
                  registerable={!resultDisabled}
                />
              ),
            )}
          </div>

          <div className="sticky top-0 mt-9 flex sm:mt-0 md:max-w-4xl">
            <ExaminationResultFileAttachmentItem
              disabledFileAttachmentButton={resultDisabled}
              onChange={form.handleChangeAttachments}
              files={form.values.resultAttachments.map(
                (attachment) => attachment.file,
              )}
              hasError={form.resultAttachmentErrorTexts.length > 0}
              errorTexts={form.resultAttachmentErrorTexts}
            />
          </div>
        </div>
        <div className="mt-9 flex justify-end">
          <Button
            type="submit"
            isLoading={form.isSubmitting}
            disabled={
              form.isSubmitting ||
              // 選択した試験に既存成績がある場合はdisabledにしているが、
              // 試験選択前は、とりあえず押してエラーに気づけるようにボタンを活性化している
              (resultDisabled && form.values.examination !== null) ||
              !form.isValid
            }
          >
            登録
          </Button>
        </div>
      </div>
    </form>
  );
};

type ResultRowProps = {
  form: ReturnType<typeof useExaminationResultForm>;
  result: ResultValue;
  registerable: boolean;
};

const ResultRow = ({ form, result, registerable }: ResultRowProps) => {
  const errors =
    result.id === "total"
      ? form.errors.totalResult
      : form.errors.subjectResults
        ? form.errors.subjectResults[result.id]
        : {};

  const touched =
    result.id === "total"
      ? form.touched.totalResult
      : form.touched.subjectResults
        ? form.touched.subjectResults[result.id]
        : {};

  return (
    <div className="mt-3 flex flex-nowrap items-start gap-4">
      <div className="box-border flex min-h-[4rem] w-[6.4rem] items-center rounded border border-solid border-gray-400 pl-4 sm:max-w-full lg:w-80">
        {registerable && result.name}
      </div>
      <div className="flex-1">
        <div className="flex w-full flex-1 gap-4">
          <Input
            className={`${styles.invisibleSpinButton} h-7 w-[6rem] flex-1 p-0 text-center sm:p-7`}
            value={result.point ?? ""}
            name={
              result.id === "total"
                ? "totalResult.point"
                : `subjectResults.${result.id}.point`
            }
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            disabled={!registerable}
            hasError={Boolean(touched?.point) && Boolean(errors?.point)}
            type="text"
          />

          <Input
            className={`${styles.invisibleSpinButton} h-7 w-[6rem] flex-1 p-0 text-center sm:p-7`}
            value={result.allocationOfMarks ?? ""}
            name={
              result.id === "total"
                ? "totalResult.allocationOfMarks"
                : `subjectResults.${result.id}.allocationOfMarks`
            }
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            disabled={!registerable}
            hasError={
              Boolean(touched?.allocationOfMarks) &&
              Boolean(errors?.allocationOfMarks)
            }
            type="text"
          />
          <Input
            className={`${styles.invisibleSpinButton} h-7 w-[6rem] flex-1 p-0 text-center sm:p-7`}
            value={result.deviation ?? ""}
            name={
              result.id === "total"
                ? "totalResult.deviation"
                : `subjectResults.${result.id}.deviation`
            }
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            disabled={!registerable}
            hasError={Boolean(touched?.deviation) && Boolean(errors?.deviation)}
            type="text"
          />
        </div>

        {touched?.point && errors?.point && (
          <ErrorText>{errors?.point}</ErrorText>
        )}
        {touched?.allocationOfMarks && errors?.allocationOfMarks && (
          <ErrorText>{errors?.allocationOfMarks}</ErrorText>
        )}
        {touched?.deviation && errors?.deviation && (
          <ErrorText>{errors?.deviation}</ErrorText>
        )}
      </div>
    </div>
  );
};
