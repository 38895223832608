import * as React from "react";
import { connect } from "react-redux";
import { initializeFiltersByQuery } from "../actions/common/filters";
import FiltersHelper from "../helpers/FiltersHelper";
import { WithRouterProps } from "../helpers/RouterHelper";
import {
  AuthenticateRouterProps,
  AuthenticatedPageCallbacks,
  AuthenticatedPageProps,
} from "./enhanceAuthenticatedPage";

export interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    AuthenticatedPageProps,
    AuthenticatedPageCallbacks {
  initializeFiltersByQuery: typeof initializeFiltersByQuery;
}

const actions = {
  initializeFiltersByQuery,
};

const withFiltersInitialization = (
  WrappedComponent: React.ComponentClass<any, any> | React.FC<any>,
) => {
  class Page extends React.Component<Props, Record<string, never>> {
    constructor(props: Props) {
      super(props);
      this.initializeFilters();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }

    private initializeFilters() {
      const { currentSection, location } = this.props;
      const query = location.search;
      const queryRegexp = /(tag_ids|smart_tag|job|status)/;

      if (currentSection.data && query && queryRegexp.test(query)) {
        const sectionId = currentSection.data.id;
        this.props.initializeFiltersByQuery({
          sectionId,
          allTags: FiltersHelper.getAllTags(
            this.props.studentFilterContext.currentFilter,
          ),
          query,
          force: true,
        });
      }
    }
  }

  return connect(null, actions)(Page);
};

export default withFiltersInitialization;
