import * as React from "react";
import { SearchInput } from "../../general/SearchInput/index";
import styles from "./styles.scss";

type BaseFields = { id: string; name: string };
type Props<T extends BaseFields> = {
  tags: T[];
  selectedTags: T[];
  renderTag: (tag: T) => React.ReactNode;
  children?: React.ReactNode;
};

export const TagSelector = <T extends BaseFields>(props: Props<T>) => {
  const [searchedTags, setSearchedTags] = React.useState<ReadonlyArray<T>>(
    props.tags,
  );

  React.useEffect(() => {
    setSearchedTags(props.tags);
  }, [props.tags.length]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchedTags(props.tags.filter((tag) => tag.name.includes(value)));
  };

  return (
    <div className={styles.root} data-testid="TagSelector">
      <SearchInput
        type="text"
        placeholder="タグ検索"
        onChange={handleChange}
        className={styles.input}
        isBlock
      />
      <div className={styles.tagsWrapper}>
        {searchedTags.map((tag) => (
          <span
            key={`tag-addition-tags-${tag.id}`}
            className={styles.tagButton}
          >
            {props.renderTag(tag)}
          </span>
        ))}
      </div>
      {props.children}
    </div>
  );
};
