import * as React from "react";

export const useStudentEditDropdownLink = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    handleOpen,
    handleClose,
  };
};
