import * as React from "react";
import Loader from "../../../../components/atoms/Loader";
import { StudyScheduleProgressHeader } from "../../../../components/organisms/StudyScheduleProgressHeader";
import { LearningMaterialProgress } from "../../../../domains/LearningMaterialProgress";
import StudentInterface from "../../../../interfaces/StudentInterface";
import StudentStudyScheduleList from "../StudentStudyScheduleList/index";
import { useStudentStudySchedules } from "../useStudentStudySchedules";
import styles from "./styles.scss";

interface Props {
  student: StudentInterface;
  data?: LearningMaterialProgress[];
  isLoading: boolean;
}

const StudentStudyScheduleIndex = ({
  student,
  data,
  isLoading,
}: Props): React.ReactElement | null => {
  const { startDate, endDate, type, onChangeDateParams } =
    useStudentStudySchedules();

  return (
    <div className={styles.root}>
      <StudyScheduleProgressHeader
        startDate={startDate}
        endDate={endDate}
        type={type}
        onChangeDateParams={onChangeDateParams}
      />

      {data ? (
        <StudentStudyScheduleList
          progresses={data}
          student={student}
          startDate={startDate}
          endDate={endDate}
        />
      ) : isLoading ? (
        <Loader loading={isLoading} />
      ) : null}
    </div>
  );
};

export default StudentStudyScheduleIndex;
