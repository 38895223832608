import { Button } from "@studyplus/boron-ui";
import classnames from "classnames";
import isBefore from "date-fns/isBefore";
import subDays from "date-fns/subDays";
import * as React from "react";
import { BlockRow } from "../../../../components/atoms/BlockRow";
import Checkbox from "../../../../components/atoms/Checkbox";
import SelectField from "../../../../components/atoms/DeprecatedSelectField";
// ファイル内のFlexは共有のFlexができる前のものなので、現時点で必要なところを置き換え
import { Flex as Flexbox } from "../../../../components/atoms/Flex";
import Icon from "../../../../components/atoms/Icon/index";
import Input from "../../../../components/atoms/Input";
import { Modal } from "../../../../components/atoms/Modal";
import { SingleDatePickerField } from "../../../../components/atoms/SingleDatePickerField/index";
import Textarea from "../../../../components/atoms/Textarea";
import { TimeInput } from "../../../../components/atoms/TimeInput";
import {
  FieldLayoutMultiRows,
  FieldLayoutOneRow,
  FormFooter,
  ScheduleDatePicker,
  ScheduleTimeFieldLayout,
  ScheduleTimeHStack,
  StackForm,
  StackItem,
  StudyPlanAmountInput,
  StudyPlanDurationInput,
  StudyPlanFieldStack,
  StudyPlanUnitTextWrapper,
} from "../../../../components/features/Schedule/FormParts";
import { isExceptionSchedule } from "../../../../domains/Schedule";
import styles from "../styles.scss";
import { RecurrenceSelect } from "./RecurrenceSelect";
import { UseScheduleFormProps, useScheduleForm } from "./useScheduleForm";

type Props = UseScheduleFormProps & {
  onClose: () => void;
};

const Component = (props: Props) => {
  const { materialOptions, mutateResult, ...formState } = useScheduleForm({
    student: props.student,
    startDate: props.startDate,
    endDate: props.endDate,
    onMutateSuccess: props.onMutateSuccess,
    onMutateError: props.onMutateError,
    schedule: props.schedule,
    onMutateWithUpdateTypeConfirm: props.onMutateWithUpdateTypeConfirm,
  });

  const isAllday = formState.values.isWholeDay;

  return (
    <>
      <Modal.Header onClose={props.onClose}>
        {props.schedule?.id ? "予定の編集" : "予定の登録"}
      </Modal.Header>
      <Modal.Body>
        <StackForm onSubmit={formState.handleSubmit}>
          {/* タイトル入力のグループ */}
          <StackItem>
            <div className={styles.scheduleModal__formTitleLabel}>
              <Label htmlFor="schedule-title">タイトル</Label>
            </div>
            <ContentBox>
              <div className={styles.scheduleModal__formInput__title}>
                <Input
                  placeholder="タイトルを入力"
                  id="schedule-title"
                  name="title"
                  value={formState.values.title}
                  onChange={formState.handleChange}
                />
              </div>
            </ContentBox>
          </StackItem>
          {/* 時刻入力のグループ: wrapで折り返した時のy軸のマージンを担保するためにStackItemを使わない */}
          <StackItem>
            <ScheduleTimeFieldLayout>
              <Icon name="icon-timeline" color="primary" />
              <Flexbox wrap marginTop="0" gap="1">
                <Flexbox alignItems="center" marginTop="0">
                  <ScheduleTimeHStack>
                    <ScheduleDatePicker
                      value={formState.values.startDate}
                      onDateChange={(m) =>
                        formState.setFieldValue(
                          "startDate",
                          m,
                          // 終日ではない場合、非同期で開始日と終了日を同じ日にセットするため、必ずvalidation errorになる時間ができてしまう。それを回避するため、時刻予定の場合はstartDateセット時にvalidateしないようにする
                          formState.values.isWholeDay,
                        )
                      }
                      noIcon
                    />
                    {!isAllday && (
                      <TimeInput
                        name="startTime"
                        value={formState.values.startTime}
                        onChange={formState.handleChange}
                        aria-label="開始時刻"
                      />
                    )}
                    <div>〜</div>
                  </ScheduleTimeHStack>
                </Flexbox>
                <Flexbox marginTop="0" alignItems="center">
                  {isAllday && (
                    <DatePickerColumn>
                      <SingleDatePickerField
                        value={formState.values.endDate}
                        onDateChange={(m) =>
                          formState.setFieldValue("endDate", m)
                        }
                        isOutsideRange={(date) =>
                          isBefore(date, subDays(formState.values.startDate, 1))
                        }
                        hasError={Boolean(formState.errors.endDate)}
                        noIcon
                      />
                    </DatePickerColumn>
                  )}
                  {!isAllday && (
                    <TimeInputColumn>
                      <TimeInput
                        name="endTime"
                        onChange={formState.handleChange}
                        value={formState.values.endTime}
                        hasError={Boolean(formState.errors.endDate)}
                        aria-label="終了時刻"
                      />
                    </TimeInputColumn>
                  )}
                </Flexbox>
              </Flexbox>
              <div>
                <FormFieldError error={formState.errors.endDate} />
              </div>
              <div>
                <Flexbox alignItems="center" marginTop="1.5rem">
                  <div className={styles.scheduleModal__formCheckbox}>
                    <Checkbox
                      id="isWholeDay"
                      checked={formState.values.isWholeDay}
                      name="isWholeDay"
                      strokeColorLevel="normal"
                      onChange={formState.onChangeWholeDay}
                    >
                      終日
                    </Checkbox>
                  </div>
                  <div className={styles.scheduleModal__recurrencePicker}>
                    <RecurrenceSelect
                      disabled={
                        props.schedule
                          ? isExceptionSchedule(props.schedule)
                          : false
                      }
                      value={formState.values.recurrence}
                      onChange={(item) => {
                        formState.setFieldValue(
                          "recurrence",
                          item ? item.value : null,
                        );
                      }}
                      date={formState.values.startDate}
                      currentRecurrence={props.schedule?.recurrence ?? null}
                    />
                  </div>
                </Flexbox>
              </div>
            </ScheduleTimeFieldLayout>
          </StackItem>
          {/* 教材入力のグループ */}
          <StackItem>
            <FieldLayoutMultiRows>
              <Icon name="icon-textbook" color="primary" />
              <Label htmlFor="material-select">学習教材設定</Label>
              <BlockRow marginTop="0.8rem">
                <SelectField
                  options={materialOptions}
                  value={formState.values.learningMaterial}
                  onChange={(item: any) =>
                    formState.setFieldValue("learningMaterial", item.value)
                  }
                  placeholder="教材を選択してください"
                  hideIndicator
                />
              </BlockRow>
              {formState.isSetMaterial && (
                <Flexbox wrap marginTop="0">
                  <StudyPlanFieldStack>
                    <Label size="sm">学習時間</Label>
                    <Flexbox marginTop="0.4rem" alignItems="center">
                      <StudyPlanDurationInput>
                        <Input
                          type="number"
                          name="studyHours"
                          min="0"
                          max="9999"
                          value={formState.values.studyHours}
                          onChange={formState.handleChange}
                          onClick={(e) => e.currentTarget.focus()}
                          onMouseUp={(e) => e.currentTarget.blur()}
                          aria-label="学習時間(時間)"
                        />
                      </StudyPlanDurationInput>
                      <StudyPlanUnitTextWrapper>時間</StudyPlanUnitTextWrapper>
                      <StudyPlanDurationInput>
                        <Input
                          type="number"
                          name="studyMinutes"
                          min="0"
                          max="59"
                          value={formState.values.studyMinutes}
                          onChange={formState.handleChange}
                          onClick={(e) => e.currentTarget.focus()}
                          onMouseUp={(e) => e.currentTarget.blur()}
                          aria-label="学習時間(分)"
                        />
                      </StudyPlanDurationInput>
                      <StudyPlanUnitTextWrapper>分</StudyPlanUnitTextWrapper>
                    </Flexbox>
                  </StudyPlanFieldStack>
                  <StudyPlanFieldStack>
                    <Label size="sm" htmlFor="studyAmountField">
                      学習量
                    </Label>
                    <Flexbox marginTop="0.4rem" alignItems="center">
                      <StudyPlanAmountInput>
                        <Input
                          type="number"
                          min="0"
                          id="studyAmountField"
                          name="studyAmount"
                          value={formState.values.studyAmount}
                          onChange={formState.handleChange}
                          onClick={(e) => e.currentTarget.focus()}
                          onMouseUp={(e) => e.currentTarget.blur()}
                        />
                      </StudyPlanAmountInput>
                      <StudyPlanUnitTextWrapper>
                        {formState.unitTitle}
                      </StudyPlanUnitTextWrapper>
                    </Flexbox>
                  </StudyPlanFieldStack>
                </Flexbox>
              )}
            </FieldLayoutMultiRows>
          </StackItem>
          {/* メモ入力のグループ */}
          <StackItem>
            <Flex className={styles.scheduleModal__formMemo}>
              <IconBox>
                <Icon name="icon-memo" />
              </IconBox>
              <div
                className={styles.scheduleModal__formMemo__textarea__container}
              >
                <Textarea
                  placeholder="メモ"
                  name="memo"
                  value={formState.values.memo}
                  onChange={formState.handleChange}
                  className={styles.scheduleModal__formMemo__textarea}
                  fullSize
                />
              </div>
            </Flex>
          </StackItem>
          {/* 場所入力のグループ */}
          <StackItem>
            <FieldLayoutOneRow>
              <Icon name="icon-place" color="primary" />
              <Input
                name="locale"
                value={formState.values.locale}
                placeholder="場所"
                onChange={formState.handleChange}
              />
            </FieldLayoutOneRow>
          </StackItem>
          <FormFooter>
            <Button
              className={styles.submitButton}
              isLoading={mutateResult.isLoading}
              type="submit"
              disabled={!formState.isValid}
            >
              登録
            </Button>
          </FormFooter>
        </StackForm>
      </Modal.Body>
    </>
  );
};

type LabelProps = {
  htmlFor?: string;
  size?: "sm" | "md";
};
const Label: React.FC<React.PropsWithChildren<LabelProps>> = ({
  htmlFor,
  children,
  size = "md",
}) => {
  const classes = classnames(styles.scheduleModal__formLabel, {
    [styles.scheduleModal__formLabel__md]: size === "md",
    [styles.scheduleModal__formLabel__sm]: size === "sm",
  });

  return (
    <label htmlFor={htmlFor} className={classes}>
      {children}
    </label>
  );
};

type FlexProps = {
  alignCenter?: boolean;
  topSpace?: boolean;
  className?: string;
  flexWrap?: boolean;
};
const Flex: React.FC<React.PropsWithChildren<FlexProps>> = ({
  children,
  alignCenter = false,
  topSpace = false,
  flexWrap = false,
  className = "",
}) => {
  const givenClassName = className ? className : "";

  const classes = classnames(styles.flex, {
    [styles.flex__alignCenter]: alignCenter,
    [styles.flex__wrap]: flexWrap,
    [styles.scheduleModal__formContent__spaceTopMd]: topSpace,
  });
  return <div className={`${classes} ${givenClassName}`}>{children}</div>;
};

const IconBox: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className={`${styles.icon} ${styles.icon__form}`}>{children}</div>
);

type ContentBoxProps = {
  topSpace?: boolean;
};
const ContentBox: React.FC<React.PropsWithChildren<ContentBoxProps>> = ({
  children,
  topSpace = false,
}) => {
  const classes = classnames(styles.scheduleModal__formContent, {
    [styles.scheduleModal__formContent__spaceTopSm]: topSpace,
  });
  return <div className={classes}>{children}</div>;
};

const DatePickerColumn: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <div
    className={`${styles.scheduleModal__datepicker} ${styles.scheduleModal__timeSchedule}`}
  >
    {children}
  </div>
);
const TimeInputColumn: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <div className={styles.scheduleModal__timeSchedule}>{children}</div>;

type FormFieldErrorProps = {
  error?: any;
};
const FormFieldError = ({ error }: FormFieldErrorProps) => {
  if (error) {
    return <div className={styles.scheduleModal__formErrorField}>{error}</div>;
  }
  return null;
};

export const ScheduleForm = React.memo(Component);
