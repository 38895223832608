import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import { toLectureSessionWithSchedule } from "../../domain-adapters/LectureSession";
import { LectureSessionWithSchedule } from "../../domains/LectureSession";
import { HTTPErrors, createError } from "../../errors";

type Props = {
  sectionId: string;
  dateString: string;
};
type ResponseData = LectureSessionWithSchedule[];
export const useFetchLectureSessions = ({ sectionId, dateString }: Props) => {
  return useQuery<ResponseData, HTTPErrors>({
    queryKey: cacheKeyOf(sectionId, dateString),
    queryFn: async () => {
      const response = await api.get(`${baseURLOf(sectionId)}`, {
        query: { date: dateString },
      });
      if (response.ok) {
        const json = await response.json();
        return json.lectureSessions.data.map(
          (lectureSession: Record<string, any>) =>
            toLectureSessionWithSchedule(lectureSession),
        );
      }
      throw await createError(response);
    },
  });
};

export const cacheKeyOf = (sectionId: string, dateString: string) => [
  sectionId,
  "lectureSession",
  dateString,
];

export const baseURLOf = (sectionId: string) =>
  `/api/v1/sections/${sectionId}/lecture_sessions`;
