import * as React from "react";
import { useParams } from "react-router";
import SectionsAnalytics from "../../../components/organisms/SectionAnalytics/index";
import { useActiveMenu } from "../../../helpers/RouterHelper";
import enhanceSectionsPage from "../../../hocs/enhanceSectionsPage";
import { useAnalyticsFilter } from "../../../hooks/filters/useAnalyticsFilter";
import { useCurrentSection } from "../../../hooks/filters/useCurrentSection";
import { useQueryError } from "../../../hooks/http/useQueryError";
import AnalyticsTableInterface, {
  AnalyticsType,
} from "../../../interfaces/AnalyticsTableInterface";
import {
  FetchAnalyticsType,
  useFetchSectionsAnalytics,
} from "./useFetchSectionsAnalytics";

type Props = {
  analyticsType: AnalyticsType;
};

export type AnalyticsTableData = {
  isLoading: boolean;
  isFetchingNextPage: boolean;
  data: AnalyticsTableInterface;
};

const SectionsAnalyticsPage_ = (props: Props) => {
  const { sectionId = "" } = useParams<{ sectionId: string }>();
  const {
    section,
    analyticsFilter,
    analyticsTable,
    changeTerm,
    changeDates,
    changeOrder,
  } = useAnalyticsPage(sectionId, props.analyticsType);

  if (!section.data || !analyticsFilter) {
    return null;
  }

  return (
    <SectionsAnalytics
      section={section.data}
      filter={{
        sectionId: section.data.id,
        term: analyticsFilter.term,
        dateRangeFilter: {
          startDate: analyticsFilter.startDate,
          endDate: analyticsFilter.endDate,
        },
      }}
      analyticsTable={analyticsTable}
      analyticsType={props.analyticsType}
      startDate={analyticsFilter.startDate}
      endDate={analyticsFilter.endDate}
      term={analyticsFilter.term}
      order={analyticsFilter.order}
      orderDir={analyticsFilter.orderDir}
      onDateRangeFilterChange={changeDates}
      onTermChange={changeTerm}
      onOrderChange={changeOrder}
    />
  );
};

const useAnalyticsPage = (sectionId: string, analyticsType: AnalyticsType) => {
  const { setActiveMenu } = useActiveMenu();
  const {
    studentFilter,
    analyticsFilter,
    changeDates,
    changeTerm,
    changeOrder,
  } = useAnalyticsFilter({ sectionId });

  React.useEffect(() => {
    setActiveMenu("analytics");
  }, []);

  const section = useCurrentSection();

  const fetchAnalyticsType = React.useRef<FetchAnalyticsType>();
  React.useEffect(() => {
    switch (analyticsType) {
      case "directMessage":
        fetchAnalyticsType.current = "direct_messages";
        break;
      case "announceMessage":
        fetchAnalyticsType.current = "announce_messages";
        break;
      case "time":
        fetchAnalyticsType.current = "time";
        break;
      case "amount":
        fetchAnalyticsType.current = "amount";
        break;
      case "stay":
        fetchAnalyticsType.current = "stay";
        break;
      default:
        fetchAnalyticsType.current = undefined;
    }
  }, [analyticsType]);

  const { data, isLoading, isFetchingNextPage, error } =
    useFetchSectionsAnalytics({
      analyticsType: fetchAnalyticsType.current,
      sectionId,
      query: location.search,
    });

  useQueryError(error);

  const analyticsTable: AnalyticsTableData = {
    isLoading,
    isFetchingNextPage,
    data: {
      columns: data?.pages[0].data.columns ?? [],
      term: data?.pages[0].data.term ?? "",
      students: data?.pages.flatMap((page) => page.data.students) ?? [],
    },
  };
  return {
    section,
    studentFilter,
    analyticsFilter,
    analyticsTable,
    changeDates,
    changeTerm,
    changeOrder,
  };
};

export const SectionsAnalyticsPage = (props: Props) => {
  const Page = () => <SectionsAnalyticsPage_ {...props} />;
  const EnhancedPage = enhanceSectionsPage(Page, { title: "アナリティクス" });
  return <EnhancedPage />;
};
