import { IconArrowDown, IconArrowUp } from "@studyplus/boron-ui";
import * as React from "react";
import { DrillLearningMaterialValue } from "../../useStudyTaskForm";
import { DrillSection } from "../DrillLearningMaterialSelectionForm";
import { CheckboxRow } from "./CheckboxRow";
import { CheckboxWithLabel } from "./CheckboxWithLabel";

type Props = {
  drillSection: DrillSection;
  temporaryDrill: DrillLearningMaterialValue;
  temporaryContentIds: string[];
  setTemporarySectionsUnique: (sections: DrillSection[]) => void;
};

const useDrillSectionCheckboxRow = ({
  drillSection,
  temporaryDrill,
  setTemporarySectionsUnique,
}: Omit<Props, "temporaryContentIds">) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const addSelectedSection = () => {
    setTemporarySectionsUnique([...temporaryDrill.sections, drillSection]);
  };
  const removeSelectedSection = () => {
    setTemporarySectionsUnique(
      temporaryDrill.sections.filter((s) => s.id !== drillSection.id),
    );
  };
  const addSelectedContent = (
    content: Required<DrillSection>["contents"][0],
  ) => {
    const temporaryContents =
      temporaryDrill.sections.find((s) => s.id === drillSection.id)?.contents ||
      [];
    setTemporarySectionsUnique([
      ...temporaryDrill.sections,
      {
        ...drillSection,
        contents: [...temporaryContents, content],
      },
    ]);
  };
  const removeSelectedContent = (
    content: Required<DrillSection>["contents"][0],
  ) => {
    setTemporarySectionsUnique(
      temporaryDrill.sections.map((s) => {
        if (s.id !== drillSection.id) return s;

        return {
          ...s,
          contents: s.contents?.filter((c) => c.id !== content.id) || [],
        };
      }),
    );
  };
  return {
    isOpen,
    setIsOpen,
    addSelectedSection,
    removeSelectedSection,
    addSelectedContent,
    removeSelectedContent,
  };
};

export const DrillSectionCheckboxRow = ({
  drillSection,
  temporaryDrill,
  temporaryContentIds,
  setTemporarySectionsUnique,
}: Props) => {
  const {
    isOpen,
    setIsOpen,
    addSelectedSection,
    removeSelectedSection,
    addSelectedContent,
    removeSelectedContent,
  } = useDrillSectionCheckboxRow({
    setTemporarySectionsUnique,
    temporaryDrill,
    drillSection,
  });

  const checked = Boolean(
    drillSection.contents?.every((content) =>
      temporaryContentIds.includes(content.id),
    ),
  );
  const indeterminate =
    !checked &&
    Boolean(
      drillSection.contents?.some((content) =>
        temporaryContentIds.includes(content.id),
      ),
    );

  return (
    <>
      <CheckboxRow key={drillSection.id}>
        <div className="flex w-full flex-nowrap items-center justify-between">
          <div className="flex-grow">
            <CheckboxWithLabel
              id={drillSection.id}
              checked={checked}
              indeterminate={indeterminate}
              onCheck={addSelectedSection}
              onUncheck={removeSelectedSection}
            >
              {drillSection.name}
            </CheckboxWithLabel>
          </div>
          <div>
            <button
              className="text-black hover:text-blue-400"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <IconArrowUp /> : <IconArrowDown />}
            </button>
          </div>
        </div>
      </CheckboxRow>
      {isOpen &&
        drillSection.contents?.map((content) => (
          <CheckboxRow key={`${drillSection.id}-${content.id}`} indent>
            <CheckboxWithLabel
              id={`${drillSection.id}-${content.id}`}
              checked={temporaryContentIds.includes(content.id)}
              indeterminate={false}
              onCheck={() => addSelectedContent(content)}
              onUncheck={() => removeSelectedContent(content)}
            >
              {content.name}
            </CheckboxWithLabel>
          </CheckboxRow>
        ))}
    </>
  );
};
