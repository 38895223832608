import { useInfiniteQuery } from "@tanstack/react-query";
import ApiClient from "../../api";
import { fromJson as fromJsonLecture } from "../../domain-adapters/Lecture";
import { ArchiveStatus } from "../../domains/Attendance";
import { Lecture } from "../../domains/Lecture";
import { HTTPErrors, createError } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import ApiResponse from "../../interfaces/ApiResponse";

type Props = {
  sectionId: string;
  archiveStatus: ArchiveStatus;
  shouldGetAllRecords?: boolean;
};
export type ResponseData = ApiResponse<Lecture[]>;

const fromJson = (json: unknown): ResponseData => {
  const parsedJson = json as { lectures: ApiResponse<Record<string, any>[]> };
  const lectures = {
    meta: parsedJson.lectures.meta,
    data: parsedJson.lectures.data.map((record) => fromJsonLecture(record)),
  };

  return lectures as ResponseData;
};
export const useFetchLectures = ({
  sectionId,
  archiveStatus = ArchiveStatus.active,
  shouldGetAllRecords,
}: Props) => {
  const result = useInfiniteQuery<ResponseData, HTTPErrors>({
    queryKey: cacheKeyOf(sectionId, archiveStatus),
    queryFn: async ({ pageParam }) => {
      const response = await ApiClient.interruptGet(`${baseURLOf(sectionId)}`, {
        query: {
          status: archiveStatus,
          page: pageParam,
          per: shouldGetAllRecords ? 99999 : 20,
        },
      });
      if (response.ok) {
        const json = await response.json();
        return fromJson(json);
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });
  const data = result.data?.pages.flatMap((page) => page.data);
  return { ...result, data };
};

export const cacheKeyOf = (sectionId: string, status: ArchiveStatus) => [
  sectionId,
  "lectures",
  status,
];

export const baseURLOf = (sectionId: string) =>
  `/api/v1/sections/${sectionId}/lectures`;
