import ForbiddenError from "../../../components/organisms/ForbiddenError/index";
import ErrorTemplate from "../../../components/templates/ErrorTemplate/index";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import styles from "./styles.scss";

interface Props extends WithRouterProps {
  operator: OperatorInterface;
}

const ForbiddenErrorPage = (props: Props) => {
  const { operator } = props;
  const backTo = `${props.location.pathname}${props.location.search}`;
  const BottomDescription: JSX.Element = (
    <div className={styles.bottomDescriptionContainer}>
      <p>
        アカウント切り替えで解決しない場合、
        <br />
        許可されていないIPアドレスでアクセスしている可能性があります。
        <br />
        所属校舎のネットワーク管理者に確認してください。
      </p>
    </div>
  );

  return (
    <ErrorTemplate
      title="アクセス権限が必要です"
      heading="アクセス権限が必要です"
      buttonLink={`/sessions/edit?url=${backTo}`}
      buttonText="アカウント切り替え"
      bottomDescription={BottomDescription}
    >
      <ForbiddenError operator={operator} />
    </ErrorTemplate>
  );
};

export default withRouter<Props>(ForbiddenErrorPage);
