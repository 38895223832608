import { Button } from "@studyplus/boron-ui";
import { DrillSearchResponseItem } from "./useFetchDrillLearningMaterials";

export const DrillLearningMaterialRow = (props: {
  drill: DrillSearchResponseItem;
  selectDrillAndAllContents: (drill: DrillSearchResponseItem) => void;
  openContentSelector: (drill: DrillSearchResponseItem) => void;
  disabledButton: boolean;
}) => (
  <div
    role="row"
    className="flex flex-nowrap items-center justify-between py-6 pl-6 pr-7"
  >
    <div className="flex flex-nowrap items-center gap-4">
      <div className="h-[4.5rem] w-[4.5rem] flex-shrink-0 flex-grow-0">
        {props.drill.imageUrl && (
          <img
            src={props.drill.imageUrl}
            alt={props.drill.name}
            className="h-full w-full object-contain"
          />
        )}
      </div>
      <div className="flex-shrink flex-grow">
        <button
          className="p-0"
          onClick={() => props.openContentSelector(props.drill)}
        >
          <div className="text-left text-black underline">
            {props.drill.name}
          </div>
        </button>
        <div className="pt-4 text-sm text-gray-900">
          {props.drill.creatorName}
        </div>
      </div>
    </div>
    <Button
      isRound
      variant="outline"
      size="xs"
      onClick={() => props.selectDrillAndAllContents(props.drill)}
      disabled={props.disabledButton}
    >
      <div className="px-8 text-md">追加</div>
    </Button>
  </div>
);
