import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  postAnnounceMessage,
  postAnnounceMessageFile,
  postAnnounceZoomMeetingMessage,
} from "../../../actions/pages/sectionAnnounceMessages/form";
import StudentTag from "../../../domains/StudentTag";
import { useFetchProfile } from "../../../hooks/http/useFetchProfile.ts";
import { MessageFormValuesInterface } from "../../../interfaces/MessageInterface";
import { PostZoomMeetingMessageParams } from "../../../interfaces/PostZoomMeetingMessageParams";
import { Props } from "./index";

const initialValues = { content: "", file: null };

export const useAnnounceMessageForm = ({
  section,
  destinationTag,
}: Pick<Props, "section" | "destinationTag">) => {
  const [values, setValues] =
    React.useState<MessageFormValuesInterface>(initialValues);
  const dispatch = useDispatch();

  const mutationState = destinationTag ? destinationTag.formState : undefined;

  const formState = mutationState
    ? {
        submitting: mutationState.submitting,
        apiErrors: mutationState.apiErrors,
        values: values,
      }
    : undefined;

  React.useEffect(() => {
    setValues(initialValues);
  }, [section.id, destinationTag?.id]);

  // フォーム送信後に、送信成功時にフォームの入力内容をリセット
  React.useEffect(() => {
    if (
      mutationState &&
      mutationState.submitting &&
      mutationState.apiErrors.length == 0
    ) {
      setValues(initialValues);
    }
  }, [mutationState ? mutationState.submitting : null]);

  // テキストをフォームに設定
  const handleChangeContent = (content: string) => {
    if (!destinationTag) {
      return;
    }

    setValues((prevValues) => ({ ...prevValues, content }));
  };

  // ファイルをフォームに設定
  const handleChangeFormFile = (file: File | null) => {
    if (!destinationTag) {
      return;
    }

    setValues((prevValues) => ({ ...prevValues, file }));
  };

  const handleSubmitContent = (content: string) => {
    if (!destinationTag) {
      return;
    }

    postAnnounceMessage(section.id, destinationTag, content)(dispatch);
  };

  const handleSubmitFile = (file: File) => {
    if (!destinationTag) {
      return;
    }

    postAnnounceMessageFile(section.id, destinationTag, file)(dispatch);
  };

  const handlePostZoomMeetingMessage = (
    params: PostZoomMeetingMessageParams,
    setSubmitting: (submitting: boolean) => void,
    setErrors: (errors: Record<string, unknown>) => void,
    onSuccessCallback: () => void,
  ) => {
    if (!destinationTag) {
      return;
    }

    postAnnounceZoomMeetingMessage(
      section.id,
      destinationTag,
      params,
      setSubmitting,
      setErrors,
      onSuccessCallback,
    )(dispatch);
  };

  const isSkillEnabled = (): boolean => {
    return !!destinationTag && !!destinationTag.enabled;
  };

  const hasRecipient = (): boolean => {
    if (!destinationTag || !destinationTag.studentCount) {
      return false;
    }

    return destinationTag.studentCount > 0;
  };

  const disabledPlaceholder = (): string => {
    if (!destinationTag) {
      return "送信先を選択してください。";
    }

    if (!isSkillEnabled()) {
      return "対象のアシスタントスキルがオフになっているため送信出来ません。";
    }

    return "指定した送信対象が0件です。送信対象が存在するタグを指定して下さい。";
  };

  const navigate = useNavigate();

  const handleSelectDestinationTag = (tag: StudentTag) => {
    navigate(`/sections/${section.id}/tags/${tag.id}/messages`);
  };

  const handleDeselectDestinationTag = () => {
    navigate(`/sections/${section.id}/tags/messages/new`);
  };

  const { data: operatorProfile } = useFetchProfile();

  return {
    formState,
    handleChangeContent,
    handleChangeFormFile,
    handleSubmitContent,
    handleSubmitFile,
    handlePostZoomMeetingMessage,
    handleSelectDestinationTag,
    handleDeselectDestinationTag,
    disabledPlaceholder,
    hasRecipient,
    operatorProfile,
  };
};
