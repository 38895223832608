import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import { IconAttention2, IconPlus } from "@studyplus/boron-ui";
import { AnalyticsTableData } from "../../../containers/pages/SectionsAnalyticsPage";
import TimeHelper from "../../../helpers/TimeHelper";
import {
  AnalyticsRowInterface,
  AnalyticsType,
} from "../../../interfaces/AnalyticsTableInterface";
import { OrderDirFilterType } from "../../../interfaces/FiltersInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import Loader from "../../atoms/Loader";
import { BillingPlanLabel } from "../../features/BillingPlanLabel";
import { JobHumanLabel } from "../../features/JobHumanLabel";
import { useFieldVisibilityToggleContext } from "../../general/FieldVisibilityToggle/useFieldVisibilityToggleContext";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../general/Popover/Popover";
import SortLabel, { OrderDirectionType } from "../SortLabel";
import styles from "./styles.scss";
import {
  useAnalyticsTableHaeder,
  useAnalyticsTableHeaderColumn,
  useAnalyticsTableRow,
  useAnalyticsTableScore,
} from "./useAnalyticsTable";

export interface Props {
  section: SectionInterface;
  analyticsTable: AnalyticsTableData;
  analyticsType: AnalyticsType;
  onOrderChange: (order: string | null) => void;
  orderDir?: OrderDirFilterType | null;
  order?: string | null;
}

export const AnalyticsTable = (props: Props) => {
  const { analyticsTable } = props;

  if (analyticsTable.isLoading) {
    return <Loader loading={analyticsTable.isLoading} />;
  } else if (analyticsTable.data.students.length <= 0) {
    return (
      <p className={styles.notfound}>該当する生徒が見つかりませんでした</p>
    );
  }

  return (
    <React.Fragment>
      <div className={styles.root}>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <TableHeader {...props} />
            <TableBody {...props} />
          </table>
        </div>
        <Loader loading={analyticsTable.isFetchingNextPage} />
      </div>
    </React.Fragment>
  );
};

type TableHeaderProps = Props;
const TableHeader = (props: TableHeaderProps) => {
  const { analyticsTable, analyticsType, order, orderDir } = props;

  const {
    analyticsTypeName,
    handleSortClick,
    handleStudentIdSortClick,
    isRestrictedAnalyticsTypeByBillingPlan,
    hasStudentIdColumnAnalyticsType,
  } = useAnalyticsTableHaeder(props.analyticsType, props.onOrderChange);

  const { isInvisible } = useFieldVisibilityToggleContext();

  return (
    <thead>
      <tr className={classNames(styles.headRow, styles.row)}>
        <th></th>
        {!isInvisible("プラン") && isRestrictedAnalyticsTypeByBillingPlan && (
          <th />
        )}
        {!isInvisible("生徒ID") && hasStudentIdColumnAnalyticsType && (
          <th className={`${styles.cell} ${styles.heading}`}>
            <SortLabel
              label="生徒ID"
              active={order === "studentCode"}
              orderDirection={orderDir as OrderDirectionType}
              onClick={handleStudentIdSortClick("studentCode")}
            />
          </th>
        )}
        <th className={`${styles.cell} ${styles.heading}`}>氏名</th>
        {!isInvisible("学年（職業）") && <th />}
        <th className={`${styles.cell} ${styles.heading}`}>
          <SortLabel
            label={analyticsTypeName(analyticsType)}
            active={!order}
            orderDirection={(orderDir as OrderDirectionType) || "desc"}
            onClick={handleSortClick}
          />
        </th>
        {analyticsTable.data.columns.map((column) => (
          <HeaderColumn
            column={column}
            key={`analytics-table-column-${column}`}
            {...props}
          />
        ))}
      </tr>
    </thead>
  );
};

type HearderColumnProps = Props & { column: string };
const HeaderColumn = ({
  column,
  analyticsTable,
  order,
  orderDir,
  onOrderChange,
}: HearderColumnProps) => {
  const { formatColumn, handleDateColumnClick } = useAnalyticsTableHeaderColumn(
    analyticsTable,
    onOrderChange,
  );

  return (
    <th>
      <SortLabel
        active={order === column}
        label={formatColumn(column)}
        onClick={handleDateColumnClick(column)}
        orderDirection={orderDir as OrderDirectionType}
      />
    </th>
  );
};

const TableBody = (props: Props) => {
  const { analyticsTable } = props;
  if (analyticsTable.data && analyticsTable.data.students.length > 0) {
    return (
      <tbody className={styles.tbody}>
        {analyticsTable.data.students.map((row, i) => {
          return (
            <TableRow
              row={row}
              i={i}
              key={`analytics-table-row-${row.id}-${i}`}
              {...props}
            />
          );
        })}
      </tbody>
    );
  } else {
    return null;
  }
};

export type TableRowProps = Props & { row: AnalyticsRowInterface; i: number };
const TableRow = ({ row, i, ...props }: TableRowProps) => {
  const { analyticsType, analyticsTable } = props;
  const {
    isRestrictedAnalyticsTypeByBillingPlan,
    hasStudentIdColumnAnalyticsType,
    isScoreRestricted,
    studentPathname,
  } = useAnalyticsTableRow(row, analyticsType);

  const { isInvisible } = useFieldVisibilityToggleContext();

  return (
    <tr className={styles.row}>
      <td className={`${styles.row} ${styles.num}`}>{i + 1}</td>
      {!isInvisible("プラン") && isRestrictedAnalyticsTypeByBillingPlan && (
        <td className={`${styles.row} ${styles.plan}`}>
          <BillingPlanLabel billingPlan={row.billingPlan} />
        </td>
      )}
      {!isInvisible("生徒ID") && hasStudentIdColumnAnalyticsType && (
        <td className={`${styles.row} ${styles.code}`}>{row.code}</td>
      )}
      <td className={`${styles.row} ${styles.title}`}>
        <Link
          to={{
            pathname: studentPathname(row.id, analyticsType),
            search: window.location.search,
          }}
        >
          {row.fullName}
        </Link>
      </td>
      {!isInvisible("学年（職業）") && (
        <td className={`${styles.cell} ${styles.job}`}>
          <JobHumanLabel>{row.jobHuman}</JobHumanLabel>
        </td>
      )}
      <td className={styles.cell} aria-label={`${row.id}-total`}>
        {isScoreRestricted ? (
          <RestrictedScore
            id={`${row.id}-total`}
            score={row.totalScore}
            analyticsType={analyticsType}
          />
        ) : (
          <FormatScore
            score={row.totalScore}
            analyticsType={props.analyticsType}
          />
        )}
      </td>
      {row.scores.map((score: number, i: number) => (
        <Score
          row={row}
          score={score}
          i={i}
          isScoreRestricted={isScoreRestricted}
          analyticsTable={analyticsTable}
          analyticsType={analyticsType}
          key={`analytics-scores-${row.id}-${i}`}
        />
      ))}
    </tr>
  );
};

type RestrictedScoreProps = {
  id: string;
  score: number;
  analyticsType: AnalyticsType;
};
const RestrictedScore = ({
  id,
  score,
  analyticsType,
}: RestrictedScoreProps) => {
  return (
    <div className="inline-flex items-center">
      <FormatScore score={score} analyticsType={analyticsType} />
      <IconPlus className="h-2 w-2" />
      <PopoverProvider>
        <Popover>
          <PopoverTrigger asChild>
            <span className="leading-[0]" data-testid="test-tooltip-icon">
              <IconAttention2
                className="h-2 w-2 text-black hover:cursor-pointer hover:text-blue-400"
                data-for={`AnalyticsRow-score-tooltip-${id}`}
              />
            </span>
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent side="top">
              <span data-testid="test-tooltip-content">
                <p className="text-center text-sm font-normal">
                  プロプランにすると生徒が記録した
                  <br />
                  全学習記録が閲覧できます
                </p>
              </span>
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </PopoverProvider>
    </div>
  );
};

const FormatScore = ({
  score,
  analyticsType,
}: {
  score: number;
  analyticsType: AnalyticsType;
}) => {
  const formatScore = (score: number) => {
    switch (analyticsType) {
      case "time":
      case "stay":
        return TimeHelper.secondsToDisplayTime(score);
      case "directMessage":
      case "announceMessage":
        return `${score}件`;
      default:
        return score.toString();
    }
  };

  return <span>{formatScore(score)}</span>;
};

type ScoreProps = {
  row: AnalyticsRowInterface;
  score: number;
  i: number;
  isScoreRestricted: boolean;
  analyticsTable: AnalyticsTableData;
  analyticsType: AnalyticsType;
};
const Score = ({
  row,
  score,
  i,
  isScoreRestricted,
  analyticsTable,
  analyticsType,
}: ScoreProps) => {
  const { classNameFromDeviation, calculateDeviation } = useAnalyticsTableScore(
    styles,
    analyticsTable,
  );
  return (
    <td
      className={`${styles.cell} ${classNameFromDeviation(
        calculateDeviation(score),
        score,
      )}`}
      aria-label={`${row.id}-${i}`}
    >
      {isScoreRestricted ? (
        <RestrictedScore
          id={`${row.id}-${i}`}
          score={score}
          analyticsType={analyticsType}
        />
      ) : (
        <FormatScore score={score} analyticsType={analyticsType} />
      )}
    </td>
  );
};
