import { useMutation } from "@tanstack/react-query";
import * as React from "react";
import { boronClient } from "../../../api";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import StudentInterface, {
  convertStudentFormValueToParams,
  StudentFormValueInterface,
} from "../../../interfaces/StudentInterface";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";
import { Modal } from "../../atoms/Modal/index";
import StudentForm from "../StudentForm";

type Props = {
  isOpen: boolean;
  student: StudentInterface;
  updateStudent: (student: StudentInterface) => void;
  handleClose: () => void;
  forReduxProps?: ForReduxProps;
} & React.ComponentProps<typeof Modal>;

// TODO: StudentNaviProfileコンポーネントが脱Reduxしたらこの型定義を削除する
type ForReduxProps = {
  submitting: boolean;
  onSubmit: (values: StudentFormValueInterface) => void;
  apiErrors: ApiErrorInterface[];
};
const StudentEditModal: React.FunctionComponent<Props> = (
  props: React.PropsWithChildren<Props>,
) => {
  const { mutate, isPending, errors } = useStudentEditModal({
    student: props.student,
    updateStudent: props.updateStudent,
    handleClose: props.handleClose,
  });

  const onSubmit = props.forReduxProps?.onSubmit || mutate;
  const submitting = props.forReduxProps?.submitting || isPending;
  const apiErrors = props.forReduxProps?.apiErrors || errors;
  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose}>
      <Modal.Header onClose={props.handleClose}>{props.children}</Modal.Header>
      <Modal.Body>
        <StudentForm
          onSubmit={onSubmit}
          submitting={submitting}
          student={props.student}
          apiErrors={apiErrors}
        />
      </Modal.Body>
    </Modal>
  );
};

type UseStudentEditModalProps = Pick<
  Props,
  "student" | "updateStudent" | "handleClose"
>;
export const useStudentEditModal = ({
  student,
  updateStudent,
  handleClose,
}: UseStudentEditModalProps) => {
  const [errors, setErrors] = React.useState<ApiErrorInterface[]>([]);
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  React.useEffect(() => {
    if (errors.length > 0) setErrors([]);
  }, [student.id]);

  const { mutate, isPending } = useMutation<
    void,
    void,
    StudentFormValueInterface
  >({
    mutationFn: async (values) => {
      const { response, data, error } = await boronClient.PATCH(
        `/api/v1/students/{student_id}`,
        {
          params: {
            path: { student_id: student.id },
          },
          body: {
            student: convertStudentFormValueToParams(values),
          },
        },
      );
      if (response.ok && data) {
        updateStudent(data.student);
        handleClose();
        showSuccessMessage("生徒情報を更新しました");
        return;
      }
      if (response.status === 422) {
        if (error?.errors) setErrors(error.errors);
        showErrorMessage("生徒を更新できませんでした");
        return;
      }
      showErrorMessage(HTTP_ERROR_MESSAGE);
    },
  });

  return {
    mutate,
    isPending,
    errors,
  };
};

export default StudentEditModal;
