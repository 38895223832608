import { Modal } from "../../components/atoms/Modal/index";
import { StudentStayAnalytics } from "../../domains/StudentAnalytics";
import StudentInterface from "../../interfaces/StudentInterface";
import { StudentStayForm } from "./StudentStayForm";
import styles from "./StudentsStayItem.scss";

type Props = {
  isOpen: boolean;
  student: StudentInterface;
  sectionId: string;
  stay: StudentStayAnalytics | null;
  closeModal: () => void;
};

export const StudentStayUpdateModal = ({
  isOpen,
  student,
  sectionId,
  stay,
  closeModal,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} className={styles.modal}>
      <Modal.Header onClose={closeModal}>
        <h1>{student.fullName}の入退室記録</h1>
      </Modal.Header>
      <Modal.Body>
        <StudentStayForm
          sectionId={sectionId}
          closeModal={closeModal}
          stayId={stay?.id || ""}
          student={student}
          editingStay={stay}
        />
      </Modal.Body>
    </Modal>
  );
};
