import { useInfiniteQuery } from "@tanstack/react-query";
import ApiClient from "../../../api";
import { HTTPErrors, createError } from "../../../errors";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import AnalyticsTableInterface from "../../../interfaces/AnalyticsTableInterface";
import ApiResponse from "../../../interfaces/ApiResponse";

type Response = ApiResponse<AnalyticsTableInterface>;
export type FetchAnalyticsType =
  | "time"
  | "amount"
  | "stay"
  | "direct_messages"
  | "announce_messages"
  | undefined;
type Props = {
  analyticsType: FetchAnalyticsType;
  sectionId: string;
  query: string;
};
export const useFetchSectionsAnalytics = ({
  analyticsType,
  sectionId,
  query,
}: Props) => {
  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error,
  } = useInfiniteQuery<Response, HTTPErrors>({
    queryKey: cacheKeyOf({ analyticsType, sectionId, query }),
    queryFn: async ({ pageParam, signal }) => {
      const response = await ApiClient.get(
        `/api/v1/sections/${sectionId}/analytics/${analyticsType}`,
        {
          query: buildQuery(pageParam as number),
          signal,
        },
      );
      if (response.ok) {
        const json = (await response.json()) as {
          amountAnalyticsTable: Response;
          timeAnalyticsTable: Response;
          stayAnalyticsTable: Response;
          directMessagesAnalyticsTable: Response;
          announceMessagesAnalyticsTable: Response;
        };
        return responseForAnalyticsType(json, analyticsType);
      } else {
        throw await createError(response);
      }
    },
    initialPageParam: 1,
    getNextPageParam,
    refetchOnWindowFocus: false,
    enabled: analyticsType !== undefined,
  });

  useOnMainScrollAreaScroll(() => {
    if (!isLoading && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage]);

  return {
    data,
    isLoading,
    isFetchingNextPage,
    error,
  };
};

const cacheKeyOf = ({ analyticsType, sectionId, query }: Props) => [
  "sectionAnalytics",
  analyticsType,
  sectionId,
  query,
];

const buildQuery = (page: number) => {
  if (page === undefined) return location.search;
  return location.search ? `${location.search}&page=${page}` : `?page=${page}`;
};

type ResponseJson = {
  timeAnalyticsTable: Response;
  amountAnalyticsTable: Response;
  stayAnalyticsTable: Response;
  directMessagesAnalyticsTable: Response;
  announceMessagesAnalyticsTable: Response;
};
const responseForAnalyticsType = (
  json: ResponseJson,
  analyticsType: FetchAnalyticsType,
) => {
  if (analyticsType === undefined)
    throw new Error("analyticsType is undefined");

  switch (analyticsType) {
    case "time":
      return json.timeAnalyticsTable;
    case "amount":
      return json.amountAnalyticsTable;
    case "stay":
      return json.stayAnalyticsTable;
    case "direct_messages":
      return json.directMessagesAnalyticsTable;
    case "announce_messages":
      return json.announceMessagesAnalyticsTable;
  }
};
