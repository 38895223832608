import Icon from "../../../../components/atoms/Icon";
import { DropdownMenuItem } from "../../../../components/general/DropdownMenu/DropdownMenu";

export const StudentEditDropdownLink = ({
  handleSelect,
}: {
  handleSelect: (e: Event) => void;
}) => {
  return (
    <>
      <DropdownMenuItem group onSelect={handleSelect}>
        <div className="flex items-center">
          <Icon
            className="mr-3 text-gray-600 group-hover:text-blue-400"
            name="icon-edit"
          />
          生徒情報の編集
        </div>
      </DropdownMenuItem>
    </>
  );
};
