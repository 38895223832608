import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { SectionsSettingsCourseStudents } from "./SectionsSettingsContentCourseStudents";

const pageInfo = {
  title: "コース",
};

export const SectionsSettingsContentCourseStudentsPage =
  enhanceSectionsSettingsPage(SectionsSettingsCourseStudents, pageInfo, [
    "content_courses",
  ]);
