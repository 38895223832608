import * as React from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import { getSectionOperatorInvitation } from "../../actions/invitation";
import InvitationAccept from "../../components/organisms/InvitationAccept";
import { WithRouterProps } from "../../helpers/RouterHelper";
import enhanceHomePage from "../../hocs/enhanceHomePage";

interface Props extends WithRouterProps<{ invitationId: string }> {
  getInvitation: HandleThunkActionCreator<typeof getSectionOperatorInvitation>;
}

class InvitationPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    const { invitationId } = this.props.params;
    this.props.getInvitation(invitationId);
  }

  render() {
    return <InvitationAccept />;
  }
}

const pageInfo = { title: "メンバー招待" };
const EnhancedPage = enhanceHomePage(InvitationPage, pageInfo);

export default connect(null, { getInvitation: getSectionOperatorInvitation })(
  EnhancedPage as any,
);
