import classNames from "classnames";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import CircleIcon from "../../../../components/atoms/CircleIcon";
import Icon from "../../../../components/atoms/Icon";
import TimeHelper from "../../../../helpers/TimeHelper.ts";
import BotMessageInterface from "../../../../interfaces/BotMessageInterface.ts";
import { DirectMessage } from "../../../../interfaces/MessageInterface.ts";
import MessageThreadInterface from "../../../../interfaces/MessageThreadInterface.ts";
import styles from "./styles.scss";

interface Props {
  messageThread: MessageThreadInterface;
  isActive: boolean;
  onClickThread: (thread: MessageThreadInterface) => void;
}

const MessageThreadListItem = (props: Props) => {
  const { student } = props.messageThread;

  return (
    <div
      onClick={handleClick(props)}
      className={classNames(styles.root, {
        [styles.isUnread]: !isRead(props.messageThread),
        [styles.isActive]: props.isActive,
        [styles.isFollowing]: props.messageThread.isFollowing,
      })}
      role="message_thread-item"
    >
      <div className={styles.left}>
        <div className={styles.attention}>
          <span className={styles.attention__dot} />
        </div>
        <CircleIcon
          iconProps={{ name: "icon-notice-user", iconClassName: styles.icon }}
          className={styles.iconWrapper}
        />
      </div>
      <div className={styles.right}>
        <div className={styles.rightHeader}>
          <div className={styles.name}>{student.fullName}</div>
          {renderSentAt(props)}
          <div className={styles.follow} aria-label="フォロー状態">
            {props.messageThread.isFollowing ? (
              <Icon name="icon-notice" className={styles.followIcon} />
            ) : null}
          </div>
        </div>
        {renderLatestMessage(props)}
      </div>
    </div>
  );
};

const renderSentAt = (props: Props) => {
  const { latestMessage, latestBotMessage } = props.messageThread;

  const latestSentAt = (() => {
    if (latestMessage && latestBotMessage) {
      return isAfter(
        new Date(latestMessage.sentAt),
        new Date(latestBotMessage.sentAt),
      )
        ? latestMessage.sentAt
        : latestBotMessage.sentAt;
    } else if (latestMessage) {
      return latestMessage.sentAt;
    } else if (latestBotMessage) {
      return latestBotMessage.sentAt;
    } else {
      return null;
    }
  })();

  return latestSentAt ? (
    <time className={styles.datetime}>
      {TimeHelper.fullFormat(latestSentAt)}
    </time>
  ) : null;
};

const renderLatestMessage = (props: Props) => {
  const { latestBotMessage, latestMessage } = props.messageThread;
  if (latestMessage && latestBotMessage) {
    return isAfter(
      new Date(latestMessage.sentAt),
      new Date(latestBotMessage.sentAt),
    )
      ? renderDirectMessage(latestMessage)
      : renderBotMessage(latestBotMessage);
  }

  if (latestMessage) {
    return renderDirectMessage(latestMessage);
  } else {
    return latestBotMessage ? (
      renderBotMessage(latestBotMessage)
    ) : (
      <p className={styles.content}>まだメッセージがありません</p>
    );
  }
};

const renderBotMessage = (message: BotMessageInterface) => {
  return (
    <p className={styles.bot_content}>
      <Icon
        name="icon-notice-assistant"
        fileType="png"
        className={styles.bot_content__icon}
      />
      <span className={styles.bot_content__message}>
        「{message.skill.name}」に該当しました。
      </span>
    </p>
  );
};

const renderDirectMessage = (message: DirectMessage) => {
  if (message.content) {
    return <p className={styles.content}>{message.content}</p>;
  } else if (message.deletedAt) {
    return <p className={styles.content}>このメッセージは削除されました</p>;
  } else {
    return <p className={styles.content}>画像ファイル</p>;
  }
};

const handleClick = (props: Props) => () => {
  props.onClickThread(props.messageThread);
};

const isRead = (messageThread: MessageThreadInterface): boolean => {
  if (!messageThread.latestMessage || !messageThread.lastReadAt) {
    return true;
  }

  return isBefore(
    new Date(messageThread.latestMessage.sentAt),
    new Date(messageThread.lastReadAt),
  );
};

export default MessageThreadListItem;
