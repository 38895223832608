import { Link } from "react-router-dom";
import StudentInterface from "../../../interfaces/StudentInterface";
import Icon from "../../atoms/Icon/index";
import LinkButton from "../../atoms/LinkButton/index";
import styles from "./styles.scss";
import { useFollowUnfollowThread } from "./useFollowUnfollowThread.tsx";

type Props = {
  student: Omit<StudentInterface, "billingPlan">;
  isFollowing: boolean;
} & (
  | {
      showCloseIcon: true;
      onClose: () => void;
    }
  | { showCloseIcon: false }
);

export const StudentMessageHeader = (props: Props) => {
  const { follow, unfollow } = useFollowUnfollowThread({
    sectionId: props.student.section.id,
    studentId: props.student.id,
  });

  return (
    <div className={styles.root}>
      {props.showCloseIcon ? (
        <Icon
          name="icon-arrow-close"
          className={styles.close}
          onClick={props.onClose}
        />
      ) : null}
      <div className={styles.title}>
        <Link to={`/students/${props.student.id}`} className={styles.name}>
          {props.student.fullName}
        </Link>
        とのメッセージ
      </div>
      <div className={styles.follow} aria-label="メッセージ通知">
        <FollowButton
          isFollowing={props.isFollowing}
          onFollow={follow}
          onUnfollow={unfollow}
        />
      </div>
    </div>
  );
};

const FollowButton = (props: {
  isFollowing: boolean;
  onFollow: () => void;
  onUnfollow: () => void;
}) => {
  if (props.isFollowing) {
    return (
      <LinkButton onClick={props.onUnfollow} role="button">
        <Icon name="icon-notice" />
      </LinkButton>
    );
  } else {
    return (
      <LinkButton onClick={props.onFollow} role="button">
        <Icon name="icon-notice-mail-mute" />
      </LinkButton>
    );
  }
};
