import { useMutation } from "@tanstack/react-query";
import { boronClient } from "../../../../../api";
import { HTTPErrors, createError } from "../../../../../errors";

type Props = {
  studentId: string;
  studyRecordId: string;
  onSuccess: () => void;
};

const URL =
  "/api/v1/students/{student_id}/study_records/{study_record_hashid}/likes";

export const usePostStudyRecordLike = ({
  studentId,
  studyRecordId,
  onSuccess,
}: Props) => {
  return useMutation<unknown, HTTPErrors, void>({
    mutationFn: () => postStudyRecordLike({ studentId, studyRecordId }),
    onSuccess,
  });
};

const postStudyRecordLike = async ({
  studentId,
  studyRecordId,
}: Omit<Props, "onSuccess" | "onError">) => {
  const { response } = await boronClient.POST(URL, {
    params: {
      path: {
        student_id: studentId,
        study_record_hashid: studyRecordId,
      },
    },
  });

  if (response.ok) return;

  throw await createError(response);
};

export const useDeleteStudyRecordLike = ({
  studentId,
  studyRecordId,
  onSuccess,
}: Props) => {
  return useMutation<unknown, HTTPErrors, void>({
    mutationFn: () => deleteStudyRecordLike({ studentId, studyRecordId }),
    onSuccess,
  });
};

const deleteStudyRecordLike = async ({
  studentId,
  studyRecordId,
}: Omit<Props, "onSuccess" | "onError">) => {
  const { response } = await boronClient.DELETE(URL, {
    params: {
      path: {
        student_id: studentId,
        study_record_hashid: studyRecordId,
      },
    },
  });

  if (response.ok) return;

  throw await createError(response);
};
