import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BlockRow } from "../../components/atoms/BlockRow/index";
import { Flex } from "../../components/atoms/Flex/index";
import Loader from "../../components/atoms/Loader";
import SwitchTabs from "../../components/molecules/SwitchTabs/index";
import { SwitchTabsProps } from "../../components/molecules/SwitchTabs/index";
import TabsNavi from "../../components/molecules/TabsNavi/index";
import {
  ArchiveStatus,
  translateMutateArchiveStatusResult,
} from "../../domains/Attendance";
import { Lecture } from "../../domains/Lecture";
import { OutletContextProps } from "../../helpers/RouterHelper";
import enhanceSectionsSettingsPage, {
  SectionsSettingsPageProps,
} from "../../hocs/enhanceSectionsSettingsPage";
import { useFetchLectures } from "../../hooks/http/useFetchLectures";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useErrorDispatcher } from "../../hooks/useErrorDispatcher";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import SectionInterface from "../../interfaces/SectionInterface";
import { ActiveLecturesTable } from "./ActiveLecturesTable";
import { ArchivedLecturesTable } from "./ArchivedLecturesTable";
import styles from "./styles.scss";
import { useArchiveStatusParameter } from "./useArchiveStatusParameter";
import { useMutateLectureArchiveStatus } from "./useMutateLectureArchiveStatus";

type Props = {
  section: SectionInterface;
} & SectionsSettingsPageProps &
  OutletContextProps;

// Main hooks
type HooksProps = {
  section: Props["section"];
};

const useSectionSettingsLecturePage = ({ section }: HooksProps) => {
  const archiveStatus = useArchiveStatusParameter();
  const { sendNotFound } = useErrorDispatcher();
  if (archiveStatus === null) {
    sendNotFound();
  }

  const lecturesState = useFetchLectures({
    sectionId: section.id,
    archiveStatus: archiveStatus || ArchiveStatus.active,
  });
  useQueryError(lecturesState.error);

  useOnMainScrollAreaScroll(() => {
    if (
      lecturesState.hasNextPage &&
      !lecturesState.isLoading &&
      !lecturesState.isFetchingNextPage
    ) {
      lecturesState.fetchNextPage();
    }
  }, [
    lecturesState.hasNextPage,
    lecturesState.isLoading,
    lecturesState.isFetchingNextPage,
    lecturesState.fetchNextPage,
  ]);

  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const lectureStatusMutation = useMutateLectureArchiveStatus({
    sectionId: section.id,
    onSuccess: (archiveStatus: ArchiveStatus) => {
      showSuccessMessage(
        translateMutateArchiveStatusResult(archiveStatus, "success"),
      );
    },
    onError: () => {
      if (archiveStatus === null) return;

      showErrorMessage(
        translateMutateArchiveStatusResult(archiveStatus, "failure"),
      );
    },
  });

  const archiveLecture = (lecture: Lecture) => {
    if (
      window.confirm(
        `「${lecture.name}」をアーカイブにしてもよろしいですか？
アーカイブに移動すると、予定に対して該当の講座が選択できなくなります。アーカイブはいつでもアクティブに戻すことができます。`,
      )
    ) {
      lectureStatusMutation.mutate({
        lectureId: lecture.id,
        archiveStatus: "archived",
      });
    }
  };

  const activateLecture = (lecture: Lecture) => {
    lectureStatusMutation.mutate({
      lectureId: lecture.id,
      archiveStatus: "active",
    });
  };

  return {
    archiveStatus,
    lecturesState,
    activateLecture,
    archiveLecture,
  };
};

// Main Component
const SectionsSettingsLecturesPage_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("lectures");
  }, []);
  const { lecturesState, archiveLecture, archiveStatus, activateLecture } =
    useSectionSettingsLecturePage({
      section: props.section,
    });

  const navigate = useNavigate();
  return (
    <>
      <TabsNavi
        tabs={[
          {
            label: "講座",
            link: `/sections/${props.section.id}/settings/lectures`,
            active: true,
          },
        ]}
      />
      <Flex justifyContent="space-between" alignItems="center" wrap>
        <SwitchTabs
          tabs={[
            {
              id: "active",
              label: "アクティブ",
              active: archiveStatus === ArchiveStatus.active,
            },
            {
              id: "archived",
              label: "アーカイブ",
              active: archiveStatus === ArchiveStatus.archived,
            },
          ]}
          onClickTab={(
            tab: Omit<SwitchTabsProps<"active" | "archived">, "onClick">,
          ) =>
            () => {
              const basePath = `/sections/${props.section.id}/settings/lectures`;
              const path =
                tab.id === "archived" ? `${basePath}/archived` : basePath;

              if (location.pathname !== path) {
                navigate(path);
              }
            }}
        />
        <div className={styles.buttonContainer}>
          <Link
            to={`/sections/${props.section.id}/settings/lectures/new`}
            className={`buiButtonBase buiButtonVariantFilled buiButtonBlock`}
          >
            講座の登録
          </Link>
        </div>
      </Flex>
      <BlockRow marginTop="1rem">
        {lecturesState.isLoading ? (
          <Loader loading />
        ) : archiveStatus === ArchiveStatus.archived ? (
          <ArchivedLecturesTable
            lectures={lecturesState.data || []}
            onActivate={activateLecture}
          />
        ) : (
          <ActiveLecturesTable
            lectures={lecturesState.data || []}
            onArchive={archiveLecture}
          />
        )}
      </BlockRow>
      <Loader loading={lecturesState.isFetchingNextPage} />
    </>
  );
};

const pageInfo = {
  title: "講座",
};
export const SectionsSettingsLecturesPage = enhanceSectionsSettingsPage(
  SectionsSettingsLecturesPage_,
  pageInfo,
  ["lectures"],
);
