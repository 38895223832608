import {
  ErrorMessage,
  Field,
  Form,
  FormikBag,
  FormikProps,
  withFormik,
} from "formik";
import * as React from "react";
import Button from "../../../../../components/atoms/Button";
import ErrorText from "../../../../../components/atoms/ErrorText";
import Input from "../../../../../components/atoms/Input";
import Label from "../../../../../components/atoms/Label";
import { Modal } from "../../../../../components/atoms/Modal";
import { ApiErrorInterface } from "../../../../../interfaces/ApiErrorResponseInterface.ts";
import styles from "./styles.scss";
import validationSchema from "./validationSchema.ts";

interface ComponentProps {
  submitting: boolean;
  apiErrors: ApiErrorInterface[];
  updatePassword: (values: Values) => void;
}
interface Values {
  current_password: string;
  password: string;
  password_confirmation: string;
}

interface State {
  isOpen: boolean;
}

type Props = ComponentProps & FormikProps<Values>;

class OperatorProfilePasswordForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.submitting === true && this.props.submitting === false) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.root}>
          <Label isMute={true}>パスワード</Label>
          <div className={styles.display}>
            ••••••••••
            <button className={styles.btn} onClick={this.handleOpenModal}>
              変更
            </button>
          </div>
        </div>
        {this.renderModal()}
      </React.Fragment>
    );
  }

  renderModal() {
    return (
      <Modal
        isOpen={this.state.isOpen}
        onRequestClose={this.handleCloseModal}
        className={styles.modal}
      >
        <Modal.Header onClose={this.handleCloseModal}>
          <h2>パスワード変更</h2>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {this.renderApiErrors()}
            <div className={styles.formGroup}>
              <div className={styles.inline}>
                <Label isMute={true} htmlFor="operator-current_password">
                  現在のパスワード
                </Label>
                <ErrorMessage
                  name="current_password"
                  component={ErrorText}
                  className={styles.input__error}
                />
              </div>
              <Field
                name="current_password"
                id="operator-current_password"
                type="password"
                hasError={this.shouldRenderFormikError("current_password")}
                component={Input}
              />
            </div>
            <div className={styles.formGroup}>
              <div className={styles.inline}>
                <Label isMute={true} htmlFor="operator-password">
                  新しいパスワード
                </Label>
                <ErrorMessage
                  name="password"
                  component={ErrorText}
                  className={styles.input__error}
                />
              </div>
              <Field
                name="password"
                id="operator-password"
                type="password"
                hasError={this.shouldRenderFormikError("password")}
                component={Input}
              />
            </div>
            <div className={styles.formGroup}>
              <div className={styles.inline}>
                <Label isMute={true} htmlFor="operator-password_confirmation">
                  新しいパスワード（確認）
                </Label>
                <ErrorMessage
                  name="password_confirmation"
                  component={ErrorText}
                  className={styles.input__error}
                />
              </div>
              <Field
                name="password_confirmation"
                id="operator-password_confirmation"
                type="password"
                hasError={this.shouldRenderFormikError("password_confirmation")}
                component={Input}
              />
            </div>
            <div className={styles.btnGroup}>
              <Button type="submit" disabled={!this.isSubmittable()}>
                更新
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  private renderApiErrors(): React.ReactElement | null {
    if (this.props.apiErrors.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        {this.props.apiErrors.map((err: ApiErrorInterface, i: number) => {
          return (
            <ErrorText
              className={styles.input__error}
              key={`operator-settings-password__error_${i}`}
            >
              {err.message}
            </ErrorText>
          );
        })}
      </React.Fragment>
    );
  }

  private shouldRenderFormikError = (name: string): boolean => {
    const errors = (this.props.errors as any)[name];
    const touched = (this.props.touched as any)[name];
    return errors && touched;
  };

  private handleCloseModal = () => {
    this.setState({ isOpen: false });
  };

  private handleOpenModal = () => {
    this.setState({ isOpen: true });
  };

  private isSubmittable(): boolean {
    return this.props.isValid && !this.props.submitting;
  }
}

const mapPropsToValues = (): Values => ({
  current_password: "",
  password: "",
  password_confirmation: "",
});

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
): void => {
  if (formikBag.props.submitting) {
    return;
  }
  formikBag.props.updatePassword(values);
};

export default withFormik({ mapPropsToValues, handleSubmit, validationSchema })(
  OperatorProfilePasswordForm,
);
