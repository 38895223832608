import * as React from "react";
import { Link } from "react-router-dom";
import { truncate } from "../../../helpers/String";
import { KarteTemplateInterface } from "../../../interfaces/KarteTemplateInterface";
import Icon from "../../atoms/Icon/index";
import styles from "./styles.scss";

interface Props {
  sectionId: string;
  karteTemplates: KarteTemplateInterface[];
}

const KarteTemplateList = (props: Props): React.ReactElement | null => {
  if (props.karteTemplates.length === 0) return null;

  return <div className={styles.tableWrapper}>{renderTable(props)}</div>;
};

const renderTable = (props: Props) => {
  return (
    <table>
      <thead>
        <tr>
          <th>テンプレート名</th>
          <th>本文</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{renderList(props)}</tbody>
    </table>
  );
};

const renderList = (props: Props) => {
  return props.karteTemplates.map((karteTemplate: KarteTemplateInterface) => {
    return (
      <tr key={`karte_template-${karteTemplate.id}`}>
        <td>{karteTemplate.name}</td>
        <td className={styles.template}>
          {truncateTemplate(karteTemplate.template)}
        </td>
        <td>
          <Link
            to={`/sections/${props.sectionId}/settings/karte_templates/${karteTemplate.id}/edit`}
            className={styles.link}
          >
            <Icon name="icon-edit" className={styles.svg} />
            編集
          </Link>
        </td>
      </tr>
    );
  });
};

const truncateTemplate = (template: string): string => {
  const firstRow = template.split(/\r\n|\r|\n/)[0];
  return truncate(firstRow, 30);
};

export default KarteTemplateList;
