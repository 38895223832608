import * as React from "react";
import { connect } from "react-redux";
import { loadKarteTemplates } from "../../../actions/sectionKartes";
import {
  loadInitialStudentKartes,
  loadStudentKartes,
} from "../../../actions/studentKartes";
import StudentKartes from "../../../components/organisms/StudentKartes/index";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../../hocs/enhanceAuthenticatedPage";
import enhanceStudentsPage from "../../../hocs/enhanceStudentsPage";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { StudentKartesStateInterface } from "../../../interfaces/KarteInterface";
import { KarteTemplateInterface } from "../../../interfaces/KarteTemplateInterface";
import { StudentStateInterface } from "../../../interfaces/StudentInterface";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    OutletContextProps {
  student: StudentStateInterface;
  studentKartes: StudentKartesStateInterface;
  karteTemplates: KarteTemplateInterface[];
  loadKarteTemplates: (sectionId: string) => void;
  loadStudentKartes: (studentId: string, page: number) => void;
  loadInitialStudentKartes: (studentId: string) => void;
}

const StudentsKartesPage = ({
  student,
  loadStudentKartes,
  studentKartes,
  karteTemplates,
  loadInitialStudentKartes,
  loadKarteTemplates,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("karte");
  }, []);

  React.useEffect(() => {
    if (student.data) {
      loadInitialStudentKartes(student.data.id);
      loadKarteTemplates(student.data.section.id);
    }
  }, [student.data]);

  useOnMainScrollAreaScroll(() => {
    if (studentKartes.loading || !studentKartes.hasMore) {
      return;
    }
    if (student.data) {
      loadStudentKartes(student.data.id, studentKartes.meta.currentPage + 1);
    }
  }, [studentKartes.loading, studentKartes.hasMore, student.data]);

  if (!student.data) {
    return null;
  }

  return (
    <StudentKartes
      student={student.data}
      studentKartes={studentKartes}
      karteTemplates={karteTemplates}
    />
  );
};

const mapStateToProps = (state: AppStateInterface) => {
  return {
    student: state.student,
    studentKartes: state.studentKartes,
    karteTemplates: state.karteTemplates,
  };
};

const actions = {
  loadKarteTemplates,
  loadInitialStudentKartes,
  loadStudentKartes,
};

const ConnectedStudentsKartesPage = connect(
  mapStateToProps,
  actions,
)(StudentsKartesPage);

export default enhanceStudentsPage(ConnectedStudentsKartesPage, {
  title: "カルテ",
});
