import * as React from "react";
import { StudentKarteEditStateInterface } from "../../../interfaces/KarteInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import BackLink from "../../atoms/BackLink/index";
import StudentKarteEditForm from "../../molecules/StudentKarteEditForm/index";

interface Props {
  student: StudentInterface;
  karteEditState: StudentKarteEditStateInterface;
}

const StudentKarteEdit = (props: Props) => {
  const { karteEditState, student } = props;

  return (
    <React.Fragment>
      <BackLink>戻る</BackLink>
      <StudentKarteEditForm student={student} karteEditState={karteEditState} />
    </React.Fragment>
  );
};

export default StudentKarteEdit;
