import { Button } from "@studyplus/boron-ui";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import * as React from "react";
import { NavigateFunction } from "react-router";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import {
  KarteTemplateFormInterface,
  KarteTemplateInterface,
} from "../../../interfaces/KarteTemplateInterface";
import ErrorText from "../../atoms/ErrorText/index";
import Input from "../../atoms/Input/index";
import Textarea from "../../atoms/Textarea/index";
import styles from "./styles.scss";
import validationSchema from "./validationSchema";

export enum KarteTemplateFormType {
  Edit,
  New,
}

interface ComponentProps extends WithRouterProps {
  type: KarteTemplateFormType;
  sectionId: string;
  karteTemplate: KarteTemplateInterface | null;
  submitting: boolean;
  loading: boolean;
  apiErrors: ApiErrorInterface[];
  onSubmit: (values: Values, navigate: NavigateFunction) => void;
  onDelete?: (
    sectionId: string,
    karteTemplateId: string,
    navigate: NavigateFunction,
  ) => void;
}
type Props = ComponentProps & FormikProps<Values>;

type Values = KarteTemplateFormInterface;

class KarteTemplateForm extends React.Component<Props, Values> {
  render() {
    return (
      <Form className={styles.root}>
        <div className={styles.name}>
          <div className={styles.label}>
            <label htmlFor="karte_template__name">テンプレート名</label>
            {this.renderError("name")}
          </div>
          <Field
            id="karte_template__name"
            name="name"
            placeholder="テンプレート名を入力"
            component={Input}
            hasError={this.shouldRenderError("name")}
          />
        </div>
        <div className={styles.template}>
          <div className={styles.label}>
            <label htmlFor="karte_template__template">本文</label>
            {this.renderError("template")}
          </div>
          <Field
            id="karte_template__template"
            name="template"
            component={Textarea}
            placeholder="本文を入力"
            rows="20"
            hasError={this.shouldRenderError("template")}
          />
        </div>
        <div>{this.renderApiErrors()}</div>
        <div className={styles.footer}>
          {this.renderDeleteBtn()}
          <div>
            <Button
              disabled={!this.submittable()}
              type="submit"
              className={styles.button}
            >
              {this.renderButtonLabel()}
            </Button>
          </div>
        </div>
      </Form>
    );
  }

  renderButtonLabel() {
    switch (this.props.type) {
      case KarteTemplateFormType.Edit:
        return "更新";
      case KarteTemplateFormType.New:
        return "登録";
    }
  }

  renderDeleteBtn() {
    if (this.props.type === KarteTemplateFormType.Edit) {
      return (
        <Button
          disabled={this.props.submitting}
          type="button"
          onClick={this.handleDeleteBtn}
          className={styles.delete_button}
        >
          削除
        </Button>
      );
    } else {
      return <div></div>;
    }
  }

  renderApiErrors() {
    if (this.props.apiErrors.length > 0) {
      return this.props.apiErrors.map((err: ApiErrorInterface, idx: number) => {
        return (
          <p key={`karte_template-error-${idx}`} className={styles.errors}>
            {err.message}
          </p>
        );
      });
    }
  }

  renderError(name: string) {
    if (this.shouldRenderError(name)) {
      return (
        <ErrorText className={styles.errors}>
          {(this.props.errors as any)[name]}
        </ErrorText>
      );
    } else {
      return null;
    }
  }

  private handleDeleteBtn = () => {
    if (
      this.props.onDelete &&
      this.props.karteTemplate &&
      window.confirm("このテンプレートを削除してよろしいですか")
    ) {
      this.props.onDelete(
        this.props.sectionId,
        this.props.karteTemplate.id,
        this.props.navigate,
      );
    }
  };

  private submittable = () => {
    return !this.props.submitting && this.props.isValid;
  };

  private shouldRenderError = (name: string) => {
    const errors = (this.props.errors as any)[name];
    const touched = (this.props.touched as any)[name];
    return errors && touched;
  };
}

const mapPropsToValues = (props: ComponentProps): Values => {
  if (props.karteTemplate) {
    return {
      name: props.karteTemplate.name,
      template: props.karteTemplate.template,
    };
  } else {
    return { name: "", template: "" };
  }
};

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
) => {
  if (formikBag.props.submitting) {
    return;
  } else {
    formikBag.props.onSubmit(values, formikBag.props.navigate);
  }
};

const FormedComponent = withFormik({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
})(KarteTemplateForm);
export default withRouter<Props>(FormedComponent);
