import SplitLayout from "../../../components/atoms/SplitLayout";
import DirectMessageForm from "../../../components/features/DirectMessageForm";
import { StudentMessageHeader } from "../../../components/features/StudentMessageHeader";
import { StudentMessageList } from "../../../components/features/StudentMessageList";
import MessageThreadInterface from "../../../interfaces/MessageThreadInterface.ts";
import OperatorInterface from "../../../interfaces/OperatorInterface.ts";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface.ts";
import styles from "./styles.scss";

interface Props {
  sectionId: string;
  messageThread: MessageThreadInterface | null;
  onCloseRightColumn: () => void;
  operator: OperatorInterface;
  operatorProfile: OperatorProfileInterface | null;
}

const SectionStudentMessage = (props: Props) => {
  if (!props.messageThread || !props.operatorProfile) {
    return null;
  }

  return (
    <div className={styles.root}>
      <SplitLayout.Header>
        <StudentMessageHeader
          student={props.messageThread.student}
          isFollowing={!!props.messageThread.isFollowing}
          onClose={props.onCloseRightColumn}
          showCloseIcon={true}
        />
      </SplitLayout.Header>
      <SplitLayout.Body>
        <StudentMessageList
          sectionId={props.sectionId}
          student={props.messageThread.student}
        />
        <DirectMessageForm
          sectionId={props.sectionId}
          student={props.messageThread.student}
          operator={props.operator}
          operatorProfile={props.operatorProfile}
        />
      </SplitLayout.Body>
    </div>
  );
};

export default SectionStudentMessage;
