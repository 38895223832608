import classNames from "classnames";
import subDays from "date-fns/subDays";
import * as React from "react";
import { formatRecurrence } from "../../../../domains/Recurrence";
import { Schedule } from "../../../../domains/Schedule";
import { StudySchedule as StudyScheduleType } from "../../../../domains/StudySchedule";
import TimeHelper, {
  toDateString,
  toTimeString,
} from "../../../../helpers/TimeHelper";
import { Flex } from "../../../atoms/Flex";
import Icon from "../../../atoms/Icon";
import LinkText from "../../../atoms/LinkText";
import { Modal } from "../../../atoms/Modal";
import { Text } from "../../../general/Text";
import styles from "./DetailModalParts.scss";

export const ScheduleDetailModalBox = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return (
    <Modal.Body>
      <div className={styles.scheduleDetailModalBox}>{children}</div>
    </Modal.Body>
  );
};

export const Stack = ({ children }: React.PropsWithChildren<unknown>) => (
  <div className={styles["u-space-y-2"]}>{children}</div>
);

export const ScheduleTitle = ({
  children,
}: React.PropsWithChildren<unknown>) => (
  <div className={styles.Title}>{children}</div>
);

export const Title = ({ children }: React.PropsWithChildren<unknown>) => (
  <h3 className={styles.Title__summary}>{children}</h3>
);

export const TitleSectionName = ({
  children,
}: React.PropsWithChildren<unknown>) =>
  children ? (
    <div className={styles.Title__sectionName}>
      <Text size="sm">{children}</Text>
    </div>
  ) : null;

type LocaleProps = Pick<React.ComponentProps<typeof LinkText>, "children">;

export const Locale = ({ children }: LocaleProps) => (
  <div className={styles.Locale}>
    <Icon name="icon-place" />
    <LinkText>{children}</LinkText>
  </div>
);

export const Note = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <div className={styles.Description}>
      <Icon name="icon-memo" />
      {children}
    </div>
  );
};

type StudyScheduleProps = {
  studySchedule: StudyScheduleType | null;
};
export const StudySchedule = ({ studySchedule }: StudyScheduleProps) => {
  if (!studySchedule) {
    return null;
  }
  return (
    <div className={styles.StudySchedule}>
      <div className={styles.StudySchedule__learningMaterial}>
        <img src={studySchedule?.learningMaterial.imageUrl} />
      </div>
      <div>
        <b>{studySchedule.learningMaterial.name}</b>
        {studySchedule.numberOfSeconds ? (
          <Flex marginTop="0">
            {TimeHelper.secondsToDisplayTime(studySchedule?.numberOfSeconds)}
          </Flex>
        ) : null}
        {studySchedule.amount ? (
          <Flex marginTop="0">
            {studySchedule.amount}
            {studySchedule.learningMaterial.unit}
          </Flex>
        ) : null}
      </div>
    </div>
  );
};

type TimeProps = Pick<Schedule, "allday" | "startAt" | "endAt" | "recurrence">;

const ScheduleTime_ = ({ allday, startAt, endAt, recurrence }: TimeProps) => {
  const endDateString = allday
    ? toDateString(subDays(endAt, 1), "/")
    : toDateString(endAt, "/");

  return (
    <div className={styles.ScheduleTime}>
      <Icon name="icon-timeline" />
      <div>
        <time>
          <span className={styles["u-margin-right-1"]}>
            {toDateString(startAt, "/")}
          </span>
          {allday ? null : (
            <span className={styles["u-margin-right-0.5"]}>
              {toTimeString(startAt)}
            </span>
          )}
          〜
          <span
            className={classNames(
              styles["u-margin-right-1"],
              styles["u-margin-left-0.5"],
            )}
          >
            {endDateString}
          </span>
          {allday ? null : <span>{toTimeString(endAt)}</span>}
        </time>
        {recurrence ? <div>{formatRecurrence(recurrence)}</div> : null}
      </div>
    </div>
  );
};
export const ScheduleTime = React.memo(ScheduleTime_);

export const AdjustIconPositionWrapper = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <div className={styles["icon-adjust-margin"]}>{children}</div>;
};

export const Classroom = ({ children }: React.PropsWithChildren<unknown>) => (
  <Flex marginTop="0" alignItems="center">
    <AdjustIconPositionWrapper>
      <Icon name="icon-door" color="gray-darken-1" />
    </AdjustIconPositionWrapper>
    <Text bold>{children}</Text>
  </Flex>
);

export const Teacher = ({ children }: React.PropsWithChildren<unknown>) => (
  <Flex marginTop="0" alignItems="center">
    <AdjustIconPositionWrapper>
      <Icon name="icon-school-mini" color="gray-darken-1" />
    </AdjustIconPositionWrapper>
    <Text bold>{children}</Text>
  </Flex>
);
