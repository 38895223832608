import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
// import Tooltip from '../../atoms/Tooltip/index';
import SectionInterface from "../../../interfaces/SectionInterface";
import CircleIcon from "../../atoms/CircleIcon/index";
import Icon from "../../atoms/Icon";
import styles from "./styles.scss";

interface Props {
  opened: boolean;
  section: SectionInterface;
}

export default class NaviMenuSchool extends React.Component<Props, unknown> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Link to="/sections">
        <div
          className={classNames(styles.root, {
            [styles.opened]: this.props.opened,
          })}
        >
          {this.renderSchoolIcon()}
          {this.renderLabel()}
          {/* FIXME: CSSの問題でデザインをツールチップ表示が実現できないので一旦コメントアウトしている
            {this.renderTooltip()} */}
        </div>
      </Link>
    );
  }

  private renderSchoolIcon() {
    return (
      <div className={styles.iconWrapper}>
        <CircleIcon
          className={styles.iconReturnWrapper}
          iconProps={{
            name: "icon-return",
            iconClassName: styles.iconReturn,
          }}
        />
        <Icon name="icon-school" className={styles.iconSchool} />
      </div>
    );
  }

  private renderLabel() {
    return (
      <div className={styles.label}>
        <div className={styles.labelLeft}>
          <div className={styles.schoolName}>
            {this.props.section.schoolName}
          </div>
          <div className={styles.sectionName}>
            {this.props.section.sectionName}
          </div>
        </div>
        <div className={styles.labelRight}>
          <Icon name="icon-arrow-right" className={styles.iconRight} />
        </div>
      </div>
    );
  }

  // FIXME: FIXME: CSSの問題でデザインをツールチップ表示が実現できないので一旦コメントアウトしている
  // private renderTooltip() {
  //   if (this.state.hovering) {
  //     return (
  //       <Tooltip label="校舎変更" />
  //     )
  //   } else {
  //     return null
  //   }
  // }
}
