import * as React from "react";
import StudentBookshelf from "../../../components/organisms/StudentBookshelf/index";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";
import enhanceStudentsPage from "../../../hocs/enhanceStudentsPage";
import { useFetchBookshelfEntries } from "../../../hooks/http/useBookshelfEntries";
import { useQueryError } from "../../../hooks/http/useQueryError";
import StudentInterface from "../../../interfaces/StudentInterface";

interface Props
  extends WithRouterProps<{ studentId: string }>,
    OutletContextProps {
  student: StudentInterface | null;
}

const StudentsLearningMaterialsPage = ({
  params,
  student,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("text");
  }, []);
  const bookshelfEntriesState = useFetchBookshelfEntries(params.studentId, {
    type: "all",
  });
  useQueryError(bookshelfEntriesState.error);

  return (
    student && (
      <StudentBookshelf
        student={student}
        bookshelfEntriesState={bookshelfEntriesState}
      />
    )
  );
};

export default enhanceStudentsPage(StudentsLearningMaterialsPage, {
  title: "テキスト",
});
