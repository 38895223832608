import OperatorInterface from "../../../interfaces/OperatorInterface";
import Icon from "../../atoms/Icon/index";
import HeaderAccount from "../../molecules/HeaderAccount/index";
import { HeaderAccountTheme } from "../../molecules/HeaderAccount/index";
import styles from "./styles.scss";

interface Props {
  operators: OperatorInterface[];
  currentOperator: OperatorInterface;
}

const HomeHeader = (props: Props) => {
  return (
    <nav className={styles.root}>
      <HeaderAccount
        operators={props.operators}
        currentOperator={props.currentOperator}
        className={styles.headerAccount}
        theme={HeaderAccountTheme.White}
      />
      <div className={styles.help}>
        <a
          href="https://fs-help.studyplus.co.jp"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="icon-help" className={styles.iconHelp} />
        </a>
      </div>
    </nav>
  );
};

export default HomeHeader;
