import isAfter from "date-fns/isAfter";
import * as React from "react";
import { useCallback } from "react";
import Loader from "../../components/atoms/Loader";
import PrintButton from "../../components/atoms/PrintButton";
import { StudentAnalyticsTypeTab } from "../../components/features/StudentAnalyticsTypeTab";
import { DateRangeNavigation } from "../../components/general/DateRangeNavigation";
import SwitchTabs from "../../components/molecules/SwitchTabs";
import { isPushableCalendarNext } from "../../helpers/AnalyticsHelper";
import { OutletContextProps } from "../../helpers/RouterHelper";
import enhanceStudentsPage from "../../hocs/enhanceStudentsPage";
import {
  AnalyticsFilter,
  useAnalyticsFilter,
} from "../../hooks/filters/useAnalyticsFilter";
import StudentInterface from "../../interfaces/StudentInterface";
import { LectureAttendanceAnalyticsTable } from "./LectureAttendanceAnalyticsTable";
import styles from "./StudentsLectureAttendanceAnalyticsPage.scss";

type UseStudentsLectureAttendanceAnalyticsPageProps = {
  analyticsFilter?: AnalyticsFilter;
};

const useStudentsLectureAttendanceAnalyticsPage = ({
  analyticsFilter,
}: UseStudentsLectureAttendanceAnalyticsPageProps) => {
  const datePickerProps = React.useMemo(() => {
    if (analyticsFilter == null) return undefined;

    return {
      calendarDisabled: analyticsFilter.term !== "daily",
      canPushRightButton: isPushableCalendarNext(
        analyticsFilter.endDate,
        analyticsFilter.term,
      ),
    };
  }, [
    analyticsFilter?.term,
    analyticsFilter?.startDate,
    analyticsFilter?.endDate,
  ]);

  return {
    datePickerProps,
  };
};

type Props = OutletContextProps & {
  student: StudentInterface;
};

const StudentsAnalyticsLectureAttendancePage_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("analytics");
  }, []);
  const student = props.student;
  const { analyticsFilter, changeDates, changeTerm, changeOrder } =
    useAnalyticsFilter({
      sectionId: student.section.id,
      withStudentFilter: false,
    });

  const { datePickerProps } = useStudentsLectureAttendanceAnalyticsPage({
    analyticsFilter,
  });

  const handleDatesChange = useCallback(
    (dates: { startDate?: Date; endDate?: Date }) => {
      if (dates.startDate && dates.endDate) {
        changeDates({
          startDate: dates.startDate,
          endDate: dates.endDate,
        });
      }
    },
    [changeDates],
  );

  if (analyticsFilter === undefined || datePickerProps === undefined) {
    return <Loader loading />;
  }

  return (
    <>
      <div className={styles.TabWrapper}>
        <StudentAnalyticsTypeTab
          student={student}
          activeAnalyticsType={"lectureAttendance"}
        />
      </div>

      <div className={styles.TableControl}>
        <div className={styles.TableControl__LeftArea} />
        <DateRangeNavigation
          startDate={analyticsFilter.startDate}
          endDate={analyticsFilter.endDate}
          term={analyticsFilter.term}
          onDatesChange={handleDatesChange}
          isDayBlocked={(day: Date) => isAfter(day, new Date())}
          {...datePickerProps}
        />
        <div className={styles.TableControl__RightArea}>
          <SwitchTabs
            tabs={[
              {
                id: "daily",
                label: "日",
                active: analyticsFilter.term === "daily",
              },
              {
                id: "weekly",
                label: "週",
                active: analyticsFilter.term === "weekly",
              },
              {
                id: "monthly",
                label: "月",
                active: analyticsFilter.term === "monthly",
              },
            ]}
            onClickTab={(tab: any) => () => {
              changeTerm(tab.id);
            }}
          />
          <PrintButton />
        </div>
      </div>
      <div className={styles.MainContentsWrapper}>
        <div className={styles.TableWrapper}>
          <LectureAttendanceAnalyticsTable
            student={student}
            analyticsFilter={analyticsFilter}
            changeOrder={changeOrder}
          />
        </div>
      </div>
    </>
  );
};

const pageInfo = {
  title: "アナリティクス",
};

export const StudentsAnalyticsLectureAttendancePage = enhanceStudentsPage(
  StudentsAnalyticsLectureAttendancePage_,
  pageInfo,
);
