import { Link } from "react-router-dom";
import SectionInterface from "../../../interfaces/SectionInterface";
import SectionSummariesInterface, {
  SectionSummaryType,
  SectionSummaryInterface,
} from "../../../interfaces/SectionSummaryInterface";
import styleVars from "../../../styles/variables.scss";
import DoughnutChart from "../../atoms/DoughnutChart/index";
import Icon from "../../atoms/Icon/index";
import styles from "./styles.scss";

interface Props {
  section: SectionInterface;
  sectionSummaries: SectionSummariesInterface | undefined;
  type: SectionSummaryType;
}

const SectionDashboardChart = (props: Props) => {
  if (!props.sectionSummaries) {
    return null;
  }

  const summary = props.sectionSummaries[props.type];

  return (
    <Link to={getLink(props)}>
      <div className={styles.root}>
        <h2 className={styles.heading}>
          {getSummaryName(props)}
          <span className={styles.quiet}>（昨日）</span>
        </h2>
        <DoughnutChart
          ratio={summary.ratio}
          options={{
            primaryColor: getSummaryColor(props),
            textColor: styleVars.colorGrayDarken3,
          }}
        />
        <div className={styles.data}>
          <div className={styles.count}>
            <span className={styles.count__number}>{summary.studentCount}</span>
            人
            {summary.karteCount !== undefined ? (
              <>（{summary.karteCount}件）</>
            ) : null}
          </div>
          <div className={styles.diff}>
            <span className={styles.diff__label}>前日比</span>
            {renderDiffValue(summary)}
          </div>
        </div>
      </div>
    </Link>
  );
};

const renderDiffValue = (summary: SectionSummaryInterface) => {
  if (summary.diff > 0) {
    return (
      <span className={`${styles.diff__value} ${styles.positive}`}>
        <Icon name="icon-arrow-rate-up" />
        {summary.diff}
      </span>
    );
  } else if (summary.diff < 0) {
    return (
      <span className={`${styles.diff__value} ${styles.negative}`}>
        <Icon name="icon-arrow-rate-down" />
        {summary.diff * -1}
      </span>
    );
  } else {
    return (
      <span className={`${styles.diff__value}`}>
        <Icon name="icon-arrow-rate-stay" />
        {summary.diff}
      </span>
    );
  }
};

const getSummaryName = (props: Props): string => {
  switch (props.type) {
    case "studyRecord":
      return "学習記録人数";
    case "karte":
      return "カルテ入力人数";
    default:
      return "";
  }
};

const getSummaryColor = (props: Props): string => {
  switch (props.type) {
    case "studyRecord":
      return "#F37A89";
    case "karte":
      return "#85C5DE";
    default:
      return "";
  }
};

const getLink = (props: Props): string => {
  switch (props.type) {
    case "studyRecord":
      return `/sections/${props.section.id}/analytics`;
    case "karte":
      return `/sections/${props.section.id}/kartes`;
    default:
      return "";
  }
};

export default SectionDashboardChart;
