import isThisMonth from "date-fns/isThisMonth";
import isThisWeek from "date-fns/isThisWeek";
import * as React from "react";
import SwitchTabs from "../../../components/molecules/SwitchTabs/index";
import { SwitchTabsProps } from "../../../components/molecules/SwitchTabs/index";
import { TermType } from "../../../domains/QueryDateRange";
import {
  formatDate,
  formatMonth,
  formatWeek,
} from "../../../helpers/TimeHelper";
import { DateRangeNavigation } from "../../general/DateRangeNavigation";
import { SingleDatePickerWrapper } from "../../molecules/SingleDatePickerWrapper";
import styles from "./styles.scss";
import {
  UseStudentStudyScheduleHeaderProps,
  UseStudentStudyScheduleHeaderReturn,
  useStudentStudyScheduleHeader,
} from "./useStudentStudyScheduleHeader";

type Props = UseStudentStudyScheduleHeaderProps & {
  leftContent?: React.ReactNode;
};

const StudyScheduleProgressHeader_ = (props: Props): React.ReactElement => {
  const { onChangeDateRange, onChangeSingleDate, onChangeTermType } =
    useStudentStudyScheduleHeader(props);

  return (
    <>
      <HeaderForDesktop
        onChangeDateRange={onChangeDateRange}
        onChangeSingleDate={onChangeSingleDate}
        onChangeTermType={onChangeTermType}
        {...props}
      />
      <HeaderForMobile
        onChangeDateRange={onChangeDateRange}
        onChangeSingleDate={onChangeSingleDate}
        onChangeTermType={onChangeTermType}
        {...props}
      />
    </>
  );
};

type HeaderProps = Props &
  Pick<
    ReturnType<typeof useStudentStudyScheduleHeader>,
    "onChangeDateRange" | "onChangeSingleDate" | "onChangeTermType"
  >;

const HeaderForDesktop: React.FC<HeaderProps> = (props) => {
  const { type, onChangeDateRange, onChangeSingleDate, onChangeTermType } =
    props;

  return (
    <div className={`${styles.tableControl} ${styles.desktop}`}>
      {renderLeftContent({ leftContent: props.leftContent })}
      {renderDatePicker({ ...props, onChangeDateRange, onChangeSingleDate })}
      {renderSwithTabs({ type, onChangeTermType })}
    </div>
  );
};

const HeaderForMobile: React.FC<HeaderProps> = (props) => {
  const { type, onChangeDateRange, onChangeSingleDate, onChangeTermType } =
    props;

  return (
    <div className={`${styles.tableControl} ${styles.mobile}`}>
      {renderDatePicker({ ...props, onChangeDateRange, onChangeSingleDate })}
      {renderSwithTabs({ type, onChangeTermType })}
      {renderLeftContent({ leftContent: props.leftContent })}
    </div>
  );
};

const renderLeftContent: React.FC<{ leftContent?: Props["leftContent"] }> = (
  props,
) => <div className={styles.leftContent}>{props.leftContent}</div>;

const renderSwithTabs: React.FC<{
  type: Props["type"];
  onChangeTermType: ReturnType<
    typeof useStudentStudyScheduleHeader
  >["onChangeTermType"];
}> = (props) => {
  const { type } = props;
  return (
    <div className={styles.switchTabs}>
      <SwitchTabs
        tabs={[
          {
            id: "daily",
            label: "日",
            active: type === "daily",
          },
          {
            id: "weekly",
            label: "週",
            active: type === "weekly",
          },
          {
            id: "monthly",
            label: "月",
            active: type === "monthly",
          },
        ]}
        onClickTab={(tab: SwitchTabsProps<TermType>) => () => {
          props.onChangeTermType(tab.id);
        }}
      />
    </div>
  );
};

type PickerCallbacks = Pick<
  UseStudentStudyScheduleHeaderReturn,
  "onChangeDateRange" | "onChangeSingleDate"
>;
const renderDatePicker = (
  props: Props & PickerCallbacks,
): React.ReactElement => {
  const { type, startDate, endDate, onChangeDateRange, onChangeSingleDate } =
    props;
  switch (type) {
    case "monthly":
      return (
        <DateRangeNavigation
          startDate={startDate}
          endDate={endDate}
          term={type}
          onDatesChange={onChangeDateRange}
          calendarDisabled={true}
          isDayBlocked={() => false}
          canPushRightButton={true}
          label={formatCurrentLabel(startDate, type)}
          changeOnNavigate={1}
        />
      );
    case "weekly":
      return (
        <DateRangeNavigation
          startDate={startDate}
          endDate={endDate}
          term="daily"
          onDatesChange={onChangeDateRange}
          calendarDisabled={false}
          isDayBlocked={() => false}
          canPushRightButton={true}
          changeOnNavigate={7}
        />
      );
    case "daily":
    default:
      return (
        <SingleDatePickerWrapper
          date={startDate}
          blockType="Nothing"
          onDateChange={onChangeSingleDate}
        />
      );
  }
};

const formatCurrentLabel = (date: Date, type: TermType): string => {
  switch (type) {
    case "monthly":
      if (isThisMonth(date)) {
        return "今月";
      } else {
        return formatMonth(date);
      }
    case "weekly":
      if (isThisWeek(date)) {
        return "今週";
      } else {
        return formatWeek(date);
      }
    case "daily":
    default:
      return formatDate(date);
  }
};

export const StudyScheduleProgressHeader = React.memo(
  StudyScheduleProgressHeader_,
);
