import { HandleThunkActionCreator } from "react-redux";
import { connect } from "react-redux";
import {
  updateStudent,
  updateStudentTags,
} from "../../../actions/common/student";
import StudentTag, {
  StudentTagFilterInterface,
} from "../../../domains/StudentTag";
import { WithRouterProps } from "../../../helpers/RouterHelper";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { OperatorPrivilegeInfoInterface } from "../../../interfaces/OperatorInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import { StudentWithStayInterface } from "../../../interfaces/StudentInterface";
import NaviMenuList from "../../atoms/NaviMenuList/index";
import SidebarLayout from "../../atoms/SidebarLayout/index";
import NaviBack from "../../molecules/NaviBack/index";
import NaviMenuListItem from "../../molecules/NaviMenuListItem/index";
import StudentNavTagList from "../../molecules/StudentNavTagList/index";
import StudentNaviProfile from "../../molecules/StudentNaviProfile/index";

interface Props extends WithRouterProps {
  student: StudentWithStayInterface;
  section: SectionInterface;
  operatorPrivileges: OperatorPrivilegeInfoInterface;
  tagFilter: StudentTagFilterInterface;
  activeMenu: string;
  opened: boolean;
  updateStudentTags: HandleThunkActionCreator<typeof updateStudentTags>;
  updateStudent: HandleThunkActionCreator<typeof updateStudent>;
  submitting: boolean;
  apiErrors: ApiErrorInterface[];
}

const actions = {
  updateStudentTags,
  updateStudent,
};

const mapStateToProps = (state: AppStateInterface) => ({
  submitting: state.student.submitting,
  apiErrors: state.student.apiErrors,
});

const StudentNavi = (props: Props) => {
  const studentTagIds = props.student.tags.map((tag) => tag.id);
  const studentTags = props.tagFilter.tags.filter((tag: StudentTag) =>
    studentTagIds.includes(tag.id),
  );

  return (
    <SidebarLayout opened={props.opened} scrollable={true} collapsible={false}>
      <NaviBack section={props.section} />
      <StudentNaviProfile
        privileges={props.operatorPrivileges}
        student={props.student}
        section={props.section}
        submitting={props.submitting}
        apiErrors={props.apiErrors}
        updateStudent={props.updateStudent}
      />
      <NaviMenuList>
        <NaviMenuListItem
          opened={props.opened}
          isActive={props.activeMenu === "timeline"}
          label="タイムライン"
          iconName="icon-timeline"
          link={`/students/${props.student.id}`}
          collapsible={false}
        />
        <NaviMenuListItem
          opened={props.opened}
          isActive={props.activeMenu === "analytics"}
          label="アナリティクス"
          iconName="icon-analytics"
          link={`/students/${props.student.id}/analytics`}
          collapsible={false}
        />
        <NaviMenuListItem
          opened={props.opened}
          isActive={props.activeMenu === "calendar"}
          label="カレンダー"
          iconName="icon-calendar"
          link={`/students/${props.student.id}/calendar`}
          collapsible={false}
        />
        <NaviMenuListItem
          opened={props.opened}
          isActive={props.activeMenu === "study_schedules"}
          label="プランニング"
          iconName="icon-planning"
          link={`/students/${props.student.id}/study_schedules`}
          collapsible={false}
        />
        <NaviMenuListItem
          opened={props.opened}
          isActive={props.activeMenu === "karte"}
          label="カルテ"
          iconName="icon-karte"
          link={`/students/${props.student.id}/kartes`}
          collapsible={false}
        />
        <NaviMenuListItem
          opened={props.opened}
          isActive={props.activeMenu === "text"}
          label="テキスト"
          iconName="icon-textbook"
          link={
            props.student.status === "active"
              ? `/students/${props.student.id}/learning_materials?type=in_progress`
              : `/students/${props.student.id}/learning_materials/pre_registrations`
          }
          collapsible={false}
        />
      </NaviMenuList>
      {props.operatorPrivileges.privileges.includes("tag:index") ? (
        <StudentNavTagList
          selectedTags={studentTags}
          sectionTags={props.tagFilter.tags}
          student={props.student}
          submitting={props.submitting}
          updateStudentTags={props.updateStudentTags}
        />
      ) : null}
    </SidebarLayout>
  );
};

export default connect(mapStateToProps, actions)(StudentNavi);
