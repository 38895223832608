import { useParams } from "react-router";
import Loader from "../../components/atoms/Loader";
import enhanceHomePage from "../../hocs/enhanceHomePage";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useErrorDispatcher } from "../../hooks/useErrorDispatcher";
import { Detail } from "./Detail";
import { useMultiSectionBatchesDetail } from "./useMultiSectionBatchesDetail";

const Index = () => {
  const { batchId = "" } = useParams();
  const { data, isLoading, error } = useMultiSectionBatchesDetail({
    batchId,
  });
  useQueryError(error);
  const { sendNotFound } = useErrorDispatcher();

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  switch (data?.multiSectionBatchHistory?.batchType) {
    case "download_study_records":
    case "download_stays":
    case "download_kartes":
    case "download_students":
    case "download_examination_results":
    case "download_section_usage_rates":
      return <Detail batch={data.multiSectionBatchHistory} />;
    default:
      // 404ページを表示させる
      sendNotFound();
      return null;
  }
};

export const MultiSectionBatchesDetailPage = enhanceHomePage(Index, {
  title: "校舎横断操作",
});
