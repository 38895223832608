import { ExaminationResult } from "../../../domains/ExaminationResult";
import Table, { TableColumn } from "../../atoms/Table";

type ExaminationResultTableProps = {
  examinationResult: ExaminationResult;
};

export const ExaminationResultTable = ({
  examinationResult,
}: ExaminationResultTableProps) => {
  return (
    <Table
      headers={[
        {
          text: "科目名",
          centering: true,
          key: "subjectResultName",
        },
        { text: "得点", centering: true, key: "point" },
        { text: "偏差値", centering: true, key: "deviation" },
      ]}
      rows={TableRows(examinationResult)}
    />
  );
};

const TableRows = (examinationResult: ExaminationResult) => {
  const totalResult = {
    id: examinationResult.id,
    name: "総合",
    point: examinationResult.point,
    allocationOfMarks: examinationResult.allocationOfMarks,
    deviation: examinationResult.deviation,
  };

  const subjectResults = examinationResult.subjectResults.map(
    (subjectResult) => ({
      id: subjectResult.id,
      name: subjectResult.subject.name,
      point: subjectResult.point,
      allocationOfMarks: subjectResult.allocationOfMarks,
      deviation: subjectResult.deviation,
    }),
  );

  return [totalResult, ...subjectResults].map((result) => (
    <TableRow key={result.id} {...result} />
  ));
};

type TableRowProps = {
  id: string;
  name: string;
  point: number | null;
  allocationOfMarks: number | null;
  deviation: number | null;
};

const TableRow = (props: TableRowProps) => (
  <tr>
    <TableColumn className="w-1/3">{props.name}</TableColumn>
    <TableColumn centering>
      <div className="whitespace-nowrap">
        {props.point}
        <span className="text-[12px] text-gray-700">
          {" "}
          / {props.allocationOfMarks}
        </span>
      </div>
    </TableColumn>
    <TableColumn centering>{props.deviation}</TableColumn>
  </tr>
);
