import { Button } from "@studyplus/boron-ui";
import { Schedule } from "../../../../domains/Schedule";
import { ConfirmUpdateTypeModal } from "./";
import {
  UseUpdateWithRecurrencesProps,
  useUpdateWithRecurrences,
} from "./useUpdateWithReccurences";

export type Props = {
  onClickGoBackToForm: (schedule: Schedule) => void;
} & UseUpdateWithRecurrencesProps;

export const ConfirmFromForm = (props: Props) => {
  const state = useUpdateWithRecurrences(props);

  return (
    <ConfirmUpdateTypeModal
      headerLabel="繰り返し予定の編集"
      onClose={props.onClose}
      buttonsArea={(chosen) => (
        <>
          <div>
            <Button
              variant="outline"
              onClick={() => {
                props.onClickGoBackToForm(state.schedule);
              }}
            >
              戻る
            </Button>
          </div>
          <div>
            <Button
              disabled={state.isUpdating}
              isLoading={state.isUpdating}
              onClick={() => state.onSubmit(chosen)}
            >
              更新
            </Button>
          </div>
        </>
      )}
    />
  );
};
