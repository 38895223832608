import endOfDay from "date-fns/endOfDay";
import endOfWeek from "date-fns/endOfWeek";
import format from "date-fns/format";
import isBefore from "date-fns/isBefore";
import ja from "date-fns/locale/ja";
import parseISO from "date-fns/parseISO";
import startOfDay from "date-fns/startOfDay";
import {
  AnalyticsTermType,
  AnalyticsType,
} from "../interfaces/AnalyticsTableInterface";
import { OrderDirFilterType } from "../interfaces/FiltersInterface";
import styles from "./AnalyticsHeatmapColumn.scss";

const AnalyticsHelper = {
  formatTermDate: (date: Date, term?: AnalyticsTermType): string => {
    switch (term) {
      case "monthly":
        return format(date, "M月", { locale: ja });
      case "weekly":
        return format(date, "M/d週", { locale: ja });
      case "daily":
      default:
        return format(date, "M/d(E)", { locale: ja });
    }
  },

  getRankColorNumber: (rank: number): number => {
    const maxNumber = 20;

    if (rank > maxNumber) {
      return rank % maxNumber;
    } else {
      return rank;
    }
  },
};

export const getHeatmapClass = (deviation: number, score: number): string => {
  if (score === 0) {
    return styles.level15;
  }
  if (deviation >= 70) {
    return styles.level05;
  } else if (deviation >= 66) {
    return styles.level04;
  } else if (deviation >= 62) {
    return styles.level03;
  } else if (deviation >= 58) {
    return styles.level02;
  } else if (deviation >= 54) {
    return styles.level01;
  } else if (deviation >= 50) {
    return styles.level00;
  } else if (deviation >= 46) {
    return styles.level11;
  } else if (deviation >= 42) {
    return styles.level12;
  } else if (deviation >= 38) {
    return styles.level13;
  } else if (deviation >= 34) {
    return styles.level14;
  } else {
    return styles.level15;
  }
};

export const determineColorWithRank = (rank: number) => {
  const maxStyleVarNumber = 20;
  let styleVarName = `color${rank}`;

  if (rank > maxStyleVarNumber) {
    styleVarName = `color${rank % maxStyleVarNumber}`;
  }

  return (styles as any)[styleVarName];
};

export default AnalyticsHelper;

export const toHeaderDate = (
  column: string,
  term?: AnalyticsTermType,
): string => {
  const date = parseISO(column);
  return AnalyticsHelper.formatTermDate(date, term);
};

export const calculateDeviation = (
  nullableScores: ReadonlyArray<number | null>,
  score: number,
) => {
  const length = nullableScores.length;
  const scores = nullableScores.map((score) => score ?? 0);
  const average =
    scores.reduce((accum, score) => accum + (score ?? 0), 0) / length;

  const variance =
    scores.reduce((accum, score) => accum + Math.pow(score - average, 2), 0) /
    length;
  const standardDeviation = Math.sqrt(variance);

  return ((score - average) / standardDeviation) * 10 + 50;
};

export const isPushableCalendarNext = (
  endDate: Date,
  term: AnalyticsTermType,
) => {
  const today = startOfDay(new Date());

  switch (term) {
    case "monthly":
      return isBefore(endOfDay(endDate), today);
    case "weekly":
      return isBefore(endOfWeek(endDate), today);
    case "daily":
    default:
      return isBefore(endDate, today);
  }
};

export const toAnalyticsScoreTypeName = (analyticsType: AnalyticsType) => {
  switch (analyticsType) {
    case "amount":
      return "学習量";
    case "time":
    default:
      return "学習時間";
  }
};

type DetermineSortOrderParams = {
  currentSortKey: string | null;
  sortKey: string | null;
  currentOrder: OrderDirFilterType;
};
export const determineDateColumnSortOrder = (
  params: DetermineSortOrderParams,
) => {
  let tmpSortOrder: OrderDirFilterType = "";
  if (params.currentSortKey === params.sortKey) {
    tmpSortOrder = params.currentOrder;
  }

  return tmpSortOrder === "desc" ? "asc" : "desc";
};

export const determineAnalyticsTypeColumnSortOrder = (
  currentSortOrder: OrderDirFilterType,
) => {
  return !currentSortOrder || currentSortOrder === "desc" ? "asc" : "desc";
};
