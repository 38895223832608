import * as React from "react";
import Icon from "../../atoms/Icon";
import LinkButton from "../../atoms/LinkButton";
import styles from "./ModalDetailHeaderButtons.scss";

type LinkButtonProps = React.ComponentProps<typeof LinkButton>;

type CommonProps = Pick<LinkButtonProps, "onClick">;

export const ModalDetailHeader = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <header className={styles.header}>{children}</header>;
};

export const EditButton = ({ onClick }: CommonProps) => {
  return (
    <LinkButton onClick={onClick} aria-label="予定を編集する">
      <Icon name="icon-edit" color="gray-darken-1" />
    </LinkButton>
  );
};

export const TrashButton = ({ onClick }: CommonProps) => {
  return (
    <LinkButton onClick={onClick} aria-label="予定を削除する">
      <Icon name="icon-trash" color="gray-darken-1" />
    </LinkButton>
  );
};

export const CloseButton = ({ onClick }: CommonProps) => {
  return (
    <LinkButton onClick={onClick} aria-label="モーダルを閉じる">
      <Icon name="icon-navi-menu-close" color="gray-darken-1" />
    </LinkButton>
  );
};
