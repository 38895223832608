import * as React from "react";

import { IconArrowDropDown, IconHome } from "@studyplus/boron-ui";
import classNames from "classnames";
import Backdrop from "../../atoms/Backdrop";
import Checkbox from "../../atoms/Checkbox";
import styles from "./styles.scss";
import {
  FieldVisibilityToggleId,
  useFieldVisibilityToggleContext,
} from "./useFieldVisibilityToggleContext";

type Props = {
  id: FieldVisibilityToggleId;
  fieldNames: string[];
};
export const FieldVisibilityToggle = ({ id, fieldNames }: Props) => {
  const { opened, toggleOpened, isInvisible, toggleFieldVisibility } =
    useFieldVisibilityToggle(id);
  return (
    <>
      <ToggleButton opened={opened} onClick={toggleOpened} />
      {opened && (
        <div className="relative">
          <Backdrop
            onClick={toggleOpened}
            className={`z-10 ${styles.backdrop}`}
          />
          <DropdownContent
            fieldNames={fieldNames}
            isInvisible={isInvisible}
            toggleFieldVisibility={toggleFieldVisibility}
            toggleOpened={toggleOpened}
          />
        </div>
      )}
    </>
  );
};

const useFieldVisibilityToggle = (id: FieldVisibilityToggleId) => {
  const [opened, setOpened] = React.useState(false);
  const {
    isInvisible,
    setCurrentFieldVisibilityToggleId,
    toggleFieldVisibility,
  } = useFieldVisibilityToggleContext();
  setCurrentFieldVisibilityToggleId(id);
  const toggleOpened = () => setOpened(!opened);

  return {
    opened,
    toggleOpened,
    isInvisible,
    toggleFieldVisibility,
  };
};

const ToggleButton = ({
  opened,
  onClick,
}: {
  opened: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      className={classNames(
        "group h-[3.2rem] whitespace-nowrap rounded-sm border border-solid bg-white px-1 text-gray-900 hover:border-blue-400",
        {
          "border-gray-400": !opened,
          "border-blue-400": opened,
        },
      )}
      onClick={onClick}
    >
      <IconHome
        className={classNames("mb-[-2px] mt-[2px] group-hover:text-blue-400", {
          "text-blue-400": opened,
        })}
      />
      <IconArrowDropDown
        className={classNames(
          "mx-[-4px] mb-[-2px] mt-[2px] group-hover:text-blue-400",
          {
            "text-blue-400": opened,
          },
        )}
      />
    </button>
  );
};

type DrowdownContentProps = Omit<Props, "id"> & {
  isInvisible: (fieldName: string) => boolean;
  toggleFieldVisibility: (fieldNames: string) => void;
  toggleOpened: () => void;
};
const DropdownContent = ({
  fieldNames,
  isInvisible,
  toggleFieldVisibility,
  toggleOpened,
}: DrowdownContentProps) => {
  return (
    <div className={styles.dropdown}>
      {fieldNames.map((fieldName) => (
        <div
          key={fieldName}
          className={styles.dropdownRow}
          onClick={() => toggleFieldVisibility(fieldName)}
        >
          <Checkbox checked={!isInvisible(fieldName)}>
            <span>{fieldName}</span>
          </Checkbox>
        </div>
      ))}
      <div className={styles.dropdownSeparator} />
      <div
        className={`${styles.dropdownRow} ${styles.dropdownCloseButton}`}
        onClick={toggleOpened}
      >
        閉じる
      </div>
    </div>
  );
};
