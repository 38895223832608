import * as React from "react";

import { Button } from "@studyplus/boron-ui";
import ApiClient from "../../../../api";
import ContentBox from "../../../../components/atoms/ContentBox";
import Icon from "../../../../components/atoms/Icon";
import {
  ImportHistoryInterface,
  helpUrl,
  renderOperationHuman,
} from "../../../../domains/ImportHistory";
import SectionInterface from "../../../../interfaces/SectionInterface";
import UploadForm from "./UploadForm";
import styles from "./styles.scss";

import format from "date-fns/format";
import { IconImportOperation } from "../../../../features/ImportHistory/IconImportOperation";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { ExaminationResultTemplateDownloadForm } from "./ExaminationResultTemplateDownloadForm";
import { OperationNote } from "./OperationNote";

interface Props {
  section: SectionInterface;
  operation: ImportHistoryInterface["operation"];
}

const ImportOperation = (props: Props) => {
  return (
    <ContentBox className={styles.root}>
      <h2 className={styles.heading}>
        <IconImportOperation
          className="mr-6 h-[3rem] w-[3rem] text-gray-600"
          operation={props.operation}
        />
        {renderOperationHuman(props.operation)}
      </h2>
      <p className={styles.help}>
        操作方法を確認しながら進めてください。
        <a
          href={helpUrl(props.operation)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon name="icon-help" />
          操作方法を見る
        </a>
      </p>
      <OperationNote operation={props.operation} />
      <DownloadForm {...props} />
      <UploadForm {...props} />
    </ContentBox>
  );
};

const DownloadForm: React.FC<Props> = (props: Props) => {
  let title = "1. テンプレートのサンプルファイルをダウンロード";
  let children = null;
  const { section, operation } = props;

  const { showErrorMessage } = useFlashMessage();

  const buttons: Array<{ name: string; link: string; disabled?: boolean }> = [];

  const [templateDownloadLink, setTemplateDownloadLink] =
    React.useState<string>("");
  switch (operation) {
    case "student_insert":
      buttons.push({
        name: "CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/student_template.csv`,
      });
      buttons.push({
        name: "タグCSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/tags.csv`,
      });
      break;
    case "student_update":
      title = "1. 生徒一覧のファイルをダウンロード";
      buttons.push({
        name: "CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/students.csv`,
      });
      break;
    case "tag_insert":
      buttons.push({
        name: "CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/tag_template.csv`,
      });
      break;
    case "tag_update":
      title = "1. タグ一覧のファイルをダウンロード";
      buttons.push({
        name: "CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/tags.csv`,
      });
      break;
    case "learning_material_insert":
      buttons.push({
        name: "CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/learning_material_template.csv`,
      });
      buttons.push({
        name: "タグCSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/learning_material_tags.csv`,
      });
      break;
    case "learning_material_update":
      title = "1. 独自教材一覧のファイルをダウンロード";
      buttons.push({
        name: "CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/learning_materials.csv`,
      });
      buttons.push({
        name: "タグCSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/learning_material_tags.csv`,
      });
      break;
    case "student_tag_insert":
      buttons.push({
        name: "テンプレートCSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/student_tag_template.csv`,
      });
      buttons.push({
        name: "生徒CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/students.csv`,
      });
      buttons.push({
        name: "タグCSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/tags.csv`,
      });
      break;
    case "student_tag_delete":
      buttons.push({
        name: "CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/student_tags.csv`,
      });
      break;
    case "bookshelf_insert":
      buttons.push({
        name: "テンプレートCSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/bookshelf_template.csv`,
      });
      buttons.push({
        name: "生徒CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/students.csv`,
      });
      buttons.push({
        name: "教材CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/bookshelf_registrable_learning_materials.csv`,
      });
      break;
    case "drill_learning_material_insert":
      // TODO: デジタル教材の一括登録実装時に修正
      break;
    case "student_billing_plan_update":
      title = "1. 生徒一覧のファイルをダウンロード";
      buttons.push({
        name: "テンプレートCSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/student_billing_plan_template.csv`,
      });
      buttons.push({
        name: "生徒CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/student_billing_plans.csv`,
      });
      break;
    case "examination_result_insert":
      title =
        "1. 成績記録のテンプレートを選択し、サンプルファイルをダウンロード";
      buttons.push({
        name: "テンプレートCSVダウンロード",
        link: templateDownloadLink,
        disabled: !templateDownloadLink,
      });
      buttons.push({
        name: "生徒CSVダウンロード",
        link: `/api/v1/sections/${section.id}/exports/students.csv`,
      });
      children = (
        <ExaminationResultTemplateDownloadForm
          handleSetTemplateDownloadLink={(
            examinationId: string | null,
            examinedOn: Date | undefined,
          ) => {
            if (examinationId && examinedOn) {
              setTemplateDownloadLink(
                `/api/v1/sections/${
                  section.id
                }/exports/examination_result_template.csv?examination_id=${examinationId}&examined_on=${format(
                  examinedOn,
                  "yyyy-MM-dd",
                )}"}`,
              );
            } else {
              setTemplateDownloadLink("");
            }
          }}
        />
      );
      break;
    default: {
      const unexpectedOperation: string | undefined = operation;
      throw new Error(`Illegal parameter error: "${unexpectedOperation}`);
    }
  }

  return (
    <div className={styles.box}>
      <div className={styles.boxHeading}>{title}</div>
      {children}
      <div className={styles.buttons}>
        {buttons.map(({ name, link, disabled = false }) => {
          return (
            <Button
              key={`download-button-${name}`}
              className={styles.downloadButton}
              disabled={disabled}
              variant="outline"
              size="sm"
              isRound={true}
              onClick={handleClickDownloadButton(name, link, showErrorMessage)}
            >
              {name}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

const handleClickDownloadButton =
  (name: string, link: string, showErrorMessage: (message: string) => void) =>
  () => {
    ApiClient.get(link)
      .then((res) => res.blob())
      .then((blob) => {
        const a = document.createElement("a");
        const blobUrl = window.URL.createObjectURL(
          new Blob([blob], {
            type: blob.type,
          }),
        );
        a.style.display = "none";
        a.href = blobUrl;
        a.download = `${name}_${format(new Date(), "yyyyMMdd_HHmmss")}.csv`;
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => {
        console.error(error);
        showErrorMessage("ダウンロードできませんでした");
      });
  };

export default ImportOperation;
