import classNames from "classnames";
import { DrillProgressBarGroup } from "../../components/features/DrillProgressBarGroup";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../components/general/Popover/Popover";
import { Progress, calcTotalCount } from "../../domains/DrillProgress";
import TimeHelper from "../../helpers/TimeHelper";

type Props = {
  drillSectionName: string;
  duration: number;
  progress: Progress;
  isGrayedOut: boolean;
};
export const RetentionLevelCell = ({
  drillSectionName,
  duration,
  progress,
  isGrayedOut,
}: Props) => {
  const totalCount = calcTotalCount(progress);

  return (
    <div className="flex h-full flex-col border-0 border-r border-solid border-gray-400">
      <DrillSectionName name={drillSectionName} isGrayedOut={isGrayedOut} />
      <Duration duration={duration} isGrayedOut={isGrayedOut} />
      <div className={classNamesWithGrayOut("flex w-full", isGrayedOut)}>
        <DrillProgressBarGroup totalCount={totalCount} progress={progress} />
      </div>
    </div>
  );
};

const DrillSectionName = ({
  name,
  isGrayedOut,
}: {
  name: string;
  isGrayedOut: boolean;
}) => {
  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger asChild className="cursor-pointer">
          <div
            className={classNamesWithGrayOut(
              "overflow-hidden text-ellipsis whitespace-nowrap px-1",
              isGrayedOut,
            )}
          >
            {name}
          </div>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent side="top">
            <div className="max-w-[32rem] break-all text-center">{name}</div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};

const Duration = ({
  duration,
  isGrayedOut,
}: {
  duration: number;
  isGrayedOut: boolean;
}) => {
  const formatedDuration = TimeHelper.secondsToDisplayTime(duration);
  return (
    <div className="flex min-h-[4rem] flex-auto items-center justify-center border-0 border-t border-solid border-gray-400">
      <div className={classNamesWithGrayOut("", isGrayedOut)}>
        {formatedDuration}
      </div>
    </div>
  );
};

const classNamesWithGrayOut = (className: string, isGrayedOut: boolean) =>
  classNames(className, { "opacity-50": isGrayedOut });
