import * as queryString from "query-string";
import StudentTag from "../domains/StudentTag";

export default class TagEntity {
  tag: StudentTag;

  constructor(tag: StudentTag) {
    this.tag = tag;
  }

  toQueryString(): string {
    const params = this.toQueryParams();
    const str = queryString.stringify(params, { arrayFormat: "bracket" });
    return str ? `?${str}` : "";
  }

  toQueryParams() {
    switch (this.tag.type) {
      case "job":
        return {
          job: [this.tag.id],
        };
      case "status":
        return {
          status: [this.tag.id],
        };
      case "tag_ids":
        return {
          tag_ids: [this.tag.id],
        };
      case "smart_tag":
        return {
          smart_tag: [this.tag.id],
        };
      default:
        return {
          tag_ids: [this.tag.id],
        };
    }
  }
}
