import classNames from "classnames";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./NameLink.scss";

export const NameLink = ({
  children,
  ...props
}: React.PropsWithChildren<LinkProps>) => {
  return (
    <Link className={classNames(styles.nameLink, props.className)} {...props}>
      {children}
    </Link>
  );
};
