import { Button } from "@studyplus/boron-ui";
import {
  ErrorMessage,
  Field,
  Form,
  FormikBag,
  FormikProps,
  withFormik,
} from "formik";
import * as React from "react";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import ErrorText from "../../atoms/ErrorText";
import Input from "../../atoms/Input";
import styles from "./styles.scss";
import validationSchema from "./validationSchema";

interface ComponentProps {
  sectionId: string;
  submitting: boolean;
  apiErrors: ApiErrorInterface[];
  inviteOperator: (
    sectionId: string,
    email: string,
    resetFrom: () => void,
  ) => void;
}

type Props = ComponentProps & FormikProps<Values>;

interface Values {
  email: string;
}

class InviteOperatorForm extends React.Component<Props> {
  render() {
    return (
      <div className={styles.root}>
        {this.renderApiErrors()}
        <ErrorMessage name="email" component={ErrorText} />
        <Form>
          <Field
            type="email"
            name="email"
            component={Input}
            className={styles.input}
            placeholder={"招待するメールアドレスを入力"}
          />
          <Button
            disabled={!this.props.isValid || this.props.submitting}
            className={styles.btn}
            type="submit"
          >
            メンバー招待
          </Button>
        </Form>
      </div>
    );
  }

  private renderApiErrors = () => {
    if (this.props.apiErrors.length > 0) {
      return this.props.apiErrors.map((err: ApiErrorInterface, idx: number) => {
        return <ErrorText key={`inviteError-${idx}`}>{err.message}</ErrorText>;
      });
    } else {
      return null;
    }
  };
}

const mapPropsToValues = (): Values => ({
  email: "",
});

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
) => {
  const { props } = formikBag;
  props.inviteOperator(props.sectionId, values.email, formikBag.resetForm);
};
export default withFormik({ mapPropsToValues, handleSubmit, validationSchema })(
  InviteOperatorForm,
);
