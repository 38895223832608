import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../api";
import { HTTPErrors, createError } from "../../../errors";

import SectionSummariesInterface from "../../../interfaces/SectionSummaryInterface";

type Props = {
  sectionId: string;
};

export const useFetchLoadSummaries = ({ sectionId }: Props) => {
  return useQuery<SectionSummariesInterface, HTTPErrors>({
    queryKey: cacheKeyOf(sectionId),
    queryFn: async () => {
      const response = await ApiClient.get(
        `/api/v1/sections/${sectionId}/summaries`,
      );

      if (response.ok) {
        const json = (await response.json()) as {
          summaries: SectionSummariesInterface;
        };
        return json.summaries;
      }
      throw await createError(response);
    },
    refetchOnWindowFocus: false,
  });
};

const cacheKeyOf = (sectionId: string) => [
  `/api/v1/sections/${sectionId}/summaries`,
];
