import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import StudentTag from "../../../domains/StudentTag";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { loadInitialTaggedStudents, loadMoreTaggedStudents } from "./common";

// 受信者一覧の取得
const getInitialDestinationTagOptionRecipients = createAction(
  "GET_INITIAL_DESTINATION_TAG_OPTION_RECIPIENTS",
);
const getInitialDestinationTagOptionRecipientsError = createAction(
  "GET_INITIAL_DESTINATION_TAG_OPTION_RECIPIENTS_ERROR",
);
const getInitialDestinationTagOptionRecipientsSuccess = createAction(
  "GET_INITIAL_DESTINATION_TAG_OPTION_RECIPIENTS_SUCCESS",
);
export const loadInitialDestinationTagOptionRecipients =
  (destinationTag: StudentTag) =>
  (dispatch: Dispatch, getState: () => AppStateInterface) => {
    const actionCreators = {
      sending: getInitialDestinationTagOptionRecipients,
      success: getInitialDestinationTagOptionRecipientsSuccess,
      error: getInitialDestinationTagOptionRecipientsError,
    };

    loadInitialTaggedStudents(destinationTag, actionCreators)(
      dispatch,
      getState,
    );
  };

const getDestinationTagOptionRecipients = createAction(
  "GET_DESTINATION_TAG_OPTION_RECIPIENTS",
);
const getDestinationTagOptionRecipientsError = createAction(
  "GET_DESTINATION_TAG_OPTION_RECIPIENTS_ERROR",
);
const getDestinationTagOptionRecipientsSuccess = createAction(
  "GET_DESTINATION_TAG_OPTION_RECIPIENTS_SUCCESS",
);
export const loadMoreDestinationTagOptionRecipients =
  (destinationTag: StudentTag, page: number) =>
  (dispatch: Dispatch, getState: () => AppStateInterface) => {
    const actionCreators = {
      sending: getDestinationTagOptionRecipients,
      error: getDestinationTagOptionRecipientsError,
      success: getDestinationTagOptionRecipientsSuccess,
    };

    loadMoreTaggedStudents(
      destinationTag,
      page,
      actionCreators,
    )(dispatch, getState);
  };

// 宛先タグ一覧取得成功したときにdispatchするアクション
export const initializeAnnounceDestinationTagOptionsSuccess = createAction(
  "INITIALIZE_ANNOUNCE_DESTINATION_TAG_OPTIONS_SUCCESS",
);
