import { boronClient } from "../../../api.ts";
import { useBoronMutation } from "../../../hooks/http/useBoronMutation.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import { StaffBoardPostFormInterface } from "../../../interfaces/StaffBoardPostInterface.ts";
import { useAddStaffBoardPostToListCache } from "../useFetchStaffBoardPosts.ts";

export const useCreateStaffBoardPost = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const { addStaffBoardPostToListCache } = useAddStaffBoardPostToListCache({
    sectionId,
  });

  return useBoronMutation(
    async (params: StaffBoardPostFormInterface) =>
      await boronClient.POST(
        "/api/v1/sections/{section_id}/staff_board_posts",
        {
          params: {
            path: {
              section_id: sectionId,
            },
          },
          body: {
            staff_board_post: params,
          },
        },
      ),
    {
      onSuccess: (responseBody) => {
        addStaffBoardPostToListCache(responseBody.staffBoardPost);
        showSuccessMessage("スタッフボードに投稿しました");
      },
      onError: () => {
        showErrorMessage("スタッフボードに投稿できませんでした");
      },
    },
  );
};
