import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import Icon from "../../atoms/Icon";
import styles from "./Breadcrumb.scss";

export type BreadcrumbItem = {
  label: string;
  url?: string;
};

/* boron-web内で使うBreadcrumbはReactRouterのLinkのみしか使わないと想定できる */
type BreadcrumbItemLinkProps = {
  href: string;
};
export const BreadcrumbItemLink = ({
  children,
  href,
}: React.PropsWithChildren<BreadcrumbItemLinkProps>) => {
  return (
    <Link
      to={href}
      className={classNames(styles.BreadcrumbItemLink, styles.isLimitedText)}
    >
      {children}
    </Link>
  );
};

type BreadcrumbItemProps = {
  isCurrent?: boolean;
  className?: string;
};
export const BreadcrumbItem = ({
  isCurrent = false,
  className,
  children,
}: React.PropsWithChildren<BreadcrumbItemProps>) => {
  return (
    <>
      <li
        className={classNames(
          className,
          styles.BreadcrumbItem,
          styles.isLimitedText,
        )}
      >
        {children}
      </li>
      {!isCurrent && (
        <li className={styles.BreadcrumbItem} aria-hidden="true">
          <Separator />
        </li>
      )}
    </>
  );
};

export const Breadcrumb = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <nav>
      <ol className={styles.Breadcrumbs}>{children}</ol>
    </nav>
  );
};

const Separator = () => {
  return <Icon name="icon-arrow-right" />;
};
