import * as React from "react";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import SectionInterface from "../../interfaces/SectionInterface";
import { DrillLearningMaterialSelectionForm } from "./DrillLearningMaterialSelectionForm/DrillLearningMaterialSelectionForm";
import { StudyTaskMainForm } from "./StudyTaskMainForm";
import {
  DrillLearningMaterialValue,
  useStudyTaskForm,
} from "./useStudyTaskForm";

type Props = OutletContextProps & { section: SectionInterface };
type currentPage = "mainForm" | "drillLearningMaterialSelectionForm";

const SectionsAnalyticsStudyTaskNewPage_ = ({
  section,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("analytics");
  }, [setActiveMenu]);

  const [currentPage, setCurrentPage] = React.useState<currentPage>("mainForm");
  const [currentStudyTaskIndex, setCurrentStudyTaskIndex] = React.useState<
    number | null
  >(null);
  const form = useStudyTaskForm({ section });

  const openMainForm = () => {
    setCurrentPage("mainForm");
  };

  const selectDrillLearningMaterial = (
    drillLearningMaterial: DrillLearningMaterialValue,
  ) => {
    if (currentStudyTaskIndex != null) {
      form.setDrillLearningMaterialForStudyTask(
        currentStudyTaskIndex,
        drillLearningMaterial,
      );
    }
    openMainForm();
  };

  const openDrillLearningMaterialSelectionForm = (index: number) => {
    setCurrentStudyTaskIndex(index);
    setCurrentPage("drillLearningMaterialSelectionForm");
  };

  if (currentPage === "mainForm") {
    return (
      <StudyTaskMainForm
        section={section}
        form={form}
        openDrillLearningMaterialSelectionFormHandler={
          openDrillLearningMaterialSelectionForm
        }
      />
    );
  } else {
    const drillLearningMaterial =
      currentStudyTaskIndex != null &&
      form.values.study_tasks[currentStudyTaskIndex]?.drill_learning_material
        .learningMaterialCode !== ""
        ? form.values.study_tasks[currentStudyTaskIndex].drill_learning_material
        : undefined;
    return (
      <DrillLearningMaterialSelectionForm
        section={section}
        openMainFormHandler={openMainForm}
        selectDrillLearningMaterialHandler={selectDrillLearningMaterial}
        defaultDrillLearningMaterial={drillLearningMaterial}
      />
    );
  }
};

const pageInfo = {
  title: "アナリティクス",
};
export const SectionsAnalyticsStudyTaskNewPage = withRouter(
  enhanceSectionsPage(SectionsAnalyticsStudyTaskNewPage_, pageInfo),
);
