import SubLayout from "../../../components/atoms/SubLayout";
import styles from "./styles.scss";

const RegisterSentPage = () => {
  return (
    <SubLayout>
      <SubLayout.Header />
      <SubLayout.Main>
        <div className={styles.root}>
          <SubLayout.Heading>認証メールを送信しました</SubLayout.Heading>
          <p className={styles.description}>
            ご入力いただいたメールアドレスに、本人認証を行うメールをお送りしました。
            <br />
            メールに記載されたURLから、アカウント登録をお手続きください。
          </p>
        </div>
      </SubLayout.Main>
    </SubLayout>
  );
};

export default RegisterSentPage;
