import { format, parseISO } from "date-fns";
import * as React from "react";
import { useParams } from "react-router";
import Loader from "../../components/atoms/Loader";
import StudentFilter from "../../components/features/NewStudentFilter";
import { useStudentFilterContext } from "../../components/features/NewStudentFilter/useStudentFilterContext";
import { SectionAnalyticsTypeTab } from "../../components/features/SectionAnalyticsTypeTab";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbItemLink,
} from "../../components/general/Breadcrumb";
import { FieldVisibilityToggle } from "../../components/general/FieldVisibilityToggle";
import SwitchTabs from "../../components/molecules/SwitchTabs";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import enhanceSectionPage from "../../hocs/enhanceSectionsPage";
import { useAnalyticsFilter } from "../../hooks/filters/useAnalyticsFilter";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import SectionInterface from "../../interfaces/SectionInterface";
import { ExaminationResultsTable } from "./ExaminationResultsTable";
import { useExaminationValues } from "./useExaminationValues";
import {
  displayItemType,
  useFetchSectionExaminationDetail,
} from "./useFetchSectionExaminationDetail";

type Props = OutletContextProps & { section: SectionInterface };

const SectionsAnalyticsExaminationDetailPage_ = ({
  section,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("analytics");
  }, []);

  const [displayItem, setDisplayItem] =
    React.useState<displayItemType>("point");

  const sectionId = section.id;
  const { examinationId } = useParams<{
    examinationId: string;
  }>();

  const { getStudentFilterParams } = useStudentFilterContext();
  const filtersQuery = getStudentFilterParams();

  const { analyticsFilter, changeOrder } = useAnalyticsFilter({
    sectionId: section.id,
  });

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useFetchSectionExaminationDetail({
    sectionId,
    examinationId: examinationId ?? null,
    displayItem,
    studentFilterParams: filtersQuery,
    analyticsFilter,
  });

  useQueryError(error);
  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  const onChangeDisplayItem = (id: displayItemType) => {
    setDisplayItem(id);
  };

  const examinationValues = useExaminationValues(data);

  return (
    <>
      <SectionAnalyticsTypeTab
        sectionId={section.id}
        analyticsType="examination"
      />
      {examinationValues.isLoading ? (
        <Loader loading />
      ) : (
        <>
          <div className="mt-5">
            <Breadcrumb>
              <BreadcrumbItem>
                <BreadcrumbItemLink
                  href={`/sections/${section.id}/analytics/examinations`}
                >
                  <div className="mr-4 text-lg">成績記録一覧</div>
                </BreadcrumbItemLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrent>
                <div className="mx-4 text-lg">{examinationValues.name}</div>
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
          <StudentFilter sectionId={section.id} />
          <div className="mt-7 rounded-md border border-solid border-gray-400 bg-white">
            <div className="m-7 mb-[2rem] flex flex-col items-center justify-between gap-4 text-lg font-light leading-none md:flex-row md:gap-0">
              <div className="w-full break-words md:max-w-md">
                <span className="mr-3">
                  {format(
                    parseISO(examinationValues.lastExaminedOn),
                    "yyyy/MM",
                  )}
                </span>
                <span className="mr-3">{examinationValues.organizerName}</span>
                <span className="mr-3">
                  {examinationValues.classificationName}
                </span>
                <span>{examinationValues.targetJobHuman}</span>
                <div className="mt-3 text-2xl font-bold">
                  {examinationValues.name}
                </div>
              </div>
              <div className="flex w-full justify-end">
                <div className="flex">
                  <div className="mr-4">
                    <FieldVisibilityToggle
                      id="sectionAnalyticsExaminationDetail"
                      fieldNames={["生徒ID"]}
                    />
                  </div>
                  <SwitchTabs
                    tabs={[
                      {
                        id: "point",
                        label: "得点",
                        active: displayItem === "point",
                      },
                      {
                        id: "deviation",
                        label: "偏差値",
                        active: displayItem === "deviation",
                      },
                    ]}
                    onClickTab={(tab) => () => {
                      onChangeDisplayItem(tab.id);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row">
              {data && data.length > 0 && (
                <ExaminationResultsTable
                  examinationResults={data}
                  analyticsFilter={analyticsFilter}
                  displayItem={displayItem}
                  onOrderChange={changeOrder}
                />
              )}
            </div>
            <Loader loading={isLoading || isFetchingNextPage} />
          </div>
        </>
      )}
    </>
  );
};

const pageInfo = {
  title: "アナリティクス",
};
export const SectionsAnalyticsExaminationDetailPage = withRouter(
  enhanceSectionPage(SectionsAnalyticsExaminationDetailPage_, pageInfo),
);
