import { IconFailed } from "@studyplus/boron-ui";
import { Values as FormValues } from "./useStudyTaskForm";

type Props = {
  drillLearningMaterial: FormValues["study_tasks"][0]["drill_learning_material"];
  resetDrillLearningMaterialHandler: () => void;
  openDrillLearningMaterialHandler: () => void;
};

export const SelectedDrillLearningMaterial = ({
  drillLearningMaterial,
  resetDrillLearningMaterialHandler,
  openDrillLearningMaterialHandler,
}: Props) => {
  const sectionCount = drillLearningMaterial.sections.length;
  const contentCount = drillLearningMaterial.sections.flatMap(
    (s) => s.contents,
  ).length;

  return (
    <div className="rounded bg-gray-100 p-7">
      <div className="relative rounded border border-solid border-gray-400 bg-white hover:border-blue-400 hover:bg-blue-100">
        <button
          className="flex w-full flex-nowrap p-4 text-left text-black"
          onClick={openDrillLearningMaterialHandler}
        >
          <div className="h-[4.5rem] w-[4.5rem]">
            {drillLearningMaterial.imageUrl && (
              <img
                className="h-full w-full object-contain"
                src={drillLearningMaterial.imageUrl}
              />
            )}
          </div>
          <div className="ml-7 flex-grow">
            <div className="flex justify-between">
              <div className="mb-4 text-xl font-bold">
                {drillLearningMaterial.name}
              </div>
            </div>
            <div className="text-md">
              [ 学習範囲 ] セクション：
              {sectionCount}
              {"　"}/{"　"}コンテンツ：
              {contentCount}
            </div>
          </div>
        </button>
        <button
          className="absolute right-4 top-4 p-0"
          onClick={resetDrillLearningMaterialHandler}
        >
          <IconFailed className="h-1 w-1 text-gray-600" />
        </button>
      </div>
    </div>
  );
};
