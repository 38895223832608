import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import Icon from "../../atoms/Icon";
import PrintButton from "../../atoms/PrintButton";
import { DateRangeNavigation } from "../../general/DateRangeNavigation";
import SwitchTabs from "../../molecules/SwitchTabs";
import { SingleDatePickerWrapper } from "../SingleDatePickerWrapper";
import styles from "./styles.scss";
import {
  UseStudentCalendarHeaderProps,
  useStudentCalendarHeader,
} from "./useStudentCalendarHeader";

type Props = UseStudentCalendarHeaderProps;

const Component = (props: Props) => {
  const {
    onChangeDateRange,
    onChangeCalendarType,
    onChangeSingleDate,
    ...state
  } = useStudentCalendarHeader(props);
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={`${styles.menus} ${styles.leftMenu}`}>
          <div>
            <Button
              className={`${styles.menus__btn} ${styles.menus__todaybtn}`}
              variant="outline"
              size="xs"
              onClick={state.onClickToday}
            >
              今日
            </Button>
          </div>
          <div className={styles.leftMenu__tab}>
            <SwitchTabs
              tabs={[
                {
                  id: "Daily",
                  label: "日",
                  active: props.calendarType === "Daily",
                },
                {
                  id: "Weekly",
                  label: "週",
                  active: props.calendarType === "Weekly",
                },
              ]}
              onClickTab={(tab) => () => {
                onChangeCalendarType(tab.id);
              }}
            />
          </div>
        </div>
        <div className={styles.dateRange}>
          {props.calendarType === "Weekly" ? (
            <DateRangeNavigation
              startDate={state.startDate}
              endDate={state.endDate}
              term="daily"
              calendarDisabled={false}
              onDatesChange={onChangeDateRange}
              canPushRightButton
            />
          ) : (
            <SingleDatePickerWrapper
              date={props.startDate}
              blockType="Nothing"
              onDateChange={onChangeSingleDate}
            />
          )}
        </div>

        <div className={`${styles.menus} ${styles.rightMenu}`}>
          <Button
            size="xs"
            className={`${styles.menus__btn} ${styles.menus__addbtn}`}
            onClick={props.onClickAddEvent}
            aria-label="予定の登録"
          >
            <Icon name="icon-plus-tag" />
          </Button>
          <PrintButton />
        </div>
      </div>
    </div>
  );
};

export const StudentCalendarHeader = React.memo(Component);
