import ContentBox from "../../../components/atoms/ContentBox";
import { MultiSectionBatch } from "../useMultiSectionBatchesDetail";
import { Failure } from "./Failure";
import { Pending } from "./Pending";
import { Success } from "./Success";
import { getMultiSectionBatchInfo } from "./getMultiSectionBatchInfo";

type Props = {
  batch: MultiSectionBatch;
};

const DownloadStatus = ({
  batch,
  dataTypeLabel,
  fileNamePrefix,
}: {
  batch: MultiSectionBatch;
  dataTypeLabel: string;
  fileNamePrefix: string;
}) => {
  switch (batch.status) {
    case "standby":
    case "processing":
      return <Pending dataTypeLabel={dataTypeLabel} />;
    case "success":
      return (
        <Success
          batch={batch}
          dataTypeLabel={dataTypeLabel}
          fileNamePrefix={fileNamePrefix}
        />
      );
    case "failure":
      return <Failure dataTypeLabel={dataTypeLabel} />;
    default:
      throw "エラーが発生しました";
  }
};

export const Detail = ({ batch }: Props) => {
  const { batchTypeHuman, dataTypeLabel, fileNamePrefix, Icon } =
    getMultiSectionBatchInfo(batch.batchType);

  return (
    <div className="flex w-[90%] flex-col">
      <h1 className="text-bold my-10 text-3xl text-black">校舎横断操作</h1>
      <ContentBox className="w-[80%] p-10">
        <div>
          <div className="flex items-center gap-6">
            <Icon className="h-[3rem] w-[3rem] text-gray-600" />
            <h2 className="text-xl">{batchTypeHuman}</h2>
          </div>
          <DownloadStatus
            batch={batch}
            dataTypeLabel={dataTypeLabel}
            fileNamePrefix={fileNamePrefix}
          />
        </div>
      </ContentBox>
    </div>
  );
};
