import classnames from "classnames";
import * as React from "react";
import Icon from "../../Icon";
import styles from "./styles.scss";

export interface Props {
  children: React.ReactNode;
  onClose?: (e: React.SyntheticEvent) => void;
}

export const Header = ({ children, onClose }: Props) => {
  return (
    <div className={styles.root}>
      {children}
      <Icon name="icon-close-x" onClick={onClose} className={styles.icon} />
    </div>
  );
};

export interface NoCloseHeaderProps {
  children: React.ReactNode;
}
export const NoCloseHeader = ({ children }: NoCloseHeaderProps) => {
  return (
    <div className={classnames(styles.root, styles.noCloseButton)}>
      {children}
    </div>
  );
};
