import * as React from "react";
import { Link } from "react-router-dom";
import { BlockRow } from "../../../../components/atoms/BlockRow";
import { Flex } from "../../../../components/atoms/Flex";
import Icon from "../../../../components/atoms/Icon";
import { StudentCourseResult } from "../../../../domains/StudentCourseResult";
import SectionInterface from "../../../../interfaces/SectionInterface";
import styles from "./styles.scss";

type Props = {
  studentCourseResult: StudentCourseResult;
  section: SectionInterface;
  unitCount: number;
  contentCount: number;
};
export const StudentCourseResultSummary = ({
  unitCount,
  section,
  contentCount,
  studentCourseResult,
}: Props) => (
  <Flex justifyContent="space-between" marginTop="0" wrap>
    <div className={styles.StudentCourseResultSummary__head}>
      <Icon
        name="icon-folder-level-1"
        color="gray-darken-1"
        className={styles.StudentCourseResultSummary__icon}
      />
      <BlockRow marginTop="0">
        <p className={styles.StudentCourseResultSummary__title}>
          {studentCourseResult.name}
        </p>
        <Flex gap="2" marginTop="1rem">
          <p
            className={styles.StudentCourseResultSummary__text}
          >{`ユニット数：${unitCount}`}</p>
          <p
            className={styles.StudentCourseResultSummary__text}
          >{` コンテンツ数：${contentCount}`}</p>
        </Flex>
      </BlockRow>
    </div>
    <Flex marginTop="0">
      <LinkButton
        to={`/sections/${section.id}/settings/content_courses/${studentCourseResult.id}`}
        icon="icon-content-list"
      >
        詳細を見る
      </LinkButton>
    </Flex>
  </Flex>
);

type LinkButtonProps = React.PropsWithChildren<{
  to: string;
  icon?: string;
}>;
const LinkButton = (props: LinkButtonProps) => (
  <Link to={props.to} className={styles.LinkButton}>
    {props.icon ? <Icon name={props.icon} /> : null}
    {props.children}
  </Link>
);
