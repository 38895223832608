import SectionInterface from "../../../interfaces/SectionInterface";
import SectionDashboardChart from "../../molecules/SectionDashboardChart/index";
import SectionDashboardStaffBoard from "../../molecules/SectionDashboardStaffBoard/index";
import styles from "./styles.scss";
import { useFetchLoadStaffBoardPosts } from "./useFetchLoadStaffBoardPosts";
import { useFetchLoadSummaries } from "./useFetchLoadSummaries";

interface Props {
  section: SectionInterface;
}

const SectionDashboard = (props: Props) => {
  const { data: staffBoardPostsData, isLoading: staffBoardPostsLoading } =
    useFetchLoadStaffBoardPosts({ sectionId: props.section.id });
  const { data: summariesData } = useFetchLoadSummaries({
    sectionId: props.section.id,
  });

  return (
    <div className={styles.root}>
      <div className={styles.staffboard}>
        <SectionDashboardStaffBoard
          section={props.section}
          staffBoardPosts={staffBoardPostsData}
          loading={staffBoardPostsLoading}
        />
      </div>
      <div className={styles.charts}>
        <div className={styles.chartContainer}>
          <div className={styles.chart}>
            <SectionDashboardChart
              section={props.section}
              sectionSummaries={summariesData}
              type={"studyRecord"}
            />
          </div>
          <div className={styles.chart}>
            <SectionDashboardChart
              section={props.section}
              sectionSummaries={summariesData}
              type={"karte"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionDashboard;
