import classNames from "classnames";
import * as React from "react";

import StudentTag from "../../../domains/StudentTag";
import Backdrop from "../../atoms/Backdrop";
import Checkbox from "../../atoms/Checkbox";
import DeprecatedTagButton from "../../features/DeprecatedTagButton";
import styles from "./styles.scss";

interface Props {
  selectedTags: StudentTag[];
  tags: StudentTag[];
  onClickTag: (tag: StudentTag) => (e: any) => void;
  isClickableTag: (tag: StudentTag) => boolean;
}

interface State {
  opened: boolean;
}

export default class StatusTagFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  render(): JSX.Element {
    return (
      <div className={styles.root}>
        {this.state.opened ? (
          <Backdrop onClick={this.toggleDropdown} className={styles.backdrop} />
        ) : null}
        {
          <DeprecatedTagButton
            iconName={this.state.opened ? "icon-arrow-up" : "icon-arrow-down"}
            label={this.props.selectedTags.map((tag) => tag.name).join("・")}
            theme="main"
            onClick={this.toggleDropdown}
          />
        }
        <div
          className={classNames(styles.dropdown, {
            [styles.opened]: this.state.opened,
          })}
        >
          {this.renderDropdown()}
          <div className={styles.dropdownSeparator} />
          <div
            className={classNames(
              styles.dropdownRow,
              styles.dropdownCloseButton,
            )}
            onClick={this.toggleDropdown}
          >
            閉じる
          </div>
        </div>
      </div>
    );
  }

  private renderDropdown() {
    return this.props.tags.map((tag) => {
      const isChecked = this.props.selectedTags.some(
        (selectedTag) => selectedTag.id === tag.id,
      );
      const isClickable = this.props.isClickableTag(tag);
      return (
        <div
          key={`status-tag-filter-${tag.id}`}
          className={classNames(styles.dropdownRow, {
            [styles.clickable]: isClickable,
          })}
          onClick={this.props.onClickTag(tag)}
        >
          <Checkbox checked={isChecked} disabled={!isClickable}>
            <span className={classNames({ [styles.checkedLabel]: isChecked })}>
              {tag.name}
            </span>
          </Checkbox>
        </div>
      );
    });
  }

  private toggleDropdown = () => {
    this.setState({ opened: !this.state.opened });
  };
}
