import * as React from "react";
import {
  SectionTimelineSummaryResponseStudyRecord,
  useFetchSectionsTimelineSummary,
} from "../../../../../features/SectionsTimelineSummaryPage/useFetchSectionsTimelineSummary";
import StudyRecordInterface, {
  StudyRecordCommentInterface,
} from "../../../../../interfaces/StudyRecordInterface";

type Props = {
  studyRecord: StudyRecordInterface;
  updateStudyRecordQueryCache?: ReturnType<
    typeof useFetchSectionsTimelineSummary
  >["updateStudyRecordQueryCache"];
};

export const useTimelineComments = ({
  studyRecord,
  updateStudyRecordQueryCache,
}: Props) => {
  const [comments, setComments] = React.useState<StudyRecordCommentInterface[]>(
    studyRecord.comments,
  );
  const [isVisible, setIsVisible] = React.useState(
    studyRecord.comments.length > 0,
  );

  const onAddComment = (comment: StudyRecordCommentInterface) => {
    setComments((prevComments) => [...prevComments, comment]);
    if (updateStudyRecordQueryCache) {
      // タイムラインサマリーのドロワーでコメントしたときに、ドロワー外の画面のコメント数に反映するための処理
      updateStudyRecordQueryCache({
        ...studyRecord,
        comments: [...studyRecord.comments, comment],
      } as SectionTimelineSummaryResponseStudyRecord);
    }
  };

  const onDeleteComment = (comment: StudyRecordCommentInterface) => {
    setComments((prevComments) =>
      prevComments.filter((prevComment) => prevComment.id !== comment.id),
    );
    if (updateStudyRecordQueryCache) {
      updateStudyRecordQueryCache({
        ...studyRecord,
        comments: studyRecord.comments.filter(
          (prevComment) => prevComment.id !== comment.id,
        ),
      } as SectionTimelineSummaryResponseStudyRecord);
    }
  };

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  return {
    comments,
    isVisible,
    onAddComment,
    onDeleteComment,
    toggleVisibility,
    setIsVisible,
  };
};
