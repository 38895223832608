import * as React from "react";
import Icon from "../../atoms/Icon/index";
import styles from "./styles.scss";

interface Props {
  onAdd: () => void;
}

const HeaderAccountAddition = (props: Props): React.ReactElement => {
  return (
    <div className={styles.root} onClick={props.onAdd}>
      <div className={styles.left}>
        <Icon name="icon-plus" className={styles.iconPlus} />
      </div>
      <div className={styles.right}>アカウントを追加</div>
    </div>
  );
};

export default HeaderAccountAddition;
