import * as React from "react";
import {
  HorizontalScrollTable,
  TableColumn,
} from "../../components/atoms/Table/index";
import {
  Lecture,
  translateAttendanceConfirm,
  translateQuestionnairePresence,
} from "../../domains/Lecture";
import { translateAttendanceLocationCollect } from "../../domains/Lecture";
import TimeHelper from "../../helpers/TimeHelper";

export const LecturesTable = ({
  children,
}: React.PropsWithChildren<unknown>) => (
  <HorizontalScrollTable
    headers={[
      { text: "講座名", centering: true, key: "name", width: 240 },
      {
        text: "出欠確認",
        centering: true,
        key: "attendanceConfirm",
      },
      {
        text: "位置情報判定",
        centering: true,
        key: "attendanceLocationCollect",
      },
      { text: "アンケート設定", centering: true, key: "questionnaires" },
      { text: "更新日時", centering: true, width: 160, key: "updatedAt" },
      { text: "", centering: false, width: 160, key: "blank" },
    ]}
    rows={children as JSX.Element[]}
  />
);

type RowProps = React.PropsWithChildren<{
  lecture: Lecture;
}>;
export const LecturesTableRow = ({ children, lecture }: RowProps) => {
  return (
    <tr>
      <TableColumn align="left">{lecture.name}</TableColumn>
      <TableColumn align="center">
        {translateAttendanceConfirm(lecture.attendanceConfirm)}
      </TableColumn>
      <TableColumn align="center">
        {translateAttendanceLocationCollect(lecture.attendanceLocationCollect)}
      </TableColumn>
      <TableColumn align="center">
        {translateQuestionnairePresence(lecture)}
      </TableColumn>
      <TableColumn align="center">
        {TimeHelper.fullFormat(lecture.updatedAt)}
      </TableColumn>
      <TableColumn align="right">{children}</TableColumn>
    </tr>
  );
};
