import * as React from "react";
import { useLayoutEffect, useRef } from "react";

import { GuardianMessageProps } from ".";
import GuardianMessageInterface from "../../../interfaces/GuardianMessageInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import Loader from "../../atoms/Loader";
import styles from "./GuardianMessageList.scss";
import GuardianMessageListItem from "./GuardianMessageListItem";

interface OwnProps {
  student: StudentInterface;
  guardianMessageProps: GuardianMessageProps;
  postedGuardianMessages: GuardianMessageInterface[];
  loadMoreMessages: () => void;
}

type Props = OwnProps;

const GuardianMessageList: React.FC<Props> = (props: Props) => {
  const { data, loading } = props.guardianMessageProps;
  // APIレスポンスのメッセージと送信したメッセージをマージしたメッセージ一覧
  const [guardianMessages, setGuardianMessages] = React.useState<
    GuardianMessageInterface[]
  >([]);
  React.useEffect(() => {
    setGuardianMessages([...props.postedGuardianMessages, ...data]);
  }, [data, props.postedGuardianMessages]);

  const { scroller } = useScroll({ ...props, guardianMessages });

  if (!guardianMessages && loading) {
    return (
      <div className={styles.root}>
        <Loader loading={loading} />
      </div>
    );
  }

  return (
    <div className={styles.root} ref={scroller} onScroll={handleScroll(props)}>
      <div className={styles.list} aria-label="メッセージ一覧" role="list">
        {renderList(props, guardianMessages)}
        {guardianMessages && loading ? <Loader loading={loading} /> : null}
      </div>
    </div>
  );
};

type UseScrollProps = Pick<Props, "student"> & {
  guardianMessages: GuardianMessageInterface[];
};
const useScroll = (props: UseScrollProps) => {
  const { guardianMessages } = props;
  const scroller = useRef<HTMLDivElement | null>(null);
  const scrollerHeight = useRef<number>(0);
  const loadedMessages = useRef<Props["guardianMessageProps"]["data"] | null>(
    null,
  );
  const prevStudentId = useRef<string | null>(null);

  useLayoutEffect(() => {
    if (!scroller.current) return;

    if (prevStudentId.current !== props.student.id) {
      // スレッド切り替え時
      scroller.current.scrollTop = scroller.current.scrollHeight;
      loadedMessages.current = guardianMessages;
      prevStudentId.current = props.student.id;
      return;
    }

    if (!loadedMessages.current || guardianMessages.length === 0) {
      // メッセージがない時
      loadedMessages.current = [];
      return;
    }

    if (loadedMessages.current[0]?.id !== guardianMessages[0]?.id) {
      // 初回読み込み or メッセージ送信時
      scrollerHeight.current = scroller.current.scrollHeight;
      scroller.current.scrollTop = scroller.current.scrollHeight;
      loadedMessages.current = guardianMessages;
      return;
    }

    if (loadedMessages.current.length !== guardianMessages.length) {
      // 上にスクロールして追加読込を行った時
      scroller.current.scrollTop =
        scroller.current.scrollHeight - scrollerHeight.current;
      scrollerHeight.current = scroller.current.scrollHeight;
      loadedMessages.current = guardianMessages;
      return;
    }
  }, [guardianMessages, props.student.id]);

  return {
    scroller,
  };
};

const renderList = (
  props: Props,
  guardianMessages: GuardianMessageInterface[],
) => {
  const { student } = props;

  if (guardianMessages) {
    return guardianMessages.map((message) => {
      return (
        <GuardianMessageListItem
          message={message}
          student={student}
          key={`GuardianMessageList-GuardianMessageListItem-${message.id}`}
        />
      );
    });
  } else {
    return null;
  }
};

const handleScroll = (props: Props) => (e: any) => {
  const { loading, hasMore, meta } = props.guardianMessageProps;

  if (loading || !hasMore) {
    return;
  }

  if (e.target.scrollTop === 0 && meta.till) {
    props.loadMoreMessages();
  }
};

export default GuardianMessageList;
