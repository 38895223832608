import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api";
import { HTTPErrors, createError } from "../../../errors";
import { cahceKeyOf } from "./useFetchLectureTransfer";

type Props = {
  sectionId: string;
  lectureAttendanceId: string;
};

export const useMutateLectureTransfer = ({
  sectionId,
  lectureAttendanceId,
}: Props) => {
  const client = useQueryClient();
  return useMutation<void, HTTPErrors>({
    mutationFn: async () => {
      const response = await api.delete(urlOf(sectionId, lectureAttendanceId));
      if (response.ok) {
        return;
      }
      throw await createError(response);
    },
    onSuccess() {
      const cacheKey = cahceKeyOf(sectionId, lectureAttendanceId);
      client.invalidateQueries({ queryKey: cacheKey });
    },
  });
};

const urlOf = (sectionId: string, lectureAttendanceId: string) =>
  `/api/v1/sections/${sectionId}/lecture_attendances/${lectureAttendanceId}/lecture_transfer`;
