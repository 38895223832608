import classNames from "classnames";
import * as React from "react";
import Label from "../../../../../components/atoms/Label";
import SwitchButton from "../../../../../components/atoms/SwitchButton";
import { GoogleUserProfileInterface } from "../../../../../interfaces/OperatorProfileInterface.ts";
import { OperatorHelpLink } from "../OperatorHelpLink";
import styles from "./styles.scss";

interface Props {
  connected: boolean;
  googleUserProfile?: GoogleUserProfileInterface;
  onSwitch: (value: boolean) => Promise<boolean>;
}

const HELP_URL = "https://fs-help.studyplus.co.jp/ja/articles/4625523";
const HELP_UNNCONNECT = "https://fs-help.studyplus.co.jp/ja/articles/4711232";

const OperatorGoogleClassroomForm = (
  props: Props,
): React.ReactElement<Props> => {
  return (
    <>
      <div className={styles.root}>
        <div className={styles.display}>
          <div className={styles.display__label}>
            <span className={styles.display__label__bold}>
              Google Classroom連携の設定
            </span>
            <OperatorHelpLink
              href={props.connected ? HELP_UNNCONNECT : HELP_URL}
            >
              {props.connected ? "連携解除" : "連携方法"}
            </OperatorHelpLink>
          </div>
          <SwitchButton
            aria-label={
              props.connected
                ? "Google Classroom連携を解除する"
                : "Google Classroomと連携する"
            }
            value={props.connected}
            label={{ on: "連携中", off: "未連携" }}
            onChange={props.onSwitch}
          />
        </div>
      </div>
      {props.connected && props.googleUserProfile ? (
        <>
          <Label isMute={true}>Googleアカウント</Label>
          <div className={styles.root}>
            <div
              className={classNames(styles.display, styles.display__setting)}
            >
              <div className={styles.display__profile}>
                <div className={styles.display__photo}>
                  <img
                    src={props.googleUserProfile.photoUrl}
                    loading="lazy"
                    className={styles.photo}
                  />
                </div>
                <div className={styles.display__userinfo}>
                  <div className={styles.display__label__name}>
                    {props.googleUserProfile.fullName}
                  </div>
                  <div className={styles.display__label__email}>
                    {props.googleUserProfile.email}
                  </div>
                </div>
              </div>
              <div className={styles.setting__btn}>
                <OperatorHelpLink href={HELP_URL}>
                  クラスの同期方法
                </OperatorHelpLink>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default OperatorGoogleClassroomForm;
