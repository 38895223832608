import { Button } from "@studyplus/boron-ui";
import Checkbox from "../../../../../components/atoms/Checkbox/index";
import { Flex } from "../../../../../components/atoms/Flex/index";
import { SearchInput } from "../../../../../components/general/SearchInput/index";
import LtiDeploymentInterface from "../../../../../interfaces/LtiDeploymentInterface";
import styles from "./styles.scss";
import {
  SearchContentTypes,
  useContentsSearchForm,
} from "./useContentsSearchForm";

type Props = {
  ltiDeployments: LtiDeploymentInterface[];
  isLoading: boolean;
  handleSearch: (
    searchText: string,
    searchContentTypes: SearchContentTypes,
  ) => void;
};
export const ContentSearchForm = ({
  ltiDeployments,
  isLoading,
  handleSearch,
}: Props) => {
  const searchProps = useContentsSearchForm();
  return (
    <>
      <Flex marginTop="0">
        <span className={styles.searchForm__label}>絞込表示：</span>
        {ltiDeployments.length > 0 ? (
          <Checkbox
            id={`ContentsSelectModal-search-checkbox-LtiResourceLink`}
            value="LtiResourceLink"
            labelMargin="narrow"
            checked={searchProps.searchContentTypes.includes("LtiResourceLink")}
            onChange={searchProps.handleChangeContentTypes}
          >
            <span className={styles.searchForm__checkboxLabel}>
              外部学習支援ツール
            </span>
          </Checkbox>
        ) : null}
        <Checkbox
          id={`ContentsSelectModal-search-checkbox-ContentFile`}
          value="ContentFile"
          labelMargin="narrow"
          checked={searchProps.searchContentTypes.includes("ContentFile")}
          onChange={searchProps.handleChangeContentTypes}
        >
          <span className={styles.searchForm__checkboxLabel}>画像・PDF</span>
        </Checkbox>
        <Checkbox
          id={`ContentsSelectModal-search-checkbox-ContentVideo`}
          value="ContentVideo"
          labelMargin="narrow"
          checked={searchProps.searchContentTypes.includes("ContentVideo")}
          onChange={searchProps.handleChangeContentTypes}
        >
          <span className={styles.searchForm__checkboxLabel}>動画</span>
        </Checkbox>
      </Flex>
      <div className={styles.searchForm__bottom}>
        <Flex marginTop="0.4rem" gap="1">
          <SearchInput
            type="text"
            placeholder="コンテンツを探す"
            value={searchProps.searchText}
            onChange={searchProps.handleChangeSearchText}
            onReset={searchProps.handleResetSearchText}
            isBlock
          />
          <Button
            isLoading={isLoading}
            onClick={() =>
              handleSearch(
                searchProps.searchText,
                searchProps.searchContentTypes,
              )
            }
            className={styles.submitButton}
          >
            検索
          </Button>
        </Flex>
      </div>
    </>
  );
};
