import { Button } from "@studyplus/boron-ui";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { HandleThunkActionCreator } from "react-redux";
import StaffBoardPostInterface from "../../../interfaces/StaffBoardPostInterface.ts";
import { BlockRow } from "../../atoms/BlockRow";
import ErrorText from "../../atoms/ErrorText";
import Input from "../../atoms/Input";
import Label from "../../atoms/Label";
import { MainSection } from "../../atoms/MainSection";
import Textarea from "../../atoms/Textarea";
import styles from "./styles.scss";
import { validationSchema } from "./validationSchema.ts";

type Props = {
  staffBoardPost?: StaffBoardPostInterface;
  submitting: boolean;
  operatorName: string;
  onSubmit: HandleThunkActionCreator<
    (values: Values, resetForm: () => void) => void
  >;
};

type Values = {
  title: string;
  name: string;
  comment: string;
};

const StaffBoardPostForm = (props: Props) => {
  const { submitting, onSubmit } = props;

  const handleSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => {
    onSubmit(values, formikHelpers.resetForm);
  };

  return (
    <BlockRow marginTop="1.6rem">
      <MainSection>
        <Formik
          initialValues={initialValues(props)}
          initialErrors={undefined}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form aria-label="スタッフボード投稿フォーム">
              <div className={styles.form_row}>
                <div className={styles.title}>
                  <Label htmlFor="staff_board_post__title">タイトル</Label>
                  <Field
                    id="staff_board_post__title"
                    name="title"
                    component={Input}
                    hasError={shouldRenderError("title", formikProps)}
                    placeholder="タイトルを入力"
                  />
                  <ErrorMessage
                    name="title"
                    component={ErrorText}
                    className={styles.errors}
                  />
                </div>
                <div className={styles.name}>
                  <Label htmlFor="staff_board_post__name">スタッフ名</Label>
                  <Field
                    id="staff_board_post__name"
                    name="name"
                    component={Input}
                    hasError={shouldRenderError("name", formikProps)}
                    placeholder="スタッフ名を入力"
                  />
                  <ErrorMessage
                    name="name"
                    component={ErrorText}
                    className={styles.errors}
                  />
                </div>
              </div>
              <Label htmlFor="staff_board_post__comment">コメント</Label>
              <Field
                id="staff_board_post__comment"
                name="comment"
                component={Textarea}
                hasError={shouldRenderError("comment", formikProps)}
                rows={4}
              />
              <ErrorMessage
                name="comment"
                component={ErrorText}
                className={styles.errors}
              />
              <div className={styles.btn_group}>
                <Button
                  className={styles.submitButton}
                  type="submit"
                  disabled={!formikProps.isValid || submitting}
                  isLoading={submitting}
                >
                  {props.staffBoardPost ? "更新" : "送信"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </MainSection>
    </BlockRow>
  );
};

const initialValues = (props: Props) =>
  props.staffBoardPost
    ? {
        title: props.staffBoardPost.title,
        name: props.operatorName,
        comment: props.staffBoardPost.comment,
      }
    : {
        title: "",
        name: props.operatorName,
        comment: "",
      };

const shouldRenderError = (
  name: keyof Values,
  formikProps: FormikProps<Values>,
) => {
  const errors = formikProps.errors[name];
  const touched = formikProps.touched[name];
  return errors && touched;
};

export default StaffBoardPostForm;
