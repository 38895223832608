import { useQuery } from "@tanstack/react-query";
import { stringify } from "query-string";
import ApiClient from "../../api";
import { ExaminationOrganizer } from "../../domains/ExaminationOrganizer";
import { HTTPErrors, createError } from "../../errors";

type UseFetchExaminationOrganizersProps = {
  sectionId?: string;
  studentId?: string;
};

export const useFetchExaminationOrganizers = ({
  sectionId,
  studentId,
}: UseFetchExaminationOrganizersProps) => {
  const queryParams = stringify({
    section_id: sectionId,
    student_id: studentId,
  });

  const result = useQuery<readonly ExaminationOrganizer[], HTTPErrors>({
    queryKey: [`examination_organizers`],
    queryFn: async () => {
      const res = await ApiClient.get(`/api/v1/examination_organizers`, {
        query: `?${queryParams}`,
      });

      if (res.ok) {
        const json = (await res.json()) as {
          examinationOrganizers: ExaminationOrganizer[];
        };
        return json.examinationOrganizers;
      }
      const error = await createError(res);
      throw error;
    },
    retry: false,
  });

  return result;
};
