import * as React from "react";
import { Text, Props as TextProps } from "../../../components/general/Text";
import styles from "./SpecialSizeText.scss";

type Props = Pick<TextProps, "bold" | "color">;

export const SpecialSizeText = ({
  children,
  ...restProps
}: React.PropsWithChildren<Props>) => {
  return (
    <Text
      whiteSpace="nowrap"
      {...restProps}
      className={styles["u-fontisze-1.3"]}
    >
      {children}
    </Text>
  );
};
