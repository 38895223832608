import { Result, StudentCourseResult } from "../domains/StudentCourseResult";

export const fromJson = (json: Record<string, any>): StudentCourseResult => ({
  ...fromJsonToResult(json),
  unitResults: (json.unitResults as Array<Record<string, any>>).map(
    (unitResult) => ({
      ...fromJsonToResult(unitResult),
      contentResults: (
        unitResult.contentResults as Array<Record<string, any>>
      ).map((contentResult) => ({
        ...fromJsonToResult(contentResult),
        testReviewUrl: contentResult.testReviewUrl,
      })),
    }),
  ),
});

const fromJsonToResult = (json: Record<string, any>): Result => ({
  id: json.id,
  name: json.name,
  activityProgress: json.activityProgress,
  gradingProgress: json.gradingProgress,
  scoreGiven: json.scoreGiven,
  scoreMaximum: json.scoreMaximum,
  completeRate: json.completeRate,
  lastSubmittedAt: json.lastSubmittedAt && new Date(json.lastSubmittedAt),
});
