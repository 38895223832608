import classNames from "classnames";
import * as React from "react";

import add from "date-fns/add";
import endOfMonth from "date-fns/endOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import sub from "date-fns/sub";
import { useCallback, useState } from "react";
import {
  formatDate,
  formatMonth,
  formatWeek,
} from "../../../helpers/TimeHelper";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { AnalyticsTermType } from "../../../interfaces/AnalyticsTableInterface";
import styleVars from "../../../styles/variables.scss";
import Icon from "../../atoms/Icon";
import { DateRangePicker } from "../DateRangePicker";
import styles from "./styles.scss";

interface Props {
  startDate: Date;
  endDate: Date;
  term: AnalyticsTermType;
  calendarDisabled: boolean;
  onDatesChange: (dateRange: { startDate?: Date; endDate?: Date }) => void;
  isDayBlocked?: (day: Date) => boolean;
  canPushRightButton?: boolean;
  label?: string;
  changeOnNavigate?: number;
}

export const DateRangeNavigation = (props: Props) => {
  const [calendarVisible, setCalendarVisible] = useState(false);

  const numberOfMonths =
    window.innerWidth > parseInt(styleVars.breakpointMobile) ? 2 : 1;

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (props.calendarDisabled) {
      return;
    }
    setCalendarVisible(!calendarVisible);
  };

  const changeOnNavigate = () => {
    switch (props.term) {
      case "monthly":
        return props.changeOnNavigate ?? 7;
      case "weekly":
        return props.changeOnNavigate ?? 7;
      case "daily":
      default:
        return props.changeOnNavigate ?? 7;
    }
  };

  const handleClickLeft = () => {
    const { startDate, endDate, term } = props;
    const change = changeOnNavigate();

    switch (term) {
      case "monthly":
        props.onDatesChange({
          startDate: sub(startOfMonth(startDate), { months: change }),
          endDate: endOfMonth(sub(endDate, { months: change })),
        });
        break;

      case "weekly":
        props.onDatesChange({
          startDate: sub(startOfWeek(startDate, { weekStartsOn: 1 }), {
            weeks: change,
          }),
          endDate: sub(endDate, { weeks: change }),
        });
        break;

      case "daily":
      default:
        props.onDatesChange({
          startDate: sub(startDate, { days: 7 }),
          endDate: sub(endDate, { days: 7 }),
        });
    }
  };

  const handleClickRight = () => {
    if (!props.canPushRightButton) {
      return;
    }

    const { startDate, endDate } = props;
    const change = changeOnNavigate();

    switch (props.term) {
      case "monthly":
        props.onDatesChange({
          startDate: add(startOfMonth(startDate), { months: change }),
          endDate: endOfMonth(add(endDate, { months: change })),
        });
        break;

      case "weekly":
        props.onDatesChange({
          startDate: add(startOfWeek(startDate, { weekStartsOn: 1 }), {
            weeks: change,
          }),
          endDate: add(endDate, { weeks: change }),
        });
        break;

      case "daily":
      default:
        props.onDatesChange({
          startDate: add(startDate, { days: 7 }),
          endDate: add(endDate, { days: 7 }),
        });
    }
  };

  const handleDatesChange = useCallback(
    (dateRange: { startDate?: Date; endDate?: Date }) => {
      setCalendarVisible(false);
      props.onDatesChange({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
    },
    [props.onDatesChange],
  );

  const handleClickOutside = useCallback(() => {
    setCalendarVisible(false);
  }, []);
  const { insideRef } = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  return (
    <div>
      <div className={styles.calendarControl}>
        <Icon
          name="icon-arrow-left"
          className={styles.icon}
          onClick={handleClickLeft}
        />
        <button
          className={classNames(styles.dateRange, {
            [styles.disabledDateRange]: props.calendarDisabled,
          })}
          onClick={handleButtonClick}
        >
          {formatDateRange({
            term: props.term,
            startDate: props.startDate,
            endDate: props.endDate,
            label: props.label,
          })}
          <Icon
            name="icon-calendar"
            className={`${styles.icon} ${styles.calendarIcon}`}
          />
        </button>
        <Icon
          name="icon-arrow-right"
          className={classNames(styles.icon, {
            [styles.disabledIcon]: !props.canPushRightButton,
          })}
          onClick={handleClickRight}
        />
        {calendarVisible && (
          <div className={styles.calendar}>
            {props.term === "daily" && (
              <div ref={insideRef}>
                <DateRangePicker
                  startDate={props.startDate}
                  endDate={props.endDate}
                  endDateOffset={6}
                  onDateRangeChange={handleDatesChange}
                  numberOfMonths={numberOfMonths}
                  disabled={props.isDayBlocked}
                  defaultMonth={props.startDate}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// Helpers

type FormatDateRangeParam = {
  term: AnalyticsTermType;
  startDate: Date;
  endDate: Date;
  label?: string;
};
export const formatDateRange = ({
  term,
  startDate,
  endDate,
  label,
}: FormatDateRangeParam): string => {
  if (label) {
    return label;
  }
  switch (term) {
    case "monthly":
      return `${formatMonth(startDate)}〜${formatMonth(endDate)}`;
    case "weekly":
      return `${formatWeek(startDate)}〜${formatWeek(endDate)}`;
    case "daily":
    default:
      return `${formatDate(startDate)}〜${formatDate(endDate)}`;
  }
};
