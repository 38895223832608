import classNames from "classnames";
import * as React from "react";

export const CheckboxRow = (props: {
  children: React.ReactNode;
  indent?: boolean;
}) => {
  return (
    <div
      role="row"
      className={classNames(
        "flex flex-nowrap items-center justify-between py-5 pr-7",
        {
          "pl-10": !props.indent,
          "pl-14": props.indent,
        },
      )}
    >
      {props.children}
    </div>
  );
};
