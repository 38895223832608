import { isNotAttendant } from "../../../domains/LectureAttendance";
import {
  ScheduleForStudent,
  hasStudySchedule,
} from "../../../domains/Schedule";
import { Colors } from "../../../helpers/styles/Colors";
import styleVars from "../../../styles/variables.scss";

// 生徒予定のデータからカレンダーにプロットする予定の色を決める
// カレンダーライブラリの仕様のため文字列のカラーコードを返す
export const determineScheduleColorCode = (schedule: ScheduleForStudent) => {
  if (
    schedule.lectureAttendance &&
    isNotAttendant(schedule.lectureAttendance)
  ) {
    return styleVars.colorGrayDarken1;
  }
  return hasStudySchedule(schedule)
    ? styleVars.colorPrimary
    : styleVars.colorIconYellow;
};

// 生徒予定のデータからカレンダーの予定詳細に表示するタイトル横のドットの色を決める
// こちらはColors(FS内のカラー型)を返す
export const determineScheduleColor = (
  schedule: ScheduleForStudent,
): Colors => {
  if (
    schedule.lectureAttendance &&
    isNotAttendant(schedule.lectureAttendance)
  ) {
    return "gray-darken-1";
  }
  return hasStudySchedule(schedule) ? "primary" : "yellow";
};
