import { createAction } from "redux-actions";
import ApiErrorResponseInterface, {
  EmergencyErrorInterface,
  MaintenanceErrorInterface,
} from "../../interfaces/ApiErrorResponseInterface";
import EmergencyInterface from "../../interfaces/EmergencyInterface";

// エラーページ表示を伴うエラーアクション
export const notFoundError = createAction("NOT_FOUND_ERROR");
export const forbiddenError = createAction("FORBIDDEN_ERROR");
export const steakError = createAction<EmergencyInterface>("STEAK_ERROR");
export const maintenanceError = createAction("MAINTENANCE_ERROR");
export const unknownError = createAction("UNKNOWN_ERROR");
export const unauthorizedError = createAction(
  "authorization/UNAUTHORIZED_ERROR",
);

export type ErrorAction =
  | ReturnType<typeof notFoundError>
  | ReturnType<typeof forbiddenError>
  | ReturnType<typeof steakError>
  | ReturnType<typeof maintenanceError>
  | ReturnType<typeof unknownError>
  | ReturnType<typeof unauthorizedError>;

// エラーのresponseを見てそれに対応するアクションを返す
// エラーページ表示を伴うエラーアクションなので、
// これを利用するのはそのページのメインのリソースのエラーのときのみ
export const buildMainResourceApiErrorAction = (
  statusCode?: number,
  errorResponse?: ApiErrorResponseInterface<
    Partial<EmergencyErrorInterface & MaintenanceErrorInterface>
  >,
): ErrorAction => {
  switch (statusCode) {
    case 401:
      return unauthorizedError();
    case 403:
      return forbiddenError();
    case 404:
      return notFoundError();
    case 503:
      if (
        !errorResponse ||
        !errorResponse.errors ||
        errorResponse.errors.length <= 0
      ) {
        return unknownError();
      }

      if (errorResponse.errors[0].emergency) {
        return steakError(errorResponse.errors[0].emergency);
      } else if (errorResponse.errors[0].maintenance) {
        return maintenanceError(errorResponse.errors[0].maintenance);
      } else {
        return unknownError();
      }
    default:
      return unknownError();
  }
};

// エラー情報をリセットするアクション
export const dispatchResetPageErrors = createAction("RESET_PAGE_ERRORS");
