import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";
import ApiClient from "../../../api";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";

export type CourseShareFormProps = {
  sectionId: string;
};

type FormValues = {
  code: string;
};

export const useCourseShareForm = ({ sectionId }: CourseShareFormProps) => {
  const mutation = useShareCourse(sectionId);
  const form = useFormik<FormValues>({
    initialValues: { code: "" },
    isInitialValid: false,
    onSubmit: (values) => {
      mutation.mutate(values.code);
    },
    validationSchema,
  });

  useEffect(() => {
    form.setSubmitting(false);
  }, [mutation.error]);

  return form;
};

const validationSchema = yup.object().shape({
  code: yup
    .string()
    .required("コースコードの入力は必須です")
    .test("CourseCodeValidator", "コースコードが不正です。", (strCode) =>
      new RegExp(/^[0-9a-zA-Z]*$/).test(strCode ?? ""),
    ),
});

const useShareCourse = (sectionId: string) => {
  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const defaultErrorMessage = "他教室のコースが登録できませんでした";

  return useMutation({
    mutationFn: async (courseId: string) => {
      const response = await ApiClient.post(
        `/api/v1/sections/${sectionId}/contents/courses/${courseId}/share`,
      );
      if (response.ok) {
        return;
      } else if (response.status === 404) {
        throw `入力されたコードのコースが存在しません`;
      } else if (response.status === 422) {
        const json = (await response.json()) as ApiErrorResponseInterface;
        if (json.errors?.length > 0) {
          throw json.errors.map((error) => error.message).join("\n");
        }
      }
      throw defaultErrorMessage;
    },
    onSuccess: () => {
      showSuccessMessage("コースを登録しました");
      navigate(`/sections/${sectionId}/settings/content_courses`);
    },
    onError: (error: string) => {
      showErrorMessage(error);
    },
  });
};
