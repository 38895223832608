import classNames from "classnames";
import * as React from "react";
import StudentTag from "../../../domains/StudentTag";
import { ActiveOperatorInterface } from "../../../interfaces/OperatorInterface";
import Icon from "../../atoms/Icon";
import SelectTagModal, { SelectTagsFor } from "../SelectTagModal";
import styles from "./styles.scss";

interface Props {
  sectionId: string;
  operator: ActiveOperatorInterface;
  sectionTags: StudentTag[];
  updateOperatorSectionTags: (
    sectionId: string,
    operatorId: string,
    tagIds: string[],
  ) => void;
}

interface State {
  isOpen: boolean;
  filteredTags: StudentTag[];
  selectedTags: StudentTag[];
}

class OperatorTagSwitch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      filteredTags: props.sectionTags,
      selectedTags: props.operator.tags,
    };
  }

  componentDidUpdate(nextProps: Props) {
    if (!this.state.isOpen) {
      return;
    }

    if (this.props.operator.tags != nextProps.operator.tags) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderToggle()}
        <SelectTagModal
          submitting={this.props.operator.submitting}
          selectTagsFor={SelectTagsFor.Operator}
          sectionTags={this.props.sectionTags}
          selectedTags={this.props.operator.tags}
          isOpen={this.state.isOpen}
          onRequestClose={this.handleModalClose}
          onSubmit={this.handleClick}
        >
          <h2>{this.props.operator.fullName}の閲覧制限タグ設定</h2>
        </SelectTagModal>
      </React.Fragment>
    );
  }

  get selectedTags() {
    return this.state.selectedTags;
  }

  get filteredTags() {
    return this.state.filteredTags;
  }

  private renderToggle() {
    const name = this.props.operator.isLimited
      ? "icon-toggle-on"
      : "icon-toggle-off";
    const label = this.props.operator.isLimited ? "あり" : "なし";
    return (
      <button
        type="button"
        onClick={this.handleToggle}
        className={classNames(styles.toggle, {
          [styles.limited]: this.props.operator.isLimited,
        })}
      >
        <Icon name={name} className={styles.svg} />
        {label}
      </button>
    );
  }

  private handleToggle = () => {
    this.setState({ isOpen: true });
  };

  private handleModalClose = () => {
    this.setState({ isOpen: false });
  };

  private handleClick = (selectedTags: StudentTag[]) => {
    if (this.props.operator.submitting) {
      return;
    } else {
      this.props.updateOperatorSectionTags(
        this.props.sectionId,
        this.props.operator.id,
        selectedTags.map((tag: StudentTag) => tag.id),
      );
    }
  };
}

export default OperatorTagSwitch;
