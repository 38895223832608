import * as React from "react";
import { useFetchClassrooms } from "../../hooks/http/useClassroomsApi";
import { useFetchTeachers } from "../../hooks/http/useFetchTeachers";
import { useQueryError } from "../../hooks/http/useQueryError";
import SectionInterface from "../../interfaces/SectionInterface";

type Props = {
  section: SectionInterface;
};
export const useSectionSettingsSectionScheduleListPage = ({
  section,
}: Props) => {
  const {
    data: classroomData,
    isLoading: isClassroomsLoading,
    error: classroomError,
  } = useFetchClassrooms({
    sectionId: section.id,
    archiveStatus: "active",
    shouldGetAllRecords: true,
    refetchOnWindowFocus: false,
  });
  useQueryError(classroomError);

  const {
    data: teacherData,
    isLoading: isTeachersLoading,
    error: teacherError,
  } = useFetchTeachers({
    sectionId: section.id,
    status: "active",
    shouldGetAllRecords: true,
    refetchOnWindowFocus: false,
  });
  useQueryError(teacherError);

  const refConditionsInitialized = React.useRef(false);

  const [selectedStudents, setSelectedStudents] =
    React.useState<ReadonlyArray<string> | null>(null);
  const [selectedTeachers, setSelectedTeachers] =
    React.useState<ReadonlyArray<string> | null>(null);
  const [selectedClassrooms, setSelectedClassrooms] =
    React.useState<ReadonlyArray<string> | null>(null);

  const isLoading = isClassroomsLoading || isTeachersLoading;
  const searchConditionLoaderProps = {
    loading: isLoading,
  };

  // ページをロードした時に教室と講師をリクエストして、一度全部検索対象にする
  React.useEffect(() => {
    if (!refConditionsInitialized.current && teacherData && classroomData) {
      setSelectedClassrooms(classroomData.map((item) => item.id));
      setSelectedTeachers(teacherData.map((item) => item.id));
      refConditionsInitialized.current = true;
    }
  }, [teacherData, classroomData]);

  const resourceTeacherOptions = React.useMemo(() => {
    if (!teacherData) {
      return [];
    }
    return teacherData.map((item) => ({ id: item.id, name: item.fullName }));
  }, [teacherData]);

  const resourceClassroomOptions = React.useMemo(() => {
    if (!classroomData) {
      return [];
    }
    return classroomData.map((item) => ({ id: item.id, name: item.name }));
  }, [classroomData]);

  const studentConditionSelectorProps = {
    onChange: setSelectedStudents,
  };

  const teacherConditionSelectorProps = {
    resourceOptions: resourceTeacherOptions,
    initialSelectedIds: selectedTeachers ?? [],
    onChange: setSelectedTeachers,
  };

  const classroomConditionSelectorProps = {
    resourceOptions: resourceClassroomOptions,
    initialSelectedIds: selectedClassrooms ?? [],
    onChange: setSelectedClassrooms,
  };

  const searchScheduleListProps = {
    selectedStudentIds: selectedStudents ?? [],
    selectedClassroomIds: selectedClassrooms ?? [],
    selectedTeacherIds: selectedTeachers ?? [],
    section,
  };

  return {
    studentConditionSelectorProps,
    teacherConditionSelectorProps,
    classroomConditionSelectorProps,
    selectedStudents,
    selectedTeachers,
    selectedClassrooms,
    searchConditionLoaderProps,
    searchScheduleListProps,
    // 選択済みのものが初期化(初期値は全て選択)されるまで検索を実行しない
    searchConditionReady:
      selectedClassrooms !== null && selectedTeachers !== null,
  };
};
