import AlignMiddle from "../../../components/atoms/AlignMiddle/index";
import Icon from "../../../components/atoms/Icon";
import LinkButton from "../../../components/atoms/LinkButton/index";
import Table from "../../../components/atoms/Table/index";
import { TableColumn } from "../../../components/atoms/Table/index";
import {
  FileTypes,
  formatContentType,
  formatFileSize,
} from "../../../domains/Content";
import { InputFile } from "./";
import styles from "./ContentFilesTable.scss";

type Props = {
  files: readonly InputFile[];
  onClickDelete: (id: string) => void;
};

export const ContentFilesTable = ({ files, onClickDelete }: Props) => {
  return (
    <Table
      headers={[
        { key: "name", text: "コンテンツ名", centering: false },
        { key: "fileSize", text: "サイズ", centering: true },
        { key: "contentType", text: "種類", centering: true },
        { key: "delete", text: "", centering: false },
      ]}
      rows={files.map((inputFile) => (
        <TableRow
          key={inputFile.id}
          inputFile={inputFile}
          onClickDelete={onClickDelete}
        />
      ))}
      headColumnClassName={""}
      className={styles.table}
    />
  );
};

type RowProps = {
  inputFile: InputFile;
  onClickDelete: Props["onClickDelete"];
};
const TableRow = ({ inputFile, onClickDelete }: RowProps) => {
  const { id, file } = inputFile;
  return (
    <tr key={id} className={styles.row}>
      <TableColumn className={styles.contentNameColumn}>
        {file.name}
      </TableColumn>
      <TableColumn noWrap centering className={styles.sizeColumn}>
        {formatFileSize(file.size)}
      </TableColumn>
      <TableColumn noWrap centering className={styles.fileTypeColumn}>
        {formatContentType(file.type as FileTypes, null)}
      </TableColumn>
      <TableColumn noWrap bold centering className={styles.deleteColumn}>
        <LinkButton
          aria-label="指定したファイルの削除"
          onClick={() => {
            onClickDelete(id);
          }}
          bold
        >
          <AlignMiddle>
            <Icon name="icon-close-x" />
          </AlignMiddle>
        </LinkButton>
      </TableColumn>
    </tr>
  );
};
