import classNames from "classnames";
import StudentInterface from "../../../interfaces/StudentInterface";
import styles from "../SectionsSettingsStudentsIndex.scss";

export const LineCodeStatus = ({ student }: { student: StudentInterface }) => {
  return (
    <span
      className={classNames(styles.guardianStatus, {
        [styles.active]: student.lineConnectionStatus === "connected",
      })}
    >
      {student.lineConnectionStatusHuman}
    </span>
  );
};
