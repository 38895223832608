import { useInfiniteQuery } from "@tanstack/react-query";
import api from "../../api";
import { fromJson as toScheduleFromJson } from "../../domain-adapters/Schedule";
import { SectionSchedule } from "../../domains/SectionSchedule";
import { HTTPErrors, createError } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { toQueryString } from "../../helpers/TimeHelper";
import ApiResponse from "../../interfaces/ApiResponse";
import SectionInterface from "../../interfaces/SectionInterface";

type FetchResponse = ApiResponse<ReadonlyArray<SectionSchedule>>;

type UseFetchSectionSchedulesProps = {
  selectedStudentIds: ReadonlyArray<string>;
  selectedClassroomIds: ReadonlyArray<string>;
  selectedTeacherIds: ReadonlyArray<string>;
  date: Date;
  section: SectionInterface;
};

export const useFetchSectionSchedules = ({
  section,
  selectedStudentIds,
  selectedClassroomIds,
  selectedTeacherIds,
  date,
}: UseFetchSectionSchedulesProps) => {
  const cacheKey = cacheKeyOf({
    section,
    selectedStudentIds,
    selectedClassroomIds,
    selectedTeacherIds,
    date,
  });
  const result = useInfiniteQuery<FetchResponse, HTTPErrors>({
    queryKey: cacheKey,
    queryFn: async ({ pageParam }) => {
      const res = await api.interruptGet(fetchUrlOf(section.id), {
        query: {
          page: pageParam,
          student_public_ids: selectedStudentIds,
          classroom_ids: selectedClassroomIds,
          teacher_ids: selectedTeacherIds,
          date: toQueryString(date),
        },
      });
      if (res.ok) {
        const json = await res.json();
        if (
          json.scheduleSections &&
          json.scheduleSections.data &&
          json.scheduleSections.meta
        ) {
          return {
            meta: json.scheduleSections.meta,
            data: json.scheduleSections.data.map(
              (scheduleSection: Record<string, any>) =>
                fromJson(scheduleSection),
            ),
          };
        }
      }

      throw await createError(res);
    },
    initialPageParam: 1,
    getNextPageParam,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const data = result.data?.pages.flatMap((item) => item.data);
  return { ...result, data };
};
const fetchUrlOf = (sectionId: string) =>
  `/api/v1/sections/${sectionId}/schedules`;

const cacheKeyOf = (props: UseFetchSectionSchedulesProps) => [
  "section/schedules",
  props.section.id,
  "date",
  toQueryString(props.date),
  "teachers",
  ...props.selectedTeacherIds,
  "classrooms",
  ...props.selectedClassroomIds,
  "students",
  ...props.selectedStudentIds,
];

export const fromJson = (json: Record<string, any>): SectionSchedule => {
  return {
    id: json.id,
    studentCount: json.studentCount,
    schedule: toScheduleFromJson(json.schedule),
    classroom: json.classroom,
    teacher: json.teacher,
    lectureSession: json.lectureSession,
  };
};
