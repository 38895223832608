import { useQuery } from "@tanstack/react-query";
import { boronClient } from "../../../api";
import { HTTPErrors, createError } from "../../../errors";
import { paths } from "../../../lib/api/v1";

type Props = {
  sectionId: string;
  studyTaskId: string;
};
export type StudyTaskDetailResponse =
  paths["/api/v1/sections/{section_id}/study_tasks/{study_task_hashid}"]["get"]["responses"]["200"]["content"]["application/json"];

const URL = "/api/v1/sections/{section_id}/study_tasks/{study_task_hashid}";

export const useFetchStudyTask = ({ sectionId, studyTaskId }: Props) => {
  return useQuery<StudyTaskDetailResponse, HTTPErrors>({
    queryKey: [URL, sectionId, studyTaskId],
    queryFn: async () => {
      const { data, response } = await boronClient.GET(URL, {
        params: {
          path: {
            section_id: sectionId,
            study_task_hashid: studyTaskId,
          },
        },
      });
      if (response.ok && data) {
        return data;
      }
      throw await createError(response);
    },
    enabled: !!sectionId && !!studyTaskId,
  });
};
