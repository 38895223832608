import * as React from "react";
import { connect } from "react-redux";
import {
  loadSkill,
  navigateToSkillIndexPage,
  updateSkill,
} from "../../../actions/common/skills/index";
import Loader from "../../../components/atoms/Loader";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";
import {
  AuthenticatedPageCallbacks,
  AuthenticatedPageProps,
} from "../../../hocs/enhanceAuthenticatedPage";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import SectionsSettingsSkillsEditStateInterface from "../../../interfaces/SectionsSettingsSkillsEditStateInterface";
import NotFoundErrorPage from "../NotFoundErrorPage";
import SectionsSettingsStudyRecordSkillsEditPage from "../SectionsSettingsStudyRecordSkillsEditPage/index";

interface Params {
  skillId: string;
}

interface DispatchProps {
  loadSkill: typeof loadSkill;
  updateSkill: typeof updateSkill;
  navigateToSkillIndexPage: typeof navigateToSkillIndexPage;
}

type StateProps = SectionsSettingsSkillsEditStateInterface;

type Props = WithRouterProps<Params> &
  StateProps &
  DispatchProps &
  AuthenticatedPageCallbacks &
  AuthenticatedPageProps &
  OutletContextProps;

const Page = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("skills");
  }, []);
  const { loadSkill, skill, ...childProps } = props;

  React.useEffect(() => {
    if (props.currentSection && props.currentSection.data) {
      loadSkill(props.currentSection.data.id, props.params.skillId);
    }
  }, []);

  if (props.loading) {
    return <Loader loading={props.loading} />;
  }

  if (!skill) {
    return <NotFoundErrorPage />;
  }

  switch (skill.skillMaster && skill.skillMaster.type) {
    case "study_record_skill_master":
    default:
      return (
        <SectionsSettingsStudyRecordSkillsEditPage
          {...childProps}
          skill={skill}
        />
      );
  }
};

const mapStateToProps = (state: AppStateInterface): StateProps =>
  state.sectionsSettingsSkillsEdit;

const actions = {
  loadSkill,
  updateSkill,
  navigateToSkillIndexPage,
};

const pageInfo = {
  title: "アシスタント",
};

const EnhancedPage = enhanceSectionsSettingsPage(Page, pageInfo, [
  "assistant:index",
]);

const SectionsSettingsSkillsEditPage = connect<
  StateProps,
  DispatchProps,
  void,
  AppStateInterface
>(
  mapStateToProps,
  actions,
)(EnhancedPage as any);

export default SectionsSettingsSkillsEditPage;
