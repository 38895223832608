import { useEffect } from "react";
import { useParams } from "react-router";
import Loader from "../../components/atoms/Loader";
import { OutletContextProps } from "../../helpers/RouterHelper.tsx";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage.tsx";
import OperatorInterface from "../../interfaces/OperatorInterface.ts";
import SectionInterface from "../../interfaces/SectionInterface.ts";
import SectionStaffBoardPost from "./SectionStaffBoardPost";

interface Props extends OutletContextProps {
  section: SectionInterface;
  currentOperator: OperatorInterface | undefined;
}

const Index = (props: Props) => {
  const { setActiveMenu, section, currentOperator } = props;

  useEffect(() => {
    setActiveMenu("dashboard");
  }, []);

  const params = useParams<{ staffBoardPostId: string }>();

  if (!params.staffBoardPostId || !currentOperator) {
    return <Loader loading />;
  }

  return (
    <SectionStaffBoardPost
      section={section}
      currentOperator={currentOperator}
      staffBoardPostId={params.staffBoardPostId}
    />
  );
};

const pageInfo = {
  title: "スタッフボード",
};
const EnhancedPage = enhanceSectionsPage(Index, pageInfo);

export default EnhancedPage;
