import classNames from "classnames";
import * as React from "react";
import Icon from "../../atoms/Icon/index";
import styles from "./styles.scss";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  isBlock?: boolean;
  onReset?: () => void;
};

export const SearchInput = (props: Props) => {
  const { isBlock, onReset, ...inputProps } = props;

  return (
    <div className={classNames(styles.root, { [styles.isBlock]: isBlock })}>
      <Icon name="icon-search" />
      <input
        {...inputProps}
        className={classNames(styles.input, { [styles.isBlock]: isBlock })}
      />
      {onReset && inputProps.value ? <ResetButton onReset={onReset} /> : null}
    </div>
  );
};

const ResetButton = (props: { onReset: Props["onReset"] }) => {
  return (
    <button onClick={props.onReset} className={styles.reset}>
      <Icon name="icon-failed" className={styles.icon} />
    </button>
  );
};
