import { ExaminationClassificationSelect } from "../../components/features/ExaminationClassificationSelect/ExaminationClassificationSelect";
import { ExaminationOrganizerSelect } from "../../components/features/ExaminationOrganizerSelect/ExaminationOrganizerSelect";

type Params = {
  organizerId: string | null;
  classificationId: string | null;
};

type Props = {
  values: Params;
  onChange: (params: Partial<Params>) => void;
  sectionId?: string;
};
export const ExaminationFilter = ({ values, onChange, sectionId }: Props) => {
  const handleOrganizerChange = (value: string | null) => {
    onChange({ organizerId: value });
  };
  const handleClassificationChange = (value: string | null) => {
    onChange({ classificationId: value });
  };

  return (
    <div className="flex gap-4">
      <div className="w-96">
        <ExaminationOrganizerSelect
          sectionId={sectionId}
          onChange={handleOrganizerChange}
          size="xs"
          placeholder="主催者"
          value={values.organizerId ?? undefined}
        />
      </div>
      <div className="w-96">
        <ExaminationClassificationSelect
          sectionId={sectionId}
          size="xs"
          organizerId={values.organizerId}
          onChange={handleClassificationChange}
          placeholder="試験の種類"
          value={values.classificationId ?? null}
        />
      </div>
    </div>
  );
};
