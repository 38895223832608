import classnames from "classnames";
import { resolveColorStyles } from "../../../../helpers/styles/Colors";
import { Text } from "../../../general/Text";
import styles from "./LectureTypeLabel.scss";

export type LectureTypes =
  | "transferred"
  | "fromOtherSection"
  | "transferredFromOtherSection"
  | "normal";
type Props = {
  lectureType: LectureTypes;
};

const LectureTypeLabels: Record<LectureTypes, string> = {
  fromOtherSection: "併校",
  transferred: "振替",
  transferredFromOtherSection: "振替", // NOTE: 振替かつ併校の場合は、振替が表示される
  normal: "",
};

export const LectureTypeLabel = ({ lectureType }: Props) => {
  if (lectureType === "normal") {
    return null;
  }
  return (
    <div
      className={classnames(
        styles.LectureTypeLabel,
        resolveColorStyles({
          backgroundColor:
            lectureType === "fromOtherSection" ? "green" : "yellow",
        }),
      )}
    >
      <Text as="span" color="white" size="xs" whiteSpace="nowrap">
        {LectureTypeLabels[lectureType]}
      </Text>
    </div>
  );
};

export const CanceledLectureType = () => (
  <div
    className={classnames(
      styles.LectureTypeLabel,
      resolveColorStyles({ backgroundColor: "gray-darken-1" }),
    )}
  >
    <Text as="span" color="white" size="xs">
      休講
    </Text>
  </div>
);
