import * as React from "react";
import { useParams } from "react-router";
import { BlockRow } from "../../../../components/atoms/BlockRow";
import Loader from "../../../../components/atoms/Loader";
import { BreadcrumbsView } from "../../../../components/general/BreadcrumbsView";
import { OutletContextProps } from "../../../../helpers/RouterHelper";
import { StudentsPageProps } from "../../../../hocs/enhanceStudentsPage";
import { useFetchStudentCourseResult } from "../../../../hooks/http/useFetchStudentCourseResult";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import StudentInterface from "../../../../interfaces/StudentInterface";
import { StudentCourseResultSummary } from "../StudentCourseResultSummary";
import { StudentCourseResultTable } from "../StudentCourseResultTable";
import styles from "./styles.scss";

type Props = StudentsPageProps &
  OutletContextProps & {
    student: StudentInterface;
  };

const useStudentCourseResultPage = ({ student }: Props) => {
  const { section } = student;
  const { courseId = "" } = useParams<{ courseId: string }>();
  const breadCrumbItems = [
    {
      label: "コンテンツ配信",
      url: `/sections/${section.id}/settings/contents`,
    },
    {
      label: "学習結果",
      url: `/sections/${section.id}/settings/content_courses/${courseId}/results`,
    },
    {
      label: student.fullName,
    },
  ];
  const result = useFetchStudentCourseResult(student.id, courseId);
  useQueryError(result.error);

  const unitCount = result.data ? result.data.unitResults.length : 0;
  const contentCount = result.data
    ? result.data.unitResults.reduce<number>(
        (accum, unitResult) => accum + unitResult.contentResults.length,
        0,
      )
    : 0;
  return {
    breadCrumbItems,
    unitCount,
    contentCount,
    ...result,
  };
};

export const StudentCourseResult = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("");
  }, []);
  const {
    breadCrumbItems,
    data: studentCourseResult,
    unitCount,
    contentCount,
    isLoading,
  } = useStudentCourseResultPage(props);
  if (!studentCourseResult) return null;
  return (
    <div className={styles.StudentCourseResult}>
      <BreadcrumbsView items={breadCrumbItems} />
      <div className={styles.StudentCourseResult__container}>
        <StudentCourseResultSummary
          studentCourseResult={studentCourseResult}
          section={props.student.section}
          unitCount={unitCount}
          contentCount={contentCount}
        />
        <BlockRow marginTop="2rem" />
        <StudentCourseResultTable studentCourseResult={studentCourseResult} />
      </div>
      <Loader loading={isLoading} />
    </div>
  );
};
