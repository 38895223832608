import { IconLock } from "@studyplus/boron-ui";
import classNames from "classnames";
import {
  FlexibleTable,
  TableColumn,
  TableHeadColumn,
} from "../../components/atoms/Table";
import { DrillProgressCell } from "../../components/features/DrillProgressCell";
import SortLabel, {
  OrderDirectionType,
} from "../../components/molecules/SortLabel";
import { Progress } from "../../domains/DrillProgress";
import { DrillSection } from "../../domains/DrillSection";
import { SectionProgress } from "../../domains/DrillSectionProgress";
import { DrillStudentProgress } from "../../domains/DrillStudentProgress";
import TimeHelper from "../../helpers/TimeHelper";

type Props = {
  drillSections: DrillSection[];
  drillLearningMaterialsProgresses: DrillStudentProgress[];
  order: OrderDirectionType;
  onOrderChange: (order: OrderDirectionType) => void;
};

export const DrillProgressTable = (props: Props) => {
  const drillProgresses = props.drillLearningMaterialsProgresses;
  return (
    <FlexibleTable className="block w-full overflow-x-auto">
      <TableHeaders
        drillSections={props.drillSections}
        onOrderChange={props.onOrderChange}
        order={props.order}
      />
      {drillProgresses.length ? (
        drillProgresses.map((progress) => {
          return (
            <TableRow
              key={progress.student.id}
              drillSections={props.drillSections}
              {...progress}
            />
          );
        })
      ) : (
        <div className="my-7 text-center">
          該当する生徒が見つかりませんでした
        </div>
      )}
    </FlexibleTable>
  );
};

type TableHeadersProps = {
  drillSections: DrillSection[];
  onOrderChange: (order: OrderDirectionType) => void;
  order: OrderDirectionType;
};

const TableHeaders = ({
  drillSections,
  onOrderChange,
  order,
}: TableHeadersProps) => {
  return (
    <thead>
      <tr className="bg-white">
        <TableHeadColumn
          centering
          className="sticky left-0 min-w-[16rem] max-w-[16rem] border-0 border-b border-solid border-gray-400 bg-white px-4 py-1"
        >
          <div className="flex items-center justify-center">氏名</div>
        </TableHeadColumn>
        <TableHeadColumn
          centering
          className="min-w-[16rem] max-w-[16rem] border-0 border-b border-solid border-gray-400 px-4 py-1"
        >
          <div className="flex items-center justify-center">総学習時間</div>
        </TableHeadColumn>
        <TableHeadColumn
          centering
          className="min-w-[16rem] max-w-[16rem] border-0 border-b border-solid border-gray-400 px-4 py-1"
        >
          <SortLabel
            label="全体定着率"
            orderDirection={order}
            onClick={onOrderChange}
            active
          />
        </TableHeadColumn>
        {drillSections.map((section) => {
          return (
            <TableHeadColumn
              centering
              className="min-w-[16rem] max-w-[16rem] border-0 border-b border-solid border-gray-400 px-4 py-1 last:border-r"
              key={section.id}
            >
              <div className="line-clamp-2 max-h-10 overflow-hidden whitespace-normal text-center leading-7">
                {section.name}
              </div>
            </TableHeadColumn>
          );
        })}
      </tr>
    </thead>
  );
};

type TableRowProps = DrillStudentProgress & {
  drillSections: DrillSection[];
};

const TableRow = ({
  progress,
  student,
  sectionProgresses,
  totalDuration,
  drillSections,
  shelved,
}: TableRowProps) => {
  return (
    <tr
      className={classNames("[&:last-child>td]:border-b-0", {
        "bg-white": shelved,
        "bg-gray-100": !shelved,
      })}
    >
      <TableColumn
        centering
        // z-indexを設定しないとstickyで他のカラムと重なった時に透けてしまう
        className={classNames("sticky left-0 z-10", columnClassNames(shelved))}
      >
        <div
          className={classNames("font-bold text-black", {
            "opacity-50": !shelved,
          })}
        >
          {student.fullName}
        </div>
      </TableColumn>
      {student.billingPlan === "pro" ? (
        <ProPlanStudentProgresses
          progress={progress}
          sec={totalDuration}
          sectionProgresses={sectionProgresses}
          drillSections={drillSections}
          shelved={shelved}
        />
      ) : (
        <FreePlanStudentProgresses
          colSpan={drillSections.length + 2}
          shelved={shelved}
        />
      )}
    </tr>
  );
};

const ProPlanStudentProgresses = (props: {
  progress: Progress;
  sec: number;
  drillSections: DrillSection[];
  sectionProgresses: SectionProgress[];
  shelved: boolean;
}) => (
  <>
    <TableColumn centering className={columnClassNames(props.shelved)}>
      {TimeHelper.secondsToDisplayTime(props.sec)}
    </TableColumn>
    <TableColumn centering className={columnClassNames(props.shelved)}>
      <div className={classNames({ "opacity-50": !props.shelved })}>
        <DrillProgressCell
          progress={props.progress}
          keysIncludedInPercentage={["perfectCount"]}
        />
      </div>
    </TableColumn>
    {props.drillSections.map((section) => {
      const currentSectionProgress = props.sectionProgresses.find(
        (sectionProgress) => sectionProgress.sectionId === section.id,
      );

      return (
        <TableColumn
          className={columnClassNames(props.shelved)}
          key={section.id}
        >
          <div className={classNames({ "opacity-50": !props.shelved })}>
            <DrillProgressCell
              progress={{
                notStudiedCount: currentSectionProgress?.notStudiedCount || 0,
                studyingCount: currentSectionProgress?.studyingCount || 0,
                establishedCount: currentSectionProgress?.establishedCount || 0,
                perfectCount: currentSectionProgress?.perfectCount || 0,
              }}
              duration={currentSectionProgress?.duration || 0}
            />
          </div>
        </TableColumn>
      );
    })}
  </>
);

const FreePlanStudentProgresses = (props: {
  colSpan: number;
  shelved: boolean;
}) => (
  <TableColumn
    colSpan={props.colSpan}
    className={columnClassNames(props.shelved)}
  >
    <p className="flex items-center gap-1 px-7 py-4 text-md text-gray-800">
      <IconLock className="inline-block h-2 w-2 shrink-0 grow-0 text-gray-600" />
      <span>
        <a
          href="https://fs-help.studyplus.co.jp/ja/articles/9153866"
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          プロプラン
        </a>
        にすると学習状況が閲覧できます
      </span>
    </p>
  </TableColumn>
);

const columnClassNames = (shelved: boolean) =>
  classNames(
    "h-auto border-0 border-b border-r border-solid text-black border-gray-400 p-0",
    {
      "bg-white": shelved,
      "bg-gray-100 text-opacity-50": !shelved,
    },
  );
