import * as React from "react";
import Helmet from "react-helmet";
import EnglishPrivacyPolicy from "../../../components/atoms/EnglishPrivacyPolicy";
import SubLayout from "../../../components/atoms/SubLayout";

const EnglishPrivacyPolicyPage: React.FC<Record<string, never>> = () => (
  <>
    <Helmet>
      <meta name="robots" content="noindex"></meta>
    </Helmet>
    <SubLayout>
      <SubLayout.Header />
      <SubLayout.Heading>
        Studyplus for School
        <br />
        Personal Information Protection Policy / Privacy Policy
      </SubLayout.Heading>
      <SubLayout.Main>
        <EnglishPrivacyPolicy />
      </SubLayout.Main>
      <SubLayout.Footer />
    </SubLayout>
  </>
);

export default EnglishPrivacyPolicyPage;
