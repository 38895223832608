import * as React from "react";
import { RouterProps } from "react-router";

import { useEffect } from "react";
import Loader from "../../../components/atoms/Loader";
import {
  OutletContextProps,
  WithRouterProps,
  withRouter,
} from "../../../helpers/RouterHelper";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { useQueryError } from "../../../hooks/http/useQueryError";
import SectionInterface from "../../../interfaces/SectionInterface";
import ImportHistory from "./ImportHistory";
import styles from "./styles.scss";
import { useFetchImportHistory } from "./useFetchImportHistory";

interface OwnProps
  extends WithRouterProps<{ sectionId: string; importHistoryId: string }> {
  section: SectionInterface;
}

type Props = OwnProps & RouterProps & OutletContextProps;

const SectionsSettingsImportHistoryPage: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    props.setActiveMenu("import_histories");
  }, []);

  const { data, isLoading, refetch, error } = useFetchImportHistory({
    sectionId: props.params.sectionId,
    importHistoryId: props.params.importHistoryId,
  });
  useQueryError(error);

  if (isLoading || !data) {
    return <Loader loading />;
  }

  return (
    <div className={styles.history}>
      <ImportHistory
        section={props.section}
        importHistory={data}
        reloadImportHistory={refetch}
      />
    </div>
  );
};

const pageInfo = {
  title: "一括操作",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsImportHistoryPage,
  pageInfo,
  ["import_history:index"],
);

export default withRouter<Props>(EnhancedPage);
