import { boronClient } from "../../../../api.ts";
import { UnprocessableEntityError } from "../../../../errors.ts";
import { useBoronMutation } from "../../../../hooks/http/useBoronMutation.ts";
import { useFlashMessage } from "../../../../hooks/useFlashMessage.ts";
import { useFetchAndUpdateUserInfo } from "./useFetchAndUpdateUserInfo.tsx";

type Params = {
  current_password: string;
  password: string;
  password_confirmation: string;
};
export const useUpdateOperatorPassword = () => {
  const { fetchAndUpdateUserInfo } = useFetchAndUpdateUserInfo();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const mutation = useBoronMutation(
    async (params: Params) =>
      await boronClient.PATCH("/api/v1/settings/password", {
        body: {
          operator: params,
        },
      }),
  );

  const updateOperatorPassword = (params: Params) => {
    mutation.mutate(params, {
      onSuccess: async () => {
        // reduxのアカウント情報を更新
        await fetchAndUpdateUserInfo();
        showSuccessMessage(
          "パスワードが更新されました。再度ログインしてください",
        );
      },
      onError: () => {
        showErrorMessage("パスワードを更新できませんでした");
      },
    });
  };

  const apiErrors =
    mutation.error instanceof UnprocessableEntityError
      ? mutation.error.originalErrors
      : [];
  return { ...mutation, updateOperatorPassword, apiErrors };
};
