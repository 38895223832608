import * as React from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import { Location } from "react-router";
import {
  changeStudentAnalyticsAmountFilter,
  changeStudentAnalyticsTimeFilter,
  loadInitialStudentAnalyticsTableAmount,
  loadInitialStudentAnalyticsTableTime,
  loadStudentAnalyticsTableAmount,
  loadStudentAnalyticsTableTime,
} from "../actions/studentAnalyticsTable";
import StudentAnalytics from "../components/organisms/StudentAnalytics";
import FiltersHelper from "../helpers/FiltersHelper";
import {
  OutletContextProps,
  WithRouterProps,
  withRouter,
} from "../helpers/RouterHelper";
import {
  AnalyticsTermType,
  StudentAnalyticsType,
} from "../interfaces/AnalyticsTableInterface";
import AppStateInterface from "../interfaces/AppStateInterface";
import { DateRangeFilterQueryInterface } from "../interfaces/DateRangeFilterInterface";
import {
  FiltersQueryInterface,
  OrderDirFilterType,
} from "../interfaces/FiltersInterface";
import FiltersInterface from "../interfaces/FiltersInterface";
import { StudentAnalyticsTableStateInterface } from "../interfaces/StudentAnalyticsTableInterface";
import StudentInterface from "../interfaces/StudentInterface";
import {
  AuthenticateRouterProps,
  AuthenticatedPageProps,
} from "./enhanceAuthenticatedPage";
import enhanceStudentsPage from "./enhanceStudentsPage";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    Pick<AuthenticatedPageProps, "studentFilterContext">,
    OutletContextProps {
  // NOTE: このHOCを使っているのは古い実装のみ(学習時間の教材別と学習量のページのみ)なので、
  // analyticsTypeに入る値として想定されるのはtimeとamountのみになる
  analyticsType: Extract<StudentAnalyticsType, "time" | "amount">;
  student: StudentInterface;
  analyticsTable: StudentAnalyticsTableStateInterface;
  filters: FiltersInterface[];
  loadStudentAnalyticsTableTime: (studentId: string, page?: number) => void;
  loadStudentAnalyticsTableAmount: (studentId: string, page?: number) => void;
  loadInitialStudentAnalyticsTableTime: HandleThunkActionCreator<
    typeof loadInitialStudentAnalyticsTableTime
  >;
  loadInitialStudentAnalyticsTableAmount: HandleThunkActionCreator<
    typeof loadInitialStudentAnalyticsTableAmount
  >;
  changeStudentAnalyticsTimeFilter: HandleThunkActionCreator<
    typeof changeStudentAnalyticsTimeFilter
  >;
  changeStudentAnalyticsAmountFilter: HandleThunkActionCreator<
    typeof changeStudentAnalyticsAmountFilter
  >;
}

interface DerivedState {
  location: Location;
}

class StudentsAnalyticsPage extends React.Component<Props, DerivedState> {
  static getDerivedStateFromProps(nextProps: Readonly<Props>) {
    return { location: nextProps.location };
  }

  get filter(): FiltersInterface | undefined {
    return this.props.filters.find(
      (filter: FiltersInterface) =>
        filter.sectionId === this.props.student.section.id,
    );
  }
  constructor(props: Props) {
    super(props);
    this.state = { location: props.location };
    this.initializeAnalyticsData();
    props.setActiveMenu("analytics");
  }

  componentDidMount() {
    (window as any).onMainScrollAreaScroll = this.handleScroll;
  }

  componentWillUnmount() {
    window.onMainScrollAreaScroll = undefined;
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location !== this.state.location) {
      this.updateAnalyticsDataByQuery(this.state.location.search.toString());
    }
  }

  render() {
    if (!this.props.student || !this.filter) {
      return null;
    }

    return (
      <StudentAnalytics
        student={this.props.student}
        analyticsTable={this.props.analyticsTable}
        onDateFilterChange={this.handleDateFilterChange}
        onTermChange={this.handleTermChange}
        onOrderChange={this.handleOrderChange}
        analyticsType={this.props.analyticsType}
        navigate={this.props.navigate}
        filter={this.filter}
        hasMoreData={this.hasMoreData()}
      />
    );
  }

  private handleDateFilterChange = (
    dateRangeFilter: DateRangeFilterQueryInterface,
  ) => {
    this.pushFilterHistory({ dateRangeFilter });
  };

  private handleTermChange = (term: AnalyticsTermType) => {
    // termが変更されるときはdateの範囲をリセット
    this.pushFilterHistory({ term, dateRangeFilter: undefined });
  };

  private handleOrderChange = (
    order?: string | null,
    orderDir?: OrderDirFilterType | null,
  ) => {
    this.pushFilterHistory({ orderDir, order });
  };

  private pushFilterHistory(obj: FiltersQueryInterface) {
    const query = FiltersHelper.toQueryString({ ...this.filter, ...obj });
    this.props.navigate({ search: query });
  }

  private loadMore = () => {
    if (!this.hasMoreData()) {
      return;
    }

    if (this.props.analyticsType === "amount") {
      this.props.loadStudentAnalyticsTableAmount(
        this.props.student.id,
        this.props.analyticsTable.meta.currentPage + 1,
      );
    } else {
      this.props.loadStudentAnalyticsTableTime(
        this.props.student.id,
        this.props.analyticsTable.meta.currentPage + 1,
      );
    }
  };

  private handleScroll = () => {
    if (this.props.analyticsTable.loading || !this.hasMoreData()) {
      return;
    }

    this.loadMore();
  };

  private hasMoreData(): boolean {
    return (
      this.props.analyticsTable.meta.currentPage <
      this.props.analyticsTable.meta.totalPages
    );
  }

  private initializeAnalyticsData(): void {
    const studentId = this.props.params.studentId;

    if (this.props.analyticsType === "amount") {
      this.props.loadInitialStudentAnalyticsTableAmount(studentId);
    } else {
      this.props.loadInitialStudentAnalyticsTableTime(studentId);
    }
  }

  private updateAnalyticsDataByQuery(query: string) {
    const studentId = this.props.params.studentId;
    const sectionId = this.props.student.section.id;

    const allTags = FiltersHelper.getAllTags(
      this.props.studentFilterContext.currentFilter,
    );
    if (this.props.analyticsType === "amount") {
      this.props.changeStudentAnalyticsAmountFilter(
        sectionId,
        studentId,
        query,
        allTags,
      );
    } else {
      this.props.changeStudentAnalyticsTimeFilter(
        sectionId,
        studentId,
        query,
        allTags,
      );
    }
  }
}

const mapStateToProps =
  (analyticsType: StudentAnalyticsType) => (state: AppStateInterface) => {
    return {
      analyticsType,
      analyticsTable: state.studentAnalyticsTable,
      filters: state.filters,
    };
  };

const actions = {
  loadStudentAnalyticsTableTime,
  loadStudentAnalyticsTableAmount,
  loadInitialStudentAnalyticsTableTime,
  loadInitialStudentAnalyticsTableAmount,
  changeStudentAnalyticsTimeFilter,
  changeStudentAnalyticsAmountFilter,
};

export default (analyticsType: StudentAnalyticsType) => {
  const pageInfo = {
    title: "アナリティクス",
  };

  const EnhancedStudentsAnalyticsPage = enhanceStudentsPage(
    StudentsAnalyticsPage,
    pageInfo,
  );

  return withRouter<Props>(
    connect(
      mapStateToProps(analyticsType),
      actions,
    )(EnhancedStudentsAnalyticsPage as any),
  );
};
