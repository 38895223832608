import classnames from "classnames";
import * as React from "react";
import Icon from "../../components/atoms/Icon";
import {
  LoadableStudentsMultiSelect,
  OptionType,
} from "../../components/features/LoadableStudentsMultiSelect";
import { resolveColorStyles } from "../../helpers/styles/Colors";
import SectionInterface from "../../interfaces/SectionInterface";
import styles from "./StudentSearchConditionSelector.scss";

type UseStudentSearchConditionSelectorProps = {
  onChange: (values: ReadonlyArray<string> | null) => void;
};

const useStudentSearchConditionSelector = ({
  onChange,
}: UseStudentSearchConditionSelectorProps) => {
  const studentProps = {
    onChange: (students: ReadonlyArray<OptionType> | null | undefined) => {
      if (!students) {
        onChange([]);
        return;
      }

      onChange(
        students.map((student: OptionType) => {
          return student.value;
        }),
      );
    },
  };
  return {
    studentProps,
  };
};

type StudentSearchConditionSelectorProps = {
  section: SectionInterface;
} & UseStudentSearchConditionSelectorProps;

const StudentSearchConditionSelector_ = ({
  section,
  ...props
}: StudentSearchConditionSelectorProps) => {
  const { studentProps } = useStudentSearchConditionSelector(props);
  return (
    <div>
      <div
        className={classnames(
          resolveColorStyles({ color: "primary" }),
          styles.header,
        )}
      >
        <div className={styles.titleViewContainer}>
          <Icon name="icon-students" />
          <div className={styles.title}>生徒</div>
        </div>
      </div>
      <div className={styles.studentSelectContainer}>
        <LoadableStudentsMultiSelect
          {...studentProps}
          placeholder="生徒名を検索"
          size="sm"
          section={section}
          hiddenDropdownIndicator={true}
          indicatorAlignItems="top"
        />
      </div>
    </div>
  );
};

export const StudentSearchConditionSelector = React.memo(
  StudentSearchConditionSelector_,
);
