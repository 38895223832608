import { Text } from "../../../components/general/Text";
import {
  AttandanceStatus,
  LectureAttendance,
} from "../../../domains/LectureAttendance";
import { TransferredAttendanceStatus } from "../../../domains/LectureStudentAnalytics";
import {
  attendanceLabels,
  transferAttendanceLabels,
} from "../../../helpers/AttendanceHelper";
import { Colors } from "../../../helpers/styles/Colors";

type Props = {
  transferredAttendanceStatus: TransferredAttendanceStatus;
} & Pick<LectureAttendance, "attendanceStatus">;

export const AttendanceStatus = ({
  transferredAttendanceStatus,
  attendanceStatus,
}: Props) => {
  const attendanceStatusLabel = attendanceLabels[attendanceStatus];
  const transferredAttendanceStatusLabel =
    transferredAttendanceStatus &&
    transferAttendanceLabels[transferredAttendanceStatus];
  return (
    <>
      <span aria-label={`出席状況:${attendanceStatusLabel}`}>
        <Text as="span" color={statusColors[attendanceStatus]}>
          {attendanceStatusLabel}
        </Text>
      </span>
      {transferredAttendanceStatus !== null && (
        <span
          aria-label={`振替先の出席状況:${transferredAttendanceStatusLabel}`}
        >
          ：
          <Text as="span" color={statusColors[transferredAttendanceStatus]}>
            {transferredAttendanceStatusLabel}
          </Text>
        </span>
      )}
    </>
  );
};

const statusColors: { [k in AttandanceStatus]: Colors } = {
  none: "black",
  presence: "primary",
  absence: "red",
  cancel: "black",
};
