import addDays from "date-fns/addDays";
import addMinutes from "date-fns/addMinutes";
import format from "date-fns/format";
import ja from "date-fns/locale/ja";
import parseISO from "date-fns/parseISO";
import set from "date-fns/set";
import subDays from "date-fns/subDays";

const parse = (date: string | Date): Date => {
  if (date instanceof Date) return date;

  return parseISO(date);
};

const fullFormat = (datetime: string | Date): string => {
  return format(parse(datetime), "yyyy/M/d HH:mm");
};

const defaultFormat = (datetime: string): string => {
  return format(parse(datetime), "M月d日 HH:mm");
};

const japaneseFormatFullDateTime = (datetime: string): string => {
  return format(parse(datetime), "yyyy年MM月dd日 HH:mm");
};

const japaneseFormatFullDate = (datetime: string): string => {
  return format(parse(datetime), "yyyy年M月d日（E）", { locale: ja });
};

export const secondsToTime = (
  sec: number,
): { hours: number; minutes: number } => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);

  return {
    hours,
    minutes,
  };
};

export const createDateFromDateAndTimeString = (
  date: Date,
  timeString: string,
) => {
  const nd = new Date(date.toISOString());
  const convertedTime = timeString.split(":").map(Number);
  nd.setHours(convertedTime[0]);
  nd.setMinutes(convertedTime[1]);
  return nd;
};

/*
 * 日付を 10/1(月) のような表記に変換する
 */
export const formatMonthDay = (date: Date) => {
  return `${format(date, "M/d(E)", { locale: ja })}`;
};

/*
 * 30分単位でキリのいい時間を求める
 * calcCeildTime(new Date("2020-12-10 10:40:00")) -> 11:00の時刻のDate
 * @param source - 計算元の日付
 */
export const calcCeiledTime = (source: Date): Date => {
  const diff = 30 - (source.getMinutes() % 30);
  return addMinutes(source, diff);
};

/*
 * comparedWitheの日付がsourceで指定された日付より前の日付かどうかを返す
 * @param source
 * @param comparedWith
 */
export const isBefore = (source: Date, comparedWith: Date): boolean =>
  source.getTime() > comparedWith.getTime();

/*
 * 引数で指定した日の翌日を返す
 * @param source - 次の日を求めたい日のDateオブジェクト
 * @param extendsTime - sourceから次の日を計算するときに、時間をそのまま継承するか(true)、00:00:00でリセットするか(false)
 */
export const calcNextDay = (source: Date, extendsTime = false): Date => {
  const nextDay = addDays(source, 1);
  if (extendsTime) return nextDay;
  return set(nextDay, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
};

/*
 * 引数で指定した日の前日を返す
 * @param source - 前日を求めたい日のDateオブジェクト
 * @param extendsTime - sourceから次の日を計算するときに、時間をそのまま継承するか(true)、00:00:00でリセットするか(false)
 */
export const calcPrevDay = (source: Date, extendsTime = false): Date => {
  const prevDay = subDays(source, 1);
  if (extendsTime) return prevDay;
  return set(prevDay, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
};

/*
 * 予定登録フォームが日付と時刻で分かれているため
 * Dateと "12:00"という時刻の文字列からDateのインスタンスを作る
 */
export const parseDateTime = (date: Date, time: string): Date => {
  const [hour, minute] = time.split(":").map((e) => Number(e));
  return set(date, { hours: hour, minutes: minute });
};

export const toStringFromDate = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const toDateString = (date: Date, delimiter = "-"): string =>
  format(date, `yyyy${delimiter}M${delimiter}d`);

export const toQueryString = (date: Date): string => format(date, "yyyy-MM-dd");

export const toTimeString = (date: Date): string => format(date, "HH:mm");

export const formatDate = (date: Date): string => {
  return format(date, "yyyy/M/d");
};

export const formatWeek = (date: Date): string => {
  return format(date, "yyyy/M/d週");
};

export const formatMonth = (date: Date): string => {
  return format(date, "yyyy年M月");
};

const secondsToDisplayTime = (
  sec: number,
  { exceptZeroMinute }: { exceptZeroMinute?: boolean } = {},
): string => {
  const { hours, minutes } = secondsToTime(sec);

  if (hours >= 1) {
    if (exceptZeroMinute && minutes === 0) {
      return `${hours}時間`;
    } else {
      return `${hours}時間 ${minutes}分`;
    }
  } else {
    return `${minutes}分`;
  }
};

// 分以下を切り捨てたDateを返す
// ex. 2024-01-01T12:34:56.789 -> 2024-01-01T12:00:00.000
export const truncateMinutes = (date: string | Date): Date => {
  const d = parse(date);
  return set(d, { minutes: 0, seconds: 0, milliseconds: 0 });
};

/* constants */
export const ONEDAY_IN_MSEC = 86400 * 1000;

const TimeHelper = {
  defaultFormat,
  fullFormat,
  secondsToTime,
  secondsToDisplayTime,
  japaneseFormatFullDate,
  japaneseFormatFullDateTime,
};

export default TimeHelper;
