import * as React from "react";
import Loader from "../../../components/atoms/Loader";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { StudyTaskContentRow } from "./StudyTaskContentRow";
import { StudyTaskDetailResponse } from "./useFetchStudyTask";
import { useFetchStudyTaskContents } from "./useFetchStudyTaskContents";

const _StudyTaskContentsDetail = ({
  sectionId,
  studyTask,
}: {
  sectionId: string;
  studyTask: StudyTaskDetailResponse;
}) => {
  const { data, error, isLoading } = useFetchStudyTaskContents({
    sectionId,
    studyTaskId: studyTask.id,
  });
  useQueryError(error);

  if (isLoading || !data) {
    return <Loader loading={isLoading || !data} />;
  }

  return (
    <div
      className="mb-7 mt-4 rounded border border-solid border-gray-400"
      aria-label="課題範囲のコンテンツ一覧"
    >
      <div className="flex items-center rounded-t bg-gray-200 px-6 py-4 text-lg">
        <span>{studyTask.learningMaterial.name}</span>
      </div>
      {data.studyTaskContents.map((studyTaskContent) => {
        return (
          <StudyTaskContentRow
            key={studyTaskContent.drillSectionPublicId}
            studyTaskContent={studyTaskContent}
          />
        );
      })}
    </div>
  );
};

export const StudyTaskContentsDetail = React.memo(_StudyTaskContentsDetail);
