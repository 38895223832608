import { useLayoutEffect, useRef } from "react";
import BotMessageInterface from "../../../interfaces/BotMessageInterface";
import { MessageInterface } from "../../../interfaces/MessageInterface.ts";
import StudentInterface from "../../../interfaces/StudentInterface.ts";
import Loader from "../../atoms/Loader";
import BotMessageItem from "./BotMessageItem";
import MessageItem from "./MessageItem/index";
import styles from "./styles.scss";

interface Props {
  student: Omit<StudentInterface, "billingPlan">;
  messages: MessageInterface[];
  isLoading: boolean;
  loadMore: (studentId: string) => void;
  changeBotMessageStatusDone: (studentId: string, messageId: string) => void;
  changeBotMessageStatusIgnored: (studentId: string, messageId: string) => void;
  deleteMessage: (studentId: string, messageId: string) => void;
}

const MessageList = (props: Props) => {
  const { messages, isLoading } = props;

  const scroller = useRef<HTMLDivElement | null>(null);
  const scrollerHeight = useRef<number>(0);
  const loadedMessages = useRef<Props["messages"] | null>(null);
  useLayoutEffect(() => {
    const initializing = loadedMessages.current === null;

    // 初回メッセージ読み込み時一番下までスクロールする
    if (scroller.current && initializing) {
      scroller.current.scrollTop = scroller.current.scrollHeight;
    }

    if (scroller.current && !initializing) {
      if (
        loadedMessages.current &&
        loadedMessages.current[0] &&
        messages[0] &&
        loadedMessages.current[0].id === messages[0].id
      ) {
        // 追加読込を行った時
        scroller.current.scrollTop =
          scroller.current.scrollHeight - scrollerHeight.current;
      } else {
        // 新しいメッセージを送信した時
        scroller.current.scrollTop = scroller.current.scrollHeight;
      }
    }

    if (scroller.current) {
      scrollerHeight.current = scroller.current.scrollHeight;
    }
    loadedMessages.current = messages;
  }, [messages.length]);

  if (messages.length === 0 && isLoading) {
    return (
      <div className={styles.root}>
        <Loader loading />
      </div>
    );
  }

  return (
    <div className={styles.root} ref={scroller} onScroll={handleScroll(props)}>
      <div className={styles.list} aria-label="メッセージ一覧" role="list">
        {renderList(props)}
        {messages.length !== 0 && isLoading && <Loader loading />}
      </div>
    </div>
  );
};

const renderList = (props: Props) => {
  const { messages, student } = props;

  return messages.map((message) => {
    if ("content" in message) {
      return (
        <MessageItem
          message={message}
          student={student}
          key={`MessageList-MessageListItem-${message.id}`}
          deleteMessage={props.deleteMessage}
        />
      );
    } else if ("skill" in message) {
      return (
        <BotMessageItem
          message={message}
          student={student}
          changeStatusDone={handleChangeBotMessageStatusDone(props, message)}
          changeStatusIgnored={handleChangeBotMessageStatusIgnored(
            props,
            message,
          )}
          key={`MessageList-BotMessageItem-${message.id}`}
        />
      );
    }
  });
};

const handleScroll = (props: Props) => (e: any) => {
  const { student, isLoading } = props;

  if (isLoading) {
    return;
  }

  if (e.target.scrollTop <= 100) {
    props.loadMore(student.id);
  }
};

const handleChangeBotMessageStatusDone =
  (props: Props, botMessage: BotMessageInterface) => () => {
    if (botMessage.status === "done") return;

    props.changeBotMessageStatusDone(props.student.id, botMessage.id);
  };

const handleChangeBotMessageStatusIgnored =
  (props: Props, botMessage: BotMessageInterface) => () => {
    if (botMessage.status === "ignored") return;

    props.changeBotMessageStatusIgnored(props.student.id, botMessage.id);
  };

export default MessageList;
