import { useEffect, useState } from "react";
import * as yup from "yup";
import { useFetchAdminSections } from "../../../hooks/http/useFetchAdminSections";
import { AdminSection } from "../../../interfaces/AdminSection";

type Props = {
  operatorId: string;
};

// NOTE: 最大選択可能校舎数
export const MAX_COUNT_OF_SELECTABLE_SECTIONS = 50;

export const useMultiSectionBatchesAdminSectionsSelectBox = ({
  operatorId,
}: Props) => {
  const { data, isLoading } = useFetchAdminSections(operatorId);

  const [adminSections, setAdminSections] = useState<AdminSection[]>([]);
  useEffect(() => {
    if (data === undefined) return;

    setAdminSections(
      data.sections.map((section) => ({ ...section, checked: false })),
    );
  }, [data]);

  return {
    adminSections,
    isLoading,
  };
};

export const adminSectionsSchema = yup.array().test({
  name: "admin-sections",
  message: "校舎を選択してください",
  test: (value) => {
    if (!value) return false;
    return value.some((section) => section.checked);
  },
});
