import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionTypes as SessionActionTypes } from "../../../actions/common/session/types";
import Loader from "../../../components/atoms/Loader";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import withErrorPages from "../../../hocs/withErrorPages";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { usePutEmailConfirmationToken } from "./usePutEmailConfirmationToken";

type Props = WithRouterProps<{ token: string }>;

const SettingsEmailPage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showSuccessMessage } = useFlashMessage();

  const { mutate, error } = usePutEmailConfirmationToken();
  useQueryError(error);

  useEffect(() => {
    mutate(
      { token: props.params.token },
      {
        onSuccess: async () => {
          dispatch({ type: SessionActionTypes.SIGN_OUT });
          showSuccessMessage(
            "メールアドレスが更新されました。再度ログインしてください",
          );
        },
        onSettled: () => {
          navigate("/login", { replace: true });
        },
      },
    );
  }, []);

  return <Loader loading />;
};

export default withRouter<Props>(withErrorPages(SettingsEmailPage));
