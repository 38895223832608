import * as React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import {
  showErrorFlashMessage,
  showSuccessFlashMessage,
} from "../../../actions/flashMessage";
import { Modal } from "../../../components/atoms/Modal";
import { StudentCalendarHeader } from "../../../components/molecules/StudentCalendarHeader";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import Settings from "../../../helpers/Settings";
import enhancedStudentsPage from "../../../hocs/enhanceStudentsPage";
import { ConfirmFromDragAndDropEdit } from "./ConfirmUpdateTypeModal/ConfirmFromDragAndDropEdit";
import { ConfirmFromForm } from "./ConfirmUpdateTypeModal/ConfirmFromForm";
import { ConfirmToDelete } from "./ConfirmUpdateTypeModal/ConfirmToDelete";
import { ScheduleDetail } from "./Forms/ScheduleDetail";
import { ScheduleForm } from "./Forms/ScheduleForm";
import { StudentCalendarContainer } from "./StudentCalendarContainer";
import styles from "./styles.scss";
import { isOpen } from "./useScheduleModal";
import {
  UseStudentCalendarProps,
  useStudentCalendar,
} from "./useStudentsCalendar";

type Props = UseStudentCalendarProps &
  OutletContextProps & {
    showErrorFlashMessage: (message: string) => void;
  };

const Component = ({
  showSuccessFlashMessage: reduxShowSuccessFlashMessage,
  showErrorFlashMessage: reduxShowErrorFlashMessage,
  ...props
}: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("calendar");
  }, []);
  const {
    calendarStartDate,
    calendarEndDate,
    calendarType,
    modalMode,
    openDetailModal,
    openUpdateConfirmFromDragAndDrop,
    openUpdateConfirmFromForm,
    closeModal,
    openAddEditModal,
    onMutateSuccess,
    onMutateError,
    onDelete,
  } = useStudentCalendar({
    showSuccessFlashMessage: reduxShowSuccessFlashMessage,
    showErrorFlashMessage: reduxShowErrorFlashMessage,
    student: props.student,
  });

  // TODO: 開始日、終了日、studentあたりがバケツリレーっぽくなってるので、contextに保存するようにリファクタリングする
  return (
    <>
      <Helmet>
        <title>
          {props.student.fullName}のカレンダー | {Settings.SITE_TITLE}
        </title>
      </Helmet>
      <div className={styles.main}>
        <div className={styles.daterange}>
          <StudentCalendarHeader
            calendarType={calendarType}
            startDate={calendarStartDate}
            onClickAddEvent={openAddEditModal}
          />
        </div>
        <div className={styles.calendar}>
          <StudentCalendarContainer
            student={props.student}
            startDate={calendarStartDate}
            endDate={calendarEndDate}
            calendarType={calendarType}
            onSelectSchedule={openDetailModal}
            onMutateError={onMutateError}
            onMutateSuccess={onMutateSuccess}
            onSelectNewScheduleFromSlot={openAddEditModal}
            openConfirmModal={openUpdateConfirmFromDragAndDrop}
          />
        </div>
        <Modal isOpen={isOpen(modalMode)} onRequestClose={closeModal}>
          {modalMode.type === "Detail" && (
            <ScheduleDetail
              schedule={modalMode.schedule}
              onClose={closeModal}
              onClickEdit={openAddEditModal}
              onClickDelete={onDelete}
            />
          )}
          {modalMode.type === "Form" && (
            <ScheduleForm
              student={props.student}
              startDate={calendarStartDate}
              endDate={calendarEndDate}
              onClose={closeModal}
              onMutateSuccess={onMutateSuccess}
              onMutateError={onMutateError}
              schedule={modalMode.schedule}
              onMutateWithUpdateTypeConfirm={openUpdateConfirmFromForm}
            />
          )}
          {modalMode.type === "UpdateConfirmFromDragAndDrop" && (
            <ConfirmFromDragAndDropEdit
              onClose={closeModal}
              studentId={props.student.id}
              from={calendarStartDate}
              to={calendarEndDate}
              schedule={modalMode.schedule}
              onMutateSuccess={onMutateSuccess}
              onMutateError={onMutateError}
            />
          )}
          {modalMode.type === "UpdateConfirmFromForm" && (
            <ConfirmFromForm
              onClose={closeModal}
              studentId={props.student.id}
              from={calendarStartDate}
              to={calendarEndDate}
              schedule={modalMode.schedule}
              onMutateSuccess={onMutateSuccess}
              onMutateError={onMutateError}
              onClickGoBackToForm={openAddEditModal}
            />
          )}
          {modalMode.type === "UpdateConfirmToDelete" && (
            <ConfirmToDelete
              onClose={closeModal}
              studentId={props.student.id}
              from={calendarStartDate}
              to={calendarEndDate}
              schedule={modalMode.schedule}
              onMutateSuccess={onMutateSuccess}
              onMutateError={onMutateError}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  showSuccessFlashMessage,
  showErrorFlashMessage,
};

const enhancedStudentsComponent = enhancedStudentsPage(React.memo(Component), {
  title: "カレンダー",
});
export const StudentsCalendar = connect(
  null,
  mapDispatchToProps,
)(enhancedStudentsComponent as any);
