import { parseISO } from "date-fns";
import { FormikProps } from "formik";
import * as React from "react";
import {
  Values,
  defaultValues,
} from "../../components/features/ExaminationResultForm/useExaminationResultForm";

// 新規登録の際は主にユーザーが選択肢を変えたときに、関連するフォームの状態が変わる
export const useNewExaminationResultFormEffect = (
  formik: FormikProps<Values>,
) => {
  // 依存元の選択肢が変更されたら依存先選択肢をリセット
  React.useEffect(() => {
    formik.setFieldValue("classificationId", null);
    formik.setFieldValue("examination", null);
  }, [formik.values.organizerId]);
  React.useEffect(() => {
    formik.setFieldValue("examination", null);
  }, [formik.values.classificationId]);

  // 試験が選択されたらその試験情報を入力フィールドに反映
  React.useEffect(() => {
    const nextValues = {
      ...defaultValues,
      examination: formik.values.examination,
      organizerId: formik.values.organizerId,
      classificationId: formik.values.classificationId,
    };

    if (formik.values.examination) {
      nextValues.examinedOn = formik.values.examination.schedule.startOn
        ? parseISO(formik.values.examination.schedule.startOn)
        : new Date();

      // 配列から、idをキーにしたオブジェクトにしてformの値にセットしている
      // [ { id: xxx, name: yyy }, ... ] => { xxx: { id: xxx, name: yyy }, ... }
      nextValues.subjectResults = Object.fromEntries(
        formik.values.examination.subjects.map((subject) => [
          subject.id,
          {
            id: subject.id,
            name: subject.name,
            point: null,
            deviation: null,
            allocationOfMarks: null,
          },
        ]),
      );
    }

    formik.setValues(nextValues);
  }, [formik.values.examination?.id]);
};
