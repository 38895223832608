import { FlexibleTable } from "../../../components/atoms/Table";
import { SectionExaminationDetail } from "../../../domains/SectionExamination";
import { AnalyticsFilter } from "../../../hooks/filters/useAnalyticsFilter";
import { OrderDirFilterType } from "../../../interfaces/FiltersInterface";
import { displayItemType } from "../useFetchSectionExaminationDetail";
import { TableHeaders } from "./TableHeaders";
import { TableRows } from "./TableRows";
import { useDeviationMap } from "./hooks/useDeviationMap";
import { useExaminationResultsTable } from "./hooks/useExaminationResultsTable";

type ExaminationResultsTableProps = {
  examinationResults: SectionExaminationDetail[];
  displayItem: displayItemType;
  analyticsFilter: AnalyticsFilter;
  onOrderChange: (
    order: string | null,
    initialOrderDir?: OrderDirFilterType,
  ) => void;
};

export const ExaminationResultsTable = ({
  examinationResults,
  analyticsFilter,
  onOrderChange,
  displayItem,
}: ExaminationResultsTableProps) => {
  const { columns, studentResults } =
    useExaminationResultsTable(examinationResults);
  const deviationMap = useDeviationMap({ studentResults, columns });

  return (
    <FlexibleTable className="block w-full overflow-x-auto rounded-t-none border-b-0 border-l-0 border-r-0 bg-gray-100">
      <TableHeaders
        columns={columns}
        analyticsFilter={analyticsFilter}
        onOrderChange={onOrderChange}
      />
      {studentResults.length && deviationMap ? (
        <TableRows
          studentResults={studentResults}
          examinationId={examinationResults[0].examination.id}
          displayItem={displayItem}
          deviationMap={deviationMap}
        />
      ) : (
        <div className="my-7 text-center">
          該当する生徒が見つかりませんでした
        </div>
      )}
    </FlexibleTable>
  );
};
