import { stringify } from "query-string";
import * as React from "react";
import { useNavigate } from "react-router";
import { useStudyScheduleProgressDateParams } from "../../../components/organisms/StudyScheduleProgressHeader";
import { TermType } from "../../../domains/QueryDateRange";
import { StudentTagFilterInterface } from "../../../domains/StudentTag";
import { toQueryObject } from "../../../helpers/FiltersHelper";
import { toQueryString } from "../../../helpers/TimeHelper";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useEffectAvoidFirst } from "../../../hooks/useEffectAvoidFirst";
import { useFilter } from "../../../hooks/useFilter";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import { useQueryString } from "../../../hooks/useQueryString";
import SectionInterface from "../../../interfaces/SectionInterface";
import { useFetchSectionStudySchedules } from "./useSectionStudyScheduleApi";

export type UseSectionStudySchedulesProps = {
  section: SectionInterface;
  studentFilter: StudentTagFilterInterface;
};
export type DateQuery = {
  date: Date;
  type: TermType;
};

export const useSectionStudySchedules = (
  props: UseSectionStudySchedulesProps,
) => {
  const navigate = useNavigate();
  const { filter } = useFilter();
  const paramsFromURL = useQueryString();
  const dateRange = useStudyScheduleProgressDateParams(paramsFromURL);

  const [dateParams, setDateParams] = React.useState<DateQuery>({
    date: dateRange.startDate,
    type: dateRange.type,
  });

  const filterConditionParams = React.useMemo(() => {
    return toQueryObject(
      {
        ...filter,
        tagFilter: props.studentFilter,
      },
      {
        withoutDateRange: true,
      },
    );
  }, [props.studentFilter, filter]);

  const [enabledFetch, setEnabledFetch] = React.useState(false);

  const [hasPlanningParam, setHasPlanningParam] = React.useState<boolean>(
    paramsFromURL.has_planning === "true",
  );

  const onChangeDateParams = React.useCallback((date: Date, type: TermType) => {
    setDateParams({ date, type });
  }, []);

  useEffectAvoidFirst(() => {
    const tagFilterParams = toQueryObject(
      { tagFilter: props.studentFilter },
      { withoutDateRange: true },
    );
    const newUrlQuery = stringify(
      {
        ...tagFilterParams,
        date: toQueryString(dateParams.date),
        type: dateParams.type,
        has_planning: hasPlanningParam,
      },
      { arrayFormat: "bracket" },
    );
    navigate(`.?${newUrlQuery}`);
  }, [dateParams, hasPlanningParam]);

  const fetchState = useFetchSectionStudySchedules({
    section: props.section,
    filterParams: filterConditionParams,
    dateParams: {
      from: dateRange.startDate,
      to: dateRange.endDate,
      term: dateRange.type,
    },
    hasPlanning: hasPlanningParam,
    enabledFetch: enabledFetch,
  });
  useQueryError(fetchState.error);

  useOnMainScrollAreaScroll(() => {
    if (fetchState.isFetching || !fetchState.hasNextPage) {
      return;
    }
    fetchState.fetchNextPage();
  }, [fetchState.isFetching, fetchState.hasNextPage]);

  const onChangeHasPlanning = React.useCallback(() => {
    setHasPlanningParam(paramsFromURL.has_planning === "true" ? false : true);
  }, [paramsFromURL.has_planning]);

  const startFetch = React.useCallback(() => {
    setEnabledFetch(true);
  }, []);

  return {
    fetchState,
    onChangeDateParams,
    onChangeHasPlanning,
    hasPlanningParam,
    dateRange,
    startFetch,
  };
};
