import * as React from "react";
import {
  UseScrollWatcherProps,
  useScrollWatcher,
} from "../../../hooks/useScrollWatcher";
import styles from "./AnnounceMessageRecipientsWrapper.scss";

type Props = Pick<UseScrollWatcherProps, "onReachToBottom">;

/**
 * アナウンスメッセージ共通のdivラッパー
 * メッセージ機能はclass componentsで作られていて、renderメソッド内部から直接hooksが使えないので切り出した
 */

export const AnnounceMessageRecipientsWrapper = ({
  children,
  onReachToBottom,
}: React.PropsWithChildren<Props>) => {
  const onScroll = useScrollWatcher({ onReachToBottom });
  return (
    <div onScroll={onScroll} className={styles.recipients}>
      {children}
    </div>
  );
};
