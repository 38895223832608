import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import OperatorApi from "../api-clients/OperatorApi";
import { FlashMessageType } from "../interfaces/FlashMessageInterface";
import {
  ActiveOperatorInterface,
  OperatorRoleTypes,
} from "../interfaces/OperatorInterface";
import { HTTP_ERROR_MESSAGE } from "../reducers";
import { apiRequestError } from "./apiRequestError";
import { buildMainResourceApiErrorAction } from "./common/errors";
import { setFlashMessage } from "./flashMessage";

const getOperatorsRequest = createAction("GET_OPERATORS_REQUEST");
const getOperatorsSuccess = createAction("GET_OPERATORS_SUCCESS");
const getOperatorsError = createAction("GET_OPERATORS_ERROR");
const getOperatorsInitialSuccess = createAction(
  "GET_OPERATORS_INITIAL_SUCCESS",
);

export const getOperatorsInitial =
  (sectionId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(getOperatorsRequest());

    OperatorApi.getOperators(sectionId, 1)
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getOperatorsInitialSuccess(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getOperatorsError());
        }
      })
      .catch(() => {
        dispatch(getOperatorsError());
      });
  };

export const getOperators =
  (sectionId: string, page: number) =>
  (dispatch: Dispatch): void => {
    dispatch(getOperatorsRequest());

    OperatorApi.getOperators(sectionId, page)
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getOperatorsSuccess(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getOperatorsError());
        }
      })
      .catch(() => {
        dispatch(getOperatorsError());
      });
  };

const inviteOperatorRequest = createAction("INVITE_OPERATOR_REQUEST");
const inviteOperatorSuccess = createAction("INVITE_OPERATOR_SUCCESS");
const inviteOperatorError = createAction("INVITE_OPERATOR_ERROR");
const inviteOperatorDefaultError = { message: "招待を送信できませんでした" };
const inviteOperatorUnknownError = { message: HTTP_ERROR_MESSAGE };
const setInviteOperatorSuccessMessage = (dispatch: Dispatch) => {
  const flashMessage = {
    type: FlashMessageType.SUCCESS,
    message: "招待を送信しました",
  };
  const flashAction = setFlashMessage(flashMessage);
  dispatch(flashAction);
};
export const inviteOperator =
  (sectionId: string, email: string, resetForm: () => void) =>
  (dispatch: Dispatch): void => {
    dispatch(inviteOperatorRequest());

    OperatorApi.invite(sectionId, email)
      .then((res) => {
        if (res.ok) {
          setInviteOperatorSuccessMessage(dispatch);
          resetForm();
          res.json().then((json) => {
            dispatch(inviteOperatorSuccess(json));
          });
        } else if (res.status === 422) {
          res.json().then((json) => {
            dispatch(inviteOperatorError(json));
            const payload = { errors: [inviteOperatorDefaultError] };
            dispatch(apiRequestError(payload));
          });
        } else {
          dispatch(inviteOperatorError());
          const payload = { errors: [inviteOperatorUnknownError] };
          dispatch(apiRequestError(payload));
        }
      })
      .catch(() => {
        dispatch(inviteOperatorError());
      });
  };

const deleteInviteRequest = createAction("DELETE_INVITE_REQUEST");
const deleteInviteSuccess = createAction("DELETE_INVITE_SUCCESS");
const deleteInviteError = createAction("DELETE_INVITE_ERROR");
const deleteInviteDefaultError = {
  message: "招待をキャンセルできませんでした",
};
const setDeleteInviteSuccessMessage = (dispatch: Dispatch) => {
  const flashMessage = {
    type: FlashMessageType.SUCCESS,
    message: "招待をキャンセルしました",
  };
  const flashAction = setFlashMessage(flashMessage);
  dispatch(flashAction);
};

export const deleteInvite =
  (sectionId: string, inviteId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(deleteInviteRequest({ inviteId }));
    OperatorApi.deleteInvite(sectionId, inviteId)
      .then((res) => {
        if (res.ok) {
          setDeleteInviteSuccessMessage(dispatch);
          const payload = { inviteId };
          dispatch(deleteInviteSuccess(payload));
        } else {
          dispatch(deleteInviteError({ inviteId }));
          const payload = { errors: [deleteInviteDefaultError] };
          dispatch(apiRequestError(payload));
        }
      })
      .catch(() => {
        dispatch(deleteInviteError({ inviteId }));
      });
  };

const deleteOperatorRequest = createAction("DELETE_OPERATOR_REQUEST");
const deleteOperatorSuccess = createAction("DELETE_OPERATOR_SUCCESS");
const deleteOperatorError = createAction("DELETE_OPERATOR_ERROR");
const deleteOperatorDefaultErrorMsg = {
  message: "アカウントの権限を停止できませんでした",
};
const setDeleteOperatorSuccessMessage = (dispatch: Dispatch) => {
  const flashMessage = {
    type: FlashMessageType.SUCCESS,
    message: "アカウントの権限を停止しました",
  };
  const flashAction = setFlashMessage(flashMessage);
  dispatch(flashAction);
};

export const deleteOperator =
  (sectionId: string, operatorId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(deleteOperatorRequest());
    OperatorApi.deleteOperator(sectionId, operatorId)
      .then((res) => {
        if (res.ok) {
          setDeleteOperatorSuccessMessage(dispatch);
          const payload = { operatorId };
          dispatch(deleteOperatorSuccess(payload));
        } else {
          dispatch(deleteOperatorError());
          const payload = { errors: [deleteOperatorDefaultErrorMsg] };
          dispatch(apiRequestError(payload));
        }
      })
      .catch(() => {
        dispatch(deleteOperatorError());
      });
  };

const changeOperatorRoleRequest = createAction("CHANGE_OPERATOR_ROLE_REQUEST");
const changeOperatorRoleSuccess = createAction("CHANGE_OPERATOR_ROLE_SUCCESS");
const changeOperatorRoleError = createAction("CHANGE_OPERATOR_ROLE_ERROR");
const changeOperatorDefaultErrorMsg = {
  message: "アカウントの権限を変更できませんでした",
};
const setChangeOperatorRoleSuccessMsg = (dispatch: Dispatch) => {
  const flashMessage = {
    type: FlashMessageType.SUCCESS,
    message: "アカウントの権限を変更しました",
  };
  const flashAction = setFlashMessage(flashMessage);
  dispatch(flashAction);
};

export const changeOperatorRole =
  (sectionId: string, operatorId: string, role: OperatorRoleTypes) =>
  (dispatch: Dispatch): void => {
    dispatch(changeOperatorRoleRequest({ operatorId }));

    OperatorApi.changeOperatorRole(sectionId, operatorId, role)
      .then((res) => {
        if (res.ok) {
          setChangeOperatorRoleSuccessMsg(dispatch);
          res
            .json()
            .then((json: { activeOperator: ActiveOperatorInterface }) => {
              dispatch(changeOperatorRoleSuccess(json));
            });
        } else {
          dispatch(changeOperatorRoleError({ operatorId }));
          const payload = { errors: [changeOperatorDefaultErrorMsg] };
          dispatch(apiRequestError(payload));
        }
      })
      .catch(() => {
        dispatch(changeOperatorRoleError());
      });
  };

const updateOperatorSectionTagsRequest = createAction(
  "UPDATE_OPERATOR_SECTION_TAGS_REQUEST",
);
const updateOperatorSectionTagsSuccess = createAction(
  "UPDATE_OPERATOR_SECTION_TAGS_SUCCESS",
);
const updateOperatorSectionTagsError = createAction(
  "UPDATE_OPERATOR_SECTION_TAGS_ERROR",
);
const updateOperatorSectionTagsDefaultErrorMsg = {
  message: "アカウントの権限を変更できませんでした",
};
const setUpdateOperatorSectionTagsSuccessMsg = (dispatch: Dispatch) => {
  const flashMessage = {
    type: FlashMessageType.SUCCESS,
    message: "アカウントの権限を変更しました",
  };
  const flashAction = setFlashMessage(flashMessage);
  dispatch(flashAction);
};
export const updateOperatorSectionTags =
  (sectionId: string, operatorId: string, tagIds: string[]) =>
  (dispatch: Dispatch): void => {
    dispatch(updateOperatorSectionTagsRequest({ operatorId }));

    OperatorApi.updateOperatorSectionTags(sectionId, operatorId, tagIds)
      .then((res) => {
        if (res.ok) {
          setUpdateOperatorSectionTagsSuccessMsg(dispatch);
          res
            .json()
            .then((json: { activeOperator: ActiveOperatorInterface }) => {
              dispatch(updateOperatorSectionTagsSuccess(json));
            });
        } else {
          dispatch(updateOperatorSectionTagsError({ operatorId }));
          const payload = {
            errors: [updateOperatorSectionTagsDefaultErrorMsg],
          };
          dispatch(apiRequestError(payload));
        }
      })
      .catch(() => {
        dispatch(updateOperatorSectionTagsError({ operatorId }));
      });
  };
