import Loader from "../../../../components/atoms/Loader";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import { DrillLearningMaterialValue } from "../../useStudyTaskForm";
import { DrillSection } from "../DrillLearningMaterialSelectionForm";
import { CheckboxRow } from "./CheckboxRow";
import { CheckboxWithLabel } from "./CheckboxWithLabel";
import { DrillSectionCheckboxRow } from "./DrillSectionCheckboxRow";
import { useFetchDrillWithContents } from "./useFetchDrillWithContents";

type Props = {
  sectionId: string;
  temporaryDrill: DrillLearningMaterialValue;
  setTemporaryDrill: (drill: DrillLearningMaterialValue) => void;
  selectedDrill: DrillLearningMaterialValue | null;
  setSelectedDrill: (drill: DrillLearningMaterialValue) => void;
  closeContentSelector: () => void;
  isLoadingSelectedDrill: boolean;
};

const useContentSelector = ({
  sectionId,
  temporaryDrill,
  setTemporaryDrill,
  selectedDrill,
  setSelectedDrill,
}: Omit<Props, "closeContentSelector" | "isLoadingSelectedDrill">) => {
  const { data, isLoading, error } = useFetchDrillWithContents({
    sectionId,
    drillMaterialCode: temporaryDrill.learningMaterialCode || "",
  });
  useQueryError(error);

  const isSelectedDrill = temporaryDrill.publicId === selectedDrill?.publicId;

  const drillSections: DrillSection[] = data?.sections ?? [];
  const temporaryContentIds = temporaryDrill.sections.flatMap(
    (s) => s.contents?.map((c) => c.id) || [],
  );

  const setTemporarySectionsUnique = (sections: DrillSection[]) => {
    const uniqueSections = new Map<string, DrillSection>();
    sections.forEach((section) => {
      const uniqueContents = Array.from(
        new Map<string, Required<DrillSection>["contents"][0]>(
          (section.contents || []).map((content) => [content.id, content]),
        ).values(),
      );
      if (uniqueContents.length === 0) return;

      uniqueSections.set(section.id, {
        ...section,
        contents: uniqueContents,
      });
    });
    setTemporaryDrill({
      ...temporaryDrill,
      sections: Array.from(uniqueSections.values()),
    });
    setSelectedDrill({
      ...temporaryDrill,
      sections: Array.from(uniqueSections.values()),
    });
  };

  const selectDrill = () => {
    if (selectedDrill != null && !isSelectedDrill) {
      alert("すでに教材が追加されています");
      return;
    }
    setSelectedDrill(temporaryDrill);
  };
  return {
    isLoading,
    isSelectedDrill,
    drillSections,
    temporaryContentIds,
    setTemporarySectionsUnique,
    selectDrill,
  };
};

export const ContentSelector = ({
  sectionId,
  temporaryDrill,
  setTemporaryDrill,
  selectedDrill,
  setSelectedDrill,
  closeContentSelector,
  isLoadingSelectedDrill,
}: Props) => {
  const {
    isLoading,
    isSelectedDrill,
    drillSections,
    temporaryContentIds,
    setTemporarySectionsUnique,
  } = useContentSelector({
    sectionId,
    temporaryDrill,
    selectedDrill,
    setTemporaryDrill,
    setSelectedDrill,
  });

  const checkedAllContents = Boolean(
    drillSections?.every((section) =>
      section.contents?.every((content) =>
        temporaryContentIds?.includes(content.id),
      ),
    ),
  );
  const indeterminateAllContents =
    !checkedAllContents &&
    Boolean(
      drillSections?.some((section) =>
        section.contents?.some((content) =>
          temporaryContentIds?.includes(content.id),
        ),
      ),
    );

  return (
    <div className="flex h-full flex-col flex-nowrap justify-start">
      <div className="text-md font-bold leading-5 text-black">
        <button
          className="p-0 text-md font-bold leading-5 text-black underline disabled:cursor-default disabled:no-underline"
          onClick={closeContentSelector}
          disabled={isSelectedDrill}
        >
          デジタル教材
        </button>
        <span className="mx-1">/</span>
        <span>{temporaryDrill.name}</span>
      </div>
      <div
        role="table"
        className="mt-4 box-border flex w-full flex-shrink flex-grow-0 flex-col flex-nowrap justify-start divide-x-0 divide-y divide-solid divide-gray-400 overflow-y-scroll rounded border border-solid border-gray-400"
      >
        <div className="flex flex-nowrap items-center justify-between py-6 pl-6 pr-7">
          <div className="flex flex-nowrap items-center gap-4">
            <div className="h-[4.5rem] w-[4.5rem] flex-shrink-0 flex-grow-0">
              {temporaryDrill.imageUrl && (
                <img
                  src={temporaryDrill.imageUrl}
                  alt={temporaryDrill.name}
                  className="h-full w-full object-contain"
                />
              )}
            </div>
            <div className="flex-shrink flex-grow">
              <div className="text-black">{temporaryDrill.name}</div>
              <div className="pt-4 text-sm text-gray-900">
                {temporaryDrill.creatorName}
              </div>
            </div>
          </div>
        </div>
        <Loader loading={isLoading || isLoadingSelectedDrill} />
        {!isLoading &&
          !isLoadingSelectedDrill &&
          drillSections?.length !== 0 && (
            <CheckboxRow>
              <CheckboxWithLabel
                id="check-all-contents"
                checked={checkedAllContents}
                indeterminate={indeterminateAllContents}
                onCheck={() => setTemporarySectionsUnique(drillSections ?? [])}
                onUncheck={() => setTemporarySectionsUnique([])}
              >
                すべて選択
              </CheckboxWithLabel>
            </CheckboxRow>
          )}
        {!isLoading &&
          !isLoadingSelectedDrill &&
          drillSections.map((drillSection) => (
            <DrillSectionCheckboxRow
              key={drillSection.id}
              drillSection={drillSection}
              temporaryDrill={temporaryDrill}
              temporaryContentIds={temporaryContentIds}
              setTemporarySectionsUnique={setTemporarySectionsUnique}
            />
          ))}
      </div>
    </div>
  );
};
