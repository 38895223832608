import * as React from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/atoms/Icon";
import LinkButton from "../../components/atoms/LinkButton";
import Loader from "../../components/atoms/Loader";
import TabsNavi from "../../components/molecules/TabsNavi";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import enhanceSectionPage from "../../hocs/enhanceSectionsPage";
import { SectionsSettingsPageProps } from "../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../interfaces/SectionInterface";
import { Menu } from "./Menu";
import {
  ClassroomTitlteView,
  SearchConditionSelector,
  TeacherTitleView,
} from "./SearchConditionSelector";
import { SearchScheduleList } from "./SearchScheduleList";
import { StudentSearchConditionSelector } from "./StudentSearchConditiionSelector";
import styles from "./styles.scss";
import { useSectionSettingsSectionScheduleListPage } from "./useSectionSettingsSectionScheduleListPage";

type Props = OutletContextProps & {
  section: SectionInterface;
} & SectionsSettingsPageProps;

const SectionsSettingsSectionScheduleListPage_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("schedule_list");
  }, []);
  const section = props.section;
  const {
    teacherConditionSelectorProps,
    classroomConditionSelectorProps,
    studentConditionSelectorProps,
    searchConditionLoaderProps,
    searchConditionReady,
    searchScheduleListProps,
  } = useSectionSettingsSectionScheduleListPage({ section });

  return (
    <>
      <div className={styles.root}>
        <div>
          <TabsNavi
            tabs={[
              {
                label: "カレンダー",
                link: "#",
                active: true,
              },
            ]}
          />
        </div>
        <div className={styles.menuWrapper}>
          <Menu />
        </div>

        <div className={styles.scheduleListWrapper}>
          <div className={styles.resourceConditionSelector}>
            <Loader {...searchConditionLoaderProps} />
            {searchConditionReady && (
              <>
                <StudentSearchConditionSelector
                  {...studentConditionSelectorProps}
                  section={section}
                />
                <SearchConditionSelector
                  {...classroomConditionSelectorProps}
                  titleView={<ClassroomTitlteView />}
                  linkViewToAddRecord={
                    <Link
                      to={`/sections/${section.id}/settings/classrooms/new`}
                    >
                      <AddLinkButton />
                    </Link>
                  }
                  keyId="classroom"
                />
                <SearchConditionSelector
                  {...teacherConditionSelectorProps}
                  titleView={<TeacherTitleView />}
                  linkViewToAddRecord={
                    <Link to={`/sections/${section.id}/settings/teachers/new`}>
                      <AddLinkButton />
                    </Link>
                  }
                  keyId="teacher"
                />
              </>
            )}
          </div>
          {searchConditionReady ? (
            <SearchScheduleList {...searchScheduleListProps} />
          ) : (
            <div className={styles.searchResultLoaderWrapper}>
              <Loader loading />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const AddLinkButton = () => (
  <LinkButton color="primary">
    <Icon name="icon-plus-tag" />
  </LinkButton>
);

const pageInfo = {
  title: "校舎予定リスト",
};
export const SectionsSettingsSectionScheduleListPage = withRouter<Props>(
  enhanceSectionPage(SectionsSettingsSectionScheduleListPage_, pageInfo),
);
