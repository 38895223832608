import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import AnnounceMessageApi from "../../../api-clients/AnnounceMessageApi";
import FiltersHelper from "../../../helpers/FiltersHelper";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { buildMainResourceApiErrorAction } from "../../common/errors";
import { openAnnounceDestinationTag } from "./common";

// 宛先タグ一覧 初期読み込み
export const loadInitialSectionAnnounceDestinationTags =
  (
    sectionId: string,
    options?: {
      selectInitialTag?: boolean;
      defaultTagId?: string;
    },
  ) =>
  (dispatch: any) => {
    dispatch(getInitialAnnounceDestinationTagsRequestSending());

    AnnounceMessageApi.getAnnounceDestinationTags(sectionId)
      .then((res: any) => {
        res.json().then((json: any) => {
          if (res.ok) {
            dispatch(getAnnounceDestinationTagsResponseReceived(json));

            const { data } = json.allTags;

            if (!data || data.length === 0) {
              return;
            }
            if (!options) {
              return;
            }

            // 先頭のタグを開くかどうかのオプションを見る
            const { selectInitialTag } = options;
            if (selectInitialTag) {
              const tag = data[0];
              dispatch(openAnnounceDestinationTag(sectionId, tag));
            }
          } else {
            dispatch(buildMainResourceApiErrorAction(res.status));
            dispatch(getAnnounceDestinationTagsResponseError());
          }
        });
      })
      .catch(() => {
        dispatch(getAnnounceDestinationTagsResponseError());
        dispatch(buildMainResourceApiErrorAction());
      });
  };

// 追加読み込み
export const loadSectionAnnounceDestinationTags =
  (sectionId: string, page?: number) =>
  (dispatch: Dispatch, getState: () => AppStateInterface) => {
    const query = FiltersHelper.stateToFiltersQueryString(getState(), { page });

    dispatch(getAnnounceDestinationTagsRequestSending());

    AnnounceMessageApi.getAnnounceDestinationTags(sectionId, { query })
      .then((res: any) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(getAnnounceDestinationTagsResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getAnnounceDestinationTagsResponseError());
        }
      })
      .catch(() => {
        dispatch(getAnnounceDestinationTagsResponseError());
      });
  };

const getAnnounceDestinationTagsRequestSending = createAction(
  "GET_SECTION_ANNOUNCE_DESTINATION_TAGS_REQUEST_SENDING",
);
const getInitialAnnounceDestinationTagsRequestSending = createAction(
  "GET_INITIAL_SECTION_ANNOUNCE_DESTINATION_TAGS_REQUEST_SENDING",
);
const getAnnounceDestinationTagsResponseReceived = createAction(
  "GET_SECTION_ANNOUNCE_DESTINATION_TAGS_RESPONSE_RECEIVED",
);
const getAnnounceDestinationTagsResponseError = createAction(
  "GET_SECTION_ANNOUNCE_DESTINATION_TAGS_RESPONSE_ERROR",
);
