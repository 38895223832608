import * as React from "react";
import { Link } from "react-router-dom";
import { BlockRow } from "../../components/atoms/BlockRow";
import { Flex } from "../../components/atoms/Flex";
import Loader from "../../components/atoms/Loader";
import TabsNavi from "../../components/molecules/TabsNavi";
import { ArchiveStatus } from "../../domains/Attendance";
import { OutletContextProps } from "../../helpers/RouterHelper";
import enhanceSectionsSettingsPage, {
  SectionsSettingsPageProps,
} from "../../hocs/enhanceSectionsSettingsPage";
import { useFetchClassrooms } from "../../hooks/http/useClassroomsApi";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useErrorDispatcher } from "../../hooks/useErrorDispatcher";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import SectionInterface from "../../interfaces/SectionInterface";
import { useArchiveStatusParameter } from "../SectionsSettingsClassroomsPage/useArchiveStatusParameter";
import { ActiveClassroomsTable } from "./ActiveClassroomsTable";
import { ArchivedClassroomsTable } from "./ArchivedClassroomsTable";
import { ClassroomsTableSwitcher } from "./ClassroomsTableSwitcher";
import styles from "./SectionsSettingsClassroomsPage.scss";

type Props = OutletContextProps & {
  section: SectionInterface;
} & SectionsSettingsPageProps;

// Main hooks
const useSectionSettingsClassroomPage = ({ section }: Props) => {
  const archiveStatus = useArchiveStatusParameter();
  const { sendNotFound } = useErrorDispatcher();
  if (archiveStatus === null) {
    sendNotFound();
  }

  const {
    isLoading,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    data: classrooms,
  } = useFetchClassrooms({
    sectionId: section.id,
    archiveStatus: archiveStatus || ArchiveStatus.active,
  });
  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage, fetchNextPage]);

  const classroomsTableProps = {
    sectionId: section.id,
    classrooms,
  };
  return {
    isLoading,
    archiveStatus,
    classroomsTableProps,
  };
};

// Main Component
const SectionsSettingsClassroomsPage_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("classrooms");
  }, []);
  const { isLoading, archiveStatus, classroomsTableProps } =
    useSectionSettingsClassroomPage(props);
  if (archiveStatus === null) return null;
  return (
    <>
      <TabsNavi
        tabs={[
          {
            label: "教室",
            link: `/sections/${props.section.id}/settings/classrooms`,
            active: true,
          },
        ]}
      />
      <Flex
        marginTop="1.0rem"
        justifyContent="space-between"
        alignItems="center"
        wrap
      >
        <ClassroomsTableSwitcher
          sectionId={props.section.id}
          currentStatus={archiveStatus}
        />
        <div className={styles.buttonContainer}>
          <Link
            to={`/sections/${props.section.id}/settings/classrooms/new`}
            className={`buiButtonBase buiButtonVariantFilled buiButtonBlock`}
          >
            教室の登録
          </Link>
        </div>
      </Flex>
      <BlockRow marginTop="1rem">
        {isLoading ? (
          <Loader loading />
        ) : archiveStatus === ArchiveStatus.archived ? (
          <ArchivedClassroomsTable {...classroomsTableProps} />
        ) : (
          <ActiveClassroomsTable {...classroomsTableProps} />
        )}
      </BlockRow>
    </>
  );
};

const pageInfo = {
  title: "教室登録/編集",
};
export const SectionsSettingsClassroomPage = enhanceSectionsSettingsPage(
  SectionsSettingsClassroomsPage_,
  pageInfo,
  ["contents"], // TODO: 出欠用の権限に書き換え
);
