import { stringify } from "query-string";

import createClient from "openapi-fetch";
import Settings from "./helpers/Settings";
import { paths } from "./lib/api/v1";

const boronUrl = Settings.BORON_URL;
const apiUrl = (path: string) => `${boronUrl}${path}`;

export const PER_PAGE = 20;

export type HTTPMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

interface RequestOptions {
  signal?: AbortSignal;
}

export interface GetRequestOptions extends RequestOptions {
  query?: string | { [key: string]: any };
  skipNullQuery?: boolean;
}

interface AbortControllerWrapper {
  path: string;
  abortController: AbortController;
}

const requestInitBase: RequestInit = {
  mode: "cors",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  credentials: "include",
};

const requestInitBaseWithJSONContentType: RequestInit = {
  ...requestInitBase,
  headers: {
    ...requestInitBase.headers,
    "Content-Type": "application/json; charset=utf-8",
  },
};

class ApiClient {
  abortControllerStore: AbortControllerWrapper[];

  constructor() {
    this.abortControllerStore = [];
  }

  get(path: string, options?: GetRequestOptions): Promise<Response> {
    let url = path;

    const requestInit: RequestInit = {
      ...requestInitBaseWithJSONContentType,
      method: "GET",
    };

    if (!options) {
      return fetch(apiUrl(url), requestInit);
    }

    if (options.query) {
      if (typeof options.query === "object") {
        const skipNull = options.skipNullQuery === true;

        url = `${url}?${stringify(options.query, {
          arrayFormat: "bracket",
          skipNull,
        })}`;
      } else if (typeof options.query === "string") {
        url = `${url}${options.query}`;
      }
    }
    if (options.signal) {
      requestInit.signal = options.signal;
    }

    return fetch(apiUrl(url), requestInit);
  }

  post(path: string, body?: Record<string, any>) {
    const requestInit: RequestInit = {
      ...requestInitBaseWithJSONContentType,
      method: "POST",
    };

    if (body) {
      requestInit.body = JSON.stringify(body);
    }

    return fetch(apiUrl(path), requestInit);
  }

  patch(path: string, body?: Record<string, any>) {
    const requestInit: RequestInit = {
      ...requestInitBaseWithJSONContentType,
      method: "PATCH",
    };

    if (body) {
      requestInit.body = JSON.stringify(body);
    }

    return fetch(apiUrl(path), requestInit);
  }

  put(path: string, body?: Record<string, any>) {
    const requestInit: RequestInit = {
      ...requestInitBaseWithJSONContentType,
      method: "PUT",
    };

    if (body) {
      requestInit.body = JSON.stringify(body);
    }

    return fetch(apiUrl(path), requestInit);
  }

  delete(path: string, body?: Record<string, any>) {
    const requestInit: RequestInit = {
      ...requestInitBaseWithJSONContentType,
      method: "DELETE",
    };

    if (body) {
      requestInit.body = JSON.stringify(body);
    }

    return fetch(apiUrl(path), requestInit);
  }

  sendFormData(path: string, method: HTTPMethod, values: any) {
    const formData = this.makeFormData(values);
    const requestInit: RequestInit = {
      ...requestInitBase,
      method,
      body: formData,
    };

    return fetch(apiUrl(path), requestInit);
  }

  interruptGet(path: string, options?: GetRequestOptions) {
    const current = this.abortPreviousRequest(path);
    return this.get(path, {
      ...options,
      signal: current.abortController.signal,
    });
  }

  abortPreviousRequest(path: string): AbortControllerWrapper {
    const previous = this.abortControllerStore.find((a) => a.path === path);

    if (previous) {
      previous.abortController.abort();
      previous.abortController = new AbortController();
      return previous;
    } else {
      const current = {
        path,
        abortController: new AbortController(),
      };

      this.abortControllerStore.push(current);
      return current;
    }
  }

  // Record<string, any> の値をFormDataで送信可能な形に変換する
  // 例:
  // values = {
  //   name: "name value",
  //   file: File,
  //   units: [
  //     {
  //     name: "foo",
  //       contents: [
  //         {
  //           name: "hoge",
  //         }
  //       ]
  //     },
  //   ]
  // }
  // makeFormData(values)
  // -> FormData {
  //      "name": "name value"
  //      "file": File,
  //      "units[0][name]": "foo
  //      "units[0][contents][0][name]": "hoge"
  //    }
  private makeFormData(
    values: Record<string, any>,
    formData_?: FormData,
    parentKey?: string,
  ): FormData {
    return makeFormData(values, formData_, parentKey);
  }
}

export default new ApiClient();

const buildQuery = (query: Record<string, any>): string => {
  return stringify(query, {
    arrayFormat: "bracket",
  });
};

// createClient時にboronClientがwindow.fetchを保持してしまい、テストコードでのfetchに対するspyやmockなどが動作しないため、
// boronClientから常に最新のfetchを参照するようにする
const fetchProxy: typeof fetch = async (...option) => window.fetch(...option);
export const boronClient = createClient<paths>({
  baseUrl: boronUrl,
  credentials: "include",
  querySerializer: buildQuery,
  fetch: fetchProxy,
});

export const makeFormData = (
  values: Record<string, any>,
  formData_?: FormData,
  parentKey?: string,
): FormData => {
  const formData = formData_ || new FormData();

  // 最初の呼び出しは各フィールドに対してkeyをつけてmakeFormDataを呼ぶ
  if (!parentKey) {
    Object.keys(values).forEach((key) => {
      makeFormData(values[key], formData, key);
    });
  }

  // プロパティを掘る必要がないものはそのままappendする
  else if (
    // instance
    values instanceof Date ||
    values instanceof File ||
    values instanceof Blob ||
    // primitive
    typeof values === "string" ||
    typeof values === "number" ||
    typeof values === "boolean" ||
    typeof values === "symbol" ||
    typeof values === "bigint" ||
    typeof values === "undefined"
  ) {
    if (values instanceof Date) {
      formData.append(parentKey, values.toISOString());
    } else {
      formData.append(parentKey, values);
    }
  }
  // Arrayの場合はindexを、
  // オブジェクト({ key: value })の場合はkeyを付け加え、
  // makeFormDataを再帰的に呼び出す
  else if (Array.isArray(values)) {
    values.forEach((v, i) => {
      makeFormData(v, formData, `${parentKey}[${i}]`);
    });
  } else if (typeof values === "object" && values !== null) {
    Object.keys(values).forEach((k) => {
      makeFormData(values[k], formData, `${parentKey}[${k}]`);
    });
  }

  return formData;
};
