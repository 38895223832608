import * as React from "react";
import AlignMiddle from "../../components/atoms/AlignMiddle/index";
import Icon from "../../components/atoms/Icon/index";
import Table from "../../components/atoms/Table";
import { TableColumn } from "../../components/atoms/Table/index";
import {
  ArchiveStatus,
  translateMutateArchiveStatusResult,
} from "../../domains/Attendance";
import { Teacher } from "../../domains/Teacher";
import styles from "./styles.scss";

type Props = {
  children: JSX.Element[];
};

export const TeacherTable = ({ children }: Props) => {
  return (
    <Table
      headers={[{ text: "講師名", centering: true, key: "name" }]}
      rows={children}
    ></Table>
  );
};

type RowProps = React.PropsWithChildren<{
  teacher: Teacher;
}>;
export const TeacherTableRow = ({ children, teacher }: RowProps) => {
  return (
    <tr>
      <TableColumn align="left">
        <AlignMiddle>
          <Icon name="icon-account" className={styles.iconAccount} />
          {teacher.fullName}
        </AlignMiddle>
      </TableColumn>
      <TableColumn align="right" noWrap bold className={styles.lastColumn}>
        {children}
      </TableColumn>
    </tr>
  );
};

export const translateMutateResult = (
  status: ArchiveStatus,
  result: "success" | "failure",
) => `講師を${translateMutateArchiveStatusResult(status, result)}`;
