import {
  ActionTypes,
  Actions,
} from "../../../actions/pages/resetPassword/types";
import { ResetPasswordState } from "../../../interfaces/ResetPasswordState";

const initialState: ResetPasswordState = {
  initialized: false,
  submitting: false,
  isValidToken: false,
  apiErrors: [],
};

const resetPassword = (
  state: ResetPasswordState = initialState,
  action: Actions,
): ResetPasswordState => {
  switch (action.type) {
    case ActionTypes.INITIALIZED:
      return {
        ...state,
        initialized: true,
      };
    case ActionTypes.CHECK_VALIDITY_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case ActionTypes.CHECK_VALIDITY_SUCCESS:
      return {
        ...state,
        submitting: false,
        isValidToken: true,
      };
    case ActionTypes.CHECK_VALIDITY_FAILURE:
      return {
        ...state,
        submitting: false,
        isValidToken: false,
      };
    case ActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case ActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        submitting: false,
        apiErrors: action.payload,
      };
    default:
      return state;
  }
};

export default resetPassword;
