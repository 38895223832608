import * as queryString from "query-string";
import { OperatorRoleTypes } from "../interfaces/OperatorInterface";
import ApiClient from "./../api";

const OperatorApi = {
  getOperators: (sectionId: string, page: number) => {
    const query = `?${queryString.stringify({ page })}`;
    return ApiClient.get(`/api/v1/sections/${sectionId}/operators`, { query });
  },
  invite: (sectionId: string, email: string) => {
    const body = {
      operator: { email },
    };
    return ApiClient.post(
      `/api/v1/sections/${sectionId}/operators/invitation`,
      body,
    );
  },
  deleteInvite: (sectionId: string, inviteId: string) => {
    return ApiClient.delete(
      `/api/v1/sections/${sectionId}/operators/invitation/${inviteId}`,
    );
  },
  deleteOperator: (sectionId: string, operatorId: string) => {
    return ApiClient.delete(
      `/api/v1/sections/${sectionId}/operators/${operatorId}`,
    );
  },
  changeOperatorRole: (
    sectionId: string,
    operatorId: string,
    role: OperatorRoleTypes,
  ) => {
    if (role == "admin") {
      return ApiClient.patch(
        `/api/v1/sections/${sectionId}/operators/${operatorId}/admin`,
      );
    } else {
      return ApiClient.patch(
        `/api/v1/sections/${sectionId}/operators/${operatorId}/member`,
      );
    }
  },
  updateOperatorSectionTags: (
    sectionId: string,
    operatorId: string,
    tagIds: string[],
  ) => {
    const body = { tag_ids: tagIds };
    return ApiClient.patch(
      `/api/v1/sections/${sectionId}/operators/${operatorId}/tags`,
      body,
    );
  },
};

export default OperatorApi;
