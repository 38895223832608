import { boronClient } from "../../../../api";
import { useBoronMutation } from "../../../../hooks/http/useBoronMutation";
import {
  StudentFormValueInterface,
  convertStudentFormValueToParams,
} from "../../../../interfaces/StudentInterface";

export const usePostStudent = ({ sectionId }: { sectionId: string }) =>
  useBoronMutation(
    async (values: StudentFormValueInterface) =>
      await boronClient.POST("/api/v1/sections/{section_id}/students", {
        params: {
          path: {
            section_id: sectionId,
          },
        },
        body: {
          student: convertStudentFormValueToParams(values),
        },
      }),
  );
