import { Button } from "@studyplus/boron-ui";
import { ConfirmUpdateTypeModal } from ".";
import { useRollback } from "../useScheduleApi";
import {
  UseUpdateWithRecurrencesProps,
  useUpdateWithRecurrences,
} from "./useUpdateWithReccurences";

export type Props = UseUpdateWithRecurrencesProps;

export const ConfirmFromDragAndDropEdit = (props: Props) => {
  const state = useUpdateWithRecurrences(props);
  const rollback = useRollback({
    studentId: props.studentId,
    from: props.from,
    to: props.to,
  });
  return (
    <ConfirmUpdateTypeModal
      headerLabel="繰り返し予定の編集"
      onClose={() => {
        rollback();
        props.onClose();
      }}
      buttonsArea={(chosen) => (
        <Button
          disabled={state.isUpdating}
          isLoading={state.isUpdating}
          onClick={() => state.onSubmit(chosen)}
        >
          更新
        </Button>
      )}
    />
  );
};
