import addWeeks from "date-fns/addWeeks";
import format from "date-fns/format";
import set from "date-fns/set";
import { FormikErrors, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { DragDropContextProps, DropResult } from "react-beautiful-dnd";
import * as yup from "yup";
import { Content } from "../../../../domains/Content";
import { ContentCourse } from "../../../../domains/ContentCourse";
import { ContentUnit } from "../../../../domains/ContentUnit";
import { parseDateTime } from "../../../../helpers/TimeHelper";
import { ContentCourseRequestBody } from "../../../../hooks/http/useContentCoursesApi";

export type ContentValue = Content & {
  sequence: number;
};
type ContentValues = { [id: string]: ContentValue };
export type UnitValue = Omit<ContentUnit, "contents"> & {
  contents: ContentValues;
};
type UnitValues = { [id: string]: UnitValue };
type Values = {
  name: string;
  description: string;
  thumbnail: File | null;
  units: UnitValues;
  isSetDuration: boolean;
  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
};
type Props = {
  onSubmit: (values: ContentCourseRequestBody) => void;
  course?: ContentCourse;
};

type ContentsSelectModalState = {
  isOpened: boolean;
  unitId: string | null;
};

export const useContentCourseFormState = ({ onSubmit, course }: Props) => {
  const handleSubmit = useCallback(
    (values: Values) => {
      const params = buildParamsFromValues(values);
      onSubmit(params);
    },
    [onSubmit],
  );

  const [contentsSelectModalState, setContentsSelectModalState] =
    useState<ContentsSelectModalState>({
      isOpened: false,
      unitId: null,
    });

  const initialValues = buildInitialValues(course);
  const form = useFormik<Values>({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validate,
  });

  useEffect(() => {
    form.resetForm({ values: initialValues });
  }, [course]);

  const removeContentFromUnit = (unitId: string, content: ContentValue) => {
    form.setFieldValue(`units.${unitId}.contents.${content.id}`, undefined);
  };

  const addUnit = () => {
    const newUnit = buildInitialUnitValues(
      Object.keys(form.values.units).length + 1,
    );
    form.setFieldValue("units", {
      ...form.values.units,
      [newUnit.id]: newUnit,
    });
  };

  const removeUnit = (id: string) => {
    form.setFieldValue(`units.${id}`, undefined);
  };
  const onDragEnd: DragDropContextProps["onDragEnd"] = (result) => {
    if (result.type === "unit") {
      handleDragUnit(result);
    } else {
      handleDragContent(result);
    }
  };
  const handleDragUnit = (result: DropResult): void => {
    const { source, destination } = result;
    if (!destination || source.droppableId !== destination.droppableId) return;

    // drag and dropしたUnit
    const sourceUnit = form.values.units[result.draggableId];
    if (!sourceUnit) return;

    const units: UnitValue[] = Object.values(form.values.units);
    units.splice(source.index, 1);
    units.splice(destination.index, 0, sourceUnit);
    const newUnits = units.reduce<UnitValues>((obj, unit, index) => {
      obj[unit.id] = {
        ...unit,
        sequence: index + 1,
      };
      return obj;
    }, {});
    form.setFieldValue("units", newUnits);
    form.setFieldTouched("units", true, false);
  };

  const DRAGGABLE_ID_SEPARATOR = "-";
  const contentDraggableIdFor = (
    unit: UnitValue,
    content: ContentValue,
  ): string => {
    return `${unit.id}${DRAGGABLE_ID_SEPARATOR}${content.id}`;
  };
  const handleDragContent = (result: DropResult): void => {
    const { source, destination } = result;
    if (!destination || source.droppableId !== destination.droppableId) return;

    const [unitId, contentId] = result.draggableId.split(
      DRAGGABLE_ID_SEPARATOR,
    );
    const unit = form.values.units[unitId];
    if (!unit) return;

    const sourceContent = unit.contents[contentId];
    if (!sourceContent) return;

    const contents = Object.values(unit.contents);
    contents.splice(source.index, 1);
    contents.splice(destination.index, 0, sourceContent);
    const newContents = contents.reduce<ContentValues>(
      (obj, content, index) => {
        obj[content.id] = {
          ...content,
          sequence: index + 1,
        };
        return obj;
      },
      {},
    );
    form.setFieldValue(`units.${unitId}.contents`, newContents);
    form.setFieldTouched(`units.${unitId}.contents`, true, false);
  };

  const openContentsSelectModal = (unitId: string) => {
    setContentsSelectModalState({
      unitId,
      isOpened: true,
    });
  };

  const closeContentsSelectModal = () => {
    setContentsSelectModalState({
      unitId: null,
      isOpened: false,
    });
  };

  const addContentsToUnit = (unitId: string, contents: Content[]) => {
    // contentsを更新
    const contentValues = buildContentValuesFromContents(contents);
    form.setFieldValue(`units.${unitId}.contents`, contentValues);
    closeContentsSelectModal();
  };

  return {
    ...form,
    addUnit,
    removeUnit,
    addContentsToUnit,
    removeContentFromUnit,
    contentsSelectModalState,
    openContentsSelectModal,
    closeContentsSelectModal,
    onDragEnd,
    contentDraggableIdFor,
  };
};

// initial values
const buildInitialValues = (course?: ContentCourse): Values => {
  if (course) {
    const startAt = course.startAt ?? new Date();
    const endAt = course.endAt ?? addWeeks(new Date(), 1);
    return {
      name: course.name,
      description: course.description || "",
      thumbnail: null,
      units: buildUnitValuesFromUnits(course.units),
      isSetDuration: Boolean(course.startAt && course.endAt),
      startDate: startAt,
      startTime: format(startAt, "HH:mm"),
      endDate: endAt,
      endTime: format(endAt, "HH:mm"),
    };
  } else {
    const today = new Date();
    return {
      name: "",
      description: "",
      thumbnail: null,
      units: {},
      isSetDuration: false,
      startDate: today,
      startTime: format(today, "HH:mm"),
      endDate: addWeeks(today, 1),
      endTime: format(addWeeks(today, 1), "HH:mm"),
    };
  }
};

const buildUnitValuesFromUnits = (
  units: readonly ContentUnit[],
): UnitValues => {
  const values: UnitValues = {};

  units.forEach((unit: ContentUnit, i: number) => {
    values[unit.id] = {
      ...unit,
      contents: buildContentValuesFromContents(unit.contents),
      sequence: i + 1,
    };
  });
  return values;
};
const buildContentValuesFromContents = (contents: Content[]): ContentValues => {
  const contentValues: ContentValues = {};
  contents.forEach((content: Content, i: number) => {
    const sequence = i + 1;
    contentValues[content.id] = { ...content, sequence };
  });
  return contentValues;
};

const buildInitialUnitValues = (sequence: number) => ({
  id: `tmpId_${Date.now()}`,
  name: "",
  description: "",
  sequence,
  contents: {},
});

// validation
const MAX_NAME_LENGTH = 32;
const MAX_DESCRIPTION_LENGTH = 3000;
export const THUMBNAIL_FILETYPES = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];
const MAX_UNIT_NAME_LENGTH = 32;
const MAX_UNIT_DESCRIPTION_LENGTH = 3000;
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("コース名の入力は必須です")
    .max(MAX_NAME_LENGTH, `コース名は${MAX_NAME_LENGTH}文字以内にしてください`),
  description: yup
    .string()
    .max(
      MAX_DESCRIPTION_LENGTH,
      `コース説明は${MAX_DESCRIPTION_LENGTH}文字以内にしてください`,
    ),
  thumbnail: yup
    .mixed()
    .test(
      "fileFormat",
      "画像ファイル(jpg,png,gif)を選択してください",
      (f) => f === null || (f && THUMBNAIL_FILETYPES.includes(f.type)),
    ),
});

const validate = (values: Values): FormikErrors<Values> => {
  return { ...validateUnits(values), ...validateDuration(values) };
};

const validateDuration = (values: Values): FormikErrors<Values> => {
  if (!values.isSetDuration) return {};
  const [startHour, startMinute] = values.startTime
    .split(":")
    .map((i) => Number(i));
  const startDateTime = set(values.startDate, {
    hours: startHour,
    minutes: startMinute,
  });
  const [endHour, endMinute] = values.endTime.split(":").map((i) => Number(i));
  const endDateTime = set(values.endDate, {
    hours: endHour,
    minutes: endMinute,
  });
  if (startDateTime < endDateTime) return {};

  return { startTime: "配信期間開始日時は終了日時より後に設定してください" };
};
// unitsは { [id: string]: UnitValue; }な形
// yupで表現できないのでvalidateでバリデーションしている
type UnitErrors = { [id: string]: FormikErrors<UnitValue> };
const validateUnits = (values: Values): FormikErrors<Values> => {
  const errors: UnitErrors = {};
  Object.values(values.units).forEach((unit: UnitValue) => {
    // validate name
    if (!unit.name) {
      errors[unit.id] = { name: `ユニット名の入力は必須です` };
    } else if (unit.name.length > MAX_UNIT_NAME_LENGTH) {
      errors[unit.id] = {
        name: `ユニット名は${MAX_UNIT_NAME_LENGTH}文字以内にしてください`,
      };
    }
    // validate description
    if (unit.description.length > MAX_UNIT_DESCRIPTION_LENGTH) {
      errors[unit.id] = {
        ...errors[unit.id],
        description: `ユニット説明は${MAX_UNIT_DESCRIPTION_LENGTH}文字以内にしてください`,
      };
    }
  });
  return Object.keys(errors).length > 0 ? { units: errors } : {};
};

// build request
const buildParamsFromValues = (values: Values): ContentCourseRequestBody => {
  return {
    name: values.name,
    description: values.description,
    thumbnail: values.thumbnail,
    units: Object.values(values.units).map((unit) => ({
      id: unit.id.startsWith("tmpId_") ? null : unit.id,
      name: unit.name,
      description: unit.description,
      sequence: unit.sequence,
      contents: Object.values(unit.contents).map((content) => ({
        id: content.id,
        sequence: content.sequence,
      })),
    })),
    start_at: values.isSetDuration
      ? parseDateTime(values.startDate, values.startTime)
      : null,
    end_at: values.isSetDuration
      ? parseDateTime(values.endDate, values.endTime)
      : null,
  };
};
