import * as React from "react";
import StudentsTemplate from "../components/templates/StudentsTemplate/index";
import { WithRouterProps, withRouter } from "../helpers/RouterHelper";
import FiltersInterface, {
  StudentFilterInterface,
} from "../interfaces/FiltersInterface";
import enhanceAuthenticatedPage, {
  AuthenticateRouterProps,
} from "./enhanceAuthenticatedPage";
import {
  AuthenticatedPageCallbacks,
  AuthenticatedPageProps,
} from "./enhanceAuthenticatedPage";

export interface StudentsPageProps
  extends WithRouterProps<AuthenticateRouterProps>,
    AuthenticatedPageProps,
    AuthenticatedPageCallbacks {}

interface PageInfoInterface {
  title: string;
}

const enhanceStudentsPage = (
  WrappedComponent: React.ComponentClass<any, any> | React.FC<any>,
  pageInfo: PageInfoInterface,
) => {
  class StudentsPage extends React.Component<
    StudentsPageProps,
    Record<string, never>
  > {
    get studentFilter() {
      return this.props.studentFilterContext.studentFilters.find(
        (studentFilter: StudentFilterInterface) => {
          return (
            !!this.props.currentSection.data &&
            studentFilter.sectionId === this.props.currentSection.data.id
          );
        },
      );
    }

    get filter() {
      return this.props.filters.find((filter: FiltersInterface) => {
        return (
          !!this.props.currentSection.data &&
          filter.sectionId === this.props.currentSection.data.id
        );
      });
    }

    render() {
      if (
        !this.props.student.data ||
        this.props.student.data.id !== this.props.params.studentId ||
        !this.props.currentOperator ||
        !this.props.currentSection.data ||
        !this.props.operatorPrivileges.data ||
        !this.filter
      ) {
        return null;
      }

      const title =
        pageInfo.title === "アナリティクス" && this.props.student.data
          ? `${this.props.student.data.fullName} アナリティクス`
          : pageInfo.title;

      return (
        <React.Fragment>
          <StudentsTemplate pageInfo={{ title }}>
            <WrappedComponent
              {...this.props}
              student={this.props.student.data}
            />
          </StudentsTemplate>
        </React.Fragment>
      );
    }
  }

  return withRouter<StudentsPageProps>(enhanceAuthenticatedPage(StudentsPage));
};

export default enhanceStudentsPage;
