import * as React from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../components/atoms/Loader";
import StudentFilter from "../../components/features/NewStudentFilter";
import { useStudentFilterContext } from "../../components/features/NewStudentFilter/useStudentFilterContext";
import { SectionTimelineTab } from "../../components/features/SectionTimelineTab";
import { TimelineExaminationResult } from "../../domains/ExaminationResult";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import SectionInterface from "../../interfaces/SectionInterface";
import { TimelineType } from "../../interfaces/TimelineInterface";
import { TimelineExaminationItem } from "./TimelineExaminationItem";
import { useFetchSectionTimelineExamination } from "./useFetchSectionTimelineExamination";

type Props = OutletContextProps & { section: SectionInterface };

const SectionsTimelineExaminationPage_ = ({
  section,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("timeline");
  }, []);
  const location = useLocation();

  const { findTagFilter } = useStudentFilterContext();
  const studentFilter = findTagFilter({ sectionId: section.id });

  const {
    data,
    error,
    fetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
  } = useFetchSectionTimelineExamination({
    sectionId: section.id,
    studentFilter,
  });
  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage, fetchNextPage]);

  return (
    <>
      <SectionTimelineTab
        sectionId={section.id}
        timelineType={TimelineType.TIMELINE_EXAMINATION}
        search={location.search}
      />
      <StudentFilter sectionId={section.id} />
      <TimelineExaminationList
        data={data}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
      />
    </>
  );
};

type TimelineExaminationListProps = {
  data: TimelineExaminationResult[] | undefined;
  isLoading: boolean;
  isFetchingNextPage: boolean;
};
const TimelineExaminationList = (props: TimelineExaminationListProps) => {
  const { data, isLoading, isFetchingNextPage } = props;

  if (isLoading) {
    return <Loader loading />;
  }

  if (data === undefined || data.length === 0) {
    return <p className="text-center">成績記録がまだありません</p>;
  }

  return (
    <div className="mx-auto my-8 max-w-[104.6rem]">
      {data.map((item) => (
        <TimelineExaminationItem key={item.id} examinationResult={item} />
      ))}
      {isFetchingNextPage && <Loader loading />}
    </div>
  );
};

const pageInfo = {
  title: "タイムライン",
};
export const SectionsTimelineExaminationPage = withRouter(
  enhanceSectionsPage(SectionsTimelineExaminationPage_, pageInfo),
);
