import * as React from "react";

import { Button, IconSetupDocument } from "@studyplus/boron-ui";
import ContentBox from "../../../../components/atoms/ContentBox";
import Icon from "../../../../components/atoms/Icon";
import {
  EntryGuidesOperations,
  helpUrl,
  renderOperationHuman,
} from "../../../../domains/ImportHistory";
import SectionInterface from "../../../../interfaces/SectionInterface";
import styles from "./styles.scss";

import { useMutation } from "@tanstack/react-query";
import { FormikErrors, useFormik } from "formik";
import { useNavigate } from "react-router";
import ApiClient from "../../../../api";
import TagButton from "../../../../components/features/TagButton";
import StudentTag from "../../../../domains/StudentTag";
import TagEntity from "../../../../entities/TagEntity";
import { useFetchStudentTags } from "../../../../hooks/http/useFetchStudentTags";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { StudentTagModal, useStudentTagModalHandler } from "./StudentTagModal";

interface Props {
  section: SectionInterface;
  operation: (typeof EntryGuidesOperations)[number];
}

const SelectedTag = ({
  tag,
  removeTag,
}: {
  tag?: StudentTag;
  removeTag: (e: any) => void;
}) => {
  if (tag !== undefined) {
    return (
      <TagButton
        label={tag.name}
        iconName="icon-close-x"
        variant="outline"
        colorType={"primary"}
        onClick={removeTag}
      />
    );
  } else {
    return <div />;
  }
};

const filteredTags = (
  tags: StudentTag[],
  operation: (typeof EntryGuidesOperations)[number],
) => {
  return operation === "entry_guides_for_guardians"
    ? tags.filter(
        (tag) =>
          tag.type != "status" || tag.id == "inviting" || tag.id == "active",
      )
    : tags.filter((tag) => tag.type != "status" || tag.id == "inviting");
};

const ImportOperationForEntryGuide = (props: Props) => {
  const form = useForm(props);
  const tagModalHandler = useStudentTagModalHandler();
  const { data } = useFetchStudentTags({
    sectionId: props.section.id,
    enabled: true,
    activeStudent: false,
  });

  const isForEntryGuidesForStudents =
    props.operation === "entry_guides_for_students";
  const tags = filteredTags(data?.tags || [], props.operation);

  return (
    <ContentBox className={styles.root}>
      <h2 className={styles.heading}>
        <IconSetupDocument className="mr-6 h-[3rem] w-[3rem] text-gray-600" />
        {renderOperationHuman(props.operation)}
      </h2>
      <p className={styles.help}>
        操作方法を確認しながら進めてください。
        <a
          href={helpUrl(props.operation)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon name="icon-help" />
          操作方法を見る
        </a>
      </p>
      <div className={styles.operationBox}>
        <div className={styles.boxHeading}>
          <span className={styles.boxHeadingTitle}>
            1. 配布する生徒をタグで選択し、作成ボタンを押す
          </span>
          <span className={styles.notice}>
            {props.operation === "entry_guides_for_guardians"
              ? "※保護者LINEが未連携の生徒のみ出力されます"
              : "※招待中の生徒のみ出力されます"}
          </span>
        </div>
        <div className={form.errors.tag && styles.hasError}>
          <TagButton
            colorType="primary"
            variant="solid"
            label="選択"
            iconName="icon-plus-tag"
            onClick={() => {
              tagModalHandler.openModal();
            }}
          />
        </div>
        {form.errors.tag && (
          <div className={styles.errorMessage}>{form.errors.tag}</div>
        )}
        <div className="mt-8">
          <SelectedTag
            tag={form.values.tag}
            removeTag={() => {
              form.resetForm();
              tagModalHandler.removeTag();
              tagModalHandler.closeStudentsList();
            }}
          />
          <form onSubmit={form.handleSubmit} className="mt-16">
            <Button
              className={styles.submitButton}
              type="submit"
              isLoading={form.isSubmitting}
            >
              作成
            </Button>
          </form>
        </div>
        <StudentTagModal
          sectionId={props.section.id}
          tags={tags}
          onSubmit={(selectedTag) => {
            form.handleChangeTag(selectedTag);
            tagModalHandler.closeModal();
          }}
          tagModalHandler={tagModalHandler}
          isForEntryGuidesForStudents={isForEntryGuidesForStudents}
        />
      </div>
    </ContentBox>
  );
};

const usePostImportHistory = (
  sectionId: string,
  operation: (typeof EntryGuidesOperations)[number],
) => {
  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  return useMutation({
    mutationFn: async (tag: TagEntity) => {
      const params: Record<string, any> = tag.toQueryParams();
      params["operation"] = operation;
      const response = await ApiClient.post(
        `/api/v1/sections/${sectionId}/import_histories_for_entry_guide`,
        params,
      );
      if (response.ok) {
        return;
      }
      throw "一括操作の処理依頼に失敗しました。";
    },
    onSuccess: () => {
      showSuccessMessage("作成しました。");
      navigate(`/sections/${sectionId}/settings/import_histories`);
    },
    onError: (error: string) => {
      showErrorMessage(error);
    },
  });
};

type Values = {
  tag?: StudentTag;
};

const validate = (values: Values): FormikErrors<Record<string, string>> => {
  const errors: FormikErrors<Record<string, string>> = {};
  if (!values.tag) {
    errors.tag = "配布する生徒タグを選択してください";
  }

  return errors;
};

const useForm = (props: Props) => {
  const initialValues = {
    tag: undefined,
  };
  const { mutate } = usePostImportHistory(props.section.id, props.operation);

  const form = useFormik<Values>({
    initialValues,
    validate,
    onSubmit: (values: Values) => {
      if (values.tag) {
        const tagEntity = new TagEntity(values.tag);
        mutate(tagEntity);
      }
    },
    enableReinitialize: true,
  });

  const handleChangeTag = (tag: StudentTag | undefined) => {
    form.setFieldValue("tag", tag);
  };
  React.useEffect(() => form.resetForm, []);

  return {
    ...form,
    handleChangeTag,
  };
};

export default ImportOperationForEntryGuide;
