import * as React from "react";

import * as queryString from "query-string";
import StudentTag, {
  StudentTagFilterInterface,
} from "../../../domains/StudentTag";
import FiltersHelper, {
  StudentFilterParams,
  buildStudentFilterParams,
} from "../../../helpers/FiltersHelper";
import { createContext } from "../../../helpers/React";
import StatusTagHelper from "../../../helpers/StatusTagHelper";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { usePushHistory } from "../../../hooks/usePushHistory";
import {
  FiltersQueryInterface,
  StudentFilterInterface,
} from "../../../interfaces/FiltersInterface";

const { useContext, Provider } =
  createContext<ReturnType<typeof useStudentFilter>>();

export const useStudentFilterContext = useContext;

// StudentFilterに関わるグローバルなステートを管理するhooks
const useStudentFilter = () => {
  const [filterKey, setFilterKey] = React.useState<string | null>(null);
  const [currentFilter, setCurrentFilter] =
    React.useState<StudentTagFilterInterface>(defaultTagFilter);
  const [isTagLoaded, setIsTagLoaded] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const localStorage =
    useLocalStorage<StudentFilterInterface[]>("studentFilters");
  const [studentFilters, setStudentFilters] = React.useState<
    StudentFilterInterface[]
  >([]);
  // URLパラメータから作ったフィルターのstate
  // NOTE: 各々の画面(タイムラインやアナリティクス)で管理する日付やソートの条件のstateをこのfiltersQueryから復元できるようにするため
  const filtersQuery = React.useRef<FiltersQueryInterface>();

  // LocalStorageからの復元
  React.useEffect(() => {
    // 選択肢としてのタグ(tagsなど)を復元したいため、URLパラメータの有無に関わらず復元する
    // URLパラメータがあれば選択済みタグをURLパラメータから復元する(ここでは復元していない)
    const studentFiltersFromStorage = localStorage.get();
    if (studentFiltersFromStorage) {
      setStudentFilters(studentFiltersFromStorage);
    }
  }, []);

  // LocalStorageへの保存
  React.useEffect(() => {
    if (!isInitialized) return;

    if (localStorage) {
      try {
        localStorage.set(studentFilters);
      } catch {
        // NOTE: 以下のエラーが発生して真っ白な画面になってしまう時があるため対処
        // DOMException: Failed to execute 'setItem' on 'Storage': Setting the value of 'persist:redux' exceeded the quota.
      }
    }
  }, [studentFilters]);

  // 他校舎も含めて管理する全体のstateを更新する
  React.useEffect(() => {
    if (!isInitialized) return;
    if (!filterKey) return;

    if (
      !studentFilters.some(
        (studentFilter) => studentFilter.sectionId === filterKey,
      )
    ) {
      // 新規追加
      setStudentFilters([
        ...studentFilters,
        { sectionId: filterKey, tagFilter: currentFilter },
      ]);
    } else {
      // 更新
      const newStudentFilters = studentFilters.map((studentFilter) => {
        if (studentFilter.sectionId === filterKey) {
          return { sectionId: filterKey, tagFilter: currentFilter };
        }
        return studentFilter;
      });
      setStudentFilters(newStudentFilters);
    }
  }, [currentFilter]);

  // 変更されたKeyに対応するフィルターを適用する
  React.useLayoutEffect(() => {
    setIsInitialized(false);
    setIsTagLoaded(false);
    if (filterKey) {
      setCurrentFilter(findTagFilter({ sectionId: filterKey }));
    }
  }, [filterKey]);

  // URLパラメータからフィルターのステートを更新する
  const updateStudentFilterByQuery = () => {
    if (!filterKey) return;
    if (!isTagLoaded) return;

    if (!isInitialized) {
      const { status, ...otherParams } = getFilterParamsByQuery();
      if (!status) {
        // 初期化時だけ連携状態のURLパラメータがないときはデフォルト値を設定したURLに遷移させる
        pushHistory(
          {
            ...otherParams,
            status: StatusTagHelper.defaultTags.map((tag) => tag.id),
          } as StudentFilterParams,
          { replace: true },
        );
        return;
      }
    }

    const allTags = [...currentFilter.tags, ...currentFilter.systemTags];
    const newFiltersQuery = FiltersHelper.queryToFilters(
      location.search,
      allTags,
    );

    if (newFiltersQuery.tagFilter) {
      setCurrentFilter(newFiltersQuery.tagFilter);
    }

    filtersQuery.current = newFiltersQuery;
  };

  // URLパラメータからフィルターを復元して初期化する
  const initializeWithQuery = () => {
    if (isInitialized) return false;
    if (!isTagLoaded) return false;
    if (!filterKey) return false;

    // フィルターに関するURLパラメータがない場合は何もしない
    const { tagIds, smartTag, status, job } = getFilterParamsByQuery();
    if (!tagIds && !smartTag && !status && !job) return false;

    updateStudentFilterByQuery();
    setIsInitialized(true);
    return true;
  };

  const { pushHistory } = usePushHistory<StudentFilterParams>();
  // ローカルストレージからフィルターを復元して初期化する
  const initializeWithStorage = () => {
    if (isInitialized) return;
    if (!isTagLoaded) return;
    if (!filterKey) return;

    const tagFilterParams = getStudentFilterParams();
    if (tagFilterParams) {
      pushHistory(tagFilterParams, { replace: true });
    }
    setIsInitialized(true);
  };

  // studentFiltersには複数校舎のtagFilterが入っているので
  // currentSectionのtagFilterを取り出して利用する
  const findTagFilter = ({
    sectionId,
  }: {
    sectionId: string;
  }): StudentTagFilterInterface => {
    const filter = studentFilters.find(
      (studentFilter: StudentFilterInterface) => {
        return studentFilter.sectionId === sectionId;
      },
    );
    return filter?.tagFilter || defaultTagFilter;
  };

  // 取得したすべての生徒タグを指定した校舎の生徒フィルターを設定する
  const setFetchedAllStudentTags = ({ tags }: { tags: StudentTag[] }) => {
    setCurrentFilter((prev) => {
      return {
        ...prev,
        tags: tags.filter(
          (tag) => tag.type === "tag_ids" || tag.type === "smart_tag",
        ),
        systemTags: tags.filter((tag) => tag.type === "job"),
        initialized: true,
      };
    });
    setIsTagLoaded(true);
  };

  const getStudentFilterParams = () => {
    return buildStudentFilterParams(currentFilter);
  };

  // フィルターに関するURLパラメータを取得する
  // NOTE: 教材フィルターと共存させた時に、教材フィルターに関するURLパラメータに影響されないようにしたい
  const getFilterParamsByQuery = () => {
    const queryParams = queryString.parse(location.search, {
      arrayFormat: "bracket",
    });
    const tagIds = queryParams.tag_ids;
    const smartTag = queryParams.smart_tag;
    const status = queryParams.status;
    const job = queryParams.job;

    return {
      tagIds,
      smartTag,
      status,
      job,
    };
  };

  return {
    studentFilters,
    findTagFilter,
    filtersQuery,
    setFetchedAllStudentTags,
    updateStudentFilterByQuery,
    getStudentFilterParams,
    getFilterParamsByQuery,
    initializeWithQuery,
    initializeWithStorage,
    currentFilter,
    setFilterKey,
    isTagLoaded,
    setIsTagLoaded,
    isInitialized,
    setIsInitialized,
  };
};

export const defaultTagFilter: StudentTagFilterInterface = {
  initialized: false,
  tags: [],
  systemTags: [],
  selectedTags: [],
  selectedStatusTags: StatusTagHelper.defaultTags,
};

export const StudentFilterProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <Provider value={useStudentFilter()}>{children}</Provider>;
};
