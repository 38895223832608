import { IconPlusTag } from "@studyplus/boron-ui";
import * as React from "react";
import ErrorText from "../../components/atoms/ErrorText";
import LinkButton from "../../components/atoms/LinkButton";
import {
  LoadableStudentsMultiSelect,
  OptionType,
} from "../../components/features/LoadableStudentsMultiSelect";
import { Text } from "../../components/general/Text";
import SectionInterface from "../../interfaces/SectionInterface";
import { SectionBody, SectionContainer, SectionTitle } from "./Section";
import { StudentTagModal, useStudentTagModal } from "./StudentTagModal";
import { useStudyTaskForm } from "./useStudyTaskForm";

type Props = {
  section: SectionInterface;
  form: ReturnType<typeof useStudyTaskForm>;
};

export const StudentAddArea = ({ section, form }: Props) => {
  const studentTagModalState = useStudentTagModal();
  const onChangeSelectedStudents = React.useCallback(
    (students: ReadonlyArray<OptionType> | null | undefined) => {
      form.setFieldValue("students", students);
    },
    [],
  );

  return (
    <>
      <SectionContainer>
        <SectionTitle>
          <span className="flex h-[1rem] w-[1rem] items-center justify-center rounded-full bg-blue-400 p-1 text-sm text-white">
            1
          </span>
          <div className="ml-4">配信先</div>
        </SectionTitle>
        <SectionBody>
          <div>
            <span>配信先対象生徒の選択</span>
            <span className="ml-2">
              ※
              <a
                href="https://fs-help.studyplus.co.jp/ja/articles/9153866"
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                プロプラン
              </a>
              の生徒のみ登録できます
            </span>
          </div>
          <div className="mt-3">
            <LoadableStudentsMultiSelect
              fontSize="md"
              value={form.values.students}
              section={section}
              filterOptions={{ billing_plan: "pro" }}
              placeholder="対象者を選択"
              onChange={onChangeSelectedStudents}
              hasError={form.touched.students && Boolean(form.errors.students)}
            />
            {form.touched.students && Boolean(form.errors.students) && (
              <ErrorText>{form.errors.students}</ErrorText>
            )}
          </div>
          <div className="mt-2 flex">
            <LinkButton onClick={studentTagModalState.openModal}>
              <span className="flex items-center p-0 hover:opacity-60">
                <IconPlusTag className="text-blue-400" />
                <Text color="primary" bold>
                  生徒タグを使って選択
                </Text>
              </span>
            </LinkButton>
          </div>
        </SectionBody>
      </SectionContainer>
      <StudentTagModal
        onChange={onChangeSelectedStudents}
        selectedStudents={form.values.students}
        {...studentTagModalState}
      />
    </>
  );
};
