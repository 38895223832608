import { Link } from "react-router-dom";
import styles from "./styles.scss";

interface Props {
  path?: string;
  label: string;
  onClick?: (e: any) => void;
}

const HeaderMenuFooter = (props: Props) => {
  if (props.path) {
    return (
      <Link to={props.path} className={styles.root}>
        {props.label}
      </Link>
    );
  } else {
    return (
      <a onClick={props.onClick} className={styles.root}>
        {props.label}
      </a>
    );
  }
};

export default HeaderMenuFooter;
