import { ScreenReaderOnly } from "../../../components/general/ScreenReaderOnly";
import { StayStatusTypes } from "../../../interfaces/StudentInterface";
import Icon from "../../atoms/Icon";
import styles from "./StudentAvatar.scss";

type Props = {
  stayStatus: StayStatusTypes;
};
export const StudentAvatar = ({ stayStatus }: Props) => {
  return (
    <div className={styles.avatarWithEnterExitStatus}>
      <Icon name="icon-navi-avatar" className={styles.iconAvatar} />
      {stayStatus === "entered" ? (
        <div className={styles.badge}>
          <ScreenReaderOnly id="student-stay-status">入室中</ScreenReaderOnly>
        </div>
      ) : (
        <ScreenReaderOnly id="student-stay-status">退室中</ScreenReaderOnly>
      )}
    </div>
  );
};
