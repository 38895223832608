import * as React from "react";
import { useParams } from "react-router";
import Loader from "../../components/atoms/Loader";
import { DrillProgressLegend } from "../../components/features/DrillProgressLegend";
import { SectionAnalyticsTypeTab } from "../../components/features/SectionAnalyticsTypeTab";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbItemLink,
} from "../../components/general/Breadcrumb";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import SectionInterface from "../../interfaces/SectionInterface";
import { StudyTaskDetail } from "./StudyTaskDetail";
import { StudyTaskStudentTable } from "./StudyTaskStudentTable";

type Props = OutletContextProps & { section: SectionInterface };

const SectionsAnalyticsStudyTaskDetailPage_ = ({
  section,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("analytics");
  }, [setActiveMenu]);

  const { studyTaskId = "" } = useParams<{ studyTaskId: string }>();
  const [breadcrumbItemName, setBreadcrumbItemName] = React.useState("");
  const [tableColumnName, setTableColumnName] = React.useState("");

  return (
    <>
      <SectionAnalyticsTypeTab
        sectionId={section.id}
        analyticsType="studyTask"
      />

      <div className="my-5">
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbItemLink
              href={`/sections/${section.id}/analytics/study_task`}
            >
              <div className="mr-4 text-lg">課題進捗</div>
            </BreadcrumbItemLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrent className="max-w-[80%]">
            {!breadcrumbItemName ? (
              <Loader loading={!!breadcrumbItemName} />
            ) : (
              <span className="mx-4 text-lg">{breadcrumbItemName}</span>
            )}
          </BreadcrumbItem>
        </Breadcrumb>
      </div>

      <StudyTaskDetail
        sectionId={section.id}
        studyTaskId={studyTaskId}
        setBreadcrumbItemName={setBreadcrumbItemName}
        setTableColumnName={setTableColumnName}
      />

      <div className="mt-8 flex flex-nowrap justify-end">
        <DrillProgressLegend />
      </div>

      <StudyTaskStudentTable
        sectionId={section.id}
        studyTaskId={studyTaskId}
        learningMaterialName={tableColumnName}
      />
    </>
  );
};

const pageInfo = {
  title: "アナリティクス",
};
export const SectionsAnalyticsStudyTaskDetailPage = withRouter(
  enhanceSectionsPage(SectionsAnalyticsStudyTaskDetailPage_, pageInfo),
);
