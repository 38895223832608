import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import { BlockRow } from "../../../../../components/atoms/BlockRow/index";
import Checkbox from "../../../../../components/atoms/Checkbox/index";
import { Flex } from "../../../../../components/atoms/Flex/index";
import Label from "../../../../../components/atoms/Label";
import Loader from "../../../../../components/atoms/Loader";
import { Modal } from "../../../../../components/atoms/Modal/index";
import Table from "../../../../../components/atoms/Table/index";
import { TableColumn } from "../../../../../components/atoms/Table/index";
import {
  Content,
  formatContentType,
  formatFileSize,
} from "../../../../../domains/Content";
import TimeHelper from "../../../../../helpers/TimeHelper";
import { useScrollWatcher } from "../../../../../hooks/useScrollWatcher";
import LtiDeploymentInterface from "../../../../../interfaces/LtiDeploymentInterface";
import { ContentSearchForm } from "./ContentSearchForm";
import styles from "./styles.scss";
import { useContentsSelectModal } from "./useContentsSelectModal";

type Props = {
  isOpen: boolean;
  onAddContents: (unitId: string, contents: Content[]) => void;
  onClose: () => void;
  unitId: string | null;
  sectionId: string;
  ltiDeployments: LtiDeploymentInterface[];
  initialCheckedContents: Content[];
};
export const ContentsSelectModal = ({
  isOpen,
  onAddContents,
  unitId,
  sectionId,
  ltiDeployments,
  onClose,
  initialCheckedContents,
}: Props) => {
  const {
    handleAdd,
    handleCheck,
    handleUnCheck,
    handleLoadMore,
    handleSearch,
    checkedContents,
    contents,
    searchResult,
  } = useContentsSelectModal({
    initialCheckedContents,
    isOpen,
    onAddContents,
    sectionId,
    unitId,
  });

  return (
    <Modal isOpen={isOpen} className={styles.modal} onRequestClose={onClose}>
      <Modal.Header onClose={onClose}>コンテンツの追加</Modal.Header>
      <Modal.Body>
        <ContentSearchForm
          ltiDeployments={ltiDeployments}
          isLoading={searchResult.isLoading}
          handleSearch={handleSearch}
        />
        <Label isMute noMargin>
          コンテンツの選択
        </Label>
        <BlockRow marginTop="1rem">
          {searchResult.isLoading ? (
            <div className={styles.loader__container}>
              <Loader loading={searchResult.isLoading} />
            </div>
          ) : (
            <SelectTable
              contents={contents}
              onCheckContent={handleCheck}
              onUnCheckContent={handleUnCheck}
              checkedContents={checkedContents}
              onLoadMore={handleLoadMore}
            />
          )}
        </BlockRow>
        <Flex justifyContent="center" marginTop="1.6rem">
          <Button
            disabled={checkedContents.length === 0}
            size="lg"
            className={styles.addContentButton}
            onClick={handleAdd}
          >
            追加
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

type SelectTableProps = {
  contents: Content[];
  onCheckContent: (content: Content) => void;
  onUnCheckContent: (content: Content) => void;
  checkedContents: Content[];
  onLoadMore: () => void;
};
const SelectTable = ({
  onCheckContent,
  onUnCheckContent,
  onLoadMore,
  contents,
  checkedContents,
}: SelectTableProps) => {
  const onScroll = useScrollWatcher({ onReachToBottom: onLoadMore });

  return (
    <div className={styles.tableWrapper} onScroll={onScroll}>
      <Table
        noOutlineBorder
        headers={tableHeaders}
        headColumnClassName={styles.headColumn}
        rows={
          contents.length > 0
            ? contents.map((content) => (
                <TableRow
                  content={content}
                  onCheckContent={onCheckContent}
                  onUnCheckContent={onUnCheckContent}
                  isChecked={checkedContents.some(
                    (checkedContent: Content) =>
                      checkedContent.id === content.id,
                  )}
                  key={`table-${content.id}`}
                />
              ))
            : [<EmptyTableRow key={`table-empty`} />]
        }
      />
    </div>
  );
};

const tableHeaders = [
  { key: "checkbox", text: "", centering: false },
  { key: "name", text: "コンテンツ名", centering: false },
  { key: "fileSize", text: "サイズ", centering: true },
  { key: "contentType", text: "種類", centering: true },
  { key: "updatedAt", text: "変更日", centering: true },
];

type TableRowProps = {
  content: Content;
  onCheckContent: (content: Content) => void;
  onUnCheckContent: (content: Content) => void;
  isChecked: boolean;
};
const TableRow_ = ({
  content,
  onCheckContent,
  onUnCheckContent,
  isChecked,
}: TableRowProps) => {
  const handleChange = () => {
    if (isChecked) {
      onUnCheckContent(content);
    } else {
      onCheckContent(content);
    }
  };

  return (
    <tr key={`content-${content.id}`} className={styles.row}>
      <TableColumn className={styles.checkboxColumn}>
        <Checkbox
          onChange={handleChange}
          checked={isChecked}
          id={`checkbox-${content.id}`}
          aria-label={`${content.name}を選択`}
          strokeColorLevel={"darken"}
        />
      </TableColumn>
      <TableColumn className={styles.contentNameColumn}>
        <BlockRow>
          {content.url ? (
            <a
              href={content.url}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.contentName}
            >
              {content.name}
            </a>
          ) : (
            content.name
          )}
        </BlockRow>
        {content.availableStartAt && content.availableEndAt ? (
          <BlockRow marginTop="0.5rem">
            <span>利用期間：</span>
            <span className={styles.contentName__durationValue}>
              {`${TimeHelper.fullFormat(
                content.availableStartAt,
              )} 〜 ${TimeHelper.fullFormat(content.availableEndAt)}`}
            </span>
          </BlockRow>
        ) : null}
      </TableColumn>
      <TableColumn noWrap centering>
        {content.fileSize ? formatFileSize(content.fileSize) : null}
      </TableColumn>
      <TableColumn noWrap centering>
        {formatContentType(content.contentType, content.ltiToolName)}
      </TableColumn>
      <TableColumn noWrap centering>
        {TimeHelper.fullFormat(content.updatedAt)}
      </TableColumn>
    </tr>
  );
};
const TableRow = React.memo(TableRow_);
const EmptyTableRow = () => {
  return (
    <tr className={styles.row}>
      <TableColumn
        colSpan={6}
        noWrap
        centering
        className={styles.emptyTableRow}
      >
        指定した検索に該当する結果はありませんでした。
      </TableColumn>
    </tr>
  );
};
