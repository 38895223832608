import { Button } from "@studyplus/boron-ui";
import format from "date-fns/format";
import set from "date-fns/set";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import uniqBy from "lodash-es/uniqBy";
import * as React from "react";
import { Link } from "react-router-dom";
import { Text } from "../../../components/general/Text";
import TimeHelper from "../../../helpers/TimeHelper";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import {
  LocationStatus,
  StayFormInterface,
  StayStateInterface,
} from "../../../interfaces/StayInterface";
import Icon from "../../atoms/Icon";
import { Modal } from "../../atoms/Modal/index";
import { SingleDatePickerField } from "../../atoms/SingleDatePickerField";
import styles from "./styles.scss";

interface ComponentProps {
  sectionId: string;
  stay: StayStateInterface;
  submitting: boolean;
  updateSectionStay: (
    sectionId: string,
    stayId: string,
    params: StayFormInterface,
  ) => void;
  deleteSectionStay: (sectionId: string, stayId: string) => void;
}

interface Values {
  date: Date;
  enteredAt: string;
  exitedAt: string;
}

type Props = ComponentProps & FormikProps<Values>;

interface State {
  isOpen: boolean;
  focused: boolean;
}

class SectionStayItem extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      focused: false,
    };
  }

  apiErrors(): ApiErrorInterface[] {
    return this.props.stay.apiErrors
      ? uniqBy(
          this.props.stay.apiErrors,
          (error: ApiErrorInterface) => error.message,
        )
      : [];
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.state.isOpen) {
      return;
    }

    if (
      this.props.stay.enteredAt != prevProps.stay.enteredAt ||
      this.props.stay.exitedAt != prevProps.stay.exitedAt
    ) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    const { stay } = this.props;
    return (
      <tr key={`sectionStay-${stay.id}`} className={styles.row}>
        <td>
          <Link
            to={`/students/${stay.student.id}`}
            className={styles.studentLink}
          >
            {stay.student.fullName}
          </Link>
        </td>
        <td>{stay.student.jobHuman}</td>
        <td>{stay.enteredSection?.sectionName}</td>
        <td>
          <EnterLeaveTimeInfo
            time={stay.enteredAt}
            buildingName={stay.enteredBuildingName}
            status={stay.enteredStatus}
          />
        </td>
        <td>
          {stay.isExited && (
            <EnterLeaveTimeInfo
              time={stay.exitedAt}
              buildingName={stay.exitedBuildingName}
              status={stay.exitedStatus}
            />
          )}
        </td>
        <td>
          {stay.isExited
            ? TimeHelper.secondsToDisplayTime(stay.staySeconds)
            : "ー"}
        </td>
        <td className={styles.last_col}>
          <button className={styles.link} onClick={this.handleUpdateModalOpen}>
            <Icon name="icon-edit" />
            編集
          </button>
          <button className={styles.link} onClick={this.handleDeleteStay}>
            <Icon name="icon-trash" />
            削除
          </button>
        </td>
        {this.renderUpdateModal()}
      </tr>
    );
  }

  private renderUpdateModal = () => {
    return (
      <Modal
        isOpen={this.state.isOpen}
        onRequestClose={this.handleUpdateModalClose}
        className={styles.modal}
      >
        <Modal.Header onClose={this.handleUpdateModalClose}>
          <h1>
            <Link to={`/students/${this.props.stay.student.id}`}>
              {this.props.stay.student.fullName}
            </Link>
            の入退室記録
          </h1>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {this.renderApiErrors()}
            <div className={styles.form_row}>
              <div className={styles.input_group}>
                <label htmlFor={`sectionStay-${this.props.stay.id}__date`}>
                  日付
                </label>
                <Field
                  id={`sectionTag-${this.props.stay.id}__date`}
                  name="date"
                  component={SingleDatePickerField}
                  className={styles.input__date}
                />
              </div>
            </div>
            <div className={styles.form_row}>
              <div className={styles.input_group}>
                <label htmlFor={`sectionStay-${this.props.stay.id}__enteredAt`}>
                  入室時間
                </label>
                <div className={styles.input__time_wrapper}>
                  <Field
                    id={`sectionTag-${this.props.stay.id}__enteredAt`}
                    name="enteredAt"
                    type="time"
                    className={styles.input__time}
                  />
                </div>
              </div>
              <div className={styles.input_group}>
                <label htmlFor={`sectionStay-${this.props.stay.id}__exitedAt`}>
                  退室時間
                </label>
                <Field
                  id={`sectionTag-${this.props.stay.id}__exitedAt`}
                  name="exitedAt"
                  type="time"
                  className={styles.input__time}
                />
              </div>
            </div>
            <div className={styles.form_row__button}>
              <Button
                disabled={this.props.submitting}
                type="submit"
                className={styles.btn}
              >
                入退室記録を更新
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  private renderApiErrors = () => {
    return this.apiErrors().map((err: ApiErrorInterface, idx: number) => {
      return (
        <p
          key={`sectionStay-${this.props.stay.id}-${idx}`}
          className={styles.input_errors}
        >
          {err.message}
        </p>
      );
    });
  };

  private handleUpdateModalOpen = () => {
    this.setState({ isOpen: true });
  };

  private handleUpdateModalClose = () => {
    this.setState({ isOpen: false });
    this.props.resetForm();
  };

  private handleDeleteStay = () => {
    if (this.props.submitting) {
      return null;
    }

    if (window.confirm("この入退室履歴を削除しますか？")) {
      this.props.deleteSectionStay(this.props.sectionId, this.props.stay.id);
    }
  };
}

const mapPropsToValues = (props: ComponentProps): Values => {
  const { enteredAt, exitedAt } = props.stay;
  return {
    date: new Date(enteredAt),
    enteredAt: format(new Date(enteredAt), "HH:mm"),
    exitedAt: exitedAt ? format(new Date(exitedAt), "HH:mm") : "",
  };
};

const mapValuesToParams = (values: Values): StayFormInterface => {
  const enteredAt = values.enteredAt.split(":");
  const exitedAt = values.exitedAt.split(":");
  return {
    entered_at: set(values.date, {
      hours: parseInt(enteredAt[0]),
      minutes: parseInt(enteredAt[1]),
    }).toISOString(),
    exited_at: values.exitedAt
      ? set(values.date, {
          hours: parseInt(exitedAt[0]),
          minutes: parseInt(exitedAt[1]),
        }).toISOString()
      : "",
  };
};

type EnterLeaveTimeInfoProps = {
  time: string;
  status: LocationStatus | null;
  buildingName: string | null;
};
export const EnterLeaveTimeInfo = ({
  time,
  status,
  buildingName,
}: EnterLeaveTimeInfoProps) => {
  const formattedTime = time ? TimeHelper.fullFormat(time) : "";

  let statusText = "";
  if (time) {
    switch (status) {
      case "outside":
        statusText = "（範囲外）";
        break;
      case "inside":
        statusText = `（${buildingName}）`;
        break;
      case "unable_to_locate":
        statusText = "（座標取得不可）";
        break;
      case "indeterminable":
        statusText = "（座標判定不可）";
        break;
    }
  }
  return <Text align="center">{`${formattedTime}${statusText}`}</Text>;
};

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
) => {
  const { props } = formikBag;

  if (props.submitting) {
    return null;
  } else {
    props.updateSectionStay(
      props.sectionId,
      props.stay.id,
      mapValuesToParams(values),
    );
  }
};

export default withFormik({
  mapPropsToValues,
  handleSubmit,
  isInitialValid: true,
})(SectionStayItem);
