import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback } from "react";
import { boronClient } from "../../../api";
import { HTTPErrors, createError } from "../../../errors";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import { paths } from "../../../lib/api/v1";

type Response =
  paths["/api/v1/sections/{section_id}/guardian_announce_message_threads"]["get"]["responses"]["200"]["content"]["application/json"]["guardianAnnonceMessageThreads"];

const buildQueryKey = (sectionId: string) => ["messageThreads", sectionId];

export const useFetchMessageThreads = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  return useInfiniteQuery<Response, HTTPErrors>({
    queryKey: buildQueryKey(sectionId),
    queryFn: async ({ pageParam }) => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/guardian_announce_message_threads",
        {
          params: {
            path: { section_id: sectionId },
            query: {
              page: pageParam as number,
            },
          },
        },
      );
      if (response.ok && data) {
        return data.guardianAnnonceMessageThreads;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });
};

export const useUpdateMessageThreadsCache = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  const queryClient = useQueryClient();
  const updateMessageThreadsCache = useCallback(
    ({
      messageThread: targetThread,
      latestMessage,
    }: {
      messageThread: Omit<Response["data"][0], "latestMessage">;
      latestMessage: Response["data"][0]["latestMessage"];
    }) => {
      queryClient.setQueryData<InfiniteData<Response>>(
        buildQueryKey(sectionId),
        (data) => {
          if (!data) {
            return data;
          }

          // スレッドが存在しない場合、先頭に挿入する
          const isExist = data.pages.some((page) =>
            page.data.some(
              (messageThread) => messageThread.id === targetThread.id,
            ),
          );
          if (!isExist) {
            return {
              ...data,
              pages: [
                {
                  ...data.pages[0],
                  data: [
                    {
                      ...targetThread,
                      latestMessage,
                    },
                    ...data.pages[0].data,
                  ],
                },
                ...data.pages.slice(1),
              ],
            };
          }

          // すでに指定されたスレッドが存在する場合、最新メッセージを更新する
          return {
            ...data,
            pages: data.pages.map((page) => {
              return {
                ...page,
                data: page.data.map((messageThread) => {
                  if (messageThread.id === targetThread.id) {
                    return {
                      ...messageThread,
                      latestMessage,
                    };
                  }
                  return messageThread;
                }),
              };
            }),
          };
        },
      );
    },
    [sectionId, queryClient],
  );

  return { updateMessageThreadsCache };
};
