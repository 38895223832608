import * as React from "react";

import StudentTag, {
  StudentTagFilterInterface,
  StudentTagType,
} from "../../../domains/StudentTag";
import FiltersHelper, { toQueryObject } from "../../../helpers/FiltersHelper";
import { useOnLocationChange } from "../../../helpers/RouterHelper";
import StatusTagHelper from "../../../helpers/StatusTagHelper";
import { usePushHistory } from "../../../hooks/usePushHistory";
import Loader from "../../atoms/Loader";
import StatusTagFilter from "../../molecules/StatusTagFilter";
import { FilterWrapper } from "../FilterWrapper";
import { StudentTagFilter } from "./StudentTagFilter";
import { useStudentFilterContext } from "./useStudentFilterContext";

type Props = {
  sectionId: string;
  label?: string;
};

const StudentFilter = (props: Props) => {
  return (
    <FilterWrapper
      StudentStatusFilter={<StatusFilter />}
      StudentTagFilter={<TagFilter {...props} />}
    />
  );
};

export const StatusFilter = () => {
  const { currentFilter, isInitialized } = useStudentFilterContext();
  const { isClickableStatusTag, handleStatusTagClick } =
    useStudentStatusFilter();

  if (!isInitialized) {
    return <Loader loading={true} />;
  }

  return (
    <StatusTagFilter
      tags={StatusTagHelper.getAll()}
      selectedTags={currentFilter.selectedStatusTags}
      onClickTag={handleStatusTagClick}
      isClickableTag={isClickableStatusTag}
    />
  );
};

export const TagFilter = (props: Props) => {
  const { isInitialized } = useStudentFilterContext();

  const { allTags, selectedTags, handleSelectTag, handleDeleteTag } =
    useStudentTagFilter(props);

  if (!isInitialized) {
    // ローダーはStatusFilter側で1つだけ表示する
    return null;
  }

  return (
    <StudentTagFilter
      label={props.label}
      tags={allTags}
      selectedTags={selectedTags}
      onSelect={handleSelectTag}
      onDeselect={handleDeleteTag}
    />
  );
};

const useStudentStatusFilter = () => {
  const context = useStudentFilterContext();

  const { pushFilterHistory } = usePushFilterHistory();

  // フィルター変更でURLパラメータが変更されたときのステート更新
  useOnLocationChange(() => context.updateStudentFilterByQuery());

  // 絞込条件の連携状態をすべて選択解除できないようにする
  const isClickableStatusTag = (tag: StudentTag): boolean => {
    const { selectedStatusTags } = context.currentFilter;
    return (
      selectedStatusTags.length !== 1 || selectedStatusTags[0].id !== tag.id
    );
  };

  // 絞込条件の連携ステータスの変更時にURLパラメータを更新する
  const handleStatusTagClick = (tag: StudentTag) => () => {
    const { selectedStatusTags } = context.currentFilter;
    if (selectedStatusTags.some((selectedTag) => selectedTag.id === tag.id)) {
      // クリックされたタグが選択済みで、そのタグのみ選択されている場合はなにもしない
      if (selectedStatusTags.length === 1) {
        return;
      }
      // クリックされたタグが選択済みで、他にも選択されているタグがある場合はタグを選択から外す
      pushFilterHistory({
        ...context.currentFilter,
        selectedStatusTags: selectedStatusTags.filter(
          (selectedTag) => selectedTag.id !== tag.id,
        ),
      });
    } else {
      // クリックされたタグが選択済みでない or 何も選択されていない場合タグを選択する
      pushFilterHistory({
        ...context.currentFilter,
        selectedStatusTags: [...selectedStatusTags, tag],
      });
    }
  };

  return {
    isClickableStatusTag,
    handleStatusTagClick,
  };
};

const useStudentTagFilter = (props: Props) => {
  const context = useStudentFilterContext();
  const { pushFilterHistory } = usePushFilterHistory();

  const [allTags, setAllTag] = React.useState<StudentTag[]>([
    ...context.currentFilter.tags,
    ...context.currentFilter.systemTags,
  ]);
  const [selectedTags, setSelectedTags] = React.useState<StudentTag[]>(
    context.currentFilter.selectedTags,
  );

  // NOTE: TagFilterでも必要な処理だが、どちらか一方だけで実行できればよいのでStatusFilter側だけで実行
  useInitialize({ sectionId: props.sectionId });

  const sortAndFilterByName = (
    tags: StudentTag[] | undefined,
    type: StudentTagType,
  ): StudentTag[] => {
    if (tags === undefined) return [];

    return tags
      .filter((tag) => tag.type === type)
      .sort((a: StudentTag, b: StudentTag) => {
        return a.name > b.name ? 1 : -1;
      });
  };

  React.useEffect(() => {
    setAllTag([
      ...context.currentFilter.systemTags,
      ...sortAndFilterByName(context.currentFilter.tags, "tag_ids"),
      ...sortAndFilterByName(
        context.currentFilter.tags.filter((tag) => tag.enabled),
        "smart_tag",
      ),
    ]);
  }, [context.currentFilter.tags, context.currentFilter.systemTags]);

  // ソート前後で表示がチラつくのでuseLayoutEffectを使う
  React.useLayoutEffect(() => {
    setSelectedTags([
      ...sortAndFilterByName(context.currentFilter.selectedTags, "job"),
      ...sortAndFilterByName(context.currentFilter.selectedTags, "tag_ids"),
      ...sortAndFilterByName(context.currentFilter.selectedTags, "smart_tag"),
    ]);
  }, [context.currentFilter.selectedTags]);

  // 絞込条件の生徒タグの選択時にURLパラメータを更新する
  const handleSelectTag = (tag: StudentTag) => () => {
    const { selectedTags } = context.currentFilter;
    const isAlreadySelected = selectedTags.some((t) => t.id === tag.id);

    if (isAlreadySelected) {
      pushFilterHistory({
        ...context.currentFilter,
        selectedTags: selectedTags.filter((t) => t.id !== tag.id),
      });
    } else {
      const selectedTag = allTags.find((t) => t.id === tag.id);
      if (selectedTag) {
        pushFilterHistory({
          ...context.currentFilter,
          selectedTags: [...selectedTags, selectedTag],
        });
      }
    }
  };
  // 絞込条件の生徒タグの削除時にURLパラメータを更新する
  const handleDeleteTag = (tag: StudentTag) => () => {
    pushFilterHistory({
      ...context.currentFilter,
      selectedTags: context.currentFilter.selectedTags.filter(
        (t) => t.id !== tag.id,
      ),
    });
  };

  return {
    allTags,
    selectedTags,
    handleSelectTag,
    handleDeleteTag,
  };
};

type UseInitializeProps = {
  sectionId: string;
};

const useInitialize = ({ sectionId }: UseInitializeProps) => {
  const {
    setFilterKey,
    isInitialized,
    setIsInitialized,
    isTagLoaded,
    initializeWithQuery,
    initializeWithStorage,
    updateStudentFilterByQuery,
  } = useStudentFilterContext();

  React.useEffect(() => {
    // 画面遷移のたびに初期化する
    setIsInitialized(false);
  }, []);

  React.useLayoutEffect(() => {
    setFilterKey(sectionId);
    if (!isTagLoaded) return;
    if (isInitialized) return;

    // URLパラメータを優先して初期化する
    const isInitializedByQuery = initializeWithQuery();
    if (isInitializedByQuery) return;
    // URLパラメータで初期化しなかった場合はローカルストレージから初期化する
    initializeWithStorage();
  }, [sectionId, isTagLoaded, isInitialized]);

  // フィルター変更でURLパラメータが変更されたときのステート更新
  useOnLocationChange(() => updateStudentFilterByQuery());
};

const usePushFilterHistory = () => {
  const { pushHistory } = usePushHistory();
  const pushFilterHistory = React.useCallback(
    (tagFilter: StudentTagFilterInterface) => {
      // 日付のフィルターはURLパラメータを使い回す(グローバルなstateで管理しないため)
      const filtersQuery = FiltersHelper.queryToFilters(location.search, []);
      // 絞込検索の条件は上書きする
      filtersQuery.tagFilter = tagFilter;

      const queryParams = toQueryObject({
        tagFilter: filtersQuery.tagFilter,
        ...filtersQuery,
      });
      pushHistory(queryParams, { deleteKeys: ["tag_ids", "job", "smart_tag"] });
    },
    [],
  );

  return {
    pushFilterHistory,
  };
};

export default StudentFilter;
