import { Button } from "@studyplus/boron-ui";
import classnames from "classnames";
import { Formik } from "formik";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { BlockRow } from "../../../components/atoms/BlockRow";
import ErrorText from "../../../components/atoms/ErrorText";
import Input from "../../../components/atoms/Input";
import Label from "../../../components/atoms/Label";
import {
  DefaultColorTypeRadioButton,
  RadioButtonGroup,
} from "../../../components/general/RadioGroupButton";
import {
  OptionType,
  SelectWrapper,
} from "../../../components/general/SelectWrapper";
import { Lecture } from "../../../domains/Lecture";
import { LectureQuestionnaire } from "../../../domains/LectureQuestionnaire";
import { isUnprocessableEntityError } from "../../../errors";
import { CopyableFormMode } from "../../../helpers/viewMode";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import SectionInterface from "../../../interfaces/SectionInterface";
import {
  LectureValue,
  defaultValues,
  prepareLectureConfig,
  validationSchema,
} from "./FormikWithLecture";
import styles from "./LectureForm.scss";
import { QuestionnairesForm } from "./QuestionnaireForm";
import {
  MutateLectureParamData,
  useMutationWithLecture,
} from "./useMutationWithLecture";

type LectureFormProps = {
  section: SectionInterface;
  lecture: Lecture | null;
  mode: CopyableFormMode;
};
const useLectureForm = ({ section, lecture, mode }: LectureFormProps) => {
  const navigate = useNavigate();
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();

  const { mutate, isPending } = useMutationWithLecture({
    onError(error) {
      const message = isUnprocessableEntityError(error)
        ? error.getErrorMessages().join("\n")
        : "講座の登録に失敗しました。しばらく間を置いてから再度お試しください";

      showErrorMessage(message);
    },
    onSuccess() {
      showSuccessMessage("講座を登録しました");
      navigate(`/sections/${section.id}/settings/lectures`, { replace: true });
    },
    section,
  });

  const initialValues = React.useMemo(() => {
    return lecture ? toLectureValue(lecture, mode) : null;
  }, [lecture]);

  // onSubmit内の処理でquestionnaireをidから特定したいため
  const normalizedLectureData = React.useMemo<
    Record<string, LectureQuestionnaire>
  >(
    () =>
      lecture?.questionnaires.reduce((p, c) => {
        return {
          ...p,
          [c.id]: c,
        };
      }, {}) ?? {},
    [lecture],
  );

  const onSubmit = (value: LectureValue) => {
    // データの詰め替え
    // LectureValue(フォーム入力のためのデータレイアウト) -> MutateLectureParamData(API送信hooksに渡すためのデータレイアウト)
    const lecture: MutateLectureParamData = {
      id: value.id,
      name: value.name,
      attendanceConfirm: value.attendanceConfirm,
      attendanceLimitDays: value.attendanceLimitDays,
      attendanceLocationCollect: value.attendanceLocationCollect === "on",
      questionnaires: value.questionnaires
        .filter((qs) => qs.isInputting)
        .map((qs) => {
          // 登録済みの質問に対して削除を行った場合はオリジナルのデータに戻してからサーバーに送る、で挙動を統一する
          // フィールドを空っぽにされた後に送信を押された場合などにエラーになってしまう等、削除アクション前に行われた内容編集を考慮して辻褄を合わせるのが大変なため
          if (
            qs.deleted && // フォーム内の該当questionnaireが削除済み
            qs.id &&
            normalizedLectureData[qs.id]?.deleted === false // オリジナルのデータが削除済みではない
          ) {
            return {
              ...normalizedLectureData[qs.id],
              deleted: true,
            };
          }
          return {
            choices: qs.choices?.filter((c) => c && c.length > 0) ?? [],
            disabled: qs.disabled,
            question: qs.question,
            format: qs.format,
            deleted: qs.deleted,
            id: qs.id,
          };
        }),
    };
    mutate({ data: lecture, mode });
  };

  return {
    onSubmit,
    initialValues,
    isPending,
  };
};

export const LectureForm = (props: LectureFormProps) => {
  const { onSubmit, initialValues, isPending } = useLectureForm(props);
  return (
    /* eslint-disable @typescript-eslint/no-empty-function */
    <Formik
      initialValues={initialValues ?? defaultValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const {
          lectureNameProps,
          getRadioGroupProps,
          getAttendanceConfirmProps,
          getAttendanceLimitDaysProps,
          nameError,
        } = prepareLectureConfig(formikProps);

        return (
          <form onSubmit={formikProps.handleSubmit} className={styles.form}>
            <div>
              <Label htmlFor="lecture-name" isMute>
                講座名
              </Label>
            </div>
            <div>
              <Input id="lecture-name" {...lectureNameProps} />
              {nameError && <ErrorText>{nameError}</ErrorText>}
            </div>
            <div role="radiogroup" aria-labelledby="should-confirm-attend">
              <BlockRow marginTop="2.0rem">
                <div
                  id="should-confirm-attend"
                  className={styles.ItemContainer}
                >
                  <div className={styles.MainLabel}>講座の出席確認</div>
                  <div>講座に対し生徒へ出席状況の確認ができます。</div>
                </div>
              </BlockRow>
              <BlockRow marginTop="2.0rem">
                <div className={styles.FormContent}>
                  <RadioButtonGroup>
                    <DefaultColorTypeRadioButton
                      {...getAttendanceConfirmProps("none")}
                    >
                      確認なし
                    </DefaultColorTypeRadioButton>
                    <DefaultColorTypeRadioButton
                      {...getAttendanceConfirmProps("presence_and_absence")}
                    >
                      出席・欠席を確認
                    </DefaultColorTypeRadioButton>
                    <DefaultColorTypeRadioButton
                      {...getAttendanceConfirmProps("presence_only")}
                    >
                      出席のみ確認
                    </DefaultColorTypeRadioButton>
                  </RadioButtonGroup>
                </div>
              </BlockRow>
            </div>
            <BlockRow marginTop="3.2rem">
              <div>
                <div className={styles.ItemContainer}>
                  <label
                    className={styles.MainLabel}
                    htmlFor="attedance-limit-days"
                    aria-describedby="detail-about-attendance-limit-days"
                  >
                    講座の出席確認期限
                  </label>
                  <p id="detail-about-attendance-limit-days">
                    出席状況の確認回答の期間を設定できます。
                  </p>
                </div>
              </div>
            </BlockRow>
            <BlockRow marginTop="2.0rem">
              <div
                className={classnames(
                  styles.ItemContainer,
                  styles.FormContent,
                  styles.DeadLineAttendance__FormContainer,
                )}
              >
                <div className={styles.DeadLineAttendance__AssumptionText}>
                  講座予定日時から
                </div>
                <div className={styles.DeadLineAttendance__Select}>
                  <SelectWrapper
                    inputId="attedance-limit-days"
                    {...getAttendanceLimitDaysProps(
                      determineAttendanceLimitValue,
                    )}
                    size="md"
                    options={limitDaysOptions}
                  />
                </div>
              </div>
            </BlockRow>
            <div role="radiogroup" aria-labelledby="should-confirm-location">
              <BlockRow marginTop="3.2rem">
                <div
                  id="should-confirm-location"
                  className={styles.ItemContainer}
                >
                  <div className={styles.MainLabel}>出席確認の位置情報判定</div>
                  <div>
                    講座を実施する教室の位置情報に対して、生徒の出席記録時の位置情報が範囲内か判定することができます。
                  </div>
                </div>
              </BlockRow>
              <BlockRow marginTop="2.0rem">
                <div className={styles.FormContent}>
                  <RadioButtonGroup>
                    <DefaultColorTypeRadioButton
                      {...getRadioGroupProps("attendanceLocationCollect", "on")}
                    >
                      有効
                    </DefaultColorTypeRadioButton>
                    <DefaultColorTypeRadioButton
                      {...getRadioGroupProps(
                        "attendanceLocationCollect",
                        "off",
                      )}
                    >
                      無効
                    </DefaultColorTypeRadioButton>
                  </RadioButtonGroup>
                </div>
              </BlockRow>
            </div>
            <div
              role="radiogroup"
              aria-labelledby="should-auto-save-study-record"
            >
              <BlockRow marginTop="3.2rem">
                <div
                  id="should-auto-save-study-record"
                  className={styles.ItemContainer}
                >
                  <div className={styles.MainLabel}>アンケートの設置</div>
                  <div>講座に出席した生徒へアンケートの設定ができます。</div>
                </div>
              </BlockRow>
            </div>
            <BlockRow marginTop="2rem">
              <div
                className={classnames(
                  styles.FormContent,
                  styles.QuestionnaireForm__Content,
                )}
              >
                <QuestionnairesForm />
              </div>
            </BlockRow>
            <div className={styles.submitButtonWrapper}>
              <Button
                className={styles.submitButton}
                type="submit"
                isLoading={isPending}
                disabled={isPending}
              >
                登録
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

const toLectureValue = (
  lecture: Lecture,
  mode: CopyableFormMode,
): LectureValue => {
  return {
    id: lecture.id,
    name: mode === "copy" ? `（コピー）${lecture.name}` : lecture.name,
    attendanceConfirm: lecture.attendanceConfirm,
    attendanceLimitDays: lecture.attendanceLimitDays ?? Infinity,
    attendanceLocationCollect: lecture.attendanceLocationCollect ? "on" : "off",
    questionnaires: lecture.questionnaires.map((qs) => {
      return {
        question: qs.question,
        choices: qs.choices ?? [],
        disabled: qs.disabled,
        format: qs.format,
        ...(mode === "edit" ? { id: qs.id } : {}),
        deleted: qs.deleted,
        isInputting: true,
      };
    }),
  };
};

const limitDaysOptions = [
  {
    label: "回答期限なし",
    value: Infinity,
  },
  { label: "当日", value: 0 },
  ...Array.from({ length: 30 }).map((_, i) => {
    const v = i + 1;
    return { label: `${v}日後`, value: v };
  }),
];

const limitDaysOptionMap = limitDaysOptions.reduce(
  (p, c) => ({
    ...p,
    [c.value]: c,
  }),
  {} as Record<number, OptionType>,
);

const determineAttendanceLimitValue = (value: number) =>
  limitDaysOptionMap[value] ?? emptyOption;

const emptyOption = limitDaysOptions[0];
