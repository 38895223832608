import * as React from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/atoms/Loader";
import { PageHeader } from "../../components/atoms/MainSection";
import { ExaminationResultForm } from "../../components/features/ExaminationResultForm/ExaminationResultForm";
import { useExaminationResultForm } from "../../components/features/ExaminationResultForm/useExaminationResultForm";
import { Breadcrumbs } from "../../components/general/Breadcrumbs";
import { ExaminationResult } from "../../domains/ExaminationResult";
import { OutletContextProps } from "../../helpers/RouterHelper";
import enhanceStudentsPage, {
  StudentsPageProps,
} from "../../hocs/enhanceStudentsPage";
import { useQueryError } from "../../hooks/http/useQueryError";
import StudentInterface from "../../interfaces/StudentInterface";
import { useFetchStudentExaminationResult } from "../StudentsAnalyticsExaminationResultPage/useFetchStudentExaminationResult";
import { useEditExaminationResultFormEffect } from "./useEditExaminationResultFormEffect";

type Props = StudentsPageProps &
  OutletContextProps & {
    student: StudentInterface;
  };

const StudentsExaminationResultEditPage_ = ({
  student,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("analytics");
  }, []);
  const params = useParams<{ examinationId: string }>();
  const {
    data: examinationResult,
    isLoading,
    error,
  } = useFetchStudentExaminationResult({
    studentId: student.id,
    examinationId: params?.examinationId ?? null,
    retry: false,
  });

  useQueryError(error);

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: "成績記録一覧",
            url: `/students/${student.id}/analytics/examination`,
          },
          { label: "成績記録の編集", url: location.pathname },
        ]}
      >
        {(item, isLastItem) =>
          isLastItem ? (
            <div>{item.label}</div>
          ) : (
            <Link to={item.url ?? "/"}>{item.label}</Link>
          )
        }
      </Breadcrumbs>
      <div className="mt-8 rounded border border-solid border-gray-400 bg-white px-7 pb-7 pt-8">
        <PageHeader helpUrl="https://fs-help.studyplus.co.jp/ja/articles/7963949">
          成績記録の編集
        </PageHeader>
        {examinationResult && (
          <div className="mt-9">
            <EditExaminationResultForm
              student={student}
              examinationResult={examinationResult}
            />
          </div>
        )}
        <Loader loading={isLoading} />
      </div>
    </>
  );
};

type EditExaminationResultFormProps = {
  student: Props["student"];
  examinationResult: ExaminationResult;
};
const EditExaminationResultForm = ({
  student,
  examinationResult,
}: EditExaminationResultFormProps) => {
  const form = useExaminationResultForm({
    studentId: student.id,
    submitType: "update",
  });
  // APIから取得したexaminationResultをformにセットする
  useEditExaminationResultFormEffect(form, examinationResult);

  return (
    <ExaminationResultForm
      studentId={student.id}
      form={form}
      resultDisabled={false}
      isExaminationSelectable={false}
    />
  );
};

export const StudentsExaminationResultEditPage = enhanceStudentsPage(
  StudentsExaminationResultEditPage_,
  {
    title: "成績記録の編集",
  },
);
