import { Button } from "@studyplus/boron-ui";
import ErrorText from "../../../../components/atoms/ErrorText";
import { Flex } from "../../../../components/atoms/Flex";
import Input from "../../../../components/atoms/Input";
import Label from "../../../../components/atoms/Label";
import styles from "./TeacherForm.scss";
import { UseTeacherFormProps, useTeacherForm } from "./useTeacherForm";

type Props = UseTeacherFormProps & { closeModal?: () => void };

export const TeacherForm = (props: Props) => {
  const {
    firstNameProps,
    firstNameErrorProps,
    firstNameErrorText,
    lastNameProps,
    lastNameErrorProps,
    lastNameErrorText,
    buttonProps,
    formProps,
    actionLabel,
  } = useTeacherForm(props);

  return (
    <Flex justifyContent="center" marginTop="0">
      <form
        {...formProps}
        className={styles.formContainer}
        role="form"
        aria-label="講師フォーム"
      >
        <div className={styles.inputLabel}>
          <Label isMute required={true}>
            お名前
          </Label>
        </div>
        <div className={styles.row}>
          <div className={styles.input}>
            <Input id="last-name" aria-label="姓" {...lastNameProps} />
            <ErrorText {...lastNameErrorProps}>{lastNameErrorText}</ErrorText>
          </div>
          <div className={styles.input}>
            <Input id="first-name" aria-label="名" {...firstNameProps} />
            <ErrorText {...firstNameErrorProps}>{firstNameErrorText}</ErrorText>
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          {props.urlActionType == "edit" ? (
            <Button
              className={styles.cancelButton}
              variant="outline"
              onClick={props.closeModal}
            >
              キャンセル
            </Button>
          ) : null}
          <Button
            type="submit"
            className={styles.submitButton}
            {...buttonProps}
          >
            {actionLabel}
          </Button>
        </div>
      </form>
    </Flex>
  );
};
