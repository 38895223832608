import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../api";
import { ExaminationResult } from "../../domains/ExaminationResult";
import { HTTPErrors, createError } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import ApiResponse from "../../interfaces/ApiResponse";

type Response = ApiResponse<ExaminationResult>;

type Props = {
  studentId: string;
  examinationOrganizerId: string | null;
  examinationClassificationId: string | null;
};

export const useFetchStudentAnalyticsExamination = ({
  studentId,
  examinationOrganizerId,
  examinationClassificationId,
}: Props) => {
  const client = useQueryClient();
  const queryKey = cacheKeyOf(
    studentId,
    examinationOrganizerId,
    examinationClassificationId,
  );
  const { data, ...result } = useInfiniteQuery<Response, HTTPErrors>({
    queryKey,
    queryFn: async ({ pageParam }) => {
      const response = await ApiClient.get(
        `/api/v1/students/${studentId}/analytics/examination`,
        {
          query: {
            page: pageParam,
            ...(examinationOrganizerId
              ? { examination_organizer_hashid: examinationOrganizerId }
              : {}),
            ...(examinationClassificationId
              ? {
                  examination_classification_hashid:
                    examinationClassificationId,
                }
              : {}),
          },
        },
      );
      if (response.ok) {
        const json = await response.json();
        return json.examinationResults;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  return {
    data: data?.pages.flatMap((page) => page.data),
    remove: () => client.removeQueries({ queryKey: queryKey }),
    ...result,
  };
};

const cacheKeyOf = (
  studentId: string,
  examinationOrganizerId: string | null,
  examinationClassificationId: string | null,
) => [
  `/students/${studentId}/analytics/examination`,
  examinationOrganizerId,
  examinationClassificationId,
];
