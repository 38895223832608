import { useEffect } from "react";
import {
  OutletContextProps,
  WithRouterProps,
  withRouter,
} from "../../../helpers/RouterHelper";
import enhanceSectionsPage from "../../../hocs/enhanceSectionsPage";
import OperatorPrivilegesStateInterface from "../../../interfaces/OperatorPrivilegesStateInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import SectionGuardianAnnounceMessages from "./SectionGuardianAnnounceMessages";

interface Props
  extends WithRouterProps<{
      tagId: string;
      action: string;
    }>,
    OutletContextProps {
  section: SectionInterface;
  operatorPrivileges: OperatorPrivilegesStateInterface;
}

const SectionsGuardianAnnounceMessagesPage = (props: Props) => {
  useEffect(() => {
    props.setActiveMenu("message");
  }, []);
  const operatorPrivilege = props.operatorPrivileges.data;
  const { location, section } = props;
  const tabs = [
    {
      label: "保護者",
      link: {
        pathname: `/sections/${section.id}/guardian_message_threads`,
        search: location.search,
      },
      active: false,
    },
  ];

  if (
    operatorPrivilege?.role === "admin" ||
    operatorPrivilege?.role === "unlimited_member"
  ) {
    tabs.push({
      label: "一斉送信",
      link: {
        pathname: `/sections/${section.id}/tags/guardian_messages`,
        search: location.search,
      },
      active: true,
    });
  }

  return (
    <SectionGuardianAnnounceMessages
      section={props.section}
      tabs={tabs}
      params={props.params}
    />
  );
};

const EnhancedPage = enhanceSectionsPage(SectionsGuardianAnnounceMessagesPage, {
  title: "メッセージ",
});

export default withRouter<Props>(EnhancedPage);
