import * as React from "react";
import { connect } from "react-redux";
import { getKarte } from "../../../actions/karte";
import BackLink from "../../../components/atoms/BackLink/index";
import Loader from "../../../components/atoms/Loader";
import KarteItem from "../../../components/molecules/KarteItem";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../../hocs/enhanceAuthenticatedPage";
import enhanceStudentsPage from "../../../hocs/enhanceStudentsPage";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { StudentKarteStateInterface } from "../../../interfaces/KarteInterface";
import { StudentStateInterface } from "../../../interfaces/StudentInterface";

interface Props
  extends WithRouterProps<AuthenticateRouterProps & { karteId: string }>,
    OutletContextProps {
  student: StudentStateInterface;
  karte: StudentKarteStateInterface;
  getKarte: (studentId: string, karteId: string) => void;
}

class StudentsKartePage extends React.Component<Props, Record<string, never>> {
  constructor(props: Props) {
    super(props);
    const { params } = props;
    props.getKarte(params.studentId, params.karteId);
    props.setActiveMenu("karte");
  }

  render() {
    const { student, karte } = this.props;

    if (!student.data) {
      return null;
    }

    if (karte.loading) {
      return <Loader loading={true} />;
    }

    if (!karte.data) {
      return null;
    }

    return (
      <React.Fragment>
        <BackLink>戻る</BackLink>
        <KarteItem karte={karte.data} section={student.data.section} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppStateInterface) => {
  return {
    student: state.student,
    karte: state.studentKarte,
  };
};

const actions = {
  getKarte,
};

const ConnectedStudentsKartePage = connect(
  mapStateToProps,
  actions,
)(StudentsKartePage);

export default enhanceStudentsPage(ConnectedStudentsKartePage, {
  title: "カルテ",
});
