import { IconHelpSupport } from "@studyplus/boron-ui";
import * as React from "react";
import { Link } from "react-router-dom";
import { Table } from "../../components/features/AnalyticsTable";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../components/general/Popover/Popover";
import { SectionDrillRetentionLevel } from "../../domains/SectionDrillRetentionLevel";

type Props = {
  sectionId: string;
  data: SectionDrillRetentionLevel[];
};
export const RetentionLevelTable = ({ sectionId, data }: Props) => {
  return (
    <Table>
      <thead className="text-sm">
        <tr className="h-4 whitespace-nowrap border-0 border-b border-solid border-gray-400">
          <th className="w-5/6">教材名</th>
          <th>
            <PopoverHeaderContent title="登録済み">
              対象教材を生徒本棚に
              <br />
              配信した人数
            </PopoverHeaderContent>
          </th>
        </tr>
      </thead>
      <tbody className="divide-x-0 divide-y divide-solid divide-gray-400 text-black">
        {data.length ? (
          data.map((item: SectionDrillRetentionLevel) => (
            <TableRow key={item.publicId} sectionId={sectionId} item={item} />
          ))
        ) : (
          <TableRowNoData />
        )}
      </tbody>{" "}
    </Table>
  );
};

const PopoverHeaderContent = ({
  title,
  children,
}: React.PropsWithChildren<{ title: string }>) => {
  return (
    <div className="flex items-center justify-center">
      <span>{title}</span>
      <PopoverProvider>
        <Popover>
          <PopoverTrigger asChild className="cursor-pointer leading-[0]">
            <div className="p-1">
              <IconHelpSupport className="h-[1.4rem] w-[1.4rem] text-gray-800" />
            </div>
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent side="top">
              <div className="text-center">{children}</div>
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </PopoverProvider>
    </div>
  );
};

const TableRow = ({
  sectionId,
  item,
}: {
  sectionId: string;
  item: SectionDrillRetentionLevel;
}) => {
  const href = `/sections/${sectionId}/analytics/retention_level/${item.learningMaterialCode}`;
  return (
    <tr>
      <td className="p-7 text-left">
        <Link to={href} className="text-blue-400 underline">
          {item.name}
        </Link>
      </td>
      <td className="p-7">{item.registeredCount}人</td>
    </tr>
  );
};

const TableRowNoData = () => {
  const search = location.search;
  const description = search
    ? "該当するデジタル教材が見つかりませんでした"
    : "教材が登録されていません";
  return (
    <tr>
      <td colSpan={2} className="py-7 text-center">
        {description}
      </td>
    </tr>
  );
};
