import { useCallback, useMemo, useRef, useState } from "react";

type Entity = { id: string };

type Props<T extends Entity> = {
  initialTargets?: T[];
  fnCurrentAllItems: () => T[];
};
export const useCollectBatchActionTargets = <T extends Entity>({
  initialTargets,
  fnCurrentAllItems,
}: Props<T>) => {
  const [targets, setTargets] = useState(initialTargets ?? []);
  const fnCurrentAllItemsRef = useRef(fnCurrentAllItems);
  fnCurrentAllItemsRef.current = fnCurrentAllItems;

  const toggleTarget = useCallback((target: T) => {
    setTargets((previous) => {
      if (previous.find((item) => item.id === target.id)) {
        return previous.filter((item) => item.id !== target.id);
      } else {
        return [...previous, target];
      }
    });
  }, []);

  // 一つでも選択済みの時 -> 全てOFF
  // 全てがOFF(選択済みのものがない) -> 表示中のもの全てON
  // アナリティクスのヘッダーのチェックボックスの挙動と同じ
  const toggleAllTargets = useCallback(() => {
    setTargets((previous) => {
      if (previous.length > 0) {
        return [];
      } else {
        return fnCurrentAllItemsRef.current();
      }
    });
  }, []);

  const setAll = useCallback(() => {
    setTargets(fnCurrentAllItemsRef.current());
  }, []);

  const removeAll = useCallback(() => {
    setTargets([]);
  }, []);

  const targetIdsSet = useMemo(
    () => new Set(targets.map((item) => item.id)),
    [targets],
  );

  const isSelectedWithId = useCallback(
    (id: string) => targetIdsSet.has(id),
    [targetIdsSet],
  );

  return {
    targets,
    isSelectedWithId,
    setAll,
    removeAll,
    toggleTarget,
    toggleAllTargets,
  };
};

type HooksReturnType = ReturnType<typeof useCollectBatchActionTargets>;

export type BatchActionDispatchers = Omit<HooksReturnType, "targets">;
export type BatchActionState = HooksReturnType["targets"];
