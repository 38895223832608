import { IconSearch } from "@studyplus/boron-ui";
import * as React from "react";
import Input, { InputLeftIconGroup } from "../../../../components/atoms/Input";
import Loader from "../../../../components/atoms/Loader";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import { useScrollWatcher } from "../../../../hooks/useScrollWatcher";
import { useThrottle } from "../../../../hooks/useThrottle";
import { DrillLearningMaterialValue } from "../../useStudyTaskForm";
import { DrillLearningMaterialRow } from "./DrillLearningMaterialRow";
import { EmptyDrillLearningMaterial } from "./EmptyDrillLearningMaterial";
import {
  DrillSearchResponseItem,
  useFetchDrillLearningMaterials,
} from "./useFetchDrillLearningMaterials";

type Props = {
  sectionId: string;
  selectedDrill: DrillLearningMaterialValue | null;
  selectDrillAndAllContents: (drill: DrillSearchResponseItem) => void;
  openContentSelector: (drill: DrillSearchResponseItem) => void;
};

export const DrillLearningMaterialSelector = ({
  sectionId,
  selectedDrill,
  selectDrillAndAllContents,
  openContentSelector,
}: Props) => {
  const [keyword, setKeyword] = React.useState<string>("");
  const onChangeKeyword = useThrottle(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setKeyword(e.target.value);
    },
    500,
  );

  const {
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data,
    error,
  } = useFetchDrillLearningMaterials({ sectionId, keyword });
  useQueryError(error);

  const drills = data?.pages.flatMap((page) => page.data) || [];

  const onScroll = useScrollWatcher({
    onReachToBottom: () => {
      if (hasNextPage) {
        fetchNextPage();
      }
    },
  });

  return (
    <div className="flex h-full flex-col flex-nowrap justify-start">
      <div className="leading-[1.4rem]">デジタル教材を検索できます</div>
      <div className="mt-7">
        <InputLeftIconGroup>
          <IconSearch className="!top-[0.9rem] text-gray-600" />
          <Input
            className="h-7 text-black"
            placeholder="教材を検索"
            onChange={onChangeKeyword.throttledExecute}
          />
        </InputLeftIconGroup>
      </div>
      <div className="mt-8 text-md font-bold leading-5 text-black">
        デジタル教材
      </div>
      <div
        role="table"
        className="mt-4 box-border flex w-full flex-shrink flex-grow-0 flex-col flex-nowrap justify-start divide-x-0 divide-y divide-solid divide-gray-400 overflow-y-scroll rounded border border-solid border-gray-400"
        onScroll={onScroll}
      >
        <Loader loading={isLoading} />
        {!isLoading && drills.length === 0 && (
          <EmptyDrillLearningMaterial keyword={keyword} />
        )}
        {drills.map((drill) => {
          return (
            <DrillLearningMaterialRow
              key={drill.publicId}
              drill={drill}
              selectDrillAndAllContents={selectDrillAndAllContents}
              openContentSelector={openContentSelector}
              disabledButton={selectedDrill?.publicId === drill.publicId}
            />
          );
        })}
        <Loader loading={isFetchingNextPage} />
      </div>
    </div>
  );
};
