import { Link } from "react-router-dom";
import Loader from "../../../components/atoms/Loader";
import {
  HeatMapTd,
  NoTextTd,
  Table,
  Td,
  Th,
  TitleTd,
} from "../../../components/features/AnalyticsTable";
import { Text } from "../../../components/general/Text";
import SortLabel from "../../../components/molecules/SortLabel";
import {
  calculateDeviation,
  toHeaderDate,
} from "../../../helpers/AnalyticsHelper";
import { AnalyticsFilter } from "../../../hooks/filters/useAnalyticsFilter";
import { AttendanceRate } from "./AttendanceRate";
import styles from "./LectureAnalyticsTable.scss";
import { LectureSessionCount } from "./LectureSessionCount";
import { useFetchLectureAnalytics } from "./useFetchLectureAnalytics";

type Props = {
  sectionId: string;
  analyticsFilter: AnalyticsFilter;
  changeOrder: (order: string | null) => void;
};
export const LectureAnalyticsTable = ({
  sectionId,
  analyticsFilter,
  changeOrder,
}: Props) => {
  const {
    isFetching,
    isFetchingNextPage,
    analyticsTableColumns,
    analyticsTableRows,
    allScores,
  } = useFetchLectureAnalytics({
    sectionId: sectionId,
    analyticsFilter,
  });

  if (!analyticsFilter || (isFetching && !isFetchingNextPage))
    return <Loader loading />;

  if (analyticsTableRows?.length === 0) return <NoLecturesFoundText />;

  return analyticsTableColumns && analyticsTableRows && allScores ? (
    <>
      <div className={styles.LectureAnalyticsTable}>
        <Table>
          <thead>
            <tr>
              <Th colSpan={2}>講座名</Th>
              <Th>
                <SortLabel
                  label="出席率"
                  active={!analyticsFilter.order}
                  orderDirection={analyticsFilter.orderDir || "desc"}
                  onClick={() => changeOrder(null)}
                />
              </Th>
              {analyticsTableColumns.map((column) => (
                <Th key={`section-header-${column}`}>
                  <SortLabel
                    label={toHeaderDate(column, analyticsFilter.term)}
                    active={analyticsFilter.order === column}
                    orderDirection={analyticsFilter.orderDir || "desc"}
                    onClick={() => changeOrder(column)}
                  />
                </Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {analyticsTableRows.map((row) => (
              <tr key={`section-row-${row.rank}`}>
                <NoTextTd>{row.rank}</NoTextTd>
                <TitleTd>
                  <Link
                    to={{
                      pathname: `/sections/${sectionId}/analytics/lectures/${row.id}/students`, // 仮
                      search: window.location.search,
                    }}
                    className={styles.lectureName}
                  >
                    {row.name}
                  </Link>
                </TitleTd>
                <Td>
                  <AttendanceRate rate={row.totalScore} />
                </Td>
                {row.scores.map((score, i) => (
                  <HeatMapTd
                    key={`section-column-${row.rank}-${i}`}
                    score={score}
                    deviation={calculateDeviation(allScores, score)}
                  >
                    <LectureSessionCount
                      attendanceRate={score}
                      lectureSessionCount={row.lectureSessionCount[i]}
                    />
                  </HeatMapTd>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Loader loading={isFetchingNextPage} />
    </>
  ) : null;
};

const NoLecturesFoundText = () => {
  return (
    <Text as="div" align="center">
      <span className={styles.NoLecturesFoundText}>
        講座別の出席記録がありません。
      </span>
    </Text>
  );
};
