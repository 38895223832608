import { useInfiniteQuery } from "@tanstack/react-query";
import SectionGuardianMessageThreadApi from "../../api-clients/SectionGuardianMessageThreadApi";
import { HTTPErrors, createError } from "../../errors";
import { toQueryObject } from "../../helpers/FiltersHelper";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import ApiResponse from "../../interfaces/ApiResponse";
import { FiltersQueryInterface } from "../../interfaces/FiltersInterface";
import GuardianMessageThreadInterface from "../../interfaces/GuardianMessageThreadInterface";

type ResponseData = ApiResponse<GuardianMessageThreadInterface[]>;

type Props = {
  sectionId: string;
  query?: FiltersQueryInterface;
};

export const useFetchSectionsGuardianMessageThreads = ({
  sectionId,
  query,
}: Props) => {
  const queryParams = query ? toQueryObject(query) : {};
  const { data, ...result } = useInfiniteQuery<ResponseData, HTTPErrors>({
    queryKey: cacheKeyOf(sectionId, queryParams),
    queryFn: async ({ pageParam }) => {
      const response =
        await SectionGuardianMessageThreadApi.getGuardianMessageThreads(
          sectionId,
          {
            query: {
              ...queryParams,
              page: pageParam,
            },
          },
        );
      if (response.ok) {
        const json = await response.json();
        return json.guardianMessageThreads;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.pages.flatMap((page) => page.data),
    meta: data?.pages[0].meta,
    ...result,
  };
};

export const cacheKeyOf = (sectionId: string, query?: { [key: string]: any }) =>
  [sectionId, "guardian_message_threads", query].filter((k) => k !== undefined);
