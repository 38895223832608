import { useEffect, useState } from "react";
import { useUpdateMessageThreadLatestMessage } from "../../hooks/http/useFetchMessageThreads.ts";
import MessageThreadInterface from "../../interfaces/MessageThreadInterface.ts";

export const useOpenThread = ({
  sectionId,
  messageThreads,
}: {
  sectionId: string;
  messageThreads: MessageThreadInterface[];
}) => {
  const [openedThreadId, setOpenedThreadId] = useState<string | null>(null);

  const { updateMessageThreadLatestMessageByStudentId } =
    useUpdateMessageThreadLatestMessage({
      sectionId,
    });

  // メッセージスレッドが取得できたら最初のスレッドを開く
  useEffect(() => {
    if (openedThreadId === null && messageThreads.length > 0) {
      openThread(messageThreads[0]);
    }
  }, [messageThreads.map((thread) => thread.id).join(",")]);

  const openedThread =
    messageThreads.find((thread) => thread.id === openedThreadId) || null;

  const openThread = (thread: MessageThreadInterface) => {
    setOpenedThreadId(thread.id);
    // スレッドを開いたら既読にする
    updateMessageThreadLatestMessageByStudentId(thread.student.id, {
      lastReadAt: new Date().toISOString(),
    });
  };

  return {
    openedThread,
    openThread,
  };
};
