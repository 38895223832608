import * as React from "react";
import { FilterParams } from "../../../containers/pages/SectionsSettingsManagementLearningMaterialsPage/useManagementLearningMaterialApi";
import { useLearningMaterialFilterContext } from "../../../containers/pages/StudentsLearningMaterialTagAnalyticsTimePage/useLearningMaterialFilterContext";
import {
  LearningMaterialTag,
  LearningMaterialTagList,
  toTagParams,
} from "../../../domains/LearningMaterialTag";
import { useOnLocationChange } from "../../../helpers/RouterHelper";
import { usePushHistory } from "../../../hooks/usePushHistory";
import Loader from "../../atoms/Loader";
import { DeprecatedTagButtonProps } from "../DeprecatedTagButton";
import { FilterWrapper } from "../FilterWrapper";
import { SelectedLearningMaterialTagButton } from "../LearningMaterialTagButton";
import { LearningMaterialTagSelector } from "../LearningMaterialTagSelector";
import {
  TagFilterDropdown,
  useTagFilterDropdown,
} from "../TagFilter/TagFilterDropDown";
import { TagFilterOpenButton } from "../TagFilter/TagFilterOpenButton";
import {
  isWithLocalStorageContextType,
  useLearningMaterialFilterWithLocalStorageContext,
} from "./useLearningMaterialFilterWithLocalStorageContext";

type Props = {
  context:
    | ReturnType<typeof useLearningMaterialFilterContext>
    | ReturnType<typeof useLearningMaterialFilterWithLocalStorageContext>;
  tags: LearningMaterialTagList;
  sectionId: string;
  label?: string;
  colorType?: DeprecatedTagButtonProps["colorType"];
  variants?: DeprecatedTagButtonProps["variants"];
  isInitializeByQueryParams?: boolean;
  isUseLocalStorage?: boolean;
  defaultOptions?: LearningMaterialTag[];
};

export const LearningMaterialFilter = (props: Props) => {
  return <FilterWrapper LearningMaterialTagFilter={<TagFilter {...props} />} />;
};

export const TagFilter = ({
  context,
  tags,
  sectionId,
  label,
  colorType,
  variants,
  isInitializeByQueryParams = true,
  isUseLocalStorage = true,
  defaultOptions,
}: Props) => {
  const { selectedTags, onSelect, onDeselect, isInitialized } = useTagFilter({
    context,
    sectionId,
    tags,
    isInitializeByQueryParams,
    isUseLocalStorage,
    defaultOptions,
  });

  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const { isOpened, close, open } = useTagFilterDropdown({
    buttonRef,
    dropdownRef,
    containerRef,
  });

  if (!isInitialized) {
    return <Loader loading={true} />;
  }

  return (
    <>
      {selectedTags.map((tag: LearningMaterialTag) => (
        <SelectedLearningMaterialTagButton
          key={`selected-tag-${tag.id}`}
          tag={tag}
          isDeselectable={true}
          onClick={() => onDeselect(tag)}
        />
      ))}
      <div ref={containerRef}>
        <TagFilterOpenButton
          onClick={isOpened ? close : open}
          buttonRef={buttonRef}
          label={label}
          colorType={colorType}
          variants={variants}
        />
      </div>
      <TagFilterDropdown
        opened={isOpened}
        dropdownRef={dropdownRef}
        onClose={close}
      >
        <LearningMaterialTagSelector
          sectionId={sectionId}
          tags={tags}
          selectedTags={selectedTags}
          onSelect={onSelect}
          onDeselect={onDeselect}
        />
      </TagFilterDropdown>
    </>
  );
};

const useTagFilter = ({
  context,
  sectionId,
  tags,
  isInitializeByQueryParams = true,
  isUseLocalStorage = true,
  defaultOptions,
}: Pick<
  Props,
  | "sectionId"
  | "tags"
  | "isInitializeByQueryParams"
  | "isUseLocalStorage"
  | "defaultOptions"
> & { context: any }) => {
  React.useEffect(() => {
    context.setIsInitialized(false);
    context.setIsTagLoaded(false);
  }, []);
  React.useEffect(() => {
    if (isWithLocalStorageContextType(context)) {
      context.setFilterKey(sectionId);
    }
  }, [isUseLocalStorage, sectionId]);

  React.useLayoutEffect(() => {
    if (!context.isTagLoaded) return;
    if (context.isInitialized) return;

    if (isInitializeByQueryParams) {
      // URLパラメータを優先して初期化する
      const isInitializedByQuery = context.initializeWithQuery(
        tags as LearningMaterialTag[],
      );
      if (isInitializedByQuery) return;

      // URLパラメータで初期化しなかった場合はローカルストレージから初期化する
      if (isWithLocalStorageContextType(context)) {
        context.initializeWithStorage();
      } else if (defaultOptions) {
        // URLパラメータやローカルストレージで初期化しない場合は、デフォルトのオプションで初期化する
        context.initializeWithOptions(defaultOptions);
      }
    }
  }, [
    context.isTagLoaded,
    context.isInitialized,
    isInitializeByQueryParams,
    tags,
    isUseLocalStorage,
    sectionId,
    defaultOptions,
  ]);

  // フィルター変更でURLパラメータが変更されたときのステート更新
  useOnLocationChange(() => {
    context.updateFilterByQuery(tags as LearningMaterialTag[]);
  });

  const { pushHistory } = usePushHistory<Partial<FilterParams>>();
  const onSelect = (tag: LearningMaterialTag) => {
    const { learning_material_tag_ids, have_no_tag } = toTagParams({
      tags: [...context.items, tag],
    });
    pushHistory({ learning_material_tag_ids, have_no_tag });
  };

  const onDeselect = (tag: LearningMaterialTag) => {
    const nextSelectedTags = context.items.filter(
      (t: LearningMaterialTag) => t.id !== tag.id,
    );
    const haveNoTag = nextSelectedTags.some(
      (tag: LearningMaterialTag) => tag.tagType === "have_no_tag",
    );
    pushHistory({
      learning_material_tag_ids: nextSelectedTags
        .filter((tag: LearningMaterialTag) => tag.id !== "have_no_tag")
        .map((tag: LearningMaterialTag) => tag.id),
      have_no_tag: haveNoTag ? haveNoTag.toString() : undefined,
    });
  };

  return {
    selectedTags: context.items,
    onSelect,
    onDeselect,
    isInitialized: context.isInitialized,
  };
};
