import { useLayoutEffect, useRef } from "react";
import * as React from "react";
import nl2br from "react-nl2br";
import reactStringReplace from "react-string-replace";
import CircleIcon from "../../../../components/atoms/CircleIcon";
import Loader from "../../../../components/atoms/Loader";
import { RegexpHelper } from "../../../../helpers/RegexpHelper";
import TimeHelper from "../../../../helpers/TimeHelper";
import SectionInterface from "../../../../interfaces/SectionInterface";
import {
  GuardianAnnounceMessage,
  useFetchGuardianAnnounceMessages,
} from "../useFetchGuardianAnnounceMessages";
import styles from "./styles.scss";

interface Props {
  tagId: string;
  section: SectionInterface;
}

const GuardianAnnounceMessageList: React.FC<Props> = ({
  tagId,
  section,
}: Props) => {
  const scroller = useRef<HTMLDivElement | null>(null);
  const scrollerHeight = useRef<number>(0);
  const loadedMessages = useRef<GuardianAnnounceMessage[]>();

  const {
    messages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useFetchGuardianAnnounceMessages({ sectionId: section.id, tagId });

  useLayoutEffect(() => {
    const initializing = loadedMessages.current === null;
    if (scroller.current && initializing) {
      scroller.current.scrollTop = scroller.current.scrollHeight;
    }
    if (scroller.current && !initializing) {
      if (
        loadedMessages.current &&
        loadedMessages.current[0] &&
        messages &&
        messages[0] &&
        loadedMessages.current[0].id === messages[0].id
      ) {
        // 追加読込を行った時
        scroller.current.scrollTop =
          scroller.current.scrollHeight - scrollerHeight.current;
      } else {
        // 新しいメッセージを送信した時
        scroller.current.scrollTop = scroller.current.scrollHeight;
      }
    }
    if (scroller.current) {
      scrollerHeight.current = scroller.current.scrollHeight;
    }
    loadedMessages.current = messages;
  }, [messages?.map((m) => m.id).join(",")]);

  if (isLoading) {
    return (
      <div className={styles.listContainer}>
        <Loader loading />
      </div>
    );
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!isLoading && hasNextPage && e.currentTarget.scrollTop === 0) {
      fetchNextPage();
    }
  };

  return (
    <div
      className={styles.listContainer}
      ref={scroller}
      onScroll={handleScroll}
    >
      <div className={styles.list} aria-label="メッセージ一覧" role="list">
        {messages &&
          messages.map((message) => (
            <AnnounceMessageListItem
              message={message}
              section={section}
              key={`GuardianAnnounceMessageListItem-${message.id}`}
            />
          ))}
        <Loader loading={isFetchingNextPage} />
      </div>
    </div>
  );
};

const AnnounceMessageListItem = (props: {
  message: GuardianAnnounceMessage;
  section: SectionInterface;
}) => {
  const { message, section } = props;
  const { sentAt } = message;

  return (
    <div className={styles.message} role="listitem">
      <div className={styles.message__header}>
        <CircleIcon
          iconProps={{ name: "icon-account", iconClassName: styles.icon }}
          className={styles.iconWrapper}
        />
        <span className={styles.name}>{section.fullName}</span>
        <time className={styles.datetime}>{TimeHelper.fullFormat(sentAt)}</time>
      </div>
      {message.imageUrl ? (
        <MessageImage imageUrl={message.imageUrl} />
      ) : (
        <MessageText content={message.content} />
      )}
    </div>
  );
};

const MessageImage = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <a href={imageUrl} target="_blank" rel="noopener noreferrer">
      <img src={imageUrl} className={styles.image} />
    </a>
  );
};

const MessageText = ({ content }: { content: string }) => {
  return (
    <p className={styles.message__content}>
      {reactStringReplace(
        nl2br(content),
        RegexpHelper.URL,
        (match: string, index: number): React.ReactNode => (
          <a href={match} target="_blank" rel="noopener noreferrer" key={index}>
            {match}
          </a>
        ),
      )}
    </p>
  );
};

export default GuardianAnnounceMessageList;
