import * as React from "react";
import { connect } from "react-redux";
import { toggleNavigation } from "../actions/toggleNavigation";
import AppStateInterface from "../interfaces/AppStateInterface";

interface Props {
  presentationalComponent: React.JSXElementConstructor<PresentationalComponentProps>;
  toggleNavigation: () => void;
  navigationOpened: boolean;
}
export type PresentationalComponentProps = {
  opened: boolean;
  onToggle: (e: any) => void;
};

class NaviMenuToggle extends React.Component<Props, any> {
  handleToggle = () => {
    this.props.toggleNavigation();
  };

  render() {
    return (
      <this.props.presentationalComponent
        onToggle={this.handleToggle}
        opened={this.props.navigationOpened}
      />
    );
  }
}

const mapStateToProps = (state: AppStateInterface) => ({
  navigationOpened: state.navigationOpened,
});

const actions = {
  toggleNavigation,
};

const NaviMenuToggleContainer = connect(
  mapStateToProps,
  actions,
)(NaviMenuToggle);

export default NaviMenuToggleContainer;
