import classNames from "classnames";
import * as React from "react";
import { Link, Location } from "react-router-dom";
import Icon from "../../Icon";
import styles from "./styles.scss";

export interface Props extends React.PropsWithChildren<unknown> {
  to?: Location | string;
  onClick?: any;
  iconName?: string;
  theme?: "main" | "gray";
  isActive?: boolean;
  isDisabled?: boolean;
  hovered?: boolean;
}

const Item = (props: Props) => {
  return (
    <li
      className={classNames(styles.root, {
        [styles.main]: props.theme === "main",
        [styles.gray]: props.theme === "gray",
      })}
    >
      {renderLink(props)}
    </li>
  );
};

const renderLink = (props: Props) => {
  return (
    <Link
      to={props.to || "#"}
      onClick={props.isDisabled ? undefined : props.onClick}
      className={classNames(styles.link, {
        [styles.active]: props.isActive,
        [styles.disabled]: props.isDisabled,
        [styles.hovered]: props.hovered,
      })}
    >
      <div className={styles.content}>
        {props.iconName ? (
          <Icon
            name={props.iconName}
            className={classNames(styles.icon, {
              [styles.gray]: props.theme === "gray",
              [styles.main]: props.theme === "main",
            })}
          />
        ) : null}
        {props.children}
      </div>
    </Link>
  );
};

export default Item;
