import * as React from "react";
import { Link } from "react-router-dom";
import {
  BreadcrumbItem,
  Breadcrumbs,
} from "../../components/general/Breadcrumbs";
import { SectionView } from "../../components/general/SectionView";
import TabsNavi from "../../components/molecules/TabsNavi";
import enhanceSectionPage from "../../hocs/enhanceSectionsPage";
import styles from "./SectionsLectureSessionDetailPage.scss";

import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import SectionInterface from "../../interfaces/SectionInterface";
import { Attendees } from "./Attenndees";
import { LectureSessionSummary } from "./LectureSessionSummary";
import { PageInfoProvider, usePageInfo } from "./PageInfoContext";
import { Questionnaires } from "./Questionnaires/Questionnaires";

type Props = OutletContextProps & {
  section: SectionInterface;
};
const SectionsLectureSessionDetailPage_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("schedule_list");
  }, []);
  const section = props.section;
  const pageInfo = usePageInfo();
  return (
    <PageInfoProvider value={pageInfo}>
      <div className={styles.BreadCrumbWrapper}>
        <SectionScheduleDetailBreadCrumb sectionId={section.id} />
      </div>
      <div className={styles.SummaryDetailWrapper}>
        <LectureSessionSummary
          sectionId={section.id}
          lectureSessionId={pageInfo.lectureSessionId}
        />
      </div>
      <div className={styles.ContentWrapper}>
        <SectionView>
          <div className={styles.ContentTabWrapper}>
            <TabsNavi
              border="none"
              tabs={[
                {
                  label: "出席記録",
                  link: {
                    pathname: `/sections/${section.id}/lecture_sessions/${pageInfo.lectureSessionId}/attendees`,
                  },
                  active: pageInfo.featureType === "attendees",
                },
                {
                  label: "アンケート",
                  link: {
                    pathname: `/sections/${section.id}/lecture_sessions/${pageInfo.lectureSessionId}/questionnaires`,
                  },
                  active: pageInfo.featureType === "questionnaires",
                },
              ]}
            />
          </div>
          {pageInfo.featureType === "attendees" && (
            <Attendees
              section={section}
              lectureSessionId={pageInfo.lectureSessionId}
            />
          )}
          {pageInfo.featureType === "questionnaires" && (
            <Questionnaires
              section={section}
              lectureSessionId={pageInfo.lectureSessionId}
            />
          )}
        </SectionView>
      </div>
    </PageInfoProvider>
  );
};

const breadCrumbItems: (sectionId: string) => BreadcrumbItem[] = (
  sectionId: string,
) => {
  return [
    { label: "校舎予定リスト", url: `/sections/${sectionId}/schedules/list` },
    { label: "講座出席詳細" },
  ];
};
const SectionScheduleDetailBreadCrumb = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  return (
    <Breadcrumbs items={breadCrumbItems(sectionId)}>
      {(item, isLastItem) =>
        isLastItem ? (
          <div>{item.label}</div>
        ) : (
          <Link to={item.url ?? "/"}>{item.label}</Link>
        )
      }
    </Breadcrumbs>
  );
};

const pageInfo = {
  title: "講座出席詳細",
};
export const SectionsLectureSessionDetailPage = withRouter(
  enhanceSectionPage(SectionsLectureSessionDetailPage_, pageInfo),
);
