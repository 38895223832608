import * as React from "react";
import { BlockRow } from "../../components/atoms/BlockRow";
import { BreadcrumbsView } from "../../components/general/BreadcrumbsView/BreadcrumbsView";
import { OutletContextProps } from "../../helpers/RouterHelper";
import enhanceSectionsSettingsPage, {
  SectionsSettingsPageProps,
} from "../../hocs/enhanceSectionsSettingsPage";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useFetchSectionSettings } from "../../hooks/http/useSectionSettingsApi";
import { OperatorPrivilege } from "../../interfaces/OperatorInterface";
import SectionInterface from "../../interfaces/SectionInterface";
import { StayLineSettingsToggleForm } from "./StayLineSettingsToggleForm";
import { StayLocationSettingsToggleForm } from "./StayLocationSettingsToggleForm";

type Props = { section: SectionInterface } & SectionsSettingsPageProps &
  OutletContextProps;

const breadcrumbsProps = (sectionId: SectionInterface["id"]) => {
  return {
    items: [
      {
        label: "入退室記録",
        url: `/sections/${sectionId}/settings/stays`,
      },
      { label: "入退室設定" },
    ],
  };
};

const Page = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("stays");
  }, []);
  const section = props.section;
  const { data } = useSectionsSettingsStaysDetailsPage({
    sectionId: section.id,
  });

  return (
    <>
      <BreadcrumbsView {...breadcrumbsProps(section.id)} />
      <BlockRow marginTop="2rem">
        <StayLineSettingsToggleForm
          sectionId={section.id}
          value={data?.isEnabledStayNotification}
        />
      </BlockRow>
      <BlockRow marginTop="2rem">
        <StayLocationSettingsToggleForm
          sectionId={section.id}
          value={data?.shouldCheckStayLocation}
        />
      </BlockRow>
    </>
  );
};

type HooksProps = {
  sectionId: string;
};
const useSectionsSettingsStaysDetailsPage = ({ sectionId }: HooksProps) => {
  const { data, error } = useFetchSectionSettings({ sectionId });
  useQueryError(error);

  return {
    data,
  };
};

const pageInfo = {
  title: "入退室設定",
};

export const requiredPrivileges: OperatorPrivilege[] = [
  "stay:index",
  "section:settings",
  "buildings",
];

export const SectionsSettingsStaysDetailsPage = enhanceSectionsSettingsPage(
  Page,
  pageInfo,
  requiredPrivileges,
);
