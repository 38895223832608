import { useNavigate } from "react-router";
import SwitchTabs from "../../components/molecules/SwitchTabs";
import { LectureAttendanceAnalyticsType } from "./index";

type Props = {
  sectionId: string;
  currentAnalyticsType: LectureAttendanceAnalyticsType;
};
export const LectureAttendanceAnalyticsSwitcher = ({
  sectionId,
  currentAnalyticsType,
}: Props) => {
  const navigate = useNavigate();
  return (
    <SwitchTabs
      tabs={[
        {
          id: "student",
          label: "生徒別",
          active: currentAnalyticsType === "student",
        },
        {
          id: "lecture",
          label: "講座別",
          active: currentAnalyticsType === "lecture",
        },
      ]}
      onClickTab={(tab) => () => {
        const url =
          tab.id === "student"
            ? `/sections/${sectionId}/analytics/lecture_attendance/student${location.search}`
            : `/sections/${sectionId}/analytics/lecture_attendance/lecture${location.search}`;
        navigate(url);
      }}
    />
  );
};
