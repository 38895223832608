import * as React from "react";
import { SectionsSettingsPageProps } from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";
import { useMutateVideo } from "./useContentVideoApi";
import { usePrepareUserInput } from "./usePrepareUserInput";

type Props = {
  section: SectionInterface;
  showSuccessFlashMessage: (message: string) => void;
  showErrorFlashMessage: (message: string) => void;
  onNotFoundContent: () => void;
  onForbiddenContent: () => void;
  contentId: string | null;
  navigate: SectionsSettingsPageProps["navigate"];
};

export const useIndex = (props: Props) => {
  const sectionId = props.section.id;
  const isNew = props.contentId ? false : true;
  const { mutate: handleSubmit, isLoading } = useMutateVideo({
    sectionId,
    onSuccess: () => {
      props.showSuccessFlashMessage(
        isNew ? "動画を登録しました" : "コンテンツを編集しました",
      );
      props.navigate(`/sections/${sectionId}/settings/contents`);
    },
    onError: (error) => {
      props.showErrorFlashMessage(error);
    },
  });

  // 新規・編集を判定して、編集の場合は content リクエスト後、入力可能状態かステータスで知らせる
  const contentState = usePrepareUserInput({
    section: props.section,
    contentId: props.contentId,
  });

  React.useEffect(() => {
    if (contentState.status === "404ContentError") {
      props.onNotFoundContent();
    }
    if (contentState.status === "403ContentError") {
      props.onForbiddenContent();
    }
    if (contentState.status === "FetchContentError") {
      props.showErrorFlashMessage(HTTP_ERROR_MESSAGE);
    }
  }, [contentState.status]);

  return {
    ...contentState,
    handleSubmit,
    isLoading,
    isNew,
  };
};
