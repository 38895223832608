import { Button } from "@studyplus/boron-ui";
import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import Icon from "../../../components/atoms/Icon";
import LinkButton from "../../../components/atoms/LinkButton";
import Loader from "../../../components/atoms/Loader";
import {
  AdjustIconPositionWrapper,
  Classroom,
  Locale,
  Note,
  ScheduleTime,
  ScheduleTitle,
  Stack,
  StudySchedule,
  Teacher,
  Title,
} from "../../../components/features/Schedule/DetailModalParts";
import { ColorDot } from "../../../components/general/ColorDot";
import { Text } from "../../../components/general/Text";
import { LectureSession } from "../../../domains/LectureSession";
import { Schedule, hasStudySchedule } from "../../../domains/Schedule";
import { SectionSchedule } from "../../../domains/SectionSchedule";
import { Colors } from "../../../helpers/styles/Colors";
import SectionInterface from "../../../interfaces/SectionInterface";
import styles from "./SectionScheduleDetail.scss";
import { useSectionScheduleDetail } from "./useSectionScheduleDetail";

type SectionScheduleDetailViewProps = {
  section: SectionInterface;
  sectionSchedule?: SectionSchedule;
};
export const SectionScheduleDetailView = ({
  section,
  sectionSchedule,
}: SectionScheduleDetailViewProps) => {
  const {
    loading,
    members,
    teacher,
    classroom,
    lectureSummaryInfo,
    lectureTitleText,
    memberSummary,
    sectionScheduleDetail,
  } = useSectionScheduleDetail({
    section,
    sectionSchedule,
  });

  if (loading || !sectionScheduleDetail) {
    return <Loader loading />;
  }
  const { schedule, lectureSession } = sectionScheduleDetail;
  const { scheduleColor } = prepareSectionScheduleProps(sectionScheduleDetail);

  return (
    <div className={styles.detailWrapper}>
      <Stack>
        <ScheduleTitle>
          <ColorDot backgroundColor={scheduleColor} />
          <Title>
            <Text size="xl">{schedule.summary}</Text>
          </Title>
        </ScheduleTitle>
        <ScheduleTime
          startAt={schedule.startAt}
          endAt={schedule.endAt}
          allday={schedule.allday}
          recurrence={null}
        />
        <Members
          summaryInfoView={
            memberSummary ? (
              <Text as="span" color="black">
                {memberSummary}
              </Text>
            ) : null
          }
        >
          <MemberList>
            {members.map((member) => (
              <Member key={`section-schedule-member-${member.id}`}>
                <Text lineClamp={1}>{member.fullName}</Text>
              </Member>
            ))}
          </MemberList>
        </Members>
        <StudySchedule studySchedule={schedule.studySchedule} />
        <Classroom>{classroom}</Classroom>
        <Teacher>{teacher}</Teacher>
        <Lecture>
          <LectureTitle>
            <Text bold>{lectureTitleText}</Text>
          </LectureTitle>
          <LectureMemberSummary>
            {lectureSummaryInfo && (
              <>
                <Text bold>{lectureSummaryInfo}</Text>{" "}
                {lectureSession && (
                  <Link
                    to={`/sections/${section.id}/lecture_sessions/${lectureSession.id}/attendees`}
                  >
                    <Button variant="outline">講座出席詳細</Button>
                  </Link>
                )}
              </>
            )}
          </LectureMemberSummary>
        </Lecture>
        <Locale>{schedule.locale}</Locale>
        <Note>{schedule.description}</Note>
      </Stack>
    </div>
  );
};

const prepareSectionScheduleProps = (sectionSchedule: SectionSchedule) => {
  return {
    scheduleColor: determineScheduleColor(
      sectionSchedule.lectureSession,
      sectionSchedule.schedule,
    ),
  };
};
export const determineScheduleColor = (
  lectureSession: LectureSession | null,
  schedule: Schedule,
): Colors => {
  if (lectureSession?.canceled) {
    return "gray-darken-1";
  }
  return hasStudySchedule(schedule) ? "primary" : "yellow";
};

type MembersProps = {
  summaryInfoView: React.ReactNode;
};
const Members = ({
  summaryInfoView,
  children,
}: React.PropsWithChildren<MembersProps>) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <div className={styles["u-flex-align-center"]}>
        <AdjustIconPositionWrapper>
          <Icon name="icon-students" color="gray-darken-1" />
        </AdjustIconPositionWrapper>
        {summaryInfoView && (
          <LinkButton onClick={() => setIsOpen(!isOpen)}>
            <h3 className={styles.MembersLabel__summary}>{summaryInfoView}</h3>
            {isOpen ? (
              <Icon name="icon-arrow-up" color="gray-darken-1" />
            ) : (
              <Icon name="icon-arrow-down" color="gray-darken-1" />
            )}
          </LinkButton>
        )}
      </div>
      {isOpen && <div className={styles.Members}>{children}</div>}
    </>
  );
};

const MemberList = ({ children }: React.PropsWithChildren<unknown>) => (
  <div className={styles.MemberList}>{children}</div>
);

const Member = ({ children }: React.PropsWithChildren<unknown>) => (
  <div className={classNames(styles["u-flex-align-center"], styles.Member)}>
    <Icon name="icon-notice-user" color="pink" />
    <div className={styles.Member__name}>{children}</div>
  </div>
);

const Lecture = ({ children }: React.PropsWithChildren<unknown>) => {
  return <div>{children}</div>;
};
const LectureTitle = ({ children }: React.PropsWithChildren<unknown>) => (
  <div className={styles["u-flex-align-center"]}>
    <AdjustIconPositionWrapper>
      <Icon name="icon-textbooks" color="gray-darken-1" />
    </AdjustIconPositionWrapper>
    {children}
  </div>
);

const LectureMemberSummary = ({
  children,
}: React.PropsWithChildren<unknown>) =>
  children ? (
    <div className={styles.LectureMemberSummary}>{children}</div>
  ) : null;
