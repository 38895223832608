import * as React from "react";
import { connect } from "react-redux";
import Loader from "../../../components/atoms/Loader";
import { useStudentFilterContext } from "../../../components/features/NewStudentFilter/useStudentFilterContext";
import { StudentTagFilterInterface } from "../../../domains/StudentTag";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import SectionsSettingsTags from "./SectionsSettingsTags";

interface OuterProps {
  section: SectionInterface;
  tagFilter: StudentTagFilterInterface;
}
type Props = OuterProps & OutletContextProps;

const mapStateToProps = (state: AppStateInterface) => ({
  section: state.currentSection,
});

const SectionsSettingsTagsPage = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("tags");
  }, []);
  const studentFilterContext = useStudentFilterContext();
  if (!studentFilterContext.isTagLoaded) {
    return <Loader loading />;
  }

  return (
    <SectionsSettingsTags section={props.section} tagFilter={props.tagFilter} />
  );
};

const pageInfo = {
  title: "タグ",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsTagsPage,
  pageInfo,
  ["tag:index"],
);

export default connect(mapStateToProps)(EnhancedPage as any);
