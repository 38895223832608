import classNames from "classnames";
import * as React from "react";
import {
  ButtonVariants,
  Colors,
  buttonVariantDataAttributesOf,
  colorTypeDataAttributesOf,
} from "../../../helpers/Styles";
import { ButtonSpinner } from "./ButtonSpinner";

import Icon from "../Icon";
import styles from "./styles.scss";

export enum ButtonTheme {
  White,
  Gray,
  Red,
  OutlinedSuperLightGray,
  WhiteWithoutHover, // 例外Theme
}

export enum ButtonSize {
  XSmall,
  Small,
  Medium,
  Large,
}

type CustomProps = {
  width?: string; // string, like "100px" or "20rem"
  theme?: ButtonTheme;
  isBlock?: boolean;
  icon?: string;
  size?: ButtonSize;
  isRound?: boolean;
  isLoading?: boolean;
  variant?: ButtonVariants;
  colorType?: Colors;
};
type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & CustomProps;

type StyleProps = {
  style: React.CSSProperties;
};

const Button: React.FC<Props> = (props) => {
  const {
    width,
    theme,
    isBlock,
    icon,
    size,
    isRound,
    isLoading,
    variant,
    colorType,
    ...attributeProps
  } = props;

  const styleProps: StyleProps =
    typeof props.width !== "undefined" ? { style: { width } } : { style: {} };

  const classNameProps = {
    theme,
    isBlock,
    icon,
    size,
    isRound,
    isLoading,
    variant,
    colorType,
    className: props.className,
  };

  const attributes = {
    ...attributeProps,
    ...styleProps,
    type: props.type || "button",
    className: className(classNameProps),
  };

  const dataAttributes = {
    ...buttonVariantDataAttributesOf(variant),
    ...colorTypeDataAttributesOf(colorType),
  };

  return (
    <button {...attributes} {...dataAttributes}>
      {props.isLoading ? (
        <ButtonSpinner />
      ) : (
        <>
          {renderIcon(icon)}
          {props.children}
        </>
      )}
    </button>
  );
};

const renderIcon = (iconName?: string) => {
  if (iconName) {
    return <Icon name={iconName} className={styles.icon} />;
  }
};

const attachParentClassName = (parentClassName?: string) => {
  if (parentClassName) {
    return `${styles.root} ${parentClassName}`;
  } else {
    return styles.root;
  }
};

type ClassNameProps = {
  theme?: ButtonTheme;
  isBlock?: boolean;
  icon?: string;
  size?: ButtonSize;
  isRound?: boolean;
  isLoading?: boolean;
  className?: string;
  variant?: ButtonVariants;
  colorType?: Colors;
};
const className = (props: ClassNameProps): string => {
  const parentClassName = attachParentClassName(props.className);

  return classNames(parentClassName, {
    // theme
    [styles.whiteTheme]: props.theme === ButtonTheme.White,
    [styles.grayTheme]: props.theme === ButtonTheme.Gray,
    [styles.redTheme]: props.theme === ButtonTheme.Red,
    [styles.outlinedSuperLightGray]:
      props.theme === ButtonTheme.OutlinedSuperLightGray,

    // block
    [styles.block]: props.isBlock,

    // size
    [styles.xsmall]: props.size === ButtonSize.XSmall,
    [styles.small]: props.size === ButtonSize.Small,
    [styles.medium]: props.size === ButtonSize.Medium,
    [styles.large]: props.size === ButtonSize.Large,

    // round
    [styles.round]: props.isRound,
  });
};

export default Button;
