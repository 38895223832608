import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { HelpLink } from "../../components/atoms/HelpLink";
import Loader from "../../components/atoms/Loader";
import { SectionAnalyticsTypeTab } from "../../components/features/SectionAnalyticsTypeTab";
import { ColorDot } from "../../components/general/ColorDot";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import { useQueryString } from "../../hooks/useQueryString";
import SectionInterface from "../../interfaces/SectionInterface";
import { StudyTaskSwitchTabs } from "./StudyTaskSwitchTabs";
import { StudyTaskTable } from "./StudyTaskTable";
import { useFetchStudyTasks } from "./useFetchStudyTasks";
import { useStudyTaskSwitchTabsContext } from "./useStudyTaskSwitchTabs";

const helpUrl = "https://fs-help.studyplus.co.jp/ja/articles/9002641";

export type Props = OutletContextProps & { section: SectionInterface };

export type Period = "during" | "before" | "after";

const SectionsAnalyticsStudyTaskPage_ = ({ section, setActiveMenu }: Props) => {
  React.useEffect(() => {
    setActiveMenu("analytics");
  }, [setActiveMenu]);

  const { period, setPeriod } = useStudyTaskSwitchTabsContext();
  const queryParams = useQueryString();
  // setPeriodする前の状態の表示が一瞬表示されることを防ぐためにuseLayoutEffectを使う
  React.useLayoutEffect(() => {
    if (["during", "before", "after"].includes(queryParams.period)) {
      setPeriod(queryParams.period as Period);
    }
  }, [queryParams.period]);
  const navigate = useNavigate();
  // periodが変更されたらURLのクエリパラメータに反映する
  React.useEffect(() => {
    navigate({ search: `?period=${period}` }, { replace: true });
  }, [period]);

  const {
    data,
    error,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchStudyTasks(section.id, period, !!period);
  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  const studyTasks = data?.pages.flatMap((page) => page.data) ?? [];

  return (
    <>
      <SectionAnalyticsTypeTab
        sectionId={section.id}
        analyticsType="studyTask"
      />

      <div className="mt-8 flex flex-wrap justify-between">
        <div className="flex flex-grow flex-col justify-end">
          <div className="mb-5">
            <StudyTaskSwitchTabs period={period} setPeriod={setPeriod} />
          </div>
        </div>
        <div className="flex flex-grow flex-col flex-nowrap items-end">
          <Link
            className="buiButtonBase buiButtonVariantFilled"
            to={`/sections/${section.id}/analytics/study_task/new`}
          >
            課題の登録
          </Link>

          <div className="mt-4">
            <div className="flex flex-initial flex-wrap items-center justify-end gap-x-4 text-md">
              <div>
                <ColorDot className="mr-1 bg-blue-300" />
                完了
              </div>
              <div>
                <ColorDot className="mr-1 bg-green-300" />
                学習中
              </div>
              <div>
                <ColorDot className="mr-1 bg-gray-400" />
                未学習
              </div>
              <div>
                <HelpLink to={helpUrl} className="h-3 w-3" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-1">
        {!isLoading && (
          <StudyTaskTable
            sectionId={section.id}
            period={period}
            studyTasks={studyTasks}
          />
        )}
        <Loader loading={isLoading || isFetchingNextPage} />
      </div>
    </>
  );
};

const pageInfo = {
  title: "アナリティクス",
};
export const SectionsAnalyticsStudyTaskPage = withRouter(
  enhanceSectionsPage(SectionsAnalyticsStudyTaskPage_, pageInfo),
);
