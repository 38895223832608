import { boronClient } from "../../../../api";
import { useBoronMutation } from "../../../../hooks/http/useBoronMutation";

export const buildKey = (sectionId: string) => ["deleteTag", sectionId];

export const useDeleteTag = ({ sectionId }: { sectionId: string }) => {
  return useBoronMutation(
    async ({ tagId }: { tagId: string }) =>
      await boronClient.DELETE(
        "/api/v1/sections/{section_id}/tags/{tag_hashid}",
        {
          params: { path: { section_id: sectionId, tag_hashid: tagId } },
        },
      ),
    {
      mutationKey: buildKey(sectionId),
    },
  );
};
