import { Chart, ChartConfiguration, registerables } from "chart.js";
import * as React from "react";

interface Props {
  ratio: number;
  options: {
    primaryColor: string;
    textColor: string;
  };
}

interface CenterTextInterface {
  text: string;
  color: string;
}

class DoughnutChart extends React.Component<Props> {
  chart: Chart<"doughnut"> | null = null;
  chartRef: React.RefObject<any> = React.createRef();

  drawCenterTextPlugin: any = (chart: Chart) => {
    const pluginOption = {
      centerText: {
        text: `${Math.floor(this.props.ratio * 100)}`,
        color: this.props.options.textColor,
      },
    };

    drawCenterText(chart, pluginOption);
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const ctx = this.chartRef.current.getContext("2d");
    Chart.register(...registerables);

    const { primaryColor } = this.props.options;
    const baseColor = "#EBECF0";
    const config: ChartConfiguration<"doughnut", number[]> = {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [this.props.ratio, 1.0 - this.props.ratio],
            backgroundColor: [primaryColor, baseColor],
            hoverBackgroundColor: [primaryColor, baseColor],
            borderWidth: 0,
          },
        ],
      },
      options: {
        aspectRatio: 2 / 1,
        cutout: "90%",
        plugins: {
          tooltip: { enabled: false },
          legend: { display: false },
        },
      },
      plugins: [
        {
          id: "chartjs-draw-center-text",
          beforeDraw: this.drawCenterTextPlugin,
        },
      ],
    };

    this.chart = new Chart(ctx, config);
  }

  componentWillUnmount() {
    if (!this.chart) {
      return;
    }

    this.chart.destroy();
  }

  render() {
    if (this.chart) {
      this.chart.update();
    }
    return <canvas ref={this.chartRef} style={{ width: "100%" }} />;
  }
}

const resetChart = (chart: Chart) => {
  const ctx = chart.ctx;

  if (!ctx) {
    return;
  }

  ctx.clearRect(
    chart.chartArea.left,
    chart.chartArea.top,
    chart.chartArea.right,
    chart.chartArea.bottom,
  );
};

const drawCenterText = (
  chart: Chart,
  options: { centerText: CenterTextInterface },
) => {
  const ctx = chart.ctx;

  if (!ctx) {
    return;
  }

  resetChart(chart);

  const { text, color } = options.centerText;
  const chartHeight = chart.chartArea.bottom;
  const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
  const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

  // 数字部分の表示
  const numbersFontSize = chartHeight / 4;
  ctx.font = `bold ${numbersFontSize}px YuGothic`;
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillStyle = color;
  ctx.fillText(text, centerX - 10, centerY);

  // %部分の表示
  const numbersPosition = centerX;
  const numbersWidth = ctx.measureText(text).width;
  const percentFontSize = numbersFontSize * 0.6;
  ctx.font = `bold ${percentFontSize}px YuGothic`;
  ctx.fillText(
    "%",
    numbersPosition + numbersWidth / 2,
    centerY + numbersFontSize * 0.1,
  );
};

export default DoughnutChart;
