import { Button } from "@studyplus/boron-ui";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import ErrorText from "../../../../components/atoms/ErrorText";
import Input from "../../../../components/atoms/Input";
import StudentTag from "../../../../domains/StudentTag";
import { UnprocessableEntityError } from "../../../../errors";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { ApiErrorInterface } from "../../../../interfaces/ApiErrorResponseInterface";
import SectionInterface from "../../../../interfaces/SectionInterface";
import { TagFormInterface } from "../../../../interfaces/TagFormInterface";
import styles from "./styles.scss";
import { usePostTag } from "./usePostTag";
import validationSchema from "./validationSchema";

type Props = {
  section: SectionInterface;
  submitting: boolean;
  onSubmitCallback: (
    type: "create" | "update" | "delete",
    tag: StudentTag,
  ) => void;
};

type Values = TagFormInterface;

const SectionTagForm = (props: Props) => {
  const [apiErrors, setApiErrors] = useState<ApiErrorInterface[]>([]);
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { mutate } = usePostTag({ sectionId: props.section.id });

  const handleSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => {
    mutate(values, {
      onSuccess: (data) => {
        showSuccessMessage("タグを作成しました");
        setApiErrors([]);
        formikHelpers.resetForm();
        const createdTag: StudentTag = {
          ...data.tag,
          enabled: true,
          type: "tag_ids",
        };
        props.onSubmitCallback("create", createdTag);
      },
      onError: (error) => {
        showErrorMessage("タグを作成できませんでした");
        if (error instanceof UnprocessableEntityError) {
          setApiErrors(error.originalErrors);
        }
      },
    });
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <>
          <div className={styles.errors}>
            <ApiErrors apiErrors={apiErrors} />
            <ErrorMessage
              name="name"
              component={ErrorText}
              className={styles.input__error}
            />
          </div>
          <Form className={styles.form}>
            <Field
              placeholder="タグ名を入力"
              name="name"
              component={Input}
              className={styles.input}
            />
            <Button
              disabled={!formikProps.isValid || props.submitting}
              type="submit"
              className={styles.btn}
            >
              タグを登録
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

const ApiErrors = ({ apiErrors }: { apiErrors: ApiErrorInterface[] }) => {
  return (
    <>
      {apiErrors.map((err, idx) => (
        <p className={styles.input__error} key={`apiError-${idx}`}>
          {err.message}
        </p>
      ))}
    </>
  );
};

export default SectionTagForm;
