import { useNavigate } from "react-router-dom";
import SwitchTabs, {
  SwitchTabsProps,
} from "../../components/molecules/SwitchTabs";

type Props = {
  sectionId: string;
  currentStatus: "active" | "archived";
};
export const ClassroomsTableSwitcher = ({
  sectionId,
  currentStatus,
}: Props) => {
  const navigate = useNavigate();

  return (
    <SwitchTabs
      tabs={[
        {
          id: "active",
          label: "アクティブ",
          active: currentStatus === "active",
        },
        {
          id: "archived",
          label: "アーカイブ",
          active: currentStatus === "archived",
        },
      ]}
      onClickTab={(
        tab: Omit<SwitchTabsProps<"active" | "archived">, "onClick">,
      ) =>
        () => {
          const basePath = `/sections/${sectionId}/settings/classrooms`;
          const path =
            tab.id === "archived" ? `${basePath}/archived` : basePath;

          if (location.pathname !== path) {
            navigate(path);
          }
        }}
    />
  );
};
