import { useCallback, useState } from "react";
import { Matcher } from "react-day-picker";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import styleVars from "../../../styles/variables.scss";
import {
  DateRange,
  DynamicDateRangePicker,
} from "../../general/DynamicDateRangePicker";
import { Controller } from "./Controller";
import styles from "./styles.scss";

interface Props {
  dateRange: DateRange;
  placeholder: string;
  onChangeDateRange: (dates: DateRange) => void;
  disabled?: Matcher | Matcher[];
  className?: string;
  fromDate?: Date;
}

/**
 * フォーム要素として日付範囲選択カレンダーを利用するときのコンポーネント
 */
export const DateRangePickerField = ({
  dateRange,
  onChangeDateRange,
  placeholder,
  disabled,
  className,
  fromDate,
}: Props) => {
  const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);

  const handleClickController = useCallback(() => {
    setIsCalendarVisible(!isCalendarVisible);
  }, []);
  const handleClickOutside = useCallback(() => {
    setIsCalendarVisible(false);
  }, []);
  const { insideRef } = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  const handleDatesChange = useCallback(
    (dates: DateRange) => {
      const { startDate, endDate } = dates;
      onChangeDateRange(dates);
      if (startDate && endDate) {
        setIsCalendarVisible(false);
      }
    },
    [onChangeDateRange],
  );

  const numberOfMonths =
    window.innerWidth > parseInt(styleVars.breakpointMobile) ? 2 : 1;

  return (
    <div className={styles.root}>
      <Controller
        dateRange={dateRange}
        placeholder={placeholder}
        onClick={handleClickController}
        className={className}
        isFocused={isCalendarVisible}
      />
      {isCalendarVisible && (
        <div ref={insideRef} className={styles.calendarContainer}>
          <DynamicDateRangePicker
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onDateRangeChange={handleDatesChange}
            numberOfMonths={numberOfMonths}
            defaultMonth={dateRange.startDate}
            disabled={disabled}
            fromDate={fromDate}
          />
        </div>
      )}
    </div>
  );
};
