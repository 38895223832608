import { useQuery } from "@tanstack/react-query";
import { stringify } from "query-string";
import ApiClient from "../../api";
import { ExaminationClassification } from "../../domains/ExaminationClassification";
import { HTTPErrors, createError } from "../../errors";

type UseFecthExaminationClassificationsProps = {
  enabled: boolean;
  organizerId: string | null;
  sectionId?: string;
  studentId?: string;
};

export const useFetchExaminationClassifications = ({
  sectionId,
  studentId,
  enabled,
  organizerId,
}: UseFecthExaminationClassificationsProps) => {
  const queryParams = stringify({
    section_id: sectionId,
    student_id: studentId,
  });

  const result = useQuery<readonly ExaminationClassification[], HTTPErrors>({
    queryKey: [`examination_classifications`, organizerId],
    queryFn: async () => {
      const res = await ApiClient.get(
        `/api/v1/examination_organizers/${organizerId}/examination_classifications`,
        {
          query: `?${queryParams}`,
        },
      );

      if (res.ok) {
        const json = (await res.json()) as {
          examinationClassifications: ExaminationClassification[];
        };
        return json.examinationClassifications;
      }
      const error = await createError(res);
      throw error;
    },
    enabled,
    retry: false,
  });

  return result;
};
