import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import { toLectureAttendanceWithScheduleFromJson as fromJson } from "../../domain-adapters/LectureAttendance";
import { LectureAttendanceWithSchedule } from "../../domains/LectureAttendance";
import { HTTPErrors, createError } from "../../errors";

type Props = {
  sectionId: string;
  lectureAttendanceId: string;
};
type ResponseData = LectureAttendanceWithSchedule;
export const useFetchLectureAttendance = ({
  sectionId,
  lectureAttendanceId,
}: Props) => {
  return useQuery<ResponseData, HTTPErrors>({
    queryKey: cacheKeyOf(sectionId, lectureAttendanceId),
    queryFn: async () => {
      const response = await api.get(
        `/api/v1/sections/${sectionId}/lecture_attendances/${lectureAttendanceId}`,
      );
      if (response.ok) {
        const json = await response.json();
        return fromJson(json.lectureAttendance);
      }
      throw await createError(response);
    },
  });
};

export const cacheKeyOf = (sectionId: string, lectureAttendanceId: string) => [
  sectionId,
  "lectureAttendance",
  lectureAttendanceId,
];
