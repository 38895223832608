import * as React from "react";
import Checkbox from "../../../components/atoms/Checkbox";
import Loader from "../../../components/atoms/Loader/index";
import StudentFilter from "../../../components/features/NewStudentFilter";
import { useStudentFilterContext } from "../../../components/features/NewStudentFilter/useStudentFilterContext";
import TabsNavi from "../../../components/molecules/TabsNavi";
import { StudyScheduleProgressHeader } from "../../../components/organisms/StudyScheduleProgressHeader";
import {
  OutletContextProps,
  WithRouterProps,
  useOnLocationChange,
  withRouter,
} from "../../../helpers/RouterHelper";
import { AuthenticatedPageCallbacks } from "../../../hocs/enhanceAuthenticatedPage";
import enhanceSectionsPage from "../../../hocs/enhanceSectionsPage";
import { StudentStudyScheduleProgressList } from "./StudentStudyScheduleProgressList";
import styles from "./styles.scss";
import {
  UseSectionStudySchedulesProps,
  useSectionStudySchedules,
} from "./useSectionStudySchedules";

type Props = WithRouterProps & AuthenticatedPageCallbacks & OutletContextProps;

const SectionsStudySchedules_: React.FC<
  Props & UseSectionStudySchedulesProps
> = (props) => {
  const context = useStudentFilterContext();

  React.useEffect(() => {
    props.setActiveMenu("study_schedules");
  }, []);

  const {
    fetchState,
    onChangeDateParams,
    onChangeHasPlanning,
    dateRange,
    hasPlanningParam,
    startFetch,
  } = useSectionStudySchedules({
    section: props.section,
    studentFilter: context.findTagFilter({ sectionId: props.section.id }),
  });

  React.useEffect(startFetch);

  const { data, isLoading, hasNextPage, isFetchingNextPage, refetch } =
    fetchState;

  useOnLocationChange(() => refetch());

  return (
    <>
      <TabsNavi
        tabs={[
          {
            label: "学習計画",
            active: true,
            link: "#",
          },
        ]}
      />
      <StudentFilter sectionId={props.section.id} />
      <StudyScheduleProgressHeader
        type={dateRange.type}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        onChangeDateParams={onChangeDateParams}
        leftContent={
          <div className={styles.checkOnlyActiveStudySchedule}>
            <div>
              <Checkbox
                id="SectionStudyPlansTableControl-checkbox"
                labelMargin="narrow"
                onChange={onChangeHasPlanning}
                checked={hasPlanningParam}
              >
                <span className={styles.label}>
                  期間中に学習計画がない生徒を非表示
                </span>
              </Checkbox>
            </div>
          </div>
        }
      />
      <div>
        <div className={styles.tableWrapper}>
          {!hasNextPage &&
          data &&
          data.pages[0].data.length === 0 &&
          !isLoading ? (
            <p className={styles.notfound}>該当生徒がいません</p>
          ) : (
            !isLoading &&
            data?.pages.map((page) => {
              return page.data.map((studentProgress) => (
                <StudentStudyScheduleProgressList
                  key={`SectionStudyPlans-${studentProgress.student.id}`}
                  student={studentProgress.student}
                  progresses={studentProgress.progresses}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  dateRangeType={dateRange.type}
                />
              ));
            })
          )}
        </div>
      </div>
      <Loader loading={isFetchingNextPage || isLoading} />
    </>
  );
};

const pageInfo = {
  title: "プランニング",
};

const EnhancedPage = enhanceSectionsPage(SectionsStudySchedules_, pageInfo);

export const SectionsStudySchedules = withRouter<Props>(EnhancedPage);
