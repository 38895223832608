import { boronClient } from "../../../../api";
import { useBoronMutation } from "../../../../hooks/http/useBoronMutation";
import { useUpdateSkillsCache } from "../useFetchSkills";

export const useUpdateSkillStatus = ({ sectionId }: { sectionId: string }) => {
  const { updateSkillsCache } = useUpdateSkillsCache({ sectionId });

  const { mutateAsync: enableSkill, isPending: isLoadingEnableSkill } =
    useBoronMutation(
      async (skillId: string) =>
        await boronClient.PUT(
          "/api/v1/sections/{section_id}/skills/{skill_hashid}/enabled",
          {
            params: {
              path: { section_id: sectionId, skill_hashid: skillId },
            },
          },
        ),
      {
        onSuccess: (_, skillId) => {
          updateSkillsCache({
            id: skillId,
            enabled: true,
          });
        },
      },
    );
  const { mutateAsync: disableSkill, isPending: isLoadingDisableSkill } =
    useBoronMutation(
      async (skillId: string) =>
        await boronClient.DELETE(
          "/api/v1/sections/{section_id}/skills/{skill_hashid}/enabled",
          {
            params: {
              path: { section_id: sectionId, skill_hashid: skillId },
            },
          },
        ),
      {
        onSuccess: (_, skillId) => {
          updateSkillsCache({
            id: skillId,
            enabled: false,
          });
        },
      },
    );

  return {
    enableSkill,
    disableSkill,
    isSubmitting: isLoadingEnableSkill || isLoadingDisableSkill,
  };
};
