import { ActiveOperatorInterface } from "../interfaces/OperatorInterface";
import {
  InvitingOperatorInterface,
  SectionOperatorsStateInterface,
} from "../interfaces/SectionOperatorsInterface";

const defaultState = {
  loading: false,
  submitting: false,
  hasMore: false,
  inviteApiErrors: [],
  invitingOperators: [],
  activeOperators: {
    data: [],
    meta: {
      currentPage: 0,
      totalPages: 0,
    },
  },
};

const sectionsSettingsOperatorsState = (
  state: SectionOperatorsStateInterface = defaultState,
  action: ReduxActions.Action<any>,
): SectionOperatorsStateInterface => {
  switch (action.type) {
    case "GET_OPERATORS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_OPERATORS_INITIAL_SUCCESS":
      return {
        ...defaultState,
        loading: false,
        hasMore:
          action.payload.activeOperators.meta.currentPage <
          action.payload.activeOperators.meta.totalPages,
        invitingOperators: action.payload.invitingOperators,
        activeOperators: action.payload.activeOperators,
      };
    case "GET_OPERATORS_SUCCESS":
      return {
        ...state,
        loading: false,
        hasMore:
          action.payload.activeOperators.meta.currentPage <
          action.payload.activeOperators.meta.totalPages,
        activeOperators: {
          data: state.activeOperators.data.concat(
            action.payload.activeOperators.data,
          ),
          meta: action.payload.activeOperators.meta,
        },
      };
    case "GET_OPERATORS_ERROR":
      return {
        ...state,
        loading: false,
      };
    case "DELETE_INVITE_REQUEST":
      return {
        ...state,
        invitingOperators: state.invitingOperators.map(
          (invite: InvitingOperatorInterface) => {
            if (action.payload.inviteId === invite.id) {
              return {
                ...invite,
                submitting: true,
              };
            } else {
              return invite;
            }
          },
        ),
      };
    case "DELETE_INVITE_SUCCESS":
      return {
        ...state,
        invitingOperators: state.invitingOperators.filter(
          (invite: InvitingOperatorInterface) =>
            invite.id !== action.payload.inviteId,
        ),
      };
    case "DELETE_INVITE_ERROR":
      return {
        ...state,
        invitingOperators: state.invitingOperators.map(
          (invite: InvitingOperatorInterface) => {
            if (invite.id === action.payload.inviteId) {
              return {
                ...invite,
                submitting: false,
              };
            } else {
              return invite;
            }
          },
        ),
      };
    case "INVITE_OPERATOR_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "INVITE_OPERATOR_SUCCESS":
      return {
        ...state,
        submitting: false,
        inviteApiErrors: [],
        invitingOperators: [
          action.payload.invitingOperator,
          ...state.invitingOperators,
        ],
      };
    case "INVITE_OPERATOR_ERROR":
      return {
        ...state,
        submitting: false,
        inviteApiErrors: action.payload
          ? action.payload.errors
          : state.inviteApiErrors,
      };
    case "DELETE_OPERATOR_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "DELETE_OPERATOR_ERROR":
      return {
        ...state,
        submitting: false,
      };
    case "DELETE_OPERATOR_SUCCESS":
      return {
        ...state,
        submitting: false,
        activeOperators: {
          ...state.activeOperators,
          data: state.activeOperators.data.filter(
            (operator: ActiveOperatorInterface) => {
              return operator.id != action.payload.operatorId;
            },
          ),
        },
      };
    case "CHANGE_OPERATOR_ROLE_REQUEST":
      return {
        ...state,
        activeOperators: {
          ...state.activeOperators,
          data: state.activeOperators.data.map(
            (operator: ActiveOperatorInterface) => {
              if (operator.id === action.payload.operatorId) {
                return {
                  ...operator,
                  submitting: true,
                };
              } else {
                return operator;
              }
            },
          ),
        },
      };
    case "CHANGE_OPERATOR_ROLE_SUCCESS":
      return {
        ...state,
        activeOperators: {
          ...state.activeOperators,
          data: state.activeOperators.data.map(
            (operator: ActiveOperatorInterface) => {
              if (operator.id === action.payload.activeOperator.id) {
                return action.payload.activeOperator;
              } else {
                return operator;
              }
            },
          ),
        },
      };
    case "CHANGE_OPERATOR_ROLE_ERROR":
      return {
        ...state,
        activeOperators: {
          ...state.activeOperators,
          data: state.activeOperators.data.map(
            (operator: ActiveOperatorInterface) => {
              if (operator.id === action.payload.operatorId) {
                return {
                  ...operator,
                  submitting: false,
                };
              } else {
                return operator;
              }
            },
          ),
        },
      };
    case "UPDATE_OPERATOR_SECTION_TAGS_REQUEST":
      return {
        ...state,
        activeOperators: {
          ...state.activeOperators,
          data: state.activeOperators.data.map(
            (operator: ActiveOperatorInterface) => {
              if (operator.id === action.payload.operatorId) {
                return {
                  ...operator,
                  submitting: true,
                };
              } else {
                return operator;
              }
            },
          ),
        },
      };
    case "UPDATE_OPERATOR_SECTION_TAGS_SUCCESS":
      return {
        ...state,
        activeOperators: {
          ...state.activeOperators,
          data: state.activeOperators.data.map(
            (operator: ActiveOperatorInterface) => {
              if (operator.id === action.payload.activeOperator.id) {
                return action.payload.activeOperator;
              } else {
                return operator;
              }
            },
          ),
        },
      };
    case "UPDATE_OPERATOR_SECTION_TAGS_ERROR":
      return {
        ...state,
        activeOperators: {
          ...state.activeOperators,
          data: state.activeOperators.data.map(
            (operator: ActiveOperatorInterface) => {
              if (operator.id === action.payload.operatorId) {
                return {
                  ...operator,
                  submitting: false,
                };
              } else {
                return operator;
              }
            },
          ),
        },
      };
    default:
      return state;
  }
};

export default sectionsSettingsOperatorsState;
