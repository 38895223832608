import { useInfiniteQuery } from "@tanstack/react-query";
import api from "../../api";
import { StudentLectureAttendanceAnalytics } from "../../domains/StudentAnalytics";
import { HTTPErrors, createError } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { toQueryString } from "../../helpers/TimeHelper";
import { AnalyticsFilter } from "../../hooks/filters/useAnalyticsFilter";
import ApiResponse from "../../interfaces/ApiResponse";
import StudentInterface from "../../interfaces/StudentInterface";

export type UseFetchStudentLectureAttendanceAnalyticsProps = {
  student: StudentInterface;
  analyticsFilter: AnalyticsFilter;
  enabledRequest: boolean;
};
type StudentLectureAttendaceAnalyticsData = {
  columns: string[];
  lectureAttendances: StudentLectureAttendanceAnalytics[];
};
type StudentLectureAttendaceAnalyticsResponse =
  ApiResponse<StudentLectureAttendaceAnalyticsData>;
export const useFetchStudentLectureAttendanceAnalytics = ({
  student,
  analyticsFilter,
  enabledRequest,
}: UseFetchStudentLectureAttendanceAnalyticsProps) => {
  return useInfiniteQuery<StudentLectureAttendaceAnalyticsResponse, HTTPErrors>(
    {
      queryKey: cacheKeyOfLectureAttendance(student, analyticsFilter),
      queryFn: async ({ pageParam }) => {
        const res = await api.interruptGet(
          `/api/v1/students/${student.id}/analytics/lecture_attendance`,
          {
            query: {
              initial_date:
                analyticsFilter?.endDate &&
                toQueryString(analyticsFilter?.endDate),
              term: analyticsFilter?.term,
              page: pageParam,
              order: analyticsFilter?.order,
              order_dir: analyticsFilter?.orderDir,
            },
          },
        );
        if (res.ok) {
          const json = await res.json();
          return {
            meta: json.lectureAttendanceAnalyticsTable.meta,
            data: fromJson(json.lectureAttendanceAnalyticsTable.data),
          };
        }
        throw await createError(res);
      },
      initialPageParam: 1,
      getNextPageParam,
      enabled: enabledRequest,
    },
  );
};

const cacheKeyOfLectureAttendance = (
  student: StudentInterface,
  analyticsFilter: AnalyticsFilter,
) => [`studentLectureAttendanceAnalytics`, student.id, analyticsFilter];

const fromJson = (
  json: Record<string, any>,
): StudentLectureAttendaceAnalyticsData => {
  return {
    columns: json.columns,
    lectureAttendances: json.lectureAttendances.map(
      (obj: Record<string, any>) => ({
        lecture: {
          id: obj.lecture.id,
          name: obj.lecture.name,
          attendanceConfirm: obj.lecture.attendanceConfirm,
          attendanceLimitDays: obj.lecture.attendanceLimitDays,
          attendanceLocationCollect: obj.lecture.attendanceLocationCollect,
          status: obj.lecture.status,
          questionnaires: obj.lecture.questionnaires,
          updatedAt: new Date(obj.lecture.updatedAt),
        },
        rank: obj.rank,
        totalScore: obj.totalScore,
        scores: obj.scores,
        lectureAttendancePresences: obj.lectureAttendancePresences,
        lectureAttendanceTotals: obj.lectureAttendanceTotals,
        sectionId: obj.sectionId,
      }),
    ),
  };
};
