import { FormikErrors } from "formik";
import { NavigateFunction } from "react-router";
import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import ApiClient from "../../../api";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";
import { SkillParamsInterface } from "../../../interfaces/SkillInterface";
import { HTTP_ERROR_MESSAGE } from "../../../reducers/index";
import { showErrorFlashMessage } from "../../flashMessage";
import { buildMainResourceApiErrorAction } from "../errors";
import { ActionTypes } from "./types";

// スキル作成

const postSkillRequest = createAction<void>(ActionTypes.POST_REQUEST);
const postSkillSuccess = createAction<void>(ActionTypes.POST_SUCCESS);
const postSkillFailure = createAction<ApiErrorResponseInterface | void>(
  ActionTypes.POST_FAILURE,
);
const closeModal = createAction<void>(ActionTypes.CLOSE_MODAL);

export const postSkill =
  (
    sectionId: string,
    params: SkillParamsInterface,
    setSubmitting: (submitState: boolean) => void,
    setErrors: (errors: FormikErrors<any>) => void,
  ) =>
  (dispatch: Dispatch): void => {
    dispatch(postSkillRequest());
    setSubmitting(true);

    ApiClient.post(`/api/v1/sections/${sectionId}/skills`, params)
      .then((res: Response) => {
        if (res.ok) {
          dispatch(postSkillSuccess());
          setSubmitting(false);
        } else if (res.status === 422) {
          res.json().then((errorPayload: ApiErrorResponseInterface) => {
            dispatch(postSkillFailure(errorPayload));
            dispatch(showErrorFlashMessage("スキルを登録できませんでした"));
            setSubmitting(false);

            // エラーをFormikに反映する
            // FIXME: FormikをmapPropsToErrorsが利用できるバージョンにしたら、そちらで置き換えたい
            // ref: https://github.com/jaredpalmer/formik/issues/33
            const errors: FormikErrors<any> = {};
            errorPayload.errors.forEach((error) => {
              errors[error.field as string] = error.message;
            });
            setErrors(errors);
          });
        } else {
          dispatch(postSkillFailure());
          dispatch(showErrorFlashMessage("スキルを登録できませんでした"));
          setSubmitting(false);
        }
      })
      .catch(() => {
        dispatch(postSkillFailure());
        dispatch(showErrorFlashMessage("スキルを登録できませんでした"));
        setSubmitting(false);
      });
  };

export const navigateToSkillIndexPage =
  (sectionId: string, navigate: NavigateFunction) =>
  (dispatch: Dispatch): void => {
    dispatch(closeModal());
    navigate(`/sections/${sectionId}/settings/skills`);
  };

export const loadSkillMaster =
  (id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: ActionTypes.GET_SKILL_MASTER });

    try {
      const response = await ApiClient.get(`/api/v1/skill_masters/${id}`);

      if (response.ok) {
        const json = await response.json();
        dispatch({
          type: ActionTypes.GET_SKILL_MASTER_SUCCESS,
          payload: json,
        });
      } else {
        dispatch({ type: ActionTypes.GET_SKILL_MASTER_ERROR });
      }
    } catch (_) {
      dispatch({ type: ActionTypes.GET_SKILL_MASTER_ERROR });
    }
  };

// スキル更新
const updateSkillRequest = createAction<void>(ActionTypes.UPDATE_REQUEST);
const updateSkillSuccess = createAction<void>(ActionTypes.UPDATE_SUCCESS);
const updateSkillFailure = createAction<ApiErrorResponseInterface | void>(
  ActionTypes.UPDATE_FAILURE,
);

export const updateSkill =
  (
    sectionId: string,
    skillId: string,
    params: SkillParamsInterface,
    setSubmitting: (submitState: boolean) => void,
    setErrors: (errors: FormikErrors<any>) => void,
  ) =>
  (dispatch: Dispatch): void => {
    dispatch(updateSkillRequest());
    setSubmitting(true);

    ApiClient.patch(`/api/v1/sections/${sectionId}/skills/${skillId}`, params)
      .then((res: Response) => {
        if (res.ok) {
          dispatch(updateSkillSuccess());
          setSubmitting(false);
        } else if (res.status === 422) {
          res.json().then((errorPayload: ApiErrorResponseInterface) => {
            dispatch(updateSkillFailure(errorPayload));
            dispatch(showErrorFlashMessage("スキルを更新できませんでした"));
            setSubmitting(false);

            // エラーをFormikに反映する
            // FIXME: FormikをmapPropsToErrorsが利用できるバージョンにしたら、そちらで置き換えたい
            // ref: https://github.com/jaredpalmer/formik/issues/33
            const errors: FormikErrors<any> = {};
            errorPayload.errors.forEach((error) => {
              errors[error.field as string] = error.message;
            });
            setErrors(errors);
          });
        } else {
          dispatch(updateSkillFailure());
          dispatch(showErrorFlashMessage("スキルを更新できませんでした"));
          setSubmitting(false);
        }
      })
      .catch(() => {
        dispatch(updateSkillFailure());
        dispatch(showErrorFlashMessage("スキルを更新できませんでした"));
        setSubmitting(false);
      });
  };

/**
 * スキル読み込み
 */
const loadSkillRequest = createAction<void>(ActionTypes.GET_REQUEST);
const loadSkillSuccess = createAction<void>(ActionTypes.GET_SUCCESS);
const loadSkillFailure = createAction<ApiErrorResponseInterface | void>(
  ActionTypes.GET_FAILURE,
);

export const loadSkill =
  (sectionId: string, skillId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(loadSkillRequest());

    ApiClient.get(`/api/v1/sections/${sectionId}/skills/${skillId}`)
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(loadSkillSuccess(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(loadSkillFailure());
          dispatch(showErrorFlashMessage(HTTP_ERROR_MESSAGE));
        }
      })
      .catch(() => {
        dispatch(loadSkillFailure());
        dispatch(showErrorFlashMessage(HTTP_ERROR_MESSAGE));
      });
  };
