import { AnyAction } from "redux";
import { createAction } from "redux-actions";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import ApiClient from "../../api";
import StudentTag from "../../domains/StudentTag";
import ApiErrorResponseInterface from "../../interfaces/ApiErrorResponseInterface";
import AppStateInterface from "../../interfaces/AppStateInterface";
import StudentInterface, {
  StudentParamsInterface,
} from "../../interfaces/StudentInterface";
import { HTTP_ERROR_MESSAGE } from "../../reducers/index";
import {
  showErrorFlashMessage,
  showSuccessFlashMessage,
} from "../flashMessage";
import { buildMainResourceApiErrorAction } from "./errors";

// GET /api/v1/students/:student_hashid actions
const getStudentRequestSending = createAction("GET_STUDENT_REQUEST_SENDING");
const getStudentResponseReceived = createAction(
  "GET_STUDENT_RESPONSE_RECEIVED",
);
const getStudentResponseError = createAction("GET_STUDENT_RESPONSE_ERROR");
export const loadStudent =
  (
    studentId: string,
  ): ThunkAction<Promise<StudentInterface | void>, any, any, AnyAction> =>
  async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
  ): Promise<StudentInterface | void> => {
    dispatch(getStudentRequestSending());

    return ApiClient.get(`/api/v1/students/${studentId}`)
      .then(async (res): Promise<StudentInterface | void> => {
        return await res.json().then((json: any) => {
          if (res.ok) {
            // 生徒情報をdispatch
            dispatch(getStudentResponseReceived(json));
            return json.student;
          } else {
            dispatch(getStudentResponseError({ status: res.status }));
            dispatch(buildMainResourceApiErrorAction(res.status, json));
          }
        });
      })
      .catch(() => {
        dispatch(getStudentResponseError());
        dispatch(buildMainResourceApiErrorAction());
      });
  };
export const clearStudent = createAction("CLEAR_STUDENT");
const updateStudentTagsRequest = createAction("UPDATE_STUDENT_TAGS_REQUEST");
const updateStudentTagsSuccess = createAction("UPDATE_STUDENT_TAGS_SUCCESS");
const updateStudentTagsFailure = createAction("UPDATE_STUDENT_TAGS_FAILURE");
export const updateStudentTags =
  (
    studentId: string,
    tags: StudentTag[],
  ): ThunkAction<void, AppStateInterface, any, AnyAction> =>
  (dispatch: ThunkDispatch<AppStateInterface, any, AnyAction>): void => {
    const body = { tag_ids: tags.map((tag: StudentTag) => tag.id) };

    dispatch(updateStudentTagsRequest());
    ApiClient.patch(`/api/v1/students/${studentId}/tags`, body)
      .then((res) => {
        if (res.ok) {
          dispatch(updateStudentTagsSuccess(tags));
          dispatch(showSuccessFlashMessage("タグを設定しました"));
        } else {
          dispatch(updateStudentTagsFailure());
          dispatch(showErrorFlashMessage("タグを設定できませんでした"));
        }
      })
      .catch(() => {
        dispatch(updateStudentTagsFailure());
        dispatch(showErrorFlashMessage(HTTP_ERROR_MESSAGE));
      });
  };

const updateStudentRequest = createAction("UPDATE_STUDENT_REQUEST");
const updateStudentSuccess = createAction("UPDATE_STUDENT_SUCCESS");
const updateStudentFailure = createAction("UPDATE_STUDENT_FAILURE");
export const updateStudent =
  (
    studentId: string,
    params: StudentParamsInterface,
    onSuccess: () => void,
  ): ThunkAction<void, any, any, AnyAction> =>
  (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(updateStudentRequest());
    ApiClient.patch(`/api/v1/students/${studentId}`, { student: params })
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((json: { student: StudentInterface }) => {
            dispatch(updateStudentSuccess(json));
            dispatch(showSuccessFlashMessage("生徒情報を更新しました"));
            onSuccess();
          });
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(updateStudentFailure(json));
            dispatch(showErrorFlashMessage("生徒情報を更新できませんでした"));
          });
        } else {
          dispatch(updateStudentFailure());
          dispatch(showErrorFlashMessage("生徒情報を更新できませんでした"));
        }
      })
      .catch(() => {
        dispatch(updateStudentFailure());
        dispatch(showErrorFlashMessage(HTTP_ERROR_MESSAGE));
      });
  };
