import * as React from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  dispatchCloseRecipientsModal,
  dispatchLoadMoreRecipients,
  dispatchOpenRecipientsModal,
  loadMoreSectionAnnounceMessages,
} from "../../../actions/pages/sectionAnnounceMessages/messageList";
import StudentTag from "../../../domains/StudentTag";
import PathHelper from "../../../helpers/PathHelper";
import type { AnnounceMessage } from "../../../interfaces/MessageInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import {
  AnnonunceMessagesRecipientsStateInterface,
  AnnounceDestinationTagWithFormStateInterface,
  AnnounceMessagesStateInterface,
} from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import Icon from "../../atoms/Icon";
import SplitLayout from "../../atoms/SplitLayout";
import AnnounceMessageForm from "../AnnounceMessageForm";
import AnnounceMessageRecipientsModal from "../AnnounceMessageRecipientsModal";
import AnnounceMessageList from "./AnnounceMessageList";
import styles from "./styles.scss";

interface Props {
  destinationTag: AnnounceDestinationTagWithFormStateInterface | null;
  messagesState: AnnounceMessagesStateInterface;
  onCloseRightColumn: (e: any) => void;
  loadMoreSectionAnnounceMessages: HandleThunkActionCreator<
    typeof loadMoreSectionAnnounceMessages
  >;
  section: SectionInterface;
  isModalOpen: boolean;
  isRecipientsModalOpen: boolean;
  recipients: AnnonunceMessagesRecipientsStateInterface;
  selectedMessageId: string | null;
  onOpenModal: () => any;
  onCloseModal: () => any;
  onOpenRecipientsModal: HandleThunkActionCreator<
    typeof dispatchOpenRecipientsModal
  >;
  onCloseRecipientsModal: HandleThunkActionCreator<
    typeof dispatchCloseRecipientsModal
  >;
  onLoadMoreRecipients: HandleThunkActionCreator<
    typeof dispatchLoadMoreRecipients
  >;
  currentOperator: OperatorInterface;
}
class PresentationalComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { props } = this;

    if (!props.section) {
      return null;
    }

    const receivedTotalCount = this.props.messagesState.data.find(
      (message) => message.id === this.props.selectedMessageId,
    )?.receivedTotalCount;

    return (
      <React.Fragment>
        <SplitLayout.Header>
          <Icon
            name="icon-arrow-close"
            className={styles.close}
            onClick={props.onCloseRightColumn}
          />
          <span className={styles.header}>
            {this.renderTagName(props.section, props.destinationTag)}
          </span>
        </SplitLayout.Header>
        <SplitLayout.Body>
          {this.renderList()}
          <AnnounceMessageForm
            destinationTag={props.destinationTag}
            section={props.section}
            isModalOpen={props.isModalOpen}
            onOpenModal={props.onOpenModal}
            onCloseModal={props.onCloseModal}
            currentOperator={props.currentOperator}
          />
        </SplitLayout.Body>

        <AnnounceMessageRecipientsModal
          isOpen={this.props.isRecipientsModalOpen}
          onClose={this.closeRecipientsModal}
          destinationTag={this.props.destinationTag}
          recipients={this.props.recipients}
          onLoadMore={this.loadMoreRecipients(this.props.selectedMessageId)}
          totalCount={this.props.recipients.meta.totalCount}
          receivedTotalCount={receivedTotalCount}
        />
      </React.Fragment>
    );
  }

  private renderTagName = (
    section: SectionInterface,
    tag: StudentTag | null,
  ) => {
    if (tag) {
      return (
        <React.Fragment>
          <Link
            to={PathHelper.getStudentsPath(section.id, tag)}
            className={styles.header__link}
          >
            {tag.name}
          </Link>
          へメッセージ
        </React.Fragment>
      );
    } else {
      return "新しいメッセージを作成";
    }
  };

  private renderEmptyList = () => {
    return <div className={styles.listContainer} />;
  };

  private renderList = () => {
    const {
      destinationTag,
      messagesState,
      section,
      loadMoreSectionAnnounceMessages,
    } = this.props;

    if (!destinationTag || !section) {
      return this.renderEmptyList();
    }

    return (
      <AnnounceMessageList
        destinationTag={destinationTag}
        messagesState={messagesState}
        section={section}
        loadMoreSectionAnnounceMessages={loadMoreSectionAnnounceMessages}
        onOpenModal={this.openRecipientsModal}
      />
    );
  };

  private openRecipientsModal = (announceMessage: AnnounceMessage) => {
    this.props.onOpenRecipientsModal(this.props.section.id, announceMessage.id);
  };

  private closeRecipientsModal = () => {
    // モーダルを開くReduxアクションを投げる
    this.props.onCloseRecipientsModal();
  };

  private loadMoreRecipients = (announceMessageId: string | null) => () => {
    if (!announceMessageId) {
      return;
    }

    // 受信者一覧もっと見る
    this.props.onLoadMoreRecipients(
      this.props.section.id,
      announceMessageId,
      this.props.recipients.meta.currentPage + 1,
    );
  };
}

const actions = {
  loadMoreSectionAnnounceMessages,
  onOpenRecipientsModal: dispatchOpenRecipientsModal,
  onCloseRecipientsModal: dispatchCloseRecipientsModal,
  onLoadMoreRecipients: dispatchLoadMoreRecipients,
};

const AnnounceMessage = connect(null, actions)(PresentationalComponent);

export default AnnounceMessage;
