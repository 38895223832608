import classNames from "classnames";
import * as React from "react";
import DeviceHelper from "../../../../helpers/DeviceHelper";
import Backdrop from "../../../atoms/Backdrop/index";
import styles from "./styles.scss";

type Props = {
  opened: boolean;
  dropdownRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  onClose: () => void;
};

export const TagFilterDropdown = (props: Props) => {
  return (
    <>
      {props.opened ? (
        <Backdrop onClick={props.onClose} className={styles.backdrop} />
      ) : null}
      <div
        className={classNames(styles.dropdown, {
          [styles.openedDropdown]: props.opened,
        })}
        ref={props.dropdownRef}
      >
        <div className={styles.main}>{props.children}</div>
        <button onClick={props.onClose} className={styles.closeButton}>
          閉じる
        </button>
      </div>
    </>
  );
};

type UseDropdownProps = {
  dropdownRef: React.RefObject<HTMLDivElement>;
  buttonRef: React.RefObject<HTMLButtonElement>;
  containerRef: React.RefObject<HTMLDivElement>;
};
export const useTagFilterDropdown = ({
  dropdownRef,
  buttonRef,
  containerRef,
}: UseDropdownProps) => {
  const [isOpened, setIsOpened] = React.useState<boolean>(false);

  const button = buttonRef.current;
  const dropdown = dropdownRef.current;
  const container = containerRef.current;
  const fixDropdownXPosition = (): void => {
    if (!dropdown || !DeviceHelper.isPC()) {
      return;
    }

    if (willOverWindowWidth()) {
      fixDropdownPositionToRight();
    } else {
      fixDropdownPositionToLeft();
    }
  };

  const fixDropdownYPosition = () => {
    if (!container || !DeviceHelper.isPC()) {
      return;
    }

    if (container && dropdown) {
      const margin = 8;
      dropdown.style.top = container.offsetHeight + margin + "px";
    }
  };

  React.useEffect(fixDropdownXPosition, [isOpened]);
  React.useEffect(fixDropdownYPosition, [container?.offsetHeight]);

  const fixDropdownPositionToLeft = (): void => {
    if (button && button.offsetWidth > 0) {
      const left = button.offsetLeft;
      if (dropdown) {
        dropdown.style.left = left + "px";
        dropdown.style.right = "auto";
      }
    }
  };
  const fixDropdownPositionToRight = (): void => {
    if (button && button.offsetParent && button.offsetWidth > 0) {
      const parentRect = button.offsetParent.getBoundingClientRect();
      const buttonRect = button.getBoundingClientRect();
      const left = button.offsetLeft;
      if (dropdown) {
        dropdown.style.left = "auto";
        dropdown.style.right =
          parentRect.width - left - buttonRect.width + "px";
      }
    }
  };
  const willOverWindowWidth = (): boolean => {
    if (button && dropdown) {
      const buttonRect = button.getBoundingClientRect();
      const dropdownRect = dropdown.getBoundingClientRect();
      const xEndPoint = buttonRect.x + dropdownRect.width;

      return xEndPoint > window.innerWidth;
    } else {
      return false;
    }
  };

  const open = () => {
    setIsOpened(true);
  };
  const close = () => {
    setIsOpened(false);
  };

  return {
    isOpened,
    dropdownRef,
    close,
    open,
  };
};
