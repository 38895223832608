import * as queryString from "query-string";
import ApiClient from "../api";
import { BookshelfStatusType } from "../interfaces/BookshelfEntryEntityInteface";

const StudentLearningMaterialApi = {
  getLearningMaterials: (
    studentId: string,
    status: BookshelfStatusType | "all",
  ): Promise<Response> => {
    const query = queryString.stringify({ type: status });
    return ApiClient.get(`/api/v1/students/${studentId}/learning_materials`, {
      query: `?${query}`,
    });
  },

  getPreRegisteredLearningMaterials: (studentId: string): Promise<Response> => {
    return ApiClient.get(
      `/api/v1/students/${studentId}/learning_materials/pre_registrations`,
    );
  },

  registerToBookshelf: (studentId: string, materialCode: string) => {
    return ApiClient.post(`/api/v1/students/${studentId}/learning_materials`, {
      material_code: materialCode,
    });
  },

  preRegisterToBookshelf: (studentId: string, materialCode: string) => {
    return ApiClient.post(
      `/api/v1/students/${studentId}/learning_materials/pre_registrations`,
      { material_code: materialCode },
    );
  },
};

export default StudentLearningMaterialApi;
