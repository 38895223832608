import addDays from "date-fns/addDays";
import addHours from "date-fns/addHours";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import set from "date-fns/set";
import startOfDay from "date-fns/startOfDay";
import { useFormik } from "formik";
import * as React from "react";
import { OnChangeValue } from "react-select";
import * as yup from "yup";
import { OptionType as LearningMaterialOptionType } from "../../../components/features/LoadableLearningMaterial";
import { OptionType } from "../../../components/features/LoadableStudentsMultiSelect";
import { useSectionContext } from "../../../contexts/section";
import { Schedule } from "../../../domains/Schedule";
import {
  SectionSchedule,
  SectionScheduleDetail,
} from "../../../domains/SectionSchedule";
import { StudySchedule } from "../../../domains/StudySchedule";
import {
  calcCeiledTime,
  parseDateTime,
  secondsToTime,
} from "../../../helpers/TimeHelper";
import { useFetchClassrooms } from "../../../hooks/http/useClassroomsApi";
import { useFetchLectures } from "../../../hooks/http/useFetchLectures";
import { useFetchTeachers } from "../../../hooks/http/useFetchTeachers";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useModalContext } from "../modal";
import { useFetchSectionSchedule } from "../modal/useFetchSectionSchedule";
import {
  SectionScheduleMutateParam,
  useMutateSectionSchedule,
} from "./useMutateSectionSchedule";

// フォームで学習計画扱うための型
type StudyScheduleValue = Omit<
  StudySchedule,
  "learningMaterial" | "numberOfSeconds"
> & {
  learningMaterial: LearningMaterialOptionType | null;
};
// フォームで校舎計画を扱う型
type SectionScheduleValue = Omit<
  Schedule,
  "studySchedule" | "recurrence" | "id" | "scheduleType" | "startAt" | "endAt"
> & {
  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
  classroom: OptionType | null;
  teacher: OptionType | null;
  lecture: OptionType | null;
  students: ReadonlyArray<OptionType> | null;
  numberOfHours: number;
  numberOfMinutes: number;
  id?: string;
} & StudyScheduleValue; // フォームの扱いとしてはフラット

// フォームの初期値を決定する
const createInitialSectionScheduleValue = (
  sectionScheduleDetail: SectionScheduleDetail | null,
  initialDate?: Date,
): SectionScheduleValue => {
  const isNew = !sectionScheduleDetail;

  // 新規登録の場合は30分刻みでキリの良い時間を指定する
  const startDate = isNew
    ? calcCeiledTime(
        initialDate
          ? set(new Date(), {
              year: initialDate.getFullYear(),
              month: initialDate.getMonth(),
              date: initialDate.getDate(),
            })
          : new Date(),
      )
    : sectionScheduleDetail.schedule.startAt;

  const endDate = isNew
    ? addHours(startDate, 1)
    : sectionScheduleDetail.schedule.endAt;

  // 終日じゃない場合終了日のフォームがデザイン上でないため、1時間自動で取ると日付跨ぎになってしまって回避不可能なエラーになる
  // その場合は終了日時を同日の23:59にする
  if (isNew && !isSameDay(startDate, endDate)) {
    endDate.setDate(startDate.getDate());
    endDate.setHours(23);
    endDate.setMinutes(59);
  }

  // 終日の場合、データ上は終了日時は終了翌日の0時となるので
  // フォーム上は-1日する
  if (!isNew && sectionScheduleDetail.schedule.allday) {
    endDate.setDate(endDate.getDate() - 1);
  }

  const startTime = format(startDate, "HH:mm");
  const endTime = format(endDate, "HH:mm");

  const studyScheduleValue = makeStudyScheduleValue(sectionScheduleDetail);
  // 新規登録の場合に、startAtとendAtからデフォルトの学習計画時間を算出する
  const defaultStudyScheduleDuration = calcDuration({
    startDate,
    startTime,
    endDate,
    endTime,
  });

  return {
    ...(sectionScheduleDetail ? { id: sectionScheduleDetail.id } : {}),
    summary: sectionScheduleDetail
      ? sectionScheduleDetail.schedule.summary
      : "",
    startDate,
    startTime,
    endDate,
    endTime,
    allday: sectionScheduleDetail
      ? sectionScheduleDetail.schedule.allday
      : false,
    locale: sectionScheduleDetail ? sectionScheduleDetail.schedule.locale : "",
    description: sectionScheduleDetail
      ? sectionScheduleDetail.schedule.description
      : "",
    classroom: makeClassroomValue(sectionScheduleDetail),
    teacher: makeTeacherValue(sectionScheduleDetail),
    lecture: makeLectureValue(sectionScheduleDetail),
    students: makeStudentValues(sectionScheduleDetail),
    learningMaterial: makeLearningMaterialValue(sectionScheduleDetail),
    amount: sectionScheduleDetail
      ? (sectionScheduleDetail.schedule.studySchedule?.amount ?? 0)
      : 0,
    numberOfHours: studyScheduleValue
      ? studyScheduleValue.numberOfHours
      : defaultStudyScheduleDuration.hours,
    numberOfMinutes: studyScheduleValue
      ? studyScheduleValue.numberOfMinutes
      : defaultStudyScheduleDuration.minutes,
  };
};

// 編集の際に生徒データをセレクトボックスのオプションに変換する
const makeStudentValues = (
  sectionScheduleDetail: SectionScheduleDetail | null,
) => {
  if (
    !sectionScheduleDetail ||
    sectionScheduleDetail.schedule.students.length === 0
  ) {
    return null;
  }
  return sectionScheduleDetail.schedule.students.map((student) => ({
    label: student.fullName,
    value: student.id,
  }));
};
// 編集の際に教室データをセレクトボックスのオプションに変換する
const makeClassroomValue = (
  sectionScheduleDetail: SectionScheduleDetail | null,
) => {
  if (!sectionScheduleDetail || !sectionScheduleDetail.classroom) {
    return null;
  }
  return {
    label: sectionScheduleDetail.classroom.name,
    value: sectionScheduleDetail.classroom.id,
  };
};
// 編集の際に先生データをセレクトボックスのオプションに変換する
const makeTeacherValue = (
  sectionScheduleDetail: SectionScheduleDetail | null,
) => {
  if (!sectionScheduleDetail || !sectionScheduleDetail.teacher) {
    return null;
  }
  return {
    label: sectionScheduleDetail.teacher.fullName,
    value: sectionScheduleDetail.teacher.id,
  };
};
// 編集の際に講座データをセレクトボックスのオプションに変換する
const makeLectureValue = (
  sectionScheduleDetail: SectionScheduleDetail | null,
) => {
  if (
    !sectionScheduleDetail ||
    !sectionScheduleDetail.lectureSession ||
    !sectionScheduleDetail.lectureSession.lecture
  ) {
    return null;
  }
  return {
    label: sectionScheduleDetail.lectureSession.lecture.name,
    value: sectionScheduleDetail.lectureSession.lecture.id,
  };
};
// 学習計画教材データを編集用に変換する
const makeLearningMaterialValue = (
  sectionSchedule: SectionScheduleDetail | null,
) => {
  if (!sectionSchedule || !sectionSchedule.schedule.studySchedule) {
    return null;
  }
  const { learningMaterial } = sectionSchedule.schedule.studySchedule;
  return {
    label: learningMaterial.name,
    value: learningMaterial.publicId,
    unit: learningMaterial.unit,
  };
};
const makeStudyScheduleValue = (
  sectionSchedule: SectionScheduleDetail | null,
) => {
  if (
    !sectionSchedule ||
    !sectionSchedule.schedule ||
    !sectionSchedule.schedule.studySchedule
  ) {
    return null;
  }
  const { studySchedule } = sectionSchedule.schedule;
  const { hours, minutes } = secondsToTime(studySchedule.numberOfSeconds);

  return {
    amount: studySchedule.amount,
    numberOfHours: hours,
    numberOfMinutes: minutes,
  };
};

const ERROR_MESSAGE_RESOURCE = "教室・講師・生徒のいずれかを設定してください";
const ERROR_MESSAGE_ENDAT = "終了時刻は開始時刻以降を指定してください";

const validationSchema = yup.object().shape({
  summary: yup
    .string()
    .required("予定のタイトルを入力してください")
    .max(200, "予定のタイトルは200文字以下で指定してください"),
  endDate: yup.mixed().test({
    message: ERROR_MESSAGE_ENDAT,
    test: (value: Date, context) => {
      const values: SectionScheduleValue = context.parent;
      return validateScheduleTime({
        startDate: values.startDate,
        startTime: values.startTime,
        endDate: value,
        endTime: values.endTime,
      });
    },
  }),
  endTime: yup.string().test({
    message: ERROR_MESSAGE_ENDAT,
    test: (value, context) => {
      const values: SectionScheduleValue = context.parent;
      return validateScheduleTime({
        startDate: values.startDate,
        startTime: values.startTime,
        endDate: values.endDate,
        endTime: value ?? "",
      });
    },
  }),
  students: yup
    .array(yup.mixed())
    .nullable()
    .test({
      message: ERROR_MESSAGE_RESOURCE,
      test: (value, context) => {
        const parent: SectionScheduleValue = context.parent;
        return validateResources({
          students: value ?? null,
          teacher: parent.teacher,
          classroom: parent.classroom,
        });
      },
    }),
  classroom: yup.mixed().test({
    message: ERROR_MESSAGE_RESOURCE,
    test: (value, context) => {
      const parent: SectionScheduleValue = context.parent;
      return validateResources({
        students: parent.students,
        teacher: parent.teacher,
        classroom: value,
      });
    },
  }),
  teacher: yup.mixed().test({
    message: ERROR_MESSAGE_RESOURCE,
    test: (value, context) => {
      const parent: SectionScheduleValue = context.parent;
      return validateResources({
        students: parent.students,
        teacher: value,
        classroom: parent.classroom,
      });
    },
  }),
});

// リソースがどれか一つはセットされているかチェックするカスタムバリデーション
type ValidateResourcesParams = Pick<
  SectionScheduleValue,
  "teacher" | "classroom" | "students"
>;
const validateResources = (params: ValidateResourcesParams) => {
  return Boolean(
    params.classroom ||
      (params.students && params.students.length > 0) ||
      params.teacher,
  );
};

// 終了日の日付が開始日より前に来てないかチェックするカスタムバリデーション
type ValidateScheduleTimeParams = Pick<
  SectionScheduleValue,
  "startDate" | "startTime" | "endDate" | "endTime"
>;
const validateScheduleTime = ({
  startDate,
  startTime,
  endDate,
  endTime,
}: ValidateScheduleTimeParams) => {
  const startAt = parseDateTime(startDate, startTime);
  const endAt = parseDateTime(endDate, endTime);
  return !isAfter(startAt, endAt);
};

// 校舎予定登録のフォームに使うFormik・formの値に関心を持つカスタムhooks
type UseFormikWithSectionScheduleProps = {
  onSubmit: (values: SectionScheduleValue) => void;
  initialDate?: Date;
  sectionScheduleDetail: SectionScheduleDetail | null;
};
const useFormikWithSectionSchedule = ({
  sectionScheduleDetail,
  initialDate,
  onSubmit,
}: UseFormikWithSectionScheduleProps) => {
  const initialValues = React.useMemo(() => {
    return createInitialSectionScheduleValue(
      sectionScheduleDetail,
      initialDate,
    );
  }, [sectionScheduleDetail]);

  const formik = useFormik<SectionScheduleValue>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const hasSummaryError =
    Boolean(formik.errors.summary) && formik.touched.summary;

  const summaryProps = {
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    name: "summary",
    value: formik.values.summary ?? "",
    hasError: hasSummaryError,
    "aria-invalid": hasSummaryError,
  };

  const alldayProps = {
    onChange: () => {
      const { allday, startDate } = formik.values;
      const newValue = !allday;
      formik.setFieldValue("allday", newValue);
      // 終日から非終日になる場合は終了日のUIが隠れるので、開始日と同じ日付をセットしておく
      if (!newValue) {
        formik.setFieldValue("endDate", startDate, false);
      }
    },
    name: "allday",
    value: "allday",
    ...(formik.values.allday ? { checked: true } : {}),
  };

  const startDateProps = {
    value: formik.values.startDate,
    onDateChange: (m: Date) => {
      formik.setFieldValue("startDate", m, formik.values.allday);
      // 終日じゃない場合終了日の日付は隠れているので、同期更新しておく
      if (!formik.values.allday) {
        formik.setFieldValue("endDate", m, false);
      }
    },
  };

  const endDateProps = {
    value: formik.values.endDate,
    onDateChange: (m: Date) => formik.setFieldValue("endDate", m),
    hasError: Boolean(formik.errors.endDate),
  };

  const getScheduleTimeProps = (type: "endTime" | "startTime") => {
    const hasError = Boolean(formik.errors[type]);

    return {
      value: formik.values[type],
      name: type,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentValues = formik.values;
        const {
          startDate,
          startTime,
          endDate,
          endTime,
          numberOfHours,
          numberOfMinutes,
          allday,
        } = currentValues;
        const newValue = e.currentTarget.value;
        formik.setFieldValue(type, newValue);

        // 現在の予定時間が何時何分か計算
        const durationObj = calcDuration({
          startDate,
          startTime,
          endDate,
          endTime,
        });
        // 終日予定でない&先ほど計算した"何時間何分"が学習計画と一致してれば、同期更新させる
        if (
          !allday &&
          durationObj.hours === numberOfHours &&
          durationObj.minutes === numberOfMinutes
        ) {
          const newStudyScheduleDuration = calcDuration({
            endDate,
            startDate,
            endTime: type === "endTime" ? newValue : endTime,
            startTime: type === "startTime" ? newValue : startTime,
          });
          formik.setFieldValue("numberOfHours", newStudyScheduleDuration.hours);
          formik.setFieldValue(
            "numberOfMinutes",
            newStudyScheduleDuration.minutes,
          );
        }
      },
      onBlur: formik.handleBlur,
      hasError,
      "aria-invalid": hasError,
    };
  };
  const studentProps = {
    defaultValue: formik.values.students,
    onChange: React.useCallback(
      (students: ReadonlyArray<OptionType> | null | undefined) => {
        formik.setFieldValue("students", students);
      },
      [],
    ),
  };

  const learningMaterialProps = {
    defaultValue: formik.values.learningMaterial,
    onChange: React.useCallback((material: OptionType | null | undefined) => {
      formik.setFieldValue("learningMaterial", material);
    }, []),
  };

  const isAnyResourceTouched =
    formik.touched.classroom ||
    formik.touched.teacher ||
    formik.touched.students;
  // リソースのうちいずれかがtouchedで全てがerror(いずれかを触ったが何も設定されていない)の場合はエラー
  const hasResourceError =
    isAnyResourceTouched &&
    (Boolean(formik.errors.classroom) ||
      Boolean(formik.errors.teacher) ||
      Boolean(formik.errors.students));

  const getResourceProps = (
    key: Extract<
      keyof SectionScheduleValue,
      "classroom" | "teacher" | "lecture"
    >,
    options: ReadonlyArray<OptionType>,
  ) => {
    return {
      // optionsがuseMemoされておりOptionTypeを指定できないため一旦anyを使用
      onChange: (p?: OnChangeValue<any, boolean>) => {
        formik.setFieldValue(key, p);
      },
      name: key,
      hasError: key !== "lecture" && hasResourceError,
      value: options.find(
        (option) => option.value === formik.values[key]?.value,
      ),
    };
  };

  const preventNumberBoxFlickrOnClick = React.useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      e.currentTarget.focus();
    },
    [],
  );
  const preventNumberBoxFlickrOnBlur = React.useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      e.currentTarget.blur();
    },
    [],
  );
  const getStudySchedulePlanValue = (
    type: Extract<
      keyof SectionScheduleValue,
      "amount" | "numberOfHours" | "numberOfMinutes"
    >,
  ) => {
    return {
      onClick: preventNumberBoxFlickrOnClick,
      onMouseUp: preventNumberBoxFlickrOnBlur,
      name: type,
      value: formik.values[type],
      onChange: formik.handleChange,
    };
  };
  return {
    summaryProps,
    summaryErrorText:
      formik.touched.summary && formik.errors.summary
        ? formik.errors.summary
        : null,
    alldayProps,
    startDateProps,
    getScheduleTimeProps,
    endDateProps,
    endAtErrorText: formik.errors.endDate ?? formik.errors.endTime ?? null,
    studentProps,
    learningMaterialProps,
    getResourceProps,
    resourceErrorText: hasResourceError ? ERROR_MESSAGE_RESOURCE : null,
    getStudySchedulePlanValue,
    onSubmit: formik.handleSubmit,
    isAllday: formik.values.allday,
    unitText: formik.values.learningMaterial
      ? formik.values.learningMaterial.unit
      : null,
    isSetMaterial: Boolean(formik.values.learningMaterial),
  };
};

// SectionScheduleのメインのカスタムhooks
type UseSectionScheduleFormFieldsProps = {
  sectionSchedule: SectionSchedule | null;
  initialDate?: Date;
};
export const useSectionScheduleForm = ({
  sectionSchedule,
  initialDate,
}: UseSectionScheduleFormFieldsProps) => {
  const section = useSectionContext();

  const {
    data: teachers,
    isLoading: teacherLoding,
    error: teacherError,
  } = useFetchTeachers({
    sectionId: section.id,
    status: "active",
    shouldGetAllRecords: true,
  });
  useQueryError(teacherError);
  const teacherOptions = React.useMemo(
    () =>
      teachers?.map((teacher) => ({
        label: teacher.fullName,
        value: teacher.id,
      })),
    [teachers],
  );

  const {
    data: classrooms,
    isLoading: classroomLoading,
    error: classroomError,
  } = useFetchClassrooms({
    sectionId: section.id,
    shouldGetAllRecords: true,
  });
  useQueryError(classroomError);
  const classroomOptions = React.useMemo(
    () =>
      classrooms?.map((classroom) => ({
        label: classroom.name,
        value: classroom.id,
      })),
    [classrooms],
  );

  const {
    data: sectionScheduleData,
    isLoading: sectionScheduleLoading,
    error: sectionScheduleError,
    isError,
  } = useFetchSectionSchedule({
    sectionSchedule: sectionSchedule ?? null,
    section,
  });
  // エラー時のコールバック処理
  React.useEffect(() => {
    if (isError) {
      showErrorMessage("該当予定の取得に失敗しました");
    }
  }, [isError]);
  useQueryError(sectionScheduleError);

  const {
    data: lectures,
    isLoading: lecturesLoading,
    error: lecturesError,
  } = useFetchLectures({
    sectionId: section.id,
    archiveStatus: "active",
    shouldGetAllRecords: true,
  });
  useQueryError(lecturesError);
  const lectureOptions = React.useMemo(
    () =>
      lectures?.map((lecture) => ({ label: lecture.name, value: lecture.id })),
    [lectures],
  );

  const { showErrorMessage, showSuccessMessage } = useFlashMessage();
  const { close } = useModalContext();

  const { mutate, isPending: isMutating } = useMutateSectionSchedule({
    section,
    onSuccess() {
      showSuccessMessage("予定を登録しました");
      close();
    },
    onError() {
      showErrorMessage("予定の登録に失敗しました");
    },
  });

  const formProps = useFormikWithSectionSchedule({
    sectionScheduleDetail: sectionScheduleData ?? null,
    initialDate,
    onSubmit(value) {
      mutate(toSectionScheduleParam(value));
    },
  });

  const isLoading =
    teacherLoding ||
    classroomLoading ||
    lecturesLoading ||
    // 編集の時は校舎予定のAPIのリクエストを待つ、編集に行くためには直前で詳細モーダルを見るフローになっているため、そこでキャッシュが効くので実質ほぼ待たないと思う
    (sectionSchedule && sectionScheduleLoading);
  return {
    isMutating,
    isLoading,
    teacherOptions,
    lectureOptions,
    classroomOptions,
    section,
    ...formProps,
  };
};

// startDate, startTime, endDate, endTimeから"何時間何分"を求める
// 学習計画の時間の同期に必要
type CaclDurationParam = Pick<
  SectionScheduleValue,
  "startDate" | "startTime" | "endDate" | "endTime"
>;
const calcDuration = (param: CaclDurationParam) => {
  const startAt = parseDateTime(param.startDate, param.startTime);
  const endAt = parseDateTime(param.endDate, param.endTime);
  const durationMs = endAt.getTime() - startAt.getTime();
  if (durationMs < 0) {
    return {
      hours: 0,
      minutes: 0,
    };
  }
  const durationSec = durationMs / 1000;
  return {
    hours: Math.floor(durationSec / 3600),
    minutes: Math.floor((durationSec % 3600) / 60),
  };
};

// Formikの値(Values)からuseMutateSectionSchedule(校舎予定登録APIのhooks)の引数に渡せる形に変換する
const toSectionScheduleParam = (
  value: SectionScheduleValue,
): SectionScheduleMutateParam => {
  const startAt = parseDateTime(value.startDate, value.startTime);
  const endAt = parseDateTime(value.endDate, value.endTime);
  return {
    ...(value.id ? { id: value.id } : {}),
    summary: value.summary,
    startAt: value.allday ? startOfDay(startAt) : startAt,
    // NOTE:
    // フォーム上では見た目の都合上1日引いた値で保持しているため、
    // 送信値にするときは1日足して戻す
    endAt: value.allday ? addDays(startOfDay(endAt), 1) : endAt,
    locale: value.locale,
    description: value.description,
    classroomId: value.classroom?.value ?? null,
    lectureId: value.lecture?.value ?? null,
    teacherId: value.teacher?.value ?? null,
    allday: value.allday,
    studentIds: value.students?.map((s) => s.value) ?? [],
    studySchedule: value.learningMaterial
      ? {
          learningMaterialPublicId: value.learningMaterial.value,
          amount: value.amount,
          numberOfSeconds:
            value.numberOfHours * 3600 + value.numberOfMinutes * 60,
        }
      : null,
  };
};
