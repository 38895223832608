import classNames from "classnames";
import * as React from "react";
import { useState } from "react";
import Button, {
  ButtonTheme,
  ButtonSize,
} from "../../../../../components/atoms/Button";
import CircleIcon from "../../../../../components/atoms/CircleIcon";
import Label from "../../../../../components/atoms/Label";
import { Modal } from "../../../../../components/atoms/Modal";
import SwitchButton from "../../../../../components/atoms/SwitchButton";
import { ApiErrorInterface } from "../../../../../interfaces/ApiErrorResponseInterface.ts";
import { ZoomMeetingSettingInterface } from "../../../../../interfaces/OperatorProfileInterface.ts";
import { OperatorHelpLink } from "../OperatorHelpLink";
import { ZoomSettingForm } from "./ZoomSettingForm.tsx";
import styles from "./styles.scss";

type Props = {
  connected: boolean;
  email?: string;
  onSwitch: (value: boolean) => Promise<boolean>;
  zoomMeetingSetting?: ZoomMeetingSettingInterface;
  updateZoomMeetingSetting: (params: ZoomMeetingSettingInterface) => void;
  submitting: boolean;
  meetingSettingErrors: ApiErrorInterface[];
};

const HELP_URL_TO_CONNECT =
  "https://fs-help.studyplus.co.jp/ja/articles/4310468";
const HELP_URL_TO_DISCONNECT =
  "https://fs-help.studyplus.co.jp/ja/articles/4375870";

const OperatorZoomForm = (props: Props): React.ReactElement<Props> => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.display}>
          <div className={styles.display__label}>
            <span className={styles.display__label__bold}>Zoom連携の設定</span>
            <HelpLink connected={props.connected} />
          </div>
          <SwitchButton
            aria-label={
              props.connected ? "Zoom連携を解除する" : "Zoomと連携する"
            }
            value={props.connected}
            label={{ on: "連携中", off: "未連携" }}
            onChange={props.onSwitch}
          />
        </div>
      </div>
      {props.connected && props.email && props.zoomMeetingSetting ? (
        <>
          <Label isMute={true}>Zoomアカウント</Label>
          <div className={styles.root}>
            <div
              className={classNames(styles.display, styles.display__setting)}
            >
              <div className={styles.display__label}>
                <CircleIcon
                  iconProps={{ name: "icon-zoom", iconClassName: styles.icon }}
                  className={styles.display__label__icon}
                />
                <span className={styles.display__label__email}>
                  {props.email}
                </span>
              </div>
              <div className={styles.setting__btn}>
                <Button
                  disabled={false}
                  theme={ButtonTheme.White}
                  size={ButtonSize.Small}
                  isRound={true}
                  onClick={handleOpenModal}
                >
                  ミーティング設定
                </Button>
              </div>
            </div>
          </div>
          <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
            <Modal.Header onClose={handleCloseModal}>
              ミーティング設定
            </Modal.Header>
            <Modal.Body>
              <ZoomSettingForm
                zoomMeetingSetting={props.zoomMeetingSetting}
                meetingSettingErrors={props.meetingSettingErrors}
                updateZoomMeetingSetting={props.updateZoomMeetingSetting}
                submitting={props.submitting}
              />
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </>
  );
};

const HelpLink = ({ connected }: { connected: boolean }) => {
  const [helpUrl, label] = connected
    ? [HELP_URL_TO_DISCONNECT, "連携解除"]
    : [HELP_URL_TO_CONNECT, "連携方法"];

  return <OperatorHelpLink href={helpUrl}>{label}</OperatorHelpLink>;
};

export default OperatorZoomForm;
