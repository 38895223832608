import { IconEdit } from "@studyplus/boron-ui";
import * as React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../../components/atoms/Loader";
import { AttachmentViewer } from "../../components/features/AttachmentViewer";
import { useAttachmentViewer } from "../../components/features/AttachmentViewer/useAttachmentViewer";
import { ExaminationResultDetail } from "../../components/features/ExaminationResultDetail";
import { MAX_NUM_OF_IMAGES } from "../../components/features/ExaminationResultFileAttachmentItem";
import { ExaminationResultTable } from "../../components/features/ExaminationResultTable";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbItemLink,
} from "../../components/general/Breadcrumb";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import enhanceStudentsPage from "../../hocs/enhanceStudentsPage";
import { useFetchExaminationResultAttachments } from "../../hooks/http/useFetchExaminationResultAttachments";
import StudentInterface from "../../interfaces/StudentInterface";
import { useFetchStudentExaminationResult } from "./useFetchStudentExaminationResult";

type Props = OutletContextProps & { student: StudentInterface };

const StudentsAnalyticsExaminationResultPage_ = ({
  student,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("analytics");
  }, []);

  const studentId = student.id;
  const { examinationId } = useParams<{
    examinationId: string;
  }>();

  const { data, isLoading } = useFetchStudentExaminationResult({
    studentId,
    examinationId: examinationId ?? null,
  });

  const { data: attachments = [] } = useFetchExaminationResultAttachments(data);
  const files = attachments.map((attachment) => attachment.file);

  const attachmentViewerProps = useAttachmentViewer({
    files,
    maxPageNo: MAX_NUM_OF_IMAGES,
  });

  return (
    <>
      {isLoading && <Loader loading />}
      {data && !isLoading && (
        <>
          <div className="mt-5">
            <Breadcrumb>
              <BreadcrumbItem>
                <BreadcrumbItemLink
                  href={`/students/${student.id}/analytics/examination`}
                >
                  <div className="mx-4 text-lg">成績記録一覧</div>
                </BreadcrumbItemLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrent>
                <div className="mx-4 text-lg">{data.examination.name}</div>
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div
            className={
              "mt-7 rounded-md border border-solid border-gray-400 bg-white p-7"
            }
          >
            <div className="mt-4 flex flex-col items-start justify-between pb-9 text-lg font-light leading-none lg:flex-row">
              <ExaminationResultDetail
                examinedOn={data.examinedOn}
                organizerName={data.examination.organizer.name}
                classificationName={data.examination.classification.name}
                targetJobName={data.examination.targetJob.human}
                examinationName={data.examination.name}
              />
              {(student.status === "active" ||
                student.status === "inviting") && (
                <div className="self-end pt-4 lg:self-auto">
                  <Link
                    to={`/students/${student.id}/analytics/examinations/${examinationId}/result/edit`}
                    className={`buiButtonBase buiButtonVariantOutline buiButtonSizeSm`}
                  >
                    <IconEdit />
                    編集
                  </Link>
                </div>
              )}
            </div>
            <div className="flex flex-col lg:flex-row">
              <div
                className={`w-full ${
                  data.resultAttachments.length > 1
                    ? "lg:max-w-[520px]"
                    : "lg:w-[70%]"
                }`}
              >
                <ExaminationResultTable examinationResult={data} />
              </div>
              {data.resultAttachments.length > 0 && (
                <div className="mt-4 h-fit max-w-4xl rounded bg-gray-100 p-7 lg:ml-4 lg:mt-0 lg:w-full">
                  {files.length > 0 ? (
                    <AttachmentViewer
                      {...attachmentViewerProps}
                      maxPageNo={MAX_NUM_OF_IMAGES}
                    />
                  ) : (
                    <Loader loading />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const pageInfo = {
  title: "アナリティクス",
};
export const StudentAnalyticsExaminationResultPage = withRouter(
  enhanceStudentsPage(StudentsAnalyticsExaminationResultPage_, pageInfo),
);
