import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import {
  Classroom,
  maxClassNameSize,
  maxCorrectionRange,
  maxLatValue,
  maxLonValue,
  minCorrectionRange,
  minLatValue,
  minLonValue,
} from "../../../../domains/Classroom";

export type FormValue = Omit<Classroom, "id" | "latitude" | "longitude"> & {
  latitudeAndLongitude: string;
};

type Props = {
  onSubmit: (value: FormValue) => void;
  initialValues: Classroom | null;
};

export const useFormikWithClassroom = ({ onSubmit, initialValues }: Props) => {
  return useFormik<FormValue>({
    initialValues: initialValues
      ? {
          name: initialValues.name,
          latitudeAndLongitude: initialValues.latitude
            ? `${initialValues.latitude}, ${initialValues.longitude}`
            : "",
          correctionRange: initialValues.correctionRange,
        }
      : {
          name: "",
          latitudeAndLongitude: "",
          correctionRange: 300,
        },
    enableReinitialize: true,
    validate,
    validationSchema,
    onSubmit(value) {
      onSubmit(validationSchema.cast(value) as FormValue);
    },
  });
};

// yupのtransformメソッドの引数の定義がanyになっているため明示的にanyを使用
const transformNullableNumber = (value: any, originalValue: any) =>
  String(originalValue) === "" ? null : value;

const exceededCorrectionRangeErrorMessage = `許容範囲は${minCorrectionRange}〜${maxCorrectionRange}の数値で入力してください`;

type Values = {
  name: string;
  latitudeAndLongitude: string;
  correctionRange: number | null;
};

export const validate = (
  values: Values,
): FormikErrors<Record<string, string>> => {
  const errors: FormikErrors<Record<string, string>> = {};

  const [latitude, longitude, ...rest] = parseLatitudeAndLongitude(
    values.latitudeAndLongitude,
  );

  if (rest.length > 0) {
    errors.latitudeAndLongitude = "緯度・経度の形式が不正です";
  }

  if (
    values.latitudeAndLongitude &&
    (Number.isNaN(Number(latitude)) || Number.isNaN(Number(longitude)))
  ) {
    errors.latitudeAndLongitude = "緯度・経度は数値で入力してください";
  }

  if (latitude < minLatValue || latitude > maxLatValue) {
    errors.latitudeAndLongitude = `緯度は${minLatValue}～${maxLatValue}の範囲で入力してください`;
  }

  if (longitude < minLonValue || longitude > maxLonValue) {
    errors.latitudeAndLongitude = `経度は${minLonValue}～${maxLonValue}の範囲で入力してください`;
  }

  return errors;
};

export const validationSchema = yup
  .object<Record<keyof FormValue, yup.AnyObjectSchema>>()
  .shape({
    name: yup
      .string()
      .trim()
      .required("教室名を入力してください")
      .max(
        maxClassNameSize,
        `教室名は${maxClassNameSize}文字以下で入力してください`,
      ),
    latitudeAndLongitude: yup.string().trim(),
    correctionRange: yup
      .number()
      .nullable()
      .typeError("有効範囲は数値で入力してください")
      .min(minCorrectionRange, exceededCorrectionRangeErrorMessage)
      .max(maxCorrectionRange, exceededCorrectionRangeErrorMessage)
      .transform(transformNullableNumber)
      .when(["latitudeAndLongitude"], {
        is: (latitudeAndLongitude: string) => latitudeAndLongitude?.length > 0,
        then: yup
          .number()
          .nullable()
          .required("有効範囲を入力してください")
          .transform(transformNullableNumber),
      }),
  });

export const parseLatitudeAndLongitude = (value: string): number[] => {
  return value
    .trim()
    .split(",")
    .map((str) => parseFloat(str));
};
