import { Button } from "@studyplus/boron-ui";
import StudentTag from "../../../domains/StudentTag";
import ApiResponse from "../../../interfaces/ApiResponse";
import { Recipient } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import Loader from "../../atoms/Loader";
import { Modal } from "../../atoms/Modal/index";
import styles from "./styles.scss";

interface RecipientsState extends ApiResponse<Recipient[]> {
  loading: boolean;
  hasMore: boolean;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  destinationTag: StudentTag | null;
  recipients?: RecipientsState;
  onLoadMore: () => void;
  totalCount: number;

  // NOTE: 現状は生徒の一斉メッセージの既読数のみを表示するためoptionalにしている
  receivedTotalCount?: number | undefined;
}

const renderRecipients = (
  onLoadMore: () => void,
  recipients?: RecipientsState,
) => {
  if (!recipients || !recipients.data) {
    return null;
  }

  return (
    <div className={styles.recipients}>
      <ul>
        {recipients.data.map((recipient: Recipient) => {
          return (
            <li key={recipient.id} className={styles.recipient}>
              <div className="flex justify-between">
                <p>{recipient.fullName}</p>
                {recipient.receivedAt && <p className="pr-5 text-md">既読</p>}
              </div>
            </li>
          );
        })}
      </ul>
      <Loader loading={recipients.loading} />
      {recipients.hasMore && !recipients.loading ? (
        <Button
          onClick={onLoadMore}
          disabled={false}
          variant="outline"
          isBlock={true}
          size="xs"
          className={styles.recipients__btn}
        >
          もっと見る
        </Button>
      ) : null}
    </div>
  );
};

const AnnounceMessageRecipientsModal = ({
  receivedTotalCount,
  isOpen,
  destinationTag,
  totalCount,
  recipients,
  onClose,
  onLoadMore,
}: Props) => {
  const isReceivedTotalCountVisible =
    receivedTotalCount !== undefined && receivedTotalCount > 0;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.root}>
      <Modal.Header onClose={onClose}>
        {destinationTag && (
          <h2 className="flex gap-8">
            <div>受信者：{totalCount}名</div>
            {isReceivedTotalCountVisible && (
              <div>既読：{receivedTotalCount}名</div>
            )}
          </h2>
        )}
      </Modal.Header>
      {renderRecipients(onLoadMore, recipients)}
    </Modal>
  );
};

export default AnnounceMessageRecipientsModal;
