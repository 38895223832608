import { Button } from "@studyplus/boron-ui";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { FSHTTPError } from "../../../../errors.ts";
import { useAddStaffBoardPostCommentToListCache } from "../../../../features/SectionsStaffBoardPostsPage/useFetchStaffBoardPosts.ts";
import { useAddStaffBoardPostCommentToSingleCache } from "../../../../hooks/http/useFetchStaffBoardPost.ts";
import { usePostStaffBoardPostComment } from "../../../../hooks/http/usePostStaffBoardPostComment.tsx";
import { useFlashMessage } from "../../../../hooks/useFlashMessage.ts";
import OperatorInterface from "../../../../interfaces/OperatorInterface.ts";
import StaffBoardPostInterface, {
  StaffBoardPostCommentFormInterface,
} from "../../../../interfaces/StaffBoardPostInterface.ts";
import { HTTP_ERROR_MESSAGE } from "../../../../reducers";
import Input from "../../../atoms/Input";
import Textarea from "../../../atoms/Textarea";
import styles from "./styles.scss";
import { validationSchema } from "./validationSchema.ts";

type Props = {
  sectionId: string;
  currentOperator: OperatorInterface;
  staffBoardPost: StaffBoardPostInterface;
  isDeletingComment: boolean;
};
type Values = StaffBoardPostCommentFormInterface;

const StaffBoardPostCommentForm: React.FC<Props> = (props) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { mutate: submitComment, isPending: isSubmitting } =
    usePostStaffBoardPostComment({
      sectionId: props.sectionId,
      staffBoardPostId: props.staffBoardPost.id,
    });
  const { addStaffBoardPostCommentToListCache } =
    useAddStaffBoardPostCommentToListCache({
      sectionId: props.sectionId,
      staffBoardPostId: props.staffBoardPost.id,
    });
  const { addStaffBoardPostCommentToSingleCache } =
    useAddStaffBoardPostCommentToSingleCache({
      sectionId: props.sectionId,
      staffBoardPostId: props.staffBoardPost.id,
    });

  const handleSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => {
    submitComment(values, {
      onSuccess: (data) => {
        addStaffBoardPostCommentToListCache(data.staffBoardPostComment);
        addStaffBoardPostCommentToSingleCache(data.staffBoardPostComment);
        showSuccessMessage("コメントを投稿しました");
        formikHelpers.resetForm();
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage("コメントの投稿に失敗しました");
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    });
  };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={{
        name: props.currentOperator.fullName,
        comment: "",
      }}
    >
      {(formikProps) => (
        <Form
          className={styles.root}
          aria-label={`${props.staffBoardPost.title}へのコメントフォーム`}
        >
          <Field
            name="name"
            className={styles.name}
            component={Input}
            hasError={formikProps.errors.name && formikProps.touched.name}
          />
          <Field
            placeholder="コメントを入力"
            name="comment"
            rows={1}
            component={Textarea}
            hasError={formikProps.errors.comment && formikProps.touched.comment}
          />
          <Button
            disabled={
              props.isDeletingComment || isSubmitting || !formikProps.isValid
            }
            type="submit"
            className={styles.button}
          >
            送信
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default StaffBoardPostCommentForm;
