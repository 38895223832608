import {
  Progress,
  calcTotalCount,
  toPercent,
} from "../../../domains/DrillProgress";
import TimeHelper from "../../../helpers/TimeHelper";
import { DrillProgressBarGroup } from "../DrillProgressBarGroup";

type Props = {
  progress: Progress;
  duration?: number;
  keysIncludedInPercentage?: Array<keyof Progress>;
  className?: string;
};

export const DrillProgressCell = ({
  progress,
  duration,
  keysIncludedInPercentage,
  className,
}: Props) => {
  const totalCount = calcTotalCount(progress);
  return (
    <div className={`flex h-12 w-full flex-col justify-between ${className}`}>
      <div className="flex grow items-center justify-center text-black">
        {duration !== undefined
          ? TimeHelper.secondsToDisplayTime(duration)
          : displayPercentage({
              progress,
              keysIncludedInPercentage,
              totalCount,
            })}
      </div>
      <div className="flex w-full">
        <DrillProgressBarGroup totalCount={totalCount} progress={progress} />
      </div>
    </div>
  );
};

const displayPercentage = ({
  progress,
  keysIncludedInPercentage,
  totalCount,
}: Props & { totalCount: number }) => {
  if (!keysIncludedInPercentage) return "";

  const numerator = keysIncludedInPercentage
    .map((k) => progress[k])
    .reduce((p, c) => p + c);

  return toPercent(numerator, totalCount);
};
