import classnames from "classnames";
import * as React from "react";
import { ColorProps, resolveColorStyles } from "../../../helpers/styles/Colors";
import styles from "./Text.module.scss";

type ActualHtmlTags =
  | "div"
  | "span"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p";
type Align = "left" | "center" | "right";
type Size = "xl" | "lg" | "md" | "sm" | "xs";
type WhiteSpace = "normal" | "nowrap" | "pre-line";
type LineClamp = 0 | 1 | 2 | 3;

export type Props = {
  as?: ActualHtmlTags;
  bold?: boolean;
  align?: Align;
  size?: Size;
  whiteSpace?: WhiteSpace;
  lineClamp?: LineClamp;
} & Pick<ColorProps, "color" | "hoverColor"> &
  Pick<React.HTMLAttributes<HTMLElement>, "className">; // デザイントークンのルールにしづらいスタイルを入れないといけない時のための脱出ハッチ

export const Text = ({
  as = "div",
  children,
  bold,
  align = "left",
  size = "md",
  whiteSpace = "normal",
  lineClamp = 0,
  className,
  ...props
}: React.PropsWithChildren<Props>) => {
  const Tag = as;
  return (
    <Tag
      className={classnames(
        classNames.size[size],
        classNames.align[align],
        classNames.whiteSpace[whiteSpace],
        {
          [styles.bold]: Boolean(bold),
          [styles.lineClamp]: lineClamp !== 0,
          [styles.lineClamp1]: lineClamp === 1,
          [styles.lineClamp2]: lineClamp === 2,
          [styles.lineClamp3]: lineClamp === 3,
        },
        resolveColorStyles(props),
        className,
      )}
    >
      {children}
    </Tag>
  );
};

const classNames = {
  size: {
    xl: [styles.xlSize],
    lg: [styles.lgSize],
    md: [styles.mdSize],
    sm: [styles.smSize],
    xs: [styles.xsSize],
  },
  align: {
    left: styles.alignLeft,
    center: styles.alignCenter,
    right: styles.alignRight,
  },
  whiteSpace: {
    normal: styles.whiteSpaceNormal,
    nowrap: styles.whiteSpaceNowrap,
    "pre-line": styles.whiteSpacePreLine,
  },
};
