import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import StudentTag from "../../../domains/StudentTag";
import { Modal } from "../../atoms/Modal";
import DeprecatedTagButton from "../../features/DeprecatedTagButton";
import { TagSelector } from "../../features/TagSelector/index";
import styles from "./styles.scss";

export enum SelectTagsFor {
  Operator,
  Student,
}

interface Props extends React.PropsWithChildren<unknown> {
  submitting: boolean;
  selectTagsFor: SelectTagsFor;
  selectedTags: StudentTag[];
  sectionTags: StudentTag[];
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (selectedTags: StudentTag[]) => void;
}

interface State {
  filteredTags: StudentTag[];
  selectedTags: StudentTag[];
}

class SelectTagModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filteredTags: props.sectionTags,
      selectedTags: props.selectedTags,
    };
  }

  private isSelectedTag = (tag: StudentTag): boolean => {
    return this.state.selectedTags.some(
      (selectedTag: StudentTag) => tag.id === selectedTag.id,
    );
  };

  private onSelectTag = (tag: StudentTag) => (): void => {
    const selectedTag = this.props.sectionTags.find((t) => tag.id === t.id);
    if (selectedTag) {
      this.setState({
        selectedTags: Array.from(
          new Set([...this.state.selectedTags, selectedTag]),
        ),
      });
    }
  };

  private onDeselectTag = (deselectedTag: StudentTag) => () => {
    this.setState({
      selectedTags: this.state.selectedTags.filter(
        (tag: StudentTag) => deselectedTag.id !== tag.id,
      ),
    });
  };

  private handleClose = () => {
    this.props.onRequestClose();
    this.setState({
      filteredTags: this.props.sectionTags,
      selectedTags: this.props.selectedTags,
    });
  };

  private handleSubmit = () => {
    if (this.props.submitting) return;
    this.props.onSubmit(this.state.selectedTags);
  };

  private renderSelectedTags() {
    if (this.state.selectedTags.length > 0) {
      return this.state.selectedTags.map((tag: StudentTag) => {
        return (
          <span className={styles.tagBtn} key={`section-tags-${tag.id}`}>
            <DeprecatedTagButton
              label={tag.name}
              theme="default"
              iconName="icon-close-x"
              onClick={this.onDeselectTag(tag)}
            />
          </span>
        );
      });
    } else {
      this.props.selectTagsFor === SelectTagsFor.Student ? (
        <span>タグを選択</span>
      ) : (
        <span>閲覧制限したいタグを選択</span>
      );
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} onRequestClose={this.handleClose}>
        <Modal.Header onClose={this.handleClose}>
          {this.props.children}
        </Modal.Header>
        <Modal.Body>
          {this.props.selectTagsFor == SelectTagsFor.Student ? null : (
            <p className={styles.notification}>
              ※タグを選択しない場合はすべての生徒が閲覧できます
            </p>
          )}
          <TagSelector
            tags={this.props.sectionTags}
            selectedTags={this.props.selectedTags}
            renderTag={(tag) => (
              <SelectedTagButton
                tag={tag}
                onClick={
                  this.isSelectedTag(tag)
                    ? this.onDeselectTag(tag)
                    : this.onSelectTag(tag)
                }
                isSelected={this.isSelectedTag(tag)}
              />
            )}
          />
          <div className={styles.selectedTagBox}>
            {this.renderSelectedTags()}
          </div>
          <div className={styles.footer}>
            <Button
              disabled={this.props.submitting}
              onClick={this.handleSubmit}
              className={styles.submitBtn}
            >
              タグを設定
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const SelectedTagButton = ({
  tag,
  onClick,
  isSelected,
}: {
  tag: StudentTag;
  onClick: () => void;
  isSelected: boolean;
}) => {
  return (
    <DeprecatedTagButton
      label={tag.name}
      theme={isSelected ? "main" : "gray"}
      onClick={onClick}
    />
  );
};

export default SelectTagModal;
