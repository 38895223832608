import classNames from "classnames";
import SectionInterface from "../../../interfaces/SectionInterface";
import NaviMenuSPToggleButton from "../NaviMenuSPToggleButton/index";
import styles from "./styles.scss";

interface Props {
  opened: boolean;
  section: SectionInterface;
}

const NaviMenuSPLogo = (props: Props) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.opened]: props.opened,
      })}
    >
      <div className={styles.logoTxt}>
        <img src="/images/logo_txt.svg" />
      </div>
      <div className={styles.button}>
        <NaviMenuSPToggleButton />
      </div>
    </div>
  );
};

export default NaviMenuSPLogo;
