import EmergencyInterface from "../../../interfaces/EmergencyInterface";
import styles from "./styles.scss";
const nl2br = require("react-nl2br");

interface Props {
  emergency: EmergencyInterface | null;
}

const SteakError = (props: Props) => {
  if (props.emergency && props.emergency.body) {
    return (
      <div className={styles.root}>
        <h2>Studyplusからのお知らせ</h2>
        <blockquote>{nl2br(props.emergency.body)}</blockquote>

        <hr className={styles.line} />
        <p>
          Studyplus for
          Schoolでは、Studyplusにアクセスできない場合、以下の機能が使用できません。
        </p>
        <ul className={styles.list}>
          <li>招待中生徒の連携</li>
          <li>連携中生徒の連携停止</li>
          <li>連携コードを発行したことがない校舎の連携コード発行</li>
          <li>勉強記録へのいいね・コメント</li>
          <li>メッセージの受信・送信（過去の閲覧は可能です）</li>
          <li>校舎独自教材の作成</li>
          <li>生徒へのテキスト登録</li>
          <li>プランニングの登録</li>
          <li>一括操作の教材・本棚登録</li>
        </ul>
      </div>
    );
  } else {
    return (
      <div className={styles.root}>
        <p>Studyplusに障害が発生している可能性があります。</p>
        <p>
          しばらく時間をおいて再度実行してもこの画面が表示される場合は、運営までお問い合わせください。
        </p>
      </div>
    );
  }
};

export default SteakError;
