import { MessageNotificationInterface } from "../../../interfaces/NotificationInterface";
import HeaderNotificationEmptyList from "../../atoms/HeaderNotificationEmptyList";
import HeaderNotificationList from "../../atoms/HeaderNotificationList";
import HeaderNotificationLoading from "../../atoms/HeaderNotificationLoading";
import HeaderBotMessageNotificationItem from "../HeaderBotMessageNotificationItem/index";
import HeaderDirectMessageNotificationItem from "../HeaderDirectMessageNotificationItem";

type MessageNotificationType = "DirectMessage" | "BotMessage";
interface Props {
  loading: boolean;
  messages: MessageNotificationInterface[];
  type: MessageNotificationType;
}

const renderItem = (
  message: MessageNotificationInterface,
  type: MessageNotificationType,
) => {
  return type === "DirectMessage" ? (
    <HeaderDirectMessageNotificationItem
      message={message}
      key={`header-direct-message-notification-item-${message.id}`}
    />
  ) : (
    <HeaderBotMessageNotificationItem
      message={message}
      key={`header-direct-message-notification-item-${message.id}`}
    />
  );
};

const renderList = (props: Props) => {
  const { messages, type } = props;
  return (
    <HeaderNotificationList>
      {messages.map((message) => {
        return renderItem(message, type);
      })}
    </HeaderNotificationList>
  );
};

const HeaderMessageNotificationList = (props: Props) => {
  if (props.loading) {
    return <HeaderNotificationLoading />;
  } else if (props.messages.length <= 0) {
    return <HeaderNotificationEmptyList label="メッセージはありません" />;
  } else {
    return renderList(props);
  }
};

export default HeaderMessageNotificationList;
