import * as React from "react";

import GuardianMessage from "../../components/features/GuardianMessage";
import TabsNavi from "../../components/molecules/TabsNavi";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../hocs/enhanceAuthenticatedPage";
import enhanceStudentsPage from "../../hocs/enhanceStudentsPage";
import { useFetchStudentGuardianMessages } from "../../hooks/http/useFetchStudentGuardianMessages";
import { useQueryError } from "../../hooks/http/useQueryError";
import StudentInterface from "../../interfaces/StudentInterface";
import styles from "./styles.scss";

type Props = WithRouterProps<AuthenticateRouterProps> &
  OutletContextProps & {
    student: StudentInterface | null;
  };

const StudentsGuardianMessagesPage = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("message");
  }, []);

  const { student } = props;
  const { guardianMessagesProps, loadMoreMessages } =
    useStudentGuardianMessages(props);

  if (!student) {
    return null;
  }

  return (
    <>
      <div style={{ marginTop: "-1.0rem" }}>
        <TabsNavi
          tabs={[
            {
              label: "生徒",
              link: {
                pathname: `/students/${student.id}/messages`,
                search: location.search,
              },
              active: false,
            },
            {
              label: "保護者",
              link: {
                pathname: `/students/${student.id}/guardian_messages`,
                search: location.search,
              },
              active: true,
            },
          ]}
        />
      </div>
      <div className={styles.root}>
        <GuardianMessage
          student={student}
          guardianMessagesProps={guardianMessagesProps}
          loadMoreMessages={loadMoreMessages}
          showCloseIcon={false}
        />
      </div>
    </>
  );
};

const useStudentGuardianMessages = (props: Props) => {
  const {
    data,
    meta,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
  } = useFetchStudentGuardianMessages({
    studentId: props.student?.id || "",
  });
  useQueryError(error);

  const guardianMessagesProps = {
    data: data || [],
    meta: meta || { till: undefined },
    loading: isLoading || isFetchingNextPage,
    hasMore: hasNextPage || false,
  };

  return {
    guardianMessagesProps,
    loadMoreMessages: fetchNextPage,
  };
};

const pageInfo = {
  title: "保護者メッセージ",
};

export default enhanceStudentsPage(StudentsGuardianMessagesPage, pageInfo);
