import * as queryString from "query-string";
import * as React from "react";
import { FilterParams } from "../../../containers/pages/SectionsSettingsManagementLearningMaterialsPage/useManagementLearningMaterialApi";
import {
  LearningMaterialTag,
  toTagParams,
} from "../../../domains/LearningMaterialTag";
import { createContext } from "../../../helpers/React";
import { useEntityArray } from "../../../hooks/useEntityArray";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { usePushHistory } from "../../../hooks/usePushHistory";

const { useContext, Provider } =
  createContext<ReturnType<typeof useLearningMaterialFilterWithLocalStorage>>();

export const useLearningMaterialFilterWithLocalStorageContext = useContext;

const useLearningMaterialFilterWithLocalStorage = () => {
  // 校舎IDごとに状態をもつ
  const [learningMaterialFilters, setLearningMaterialFilters] = React.useState<
    Record<string, LearningMaterialTag[]>
  >({});
  const [filterKey, setFilterKey] = React.useState<string | null>(null);
  const selectedOptionsState = useEntityArray<LearningMaterialTag>();
  const [isTagLoaded, setIsTagLoaded] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);

  const localStorage = useLocalStorage<Record<string, LearningMaterialTag[]>>(
    "LearningMaterialFilters",
  );
  // LocalStorageからの復元
  React.useEffect(() => {
    const learningMaterialFiltersFromStorage = localStorage.get();
    if (learningMaterialFiltersFromStorage) {
      setLearningMaterialFilters(learningMaterialFiltersFromStorage);
    }
  }, []);

  // LocalStorageへの保存
  React.useEffect(() => {
    if (!filterKey) return;
    if (!isInitialized) return;

    if (localStorage) {
      try {
        localStorage.set(learningMaterialFilters);
      } catch {
        // NOTE: 以下のエラーが発生して真っ白な画面になってしまう時があるため対処
        // DOMException: Failed to execute 'setItem' on 'Storage': Setting the value of 'xxx' exceeded the quota.
      }
    }
  }, [learningMaterialFilters]);

  // 他校舎も含めて管理する全体のstateを更新する
  React.useEffect(() => {
    if (!filterKey) return;
    if (!isInitialized) return;

    setLearningMaterialFilters((prev) => {
      return {
        ...prev,
        [filterKey]: selectedOptionsState.items,
      };
    });
  }, [selectedOptionsState.items]);

  // 変更されたKeyに対応するフィルターを適用する
  React.useLayoutEffect(() => {
    if (filterKey && learningMaterialFilters[filterKey]) {
      selectedOptionsState.replace(learningMaterialFilters[filterKey]);
    }
  }, [filterKey]);

  // URLパラメータからフィルターを復元する
  const updateFilterByQuery = (tags: LearningMaterialTag[]) => {
    const { learningMaterialTagIds, haveNoTag } = getFilterParamsByQuery();

    selectedOptionsState.replace(
      tags.filter((tag) => {
        return (
          learningMaterialTagIds?.includes(tag.id) ||
          (haveNoTag === "true" && tag.tagType === "have_no_tag")
        );
      }),
    );
  };

  const { pushHistory } = usePushHistory<Partial<FilterParams>>();
  // 指定されたタグで初期化する
  const initializeWithOptions = (items: LearningMaterialTag[]) => {
    if (isInitialized) return;

    const { learning_material_tag_ids, have_no_tag } = toTagParams({
      tags: items,
    });
    pushHistory({ learning_material_tag_ids, have_no_tag }, { replace: true });

    setIsInitialized(true);
  };

  // URLパラメータからフィルターを復元して初期化する
  const initializeWithQuery = (tags: LearningMaterialTag[]) => {
    if (isInitialized) return false;
    if (!isTagLoaded) return false;
    // フィルターに関するURLパラメータがない場合は何もしない
    const { learningMaterialTagIds, haveNoTag } = getFilterParamsByQuery();
    if (!learningMaterialTagIds && !haveNoTag) return false;

    updateFilterByQuery(tags);

    setIsInitialized(true);

    return true;
  };

  // ローカルストレージからフィルターを復元して初期化する
  const initializeWithStorage = () => {
    if (isInitialized) return;
    if (!isTagLoaded) return;
    if (!filterKey) return;

    const tags = learningMaterialFilters[filterKey];
    if (tags && tags.length > 0) {
      const { learning_material_tag_ids, have_no_tag } = toTagParams({
        tags,
      });
      pushHistory(
        { learning_material_tag_ids, have_no_tag },
        { replace: true },
      );
    }
    setIsInitialized(true);
  };

  // フィルターに関するURLパラメータを取得する
  // NOTE: 生徒フィルターと共存させた時に、生徒フィルターに関するURLパラメータに影響されないようにしたい
  const getFilterParamsByQuery = () => {
    const queryParams = queryString.parse(location.search, {
      arrayFormat: "bracket",
    });
    const learningMaterialTagIds = queryParams.learning_material_tag_ids;
    const haveNoTag = queryParams.have_no_tag;

    return {
      learningMaterialTagIds,
      haveNoTag,
    };
  };

  return React.useMemo(
    () => ({
      ...selectedOptionsState,
      initializeWithOptions,
      initializeWithQuery,
      initializeWithStorage,
      isInitialized,
      setIsInitialized,
      setFilterKey,
      updateFilterByQuery,
      setIsTagLoaded,
      isTagLoaded,
    }),
    [
      selectedOptionsState.items,
      initializeWithOptions,
      initializeWithQuery,
      initializeWithStorage,
      isInitialized,
      setIsInitialized,
      setFilterKey,
      updateFilterByQuery,
      setIsTagLoaded,
      isTagLoaded,
    ],
  );
};

// 現状でdispatcherだけ必要なコンポーネントのユースケースがないのでProviderは分けない
export const LearningMaterialFilterWithLocalStorageProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return (
    <Provider value={useLearningMaterialFilterWithLocalStorage()}>
      {children}
    </Provider>
  );
};

type WithLocalStorageContextType = ReturnType<
  typeof useLearningMaterialFilterWithLocalStorage
>;
export const isWithLocalStorageContextType = (
  arg: any,
): arg is WithLocalStorageContextType => {
  return arg.setFilterKey !== undefined;
};
