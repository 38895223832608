import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import { LectureTransfer } from "../../../domains/LectureAttendance";
import { HTTPErrors, createError } from "../../../errors";

type Props = {
  sectionId: string;
  lectureAttendanceId: string;
};
type ResponseData = LectureTransfer;
export const useFetchLectureTransfer = ({
  sectionId,
  lectureAttendanceId,
}: Props) => {
  return useQuery<ResponseData, HTTPErrors>({
    queryKey: cahceKeyOf(sectionId, lectureAttendanceId),
    queryFn: async () => {
      const response = await api.get(baseURLOf(sectionId, lectureAttendanceId));
      if (response.ok) {
        const json = await response.json();
        return json;
      }
      throw await createError(response);
    },
    enabled: lectureAttendanceId !== "",
  });
};

export const cahceKeyOf = (
  sectionId: string,
  lectureAttendanceId: string,
): string[] => [sectionId, "lecture_transfer", lectureAttendanceId];

const baseURLOf = (sectionId: string, lectureAttendanceId: string) =>
  `/api/v1/sections/${sectionId}/lecture_attendances/${lectureAttendanceId}/lecture_transfer`;
