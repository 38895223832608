import classNames from "classnames";
import * as React from "react";
import styles from "./styles.scss";

export interface SwitchTabsProps<T = string> {
  id: T;
  label: string | React.ReactNode;
  active: boolean;
  disabled?: boolean;
  onClick?: (e: any) => void;
}

interface Props {
  tabs: SwitchTabsProps[];
  onClickTab: (tab: any) => (e: any) => void;
}

const SwitchTabItem = (props: SwitchTabsProps) => {
  const onClick = (e: any) => {
    if (props.onClick && !props.disabled) {
      props.onClick(e);
    }
  };
  return (
    <li
      onClick={onClick}
      className={classNames(styles.tab, {
        "bg-gray-800 text-white": props.active,
        "cursor-not-allowed opacity-50": props.disabled,
        "bg-white text-gray-900 hover:bg-white hover:text-gray-900":
          !props.active && props.disabled,
        "pointer-events-none": props.active,
      })}
      role="radio"
      aria-checked={props.active}
    >
      {props.label}
    </li>
  );
};

const SwitchTabs: React.FC<Props> = (props) => (
  <ul className={styles.root} role="radiogroup">
    {props.tabs.map((tabItem) => (
      <SwitchTabItem
        {...tabItem}
        key={`switchTabs-${tabItem.id}`}
        onClick={props.onClickTab(tabItem)}
      />
    ))}
  </ul>
);

export default SwitchTabs;
