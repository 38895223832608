import SteakError from "../../../components/organisms/SteakError/index";
import ErrorTemplate from "../../../components/templates/ErrorTemplate/index";
import EmergencyInterface from "../../../interfaces/EmergencyInterface";

interface Props {
  emergency: EmergencyInterface | null;
}

const CannotAccessToSteakErrorPage = (props: Props) => {
  return (
    <ErrorTemplate
      title={"ただいま、Studyplusにアクセスすることができません。"}
      heading={"ただいま、Studyplusにアクセスすることができません。"}
    >
      <SteakError emergency={props.emergency} />
    </ErrorTemplate>
  );
};

export default CannotAccessToSteakErrorPage;
