import { useIsMutating } from "@tanstack/react-query";
import { boronClient } from "../../../../api";
import { useBoronMutation } from "../../../../hooks/http/useBoronMutation";
import { useDeleteSkillsCache } from "../useFetchSkills";

export const useDeleteSkill = ({ sectionId }: { sectionId: string }) => {
  const { deleteSkillsCache } = useDeleteSkillsCache({ sectionId });

  const { mutate: deleteSkill } = useBoronMutation(
    async (skillId: string) =>
      await boronClient.DELETE(
        "/api/v1/sections/{section_id}/skills/{skill_hashid}",
        {
          params: {
            path: { section_id: sectionId, skill_hashid: skillId },
          },
        },
      ),
    {
      mutationKey: ["deleteSkill", sectionId],
      onSuccess: (_, skillId) => {
        deleteSkillsCache(skillId);
      },
    },
  );

  const mutatingCount = useIsMutating({
    mutationKey: ["deleteSkill", sectionId],
  });
  const isDeletingSomeSkill = mutatingCount > 0;

  return {
    deleteSkill,
    isDeletingSomeSkill,
  };
};
