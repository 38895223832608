import { GroupBase, Props as SelectProps } from "react-select";
import { AsyncPaginate, AsyncPaginateProps } from "react-select-async-paginate";
import {
  DropdownIndicatorProps,
  IndicatorAlignItemsProps,
  SizeProps,
  customStyles,
} from "./styles";
import "./LoadableMultiSelect.scss";
import classNames from "classnames";

export type Additional = { page: number };
export type Props<OptionType, IsMulti extends boolean = false> = SelectProps<
  OptionType,
  IsMulti
> &
  AsyncPaginateProps<OptionType, GroupBase<OptionType>, Additional, IsMulti> &
  SizeProps &
  DropdownIndicatorProps &
  IndicatorAlignItemsProps & {
    hasError?: boolean;
  };

const defaultAdditional: Additional = { page: 1 };
export const LoadableSelect = <OptionType, IsMulti extends boolean = false>({
  ...restProps
}: Props<OptionType, IsMulti>) => {
  return (
    <AsyncPaginate
      debounceTimeout={1000}
      loadOptionsOnMenuOpen={false} // フォーカスがあたってmenuが開いたときに検索を走らせない
      additional={defaultAdditional}
      size="md" // customStylesで解決されるprops
      styles={customStyles as any} // 一旦anyにする。customStylesに設定しているOptionTypeがRecordで固定になってて回避する方法がまだわからくて型チェックが通らない
      {...restProps}
    />
  );
};

export const PrimaryLoadableMultiSelect = <OptionType,>({
  className,
  ...props
}: Props<OptionType, true>) => {
  return (
    <LoadableSelect
      isMulti
      {...props}
      className={classNames("react-select", className)}
      classNamePrefix="react-select"
    />
  );
};
