import { Button, IconHelp } from "@studyplus/boron-ui";
import { SectionAnalyticsTypeTab } from "../../components/features/SectionAnalyticsTypeTab";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbItemLink,
} from "../../components/general/Breadcrumb";
import SectionInterface from "../../interfaces/SectionInterface";
import { DrillLearningMaterialsArea } from "./DrillLearningMaterialsArea";
import { StudentAddArea } from "./StudentAddArea";
import { useStudyTaskForm } from "./useStudyTaskForm";

const helpUrl = "https://fs-help.studyplus.co.jp/ja/articles/9104095";

type Props = {
  section: SectionInterface;
  form: ReturnType<typeof useStudyTaskForm>;
  openDrillLearningMaterialSelectionFormHandler: (index: number) => void;
};

export const StudyTaskMainForm = ({
  section,
  form,
  openDrillLearningMaterialSelectionFormHandler,
}: Props) => {
  return (
    <>
      <SectionAnalyticsTypeTab
        sectionId={section.id}
        analyticsType="studyTask"
      />

      <div className="my-5">
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbItemLink
              href={`/sections/${section.id}/analytics/study_task`}
            >
              <div className="mr-4 text-lg">課題進捗</div>
            </BreadcrumbItemLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrent>
            <div className="mx-4 text-lg">課題の登録</div>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className="rounded border border-solid border-gray-400 bg-white px-7 pb-8 pt-6">
        <div className="mb-7">
          <div className="mb-4 text-2xl font-bold">課題の登録</div>
          <div className="flex items-center">
            <div>課題の設定は登録方法を確認しながら進めてください。</div>
            <a
              href={helpUrl}
              className="text-blue-400"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="inline-flex items-center gap-1">
                <IconHelp />
                登録方法を見る
              </span>
            </a>
          </div>
        </div>
        <StudentAddArea section={section} form={form} />
        <DrillLearningMaterialsArea
          form={form}
          openDrillLearningMaterialSelectionFormHandler={
            openDrillLearningMaterialSelectionFormHandler
          }
        />
        <div className="mt-7 flex justify-end">
          <Button onClick={form.submitForm}>登録</Button>
        </div>
      </div>
    </>
  );
};
