import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import { Action, ActionTypes } from "../../actions/common/session/types";
import { LOGIN_PATH } from "../../containers/Routes/RequireLogin/index";
import AuthorizationStateInterface from "../../interfaces/AuthorizationStateInterface";
import OperatorInterface from "../../interfaces/OperatorInterface";

const defaultState: AuthorizationStateInterface = {
  currentOperator: null,
  loggedInOperators: [],
  backTo: "",
  isLoggedIn: false,
  shouldRedirect: false,
};

const session = (
  state: AuthorizationStateInterface = defaultState,
  action: Action,
): AuthorizationStateInterface => {
  switch (action.type) {
    case ActionTypes.SIGN_OUT: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ActionTypes.SWITCH_OPERATOR:
      return {
        ...state,
        isLoggedIn: false,
      };

    case ActionTypes.SWITCH_OPERATOR_WITH_REDIRECT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        shouldRedirect: true,
        isLoggedIn: true,
      };

    case ActionTypes.SWITCH_OPERATOR_SUCCESS:
      return {
        ...state,
        ...action.payload,
        shouldRedirect: false,
        isLoggedIn: true,
      };

    case ActionTypes.UPDATE_OPERATOR_INFO:
      return {
        ...state,
        currentOperator: action.payload,
        loggedInOperators: state.loggedInOperators.map(
          (user: OperatorInterface) => {
            if (state.currentOperator && user.id === state.currentOperator.id) {
              return action.payload;
            } else {
              return user;
            }
          },
        ),
      };

    case "authorization/GET_USER_INFO_REQUEST":
      return state;

    case "authorization/GET_USER_INFO_SUCCESS":
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true,
        shouldRedirect: false,
        backTo: defaultState.backTo,
      };

    // userInfoの取得に失敗したとき、
    // APIから401を受け取ったときはcurrentOperatorの認可情報を廃棄する
    case "authorization/GET_USER_INFO_ERROR":
    case ActionTypes.UNAUTHORIZED_ERROR:
      return {
        ...state,
        currentOperator: null,
        loggedInOperators: state.loggedInOperators.filter(
          (user: OperatorInterface) => {
            state.currentOperator && user.id !== state.currentOperator.id;
          },
        ),
        shouldRedirect: true,
        backTo: LOGIN_PATH,
        isLoggedIn: false,
      };

    case ActionTypes.UPDATE_USER_CONSENT_AGREED_SUCCESS:
      return {
        ...state,
        currentOperator: state.currentOperator
          ? { ...state.currentOperator, isConsentAgreed: true }
          : null,
      };

    case ActionTypes.RESET_BACK_TO_URL:
      return {
        ...state,
        backTo: "",
      };

    default:
      return state;
  }
};

const conf = {
  key: "session",
  storage,
  blacklist: ["isLoggedIn"],
};

export default persistReducer<AuthorizationStateInterface, Action>(
  conf,
  session,
);
