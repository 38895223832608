import { Flex } from "../../atoms/Flex/index";
import Icon from "../../atoms/Icon/index";
import styles from "./styles.scss";

type Props = {
  label: string;
  onClick: () => void;
};
export const TagSelectorNewButton = ({ onClick, label }: Props) => (
  <Flex justifyContent="flex-end" marginTop="1.6rem">
    <button onClick={onClick} className={styles.newButton}>
      <Icon name="icon-plus-tag" />
      {label}
    </button>
  </Flex>
);
