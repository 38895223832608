import isEqual from "lodash-es/isEqual";
import SectionInterface from "../interfaces/SectionInterface";
import { Classroom } from "./Classroom";
import { LectureAttendance } from "./LectureAttendance";
import { LectureSession } from "./LectureSession";
import {
  Recurrence,
  RequestBody as RecurrenceRequestBody,
  toJson as toRecurrenceJson,
} from "./Recurrence";
import type {
  StudySchedule,
  RequestBody as StudyScheduleRequestBody,
} from "./StudySchedule";
import { toJson as toStudyScheduleJson } from "./StudySchedule";
import { Teacher } from "./Teacher";

export type ScheduleType =
  | "single_schedule"
  | "recurring_exception"
  | "recurring_original"
  | "recurring";

// 生徒予定の型
export type Schedule = {
  id: string;
  summary: string | null;
  startAt: Date;
  endAt: Date;
  locale: string | null;
  description: string | null;
  allday: boolean;
  recurrence: Recurrence | null;
  studySchedule: StudySchedule | null;
  scheduleType: ScheduleType;
};

// サーバーからレスポンスされるScheduleは校舎予定・生徒予定どちらでもあり得るデータを扱う型
// 生徒カレンダー画面で一覧・詳細を扱うためのもの
export type ScheduleForStudent = Schedule & {
  isOwnedBySection: boolean;
  lectureSession: LectureSession | null;
  lectureAttendance: LectureAttendance | null;
  classroom: Classroom | null;
  teacher: Teacher | null;
  section: SectionInterface | null;
};

export type UpdateType = "this" | "following";

export const create = (params: Partial<Schedule>): Schedule => ({
  id: "",
  summary: "",
  startAt: new Date(),
  endAt: new Date(),
  locale: null,
  description: null,
  allday: false,
  recurrence: null,
  studySchedule: null,
  scheduleType: "single_schedule",
  ...params,
});

export type RequestBody = {
  summary: string | null;
  start_at: string;
  end_at: string;
  locale: string | null;
  description: string | null;
  allday: boolean;
  recurrence: RecurrenceRequestBody | null;
  study_schedule: StudyScheduleRequestBody | null;
};
export const toJson = ({
  summary,
  startAt,
  endAt,
  locale,
  description,
  allday,
  recurrence,
  studySchedule,
}: Schedule): RequestBody => ({
  summary,
  start_at: startAt.toISOString(),
  end_at: endAt.toISOString(),
  locale,
  description,
  allday,
  recurrence: recurrence ? toRecurrenceJson(recurrence) : null,
  study_schedule: studySchedule ? toStudyScheduleJson(studySchedule) : null,
});

// 学習量,学習時間の計画がともに0のstudyScheduleは
// フロントでは通常の予定として扱う
export const hasStudySchedule = (schedule: Schedule) => {
  if (!schedule.studySchedule) {
    return false;
  }
  return (
    schedule.studySchedule.amount > 0 ||
    schedule.studySchedule.numberOfSeconds > 0
  );
};

type DetermineScheduleConditionParams = {
  originalSchedule?: Schedule;
  newSchedule: Schedule;
};

export const isRecurrenceChanged = ({
  originalSchedule,
  newSchedule,
}: DetermineScheduleConditionParams) =>
  !isEqual(originalSchedule?.recurrence, newSchedule.recurrence);

export const shouldConfirmUpdateType = ({
  originalSchedule,
  newSchedule,
}: DetermineScheduleConditionParams) => {
  if (!originalSchedule) {
    return false;
  }
  if (
    originalSchedule.id &&
    isRecurringSchedule(newSchedule) &&
    !isRecurrenceChanged({ originalSchedule, newSchedule })
  ) {
    return true;
  }
  return false;
};

// 既存の繰り返し予定の繰り返し条件を変更したか
export const shouldConfirmChangeRecurrence = ({
  originalSchedule,
  newSchedule,
}: DetermineScheduleConditionParams) => {
  return (
    originalSchedule?.id &&
    originalSchedule.recurrence &&
    isRecurrenceChanged({ originalSchedule, newSchedule })
  );
};

// 繰り返しの予定 -> 別条件の繰り返しの予定は "following" を送る
export const determineUpdateTypeWhenNoNeedConfirm = (
  schedule?: Schedule,
): UpdateType => {
  if (!schedule) {
    return "this";
  }
  return isRecurringSchedule(schedule) ? "following" : "this";
};

export const isExceptionSchedule = (schedule: Schedule) =>
  schedule.scheduleType === "recurring_exception";

export const isRecurringSchedule = (schedule: Schedule) =>
  schedule.scheduleType === "recurring" ||
  schedule.scheduleType === "recurring_original";

type Result = "valid" | Error;

export const validateDateRange = (schedule: Schedule): Result => {
  if (schedule.startAt.getTime() > schedule.endAt.getTime()) {
    return new Error("終了時刻は開始時刻以降を指定してください");
  }

  if (
    !schedule.allday &&
    (schedule.startAt.getFullYear() !== schedule.endAt.getFullYear() ||
      schedule.startAt.getMonth() !== schedule.endAt.getMonth() ||
      schedule.startAt.getDate() !== schedule.endAt.getDate())
  ) {
    return new Error("日付を跨いだ予定を作る場合は、終日を指定してください");
  }
  return "valid";
};
export const defaultScheduleTitle = "（タイトルなし）";
