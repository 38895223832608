import * as React from "react";
import AlignMiddle from "../../components/atoms/AlignMiddle";
import Icon from "../../components/atoms/Icon";
import LinkButton from "../../components/atoms/LinkButton";
import {
  ClassroomsTable,
  ClassroomsTableRow,
} from "../../components/features/ClassroomsTable";
import { ScreenReaderOnly } from "../../components/general/ScreenReaderOnly";
import { Text } from "../../components/general/Text";
import {
  ArchiveStatus,
  translateMutateArchiveStatusResult,
} from "../../domains/Attendance";
import { ClassroomList } from "../../domains/Classroom";
import { useUpdateClassroomArchiveStatus } from "../../hooks/http/useClassroomsApi";
import { useFlashMessage } from "../../hooks/useFlashMessage";

type UseArchivedClassroomsTableProps = {
  sectionId: string;
};

// Main hooks
const useArchivedClassroomsTable = ({
  sectionId,
}: UseArchivedClassroomsTableProps) => {
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();
  const { mutate } = useUpdateClassroomArchiveStatus({
    sectionId,
    onError() {
      showErrorMessage(
        translateMutateArchiveStatusResult(ArchiveStatus.active, "failure"),
      );
    },
    onSuccess() {
      showSuccessMessage(
        translateMutateArchiveStatusResult(ArchiveStatus.active, "success"),
      );
    },
  });

  const activateClassroom = (classroomId: string) => {
    mutate({ classroomId, archiveStatus: ArchiveStatus.active });
  };

  return { activateClassroom };
};

// Main Component
type Props = UseArchivedClassroomsTableProps & {
  classrooms: ClassroomList | undefined;
};
const ArchivedClassroomsTable_ = ({ sectionId, classrooms }: Props) => {
  const { activateClassroom } = useArchivedClassroomsTable({ sectionId });

  if (!classrooms) {
    return null;
  }
  if (classrooms.length === 0) {
    return <Text align="center">アーカイブの教室がありません。</Text>;
  }

  return (
    <ClassroomsTable>
      {classrooms.map((classroom) => (
        <ClassroomsTableRow
          key={`archived-classroom-${classroom.id}`}
          classroom={classroom}
        >
          <LinkButton
            onClick={() => {
              activateClassroom(classroom.id);
            }}
          >
            <AlignMiddle>
              <Icon name="icon-archive" />
              <ScreenReaderOnly>{classroom.name}を</ScreenReaderOnly>
              アクティブに戻す
            </AlignMiddle>
          </LinkButton>
        </ClassroomsTableRow>
      ))}
    </ClassroomsTable>
  );
};

export const ArchivedClassroomsTable = React.memo(ArchivedClassroomsTable_);
