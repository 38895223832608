import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { OutletContextProps } from "../../helpers/RouterHelper.tsx";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage.tsx";
import { useFetchProfile } from "../../hooks/http/useFetchProfile.ts";
import AppStateInterface from "../../interfaces/AppStateInterface.ts";
import OperatorInterface, {
  OperatorPrivilegeInfoInterface,
} from "../../interfaces/OperatorInterface.ts";
import SectionInterface from "../../interfaces/SectionInterface.ts";
import SectionMessages from "./SectionMessages/index";

interface Props extends OutletContextProps {
  section: SectionInterface;
  operatorPrivilege: OperatorPrivilegeInfoInterface | null;
  currentOperator: OperatorInterface;
}

const SectionsMessageThreadsPage: React.FC<Props> = (props) => {
  const location = useLocation();

  useEffect(() => {
    props.setActiveMenu("message");
  }, []);

  const { data: operatorProfile } = useFetchProfile();

  if (!props.section) {
    return null;
  }

  return (
    <SectionMessages
      section={props.section}
      location={location}
      operatorPrivilege={props.operatorPrivilege}
      operatorProfile={operatorProfile ?? null}
      operator={props.currentOperator}
    />
  );
};

const pageInfo = {
  title: "メッセージ",
};

const EnhancedPage = enhanceSectionsPage(SectionsMessageThreadsPage, pageInfo);

const mapStateToProps = (state: AppStateInterface) => {
  return {
    operatorPrivilege: state.operatorPrivileges.data,
  };
};

const ConnectedPage = connect(mapStateToProps)(EnhancedPage);

export default ConnectedPage;
