import { addDays, isAfter, isBefore, subDays } from "date-fns";
import { Matcher } from "react-day-picker";
import * as yup from "yup";
import { DateRange } from "../../general/DynamicDateRangePicker";

export const useMultiSectionBatchesDateRangePicker = (dateRange: DateRange) => {
  // 期間（endDate - StartDate)は31日以内である必要がある
  const isOutsideRange = (date: Date) => {
    const allowedDateRangeDays = 31;

    if (!dateRange?.startDate) return false;

    const isBeforeStartDate = isBefore(date, dateRange.startDate);
    const isAfterEndDate = isAfter(
      date,
      addDays(dateRange.startDate, allowedDateRangeDays - 1),
    );

    return isBeforeStartDate || isAfterEndDate;
  };

  // 昨日以前のみ選択可能
  const isAfterYesterday = (date: Date) => {
    const yesterday = subDays(new Date(), 1);
    return isAfter(date, yesterday);
  };

  const disabled: Matcher | Matcher[] = [isOutsideRange, isAfterYesterday];

  return {
    disabled,
  };
};

export const dateRangeSchema = yup
  .object()
  .shape({
    startDate: yup.date(),
    endDate: yup.date(),
  })
  .test("date-range", "期間を選択してください", (value) => {
    return value.startDate !== undefined && value.endDate !== undefined;
  });
