import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback } from "react";
import { boronClient } from "../../api.ts";
import { HTTPErrors, createError } from "../../errors.ts";
import { getNextPageParam } from "../../helpers/ReactQueryHelper.ts";
import StaffBoardPostInterface, {
  StaffBoardPostCommentInterface,
} from "../../interfaces/StaffBoardPostInterface.ts";
import { paths } from "../../lib/api/v1";

type Posts =
  paths["/api/v1/sections/{section_id}/staff_board_posts"]["get"]["responses"]["200"]["content"]["application/json"]["staffBoardPosts"];

export const useFetchStaffBoardPosts = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
  } = useInfiniteQuery<Posts, HTTPErrors>({
    queryKey: buildKey(sectionId),
    queryFn: async ({ pageParam }) => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/staff_board_posts",
        {
          params: {
            path: {
              section_id: sectionId,
            },
            query: {
              page: pageParam as number,
              per: 20,
            },
          },
        },
      );
      if (response.ok && data) {
        return data.staffBoardPosts;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  const staffBoardPosts = data?.pages.flatMap((page) => page.data) ?? [];

  const fetchNextStaffBoardPosts = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  return {
    staffBoardPosts,
    isLoading: isPending || isFetchingNextPage,
    error,
    fetchNextStaffBoardPosts,
  };
};

const buildKey = (sectionId: string) => ["staffBoardPosts", sectionId];

export const useAddStaffBoardPostToListCache = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  const queryClient = useQueryClient();
  const addStaffBoardPostToListCache = (
    staffBoardPost: StaffBoardPostInterface,
  ) => {
    queryClient.setQueryData<InfiniteData<Posts>>(
      buildKey(sectionId),
      (data) => {
        if (!data) return;

        return {
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            data: [staffBoardPost, ...page.data],
          })),
        };
      },
    );
  };

  return { addStaffBoardPostToListCache };
};

export const useRemoveStaffBoardPostFromListCache = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  const queryClient = useQueryClient();
  const removeStaffBoardPostFromListCache = (staffBoardPostId: string) => {
    queryClient.setQueryData<InfiniteData<Posts>>(
      buildKey(sectionId),
      (data) => {
        if (!data) return;

        return {
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            data: page.data.filter((post) => post.id !== staffBoardPostId),
          })),
        };
      },
    );
  };

  return { removeStaffBoardPostFromListCache };
};

export const useAddStaffBoardPostCommentToListCache = ({
  sectionId,
  staffBoardPostId,
}: {
  sectionId: string;
  staffBoardPostId: string;
}) => {
  const queryClient = useQueryClient();
  const addStaffBoardPostCommentToListCache = (
    staffBoardPostComment: StaffBoardPostCommentInterface,
  ) => {
    queryClient.setQueryData<InfiniteData<Posts>>(
      buildKey(sectionId),
      (data) => {
        if (!data) return;

        return {
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            data: page.data.map((post) => {
              if (post.id === staffBoardPostId) {
                return {
                  ...post,
                  comments: [...post.comments, staffBoardPostComment],
                };
              }
              return post;
            }),
          })),
        };
      },
    );
  };

  return { addStaffBoardPostCommentToListCache };
};

export const useRemoveStaffBoardPostCommentFromListCache = ({
  sectionId,
  staffBoardPostId,
}: {
  sectionId: string;
  staffBoardPostId: string;
}) => {
  const queryClient = useQueryClient();
  const removeStaffBoardPostCommentFromListCache = (
    staffBoardPostCommentId: string,
  ) => {
    queryClient.setQueryData<InfiniteData<Posts>>(
      buildKey(sectionId),
      (data) => {
        if (!data) return;

        return {
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            data: page.data.map((post) => {
              if (post.id === staffBoardPostId) {
                return {
                  ...post,
                  comments: post.comments.filter(
                    (comment) => comment.id !== staffBoardPostCommentId,
                  ),
                };
              }
              return post;
            }),
          })),
        };
      },
    );
  };

  return { removeStaffBoardPostCommentFromListCache };
};
