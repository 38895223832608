import { IconNaviAvatar } from "@studyplus/boron-ui";
import { Link } from "react-router-dom";
import Loader from "../../components/atoms/Loader";
import { AttachmentViewer } from "../../components/features/AttachmentViewer";
import { useAttachmentViewer } from "../../components/features/AttachmentViewer/useAttachmentViewer";
import { BillingPlanLabel } from "../../components/features/BillingPlanLabel";
import { ExaminationResultDetail } from "../../components/features/ExaminationResultDetail";
import { MAX_NUM_OF_IMAGES } from "../../components/features/ExaminationResultFileAttachmentItem";
import { ExaminationResultTable } from "../../components/features/ExaminationResultTable";
import { Examination } from "../../domains/Examination";
import {
  ExaminationResult,
  ExaminationResultAttachment,
  TimelineExaminationResult,
} from "../../domains/ExaminationResult";
import TimeHelper from "../../helpers/TimeHelper";
import { useFetchExaminationResultAttachments } from "../../hooks/http/useFetchExaminationResultAttachments";
import StudentInterface from "../../interfaces/StudentInterface";

type Props = {
  key: string;
  examinationResult: TimelineExaminationResult;
};
export const TimelineExaminationItem = (props: Props) => {
  const { examinationResult } = props;
  const { student, examination, resultAttachments } = examinationResult;

  return (
    <div
      className="mt-7 rounded-md border border-solid border-gray-400 bg-white"
      aria-label={`生徒: ${student.fullName}, 試験: ${examination.name}`}
    >
      <Header
        examinationResultUpdatedAt={props.examinationResult.updatedAt}
        student={props.examinationResult.student}
      />
      <div className="m-7 rounded-md border border-solid border-gray-400 px-7 py-0">
        <div className="mt-7 flex flex-col items-start justify-between pb-9 text-lg font-light leading-none lg:flex-row">
          <ContentHeader
            examinationResult={examinationResult}
            examination={examination}
            student={student}
          />
        </div>
        <div className="mb-7 flex flex-col lg:flex-row">
          <ContentBody
            examinationResult={examinationResult}
            resultAttachments={resultAttachments}
          />
        </div>
      </div>
    </div>
  );
};

type HeaderProps = {
  examinationResultUpdatedAt: string;
  student: StudentInterface;
};
const Header = (props: HeaderProps) => {
  const { student, examinationResultUpdatedAt } = props;
  const formatedUpdatedAt = TimeHelper.fullFormat(examinationResultUpdatedAt);

  return (
    <div
      className="flex items-center justify-start px-7 pt-6"
      aria-label={`生徒: ${student.fullName}, 成績更新日時: ${formatedUpdatedAt}`}
    >
      <IconNaviAvatar className="h-4 w-4 text-darkred-300" />
      <div className="ml-4 flex items-start gap-3">
        <Link to={`/students/${student.id}`} className="font-bold text-black">
          {student.fullName}
        </Link>
        {student.billingPlan ? (
          <BillingPlanLabel billingPlan={student.billingPlan} />
        ) : null}
      </div>
      <div className="ml-7">{formatedUpdatedAt}</div>
    </div>
  );
};

type ContentHeaderProps = {
  examinationResult: ExaminationResult;
  examination: Examination;
  student: StudentInterface;
};
const ContentHeader = (props: ContentHeaderProps) => {
  const { examinationResult, examination, student } = props;
  const { section } = student;

  return (
    <>
      <ExaminationResultDetail
        examinedOn={examinationResult.examinedOn}
        organizerName={examination.organizer.name}
        classificationName={examination.classification.name}
        targetJobName={examination.targetJob.human}
        examinationName={examination.name}
      >
        <Link
          to={`/sections/${section.id}/analytics/examinations/${examination.id}`}
          className="text-black underline"
        >
          {examination.name}
        </Link>
      </ExaminationResultDetail>
      <div className="self-end pt-4 lg:self-auto">
        <Link
          to={`/students/${student.id}/analytics/examinations/${examination.id}/result`}
          className={`buiButtonBase buiButtonVariantOutline buiButtonSizeSm`}
        >
          詳細を見る
        </Link>
      </div>
    </>
  );
};

type ContentBodyProps = {
  examinationResult: ExaminationResult;
  resultAttachments: ExaminationResultAttachment[];
};
const ContentBody = (props: ContentBodyProps) => {
  const { examinationResult, resultAttachments } = props;

  const { data: attachments = [] } =
    useFetchExaminationResultAttachments(examinationResult);
  const files = attachments.map((attachment) => attachment.file);

  const attachmentViewerProps = useAttachmentViewer({
    files: files,
    maxPageNo: MAX_NUM_OF_IMAGES,
  });

  return (
    <>
      <div
        className={`w-full ${
          resultAttachments.length > 1 ? "lg:max-w-[520px]" : "lg:w-[70%]"
        }`}
      >
        <ExaminationResultTable examinationResult={examinationResult} />
      </div>
      {resultAttachments.length > 0 && (
        <div className="mt-4 h-fit max-w-4xl rounded bg-gray-100 p-7 lg:ml-4 lg:mt-0 lg:w-full">
          {files.length > 0 ? (
            <AttachmentViewer
              {...attachmentViewerProps}
              maxPageNo={MAX_NUM_OF_IMAGES}
            />
          ) : (
            <Loader loading />
          )}
        </div>
      )}
    </>
  );
};
