import { IconEllipsis } from "@studyplus/boron-ui";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "../../../components/general/DropdownMenu/DropdownMenu";
import StudentEditModal from "../../../components/molecules/StudentEditModal";
import { OperatorRole } from "../../../interfaces/OperatorInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import { DisableStudentDropdownLink } from "./DisableStudentDropdownLink";
import { DisconnectAppDropdownLink } from "./DisconnectAppDropdownLink";
import { DisconnectAppModal } from "./DisconnectAppDropdownLink/DisconnectAppModal";
import { useDisconnectAppDropdownLink } from "./DisconnectAppDropdownLink/useDisconnectAppDropdownLink";
import { DisconnectLineDropdownLink } from "./DisconnectLineDropdownLink";
import { StudentEditDropdownLink } from "./StudentEditDropdownLink";
import { useStudentEditDropdownLink } from "./StudentEditDropdownLink/useStudentEditDropdownLink";

type Props = {
  student: StudentInterface;
  operatorRole: OperatorRole;
  guardianLineConnectionUsable: boolean;
  updateStudent: (student: StudentInterface) => void;
  removeStudent: (studentId: string) => void;
};
export const StudentItemDropdown = ({
  student,
  operatorRole,
  guardianLineConnectionUsable,
  updateStudent,
  removeStudent,
}: Props) => {
  const {
    isOpen: isOpenStudentEditModal,
    handleOpen: handleOpenStudentEdit,
    handleClose: handleCloseStudentEdit,
  } = useStudentEditDropdownLink();

  const {
    isOpen: isOpenDisconnectAppModal,
    handleOpen: handleOpenDisconnectApp,
    handleClose: handleCloseDisconnectApp,
  } = useDisconnectAppDropdownLink();

  if (operatorRole === "limited_member") {
    return null;
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="text-gray-600 hover:text-blue-400">
          <IconEllipsis />
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
          <DropdownMenuContent side="left" align="start">
            <StudentEditDropdownLink handleSelect={handleOpenStudentEdit} />
            {student.status !== "active" &&
            student.status !== "inviting" ? null : (
              <DisconnectAppDropdownLink
                handleSelect={handleOpenDisconnectApp}
              />
            )}
            {guardianLineConnectionUsable && (
              <DisconnectLineDropdownLink
                student={student}
                updateStudent={updateStudent}
              />
            )}
            {student.isDeletable && (
              <DisableStudentDropdownLink
                student={student}
                removeStudent={removeStudent}
              />
            )}
          </DropdownMenuContent>
        </DropdownMenuPortal>
      </DropdownMenu>

      {/* NOTE: モーダルをドロップダウンの外に定義しないと競合してモーダルが操作できないなど、うまく動かないためここで定義する */}
      <StudentEditModal
        isOpen={isOpenStudentEditModal}
        student={student}
        updateStudent={updateStudent}
        handleClose={handleCloseStudentEdit}
      >
        <h2>生徒を編集</h2>
      </StudentEditModal>
      <DisconnectAppModal
        isModalOpen={isOpenDisconnectAppModal}
        student={student}
        updateStudent={updateStudent}
        closeModal={handleCloseDisconnectApp}
      />
    </>
  );
};
