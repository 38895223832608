import { boronClient } from "../../../../api";
import { useBoronMutation } from "../../../../hooks/http/useBoronMutation";

export const buildKey = (sectionId: string) => ["postTag", sectionId];

export const usePostTag = ({ sectionId }: { sectionId: string }) => {
  return useBoronMutation(
    async ({ name }: { name: string }) =>
      await boronClient.POST("/api/v1/sections/{section_id}/tags", {
        params: { path: { section_id: sectionId } },
        body: {
          tag: {
            name,
          },
        },
      }),
    {
      mutationKey: buildKey(sectionId),
    },
  );
};
